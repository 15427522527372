import { DEFAULT_VALUES } from "../../constants";
import { priceFormatter } from "../../helper";

const { ZERO } = DEFAULT_VALUES;

const PriceInfo = ({
  flightIndex,
  totalPrice,
  publishedPrice,
  tavaMarkup,
  currencySymbol,
  translationHandler,
}) => {
  return (
    <>
      {flightIndex === ZERO && (
        <div className='flex justify-center text-[10px] text-primary-700'>
          {translationHandler("flightResults.pricesStartingAt")}
        </div>
      )}
      {totalPrice !== publishedPrice && (
        <h5 className='text-base text-center text-contrast-600 font-bold line-through'>
          {currencySymbol}
          &nbsp;
          {priceFormatter(parseFloat(publishedPrice))}
        </h5>
      )}
      <h5 className='text-lg text-center text-contrast-900 font-bold mb-1'>
        {currencySymbol}
        &nbsp;
        {priceFormatter(parseFloat(totalPrice + tavaMarkup))}
      </h5>
    </>
  );
};

export default PriceInfo;
