import { useState } from "react";
import { get, isEmpty } from "lodash";
import {
  getDayDifference,
  getFormattedAddress,
  getFormattedDateObject,
  getFutureFormattedDateObject,
  getLeadGuestInfo,
} from "../../helper";
import { selectSelectedTrip } from "../Booking/Trips";
import { DEFAULT_VALUES } from "../../constants";
import { useSelector } from "react-redux";

const { ZERO, ONE, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;

const calculateTotalGuest = (rooms) => {
  let totalGuests = {
    adults: 0,
    children: 0,
  };

  rooms?.forEach((room) => {
    const roomGuests = room.hotelPassenger.reduce(
      (total, guest) => {
        if (guest?.paxType === 1) total.adults = total.adults + 1;
        else total.children = total.children + 1;
        return total;
      },
      { adults: 0, children: 0 }
    );

    totalGuests = {
      adults: totalGuests.adults + roomGuests.adults,
      children: totalGuests.children + roomGuests.children,
    };
  });

  return totalGuests;
};
const HotelDetailSection = () => {
  const [imageError, setImageError] = useState(false);
  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedTripHotel = selectedTrip?.hotels[0];
  const address = get(
    selectedTripHotel,
    "blockRoomResJson.blockRoomResult.addressLine1",
    EMPTY_STRING
  );
  const hotelName = get(
    selectedTripHotel,
    "blockRoomResJson.blockRoomResult.hotelName",
    EMPTY_STRING
  );
  const totalRooms = get(
    selectedTripHotel,
    "bookingReqJson.hotelRoomsDetails.length",
    EMPTY_STRING
  );
  const DayRates = get(
    selectedTripHotel,
    "blockRoomResJson.blockRoomResult.hotelRoomsDetails[0].dayRates",
    EMPTY_ARRAY
  );
  const { profileDetails = {} } =
    !isEmpty(selectedTrip) &&
    getLeadGuestInfo(
      get(
        selectedTripHotel,
        "bookingReqJson.hotelRoomsDetails[0].hotelPassenger",
        EMPTY_ARRAY
      )
    );
  const { adults, children } = calculateTotalGuest(
    get(selectedTripHotel, "bookingReqJson.hotelRoomsDetails", EMPTY_ARRAY)
  );
  const { title, firstName, lastName } = profileDetails;

  const checkInObject = DayRates?.[ZERO];
  const checkOutObject = DayRates?.slice(-1)[ZERO];
  let totalDays;
  let checkInDate;
  let checkOutDate;
  if ((checkInObject, checkOutObject)) {
    totalDays = getDayDifference(checkInObject.date, checkOutObject.date);
    checkInDate = getFormattedDateObject(checkInObject.date);
    checkOutDate = getFutureFormattedDateObject(checkOutObject.date);
  }
  const bookingDuration = totalDays + ONE || ONE;

  const totaladults = `${adults} Adult${adults > 1 ? "s" : ""}`;
  const childsCountText = children > 1 ? "'s" : "";
  const totalchildren =
    children > 0 ? `| ${children} Child${childsCountText}` : "";
  const noOfRooms = `${totalRooms} Room${totalRooms > 1 ? "s" : ""}`;
  let hotelImages = [];

  return (
    <>
      <div className='flex flex-col-reverse sm:flex-row gap-3 px-4 py-3 border-b border-dashed border-gray-300 justify-between'>
        <div className='flex-1'>
          <h4 className='text-gray-900 font-bold text-base mb-2'>
            {hotelName}
          </h4>
          <p className='text-gray-700 text-xs mb-2'>
            {getFormattedAddress(address)}
          </p>
        </div>
        <div className='shrink-0'>
          {imageError || isEmpty(hotelImages) ? (
            <div className='flex justify-center items-center w-full rounded-md h-28 bg-black bg-opacity-30 px-4 py-2'>
              <span className=' text-white font-bold'>{hotelName}</span>
            </div>
          ) : (
            <img
              src={hotelImages?.[0]}
              className='rounded-md h-28'
              width='100'
              onError={() => setImageError(true)}
              alt='Hotel Room'
            />
          )}
        </div>
      </div>
      <div className='p-4 bg-indigo-100/30'>
        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-12 md:col-span-12 lg:col-span-6'>
            <div className='flex gap-3 items-center justify-between'>
              <div>
                <span className='text-gray-700 text-xs font-medium'>
                  Check In
                </span>
                <h5 className='text-xs  text-gray-900/90 mb-1 font-bold'>
                  {checkInDate ? (
                    <>
                      {checkInDate.day}{" "}
                      <strong className='text-sm'>
                        {`${checkInDate.date} ${checkInDate.month} `}
                      </strong>
                      {checkInDate.year}
                    </>
                  ) : (
                    "..."
                  )}
                </h5>
              </div>
              <div className='icon shrink-0'>
                <div className='bg-gray-200 font-medium text-gray-800 rounded-2xl px-3 py-0.5 text-[10px]'>
                  {`${bookingDuration} Night${bookingDuration > 1 ? "s" : ""}`}
                </div>
              </div>
              <div>
                <span className='text-gray-700 text-xs font-medium'>
                  Check Out
                </span>
                <h5 className='text-xs text-gray-900/90 mb-1 font-bold'>
                  {checkOutDate ? (
                    <>
                      {checkOutDate.day}{" "}
                      <strong className='text-sm'>
                        {`${checkOutDate.date} ${checkOutDate.month} `}
                      </strong>
                      {checkOutDate.year}
                    </>
                  ) : (
                    "..."
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className='col-span-12 md:col-span-12 lg:col-span-6 flex gap-4 justify-between md:justify-around'>
            <div className='text-end'>
              <span className='text-gray-700 text-xs font-medium'>
                Guest & Rooms
              </span>
              <h5 className='text-xs text-gray-800/90 font-bold mb-1'>
                {totaladults} {totalchildren} | {noOfRooms}
              </h5>
            </div>
            <div>
              <span className='text-gray-600 text-xs font-medium'>
                Primary Guest
              </span>
              <h5 className='text-xs  text-gray-900 mb-1 font-bold'>
                {title} {firstName} {lastName}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelDetailSection;
