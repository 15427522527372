import PropTypes from "prop-types";

export const TablePropsTypes = {
  columnDefs: PropTypes.array,
  rowData: PropTypes.array,
};

export const BadgePropsTypes = {
  value: PropTypes.string,
  shouldCapitalizeFirstLetter: PropTypes.bool,
};
