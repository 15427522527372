import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_VALUES } from "../../constants";
import { fetchTransactionDetails } from "./transactions.actions";

const { EMPTY_ARRAY } = DEFAULT_VALUES;

const initialState = {
  transactionDetails: {
    result: EMPTY_ARRAY
  }
};

const slice = createSlice({
  name: "transactions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTransactionDetails.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.transactionDetails = action.payload;
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
