import { get } from "lodash";
import { DEFAULT_VALUES, GUEST_TYPES } from "../constants";

const { ZERO } = DEFAULT_VALUES;
const { ADULT, CHILD } = GUEST_TYPES;

export const mapGuestDetails = (
  leadGuestInfo,
  otherGuestsInfo,
  isSamePanForAllAllowed,
  roomGuests,
  index,
  firstAdultGuestPan,
  isPassportRequired,
  isPANRequired
) => {
  const { email = "", phoneNumber = "" } = get(
    leadGuestInfo,
    "profileDetails",
    {}
  );
  const passportNumber = get(
    leadGuestInfo,
    "passportDetails.passportNumber",
    null
  );

  let adultGuests = [];
  let childrenGuests = [];

  otherGuestsInfo.forEach((guest) => {
    const {
      roomIndex,
      profileDetails: { isChild },
    } = guest;
    if (roomIndex === index) {
      isChild ? childrenGuests.push(guest) : adultGuests.push(guest);
    }
  });
  const firstAdultGuestId = adultGuests[ZERO]?.id;

  let mappedGuestDetails = [];

  adultGuests.forEach((guest) => {
    const {
      title = "",
      firstName = "",
      middleName = null,
      lastName = "",
    } = get(guest, "profileDetails", {});
    const passportNumber = get(guest, "passportDetails.passportNumber", null);
    const panCardNumber = get(guest, "panCardDetails.panCardNumber", null);
    const guestPanNumber = isSamePanForAllAllowed
      ? firstAdultGuestPan
      : panCardNumber;

    const formattedGuestInfo = {
      title,
      firstName,
      middleName,
      lastName,
      phoneNumber,
      email,
      paxType: ADULT,
      isLeadPassenger: guest.id === firstAdultGuestId,
      ...(isPassportRequired && { passportNumber }),
      ...(isPANRequired && { pan: guestPanNumber }),
    };
    mappedGuestDetails.push(formattedGuestInfo);
  });

  childrenGuests.forEach((guest, childIndex) => {
    const {
      title = "",
      firstName = "",
      middleName = null,
      lastName = "",
    } = get(guest, "profileDetails", {});
    const passportNumber = get(guest, "passportDetails.passportNumber", null);
    const guestPanNumber = firstAdultGuestPan;

    const formattedGuestInfo = {
      title,
      firstName,
      middleName,
      lastName,
      phoneNumber,
      email,
      paxType: CHILD,
      age: get(roomGuests, `${index}.childAge[${childIndex}]`, ZERO),
      isLeadPassenger: false,
      ...(isPassportRequired && { passportNumber }),
      ...(isPANRequired && { pan: guestPanNumber }),
    };
    mappedGuestDetails.push(formattedGuestInfo);
  });

  return mappedGuestDetails;
};
