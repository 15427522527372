import PropTypes from "prop-types";

export const SearchHotelSectionPropTypes = {
  showEditSearch: PropTypes.bool,
  defaultExpandedValue: PropTypes.bool,
};

export const SearchSectionPropTypes = {
  showEditSearch: PropTypes.bool,
};

export const SearchButtonPropTypes = {
  tripType: PropTypes.string,
};

export const TravelersCountPropTypes = {
  source: PropTypes.string,
};

export const MultiCitySearchPropTypes = {
  handleLocationChange: PropTypes.func,
  footer: PropTypes.element,
};

export const FareTypeSelectorPropTypes = {
  fareType: PropTypes.string,
  setFareType: PropTypes.func,
  isHomePage: PropTypes.bool,
};

export const FareTypeCardPropTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  handleFareTypeChange: PropTypes.func,
  isHomePage: PropTypes.bool,
  translationHandler: PropTypes.func,
};
