import { useState } from "react";
import SuccessModal from "./BookingSuccessModal";
import FailureModal from "./BookingFailureModal";
import ConfirmModal from "./BookingConfirmationModal";
import CancelModal from "./BookingRefundModal";
import Modal from "../../Modal";
import { BOOKING_CANCELLATION_STATES, MODAL_SIZE } from "../../../../constants";

const { CANCEL, CONFIRM, SUCCESS, FAILURE } = BOOKING_CANCELLATION_STATES;
const { LG } = MODAL_SIZE;

const renderModalContent = (currentModal, setCurrentModal) => {
  return {
    [CANCEL]: <CancelModal setCurrentModal={setCurrentModal} />,
    [CONFIRM]: <ConfirmModal setCurrentModal={setCurrentModal} />,
    [SUCCESS]: <SuccessModal setCurrentModal={setCurrentModal} />,
    [FAILURE]: <FailureModal setCurrentModal={setCurrentModal} />,
  }[currentModal];
};

const BookingCancellationModal = () => {
  const [currentModal, setCurrentModal] = useState(CANCEL);
  return (
    <Modal size={LG}>{renderModalContent(currentModal, setCurrentModal)}</Modal>
  );
};

export default BookingCancellationModal;
