export const getLeadGuestInfo = (primaryRoomPassengers = []) => {
  const { email, firstName, phoneNumber, title } = primaryRoomPassengers.find(
    (passenger) => passenger?.isLeadPassenger
  );

  return {
    profileDetails: {
      title,
      email,
      phoneNumber,
      firstName,
    },
  };
};
