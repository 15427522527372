import * as authActions from "./auth.actions";
import * as slice from "./auth.reducer";
import * as selectors from "./auth.selector";
import LogIn from "./LogIn";
import SetPassword from "./SetPassword";
import AuthConfirmation from "./AuthConfirmation";
import ForgotPassword from "./ForgotPassword";

export const {
  login,
  signUp,
  regenerateAccessToken,
  verifyOtp,
  acceptInvitation,
  invitationDetails,
  forgotPassword,
  resetPasswordByOtp,
  fetchTenantDetails,
} = authActions;

export const {
  name,
  reducer,
  actions: {
    setAuthInfo,
    setInvitationId,
    setIsUserAuthenticated,
    setCurrentUserInfo,
    setTenantConfig,
    setIsSidePanelCollapsed
  },
} = slice;

export const {
  selectAuthInfo,
  selectCurrentUserInfo,
  selectInvitationId,
  selectInvitationDetails,
  selectIsUserAuthenticated,
  selectTenantDetails,
  selectIsSidePanelCollapsed
} = selectors;

export { LogIn, SetPassword, AuthConfirmation, ForgotPassword };
