import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { JourneyDetails } from "../../../molecules/TripOverviewCard";
import { DEFAULT_VALUES, MODAL_SIZE } from "../../../../constants";
import { selectSelectedFlightIndexInfo } from "../../../../screens/FlightResults";
import Modal, { setSelectedModal } from "../../Modal";

const { ZERO } = DEFAULT_VALUES;
const { MD, FULL, LG, XL } = MODAL_SIZE;

const Header = () => {
  const { t } = useTranslation();
  return (
    <span className='text-base font-bold text-primary-700'>
      {t("flightResults.journeyDetails.flightDetails")}
    </span>
  );
};

const ViewFlightDetailsModal = () => {
  const dispatch = useDispatch();

  const selectedFlightIndexInfo = useSelector(selectSelectedFlightIndexInfo);

  const { itineraries } = selectedFlightIndexInfo || {};

  const handleClose = () => dispatch(setSelectedModal(null));

  return (
    <Modal
      title={<Header />}
      handleClose={handleClose}
      shouldCloseOnClickOutside={true}
      size={XL}
    >
      <div className='p-4 bg-contrast-50 max-h-[500px] overflow-y-auto'>
        <JourneyDetails
          itineraries={itineraries}
          segments={itineraries[ZERO].segments}
        />
      </div>
    </Modal>
  );
};

export default memo(ViewFlightDetailsModal);
