import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal, { setSelectedModal } from "../../Modal";
import { reissuanceCallback } from "../../../../screens/Booking/FlightBookings";
import { CACHE_KEYS, DEFAULT_VALUES, ROUTES } from "../../../../constants";
import { getFromSessionStorage } from "../../../../helper";
import { selectFlightPriceReq } from "../../../../screens/FlightResults";

const { BOOKING_INFORMATION } = CACHE_KEYS;
const { BOOKINGS, PAYMENT_CONFIRM } = ROUTES;
const { ZERO, EMPTY_OBJECT } = DEFAULT_VALUES;

const RefundModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const priceAPIRequest = useSelector(selectFlightPriceReq);

  const handleClose = () => {
    dispatch(setSelectedModal(null));
  };

  const handleSubmit = () => {
    const bookingInformation = getFromSessionStorage(BOOKING_INFORMATION);
    if (!bookingInformation || !priceAPIRequest) navigate(BOOKINGS);

    const { reissuanceDetails, tavaBookingId } = bookingInformation;
    const { endUserIp, resultIndex, traceId } =
      priceAPIRequest[ZERO] || EMPTY_OBJECT;

    const reissuanceCallbackRequest = {
      requestBody: {
        ...reissuanceDetails,
        endUserIp,
        ResultIndex: resultIndex,
        TraceId: traceId,
        tavaBookingId,
        isRefundable: true,
      },
    };

    dispatch(reissuanceCallback(reissuanceCallbackRequest));
    navigate(PAYMENT_CONFIRM);
    handleClose();
  };

  const footer = (
    <div className='flex justify-end p-6 border-t'>
      <button type='submit' className='btn btn-primary' onClick={handleSubmit}>
        {t("refundModal.continue")}
      </button>
    </div>
  );

  return (
    <div>
      <Modal
        handleClose={handleClose}
        title={t("refundModal.title")}
        footer={footer}
      >
        <div className='px-10 py-6 border-t'>
          <p
            dangerouslySetInnerHTML={{
              __html: t("refundModal.message"),
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RefundModal;
