import { DEFAULT_VALUES } from "../constants";
import { getFutureFormattedDateObject } from "./getDateTime";

const{ ONE } = DEFAULT_VALUES;

const currentYear = new Date().getFullYear();
const nextYear = new Date().getFullYear() + ONE;
const currentMonth = new Date().getMonth() + ONE;
const { date, month, year } = getFutureFormattedDateObject(new Date());
const nextDate = `${date}/${month}/${year}`;


export const getPrompt = (prompt, endUserIp) => {
	return `I have a search engine for travel. 
  The user has inputed the following search query: ${prompt}.
  Convert search query into JSON & return only JSON with no extra word.
  If its a flight booking create a FlightsJSON which is defined below and create a HotelsJSON if search query contain hotel booking.
  Seach query can contain info for both hotel and flight booking, manage both if details given, check for hotel in query and create hotelJSON from the details provided.
  Always use YYYY-MM-DD date format for FlightJSON object and use DD/MM/YYYY date format for HotelJSON object. 
  To prepare FlightsJSON follow below instructions to prepare the JSON - 
  By default consider 'tripType' to be oneway if for flight search two cities name are given and nothing is provided such as returning from or many cities name are given in which case it will be multiCity, and do not consider the city given for hotel in flight in any way.
  Always use future dates.
  Use current year ${currentYear} or next year ${nextYear} if not specified.
  If month is specified and year is not specified and month is less than current ${currentMonth}th month use ${nextYear} other wise use ${currentYear}
  If no dates specified, use ${nextDate} as departureDate.
  Add key 'fareType' with default value as 'Regular', if user specify any use that, only possible values are Student, ArmedForce and SeniorCitizen.
  If user specify need direct flights, add key 'isDirectFlight' with true, else add this key always with default value as false.
  Add key 'travelClass' with default value as ECONOMY, update this if user specify, value would be in uppercase all time.
  Add key 'endUserIp', with ${endUserIp}.
  Add key 'includedAirlines', with default value as [], if in query specified any airline to include get the iataCode of specified airline and add that airline code in this array.
  Add key 'excludedAirlines', with default value as [], If in query specified any airline to exclude, add that airline code in this array as string.
  Add key 'passengersCount' which holds an object, contains keys adult with default value 1, children default value 0 and infants with default value 0, update this object as per user query.
  Add key 'tripType' for round trip add value roundTrip, for one way add oneWay & for multi city flights, add type multiCity, by default it will be oneWay, if there is something like return, returning from or something like that in query than add roundTrip as the value of tripType and multiCity if going to different cities.
  Add key 'currencyCode' with default value INR, update this, if user provide currency.
  Add key 'travelType' & set its value as domestic if user fly within a country, and set international, if user searching for across the countries.
  Add key 'key' with default value as search.
  Add key 'maxPrice' with default value "", update this if user specify any budget, amount, figure or price added in query.
  Add key 'numberOfStops' with default value 0, update this if user specify number of stop in query.
  Add key 'departureTime' with default value 0. update departureTime if user specify any time then multiply it with 60 to give the minutes for departure.
  Add key 'arrivalTime' with default value 0. update arrivalTime if user specify any time then multiply it with 60 to give the minutes for arrival.
  Add key 'journeys' with default value as [].In journeys add key 'originCode', 'destCode' key,'departureDate' key,'returnDate' key, default value for this keys will be "" , update value of destCode with value after to and originCode from value after from  based on the query search for flight only also try to update this value based on the query if it differs a bit from this format and for this value only use query for flight, put these keys as an object inside journeys, Make sure this object & its keys never remove, In multicity journeys will contain objects for each flight.
  If its a round trip or return trip, make value of tripType to roundTrip if in query something is provided like return, returning from, return from, returning on, but if returnDate not provided, add next day of departureDate as returnDate and update 'returnDate' key inside journeys if its a round trip.
  Put this whole JSON in new object's key 'FlightsJSON' an send result JSON.
  you also need to distinguish between if the user is talking about hotel OR flights and create JSON accordingly
  Follow the belown insturction to create HotelsJSON if query contain hotel search.
  Add key 'HotelsJSON' with default value {}.
  Add key 'maxRating' with default value of 5, & update this if user specify.
  Add key 'minRating' with default value of 0, & update this if user specify.
  Add key 'starRating' with default value of Empty Array, & update this array if user specify and star ratings.
  Add key 'noOfRooms' with default value 1, update if user specify any.
  Ensure that the checkInDate and checkOutDate are in DD/MM/YYYY format.
  Add key 'checkInDate' from query in format DD/MM/YYYY, add ${nextDate} date, if user not specify.
  Add key 'checkOutDate' from query in format DD/MM/YYYY based on the checkInDate, if checkOutDate not provided calculate based on the number of nights which is by default set as 1.
  Add key 'countryCode' with default value 'IN', update this with city provided.
  Add key 'isProviderMapped' with default value as "true".
  Add key 'guestNationality' with default value 'IN', update if user specify his country, add 2 digit country code.
  Add key 'preferredCurrency' with default value as 'INR', update this if user specify to user other currency and then add that currency country code.
  Add key 'endUserIp' with value ${endUserIp}.
  Add key 'source' as "TBO".
  Add key 'maxPrice' with default value "", update this if user specify any budget, amount, figure or price added in query.
  Add key 'noOfNights' with default value "1", and calculate this based on checkInDate & checkOutDate dates if provided.
  Add key 'roomGuests' with default JSON value "[{"noOfAdults": 1, "noOfChild": 0, "childAge":[]}]", update number of adults & children if provided, if number of children are not zero, update the value of the child age as provided for all the children if not provided add default value the child age, there will be as many objects in roomGuests as of number of rooms, create the object from information provided for guests in each room in case of multi rooms, if information not provided do it your self(Total guests should be divided among the available rooms, ensuring that there is at least one adult in each room).
  Add key 'cityName', with default value "", update this with city name provided in the query.`
}
