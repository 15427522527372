import { Cross, Information, RenderSVG } from "../../../../assets/icons";
import Modal from "../../Modal";

const ApprovalsModal = ({
  title = "",
  primaryButtonText = "",
  handleClose = () => {},
  handleSubmit = () => {},
  children,
}) => {
  return (
    <Modal shouldShowModalFromProps handleClose={handleClose}>
      <div className='flex flex-col divide-y divide-contrast-300'>
        <div className='flex justify-between items-center p-6'>
          <div className='flex gap-5 items-center'>
            <div className='rounded-full bg-primary-100 p-2 w-10 h-10'>
              <RenderSVG
                Svg={Information}
                width='24'
                height='24'
                className='text-[25px]'
              />
            </div>
            <div className='text-2xl leading-8 font-bold'>{title}</div>
          </div>
          <button onClick={handleClose}>
            <RenderSVG Svg={Cross} alt='cross' className='text-contrast-900' />
          </button>
        </div>
        <div className='p-6'>{children}</div>
        <div className='w-full p-3 border-t border-contrast-200 justify-start items-center gap-2 inline-flex'>
          <div className='grow shrink basis-0 justify-end items-start gap-4 flex'>
            <button
              type='button'
              className='px-4 py-3 bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300'
              onClick={handleClose}
            >
              <div className="text-contrast-700 text-sm font-medium font-['Inter'] leading-tight">
                Close
              </div>
            </button>
            <button
              type='button'
              className={
                "px-4 py-3 rounded-md shadow justify-center items-center flex bg-primary-600 hover:bg-primary-700 active:bg-primary-600"
              }
              onClick={handleSubmit}
            >
              <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                {primaryButtonText}
              </div>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApprovalsModal;
