import { memo } from "react";
import { useCollapse } from "react-collapsed";
import { ChevronDown, ChevronUp, RenderSVG } from "../../../assets/icons";
import { DEFAULT_VALUES } from "../../../constants";

const { EMPTY_STRING, EMPTY_ARRAY, TWO, ONE } = DEFAULT_VALUES;
const CHILD = "Child";
const ADULT = "Adult";
const MALE = "Male";
const FEMALE = "Female";
const MR = "Mr";

const HotelPassengersInfo = ({ passengersData = EMPTY_ARRAY }) => {

  return (
    <div className='bg-white rounded-xl border border-contrast-300 overflow-hidden'>
      <div className='flex flex flex-col px-6 py-2 border border-gray-200  divide-gray-200 border-contrast-300 sm:py-4 sm:flex-row sm:items-center'>
        <h4 className='text-sm xs:text-base font-bold text-contrast-900 flex-1'>
          Guest Details
        </h4>
        <h4 className='text-sm gap-10 flex items-center font-medium text-contrast-900 xs:text-base sm:gap-3'>
          <span className='text-sm xs:text-base font-bold text-contrast-700  mr-4'>
            No Of Room(s): {passengersData.length}
          </span>
        </h4>
      </div>
      <div className='flex flex-col'>
        {passengersData.map(
          (room, index) =>
            room.hotelPassenger &&
            room.hotelPassenger.length > 0 && (
              <RoomSection key={index} room={room} roomTypeName={room.roomTypeName} index={index} />
            )
        )}
      </div>
    </div>
  );
};

const RoomSection = ({ room, index, roomTypeName}) => {
  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: 200,
    defaultExpanded: false,
  });

  const passengerType = (paxType) => {
    return paxType === TWO ? CHILD : ADULT;
  };

  const getPaxGender = (title) => {
    return title === MR ? MALE : FEMALE;
  };

  return (
    <div className='px-4 gap-2 py-2'>
      <div className='border rounded-sm border-gray-200 divide-y'>
        <div className='flex justify-between items-center text-contrast-700 px-2 py-1 sm:px-6'>
          <h5 className='text-xs sm:text-sm font-bold '>Room {index + 1} - {roomTypeName} </h5>
          <button className='p-2 cursor-pointer shrink-0' {...getToggleProps()}>
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              className='text-contrast-800'
              width='20'
            />
          </button>
        </div>
        <div {...getCollapseProps()}>
          {room.hotelPassenger.map(
            ({
              id,
              title = EMPTY_STRING,
              firstName = EMPTY_STRING,
              lastName = EMPTY_STRING,
              paxType = ONE,
            }) => (
              <div key = {id}>
                <div className='flex items-center flex-wrap gap-2 justify-between px-2 py-1 gap-1 sm:px-6 sm:gap-4'>
                  <h6 className='text-[10px] sm:text-xs font-bold text-contrast-600'>
                    {title} {firstName} {lastName}
                  </h6>
                  <span className='text-[9px] sm:text-[11px] font-semibold text-primary-800 bg-primary-100 px-3 py-1 rounded-3xl capitalize'>
                    {passengerType(paxType)} - {getPaxGender(title)}
                  </span>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(HotelPassengersInfo);
