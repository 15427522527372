import { v4 as uuid } from "uuid";
import { get } from "lodash";
import { ArrowLeft, ArrowRoundTrip, RenderSVG } from "../../../assets/icons";
import { DEFAULT_VALUES, INDEX } from "../../../constants";
import { getFormattedArrivalAndDepartureDate } from "../../../helper";
import { useSelector } from "react-redux";
import { selectTenantDetails } from "../../../screens/Auth";

const { ZERO } = DEFAULT_VALUES;
const { LAST } = INDEX;

const JourneySummary = ({ journey = [] }) => {
  const tenantDetails = useSelector(selectTenantDetails);
  const tenantConfig = get(tenantDetails, "tenantConfig");

  const lastJourney = journey.at(LAST);
  const { destCode = {}, returnDate } = lastJourney;

  const journeyCities = journey
    .map((journey) => journey.originCode.cityName)
    .concat(destCode.cityName);

  const { departureDate } = journey[ZERO];

  return (
    <div className='flex flex-center 2xl:gap-4 gap-2 me-auto 2xl:text-lg lg:text-md text-sm text-contrast-900'>
      {journeyCities.map(
        (city, index) =>
          city && (
            <span key={uuid()} className='flex items-center'>
              {index !== ZERO && (
                <RenderSVG
                  Svg={returnDate ? ArrowRoundTrip : ArrowLeft}
                  width={returnDate ? "14" : "18"}
                  height={returnDate ? "14" : "18"}
                  alt='Left Arrow'
                  className='mr-3 text-contrast-900'
                  stroke={tenantConfig.contrast}
                />
              )}
              <span>{city}</span>
            </span>
          )
      )}
      <span>•</span>
      <span className='flex flex-row items-center text-sm lg:text-base font-normal text-nowrap'>
        {getFormattedArrivalAndDepartureDate(departureDate)}{" "}
        {returnDate && (
          <span className='ml-1'>
            {" "}
            - {getFormattedArrivalAndDepartureDate(returnDate)}
          </span>
        )}
      </span>
    </div>
  );
};

export default JourneySummary;
