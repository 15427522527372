import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { get, cloneDeep, isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { FlightConfirmationPropTypes } from "../../../prop-types";
import {
  FlightInfo,
  TravelersInfo,
  WebCheckinInfo,
  AirlineContactInfo,
  PaymentInfo,
} from "../../../components/organisms/FlightBookingInfoSections";
import {
  CheckCircleIcon,
  InvoiceIcon,
  RenderSVG,
  TicketOutline,
  TicketSlash,
} from "../../../assets/icons";
import {
  DEFAULT_VALUES,
  ROUTES,
  TRIP_TYPES,
  BOOKING_STATUS_CODES,
} from "../../../constants";
import config from "../../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { downloadEticket } from "../Trips";
import { useState } from "react";
import Spinner, {
  SPINNER_NAMES,
  selectActiveSpinners,
} from "../../../components/organisms/Spinner";
import { CancellationModalSkeleton } from "../../../components/organisms/AppSkeletons";
import { ticketDownlaod, getFormattedDate } from "../../../helper";
import { sendETicketEmail } from "../Trips/trips.actions";

const { EMPTY_ARRAY, EMPTY_STRING } = DEFAULT_VALUES;
const { USER_BOOKINGS } = ROUTES;
const { ROUND_TRIP } = TRIP_TYPES;
const { CONFIRMED, PARTIALLY_CONFIRMED, PARTIALLY_CANCELLED } = BOOKING_STATUS_CODES;
const { logo } = config;
const { DOWNLOAD_TICKET, EMAIL_ETICKET } = SPINNER_NAMES;

const FlightConfirmation = ({
  flightsInfo,
  bookingId,
  pnrList = EMPTY_ARRAY,
  flightBookingStatus,
  isSpinnerActive,
  barcodeDetails = EMPTY_ARRAY,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isEticketSend, setIsEticketSend] = useState(false);
  const activeSpinners = useSelector(selectActiveSpinners);
  const [isTicketDownloaded, setIsTicketDownloaded] = useState(false);

  const downloadETicketSpinner = activeSpinners.some(
    (spinnerName) => spinnerName === `${DOWNLOAD_TICKET}_${bookingId}`
  );
  const sendEticketSpinner = activeSpinners.some(
    (spinnerName) => spinnerName === EMAIL_ETICKET
  );

  const itineraries = flightsInfo?.reduce((itineraryAcc, flight) => {
    const { reissuedBookings = EMPTY_ARRAY } = flight;
    const flightData = isEmpty(reissuedBookings) ? flight : get(reissuedBookings, "[0]");
    const itinerary = get(
      flightData,
      "bookingJson.journeyDetails[0].itineraries",
      []
    );
    return [...itineraryAcc, ...itinerary];
  }, []);
  const journeyDetails = flightsInfo?.map((flightInfo) => {
    const { reissuedBookings = EMPTY_ARRAY } = flightInfo;
    const flightData = isEmpty(reissuedBookings) ? flightInfo : get(reissuedBookings, "[0]");
    return get(flightData, "bookingJson.journeyDetails[0]")
  }
  );
  const travelersData =
    journeyDetails && get(journeyDetails[0], "travelerDetails", EMPTY_ARRAY);
  const tripType = get(flightsInfo, "[0].journeyType", ROUND_TRIP);
  const travelerDetails = cloneDeep(travelersData);
  const updatedTravelerDetails = travelerDetails?.map((traveler) => {
    const matchingBarcode = barcodeDetails.find(
      (barcode) =>
        traveler.givenName?.toLowerCase() ===
          barcode.firstName?.toLowerCase() &&
        traveler.dateOfBirth === getFormattedDate(barcode.dateOfBirth)
    );
    if (matchingBarcode) {
      return {
        ...traveler,
        barcodeInBase64: get(matchingBarcode, "barcodeInBase64", EMPTY_STRING),
      };
    }
    return traveler;
  });

  const hasPNR = pnrList.some((pnr) => pnr !== null);
  const isFlightBookingSuccessful =
    flightBookingStatus === CONFIRMED ||
    flightBookingStatus === PARTIALLY_CONFIRMED ||
    flightBookingStatus === PARTIALLY_CANCELLED;

  const bookingActions = [
    {
      id: "cancelTicket",
      label: "bookingResult.actions.cancelTicket",
      icon: TicketSlash,
      handleClick: () => navigate(USER_BOOKINGS),
    },
  ];

  return (
    <div className='relative z-0'>
      <Tooltip
        id='comingSoon-tooltip'
        className='!w-56 !sm:w-72 !z-50 !bg-primary-600 !rounded-lg text-center'
      />
      <div className='py-16 relative'>
        <div className='container px-8 mx-auto -mt-12'>
          <div className='grid grid-cols-12 gap-8 '>
            <div className='col-span-12 xl:col-span-8 flex flex-col gap-4'>
              <div className='flex flex-col gap-8'>
                <div className='bg-white rounded-lg border border-dashed border-contrast-300 shadow-sm'>
                  <div className='flex flex-wrap px-4 py-3 border-b border-dashed border-contrast-300 justify-between'>
                    <h4 className='text-contrast-900 font-bold text-sm'>
                      {t(`searchSection.flightTripTypes.${tripType}`)}{" "}
                      {t("bookingResult.flight")}
                    </h4>
                    <p className='text-contrast-800 font-semibold text-xs flex gap-2 items-center'>
                      <span>{t("bookingResult.bookingId")}</span>
                      <span className='text-primary-700 bg-primary-100 px-2 py-0.5 rounded-2xl'>
                        {bookingId || EMPTY_STRING}
                      </span>
                    </p>
                  </div>
                  {itineraries?.map((itinerary, index) => (
                    <FlightInfo
                      key={index}
                      pnrList={pnrList}
                      itinerary={itinerary}
                      index={index}
                    />
                  ))}
                </div>
              </div>

              <PaymentInfo journeyDetails={journeyDetails} />
              <TravelersInfo travelersData={updatedTravelerDetails} />
              {isFlightBookingSuccessful  && !isSpinnerActive && (
                <WebCheckinInfo
                  itineraries={itineraries}
                  pnrList={pnrList}
                  tripType={tripType}
                  hasPNR={hasPNR}
                />
              )}

              <div className='rounded-lg border border-contrast-300 shadow-sm overflow-hidden'>
                <div className='px-6 py-4 border-b border-contrast-300 bg-contrast-50'>
                  <h4 className='text-base font-bold text-contrast-900 flex-1'>
                    {t("bookingResult.importantInfo")}
                  </h4>
                </div>
                <div className='py-4 px-6 flex flex-col gap-6'>
                  <div className=''>
                    <h4 className='font-bold text-contrast-900 mb-2 text-sm'>
                      {t("bookingResult.checkList")}
                    </h4>
                    <ul className='list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1'>
                      <li>{t("bookingResult.checkListPoints.point1")}</li>
                      <li>{t("bookingResult.checkListPoints.point2")}</li>
                      <li>{t("bookingResult.checkListPoints.point3")}</li>
                    </ul>
                  </div>

                  <div className=''>
                    <h4 className='font-bold text-contrast-900 mb-2 text-sm'>
                      {t("bookingResult.checkinAndBoarding")}
                    </h4>
                    <ul className='list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1'>
                      <li>{t("bookingResult.boardingPoints.point1")}</li>
                      <li>{t("bookingResult.boardingPoints.point2")}</li>
                    </ul>
                  </div>

                  <div className=''>
                    <h4 className='font-bold text-contrast-900 mb-2 text-sm'>
                      {t("bookingResult.kindAttention")}
                    </h4>
                    <ul className='list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1'>
                      <li>{t("bookingResult.attentionPoints.point1")}</li>
                      <li>{t("bookingResult.attentionPoints.point2")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-span-12 xl:col-span-4'>
              {/* For now commented for b2b */}
              {/* <div className='border border-contrast-300 rounded-lg mb-6 bg-white print:hidden'>
								<ul className='flex flex-col text-sm divide-y'>
									<li className='flex items-center gap-2 px-4 py-3'>
										<h4 className='font-bold text-base text-contrast-900'>
											{t('bookingResult.manageTrip')}
										</h4>
									</li>
									{bookingActions.map(({ id, icon, label, handleClick }) => (
										<li
											key={id}
											className='flex items-center gap-2 px-4 py-3 border-0 border-t border-solid border-contrast-300'
										>
											<button
												className='flex gap-2 items-center text-sm font-medium text-primary-600'
												onClick={handleClick}
												data-tooltip-id='comingSoon-tooltip'
												data-tooltip-content={t('bookingResult.comingSoon')}
												disabled
											>
												<div className='icon'>
													<RenderSVG Svg={icon} height='20' />
												</div>
												<span>{t(label)}</span>
											</button>
										</li>
									))}

									<li className='flex items-center gap-2 px-4 py-3 bg-primary-100/50 border-0 border-t border-contrast-300 border-solid'>
										<button
											className='py-3 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium w-full disabled:cursor-not-allowed'
											disabled={isSpinnerActive}
											onClick={() => navigate(USER_BOOKINGS)}
										>
											{t('bookingResult.gotoMyTrips')}
										</button>
									</li>
								</ul>
							</div> */}

              {isFlightBookingSuccessful && !isSpinnerActive && (
                <div className='border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6 print:hidden'>
                  <div className='p-6'>
                    <h4 className='font-bold text-contrast-900 text-base mb-5'>
                      {t("bookingResult.tickets")}
                    </h4>
                    <ul className='flex flex-col gap-3'>
                      <li>
                        <button
                          className='flex gap-2 items-center text-sm font-medium text-primary-600 disabled:cursor-not-allowed'
                          onClick={() => window.print()}
                          disabled={isSpinnerActive}
                        >
                          <div className='icon'>
                            <RenderSVG Svg={TicketOutline} width='20' />
                          </div>
                          <span>{t("bookingResult.downloadTicket")}</span>
                        </button>
                      </li>
                      {/* Commented for now for b2b */}
                      {/* <li className=''>
											<button
												className='flex gap-2 items-center text-sm font-medium text-primary-600 cursor-pointer'
												data-tooltip-id='comingSoon-tooltip'
												data-tooltip-content={t('bookingResult.comingSoon')}
												disabled
											>
												<div className='icon'>
													<RenderSVG
														Svg={InvoiceIcon}
														width='20'
														className='text-white'
													/>
												</div>
												<span>{t('bookingResult.emailTicket')}</span>
											</button>
										</li> */}
                    </ul>
                  </div>
                </div>
              )}
              {isFlightBookingSuccessful && !isSpinnerActive && (
                <div className='border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6 print:hidden'>
                  <div className='p-6'>
                    <h4 className='font-bold text-contrast-900 text-base mb-5'>
                      Download Ticket
                    </h4>
                    <ul className='flex flex-col gap-3'>
                      <li>
                        <button
                          className='flex gap-2 items-center text-sm font-medium text-primary-600 disabled:cursor-not-allowed'
                          onClick={() => {
                            dispatch(
                              downloadEticket({
                                params: {
                                  flight: true,
                                  hotel: false,
                                },
                                tavaBookingId: bookingId,
                              })
                            ).then((res) => {
                              if (res) {
                                const flightContent =
                                  res?.payload?.output?.htmlContent?.flightPdf;
                                if (flightContent) {
                                  ticketDownlaod(flightContent, bookingId);
                                  setIsTicketDownloaded(true);
                                }
                              }
                            });
                          }}
                          disabled={downloadETicketSpinner}
                        >
                          <div className='icon'>
                            <RenderSVG Svg={TicketOutline} width='20' />
                          </div>
                          <span>Download Ticket</span>

                          <Spinner name={DOWNLOAD_TICKET}>
                            {isTicketDownloaded && !downloadETicketSpinner && (
                              <div>
                                <RenderSVG Svg={CheckCircleIcon} width='20' />
                              </div>
                            )}
                          </Spinner>
                        </button>
                      </li>
                      <li>
                        <button
                          className='flex gap-2 items-center text-sm font-medium text-primary-600 cursor-pointer'
                          onClick={() => {
                            dispatch(
                              sendETicketEmail({
                                params: {
                                  flight: true,
                                  hotel: false,
                                },
                                tavaBookingId: bookingId,
                              })
                            ).then(() => {
                              setIsEticketSend(true);
                            })
                          }}
                          disabled={sendEticketSpinner}
                        >
                          <div className='icon'>
                            <RenderSVG Svg={TicketOutline} width='20' />
                          </div>
                          <span>{t("bookingResult.emailTicket")}</span>
                          <Spinner
                            name={EMAIL_ETICKET}
                          >
                            {isEticketSend && !sendEticketSpinner && (
                              <div>
                                <RenderSVG Svg={CheckCircleIcon} width='20' />
                              </div>
                            )}
                          </Spinner>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <AirlineContactInfo itineraries={itineraries} />
              <div className='border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6'>
                <div className='p-6'>
                  <h4 className='font-bold text-contrast-900 text-base mb-5'>
                    {t("bookingResult.contactUs")}
                  </h4>
                  <div className='flex items-center gap-3 '>
                    <div className='logo'>
                      <img
                        src={logo}
                        width='30'
                        className='rounded border border-contrast-300'
                        alt='tavatrip logo'
                      />
                    </div>
                    <div className='flex-1'>
                      <p className='text-xs text-contrast-600 mb-1'>
                        {t("bookingResult.customerSupport")}
                      </p>
                      <h5 className='text-sm font-semibold text-primary-600 underline'>
                        <a target='_blank' href='mailto:support@tavatrip.com'>
                          support@tavatrip.com
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FlightConfirmation.propTypes = FlightConfirmationPropTypes;

export default FlightConfirmation;
