import React, { useState } from "react";
import { Formik, Form } from "formik";
import MultiSelectField from "../../components/molecules/MultiSelectField/MultiSelectField";
import { useDispatch, useSelector } from "react-redux";
import { selectPermissions, setSelectedRolesPermissions } from ".";
import { toast } from "react-toastify";
import RenderEditedPermissions from "./RenderEditedPermissions";
import { get } from "lodash";
import { DEFAULT_VALUES } from "../../constants";
import { mapUpdatePermissionsRequest } from "../../helper/mapUpdatePermissionsRequest";
import { updateRolesPermission } from "./permissions.actions";
import { selectCurrentUserInfo } from "../Auth";
import Spinner from "../../components/organisms/Spinner";
import { selectRolesPermissionsAssociationData } from "./permissions.selector";

const { ZERO } = DEFAULT_VALUES;

const findAddedAndRevokedPermissions = (
  initial,
  updatedNames,
  allPermissions
) => {
  const initialPermissionSet = new Set(
    initial.map((permission) => permission.name)
  );

  const addedPermissions = updatedNames
    .filter((permissionName) => !initialPermissionSet.has(permissionName))
    .map(
      (permissionName) =>
        allPermissions.find((p) => p.name === permissionName)?.id
    );

  const revokedPermissions = initial
    .filter((permission) => !updatedNames.includes(permission.name))
    .map((permission) => permission.permissionId);

  return { addedPermissions, revokedPermissions };
};

const generatePermissionsArray = (allPermissions, permissionNames) => {
  return permissionNames.map((name, index) => {
    const permission = allPermissions.find((p) => p.name === name);
    return {
      permissionId: permission.id,
      name: permission.name,
    };
  });
};

const PermissionRow = ({ rolePermissionsAssociationData }) => {
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  const selectedCurrentUserInfo = useSelector(selectCurrentUserInfo);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);

  const tenantId = get(selectedCurrentUserInfo, "tenantId", "");

  const roleAssociationPermissions = get(
    rolePermissionsAssociationData,
    "permissions",
    []
  );
  const roleName = get(rolePermissionsAssociationData, "name", "");
  const roleId = get(rolePermissionsAssociationData, "id", ZERO);
  const tenantRolesPermissions = roleAssociationPermissions.map(
    (permission) => permission.name
  );

  const allPermissions = permissions.map((permission) => ({
    name: permission.name,
    id: permission.id,
  }));

  const handleAddPermissions = (values, { resetForm }) => {
    const { addedPermissions, revokedPermissions } =
      findAddedAndRevokedPermissions(
        roleAssociationPermissions,
        values?.permission,
        allPermissions
      );
    const updatePermissionReq = mapUpdatePermissionsRequest(
      roleId,
      addedPermissions,
      revokedPermissions
    );
    const queryParams = { tenantId, roleId };
    dispatch(updateRolesPermission({ updatePermissionReq, queryParams })).then(
      (res) => {
        if (res.payload) {
          const updatedPermissions = generatePermissionsArray(
            allPermissions,
            values?.permission
          );
          const req = { roleId, updatedPermissions };
          dispatch(setSelectedRolesPermissions(req));
          toast.success(`Permissions were updated successfully!`);
        }
      }
    );
    resetForm();
  };

  return (
    <tr>
      <td className='border border-contrast-200 px-4 py-4'>
        <div className='text-contrast-900 text-sm'>{roleName}</div>
      </td>
      <td className='border border-contrast-200 px-4 pt-4'>
        <Formik
          initialValues={{ permission: tenantRolesPermissions }}
          onSubmit={handleAddPermissions}
          enableReinitialize
        >
          {({ values, dirty }) => (
            <Form className='w-full text-contrast-500'>
              <div className='flex w-full gap-4'>
                <div className='w-full'>
                  <MultiSelectField
                    name='permission'
                    selectableValues={allPermissions}
                    initialSelectedValues={tenantRolesPermissions}
                    placeholder='Select Permission'
                  />
                  <div className='flex flex-wrap pb-2'>
                    <RenderEditedPermissions
                      tenantRolesPermissions={tenantRolesPermissions}
                      values={values}
                    />
                  </div>
                </div>
                <div className='mt-1'>
                  <button
                    type='submit'
                    className='rounded-md shadow-sm px-4 py-2 bg-primary-600 hover:bg-primary-700 active:bg-primary-600 text-white text-sm leading-5 h-10 disabled:cursor-not-allowed '
                    disabled={isSpinnerActive || !dirty}
                  >
                    <Spinner
                      name={`Update_${roleId}`}
                      setIsSpinnerActive={setIsSpinnerActive}
                    >
                      Save
                    </Spinner>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </td>
    </tr>
  );
};

export default PermissionRow;
