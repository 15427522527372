const ProgressBar = (props) => {
  const { completed, name, count } = props;

  const fillerStyles = {
    width: `${completed}%`,
    borderRadius: 'inherit',
    textAlign: 'right',
  };

  return (
    <div>
      <div className='text-sm'>{name}</div>
      <div className='flex justify-between mb-2'>
        <div className='h-2 w-[80%] bg-contrast-200 rounded-xl m-auto'>
          <div style={fillerStyles} className='h-full bg-primary-600'></div>
        </div>
        <div className='w-[20%] text-sm pl-2'>{count}</div>
      </div>
    </div>
  );
};

export default ProgressBar;
