import { get, isEmpty } from "lodash";
import {
  DEFAULT_VALUES,
  FLIGHT_SERVICE_TYPE,
  SORTING_HEADERS,
} from "../constants";
import { getLayoverDuration } from "./getLayoverDuration";

const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { TBO } = FLIGHT_SERVICE_TYPE;
const { cheapest, nonStopFirst, fastest, best, arrival, departure } =
  SORTING_HEADERS;

const getMinutesFromDurationString = (duration) => {
  const [hoursString, minutesString] = duration.split(":");
  return Number(hoursString) * 60 + Number(minutesString);
};

const getFlightDuration = (flight) => {
  const source = get(flight, "source", EMPTY_STRING);
  let layoverDuration = [];
  if (source === TBO)
    layoverDuration = getLayoverDuration(flight.itineraries[ZERO].segments);
  let flightDuration = flight.itineraries.reduce(
    (totalDuration, itinerary) =>
      totalDuration + getMinutesFromDurationString(itinerary.duration),
    ZERO
  );
  if (!isEmpty(layoverDuration)) {
    const layoverMinutes = layoverDuration.reduce((total, duration) => {
      let [hours, minutes] = duration.split(" ");
      if (hours?.includes("M")) minutes = hours;
      const hoursInMinutes = hours?.includes("H")
        ? parseInt(hours, 10) * 60
        : ZERO;
      const updatedMinutes = minutes?.includes("M")
        ? parseInt(minutes, 10)
        : ZERO;
      return total + hoursInMinutes + updatedMinutes;
    }, ZERO);
    flightDuration += layoverMinutes;
  }
  return flightDuration;
};

const getSortedFlight = (flight, key) => {
  switch (key) {
    case cheapest.name:
      return flight.price.totalPrice + flight.price.tavaMarkup;
    case fastest.name:
      return getFlightDuration(flight);
    case nonStopFirst.name:
      return flight.itineraries.reduce(
        (totalStops, itinerary) => totalStops + itinerary.segments.length,
        ZERO
      );
    case departure.name:
      return new Date(
        flight.itineraries[ZERO].segments[ZERO].departure.date +
          " " +
          flight.itineraries[ZERO].segments[ZERO].departure.time
      );
    case arrival.name:
      return new Date(
        flight.itineraries[ZERO].segments[
          flight.itineraries[ZERO].segments.length - ONE
        ].arrival.date +
          " " +
          flight.itineraries[ZERO].segments[
            flight.itineraries[ZERO].segments.length - ONE
          ].arrival.time
      );
  }
};

const sortingFunction = (sortedFlights, sortingKey) =>
  sortedFlights &&
  [...sortedFlights].sort((currentFlight, nextFlight) => {
    let currentFlightValue, nextFlightValue;
    if (sortingKey !== best.name) {
      currentFlightValue = getSortedFlight(currentFlight[ZERO], sortingKey);
      nextFlightValue = getSortedFlight(nextFlight[ZERO], sortingKey);
    } else {
      if (
        currentFlight[ZERO].price.totalPrice + currentFlight[ZERO].price.tavaMarkup  !==
        nextFlight[ZERO].price.totalPrice + nextFlight[ZERO].price.tavaMarkup
      ) {
        currentFlightValue = getSortedFlight(
          currentFlight[ZERO],
          cheapest.name
        );
        nextFlightValue = getSortedFlight(nextFlight[ZERO], cheapest.name);
      } else {
        currentFlightValue = getSortedFlight(currentFlight[ZERO], fastest.name);
        nextFlightValue = getSortedFlight(nextFlight[ZERO], fastest.name);
      }
    }
    return currentFlightValue - nextFlightValue;
  });

const getSortedFlights = (sortedFlights = [], name = "") =>
  sortingFunction(sortedFlights, name);

export default getSortedFlights;
