import { useEffect, useState } from "react";
import { get } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { DEFAULT_VALUES, DEFAULT_CURRENCY_CODE } from "../../../../constants";
import { closeRazorPayModal } from "../../../../helper";
import {
  selectPaymentAPIResponse,
  setBookCallbackResponse,
  initiateBookCallback,
  selectSelectedTripId,
} from "../../Trips";
import { selectUserInfo } from "../../../Profile";
import { selectCurrentUserInfo, selectTenantDetails } from "../../../Auth";
import { updatePaymentStatus } from "../../Trips/index"

const { EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;

const FLIGHTS_PAYMENT_MODAL_TITLE = "TavaTrip";
const MOCK_SESSION_ID = "MOCK_SESSION_ID";
const PAYMENT_CONFIRM = "confirm";

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const tenantDetails = useSelector(selectTenantDetails);
  const userInfo = useSelector(selectUserInfo);
  const paymentInformation = useSelector(selectPaymentAPIResponse);
  const selectedTripId = useSelector(selectSelectedTripId);
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const [razorpayInstance, setRazorpayInstance] = useState();

  const tenantConfig = get(tenantDetails, "tenantConfig");
  const currency = get(userInfo, "tenant.currency", DEFAULT_CURRENCY_CODE);
  const { brandName } = tenantConfig;
  const {
    successURL = EMPTY_STRING,
    abortURL = EMPTY_STRING,
    callbackURL = EMPTY_STRING,
    payload = EMPTY_OBJECT,
    traveler = EMPTY_OBJECT,
    description = EMPTY_STRING,
  } = state;
  const {
    email = EMPTY_STRING,
    contact = EMPTY_STRING,
    name = EMPTY_STRING,
  } = traveler || EMPTY_OBJECT;
  const { id, orderId, paymentMethod } = paymentInformation || EMPTY_OBJECT;

  const handlePayment = () => {
    const callbackPayload = {
      paymentSessionId: id,
      paymentMethod,
      currency,
      ...payload,
    };

    dispatch(setBookCallbackResponse(EMPTY_OBJECT));
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      name: FLIGHTS_PAYMENT_MODAL_TITLE,
      currency,
      order_id: orderId,
      description,
      amount: payload.amount,
      handler: (e) => {
        const paymentId = e.razorpay_payment_id;
        const callbackRequest = {
          requestBody: { ...callbackPayload, paymentId },
          url: callbackURL,
        };
        dispatch(initiateBookCallback(callbackRequest));
        navigate(successURL);
      },
      modal: {
        ondismiss: () => {
          // TODO: Need to handle the onDismiss callback.
          dispatch(setBookCallbackResponse(EMPTY_OBJECT));
          navigate(successURL);
          dispatch(updatePaymentStatus({ bookingId: selectedTripId }))
        },
      },
      prefill: {
        email,
        name,
        contact,
      },
    };

    const rp = new window.Razorpay(options);
    setRazorpayInstance(rp);
    document.body.style.overflow = "visible";
    rp.on("payment.failed", () => {
      dispatch(updatePaymentStatus({ bookingId: selectedTripId }))
      if (window.location.pathname.includes(`/users/${currentUserInfo?.id}/bookings`)) {
        navigate(`/users/${currentUserInfo?.id}/bookings/${selectedTripId}/payment/confirm`);
      } else {
        navigate(`/bookings/${selectedTripId}/payment/confirm`);
      }
      closeRazorPayModal(rp);
      
    });
  };

  useEffect(() => {
    if (!orderId) navigate(abortURL);
    if (id === MOCK_SESSION_ID) navigate(PAYMENT_CONFIRM);
    else handlePayment();
  }, [orderId]);

  useEffect(() => {
    if (razorpayInstance) razorpayInstance.open();

    return () => {
      if (razorpayInstance) closeRazorPayModal(razorpayInstance);
    };
  }, [razorpayInstance]);

  return (
    <Helmet>
      <title>{brandName} | Trip Payment</title>
    </Helmet>
  );
};

export default Payment;
