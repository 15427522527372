import http from "../httpMethods";
import { get, isEmpty } from "lodash";
import { toast } from "react-toastify";
import {
  addSpinner,
  removeSpinner,
  SPINNER_NAMES,
} from "../../components/organisms/Spinner";
import { setSelectedModal } from "../../components/organisms/Modal";
import {
  getCorrelationId,
  checkForSuccessResponse,
  getFromLocalStorage,
  generateReferrerID,
  checkTokenExpiry,
  signout,
} from "../../helper";
import { MODALS } from "../../components/organisms/AppModals/modals.constants";
import {
  PROMISES,
  AUTH_DATA,
  HTTPS_STATUS_CODES,
  DEFAULT_VALUES,
} from "../../constants";
import { captureMessage } from "@sentry/react";
import { removeAwaitedRequests } from "../awaitedRequests";
import { regenerateAccessToken } from "../../screens/Auth";

const { EMPTY_OBJECT, TWO_THOUSAND } = DEFAULT_VALUES;
const { API_FAILURE_MODAL } = MODALS;
const { PENDING } = SPINNER_NAMES;
const { CANCELLED } = PROMISES;
const { AUTH } = AUTH_DATA;
const { GONE, BAD_REQUEST } = HTTPS_STATUS_CODES;
const USERS = "users";
const TRAVELERS = "travelers";
const LOGIN = "login";
const SIGN_UP = "sign-up";
const RESET_PASSWORD_BY_OTP = "reset-password-by-otp";
const FORGOT_PASSWORD = "forgot-password";
const BOOK = "book";
const INVITATION = "invitation";
const duplicateBookingMessage =
  "Unable to complete booking! It appears that a booking with same details already exists. Please try again with other dates or traveler.";

export const handleException = (exception, methodType, url) => {
  console.log(
    `Unable to process this ${methodType} request
      for end-point ${url}
      Failed with response:`,
    exception.status
  );
};

export const createHeader = (httpHeaders, shouldAllowCustomHeaders) => {
  const requestOptions = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(shouldAllowCustomHeaders && { "X-Request-Id": getCorrelationId() }),
      ...(shouldAllowCustomHeaders && { "X-Referer": generateReferrerID() }),
    },
  };

  return httpHeaders
    ? {
        headers: {
          ...requestOptions.headers,
          ...httpHeaders.headers,
        },
      }
    : requestOptions;
};

const exceptionMessage = (url) => {
  switch (url.split("/")[0]) {
    case USERS:
    case TRAVELERS:
      return "response.data";
    case LOGIN:
       return "response.data";
    case SIGN_UP:
    case FORGOT_PASSWORD:
    case RESET_PASSWORD_BY_OTP:
    case INVITATION:
      return "response.data.message";
    default:
      return "response.data";
  }
};

const getExceptionCodePath = (url) => {
  switch (url.split("/")[1]) {
    case BOOK:
      return "response.data.statusCode";
    default:
      return "response.data.errors[0].statusCode";
  }
};

const asyncAction = async ({
  url,
  methodType = "get",
  httpHeaders = {},
  body = {},
  spinner,
  dispatch,
  errorMessage,
  showErrorToast = false,
  source,
  isAuthRequired = false,
  abortOnPageChange = true,
  getState,
  shouldAllowCustomHeaders = true,
  abortAwaitingRequests = false,
} = {}) => {
  const { auth } = getState();

  const { authInfo, currentUserInfo } = auth ;
  const { accessToken: backendFetchedAccessToken } = authInfo || currentUserInfo || EMPTY_OBJECT;

  const authData = getFromLocalStorage(AUTH);
  let { accessToken: locallyStoredAccessToken, refreshToken } =
    authData || EMPTY_OBJECT;

  let userAccessToken = backendFetchedAccessToken || locallyStoredAccessToken;

  // Commenting to handle session expiry code

  // verifing is auth tokens valid
  if (isAuthRequired) {
    if(!userAccessToken) return
    const isRefreshTokenInvalid = checkTokenExpiry(refreshToken);
    if (isRefreshTokenInvalid) {
      signout(TWO_THOUSAND);
      return;
    }

    const shouldRefreshAccessToken = checkTokenExpiry(userAccessToken);
    if (shouldRefreshAccessToken) {
      const res = await dispatch(
        regenerateAccessToken({ refreshToken, spinner })
      );

      if (!res.payload) {
        toast.error("Session Expired, Please login again.");
        signout(TWO_THOUSAND);
      } else {
        userAccessToken = get(res, "payload.output.response.token.accessToken");
      }
    }
  }

  httpHeaders = {
    ...httpHeaders,
    ...createHeader(httpHeaders, shouldAllowCustomHeaders),
  };

  if (isAuthRequired)
    httpHeaders.headers.Authorization = `Bearer ${backendFetchedAccessToken}`;

  if (spinner) {
    dispatch(addSpinner(spinner));
    dispatch(removeSpinner(PENDING));
  }

  return await http(
    methodType,
    url,
    body,
    httpHeaders,
    abortOnPageChange,
    abortAwaitingRequests
  )
    .then((response) => {
      spinner && dispatch(removeSpinner(spinner));
      const successResponse = checkForSuccessResponse(response, source, url);
      if (isEmpty(successResponse))
        dispatch(setSelectedModal(API_FAILURE_MODAL));
      return Promise.resolve(response);
    })
    .catch((exception) => {
      const responseOfStatusMessage = exception.message;
      spinner && dispatch(removeSpinner(spinner));
      if (exception.response.status === GONE)
        return Promise.resolve(exception.response); // Handled Session Expired Senarios.
      if (exception.status !== CANCELLED) {
        const exceptionPath = exceptionMessage(url);
        const exceptionCodePath = getExceptionCodePath(url);
        const exceptionCode = Number(get(exception, exceptionCodePath, 0));
        const apiErrorMessage = get(exception, exceptionPath, errorMessage);
        toast.error(apiErrorMessage.data)
        if (url.split("/")[1] === BOOK && exceptionCode === BAD_REQUEST)
          showErrorToast && toast.error(duplicateBookingMessage);
        else apiErrorMessage && showErrorToast && toast.error(apiErrorMessage)
        const { password, ...updatedBody } = body;
        const detailedErrorInfo = { url, updatedBody, exception };
        captureMessage(
          `${apiErrorMessage}\n${JSON.stringify(detailedErrorInfo)}`
        );

        handleException(exception, methodType, url);
      }
      if (url.includes("price"))
        dispatch(setSelectedModal(API_FAILURE_MODAL));
      throw new Error( responseOfStatusMessage || "Network Error");
    })
    .then((response) => {
      removeAwaitedRequests(url);
      return response;
    });
};

export default asyncAction;
