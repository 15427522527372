import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectHotelSearchFilters } from "../../components/organisms/Search";

const NoHotelResults = ({ totalHotels }) => {
  const { t } = useTranslation();
  const hotelSearchFilters = useSelector(selectHotelSearchFilters);
  const { hotelCode = "" } = hotelSearchFilters || {};

  return totalHotels ? (
    <div className='bg-white rounded-md flex flex-col gap-3 min-h-[50vh] items-center justify-center'>
      <div className='text-primary-700 text-4xl mb-4 font-semibold uppercase leading-tight tracking-tight'>
        {t("hotelResults.tooManyFilters")}
      </div>
      <div className='text-contrast-900 text-md font-semibold'>
        {t("hotelResults.removeFilters")}
      </div>
    </div>
  ) : (
    <div className='bg-white rounded-md flex flex-col gap-3 min-h-[50vh] items-center justify-center'>
      <div className='text-primary-600 font-semibold uppercase leading-tight tracking-tight'>
        {hotelCode ? "No Rooms Available" : t("hotelResults.noHotels")}
      </div>
      <div className='text-contrast-700 text-base text-md font-semibold'>
        {t("hotelResults.tryAgain")}
      </div>
    </div>
  );
};

export default NoHotelResults;
