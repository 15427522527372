import { DEFAULT_VALUES } from "../constants";
const { ZERO } = DEFAULT_VALUES;

export const getSSRPrice = (data) =>
  Object.values(data).reduce(
    (acc, flights) =>
      acc +
      flights.reduce((flightAcc, { price = 0 }) => flightAcc + price, ZERO),
    ZERO
  );
