import { get, isEmpty } from "lodash";
import {
  RenderSVG,
  FlightIcon,
  HotelsIcon
} from "../../assets/icons";
import ProgressBar from "../../components/molecules/ProgressBar";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { FrequentlyUsedSkeleton } from "../../components/organisms/AppSkeletons/DashboardSkeleton";
import { SEARCH_SECTION } from "../../constants";

const { ANALYTICS_FREQ_USED_AIRLINES, ANALYTICS_FREQ_USED_HOTELS } = SPINNER_NAMES;
const { FLIGHT } = SEARCH_SECTION;

const FrequentlyUsedAnalytics = ({ frequentlyUsedData, type }) => {
    const targetField = type === FLIGHT ? 'frequentAirlines' : 'frequentHotels';
    const totalField = type === FLIGHT ? 'totalFlightBookings' : 'totalHotelBookings';

    return (
      <>
        <div className='w-full h-full'>
          <div className='flex flex-col h-full border border-contrast-300 rounded-lg bg-white'>
            <div className='items-start grid grid-cols-5 p-6 gap-8'>
              <RenderSVG
                Svg={type === FLIGHT ? FlightIcon : HotelsIcon}
                alt='Transactions'
                className='none bg-primary-600 p-1 rounded-lg'
                color='white'
                width='30'
                height='30'
              />
              <div className='col-span-4 flex justify-start items-center capitalize text-[#111827] text-base font-medium font-inter'>
                Frequently Booked {type === FLIGHT ? "Airlines" : "Hotels"}
              </div>
            </div>
            <div className='flex flex-col p-4'>
              <Spinner
                size='w-full'
                name={type === FLIGHT ? ANALYTICS_FREQ_USED_AIRLINES : ANALYTICS_FREQ_USED_HOTELS}
                showSkeleton={true}
                loaderComponent={<FrequentlyUsedSkeleton />}
                className='inline-block'
              >
                {!isEmpty(frequentlyUsedData) && (
                  <>
                    <div className='font-semibold mb-6'>Total bookings: {frequentlyUsedData.data[totalField]}</div>
                    <div>
                      {frequentlyUsedData.data[targetField].map(
                        ({ name, count }) => {
                          const completed =
                            (count / frequentlyUsedData.data[totalField]) * 100;
                          return (
                            <ProgressBar
                              key={name}
                              name={name}
                              completed={completed}
                              count={count}
                            />
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </Spinner>
            </div>
          </div>
        </div>
      </>
    );
}

export default FrequentlyUsedAnalytics;
