import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { createPopper } from "@popperjs/core";
import { useFormikContext } from "formik";
import { Tooltip } from "react-tooltip";
import get from "lodash/get";
import ErrorMessage from "../../atoms/ErrorMessage";
import { Minus, Plus, RenderSVG, Users } from "../../../assets/icons";
import {
  DEFAULT_VALUES,
  WINDOWS_EVENTS,
  SEARCH_SECTION,
  TRAVELER_BUTTON_TYPES,
  FARE_TYPES,
  TRIP_TYPES,
} from "../../../constants";
import { TravelersCountPropTypes } from "../../../prop-types";
import { selectSelectedTrip } from "../../../screens/Booking/Trips";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { getCategorizedCountForTravelers } from "../../../helper";

const { ONE_WAY, ROUND_TRIP } = TRIP_TYPES;
const { ZERO, ONE, TWO, NINE, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { HOTEL } = SEARCH_SECTION;
const { CLICK } = WINDOWS_EVENTS;
const { DECREMENT, INCREMENT } = TRAVELER_BUTTON_TYPES;
const { REGULAR } = FARE_TYPES;
const ADULT = "adult";
const CHILDREN = "children";
const INFANTS = "infants";

const ageGroupsFlights = [
  {
    id: ZERO,
    name: ADULT,
    defaultValue: ONE,
    age: "searchSection.ages.adult",
    label: "searchSection.adults",
  },
  {
    id: ONE,
    name: CHILDREN,
    defaultValue: ZERO,
    age: "searchSection.ages.children",
    label: "searchSection.children",
  },
  {
    id: TWO,
    name: INFANTS,
    defaultValue: ZERO,
    age: "searchSection.ages.infants",
    label: "searchSection.infants",
  },
];

const ageGroupsHotels = [
  {
    id: ZERO,
    name: ADULT,
    defaultValue: ONE,
    age: "searchSection.ages.adult",
    label: "searchSection.adults",
  },
  {
    id: ONE,
    name: CHILDREN,
    defaultValue: ZERO,
    age: "searchSection.ages.children",
    label: "searchSection.children",
  },
];

const renderFieldError = (name, errors) =>
  get(errors, name, false) && <ErrorMessage errorMessage={get(errors, name)} />;

const TravelersCount = ({ source = EMPTY_STRING }) => {
  const { state } = useLocation();
  const { values, setFieldValue, errors, submitCount } = useFormikContext();
  const tripDetails = useSelector(selectSelectedTrip);
  const { passengers = [] } = tripDetails || {};
  const { fareType, tripType } = values;
  const adultsOnlySelection = fareType !== REGULAR.value;
  const travelersCountRef = useRef();
  const selectRef = useRef();
  const dropdownRef = useRef();
  const popperInstance = useRef(null);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [totalPassengers, setTotalPassengers] = useState(ONE);
  const selectedAgeGroups =
    source === HOTEL ? ageGroupsHotels : ageGroupsFlights;
  const { isReissuance = false } = state || EMPTY_OBJECT;
  const { adultCount, childCount, infantCount } =
    getCategorizedCountForTravelers(passengers, "flight");

  useEffect(() => {
      adultCount === 0
      ? setFieldValue(ADULT, ONE)
      : setFieldValue(ADULT, adultCount);
      setFieldValue(CHILDREN, childCount);
      setFieldValue(INFANTS, infantCount);
  }, [tripDetails, setFieldValue]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        travelersCountRef.current &&
        !travelersCountRef.current.contains(e.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener(CLICK, checkIfClickedOutside);

    return () => {
      document.removeEventListener(CLICK, checkIfClickedOutside);
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [travelersCountRef.current]);

  useEffect(() => {
    (get(errors, CHILDREN) || get(errors, INFANTS)) && !show && setShow(true);
  }, [submitCount]);

  useEffect(() => {
    const { adult, children, infants } = values;
    const filteredObject = { adult, children, infants };
    const totalCount = Object.values(filteredObject).reduce(
      (travelers, value) => {
        return value ? travelers + parseInt(value) : travelers;
      },
      ZERO
    );
    setTotalPassengers(totalCount);
  }, [values]);

  useEffect(() => {
    if (show) {
      popperInstance.current = createPopper(
        selectRef.current,
        dropdownRef.current,
        {
          placement: "bottom",
        }
      );
    }

    return () => {
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [show]);

  const shouldDisableDecrement = (values, name) =>
    +values[name] === ZERO || (name === ADULT && values[name] === ONE);

  const shouldDisableIncrement = (values, name) =>
    (name !== ADULT && adultsOnlySelection) ||
    totalPassengers >= NINE ||
    (name === INFANTS && +values[INFANTS] === +values[ADULT]);

  const incrementPassengersCount = (name) => {
    if (name === INFANTS && +values[INFANTS] === +values[ADULT]) return;
    setFieldValue(name, +values[name] + ONE);
  };

  const decrementPassengersCount = (name) => {
    if (+values[name] === ZERO) return;
    let minValue = name === ADULT ? ONE : ZERO;
    let newValue = +values[name] > minValue ? +values[name] - ONE : minValue;
    if (name === ADULT && +values[INFANTS] > newValue)
      setFieldValue(INFANTS, newValue);
    setFieldValue(name, newValue);
  };

  const renderCountPlaceholder = () => {
    if (source === HOTEL)
      return totalPassengers > ONE
        ? t("searchSection.guests")
        : t("searchSection.guest");
    else
      return totalPassengers > ONE
        ? t("searchSection.travelers")
        : t("searchSection.traveler");
  };

  const getErrorMessage = (values, name, buttonType) => {
    const infantsError =
      name === INFANTS
        ? "Number of infants cannot be more than adults"
        : EMPTY_STRING;
    switch (buttonType) {
      case DECREMENT:
        if (!shouldDisableDecrement(values, name)) return EMPTY_STRING;
        return name === ADULT
          ? "Minimum 1 adult is required."
          : `No ${name} selected.`;
      case INCREMENT:
        if (adultsOnlySelection && (name === CHILDREN || name === INFANTS))
          return `Only adult passengers can avail of ${fareType} fares. You may continue to book a regular fare or de-select all child/infant passengers to book the ${fareType} fare.`;
        if (!shouldDisableIncrement(values, name)) return EMPTY_STRING;
        return totalPassengers >= NINE
          ? "Maximum 9 passengers can be selected."
          : infantsError;
      default:
        return EMPTY_STRING;
    }
  };
  const isBottom = get(
    popperInstance,
    'current.state.placement.includes("bottom")',
    ""
  );

  return (
    <div
      className={classNames(
        "col-span-12 md:col-span-5 lg:col-span-5 flex-1 relative text-black rounded-md h-fit",
        {
          "xl:col-span-4 ": tripType === ONE_WAY || tripType === ROUND_TRIP,
          "hover:ring-2 hover:ring-primary-50": !isReissuance,
        }
      )}
      ref={travelersCountRef}
    >
      <button
        ref={selectRef}
        type='button'
        disabled={isReissuance}
        onClick={() => setShow(!show)}
        className='rounded-md w-full bg-white border shadow-sm border-contrast-300 flex items-center gap-2 px-3 py-3.5 disabled:bg-gray-200'
      >
        <RenderSVG Svg={Users} className='text-contrast-400' alt='User Icon' />
        <span className='text-contrast-900 whitespace-nowrap text-ellipsis overflow-hidden text-[16px]'>
          {`${totalPassengers} ${renderCountPlaceholder()}`}
        </span>
      </button>
      {show && (
        <div
          ref={dropdownRef}
          className={classNames(
            "dropdown-menu absolute z-30 bg-white px-6 py-4 rounded-lg border border-contrast-200 shadow-2xl my-2 w-full md:min-w-[478px] md:right-0",
            {
              "top-full": !isBottom,
              "bottom-full": isBottom,
            }
          )}
        >
          <div>
            {renderFieldError(CHILDREN, errors) ||
              renderFieldError(INFANTS, errors)}
          </div>
          <ul className='divide-y divide-contrast-200'>
            {selectedAgeGroups.map(({ id, name, age, label }) => (
              <li key={id} className='py-2 flex items-center'>
                <div className='p-4'>
                  <h4 className='font-semibold text-contrast-900 capitalize'>
                    {t(label)}
                  </h4>
                  <p className='text-contrast-600 text-xs md:text-base'>
                    {t(age)}
                  </p>
                </div>
                <div className='flex items-center gap-2 2xl:gap-4 ms-auto'>
                  <Tooltip
                    id='button-tooltip'
                    className='!bg-contrast-500 !rounded-lg max-w-xs'
                  />
                  <button
                    className='w-12 h-12 rounded-full border border-secondary-300 grid place-content-center disabled:bg-secondary-300 disabled:cursor-not-allowed'
                    type='button'
                    onClick={() => decrementPassengersCount(name)}
                    disabled={shouldDisableDecrement(values, name)}
                    data-tooltip-id='button-tooltip'
                    data-tooltip-content={getErrorMessage(
                      values,
                      name,
                      DECREMENT
                    )}
                  >
                    <RenderSVG Svg={Minus} alt='Minus Icon' />
                  </button>
                  <p className='w-6 h-6 md:w-12 md:h-12 grid place-content-center'>
                    {values[name]}
                  </p>
                  <button
                    className='w-12 h-12 rounded-full border border-secondary-300 grid place-content-center disabled:bg-secondary-300 disabled:cursor-not-allowed'
                    type='button'
                    onClick={() => incrementPassengersCount(name)}
                    disabled={shouldDisableIncrement(values, name)}
                    data-tooltip-id='button-tooltip'
                    data-tooltip-content={getErrorMessage(
                      values,
                      name,
                      INCREMENT
                    )}
                  >
                    <RenderSVG Svg={Plus} alt='Plus Icon' />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

TravelersCount.propTypes = TravelersCountPropTypes;

export default TravelersCount;
