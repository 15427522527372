import React from "react";
import UserImageMale from "../../assets/images/UserImageMale.png";
import UserImageFemale from "../../assets/images/UserImageFemale.png";

import { Carousel } from "@material-tailwind/react";
import { get } from "lodash";

const GreetingCardAnalytics = ({ analyticsInfo }) => {
  const greetingResponse = get(analyticsInfo, 'data', {});
  if(!greetingResponse) return;
  const gender = greetingResponse.gender || 'Male'; // Default to 'Male' if gender is undefined

  const data = [
    {
      message: `Hello ${greetingResponse.name ? greetingResponse.name : `${greetingResponse.firstName || "Unnamed"} ${greetingResponse.middleName ? greetingResponse.middleName + " " : ""}${greetingResponse.lastName || ""}`} ,`,
      description: "Welcome back! Here you can view the details of your bookings and access your analytics.",
      image: gender === 'Female' ? UserImageFemale : UserImageMale,
      bgColor: 'bg-gradient-to-r from-indigo-700',
    },
  ];

  const renderGreetingCard = (item, index) => (
    <div
      key={index}
      className={`flex flex-col pt-4 px-8 pb-2 border rounded-lg gap-7 max-w-full h-full bg-gradient-to-r from-primary-700 via-primary-500 to-primary-100`}
    >
      <div className="flex items-start justify-around w-full p-6 overflow-auto no-scrollbar">
        <div className="flex flex-col mt-4">
          <div className="flex items-center capitalize font-bold text-white text-2xl">
            {item.message}
          </div>
          <div className="capitalize font-bold text-white text-lg mt-2">
            {item.description}
          </div>
        </div>
        {item.image && (
          <div>
            <img
              src={item.image}
              alt="User Greeting"
              className="w-45 h-40"
            />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="w-full md:w-3/3">
      <div className="relative rounded-lg h-60 sm:max-h-60 md:max-h-96">
      {data.length > 1 ? (
          <Carousel
            className="rounded-xl"
            autoplay={true}
            autoplayDelay={5000}
            loop={true}
          >
            {data.map(renderGreetingCard)}
          </Carousel>
        ) : (
          data.map(renderGreetingCard)
        )}
      </div>
    </div>
  );
};

export default GreetingCardAnalytics;
