import { useState } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IllustrationUpload, RenderSVG } from "../../../../assets/icons";
import Modal from "../../Modal";

const MAX_IMAGE_SIZE_ALLOWED = 5 * 1024 * 1024;
const VALID_IMAGE_FILE_TYPES = ["image/jpeg", "image/png", "image/svg+xml"];

const Footer = ({ handleClose, handleSaveImage }) => {
  const { t } = useTranslation();
  return (
    <div className='p-6 flex gap-4 justify-end border-t border-contrast-200'>
      <button
        type='button'
        className='py-[10px] px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium'
        onClick={handleClose}
      >
        {t("accountSettings.cancel")}
      </button>
      <button
        type='button'
        className='py-[10px] px-4  rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium'
        onClick={handleSaveImage}
      >
        {t("accountSettings.update")}
      </button>
    </div>
  );
};

const UploadFile = ({ handleClose, handleSave }) => {
  const { t } = useTranslation();
  const [uploadedImage, setUploadedImage] = useState();
  const [dropZoneFocus, setDropZoneFocus] = useState();

  const handleUploadImage = (e) => {
    e.preventDefault();
    let image;
    if (e.type === "change") image = e.target.files[0];
    else image = e.dataTransfer.files[0];
    if (!image) return;
    if (
      !VALID_IMAGE_FILE_TYPES.includes(image.type) ||
      image.size > MAX_IMAGE_SIZE_ALLOWED
    ) {
      toast.error("Please select a valid image file less than 5 MB.");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImage(reader.result);
    };
    reader.readAsDataURL(image);
    setDropZoneFocus(false);
  };

  const handleSaveImage = () => {
    handleSave(uploadedImage);
    handleClose();
  };

  return (
    <Modal
      title={t("accountSettings.updateProfilePhoto")}
      footer={
        <Footer handleSaveImage={handleSaveImage} handleClose={handleClose} />
      }
      handleClose={handleClose}
      shouldCloseOnClickOutside={true}
      shouldShowModalFromProps={true}
    >
      <div className='p-6 box-content'>
        <div
          className={classNames(
            "relative p-8 lg:p-10 rounded-lg grid grid-cols-12 xl:gap-10 gap-6 items-center",
            {
              "bg-cyan-50 border-contrast-400": dropZoneFocus,
              "bg-contrast-100": !dropZoneFocus,
            }
          )}
          onDrop={handleUploadImage}
          onDragLeave={(e) => {
            e.preventDefault();
            setDropZoneFocus(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setDropZoneFocus(true);
          }}
        >
          <input
            key={uploadedImage}
            id='uploadedImage'
            type='file'
            onChange={handleUploadImage}
            className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
            accept='.png, .jpg, .jpeg'
          />
          <div className='col-span-12 md:col-span-6 text-center'>
            {uploadedImage ? (
              <img
                src={uploadedImage}
                width='220'
                height='220'
                className='max-w-full mx-auto'
                alt='selective-photo'
              />
            ) : (
              <RenderSVG
                Svg={IllustrationUpload}
                className='max-w-full mx-auto'
                width='220'
                height='220'
                alt='IllustrationUpload'
              />
            )}
          </div>
          <div className='col-span-12 md:col-span-6 text-center md:text-left font-lato'>
            <div>
              <h6 className='text-xl text-contrast-700 font-bold mb-2'>
                {t("accountSettings.selectFile")}
              </h6>
              <span className='text-sm text-contrast-500'>
                {t("accountSettings.dropFileHereOr")}{" "}
                <label
                  htmlFor='uploadedImage'
                  className='text-primary-600 cursor-pointer'
                >
                  {t("accountSettings.browse")}
                </label>{" "}
                {t("accountSettings.throughYourMachine")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadFile;
