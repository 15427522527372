import { useEffect, useState } from "react";
import { RenderSVG, Refresh } from "../../../assets/icons/index.js";
import { useSelector } from "react-redux";
import { selectCurrentUserInfo } from "../../Auth/index.js";
import {
  DEFAULT_VALUES,
  AVAILABLE_PERMISSIONS,
  POLICY_CATAGORY,
} from "../../../constants.js";
import {
  selectAllServicesPolicy,
  selectSelectedServicePolicyId,
} from "../index.js";
import PolicyDetailBody from "../PolicyDetailBody.js";
import Asterisk from "../../../components/atoms/Asterisk.js";

const { EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const {
  CAN_EDIT_POLICIES,
  CAN_RESET_POLICIES,
  CAN_MANAGE_DEPARTMENT_EXCEPTION,
} = AVAILABLE_PERMISSIONS;
const { COST } = POLICY_CATAGORY;

const getRequiredPermissions = (authInfo) => {
  const { permissions } = authInfo || EMPTY_OBJECT;
  const requiredPermissions = {
    canEditPolicies: permissions.includes(CAN_EDIT_POLICIES),
    canResetPolicies: permissions.includes(CAN_RESET_POLICIES),
    canManageDepartmentException: permissions.includes(
      CAN_MANAGE_DEPARTMENT_EXCEPTION
    ),
  };
  return requiredPermissions;
};

const Hotels = () => {
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const allServicesPolicy = useSelector(selectAllServicesPolicy);
  const selectedServicePolicyId = useSelector(selectSelectedServicePolicyId);
  const [selectedServicePolicesList, setSelectedServicePolicesList] =
    useState(EMPTY_ARRAY);

  const { canResetPolicies } = getRequiredPermissions(currentUserInfo);

  useEffect(() => {
    if (!selectedServicePolicyId) return;
    const selectedServicePolicyData = allServicesPolicy.find(
      ({ id }) => id === selectedServicePolicyId
    );
    const policyList = Object.keys(selectedServicePolicyData.policies).map(
      (key) => ({
        name: key,
        value: selectedServicePolicyData.policies[key],
      })
    );
    setSelectedServicePolicesList(policyList);
  }, [allServicesPolicy, selectedServicePolicyId]);

  return (
    <div className='h-full w-full pt-4'>
      {selectedServicePolicesList.map(({ name, value }) => {
        const isCostPolicy = name === COST;
        return (
          <div
            key={name}
            className={
              "flex flex-row gap-8 p-4 mx-4 mb-4 border rounded-2xl shadow-md transition duration-1000 ease-in-out border-contrast-200 bg-white hover:border-contrast-400"
            }
          >
            <div className='w-[25%]'>
              <div className='text-lg font-semibold'>
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </div>
              <div className='text-sm font-normal text-gray-500'>
                {value.description}
              </div>
              {canResetPolicies && (
                <button
                  type='button'
                  className='py-2 mt-4 px-4 flex items-center justify-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium'
                >
                  <RenderSVG Svg={Refresh} />
                  <span className='font-medium text-sm text-gray-700'>
                    Reset Policy
                  </span>
                </button>
              )}
            </div>

            <div className='w-[75%] flex flex-col relative overflow-x-auto'>
              <table className='w-full table-auto border-collapse border border-slate-200 rounded-2xl'>
                <tbody>
                  <PolicyDetailBody
                    policyValue={value}
                    policyName={name}
                    isCostPolicy={isCostPolicy}
                  />
                </tbody>
              </table>
              {isCostPolicy && <Asterisk>Cost per booking</Asterisk>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Hotels;
