import axios from "axios";
import { PROMISES, REQUEST_METHODS, SUCCESS } from "../../constants";
import { addAwaitedRequest } from "../awaitedRequests";

const { GET, PUT, PATCH, POST, DELETE } = REQUEST_METHODS;
const { REACT_APP_API_BASE_URL } = process.env;
const { CANCELLED } = PROMISES;

export const ApiClient = axios.create({ baseURL: REACT_APP_API_BASE_URL });

/*--- handle the awaited request ---*/
ApiClient.interceptors.request.use((config) => {
  const { url, abortAwaitingRequests } = config;
  const cancelTokenSource = axios.CancelToken.source();
  abortAwaitingRequests && addAwaitedRequest(url, cancelTokenSource);
  config.cancelToken = cancelTokenSource.token;
  return config;
});

const ongoingRequests = new Set();

const axiosApi = (
  method,
  url,
  body,
  requestWithConfig,
  abortOnPageChange,
  abortAwaitingRequests
) => {
  const controller = new AbortController();
  abortOnPageChange && ongoingRequests.add(controller);

  const updatedConfig = {
    ...requestWithConfig,
    signal: controller.signal,
    abortAwaitingRequests,
  };

  switch (method) {
    case GET:
      return ApiClient.get(url, updatedConfig);
    case POST:
      return ApiClient.post(url, body, updatedConfig);
    case PATCH:
      return ApiClient.patch(url, body, updatedConfig);
    case DELETE:
      return ApiClient.delete(url, updatedConfig);
    case PUT:
      return ApiClient.put(url, body, updatedConfig);
  }
};

const requestMethods = async (
  methodType,
  url,
  body,
  requestWithConfig,
  abortOnPageChange,
  abortAwaitingRequests
) => {
  if (!url) throw new Error("You must specify a url");

  try {
    const response = await axiosApi(
      methodType,
      url,
      body,
      requestWithConfig,
      abortOnPageChange,
      abortAwaitingRequests
    );
    if (response.status === SUCCESS) return response.data;
    return Promise.reject(response);
  } catch (error) {
    return axios.isCancel(error)
      ? Promise.reject({
          status: CANCELLED,
          message: "Request cancelled",
        })
      : Promise.reject(error);
  }
};

export const cancelAllAPIRequests = () => {
  ongoingRequests.forEach((controller) => controller.abort());
  ongoingRequests.clear();
};

export default requestMethods;
