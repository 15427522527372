import { razorpayContainerClass } from "../constants";

export const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });

export const closeRazorPayModal = (razorpayPaymentObject) => {
  if (razorpayPaymentObject) {
    razorpayPaymentObject.close();
    const razorpayContainers = document.querySelectorAll(
      razorpayContainerClass
    );
    if (razorpayContainers)
      razorpayContainers.forEach((container) => {
        container.style.display = "none";
      });
  }
};
