import { init, BrowserTracing, Replay } from "@sentry/react";
const STAGING = "staging";

const initializeSentry = () => {
  init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || STAGING,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export default initializeSentry;
