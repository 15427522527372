import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BookingLinksPropTypes } from "../../../prop-types";
import {
  getUserBookingInfo,
  setIsGenerateVoucherInitiated,
} from "../../../screens/MyTrips";
import {
  RenderSVG,
  HotelTicket,
  CheckCircleIcon,
  TicketOutline,
  TicketSlash,
} from "../../../assets/icons";
import {
  ROUTES,
  SEARCH_SECTION,
  BOOKING_STATUS,
  CONTACT_EMAIL,
} from "../../../constants";
import { downloadEticket } from "../../../screens/Booking/Trips";
import Spinner, { SPINNER_NAMES, selectActiveSpinners } from "../Spinner";
import { sendETicketEmail } from "../../../screens/Booking/Trips/trips.actions";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ticketDownlaod } from "../../../helper";
import config from "../../../config.json";

const { BOOKINGS, BOOKING_INFO_ROUTE, HOTEL_CANCELLATION } = ROUTES;
const { HOTEL } = SEARCH_SECTION;
const { DOWNLOAD_TICKET, EMAIL_ETICKET } = SPINNER_NAMES;
const { logo } = config;
const { FAILED, CANCELLED, PENDING, CONFIRMED } = BOOKING_STATUS;

const BookingLinks = ({
  isSpinnerActive = false,
  bookingId,
  tenantBookingStatus,
  isPastCheckInDate,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [isEticketSend, setIsEticketSend] = useState(false);
  const [isTicketDownloaded, setIsTicketDownloaded] = useState(false);
  const activeSpinners = useSelector(selectActiveSpinners);

  const downloadETicketSpinner = activeSpinners.some(
    (spinnerName) => spinnerName === DOWNLOAD_TICKET
  );
  const sendEticketSpinner = activeSpinners.some(
    (spinnerName) => spinnerName === EMAIL_ETICKET
  );
  const shouldHideCancelBookingButton =
    tenantBookingStatus &&
    (tenantBookingStatus.toUpperCase() === PENDING ||
      tenantBookingStatus.toUpperCase() === CANCELLED);

  const hasHotelBookingFailed =
    tenantBookingStatus && tenantBookingStatus.toUpperCase() === FAILED;

  const hasHotelBookingConfirmed =
    tenantBookingStatus && tenantBookingStatus.toUpperCase() === CONFIRMED;

  const handleNavigateToUserBookings = () => {
    if (!isVoucherBooking) {
      dispatch(setIsGenerateVoucherInitiated(false));
      dispatch(
        getUserBookingInfo({
          queryParams: {
            division: HOTEL,
            id: bookingId,
          },
        })
      );
      navigate(
        BOOKING_INFO_ROUTE.replace(":division", HOTEL).replace(":id", bookingId)
      );
    } else navigate(BOOKINGS);
  };
  
  const handleTicketDownload = (bookingId) => {
    dispatch(
      downloadEticket({
        params: {
          flight: false,
          hotel: true,
        },
        tavaBookingId: bookingId,
      })
    ).then((res) => {
      if (res) {
        const hotelContent = res?.payload?.output?.htmlContent?.hotelPdf;
        if (hotelContent) {
          ticketDownlaod(hotelContent, bookingId);
          setIsTicketDownloaded(true);
        }
      }
    });
  };

  return (
    <div className='col-span-12 xl:col-span-4 print:hidden w-full'>
      {!isSpinnerActive && hasHotelBookingConfirmed && <div className='border border-contrast-300 rounded-lg mb-6 bg-white'>
        <ul className='flex flex-col text-sm'>
          <li className='flex items-center gap-2 px-4 py-3'>
            <h4 className='font-bold text-base text-contrast-900'>
              Manage your trip &amp; get all the help
            </h4>
          </li>
          {/* Todo : Commented for now */}
          {/* <li className="flex items-center gap-2 px-4 py-3">
            <Link
              to=''
              className='flex gap-2 items-center text-sm font-medium text-[#4f46e5]'
            >
              <RenderSVG
                Svg={PhoneIcon}
                className='w-[20px] h-[20px]'
                alt='phone-icon'
              />

              <span>Contact Property</span>
            </Link>
          </li>
          <li className='flex items-center gap-2 px-4 py-3'>
            <Link
              className='flex gap-2 items-center text-sm font-medium text-[#4f46e5]'
              to=''
              disabled={isSpinnerActive}
            >
              <RenderSVG
                Svg={TicketSlash}
                className='w-[20px] h-[20px]'
                alt='ticket-slash-icon'
              />
              <span>Cancel Booking</span>
            </Link>
          </li> */}
          <li className='flex items-center gap-2 px-4 py-3'>
            <button
              className='flex gap-2 items-center text-sm font-medium text-[#4f46e5] disabled:cursor-not-allowed'
              disabled={isSpinnerActive}
              onClick={() => window.print()}
            >
              <RenderSVG
                Svg={HotelTicket}
                alt='ticket-icon'
                className='w-[20px] h-[20px]'
                fill='none'
              />
              <span>Print Receipt</span>
            </button>
          </li>
          {!hasHotelBookingFailed && !shouldHideCancelBookingButton && (
            <div>
              <li className='flex items-center gap-2 px-4 py-3'>
                <button
                  className='flex gap-2 items-center text-sm font-medium text-primary-600 cursor-pointer'
                  onClick={() => {
                    dispatch(
                      sendETicketEmail({
                        params: {
                          flight: false,
                          hotel: true,
                        },
                        tavaBookingId: bookingId,
                      })
                    ).then(() => {
                      setIsEticketSend(true);
                    });
                  }}
                  disabled={sendEticketSpinner}
                >
                  <div className='icon'>
                    <RenderSVG Svg={TicketOutline} width='20' />
                  </div>
                  <span>{t("bookingResult.emailTicket")}</span>
                  <Spinner name={EMAIL_ETICKET}>
                    {isEticketSend && !sendEticketSpinner && (
                      <div>
                        <RenderSVG Svg={CheckCircleIcon} width='20' />
                      </div>
                    )}
                  </Spinner>
                </button>
              </li>
              {!isPastCheckInDate && (
                  <li className='flex items-center gap-2 px-4 py-3'>
                    <Link
                      className='flex gap-2 items-center text-sm font-medium text-[#4f46e5]'
                      to={HOTEL_CANCELLATION.replace(":bookingId", bookingId)}
                      disabled={isSpinnerActive}
                    >
                      <RenderSVG
                        Svg={TicketSlash}
                        className='w-[20px] h-[20px]'
                        alt='ticket-slash-icon'
                      />
                      <span>Cancel Booking</span>
                    </Link>
                  </li>
                )}
            </div>
          )}
          {/* {showNavigateToMyTripOption && (
            <li className="flex items-center gap-2 px-4 py-3">
              <Link
                className='flex gap-2 items-center text-sm font-medium text-[#4f46e5]'
                to=''
              >
                <RenderSVG
                  Svg={Dashboard}
                  className='w-[20px] h-[20px]'
                  alt='dashboard-icon'
                />
                <span>More in My Trips</span>
              </Link>
            </li>
          )}

          {showNavigateToMyTripOption && (
            <li className='flex items-center gap-2 px-4 py-3 bg-primary-50/50'>
              <button
                className='py-3 px-4 rounded-md bg-[#4f46e5] hover:bg-[#4338ca] active:bg-[#4f46e5] shadow-sm text-sm text-white font-medium w-full disabled:cursor-not-allowed'
                disabled={isSpinnerActive}
                onClick={() => handleNavigateToUserBookings()}
              >
                Go to My Trips
              </button>
            </li>
          )} */}
        </ul>
      </div>}
      <div className='border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6'>
        <div className='p-6'>
          <h4 className='font-bold text-contrast-900 text-base mb-5'>
            {t("bookingResult.contactUs")}
          </h4>
          <div className='flex items-center gap-3 '>
            <div className='logo'>
              <img
                src={logo}
                width='30'
                className='rounded border border-contrast-300'
                alt='tavatrip logo'
              />
            </div>
            <div className='flex-1'>
              <p className='text-xs text-contrast-600 mb-1'>
                {t("bookingResult.customerSupport")}
              </p>
              <h5 className='text-sm font-semibold text-primary-600 underline'>
                <a target='_blank' href='mailto:support@tavatrip.com'>
                  {CONTACT_EMAIL}
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BookingLinks.propTypes = BookingLinksPropTypes;

export default BookingLinks;
