import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  getFromLocalStorage,
  setToSessionStorage,
  getSSRPrice,
  checkIsInternationalFlight,
  getUniqueCountriesByIataCodes,
  getFromSessionStorage,
  checkIfUserHasPermission,
  getPreferredAirlinesCode,
  mapUsersToTravelers,
} from "../../../helper";
import { getPolicies } from "../../../screens/Policies";
import {
  fetchFlightPrices,
  selectFlightPriceInfo,
  selectFlightPriceReq,
  selectSelectedTripType,
  selectSelectedFlightOptions,
  resetFlightPriceInfo,
  selectSelectedFlilteredFlights,
} from "../../../screens/FlightResults";
import { selectSelectedModal, setSelectedModal } from "../Modal";
import {
  fetchSpecialServices,
  selectSelectedLCCBaggages,
  selectSelectedLCCMeals,
  selectSelectedLCCSeats,
  getPromoCodes,
  selectSpecialServices,
  setSpecialServices,
  setBookingTravelers,
  setSelectedTravelers,
  setContactDetails,
  setAddressDetails,
  selectTripStatus,
  setIsFlightSearchInitiated,
  selectAddressDetails,
  selectContactDetails,
  selectSelectedTravelers,
} from "../../../screens/Booking/FlightBookings";
import {
  fetchFlights,
  selectSearchFilters,
  getHotels,
  selectHotelSearchFilters,
  getHotelsStaticData,
  selectFlights,
  selectHotels,
  setActiveFilters,
  selectActiveFilters,
  selectFilteredHotelResult,
  selectFlightTokens,
  selectIsStaticDataLoaded,
  selectIsHotelsLoaded,
  setIsHotelsLoaded,
  setIsStaticDataLoaded,
} from "../Search";
import renderModal from "../AppModals";
import renderDrawer from "../AppDrawers";
import {
  getCostCodes,
  getRateExchanges,
  getTravelers,
  selectCountryInfo,
  selectExchangeRates,
  selectUserInfo,
} from "../../../screens/Profile";
import { actions as authActions } from "../../../screens/Auth/auth.reducer";
import { actions } from "../../../screens/FlightResults/flightResults.reducer";
import { selectSelectedDrawer } from "../Drawer";
import {
  getHotelInfo,
  selectHotelInfo,
  selectHotelInfoReqBody,
  selectPricePolicyReqBody,
  resetHotelInfo,
  selectPricePolicyInfo,
} from "../../../screens/HotelInfo";
import { actions as flightBookingActions } from "../../../screens/Booking/FlightBookings/flightBookings.reducer";
import { cancelAllAPIRequests } from "../../../infrastructure/httpMethods/requestingMethods";
import {
  selectCurrentUserInfo,
  setIsUserAuthenticated,
  selectIsUserAuthenticated,
  fetchTenantDetails,
} from "../../../screens/Auth";
import {
  selectSessionFlag,
  setSessionFlag,
  updateSessionInfo,
} from "../../../screens/session";
import { MODALS } from "../AppModals";
import { verifyHotelPricePolicy } from "../../../screens/HotelInfo/hotelInfo.actions";
import {
  getPermissions,
  getTenantsRoles,
} from "../../../screens/Permissions/permissions.actions";
import { getWallet, selectWalletInfo } from "../../../screens/Account";
import { actions as tripsActions } from "../../../screens/Booking/Trips/trips.reducer";
import {
  fetchTripsById,
  selectSelectedTrip,
  selectSelectedTripId,
} from "../../../screens/Booking/Trips";
import {
  ROUTES,
  DEFAULT_VALUES,
  CACHE_KEYS,
  FLIGHT_SERVICE_TYPE,
  API_RESPONSES,
  AVAILABLE_PERMISSIONS,
  BOOKING_SEARCH_OPTIONS,
  TRIP_TYPES,
  FLIGHTS_RESULT_TYPE,
  DEFAULT_CURRENCY,
} from "../../../constants";
import FlightActiveFilters from "../../../screens/FlightResults/FlightFilters/FlightActiveFilters";

const { setSelectedTripType, setIsInternational } = actions;
const { setTotalSSRPrice } = flightBookingActions;
const { setAuthInfo } = authActions;
const { setSelectedTripId } = tripsActions;

const { ZERO, EMPTY_STRING, EMPTY_OBJECT, NEG_ONE, ONE, EMPTY_ARRAY } = DEFAULT_VALUES;
const { CAN_VIEW_ROLES, CAN_VIEW_WALLET } = AVAILABLE_PERMISSIONS;
const { AUTH, SESSION_ID, HOTEL_SEARCH_FILTERS, HOTEL_INFO_REQUEST_BODY } =
  CACHE_KEYS;
const { ONE_WAY, ROUND_TRIP } = TRIP_TYPES;
const { BOOKINGS } = ROUTES;
const { TBO } = FLIGHT_SERVICE_TYPE;
const { SESSION_EXPIRED_MODAL, API_FAILURE_MODAL } = MODALS;
const { SESSION_EXPIRED } = API_RESPONSES;
const { BOOKING_ID } = BOOKING_SEARCH_OPTIONS;
const { OUTBOUND_RESULT, INBOUND_RESULT, PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const { CURRENCY_INFO } = CACHE_KEYS;

const FLIGHT_DETAILS = "flightDetails";
const INTERNATIONAL = "international";
const DOMESTIC = "domestic";
const TAVA = "TAVA_";

const authInfo = getFromLocalStorage(AUTH);

const getHostname = (url = "") => {
  const withoutProtocol = url.replace(/^https?:\/\//, "");
  const firstSlashIndex = withoutProtocol.indexOf("/");
  const hostname =
    firstSlashIndex !== -1
      ? withoutProtocol.slice(0, firstSlashIndex)
      : withoutProtocol;
  return hostname;
};

const getTavaBookingId = (url = EMPTY_STRING) => {
  const tavaIndex = url.indexOf(TAVA);
  if (tavaIndex === NEG_ONE) {
    return null;
  }
  let endIndex = tavaIndex;
  while (endIndex < url.length && url[endIndex] !== '/') {
    endIndex++;
  }
  return url.substring(tavaIndex, endIndex);
}

const EffectController = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = getTavaBookingId(location.pathname);
  const navigate = useNavigate();
  let timeoutId = useRef(null);
  const selectedUserInfo = useSelector(selectUserInfo);
  const selectedModal = useSelector(selectSelectedModal);
  const selectedDrawer = useSelector(selectSelectedDrawer);
  const flightPriceInfo = useSelector(selectFlightPriceInfo);
  const flightPriceReq = useSelector(selectFlightPriceReq);
  const storeState = useSelector((store) => store);
  const searchFilters = useSelector(selectSearchFilters);
  const tripType = useSelector(selectSelectedTripType);
  const tripDetails = useSelector(selectSelectedTrip);
  const activeFilters = useSelector(selectActiveFilters);
  const selectedFlights = useSelector(selectSelectedFlightOptions);
  const selectedMeals = useSelector(selectSelectedLCCMeals);
  const selectedSeats = useSelector(selectSelectedLCCSeats);
  const selectedBaggages = useSelector(selectSelectedLCCBaggages);
  const selectedCurrentUserInfo = useSelector(selectCurrentUserInfo);
  const { ip: endUserIp } = useSelector(selectCountryInfo) || EMPTY_OBJECT;
  const specialServicesDetails = useSelector(selectSpecialServices);
  const isUserAuthenticated = useSelector(selectIsUserAuthenticated);
  const walletInfo = useSelector(selectWalletInfo);
  const selectedTripId = useSelector(selectSelectedTripId);
  const tripStatus = useSelector(selectTripStatus);
  const flightResults = useSelector(selectFlights);
  const selectedTravelers = useSelector(selectSelectedTravelers);

  const hotelInfoReqBody = useSelector(selectHotelInfoReqBody);
  const pricePolicyReqBody = useSelector(selectPricePolicyReqBody);
  const hotelInfo = useSelector(selectHotelInfo);
  const hotelResult = useSelector(selectHotels);
  const sessionFlag = useSelector(selectSessionFlag);
  const pricePolicyInfo = useSelector(selectPricePolicyInfo);
  const isStaticDataLoaded = useSelector(selectIsStaticDataLoaded);
  const isHotelsLoaded = useSelector(selectIsHotelsLoaded);
  const exchangeRates = useSelector(selectExchangeRates);
  const countryInfo = useSelector(selectCountryInfo)

  const handleSessionCreation = (sessionId, endUserIp) => {
    const pageUrl = window.location.href;
    const url = new URL(pageUrl);
    const existingSessionId = new URLSearchParams(pageUrl).get(SESSION_ID);
    if (existingSessionId && existingSessionId !== "undefined") {
      dispatch(setSessionFlag(`session_updated_on_${Date()}`));
      return;
    }
    const encodedCurrentIP = btoa(endUserIp);
    const sessionParams = `${sessionId}_${encodedCurrentIP}`;
    window.location.pathname !== BOOKINGS &&
      url.searchParams.set(SESSION_ID, sessionParams);
    window.history.replaceState(null, null, url);
    const sessionData = {
      sessionParams,
      encodedCurrentIP,
      sessionId,
    };

    if (url.pathname.includes("hotels")) {      
      setToSessionStorage("hotelsSession", sessionData);
      dispatch(setIsHotelsLoaded(false));
      dispatch(setIsStaticDataLoaded(false));
    } else {      
      setToSessionStorage(SESSION_ID, sessionData);
    }

    dispatch(setSessionFlag(`session_updated_on_${Date()}`));
  };
  const hotelResults = get(hotelResult, "hotelResults", EMPTY_ARRAY);
  const sourceData = get(hotelResult, "sourceData", EMPTY_ARRAY);

  const selectedHotelSearchFilters = useSelector(selectHotelSearchFilters);
  const { flightSearchQuery, isFlightSearchInitiated, isAISearchInitiated } =
    tripStatus;
  const tenantId = get(selectedCurrentUserInfo, "tenantId", "");
  const availablePermissions = get(selectedCurrentUserInfo, "permissions", []);
  const hasViewRolesPermission =
    selectedCurrentUserInfo &&
    checkIfUserHasPermission(CAN_VIEW_ROLES, availablePermissions);
  const hasViewWalletPermission =
    selectedCurrentUserInfo &&
    checkIfUserHasPermission(CAN_VIEW_WALLET, availablePermissions);

  const isHomePage = location.pathname === BOOKINGS;
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);

  useEffect(() => {
    if (selectedTripId) return;
    const bookingId = getFromSessionStorage(BOOKING_ID) || id;
    if (bookingId) dispatch(setSelectedTripId(bookingId));
  }, [id]);

  useEffect(() => {
    if (!authInfo) return;
    dispatch(setAuthInfo(authInfo));
    dispatch(setIsUserAuthenticated(!!get(authInfo, "id", false)));
  }, [dispatch, authInfo]);

  useLayoutEffect(() => {
    cancelAllAPIRequests();
  }, [location.pathname]);

  useEffect(() => {
    countryInfo && dispatch(getRateExchanges());
  }, [countryInfo, dispatch]);

  useEffect(() => {
    if (!isEmpty(exchangeRates) && !isEmpty(countryInfo)) {
      const currencyInfo = get(countryInfo, "currency", DEFAULT_CURRENCY);
      setToSessionStorage(CURRENCY_INFO, {
        exchangeRate: exchangeRates[`${currencyInfo.code}`],
        ...currencyInfo,
      });
    }
  }, [exchangeRates, countryInfo]);

  useEffect(() => {
    !isEmpty(hotelInfoReqBody) &&
      isEmpty(hotelInfo) &&
      dispatch(getHotelInfo(hotelInfoReqBody)).then((res) => {
        if (res.payload) {
          dispatch(setSessionFlag(`session_updated_on_${Date()}`));
        }
      });
  }, [hotelInfoReqBody]);

  useEffect(() => {
    !isEmpty(pricePolicyReqBody) &&
      isEmpty(pricePolicyInfo) &&
      dispatch(verifyHotelPricePolicy(pricePolicyReqBody)).then((res) => {
        if (res.payload) {
          dispatch(setSessionFlag(`session_updated_on_${Date()}`));
        }
      });
  }, [pricePolicyReqBody]);

  useEffect(() => {
    if (!selectedCurrentUserInfo?.id) return;
    const { id } = selectedCurrentUserInfo;
    id && dispatch(getTravelers(id));
  }, [dispatch, selectedCurrentUserInfo?.id]);

  useEffect(() => {
    if (tripType) return;
    const { tripType: optedTripType } = searchFilters;
    if (optedTripType) dispatch(setSelectedTripType(optedTripType));
  }, [tripType, searchFilters]);

  useEffect(() => {
    window.scrollTo(ZERO, ZERO);
  }, [location]);

  useEffect(() => {
    setShouldShowModal(selectedModal);
  }, [selectedModal]);

  useEffect(() => {
    setShouldShowDrawer(selectedDrawer);
  }, [selectedDrawer]);

  useEffect(() => {
    if (!isEmpty(searchFilters) && isEmpty(flightResults) && endUserIp) {
      const { journeys } = searchFilters;
      const countries = getUniqueCountriesByIataCodes([
        journeys[0].destCode.iata,
        journeys[0].originCode.iata,
      ]);
      const sessionId = uuid();
      const formattedJourney = journeys?.map((each) => ({
        ...each,
        originCode: get(each, "originCode.iata", EMPTY_STRING),
        destCode: get(each, "destCode.iata", EMPTY_STRING),
      }));
      const queryFilter = {
        ...searchFilters,
        endUserIp,
        journeys: formattedJourney,
        travelType: countries.length > ONE ? INTERNATIONAL : DOMESTIC,
        key: "search",
        sessionId,
        sources: ["GDS"],
        preferredAirlines: getPreferredAirlinesCode(selectedUserInfo.policies),
      };

      dispatch(resetFlightPriceInfo());
      const flightsReqBody = { body: queryFilter };
      dispatch(fetchFlights(flightsReqBody)).then((res) => {
        if (res.payload) handleSessionCreation(sessionId, endUserIp);
      });
      const updatedReqBody = {
        body: { ...flightsReqBody.body, sources: null },
      };
      dispatch(fetchFlights(updatedReqBody)).then((res) => {
        if (res.payload) {
          if (!isEmpty(flightSearchQuery))
            dispatch(setIsFlightSearchInitiated(true));
          handleSessionCreation(sessionId, endUserIp);
        }
      });
      dispatch(setIsInternational(countries.length > ONE));
    }
  }, [dispatch, searchFilters, endUserIp]);

  useEffect(() => {
    if (isEmpty(flightSearchQuery)) return;
    const { tripType, includedAirlines, maxPrice, numberOfStops } =
      searchFilters;

    const updatedFilters = {
      airlines: includedAirlines,
      price: {
        maxPrice: maxPrice,
        minPrice: 0,
      },
      departureTime: {
        minTime: searchFilters.departureTime,
        maxTime: 1439,
      },
      arrivalTime: {
        minTime: searchFilters.arrivalTime,
        maxTime: 1439,
      },
      stops: numberOfStops,
    };

    if (tripType === ROUND_TRIP) {
      dispatch(
        setActiveFilters({
          ...activeFilters,
          isolated: { outbound: updatedFilters, inbound: updatedFilters },
        })
      );
    } else if (tripType === ONE_WAY) {
      const packageFilters = { packages: updatedFilters };
      dispatch(setActiveFilters({ ...activeFilters, ...packageFilters }));
    }
  }, [searchFilters, flightSearchQuery]);

  useEffect(() => {
    if(isStaticDataLoaded && isHotelsLoaded){
      const { sessionId } = selectedHotelSearchFilters;
      handleSessionCreation(sessionId, endUserIp);
    }
  }, [isHotelsLoaded, isStaticDataLoaded])

  useEffect(() => {
    if (
      !isEmpty(selectedHotelSearchFilters) &&
      (isEmpty(hotelResult))
    ) {      
      dispatch(resetHotelInfo());
      setToSessionStorage(HOTEL_SEARCH_FILTERS, selectedHotelSearchFilters);
      dispatch(getHotels({ body: selectedHotelSearchFilters }))
      const hotelCode = get(selectedHotelSearchFilters, "hotelCode", "");
      const cityId = get(selectedHotelSearchFilters, "cityId", "");
      if (!isEmpty(cityId))
        dispatch(getHotelsStaticData({ body: { cityId } }))
      else if (!isEmpty(hotelCode)) {
        dispatch(
          getHotelsStaticData({
            body: {
              hotelIds: [hotelCode],
            },
          })
        )
      }
    }
  }, [dispatch, selectedHotelSearchFilters]);
  
  useEffect(() => {
    if (!flightPriceReq || !isEmpty(flightPriceInfo)) return;
    dispatch(setSpecialServices([]));
    dispatch(fetchFlightPrices(flightPriceReq)).then((res) => {
      if (res.payload) {
        dispatch(setSessionFlag(`session_updated_on_${Date()}`));
      }
    });
    const isInternational = checkIsInternationalFlight(
      flightPriceReq[ZERO].price.itineraries
    );
    dispatch(setIsInternational(isInternational));
  }, [dispatch, flightPriceReq, flightPriceInfo]);

  useEffect(() => {
    if (isEmpty(flightPriceReq) || !isEmpty(specialServicesDetails)) return;
    const priceReqForTBO = flightPriceReq.filter(
      (request) => request.source === TBO
    );
    if (priceReqForTBO.every(({ source }) => source === TBO)) {
      const tboReqForSSR = priceReqForTBO.map(
        ({
          tokenId,
          isLCC,
          traceId,
          endUserIp,
          resultIndex,
          source,
          session,
        }) => ({
          source,
          isLCC,
          resultIndex,
          data: {
            TokenId: tokenId,
            TraceId: traceId,
            EndUserIp: endUserIp,
            ResultIndex: resultIndex,
            Session: session,
          },
          commission: {},
        })
      );

      !isEmpty(flightPriceInfo) &&
        dispatch(fetchSpecialServices(tboReqForSSR)).then((res) => {
          if (res.payload) {
            dispatch(setSessionFlag(`session_updated_on_${Date()}`));
          }
        });
    }
  }, [dispatch, flightPriceReq, flightPriceInfo]);

  useEffect(() => {
    if (!isEmpty(flightPriceInfo) || !isEmpty(hotelInfo))
      dispatch(getPromoCodes());
  }, [dispatch, flightPriceInfo, hotelInfo]);

  useEffect(() => {
    if (!isEmpty(selectedFlights) && tripType)
      setToSessionStorage(FLIGHT_DETAILS, selectedFlights);
  }, [selectedFlights, tripType]);

  useEffect(() => {
    const TotalSSRPrice =
      getSSRPrice(selectedBaggages) +
      getSSRPrice(selectedMeals) +
      getSSRPrice(selectedSeats);

    dispatch(setTotalSSRPrice(TotalSSRPrice));
  }, [dispatch, selectedBaggages, selectedMeals, selectedSeats]);

  useEffect(() => {
    if (!selectedCurrentUserInfo || !hasViewRolesPermission) return;
    dispatch(getPermissions());
    dispatch(getTenantsRoles(tenantId));
  }, [dispatch, selectedCurrentUserInfo]);

  useEffect(() => {
    const url = window.location.href;
    const hostName = getHostname(url);
    dispatch(fetchTenantDetails({ hostName }));
  }, []);

  const syncSession = () => {
    const url = new URL(window.location.href);
    let sessionDetails;
    if (url.pathname.includes("hotels")) {
      sessionDetails = getFromSessionStorage("hotelsSession") || EMPTY_OBJECT;
    } else {
      sessionDetails = getFromSessionStorage(SESSION_ID) || EMPTY_OBJECT;
    }
    const { decodedParentIP, sessionParams } = sessionDetails;
    const parentIp = decodedParentIP || endUserIp;
    if (sessionParams && parentIp) {
      
      const { booking, hotelBooking, hotelInfo, pricing, modal, search, session, spinner, trips } = storeState;
      const requestBody = { booking, hotelBooking, hotelInfo, modal, pricing, search, session, spinner, trips }

      dispatch(updateSessionInfo({ sessionParams, requestBody })).then(
        (res) => {
          const message = get(res, "payload.message", "").toLowerCase().trim();
          if (message === SESSION_EXPIRED) {
            dispatch(setSelectedModal(SESSION_EXPIRED_MODAL));
            return;
          } else if (!res.payload?.data)
            dispatch(setSelectedModal(API_FAILURE_MODAL));
          if (url.pathname.includes("hotels")) {
            setToSessionStorage("hotelsSession", {
              ...sessionDetails,
              decodedParentIP: endUserIp,
              encodedParentIP: btoa(endUserIp),
            });
          } else {
            setToSessionStorage(SESSION_ID, {
              ...sessionDetails,
              decodedParentIP: endUserIp,
              encodedParentIP: btoa(endUserIp),
            });
          }
        }
      );
    }
  };

  const throttledSyncSession = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(syncSession, 200);
  };

  useEffect(() => {
    if (!sessionFlag) return;
    throttledSyncSession();
    return () => clearTimeout(timeoutId);
  }, [sessionFlag]);

  useEffect(() => {
    if (!isEmpty(tripDetails) && isEmpty(selectedTravelers)) {
      const userDetails =
        tripDetails?.passengers[0]?.users ||
        tripDetails?.passengers[0]?.travelers;

      const email = userDetails?.email || "";
      const phone = userDetails?.phone || "+91-";
      const address = userDetails?.address || "";
      const city = userDetails?.city || "";
      const state = userDetails?.state || "";

      const nationality = {
        isoCode: "",
        name: userDetails?.country || "India",
      };
      const phoneCode = phone.substring(ZERO, phone.indexOf("-"));
      const phoneNumber = phone.replace("+", "").replace("-", "");
      const contactInfo = { email, phoneCode, phoneNumber };
      const addressInfo = { address, city, nationality, state };
      dispatch(setContactDetails(contactInfo));
      dispatch(setAddressDetails(addressInfo));
      dispatch(
        setSelectedTravelers(mapUsersToTravelers(tripDetails?.passengers))
      );
      dispatch(
          setBookingTravelers(mapUsersToTravelers(tripDetails?.passengers))
      );
    }
  }, [tripDetails]);

  useEffect(() => {
    if (isHomePage) sessionStorage.removeItem(SESSION_ID);
  }, [location.pathname]);

  useEffect(() => {
    isEmpty(walletInfo) &&
      selectedCurrentUserInfo &&
      hasViewWalletPermission &&
      dispatch(getWallet());
  }, [selectedCurrentUserInfo]);

  useEffect(() => {
    if (!selectedTripId) return;
    const pagePathName = window.location.pathname;
    const route = `/bookings/${selectedTripId}`;
    pagePathName !== route && dispatch(fetchTripsById(selectedTripId));
  }, [selectedTripId]);

  useEffect(() => {
    if (!selectedCurrentUserInfo) return;
    const { tenantId } = selectedCurrentUserInfo;
    tenantId &&
      (dispatch(getPolicies(tenantId)), dispatch(getCostCodes(tenantId)));
  }, [selectedCurrentUserInfo]);

  //TODO: AISearch flow for Redirection to the Price Page Directly. 
   // const getHotelPrice = (hotel) => {
  //   const {
  //     price: { publishedPriceRoundedOff },
  //   } = hotel;
  //   return publishedPriceRoundedOff;
  // };

  // useEffect(() => {
  //   if (!hotelResults) return;
  //   const filteredHotelResults = hotelResults.filter((hotel) => {
  //     const price = getHotelPrice(hotel);
  //     const stars = hotel.starRating;
  //     const starRatings = selectedHotelSearchFilters.starRating;
  //     const starsCheck =
  //       !isEmpty(starRatings) && !starRatings.includes(String(stars));
  //     const maxPriceCheck =
  //       selectedHotelSearchFilters.maxPrice &&
  //       price >= selectedHotelSearchFilters.maxPrice;
  //     const minPriceCheck =
  //       selectedHotelSearchFilters.minPrice &&
  //       price <= selectedHotelSearchFilters.minPrice;
  //     return !(starsCheck || maxPriceCheck || minPriceCheck);
  //   });    
  //   dispatch(setFilteredHotelResult(filteredHotelResults));
  // }, [selectedHotelSearchFilters, hotelResults]);

  // useEffect(() => {
  //   if (!isEmpty(selectedFilteredHotels) && isAISearchInitiated) {
  //     const hotelInformation = getFilteredHotelsByCategoryId(selectedFilteredHotels);
  //     const { resultIndex, hotelCode, supplierHotelCodes, source } =
  //       hotelInformation;
  //     const findTraceId = (sourceData, source) => {
  //       const data = sourceData.find((item) => item.source === source);
  //       return data ? data.traceId : null;
  //     };
  //     const checkInDate = selectedHotelSearchFilters.checkInDate || EMPTY_STRING;
  //     const checkOutDate = selectedHotelSearchFilters.checkOutDate || EMPTY_STRING;
  //     const traceId = findTraceId(sourceData, source);
  //     const categoryId = get(
  //       supplierHotelCodes,
  //       "[0].categoryId",
  //       EMPTY_STRING
  //     );
  //     const hotelInfoAPIReq = {
  //       resultIndex,
  //       hotelCode,
  //       categoryId,
  //       checkInDate:checkInDate.replaceAll("/","-"),
  //       checkOutDate:checkOutDate.replaceAll("/","-"),
  //       endUserIp,
  //       traceId,
  //       source: "TBO", //TODO Need to eliminate use of specific provider name
  //     };

  //     dispatch(setHotelInfoReqBody(hotelInfoAPIReq));
  //     setToSessionStorage(HOTEL_INFO_REQUEST_BODY, hotelInfoAPIReq);
  //     navigate(`bookings/${selectedTripId}/hotels/${hotelCode}`);
  //   }
  // }, [selectedFilteredHotels]);

  // const getFilteredHotelsByCategoryId = (filteredHotelResult) => {
  //   let filteredHotels = filteredHotelResult.filter((hotel) => {
  //     const categoryId = get(
  //       hotel,
  //       "supplierHotelCodes[0].categoryId",
  //       EMPTY_STRING
  //     );
  //     return categoryId !== EMPTY_STRING;
  //   });
  //   return filteredHotels[ZERO] || EMPTY_OBJECT;
  // };

    // useEffect(() => {
  //   if (isEmpty(selectedFilteredFlights)) return;
  //   const isPackagedFlightSelection = Object.keys(
  //     selectedFilteredFlights
  //   ).includes(PACKAGE_RESULT);
  //   const flightData = isPackagedFlightSelection
  //     ? get(selectedFilteredFlights, PACKAGE_RESULT, {})
  //     : [
  //         get(selectedFilteredFlights, OUTBOUND_RESULT, {}),
  //         get(selectedFilteredFlights, INBOUND_RESULT, {}),
  //       ];
  //   getFormattedPriceRequest(
  //     flightData,
  //     flightTokens,
  //     navigate,
  //     dispatch,
  //     isReissuance,
  //     selectedTripId
  //   );
  // }, [dispatch, selectedFilteredFlights]);

  return (
    <>
      {shouldShowModal && renderModal(selectedModal)}
      {shouldShowDrawer && renderDrawer(selectedDrawer)}
      {isFlightSearchInitiated && <FlightActiveFilters />}
    </>
  );
};

export default EffectController;
