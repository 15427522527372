import parse from "html-react-parser";
import { BookingImportantInfoPropTypes } from "../../../prop-types";
import { DEFAULT_VALUES } from "../../../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;

const BookingImportantInfo = ({ decodedHotelInfo = EMPTY_STRING }) => (
  <div className='rounded-lg border border-[#f43f5e] shadow-sm overflow-hidden'>
    <div className='px-4 py-3 border-b border-[#f43f5e] bg-gradient-to-r from-[#f43f5e] to-[#be123c]'>
      <h4 className='text-base font-bold text-white flex-1'>
        Important Information
      </h4>
    </div>
    <div>
      <h4 className='font-bold text-contrast-900 mb-2 text-sm m-2'>
        Kind Attention
      </h4>
      <ul className='list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1 p-3'>
        <li>{parse(decodedHotelInfo)}</li>
      </ul>
    </div>
  </div>
);

BookingImportantInfo.propTypes = BookingImportantInfoPropTypes

export default BookingImportantInfo;
