import { useEffect, useMemo, useRef, useState } from "react";
import { get, set, isEmpty, isString } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Country } from "country-state-city";
import SeatSelection from "./SeatSelection";
import MealSelection from "./Meals/MealSelection";
import { v4 as uuid } from "uuid";
import {
  mapBookingAPIRequest,
  getAirportInfoByIata,
  getFormattedTime,
  travelersSortbyTravelerType,
  generateTravelerValidationSchema,
  generateContactValidationSchema,
  generateAddressValidationSchema,
  getPrimaryTravelerIndex,
  getPriceDifference,
  getFromLocalStorage,
} from "../../../helper";
import BookingTravelers from "./BookingTravelers";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  FLIGHT_SERVICE_TYPE,
  TRAVELER_TYPE,
  SSR_BUTTONS,
  INDEX,
  REGEX,
  INITIAL_ADDRESS_DETAILS,
  INITIAL_CONTACT_DETAILS,
  INITIAL_TRAVELER_VALIDATION_SCHEMA,
  TRAVELLER_INFORMATION_SECTIONS,
  SEARCH_SECTION,
} from "../../../constants";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import {
  selectFlightPriceInfo,
  selectFlightPriceReq,
  selectIsInternationalFlight,
} from "../../FlightResults";
import { selectCountryInfo } from "../../Profile";
import TripOverviewCard from "../../../components/molecules/TripOverviewCard";
import {
  selectSelectedTravelers,
  selectSpecialServices,
  selectSelectedLCCSeats,
  setSelectedTravelers,
  setBookingTravelers,
  setGstDetails,
  selectBookingTravelers,
  setFlightBookingReqBody,
  selectAddressDetails,
  selectContactDetails,
  initiateBooking,
  reissuance,
  setTripStatus,
  selectTripStatus,
  selectTripOrder,
  setFlightSearchQuery,
  setIsFlightSearchInitiated,
} from "./index";

import PromoCodeCard from "../../../components/molecules/PromoCodeCard";
import { selectReIssuanceFlightData } from "../../../components/organisms/Search";
import PriceChangedModal from "../../../components/organisms/AppModals/PriceChangedModal";
import FlightDetailChangeInfoModal from "../../../components/organisms/AppModals/FlightDetailChangeInfoModal";
import BaggageSelection from "./Baggages";
import {
  setSelectedModal,
  selectSelectedModal,
} from "../../../components/organisms/Modal";
import { MODALS } from "../../../components/organisms/AppModals";
import { SpecialServicesSkeleton } from "../../../components/organisms/AppSkeletons";
import ContinueButton from "./ContinueButton";
import {
  selectSelectedTrip,
  selectSelectedTripId,
  setSearchTimeLog,
  setShowTimer,
  setShowTimerExpireBanner,
} from "./../Trips";
import { setSessionFlag } from "../../session";
import { setSelectedFilteredFlights, resetFlightPriceInfo } from "../../FlightResults";
import { toast } from "react-toastify";
import { selectCurrentUserInfo } from "../../Auth";

const { LAST_NAME_AMENDMENT_MODAL } = MODALS;
const { EMPTY_ARRAY, EMPTY_OBJECT, ZERO, ONE, EMPTY_STRING, STRING } = DEFAULT_VALUES;
const { SPECIAL_SERVICES, PRICE } = SPINNER_NAMES;
const { FLIGHT_SEARCH_TIME } = CACHE_KEYS;
const { HOTEL } = SEARCH_SECTION;

const { HELD_INFANT } = TRAVELER_TYPE;
const { TRAVELERS, SEATS, MEALS, BAGGAGES, SAVE } = SSR_BUTTONS;
const { SECOND, THIRD } = INDEX;
const carriersToCheck = ["spicejet"];
const { BAGGAGE_COUNT_REGEX } = REGEX;
const NEPAL = "Nepal";
const BHUTAN_AIRLINES = "Bhutan Airlines";
const DEFAULT_BAGGAGE_UNIT = "kg";

const { TBO, AMADEUS } = FLIGHT_SERVICE_TYPE;
const { CONTACT_INFO, ADDRESS_INFO } = TRAVELLER_INFORMATION_SECTIONS;

const getInitialSelectedDetails = (travelerDetails = EMPTY_ARRAY) => {
  const countryList = Country.getAllCountries();
  const contactDetails = travelerDetails.find(
    ({ email, phoneNumber }) => email && phoneNumber
  );

  if (!contactDetails)
    return {
      contactDefaultValues: INITIAL_CONTACT_DETAILS,
      addressDefaultValues: INITIAL_ADDRESS_DETAILS,
    };
  const {
    nationality: nationalityCode,
    email,
    phoneNumber,
    phoneCode,
    address,
    state,
    city,
  } = contactDetails || EMPTY_OBJECT;
  const nationality = countryList.find(
    (country) => country.isoCode === nationalityCode.toUpperCase()
  );
  const contactDefaultValues = {
    email,
    phoneNumber,
    phoneCode,
  };
  const addressDefaultValues = {
    address,
    city,
    state,
    nationality,
  };
  return { contactDefaultValues, addressDefaultValues };
};

const getFormattedBaggageDifference = (currentBaggage, previousBaggage) => {
  const currentBaggageValue = currentBaggage.split(" ");
  const previousBaggageValue = previousBaggage.split(" ");
  const changeInBaggage =
    currentBaggageValue[ZERO] - previousBaggageValue[ZERO];
  const baggageUnit = previousBaggageValue[ONE];
  return { changeInBaggage, baggageUnit };
};

const getBaggageDifference = (flightPriceReq, allFlightPriceRes) => {
  const previousBaggage = flightPriceReq.reduce(
    (acc, requestBody) => {
      const itineraries = get(requestBody, "price.itineraries", EMPTY_ARRAY);
      const itineraryBaggage = itineraries.reduce(
        (itineraryAcc, itinerary) => {
          const baggage = get(itinerary, "segments[0].baggage", "0 kg");
          const match = baggage?.match(BAGGAGE_COUNT_REGEX) || EMPTY_ARRAY;
          const quantity =
            match.length > ZERO ? parseInt(match[SECOND], 10) || ZERO : ZERO;
          const unit =
            match.length > ZERO ? match[THIRD] : DEFAULT_BAGGAGE_UNIT;

          return {
            quantity: itineraryAcc.quantity + quantity,
            unit: unit,
          };
        },
        { quantity: ZERO, unit: DEFAULT_BAGGAGE_UNIT }
      );

      return {
        quantity: acc.quantity + itineraryBaggage.quantity,
        unit: itineraryBaggage.unit,
      };
    },
    { quantity: ZERO, unit: DEFAULT_BAGGAGE_UNIT }
  );
  const combinedPreviousBaggage = `${previousBaggage.quantity} ${previousBaggage.unit}`;

  const segments = get(
    allFlightPriceRes,
    "[0].flightPriceInfo.segments",
    EMPTY_ARRAY
  );
  const currentBaggage = segments.reduce(
    (segmentAcc, segment) => {
      const baggage = get(segment, "[0].Baggage", "0 kg");
      const match = baggage?.match(BAGGAGE_COUNT_REGEX) || EMPTY_ARRAY;
      const quantity =
        match.length > ZERO ? parseInt(match[SECOND], 10) || ZERO : ZERO;
      const unit = match.length > ZERO ? match[THIRD] : DEFAULT_BAGGAGE_UNIT;
      return {
        quantity: segmentAcc.quantity + quantity,
        unit: unit,
      };
    },
    { quantity: ZERO, unit: DEFAULT_BAGGAGE_UNIT }
  );
  const combinedCurrentBaggage = `${currentBaggage.quantity} ${currentBaggage.unit}`;

  return getFormattedBaggageDifference(
    combinedCurrentBaggage,
    combinedPreviousBaggage
  );
};

const TravellerInformation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((store) => store);

  const specialServices = useSelector(selectSpecialServices);
  const selectedTravelers = useSelector(selectSelectedTravelers);
  const selectedContactDetails = useSelector(selectContactDetails);
  const selectedAddressDetails = useSelector(selectAddressDetails);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;
  const selectedSeats = useSelector(selectSelectedLCCSeats);
  const allFlightPriceRes = useSelector(selectFlightPriceInfo);
  const isInternational = useSelector(selectIsInternationalFlight);
  const selectedBookingTravelers = useSelector(selectBookingTravelers);
  const reIssuanceFlightData = useSelector(selectReIssuanceFlightData);
  const selectedModal = useSelector(selectSelectedModal);
  const selectedTripId = useSelector(selectSelectedTripId);
  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedTripStatus = useSelector(selectTripStatus);
  const selectedTripOrder = useSelector(selectTripOrder);
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const { ip: endUserIp } = useSelector(selectCountryInfo) || {};

  const { isAISearchInitiated, hotelSearchQuery,  } = selectedTripStatus;
  const { isReissuance, flightId } = reIssuanceFlightData;
  const reissuanceFlight = selectedTrip?.flights.find(
    (each) => each.id === flightId
  );
  const travelerDetails = get(
    reissuanceFlight,
    "bookingJson.journeyDetails.0.travelerDetails",
    []
  );
  const [selectionProgress, setSelectionProgress] = useState({
    travelerSelection: false,
    seatSelection: false,
    mealSelection: false,
    baggageSelection: false,
  });
  const [presentSpecialServices, setPresentSpecialServices] = useState({
    seatDynamic: false,
    mealDynamic: false,
    baggages: false,
  });
  const [shouldShowModal, setShouldShowModal] = useState(true);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [paxSortByTravelerType, setPaxSortByTravelerType] = useState({});
  const [travelerSectionHighlighted, setTravelerSectionHighlighted] =
    useState(false);
  const [scrollOnError, setScrollOnError] = useState({});

  const noSSRPresent =
    !presentSpecialServices.baggages &&
    !presentSpecialServices.seatDynamic &&
    !presentSpecialServices.mealDynamic;

  const [showFlightInfoChangeModal, setShowFlightInfoChangeModal] =
    useState(false);
  const [areDistinctTraveler, setAreDistinctTraveler] = useState(false);

  const gstFormRef = useRef();
  const contactFormRef = useRef();
  const addressFormRef = useRef();

  const { addressDefaultValues, contactDefaultValues } =
    getInitialSelectedDetails(travelerDetails);

  useEffect(() => {
    setAreDistinctTraveler(hasDistinctNamesAndCarriers(selectedTravelers));
  }, [selectedTravelers]);

  const {
    travelerPricings = EMPTY_ARRAY,
    documentsRequired: bookingRequirements = {},
  } = get(allFlightPriceRes, "0.flightPriceInfo", EMPTY_OBJECT);

  let isPriceChanged = false;
  let grandTotalPrice = 0;
  allFlightPriceRes.map(({ flightPriceInfo }) => {
    isPriceChanged = isPriceChanged || flightPriceInfo.isPriceChanged;
    grandTotalPrice += flightPriceInfo?.price?.offeredFare || ZERO;
  });

  const numberOfTravelers = travelerPricings.reduce(
    (totalCount, { travelersCount }) => totalCount + +travelersCount,
    ZERO
  );
  const selectableSSRServicesCount = travelerPricings.reduce(
    (totalCount, { travelersCount, travelerType }) =>
      totalCount + travelerType !== HELD_INFANT ? +travelersCount : ZERO,
    ZERO
  );

  const changeInPrice =
    isPriceChanged && getPriceDifference(grandTotalPrice, flightPriceReq);

  const showPriceModal = Boolean(
    isPriceChanged && changeInPrice && shouldShowModal
  );

  const { isGSTMandatory, isGSTAllowed } = bookingRequirements;

  useEffect(() => {
    if (selectedModal == "flightOptionsModal") {
      dispatch(setSelectedModal(null));
    }
  }, [])
  useEffect(() => {
    const seatSelection = !specialServices.some(
      ({ data: { seatDynamic, seatPreference } }) =>
        !isEmpty(seatDynamic) || !isEmpty(seatPreference)
    );
    const mealSelection = !specialServices.some(
      ({ data: { mealDynamic, meal } }) =>
        !isEmpty(mealDynamic) || !isEmpty(meal)
    );
    const baggageSelection = !specialServices.some(
      ({ data: { baggage } }) => !isEmpty(baggage)
    );

    setSelectionProgress((preState) => ({
      ...preState,
      seatSelection,
      mealSelection,
      baggageSelection,
    }));
    setPresentSpecialServices({
      seatDynamic: !seatSelection,
      mealDynamic: !mealSelection,
      baggages: !baggageSelection,
    });
  }, [specialServices]);

  const isLccFlight = flightPriceReq[ZERO]?.isLCC || false;
  const isGoingToNepal = get(
    flightPriceReq,
    "0.price.itineraries",
    EMPTY_ARRAY
  ).some(({ segments }) =>
    segments.some((segment) => {
      const { country } = getAirportInfoByIata(segment.arrival.iataCode);
      const carrierName = segment.carrierName;
      return (
        country === NEPAL && carrierName !== BHUTAN_AIRLINES && isLccFlight
      );
    })
  );

  const handleClose = () => setShouldShowModal(false);

  const flightChangedDetailResponse = get(
    allFlightPriceRes,
    "0.flightPriceInfo.flightDetailChangeInfo",
    EMPTY_STRING
  );

  const isInflightDetailsChanged = (valuesInflightChangedDetail) => {
    if (!isString(valuesInflightChangedDetail)) {
      return false;
    }

    const trimmedflightChangedDetail = valuesInflightChangedDetail.trim();

    return !!trimmedflightChangedDetail;
  };

  const hasFlightDetalilsChanged = isInflightDetailsChanged(
    flightChangedDetailResponse
  );

  const { changeInBaggage = ZERO, baggageUnit = DEFAULT_BAGGAGE_UNIT } =
    hasFlightDetalilsChanged &&
    getBaggageDifference(flightPriceReq, allFlightPriceRes);
  const hasBaggageChanged = changeInBaggage !== ZERO;

  const previousTime = get(
    flightPriceReq,
    "[0].price.itineraries[0].segments[0].departure.time",
    EMPTY_STRING
  );
  const currentTime = get(
    allFlightPriceRes,
    "[0].flightPriceInfo.segments[0][0].Origin.DepTime",
    EMPTY_STRING
  );
  const formattedCurrentTime = getFormattedTime(currentTime);
  const hasTimeChanged = formattedCurrentTime !== previousTime;

  useEffect(() => {
    const hasBaggageOrTimeChanged =
      hasFlightDetalilsChanged && (hasBaggageChanged || hasTimeChanged);

    setShowFlightInfoChangeModal(hasBaggageOrTimeChanged);
  }, [hasFlightDetalilsChanged, hasBaggageChanged, hasTimeChanged]);

  useEffect(() => {
    const gstDetails = travelerDetails.find(({ gstDetails }) => gstDetails);
    gstDetails && dispatch(setGstDetails(gstDetails));
  }, [dispatch, travelerDetails]);

  useEffect(() => {
    if (isSaveButtonClicked && noSSRPresent && selectedModal === null) {
      setIsSaveButtonClicked(false);
      handleSave();
    }
  }, [selectedTravelers, selectedModal]);

  const source = get(flightPriceReq, "0.source", TBO);

  const isAmadeusAndInternational = isInternational && source === AMADEUS;

  const handleSave = async () => {
    const flightSearchTime = getFromLocalStorage(FLIGHT_SEARCH_TIME);
    setSelectionProgress({ ...selectionProgress, baggageSelection: true });
    // create the booking API request
    const apiRequest = mapBookingAPIRequest(store);
    const handleSaveCallback = (res) => {
      const resetFlightSearchData = () => {
        dispatch(setFlightBookingReqBody(apiRequest));
        dispatch(setSelectedFilteredFlights(EMPTY_OBJECT));
        dispatch(resetFlightPriceInfo());
        dispatch(
          setTripStatus({ key: "flightSearchQuery", value: EMPTY_OBJECT })
        );
        dispatch(setIsFlightSearchInitiated(false));
        dispatch(setSearchTimeLog(flightSearchTime));
        dispatch(setShowTimer({ id: selectedTripId, showTime: true }));
        dispatch(
          setShowTimerExpireBanner({ id: selectedTripId, flight: false })
        );
        if (
          window.location.pathname.includes(
            `/users/${currentUserInfo?.id}/bookings`
          )
        ) {
          navigate(`/users/${currentUserInfo?.id}/bookings/${selectedTripId}`);
        } else {
          navigate(`/bookings/${selectedTripId}`);
        }
      };
      if (isAISearchInitiated && selectedTripOrder.length === 2) {
        if (selectedTripOrder[1] === HOTEL) {
          redirectToHotelSearch();
        } else {
          resetFlightSearchData();
        }
      } else {
        resetFlightSearchData();
      }
    };
    isReissuance
      ? dispatch(reissuance(apiRequest)).then(handleSaveCallback)
      : dispatch(initiateBooking(apiRequest)).then((res) => {
        handleSaveCallback(res);
        toast.success("Flight saved successfully!");
      });
      dispatch(setSessionFlag(`session_updated_on_${Date()}`));
  };

  const redirectToHotelSearch = () => {
    if (!isEmpty(hotelSearchQuery)) {
      dispatch(setFlightSearchQuery(EMPTY_OBJECT));
      navigate(`/bookings/${selectedTripId}/hotels?${hotelSearchQuery}`)
    }
  };

  const getFormattedDate = (date) => {
    if (isEmpty(date)) return;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  const hasDistinctNamesAndCarriers = (updatedTravelers) => {
    const formattedTravelerNames = updatedTravelers?.map(
      (traveler) =>
        `${traveler?.profileDetails?.firstName?.toLowerCase()}
         ${traveler?.profileDetails?.lastName?.toLowerCase()}`
    );
    const areTravelersNamesDistinct =
      new Set(formattedTravelerNames).size === updatedTravelers?.length;
    return !areTravelersNamesDistinct;
  };

  const handleTravelerContinue = () => {
    const primaryTravelerIndex = getPrimaryTravelerIndex(selectedTravelers);
    let updatedSelectedTravelers = selectedTravelers.map((traveler, index) => {
      const contactFormValues = Object.fromEntries(
        Object.entries(contactFormRef.current?.values || EMPTY_OBJECT).map(([key, value]) => [
          key,
          typeof value === STRING ? value.trim() : value,
        ])
      );
    
      const addressFormValues = Object.fromEntries(
        Object.entries(addressFormRef.current?.values || EMPTY_OBJECT).map(([key, value]) => [
          key,
          typeof value === STRING ? value.trim() : value,
        ])
      );
    
      return {
        ...traveler,
        profileDetails: {
          ...traveler.profileDetails,
          ...contactFormValues,
          ...addressFormValues,
        },
        IsLeadPax: primaryTravelerIndex === index,
      };
    });
    if (isGSTAllowed) {
      const isFormValid =
        !gstFormRef.current?.values?.includeGST || gstFormRef.current?.isValid;

      const updateFormValues = gstFormRef?.current?.values;
      const isGSTValid = isGSTMandatory
        ? gstFormRef.current.isValid
        : isFormValid;
      if (!isGSTValid) return;

      set(
        updatedSelectedTravelers,
        `${primaryTravelerIndex}.gstDetails`,
        updateFormValues
      );
    }

    const isInformationValid =
      contactFormRef.current?.isValid && addressFormRef.current?.isValid;
    if (!isInformationValid) return;

    const hasNoLastName = selectedTravelers.some(
      (item) => !item.profileDetails.lastName
    );
    if (hasNoLastName) {
      updatedSelectedTravelers = updatedSelectedTravelers.map((traveler) => ({
        ...traveler,
        profileDetails: {
          ...traveler.profileDetails,
          lastName:
            traveler.profileDetails?.lastName ||
            traveler.profileDetails?.firstName,
        },
      }));

      const updatedBookingTraveler = selectedBookingTravelers.map(
        (traveler) => {
          const isTravelerSelected = selectedTravelers.some(
            ({ travelerId }) => travelerId === traveler.travelerId
          );

          return isTravelerSelected
            ? {
                ...traveler,
                profileDetails: {
                  ...traveler.profileDetails,
                  lastName:
                    traveler.profileDetails?.lastName ||
                    traveler.profileDetails?.firstName,
                },
              }
            : traveler;
        }
      );
      dispatch(setBookingTravelers(updatedBookingTraveler));
      dispatch(setSelectedModal(LAST_NAME_AMENDMENT_MODAL));
    }
    dispatch(setSelectedTravelers(updatedSelectedTravelers));

    !hasDistinctNamesAndCarriers(updatedSelectedTravelers) &&
      !noSSRPresent &&
      setSelectionProgress({
        ...selectionProgress,
        travelerSelection: true,
        seatSelection: !presentSpecialServices?.seatDynamic,
      });
      dispatch(setSessionFlag(`session_updated_on_${Date()}`));
      return true;
  };

  useEffect(() => {
    const requiredFormattedTravelers = travelersSortbyTravelerType(
      dispatch,
      isReissuance,
      travelerDetails,
      selectedBookingTravelers,
      setSelectedTravelers
    );
    setPaxSortByTravelerType(requiredFormattedTravelers);
  }, [dispatch, isReissuance, selectedBookingTravelers]);
  const travelerValidationSchema = useMemo(() => {
    if (!isEmpty(paxSortByTravelerType)) {
      const paxType = Object.keys(paxSortByTravelerType);
      return paxType.reduce((acc, type) => {
        acc[type] = generateTravelerValidationSchema(
          type,
          isAmadeusAndInternational,
          isGoingToNepal,
          flightPriceReq,
          allFlightPriceRes,
          t
        );
        return acc;
      }, INITIAL_TRAVELER_VALIDATION_SCHEMA);
    }
  }, [paxSortByTravelerType, allFlightPriceRes]);

  const contactDetailsValidationSchema = useMemo(() => {
    return generateContactValidationSchema(t);
  }, []);

  const addressDetailsValidationSchema = useMemo(() => {
    return generateAddressValidationSchema(travelerPricings);
  }, []);

  const continueButtonProps = {
    [TRAVELERS]: {
      name: TRAVELERS,
      shouldShowButton: !noSSRPresent && !selectionProgress.travelerSelection,
      customOnClick: () => {
        gstFormRef.current?.handleSubmit();
        contactFormRef.current?.handleSubmit();
        addressFormRef.current?.handleSubmit();
      },
      isContinueDisabled:
        areDistinctTraveler || numberOfTravelers !== selectedTravelers?.length,
      ...(areDistinctTraveler && {
        message: t("travelerInfo.error.distinctTraveler"),
      }),
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
        selectedAddressAndContactDetails: [
          {
            data: selectedContactDetails,
            validationSchema: contactDetailsValidationSchema,
            section: CONTACT_INFO,
          },
          {
            data: selectedAddressDetails,
            validationSchema: addressDetailsValidationSchema,
            section: ADDRESS_INFO,
          },
        ],
      },
      setTravelerSectionHighlighted,
      setScrollOnError,
      handleContinue: handleTravelerContinue,
    },

    [SEATS]: {
      name: SEATS,
      shouldShowButton:
        selectionProgress.travelerSelection && !selectionProgress.seatSelection,
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
      },
      handleContinue: () =>{
        setSelectionProgress({
          ...selectionProgress,
          seatSelection: true,
          mealSelection: !presentSpecialServices?.mealDynamic,
        }),
        dispatch(setSessionFlag(`session_updated_on_${Date()}`));
      }
    },

    [MEALS]: {
      name: MEALS,
      shouldShowButton:
        selectionProgress.travelerSelection &&
        selectionProgress.seatSelection &&
        !selectionProgress.mealSelection,
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
      },
      handleContinue: () =>{
        setSelectionProgress({
          ...selectionProgress,
          mealSelection: true,
          baggageSelection: !presentSpecialServices?.baggages,
        });
        dispatch(setSessionFlag(`session_updated_on_${Date()}`));
      }
    },

    [BAGGAGES]: {
      name: BAGGAGES,
      shouldShowButton:
        selectionProgress.travelerSelection &&
        selectionProgress.seatSelection &&
        selectionProgress.mealSelection,
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
      },
      handleContinue: handleSave,
    },

    [SAVE]: {
      name: SAVE,
      shouldShowButton: noSSRPresent,
      customOnClick: () => {
        gstFormRef.current?.handleSubmit();
        contactFormRef.current?.handleSubmit();
        addressFormRef.current?.handleSubmit();
      },
      isContinueDisabled:
        areDistinctTraveler || numberOfTravelers !== selectedTravelers?.length,
      ...(areDistinctTraveler && {
        message: t("travelerInfo.error.distinctTraveler"),
      }),
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
      },
      setTravelerSectionHighlighted,
      handleContinue: () => {
        handleTravelerContinue() && setIsSaveButtonClicked(true);
      },
    },
  };

  return (
    <div className='flex flex-col align-middle min-h-[70vh]'>
      <div>
        <div className='grid grid-cols-12 items-start gap-6 md:gap-10'>
          <div className='col-span-12 lg:col-span-8'>
            <div className='block lg:hidden col-span-12 lg:col-span-4 bg-white rounded-2xl'>
              <TripOverviewCard />
            </div>
            <BookingTravelers
              selectionProgress={selectionProgress}
              setSelectionProgress={setSelectionProgress}
              gstFormRef={gstFormRef}
              contactFormRef={contactFormRef}
              addressFormRef={addressFormRef}
              contactDefaultValues={contactDefaultValues}
              addressDefaultValues={addressDefaultValues}
              isReissuanceFlight={isReissuance}
              isGoingToNepal={isGoingToNepal}
              travelersSortTravelerType={paxSortByTravelerType}
              travelerValidationSchema={travelerValidationSchema}
              highlightErrorBorder={travelerSectionHighlighted}
              bookingTravellerErrorSections={scrollOnError}
            />
            <ContinueButton {...continueButtonProps[TRAVELERS]} />
            <Spinner
              name={[SPECIAL_SERVICES, PRICE]}
              showSkeleton={true}
              loaderComponent={<SpecialServicesSkeleton />}
            >
              {specialServices.some(
                ({ data: { seatDynamic, seatPreference } }) =>
                  !isEmpty(seatDynamic) || !isEmpty(seatPreference)
              ) && (
                <div>
                  <SeatSelection
                    selectionProgress={selectionProgress}
                    setSelectionProgress={setSelectionProgress}
                    presentSpecialServices={presentSpecialServices}
                  />
                  <ContinueButton {...continueButtonProps[SEATS]} />
                </div>
              )}
              {specialServices.some(
                ({ data: { mealDynamic, meal } }) =>
                  !isEmpty(mealDynamic) || !isEmpty(meal)
              ) && (
                <div>
                  <MealSelection
                    selectionProgress={selectionProgress}
                    setSelectionProgress={setSelectionProgress}
                  />
                  <ContinueButton {...continueButtonProps[MEALS]} />
                </div>
              )}
              {specialServices.some(
                ({ data: { baggage } }) => !isEmpty(baggage)
              ) && (
                <BaggageSelection
                  selectionProgress={selectionProgress}
                  setSelectionProgress={setSelectionProgress}
                />
              )}
              {noSSRPresent ? (
                <ContinueButton {...continueButtonProps[SAVE]} />
              ) : (
                <ContinueButton {...continueButtonProps[BAGGAGES]} />
              )}
            </Spinner>
            <div className='block lg:hidden col-span-12 lg:col-span-4 bg-white rounded-2xl'>
              <PromoCodeCard />
            </div>
          </div>
          <div className='hidden lg:block col-span-12 lg:col-span-4 bg-white rounded-2xl'>
            <TripOverviewCard />
            <PromoCodeCard />
          </div>
        </div>
        {showPriceModal && (
          <PriceChangedModal
            handleClose={handleClose}
            changeInPrice={changeInPrice}
          />
        )}
        {showFlightInfoChangeModal && (
          <FlightDetailChangeInfoModal
            handleClose={() => setShowFlightInfoChangeModal(false)}
            changeInBaggage={changeInBaggage}
            baggageUnit={baggageUnit}
            hasTimeChanged={hasTimeChanged}
            currentTime={formattedCurrentTime}
          />
        )}
      </div>
    </div>
  );
};

export default TravellerInformation;
