import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { get, isEmpty } from "lodash";
import { createWallet, updateWallet, selectWalletInfo } from "./index";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { MODALS } from "../../components/organisms/AppModals";
import { selectCurrentUserInfo } from "../Auth";
import { getTravelers } from "../Profile";
import {
  checkIfUserHasPermission,
  priceFormatter,
  useClickOutside,
} from "../../helper";
import DeleteConfirmationModal from "../../components/organisms/AppModals/DeleteConfirmationModal";
import {
  KebabMenu,
  PlusGray,
  RenderSVG,
  ExclamationCircleIcon,
  FillEmail,
  PhoneIcon,
  AddressIcon,
  DomainIcon,
  CurrencyIcon,
  OrganisationIcon,
  LicenseIcon,
  ActiveIcon,
  LanguageIcon,
  CountryIcon
} from "../../assets/icons";
import { DEFAULT_VALUES, AVAILABLE_PERMISSIONS, ROUTES } from "../../constants";
import { selectCountryInfo, selectUserInfo } from "../Profile";

const { CREATE_WALLET, UPDATE_WALLET, GET_WALLET } = SPINNER_NAMES;
const { DEFAULT_COMPANY_NAME, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const walletDisableTitle = "Disable Your Wallet?";
const disablePrimaryButtonText = "Disable Wallet";
const { DELETE_CONFIRMATION_MODAL } = MODALS;
const { CAN_VIEW_WALLET, CAN_UPDATE_WALLET, CAN_CREATE_WALLET } =
  AVAILABLE_PERMISSIONS;
const { TRANSACTIONS } = ROUTES;

const Account = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const walletInfo = useSelector(selectWalletInfo);
  const selectedCurrentUserInfo = useSelector(selectUserInfo) || EMPTY_OBJECT;
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const tenantName = get(
    selectedCurrentUserInfo,
    "tenant.name",
    DEFAULT_COMPANY_NAME
  );
  const [initialValues, setInitialValues] = useState({
    companyName: tenantName,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState(null);
  const menuRef = useRef();

  const { balance, id, isEnabled } = walletInfo;

  const isBalanceAvailable = parseFloat(balance) > 0;
  const availablePermissions = get(selectedCurrentUserInfo, "permissions", []);

  const accountDetailsValidationSchema = Yup.object().shape({
    companyName: Yup.string().required("Enter Company Name"),
  });
  const tenant = get(selectedCurrentUserInfo, "tenant", DEFAULT_COMPANY_NAME);
  const {
    name,
    email,
    primaryDomain,
    license,
    isActive,
    country,
    defaultCurrency,
    defaultLanguage,
    address,
    phone,
    billingEmail,
  } = tenant;

  const hasCanViewWalletPermission =
    selectedCurrentUserInfo &&
    checkIfUserHasPermission(CAN_VIEW_WALLET, availablePermissions);
  const hasCanUpdateWalletPermission =
    selectedCurrentUserInfo &&
    checkIfUserHasPermission(CAN_UPDATE_WALLET, availablePermissions);
  const hasCanCreateWalletPermission =
    selectedCurrentUserInfo &&
    checkIfUserHasPermission(CAN_CREATE_WALLET, availablePermissions);
  const currencySymbol = selectedCountryInfo?.currency?.symbol;

  useClickOutside(menuRef, setIsDropdownOpen);
  useEffect(()=>{
    const  { id = EMPTY_STRING}  = selectedCurrentUserInfo;
    !isEmpty(id) && dispatch(getTravelers(id))
  },[])

  const handleClose = () => setSelectedModal(null);

  const handleWalletUpdate = (isEnable) => {
    dispatch(updateWallet({ id, body: { isEnabled: isEnable } })).then(
      (res) => {
        if (res.payload) !isEnable && handleClose();
      }
    );
  };

  const handleCreateWallet = () => {
    dispatch(createWallet());
  };

  const handleOnSaved = (values) => {
    //ToDo: Need to integrate the API for updating account details
  };

  return (
    <>
      <div className='main min-h-full  flex flex-col bg-contrast-100 px-8 pt-4'>
        <div className='overflow-auto'>
          <div className='bg-white rounded-2xl mb-8'>
            <div className='border-b border-contrast-200 py-6 px-6'>
              <h4 className='text-lg text-contrast-900 font-semibold'>
                {t("accountSettings.companyInformation")}
              </h4>
            </div>
            <div className='flex'>
              <ul className='ml-5 flex-col  gap-3 m-4 mt-0 p-4 container text-wrap'>
                {name && (
                  <li className='flex justify-start gap-6'>
                    <div className=' text-md font-medium w-4/12 text-gray-700'>
                      Name
                    </div>
                    <div className='text-gray-500 gap-3 flex w-8/12'>
                      <RenderSVG
                        Svg={OrganisationIcon}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {name}
                    </div>
                  </li>
                )}
                {email && (
                  <li className='flex justify-start gap-6'>
                    <div className='text-md flex w-1/3 text-gray-700  font-medium'>
                      Email
                    </div>
                    <div className='text-gray-500 gap-3 w-8/12 flex'>
                      <RenderSVG
                        Svg={FillEmail}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {email}
                    </div>
                  </li>
                )}
                {primaryDomain && (
                  <li className='flex justify-start gap-6'>
                    <div className='text-md flex text-gray-700 w-1/3 font-medium'>
                      Primary Domain
                    </div>
                    <div className='text-gray-500 gap-3 w-8/12 flex'>
                      <RenderSVG
                        Svg={DomainIcon}
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {primaryDomain}
                    </div>
                  </li>
                )}
                {license && (
                  <li className='flex justify-start gap-6'>
                    <div className='text-md flex text-gray-700 w-1/3 font-medium'>
                      License
                    </div>
                    <div className='text-gray-500 gap-3 w-8/12 flex'>
                      <RenderSVG
                        Svg={LicenseIcon}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {license}
                    </div>
                  </li>
                )}
                {!!(isActive !== undefined) && (
                  <li className='flex justify-start gap-6'>
                    <div className='text-md flex text-gray-700 w-1/3 font-medium'>
                      Is Active
                    </div>
                    <div className='text-gray-500 gap-3 w-8/12 flex'>
                      <RenderSVG
                        Svg={ActiveIcon}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {`${isActive}`}
                    </div>
                  </li>
                )}
                {country && (
                  <li className='flex justify-start gap-6'>
                    <div className='text-md flex text-gray-700 w-1/3 font-medium'>
                      Country
                    </div>
                    <div className='text-gray-500 gap-3 w-8/12 flex'>
                      <RenderSVG
                        Svg={CountryIcon}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />

                      {country}
                    </div>
                  </li>
                )}
                {defaultCurrency && (
                  <li className='flex justify-start gap-6'>
                    <div className='text-md flex text-gray-700 w-1/3 font-medium'>
                      Default Currency
                    </div>
                    <div className='text-gray-500 gap-3 w-8/12 flex'>
                      <RenderSVG
                        Svg={CurrencyIcon}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {defaultCurrency}
                    </div>
                  </li>
                )}
                {defaultLanguage && (
                  <li className='flex justify-start gap-6'>
                    <div className='text-md flex text-gray-700 w-1/3 font-medium'>
                      Default Language
                    </div>
                    <div className='text-gray-500 gap-3 w-8/12 flex'>
                      <RenderSVG
                        Svg={LanguageIcon}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {defaultLanguage}
                    </div>
                  </li>
                )}
                {address && (
                  <li className='flex justify-start gap-6'>
                    <div className='text-md flex text-gray-700 w-1/3 font-medium'>
                      Address
                    </div>
                    <div className='text-gray-500 gap-3 w-8/12 flex'>
                      <RenderSVG
                        Svg={AddressIcon}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {address}
                    </div>
                  </li>
                )}
                {phone && (
                  <li className='flex justify-start gap-6'>
                    <div className='text-md flex text-gray-700 w-1/3 font-medium'>
                      Phone
                    </div>
                    <div className='text-gray-500 w-8/12 gap-3 flex'>
                      <RenderSVG
                        Svg={PhoneIcon}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {phone}
                    </div>
                  </li>
                )}
                {billingEmail && (
                  <li className='flex justify-start  gap-6'>
                    <div className='text-md flex text-gray-700 w-1/3 font-medium'>
                      Billing Email
                    </div>
                    <div className='text-gray-500 gap-3 w-8/12 flex'>
                      <RenderSVG
                        Svg={FillEmail}
                        width='16'
                        alt='plus-gray'
                        fill='#433C71'
                      />
                      {billingEmail}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {hasCanViewWalletPermission &&
          selectedCurrentUserInfo.isWalletEnabled && (
            <div className='bg-white rounded-2xl border border-contrast-200'>
              <div className='pt-4'>
                <div className='p-4 border-b border-contrast-200'>
                  <h4 className='text-lg text-contrast-900 font-semibold'>
                    {t("accountSettings.companyWallet")}
                  </h4>
                  <p className='text-sm text-contrast-500'>
                    {t("accountSettings.manageEmployeeTravelFunds")}
                  </p>
                </div>
                <div className='h-60'>
                  <Spinner name={GET_WALLET}>
                    {isEmpty(walletInfo) ? (
                      <Spinner name={CREATE_WALLET}>
                        <div className='border-t border-contrast-200'>
                          <div className='min-h-[200px] flex justify-center items-center'>
                            {hasCanCreateWalletPermission ? (
                              <div className='text-center'>
                                <p className='text-sm text-contrast-900 font-medium mb-4'>
                                  {t("accountSettings.noBankAccount")}
                                </p>
                                <button
                                  onClick={handleCreateWallet}
                                  className='btn text-xs text-contrast-700 font-medium inline-flex items-center gap-2 border border-contrast-300 hover:bg-contrast-50 p-2 shadow-sm rounded'
                                >
                                  <RenderSVG
                                    Svg={PlusGray}
                                    width='16'
                                    alt='plus-gray'
                                  />
                                  <span>
                                    {t("accountSettings.connectAccount")}
                                  </span>
                                </button>
                              </div>
                            ) : (
                              <div className='text-center'>
                                <p className='text-sm text-contrast-900 font-medium mb-4'>
                                  {t(
                                    "accountSettings.noWalletCreatePermission"
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </Spinner>
                    ) : (
                      <div className='px-6 pt-6 pb-6 mb-2 relative'>
                        {!isEnabled && (
                          <div className='absolute inset-0 flex h-60 rounded-b-2xl flex-col items-center justify-center bg-contrast-200 bg-opacity-75 z-20'>
                            <p className='mb-4'>
                              {t("accountSettings.walletDisabled")}
                            </p>
                            <button
                              className='text-white bg-primary-600 py-2 px-4 rounded'
                              onClick={() => handleWalletUpdate(true)}
                            >
                              <Spinner name={UPDATE_WALLET} size='w-20'>
                                {t("accountSettings.enableWallet")}
                              </Spinner>
                            </button>
                          </div>
                        )}
                        <div className='flex items-center justify-between gap-5 mb-2'>
                          <span className='text-base font-medium text-contrast-900'>
                            {t("accountSettings.availableBalance")}
                          </span>

                          <button
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          >
                            <RenderSVG Svg={KebabMenu} width='20' height='20' />
                          </button>

                          {isDropdownOpen && (
                            <div
                              ref={menuRef}
                              className='absolute z-10 top-9 right-1 rounded-lg shadow-2xl bg-white p-4'
                            >
                              <button
                                className='text-sm font-light text-contrast-900 text-left px-4 pt-2 cursor-pointer w-full hover:text-primary-700 mb-1'
                                onClick={() =>
                                  setSelectedModal(DELETE_CONFIRMATION_MODAL)
                                }
                              >
                                {t("accountSettings.disableWallet")}
                              </button>
                            </div>
                          )}
                        </div>
                        <h3 className='text-4xl text-primary-600 font-extrabold mb-4 flex w-3'>
                          {!currencySymbol ? (
                            <Spinner showSpinner={true} />
                          ) : (
                            <span>
                              {currencySymbol}&nbsp;{priceFormatter(balance)}
                            </span>
                          )}
                        </h3>
                        <div className='flex items-center gap-2'>
                          {!isBalanceAvailable && (
                            <span className='text-sm font-medium text-contrast-500'>
                              {t("accountSettings.noFundsAvailable")}
                            </span>
                          )}
                        </div>
                        <Link
                          to={TRANSACTIONS}
                          state={{ shouldShowWalletTransactions: true }}
                          className='text-primary-600 py-2 underline underline-offset-2 text-sm font-medium'
                        >
                          {t("accountSettings.viewTransactions")}
                        </Link>
                      </div>
                    )}
                  </Spinner>
                </div>
              </div>
            </div>
          )}
      </div>    
      {selectedModal === DELETE_CONFIRMATION_MODAL && (
        <DeleteConfirmationModal
          title={walletDisableTitle}
          primaryButtonText={disablePrimaryButtonText}
          handleSubmit={() => handleWalletUpdate(false)}
          handleClose={handleClose}
          iconName={ExclamationCircleIcon}
          spinnerName={UPDATE_WALLET}
          hasUpdatePermission={hasCanUpdateWalletPermission}
        >
          <div>
            <p className='text-sm pb-4'>
              {hasCanUpdateWalletPermission
                ? "Are you sure you want to disable your wallet"
                : "You do not have permission to disable wallet"}
            </p>
          </div>
        </DeleteConfirmationModal>
      )}
    </>
  );
};

export default Account;
