import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { REQUEST_METHODS, DEFAULT_VALUES } from "../../constants";
import { toast } from "react-toastify";
import Spinner,{SPINNER_NAMES} from "../../components/organisms/Spinner";

const { GET } = REQUEST_METHODS;
const { EMPTY_STRING } = DEFAULT_VALUES
const {FETCH_TRANSACTIONS}=SPINNER_NAMES

const createTransactionParams = (pageSize, pageNumber ,searchKey) => {
  return pageSize && pageNumber || searchKey? `?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=${searchKey}` : EMPTY_STRING;
}

export const fetchTransactionDetails = createAsyncThunk(
  "fetch-transactions-details",
  async (queryParams, thunkArgs) => {
    const { pageSize, pageNumber, tenantId ,searchKey } = queryParams;
    if(!tenantId){
      toast.error("Please login again");
      return;
    } 
    const params = createTransactionParams(pageSize, pageNumber , searchKey);
    return await asyncAction({
      url: `/tenants/${tenantId}/transactions${params}`,
      methodType: GET,
      showErrorToast: true,
      abortOnPageChange: false,
      isAuthRequired: true,
      spinner:FETCH_TRANSACTIONS,
      errorMessage: "Failed to fetch transaction details",
      ...thunkArgs,
    });
  }
);
