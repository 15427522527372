import { get } from "lodash";
import {
  getAirlineIconUrlByCarrierCode,
  getAirportInfoByIata,
  getDayFromDate,
  getFormattedLongDate,
  getStopLabel,
  getTotalTimeDifference,
  timeFormatter,
} from "../../../helper";
import { DEFAULT_VALUES } from "../../../constants";
import { useTranslation } from "react-i18next";
import { getStops } from "../../../utils/flights.utils";

const { ZERO, ONE, EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY } = DEFAULT_VALUES;

const OneWayApprovalsCard = (itinerary) => {
  const { t } = useTranslation();
  const segments = get(itinerary, "itinerary.segments");
  const firstSegment = segments[ZERO];
  const lastSegment = segments[segments.length - ONE];
  const totalStops = segments.length - ONE;

  const cabinClass = get(firstSegment, "cabinClass", "");
  const carrierCode = get(firstSegment, "carrierCode", "");
  const { departure = EMPTY_OBJECT } = firstSegment || EMPTY_OBJECT;
  const { arrival = EMPTY_OBJECT } = lastSegment || EMPTY_OBJECT;
  const journeyStartDate = departure.date;
  const journeyStartDay = journeyStartDate && getDayFromDate(journeyStartDate);
  const stopsLabel = getStopLabel(totalStops, t);
  return (
    <div className='py-4 px-4'>
      <h6 className='text-base 2xl:text-xl text-contrast-900 font-semibold mb-4'>
        {getFormattedLongDate(journeyStartDate, {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}{" "}
        {/* Fri, April 19, 2024 */}
      </h6>
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-12 lg:col-span-5 xl:col-span-4 2xl:col-span-5'>
          <div className='flex items-center gap-4'>
            <div className='logo'>
              <img
                src={getAirlineIconUrlByCarrierCode(carrierCode)}
                width='30'
                className='rounded border border-contrast-300'
                alt='airline logo'
              />
            </div>
            <div className='flex-1'>
              <h5 className='text-base 2xl:text-lg font-semibold text-contrast-900 mb-1'>
                {timeFormatter(departure.time)} - {timeFormatter(arrival.time)}
              </h5>
              <p className='text-xs text-contrast-600'>United</p>
            </div>
          </div>
        </div>
        <div className='col-span-12 lg:col-span-7 xl:col-span-8 2xl:col-span-7'>
          <div className=' flex gap-4'>
            <div className='flex-1'>
              <h5 className='text-base 2xl:text-lg text-contrast-900 font-semibold mb-1'>
                {getTotalTimeDifference(
                  departure.iataCode,
                  departure.date,
                  departure.time,
                  arrival.iataCode,
                  arrival.date,
                  arrival.time
                )}
              </h5>
              <p className='text-xs text-contrast-600'>
                {departure.iataCode}-{arrival.iataCode}
              </p>
            </div>
            <div className='flex-1 text-end sm:text-start'>
              <h5 className='text-base 2xl:text-lg text-contrast-900 font-semibold mb-1'>
                {stopsLabel}
              </h5>

              <p className='text-xs text-contrast-600'>{getStops(segments)}</p>
            </div>
            <div className='flex-1 text-end sm:text-start'>
              <h5 className='text-base 2xl:text-lg text-contrast-900 font-semibold mb-1'>
                {cabinClass}
              </h5>
              <p className='text-xs text-contrast-600'>UA 1764</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneWayApprovalsCard;
