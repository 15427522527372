import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { cloneDeep, get } from "lodash";
import classNames from "classnames";
import { DEFAULT_VALUES, TRIP_TYPES, BOOKING_STATUS_CODES } from "../../../../constants";
import { getCityNameByIata } from "../../../../helper";
import { ArrowLeft, RenderSVG } from "../../../../assets/icons";
import { selectCurrentUserInfo } from "../../../Auth";

const { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, ZERO, ONE } = DEFAULT_VALUES;
const { CANCELLED, FAILED } = BOOKING_STATUS_CODES;
const { ROUND_TRIP, ONE_WAY } = TRIP_TYPES;
const DEFAULT_PAX_COUNT = {
  ADULT: 0,
  CHILD: 0,
  HELD_INFANT: 0,
};
const TRAVEL_CLASS = {
  Economy: "ECONOMY",
  Business: "BUSINESS",
  First: "FIRST",
  Premium_Economy: "PREMIUM_ECONOMY",
};

const getSearchFilters = (flightData, paxData, bookingId, pnr) => {
  const { departure, arrival, cabinClass } = flightData[ZERO] || EMPTY_OBJECT;
  const pax = paxData.reduce((paxAcc, { travelerType }) => {
    paxAcc[travelerType] += ONE;
    return paxAcc;
  }, cloneDeep(DEFAULT_PAX_COUNT));

  const tripType = flightData.length > ONE ? ROUND_TRIP : ONE_WAY;
  const destCode = {
    cityName: arrival.cityName,
    iata: arrival.iataCode,
    airportName: arrival.airportName,
  };
  const originCode = {
    cityName: departure.cityName,
    iata: departure.iataCode,
    airportName: departure.airportName,
  };
  const JourneyDetails = {
    destCode,
    originCode,
    departureDate: departure.date || EMPTY_STRING,
    departureTime: departure.time || EMPTY_STRING,
    returnDate: arrival.date || EMPTY_STRING,
    returnTime: arrival.time || EMPTY_STRING,
  };

  const reIssuanceObject = {
    searchType: ONE,
    bookingId,
    pnr,
  };

  const searchFilters = {
    journey: [JourneyDetails],
    tripType,
    travelClass: TRAVEL_CLASS[cabinClass] || TRAVEL_CLASS.Economy,
    adult: pax.ADULT || ONE,
    children: pax.CHILD || ZERO,
    infants: pax.HELD_INFANT || ZERO,
    ...reIssuanceObject,
  };
  return searchFilters;
};

const RenderLocation = ({ locationData }) => {
  const { iataCode, airportName, time } = locationData;
  
  return (
    <div>
      <h5 className='text-sm font-bold text-contrast-900 mb-1'>
        {getCityNameByIata(iataCode)} ({iataCode})
      </h5>
      <div className='text-xs text-contrast-600 flex gap-2'>
        <span>{time}</span>
        <span className='inline-block w-24'>{airportName}</span>
      </div>
    </div>
  );
};

const Flights = ({ flightData }) => {
  const navigate = useNavigate();
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const {
    id,
    tripId,
    itineraries,
    travelerDetails,
    bookingId,
    pnr,
    isSuccessReIssuedBooking = false,
  } = flightData;
  const locationsArray = itineraries.map(({ segments = EMPTY_ARRAY }) => {
    const { departure, cabinClass, carrierCode } = segments[0] || EMPTY_OBJECT;
    const arrival = get(
      segments,
      `${segments.length - ONE}.arrival`,
      EMPTY_OBJECT
    );
    return { departure, arrival, cabinClass, carrierCode };
  });

  const searchFilters = getSearchFilters(
    locationsArray,
    travelerDetails,
    bookingId,
    pnr
  );

  const handleOnClick = () => {
    if (!isSuccessReIssuedBooking) {
      const flightSearchRoute = window.location.pathname.includes(
        `/users/${currentUserInfo?.id}/bookings`
      )
        ? `/users/${currentUserInfo?.id}/bookings/${tripId}/flights`
        : `/bookings/${tripId}/flights`;
  
      navigate(flightSearchRoute, {
        state: { isReissuance: true, flightId: id, searchFilters },
      });
    }
  };
  

  return (
    <div className='flex-1'>
      <p className='text-xs text-contrast-600 mb-1'>
        {locationsArray.map(({ departure, arrival }) => (
          <div className='flex justify-between'>
            <RenderLocation locationData={departure} />
            <div className='icon shrink-0 opacity-50'>
              <RenderSVG
                Svg={ArrowLeft}
                width='20'
                alt='Left Arrow'
                stroke='#000000'
              />
            </div>
            <RenderLocation locationData={arrival} />
          </div>
        ))}
      </p>
      <h5
        className={classNames("text-sm font-semibold", {
          "text-primary-600 hover:cursor-pointer": !isSuccessReIssuedBooking,
        })}
        onClick={handleOnClick}
      >
        <span className='flex justify-center'>
          {!isSuccessReIssuedBooking
            ? "Reissuance Ticket"
            : "ReIssuance Already done for the Itinerary"}
        </span>
      </h5>
    </div>
  );
};

const Reissuance = ({ flightData }) => {
  return (
    <div className='border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6 print:hidden'>
      <div className='p-6'>
        <h4 className='font-bold text-contrast-900 text-base mb-5'>
          Re-issuance
        </h4>
        <div className='flex flex-col gap-3'>
          {flightData.map((data) => (
            (data.tenantStatus !== CANCELLED && data.tenantStatus !== FAILED) && <Flights key={data.id} flightData={data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reissuance;
