export const SPINNER_NAMES = {
  FETCH_FLIGHTS_RESULTS: "fetchFlightResults",
  SIGNIN: "signin",
  SEARCH: "search",
  PRICE: "price",
  BOOK_FLIGHT: "book",
  REISSUANCE_FLIGHT: "reissuance",
  SPECIAL_SERVICES: "specialServices",
  SIGNUP: "signUp",
  MAIN_SPINNER: "mainSpinner",
  BOOKINGS: "bookings",
  FETCH_LOGS: "fetchLogs",
  FLIGHT_PAYMENT: "payment",
  PAYMENT_PROVIDER: "providers",
  VERIFY_OTP: "verifyOtp",
  RESET_PASSWORD_BY_OTP: "resetPasswordByOtp",
  AUTH: "auth",
  PAYMENT_METHOD: "paymentMethod",
  FETCH_HOTEL_RESULTS: "fetchHotelResults",
  FETCH_HOTEL_INFO: "fetchHotelInfo",
  BOOK_CALLBACK: "bookCallback",
  HOTEL_BOOK_CALLBACK: "hotelBookCallback",
  PENDING: "pending",
  FETCH_USER: "fetchUser",
  ADD_TRAVELER: "addTraveler",
  DELETE_TRAVELER: "deleteTraveler",
  UPDATE_TRAVELER: "updateTraveler",
  FETCH_LOCATIONS: "fetchLocations",
  FETCH_ADMIN_BOOKING_INFO: "fetchAdminBookingInfo",
  SUBMIT_QUERY: "submitQuery",
  FLIGHT_CANCELLATION: "flightCancellation",
  CANCELLATION_CHARGES: "cancellationCharges",
  BOOKING_REFUND: "flightRefund",
  VERIFY_HOTEL_PRICE_POLICY: "verifyHotelPricePolicy",
  HOTEL_CANCELLATION: "hotelCancellation",
  PROCESS_PAYMENT: "processPayment",
  FETCH_USER_BOOKING_INFO: "fetchUserBookingInfo",
  GENERATE_VOUCHER: "generateVoucher",
  HOTEL_BOOKING: "initiateHotelBooking",
  FETCH_USERS: "fetchUsers",
  UPDATE_USERS: "updateUsers",
  DELETE_USERS: "deleteUsers",
  ACCEPT_INVITATION: "acceptInvitation",
  RESEND_INVITATION: "resendInvitaion",
  INVITATION_DETAIL: "invitationDetail",
  FORGOT_PASSWORD: "forgotPassword",
  LOGIN: "Login",
  PERMISSIONS: "permissions",
  ADD_USER: "addUser",
  GET_POLICY: "getPolicy",
  UPDATE_POLICY: "updatePolicy",
  CREATE_WALLET: "createWallet",
  GET_WALLET: "getWallet",
  UPDATE_WALLET: "updateWallet",
  FETCH_ANALYTICS_INFO: "fetchAnalyticsInfo",
  FETCH_TRIPS: "fetchTrips",
  CREATE_TRIP: "createTrip",
  GET_ROLES_PERMISSIONS: "GetRolesPermissions",
  PROMPT_SEARCH: "promptSearch",
  FETCH_WALLET_TRANSACTIONS: "fetchWalletTransactions",
  FETCH_INVITES:"fetchInvites",
  DELETE_USERS:"deleteUsers",
  FETCH_HOTEL_STATIC_DATA: "FetchHotelsStaticData",
  UPDATE_PASSWORD:"updatePassword",
  FETCH_TRANSACTIONS:"fetchTransactions",
  DOWNLOAD_TICKET:"download_ticket",
  EMAIL_ETICKET: "emailEticket",
  GET_COST_CODES: "getCostCodes",
  DELETE_TRIP_BY_ID: "deleteTripById",
  ADD_COST_CODES: "addCostCodes",
  COST_CODES: "costCodes",
  TENANT_DETAILS: "tenantDetails",
  DELETE_COST_CODE: "deleteCostCode",
  ANALYTICS_TRIP_DATA: "trips",
  ANALYTICS_SPENT_DATA: "spent",
  ANALYTICS_TRANSACTIONS_DATA: "transactions",
  ANALYTICS_GRAPH_DATA: "graph",
  ANALYTICS_GREETING_DATA: "greetings",
  ANALYTICS_AGGREGATE_DATA: "aggregate",
  ANALYTICS_FREQ_USED_AIRLINES: "frequentlyUsedAirlines",
  ANALYTICS_FREQ_USED_HOTELS: "frequentlyUsedHotels",
  BOOKING_PAYMENT_TERMINATE: "terminatePayment",
  ANALYTICS_USER_UPCOMING_BOOKINGS: "userUpcomingBookings",
  LOADING_TRAVELERS: "Loading Travelers...",
  DELETE_CHILD_BOOKING_BY_PARENT_ID: "deleteChildBookingByParentId",
  FETCH_MONTH_BASED_BOOKINGS:"fetchMonthBasedBookings"
};
