import { Cross, RenderSVG } from "../../../../assets/icons";
import { useState } from "react";
import Modal from "../../Modal";
import Spinner from "../../Spinner";
import { MODAL_SIZE } from "../../../../constants";

const { MD } = MODAL_SIZE;

const DeleteConfirmationModal = ({
  title = "",
  primaryButtonText = "Delete",
  handleClose,
  handleSubmit,
  children,
  spinnerName,
  hasUpdatePermission = true,
}) => {
  const [isDeleteConfirmationModalSpinnerActive, setIsDeleteConfirmationModalSpinnerActive] =
    useState(true);
  return (
    <Modal size={MD} shouldShowModalFromProps handleClose={handleClose}>
      <div className='flex flex-col divide-y divide-contrast-300'>
        <div className='flex justify-between items-center p-6'>
          <div className='flex gap-5 items-center'>
            <div className='p-3 rounded-full bg-red-100/50'>
              <div className='rounded-full bg-red-600 text-white w-5 h-5 text-center font-bold'>
                !
              </div>
            </div>
            <div className='text-2xl leading-8 font-bold'>{title}</div>
          </div>
          <div>
            <button onClick={handleClose}>
              <RenderSVG Svg={Cross} className='text-contrast-900' />
            </button>
          </div>
        </div>
        <div className='p-6'>{children} </div>
        <div>
          <div className='flex gap-3 justify-end p-6'>
            <button
              type='button'
              className='rounded-md shadow-sm px-4 py-3 border border-contrast-300 text-sm leading-5 w-20 h-12'
              onClick={handleClose}
            >
              Cancel
            </button>
            {hasUpdatePermission && (
              <button
                type='button'
                className='rounded-md shadow-sm px-4 py-3 bg-red-600 text-white text-sm leading-5 min-w-20 h-12'
                onClick={handleSubmit}
                disabled={isDeleteConfirmationModalSpinnerActive}
              >
                <Spinner setIsSpinnerActive={setIsDeleteConfirmationModalSpinnerActive}
                  name={spinnerName}  persistSize={true}>
                  {primaryButtonText}
                </Spinner>
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
