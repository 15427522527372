import PropTypes from "prop-types";

export const ModalPropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.element,
  children: PropTypes.element,
  size: PropTypes.string,
  position: PropTypes.string,
  shouldShowModalFromProps: PropTypes.bool,
  shouldCloseOnClickOutside: PropTypes.bool,
  shouldShowBorder: PropTypes.bool,
  handleReset: PropTypes.func,
  handleClose: PropTypes.func,
};
