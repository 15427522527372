import { useMemo, useState } from "react";
import { Field, useFormikContext } from "formik";
import airlinesJson from "../../../../assets/json/carrierCodes.json";
import CustomMultiSelectField from "../../../molecules/CustomMultiSelectField";
import classNames from "classnames";
import { isEmpty } from "lodash";

const MAX_VISIBLE_VALUES = 9;
const ADDED = "Added";
const DELETED = "Delete";

// Function to check if an object exists in an array
const existsInArray = (array, obj) =>
  array.some((item) => item.code === obj.code);

const EditedAirlines = ({ existingAirlines }) => {
  const { values } = useFormikContext();
  const [shouldShowAllAirlines, setShouldShowAllAirlines] = useState(false);

  const toggleShouldShowAllAirlines = () =>
    setShouldShowAllAirlines(!shouldShowAllAirlines);

  const filteredAirlines = useMemo(() => {
    // Find added objects
    const addedAirlines = values.data.reduce((acc, obj) => {
      if (!existsInArray(existingAirlines, obj))
        acc = [...acc, { ...obj, status: ADDED }];
      return acc;
    }, []);

    // Find removed objects
    const removedAirlines = existingAirlines.reduce((acc, obj) => {
      if (!existsInArray(values.data, obj))
        acc = [...acc, { ...obj, status: DELETED }];
      return acc;
    }, []);

    return [...addedAirlines, ...removedAirlines];
  }, [existingAirlines, values.data]);

  const visibleAirlines = shouldShowAllAirlines
    ? filteredAirlines
    : filteredAirlines.slice(0, MAX_VISIBLE_VALUES);

  return (
    !isEmpty(visibleAirlines) && (
      <div className='flex flex-wrap pt-4'>
        <div
          className={classNames("flex flex-wrap p-2 w-full", {
            "border-2 border-gray-300 max-h-20 rounded-md overflow-y-scroll":
              shouldShowAllAirlines,
          })}
        >
          {visibleAirlines.map(({ name, code, status }) => (
            <div
              key={code}
              className={classNames(
                "rounded-2xl py-0.5 px-[10px] text-xs font-medium inline-flex items-center gap-[6px] mb-2 mr-2",
                {
                  "bg-green-100 text-green-800": status === ADDED,
                  "bg-red-100 text-red-800 line-through whitespace-nowrap":
                    status === DELETED,
                }
              )}
            >
              {name}
            </div>
          ))}
          {filteredAirlines.length > MAX_VISIBLE_VALUES && (
            <button
              type='button'
              onClick={toggleShouldShowAllAirlines}
              className='text-sm text-blue-500'
            >
              {shouldShowAllAirlines ? "View Less" : "View More"}
            </button>
          )}
        </div>
      </div>
    )
  );
};

const AirlinePolicyManager = ({ policyName, policyType, policyData }) => {
  const allAirlines = useMemo(
    () =>
      Object.keys(airlinesJson).map((airlineCode) => ({
        code: airlineCode,
        name: airlinesJson[airlineCode],
      })),
    [airlinesJson]
  );

  return (
    <>
      <div className='flex items-start justify-between gap-2'>
        <div>
          <label className='text-lg font-semibold mb-1 capitalize'>
            {policyType + " " + policyName}
          </label>
          <div className='text-sm font-normal text-gray-500'>
            {`Select the airlines which ever you wants to ${policyType} on time of search.`}
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <label className='relative inline-flex items-center cursor-pointer'>
            <Field type='checkbox' name='isEnabled' className='sr-only peer' />
            <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
          </label>
        </div>
      </div>
      <div className='flex w-full rounded-md focus:outline-none'>
        <div className='w-full text-contrast-500'>
          <CustomMultiSelectField
            fieldName='data'
            displayKey='name'
            uniqueKey='code'
            rawData={allAirlines}
            initialSelectedValues={policyData.data}
            placeholder='Select Airlines'
          />
          {policyData.data && (
            <EditedAirlines existingAirlines={policyData.data} />
          )}
        </div>
      </div>
    </>
  );
};

export default AirlinePolicyManager;
