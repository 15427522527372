import { get, isEmpty } from "lodash";
import { DEFAULT_VALUES, TBO_FARE_TYPES, FLIGHT_PROVIDERS } from "../constants";
import { getAirportInfoByIata } from "./getAirportInfoByIata";

const { EMPTY_STRING, EMPTY_OBJECT, ZERO, ONE, EMPTY_ARRAY } = DEFAULT_VALUES;
const { REGULAR } = TBO_FARE_TYPES;

const PASSPORT_DOCUMENT_TYPE = "PASSPORT";
const PAN_DOCUMENT_TYPE = "PAN";
const MEALDYNAMIC = "mealDynamic";
const INFANT = "Infant";
const MEAL = "meal";
const BAGGAGE = "baggage";
const NEPAL = "Nepal";
const BHUTAN_AIRLINES = "Bhutan Airlines";

const getSSRDetails = (value, itineraries, index, defaultValues, key) =>
  itineraries.reduce((acc, { segments }) => {
    let allSegmentSSRData = [];
    segments.forEach(({ flightNumber }) => {
      const selectedSegmentData = get(value, `${flightNumber}.${index}`);
      const segmentData = isEmpty(selectedSegmentData)
        ? defaultValues && defaultValues[flightNumber]
        : selectedSegmentData;
      allSegmentSSRData = segmentData
        ? [...allSegmentSSRData, segmentData]
        : allSegmentSSRData;
    });
    if (key === BAGGAGE && allSegmentSSRData.length > ONE)
      allSegmentSSRData = allSegmentSSRData.slice(ZERO, ONE);
    return [...acc, ...allSegmentSSRData];
  }, []);

const travelerMapping = { 1: "Adult", 2: "Child", 3: "Infant" };

const getFormattedFareBreakdown = (fareBreakdown = []) => {
  const fareBreakDownByTravelerType = fareBreakdown.reduce(
    (formattedFareBreakDown, fare) => {
      const {
        Currency,
        PassengerType,
        PassengerCount,
        BaseFare,
        Tax,
        TaxBreakUp,
        YQTax,
        AdditionalTxnFeeOfrd,
        AdditionalTxnFeePub,
        PGCharge,
        SupplierReissueCharges,
      } = fare;
      const travelerType = travelerMapping[PassengerType];

      formattedFareBreakDown[travelerType] = {
        baseFare: BaseFare / PassengerCount,
        tax: Tax / PassengerCount,
        yqTax: YQTax / PassengerCount,
        additionalTxnFeeOfrd: AdditionalTxnFeeOfrd / PassengerCount,
        additionalTxnFeePub: AdditionalTxnFeePub / PassengerCount,
        taxBreakUp: TaxBreakUp,
        pgCharge: PGCharge || ZERO,
        supplierReissueCharges: SupplierReissueCharges || ZERO,
        currency: Currency,
      };
      return formattedFareBreakDown;
    },
    {}
  );
  return fareBreakDownByTravelerType;
};

const getFormattedSSRDetails = (ssrData = EMPTY_OBJECT) =>
  Object.entries(ssrData).reduce((formattedDetails, [key, value]) => {
    if (key.includes("seat"))
      formattedDetails.seatDetails = {
        type: key,
        value,
      };
    if (key.includes("meal"))
      formattedDetails.mealDetails = {
        type: key,
        value,
      };
    if (key.includes("baggage"))
      formattedDetails.baggageDetails = {
        type: key,
        value,
      };
    return formattedDetails;
  }, {});

const getSpecialServicesDetails = (
  booking,
  flightDetails,
  travelerType,
  index
) => {
  let specialServicesDetails = EMPTY_OBJECT;
  const { resultIndex, isLCC, price } = flightDetails;
  const { itineraries } = price;

  const {
    selectedLCCSeats: seatDynamic,
    selectedLCCBaggages: baggage,
    selectedLCCMeals: mealDynamic,
    selectedNonLCCMeal: meal,
    selectedNonLCCSeats: seatPreference,
    SSRDefaultValuesLCC,
  } = booking;
  const lccSSRDetails = {
    seatDynamic,
    baggage,
    mealDynamic,
  };
  const nonLCCSSRDetails = {
    seatPreference,
    meal,
  };

  if (isLCC)
    specialServicesDetails = Object.entries(lccSSRDetails).reduce(
      (acc, service) => {
        const [key, value] = service;
        const defaultKey = key.charAt(ZERO).toLowerCase() + key.slice(ONE);
        if (travelerType === INFANT && key !== MEALDYNAMIC) return acc;
        return {
          ...acc,
          [key]: getSSRDetails(
            value,
            itineraries,
            index,
            SSRDefaultValuesLCC[defaultKey],
            key
          ),
        };
      },
      {}
    );
  else
    specialServicesDetails = Object.entries(nonLCCSSRDetails).reduce(
      (acc, [key, value]) => {
        if (travelerType === INFANT && key !== MEAL) return acc;
        return {
          ...acc,
          [key]: value?.[resultIndex]?.[index] || {},
        };
      },
      {}
    );
  // get formatted special services details
  const formattedSpecialServicesDetails = getFormattedSSRDetails(
    specialServicesDetails
  );

  return formattedSpecialServicesDetails;
};

const mapDocumentInfo = (pax, flightPriceInfo, isGoingToNepal) => {
  const {
    travelerType,
    passportDetails = EMPTY_OBJECT,
    panCardDetails = EMPTY_OBJECT,
    document,
  } = pax;
  const { panCardNumber } = panCardDetails;
  const { documentsRequired, fareType } = flightPriceInfo;
  const { expireDate, issuedDate, passport, issueCountry = EMPTY_OBJECT } = passportDetails;
  const { isoCode = EMPTY_STRING } = issueCountry;
  const { isPanRequiredAtBook, isPassportRequiredAtBook, isPassportRequiredAtTicket } = documentsRequired;

  let documents = [
    {
      documentType: EMPTY_STRING,
      expiryDate: EMPTY_STRING,
      issuanceDate: EMPTY_STRING,
      issuanceCountry: EMPTY_STRING,
      holder: true,
    },
  ];

  if (isPassportRequiredAtBook || isPassportRequiredAtTicket || (isGoingToNepal && travelerType !== INFANT))
    documents[ZERO] = {
      documentType: PASSPORT_DOCUMENT_TYPE,
      expiryDate: expireDate,
      issuanceDate: issuedDate,
      issuanceCountry: isoCode,
      holder: true,
      number: passport,
    };
  if (isPanRequiredAtBook)
    documents = [
      ...documents,
      {
        documentType: PAN_DOCUMENT_TYPE,
        number: panCardNumber,
      },
    ];
  if (fareType !== REGULAR) {
    const { number, type } = document;
    documents = [
      ...documents,
      {
        number,
        documentType: type.value,
      },
    ];
  }
  return documents;
};

const mapAllowedBaggageDetails = (baggageData) => {
  const checkInBaggage =
    get(baggageData, "weight") ||
    get(baggageData, "numberOfBags", EMPTY_STRING);
  const cabinBaggage = get(baggageData, "cabinBaggage", EMPTY_STRING);
  return { checkInBaggage, cabinBaggage };
};

const checkingIsFlightGoingToNepal = (itineraries, isLCC) =>
  itineraries.some(({ segments }) =>
    segments.some((segment) => {
      const { country } = getAirportInfoByIata(segment.arrival.iataCode);
      const carrierName = segment.carrierName;
      return country === NEPAL && carrierName !== BHUTAN_AIRLINES && isLCC;
    })
  );

export const mapTravelersInfo = (
  flightDetails,
  booking,
  currentFlightPriceRes
) => {
  const { baggage, price, isLCC } = flightDetails;
  const { selectedTravelers } = booking;
  const { flightPriceInfo = EMPTY_OBJECT } =
    currentFlightPriceRes || EMPTY_OBJECT;
  const { fareBreakdown = EMPTY_ARRAY } = flightPriceInfo;
  const { itineraries = EMPTY_ARRAY } = price;

  //getting the fare breakdown object alone their traveler based
  const fareBreakDownByTravelerType = getFormattedFareBreakdown(fareBreakdown);

  //mapped the baggageDetails
  const allowedBaggage = mapAllowedBaggageDetails(baggage);

  //mapped the travelers with their required details
  const mappedTravelerDetails = selectedTravelers.map((pax, index) => {
    const {
      travelerId,
      travelerType,
      profileDetails,
      gstDetails,
      IsLeadPax: isPrimaryPax,
    } = pax;
    const {
      gender,
      email,
      firstName,
      lastName,
      phoneNumber,
      phoneCode,
      dateOfBirth,
      title,
      nationality,
      address = EMPTY_STRING,
      city = EMPTY_STRING,
    } = profileDetails;
    const {
      isoCode: countryCode = EMPTY_STRING,
      name: countryName = EMPTY_STRING,
    } = nationality;

    //verify is flight going to nepal
    const isGoingToNepal = checkingIsFlightGoingToNepal(itineraries, isLCC);

    // getting the selected special services details
    const SSRData = getSpecialServicesDetails(
      booking,
      flightDetails,
      travelerType,
      index
    );

    // getting the documents details of selected traveler
    const documents = mapDocumentInfo(pax, flightPriceInfo, isGoingToNepal);

    return {
      id: travelerId,
      isPrimaryPax,
      fareOption: null,
      travelerType: travelerType.toUpperCase(),
      fare: fareBreakDownByTravelerType[travelerType],
      gender,
      email,
      givenName: firstName,
      familyName: lastName,
      phoneNumber: phoneNumber?.slice(phoneCode.length - 1),
      phoneCode,
      dateOfBirth,
      title,
      nationality: countryCode,
      countryCode,
      countryName,
      address,
      city,
      gstDetails,
      allowedBaggage,
      documents,
      ...SSRData,
    };
  });
  return mappedTravelerDetails;
};
