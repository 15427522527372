import PropTypes from "prop-types";

export const HotelConfirmationPropTypes = {
  hotelsInfo: PropTypes.array,
  bookingId: PropTypes.string,
  hotelBookingStatus: PropTypes.string,
  isSpinnerActive: PropTypes.bool,
};

export const BookingPricingPropTypes = {
  amount: PropTypes.string,
  hotelRoomsInfo: PropTypes.array,
  numberOfAdults: PropTypes.number,
};

export const BookingImportantInfoPropTypes = {
  decodedHotelInfo: PropTypes.string,
};

export const BookingLinksPropTypes = {
  isSpinnerActive: PropTypes.bool,
  bookingId: PropTypes.string,
};
