import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import Modal from "../../Modal";
import { selectSearchFilters } from "../../Search";
import { priceFormatter, getQueryParams } from "../../../../helper";
import { RenderSVG, InflationIcon } from "../../../../assets/icons";
import {
  MODAL_SIZE,
  CURRENCY_SYMBOLS,
  DEFAULT_VALUES,
} from "../../../../constants";
import { selectCountryInfo } from "../../../../screens/Profile";
import classNames from "classnames";
import { selectSelectedTripId } from "../../../../screens/Booking/Trips";
import { selectCurrentUserInfo } from "../../../../screens/Auth";

const { MD } = MODAL_SIZE;
const { INR } = CURRENCY_SYMBOLS;
const { ZERO, TWO } = DEFAULT_VALUES;

const PriceChangedModal = ({ handleClose, changeInPrice }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const activeFilters = useSelector(selectSearchFilters);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const selectedTripId = useSelector(selectSelectedTripId);
  const currentUserInfo = useSelector(selectCurrentUserInfo);

  const queryParams = getQueryParams(activeFilters);
  
  const myTripFlightSearchRoute = `/users/${currentUserInfo?.id}/bookings/${selectedTripId}/flights?${queryParams}`;
  const flightSearchRoute = `/bookings/${selectedTripId}/flights?${queryParams}`;
  return (
    <div className='overflow-auto'>
      <Modal size={MD} shouldShowModalFromProps handleClose={handleClose} zIndex="1001">
        <div className='flex justify-center items-center text-center py-4 mt-4'>
          <RenderSVG
            Svg={InflationIcon}
            alt='Inflation Icon'
            height='100'
            width='100'
            className={classNames({
              "rotate-[75deg]": changeInPrice < ZERO,
            })}
          />
        </div>
        <div className='flex justify-center items-center py-8 p-10'>
          <div className='text-center text-xl min-w-[65px]'>
            {changeInPrice > ZERO
              ? t("priceChangeModal.priceIncreaseMessage", {
                  priceChange: `${currencySymbol} ${priceFormatter(
                    changeInPrice.toFixed(TWO)
                  )}`,
                })
              : t("priceChangeModal.priceDecreaseMessage", {
                  priceChange: `${currencySymbol} ${priceFormatter(
                    Math.abs(changeInPrice)
                  )}`,
                })}
          </div>
        </div>
        <div className='flex justify-center items-center px-10 gap-4 mb-4'>
          <div className='py-4 text-center'>
            <button
              className='btn bg-transparent border border-contrast-400 hover:bg-contrast-100'
              onClick={() => {
                {
                  if (
                    window.location.pathname.includes(
                      `/users/${currentUserInfo?.id}/bookings`
                    )
                  ) {
                    navigate(myTripFlightSearchRoute);
                  } else {
                    navigate(flightSearchRoute);
                  }
                }
              }}
            >
              Search Again
            </button>
          </div>
          <div className='py-4 text-center'>
            <button className='btn btn-primary' onClick={() => handleClose()}>
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PriceChangedModal;
