import { useRef, useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import { useClickOutside } from "../../helper";
import ErrorMessage from "./ErrorMessage";
import { ChevronDown, RenderSVG } from "../../assets/icons";

const INDIAN_STATES = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];

const StateDropdown = ({
  onChange,
  fieldName,
  value,
  setFieldTouched,
  placeHolder,
  t,
  onBlur = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value || "");
  const dropDownRef = useRef();

  const { errors, touched } = useFormikContext();

  useClickOutside(dropDownRef, setIsOpen);

  useEffect(() => {
    value && setSearchTerm(value);
  }, [value]);

  const renderFieldError = () =>
    get(errors, fieldName, false) &&
    get(touched, fieldName, false) && (
      <ErrorMessage errorMessage={get(errors, fieldName)} />
    );

  const handleOptionClick = (option) => {
    setSearchTerm(option);
    onChange(option);
    setIsOpen(false);
  };

  const filteredOptions = INDIAN_STATES.filter((state) =>
    state.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const exactMatch = INDIAN_STATES.find(
      (state) => state.toLowerCase() === searchTerm.toLowerCase()
    );
    if (exactMatch) {
      setSearchTerm(exactMatch);
      onChange(exactMatch);
    }
  }, [searchTerm]);

  return (
    <div className='form-group z-50'>
      <div onBlur={onBlur} ref={dropDownRef} className='relative'>
        <input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={() => {
            setFieldTouched(fieldName, true);
            setIsOpen(true);
          }}
          onBlur={(e) => {
            isEmpty(e.target.value) && onChange("");
          }}
          className='border border-contrast-300 rounded-md px-4 py-[8px] text-sm cursor-pointer w-full'
          placeholder={t(placeHolder)}
        />
        <div
          className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer'
          onClick={() => setIsOpen(!isOpen)}
        >
          <RenderSVG
            Svg={ChevronDown}
            className='text-contrast-700'
            width='20'
            height='20'
          />
        </div>
        {isOpen && (
          <div className='absolute w-full max-h-44 overflow-y-scroll bg-white border border-contrast-300 rounded shadow-md z-50'>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option}
                  className='px-4 w-full py-3 cursor-pointer hover:bg-contrast-100'
                  onClick={() => handleOptionClick(option)}
                >
                  <div
                    className={classNames(
                      "rounded-2xl py-0.5 px-[10px] text-xs font-medium inline-flex items-center"
                    )}
                  >
                    {option}
                  </div>
                </div>
              ))
            ) : (
              <div className='px-4 w-full py-3 text-gray-500'>
                No results found
              </div>
            )}
          </div>
        )}
      </div>
      <div>{renderFieldError()}</div>
    </div>
  );
};

export default StateDropdown;
