import { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import classNames from "classnames";
import { DEFAULT_VALUES } from "../../../../constants";

const { ZERO, ONE, FIVE } = DEFAULT_VALUES;
const MAX_RATING = FIVE;

const RenderRatings = ({
  index,
  rating,
  hoverRating,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const isFilled = index <= (hoverRating || rating);
  return (
    <span
      className={classNames("text-3xl px-2 cursor-pointer", {
        "text-primary-600": isFilled,
      })}
      onClick={() => onClick(index)}
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={onMouseLeave}
    >
      ★
    </span>
  );
};

const RatingPolicyManager = ({ policyType, policyName, policyData }) => {
  const { errors, setFieldValue } = useFormikContext();
  const [ratings, setRatings] = useState(policyData?.value || ZERO);
  const [hoverRating, setHoverRating] = useState(ZERO);

  useEffect(() => {
    setFieldValue("value", ratings);
  }, [ratings]);

  return (
    <>
      <div className='flex items-start justify-between gap-2'>
        <div>
          <label className='text-lg font-semibold mb-1 capitalize'>
            {policyType + " " + policyName}
          </label>
          <div className='text-sm font-normal text-gray-500'>
            {`Update the ${policyType} ${policyName} value`}
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <label className='relative inline-flex items-center cursor-pointer'>
            <Field type='checkbox' name='isEnabled' className='sr-only peer' />
            <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
          </label>
        </div>
      </div>
      <div className='flex flex-col gap-y-4 justify-center items-center border border-contrast-200 py-4 rounded-lg bg-contrast-100'>
        <div className='flex justify-center'>
          {Array.from({ length: MAX_RATING }, (_, index) => index + ONE).map(
            (rating) => (
              <RenderRatings
                key={rating}
                index={rating}
                rating={ratings}
                hoverRating={hoverRating}
                onClick={(index) =>
                  setRatings(index === ratings ? ZERO : index)
                }
                onMouseEnter={(index) => setHoverRating(index)}
                onMouseLeave={() => setHoverRating(ZERO)}
              />
            )
          )}
        </div>
        <div className='text-lg'>{`Rating: ${ratings} out of ${MAX_RATING}`}</div>
        {errors.value && <div className='text-red-900'>{errors.value}</div>}
      </div>
    </>
  );
};

export default RatingPolicyManager;
