import {
  Morning,
  Noon,
  Evening,
  DeparturePlaneIcon,
  bookingIcon,
  Approvals,
} from "./assets/icons";
import config from "./config.json";

const { logo, logoWithBrand, logoWithBrandAlpha, logoWithBrandBeta } = config;

export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  SET_PASSWORD: "/set-password",
  AUTH_CONFIRMATION: "/auth-confirmation",
  BOOKINGS: "/bookings",
  CURRENT_USER_BOOKINGS: "/users/:userId/bookings",
  MY_TRIPS_BOOKING_ID: "users/:userId/bookings/:id",
  MY_TRIPS_FLIGHTS: "users/:userId/bookings/:id/flights",
  MY_TRIPS_HOTELS:"users/:userId/bookings/:id/hotels",
  MY_TRIPS_HOTEL_INFO:"users/:userId/bookings/:id/hotels/:hotelId",
  MY_TRIPS_HOTEL_PRICE:"users/:userId/bookings/:id/hotels/:hotelId/price",
  MY_TRIPS_FLIGHT_PRICE:"users/:userId/bookings/:id/flights/price",
  MY_TRIPS_PAYMENT: "users/:userId/bookings/:id/payment",
  MY_TRIPS_PAYMENT_CONFIRM: "users/:userId/bookings/:id/payment/confirm",
  MY_TRIPS_PREVIEW_INVOICE:"users/:userId/bookings/:id/preview/invoice",
  MY_TRIPS_PREVIEW: "users/:userId/bookings/:id/preview/:service",
  MY_TRIPS_FLIGHT_CANCELLATION: "users/:userId/bookings/:id/preview/flight/cancel",
  MY_TRIPS_HOTEL_CANCELLATION: "users/:userId/bookings/:id/preview/hotel/cancellation",
  BOOKING_ID: "/bookings/:id",
  FLIGHTS: "/bookings/:id/flights",
  HOTELS: "/bookings/:id/hotels",
  HOTEL_INFO: "/bookings/:id/hotels/:hotelId",
  HOTEL_PRICE: "/bookings/:id/hotels/:hotelId/price",
  FLIGHT_PRICE: "/bookings/:id/flights/price",
  PAYMENT: "/bookings/:id/payment",
  PAYMENT_CONFIRM: "/bookings/:id/payment/confirm",
  USERS: "/users",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  POLICIES: "/policies",
  APPROVALS: "/approvals",
  APPROVALS_DETAILS: "/approvals/:id",
  ACCOUNT: "/account",
  COST_CODES: "/cost-codes",
  PERMISSIONS: "/permissions",
  TRANSACTIONS: "/transactions",
  BILLING: "/billing",
  PREVIEW: "/bookings/:id/preview/:service",
  HOTEL_CANCELLATION: "/bookings/:bookingId/preview/hotel/cancellation",
  INVOICE: "/bookings/:bookingId/preview/invoice",
  //TODO: need to verify these below routes and handle according.
  ADMIN_BOOKINGS: "/admin/bookings",
  ADMIN_API_LOGS: "/admin/logs",
  PRIVACY_POLICY: "/privacy-policy",
  PAYMENT_SECURITY: "/payment-security",
  COOKIE_POLICY: "/cookie-policy",
  CANCELLATION_AND_REFUND_POLICY: "/cancellation-and-refund-policy",
  FLIGHT_CANCELLATION: "/bookings/:id/preview/flight/cancel",
  TERMS_AND_CONDITION: "/terms-and-conditions",
  BOOKING_INFO_ROUTE: "/bookings/:division/:id",
  WALLET_TRANSACTIONS: "/account/wallet/transactions",
};

export const DEFAULT_VALUES = {
  EMPTY_STRING: "",
  SINGLE_SPACE_STRING: " ",
  EMPTY_OBJECT: {},
  EMPTY_ARRAY: [],
  NEG_ONE: -1,
  NEG_TWO: -2,
  NEG_THREE: -3,
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  FIFTEEN: 15,
  TWELVE: 12,
  TWENTY_FIVE:25,
  FIFTY: 50,
  SIXTY: 60,
  HUNDRED: 100,
  THOUSAND: 1000,
  TWO_THOUSAND: 2000,
  ADMIN: "/admin",
  INFINITY: 9999999999,
  TOTAL_PAGES_TO_RENDER: 5,
  DEFAULT_HOTEL_SEARCH_KEYWORD: "gurugram",
  FLIGHT_DETAILS: "flightDetails",
  TIMEOUT_DURATION: 4000,
  NUMBER: "number",
  DEFAULT_MAX_DIGITS: 50,
  MILLISECONDS_PER_YEAR: 365.25 * 24 * 60 * 60 * 1000,
  GDS: "GDS",
  DEFAULT_PRIMARY_TEXT: "Save",
  DEFAULT_COMPANY_NAME: "TAVA",
  CREDIT: "CREDIT",
  DEBIT: "DEBIT",
  MAX: "MAX",
  INFINITE: "INFINITE",
  THREE_HUNDRED: 300,
  STRING: "string",
  EXPONENT_THREE: 1e3,
  EXPONENT_FIVE: 1e5,
  EXPONENT_SIX: 1e6,
  EXPONENT_SEVEN: 1e7,
  EXPONENT_NINE: 1e9,
  DOUBLE_DECIMAL_ZERO: ".00",
  SINGLE_DECIMAL_ZERO: ".0",
};

export const PAYMENT_PROVIDERS = {
  UPI: "upi",
  CARD: "card",
  RAZORPAY: "RAZORPAY",
  STRIPE: "stripe",
  WALLET: "WALLET",
};

export const INDEX = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
  FOURTH: 3,
  FIFTH: 4,
  LAST: -1,
};

export const REGEX = {
  SEATMAP: /\/seatmaps?\w*/,
  UPI_REGEX: /^[a-zA-Z0-9.-]{2,256}@[a-zA-Z]{2,64}$/,
  CARD_NUMBER_REGEX: /^\d{13,19}$/,
  CARD_EXPIRY_MONTH_REGEX: /^(0[1-9]|1[0-2])$/,
  CARD_EXPIRY_YEAR_REGEX: /^\d{4}/,
  CVV_REGEX: /^\d{3}$/,
  CARD_NAME_REGEX: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
  FLIGHT_SEARCH: /^\/search?\w+/,
  NAME: /^[a-zA-Z]+$/,
  EMAIL: /^(?!.*\.\..*)(?!.*[!]{2,})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  OPTIONAL_NAME: /^[a-zA-Z]*$/,
  NUMERIC: /^\d*$/,
  LOCATIONS: /\/locations\w*/,
  FARE_CALENDAR: /\/fareCalendar\w*/,
  LOGS: /\/logs\w*/,
  PRICE: /\/price\w*/,
  CAPITAL_ALPHABET: /^[A-Z]$/,
  BOOKINGS: /bookings\w*/,
  CHARACTER_AFTER_WHITESPACE: /\b\w/g,
  WHITESPACES_AT_START: /^\S/,
  HOTEL_SEARCH: /^\/hotels\/search?\w+/,
  PHONE_NUMBER: /^\+?[1-9]\d{0,2}-?[1-9]\d{7,13}$/,
  PASSPORT_NUMBER: /^\s*[a-zA-Z0-9]+\s*$/,
  PANCARD_NUMBER:/^[A-Z]{5}[0-9]{4}[A-Z]$/,
  ONLY_DIGITS: /^\d+$/,
  NON_ZERO_PASSPORT_NUMBER: /^(?!0+$)\w+$/,
  BAGGAGE_COUNT_REGEX: /(\d+)\s*(\D*)/,
  CURRENCY_REGEX: /(?:Rs\.|INR|₹)\s?([\d,]+)/g,
  COMPANY_NAME: /^[A-Za-z&'.,()\- ]+$/,
  PASSWORD_REGEX: /^(?!.*\s)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
  NO_WHITESPACES:/^\S*$/,
  DASH_SEPARATOR: /-{10,}/g,
  EMAIL_REGEX_WITH_WITESPACES: /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/,
  CAMEL_CASE_REGEX: /^[a-z]+([A-Z][a-z]*)*$/,
  DELEMITER_AND_NEXT_CHAR_REGEX: /[_\s-]+(.)?/g,
  START_WITH_UPPER_CASE_REGEX: /^[A-Z]/,
  PASCAL_CASE_REGEX: /^[A-Z][a-z]+([A-Z][a-z]*)*$/,
  WHITE_SPACES_DETECTION_REGEX:/^(?!\s*$).+/,
  COST_CODE_VALIDATION_REGEX: /^[a-zA-Z0-9-_\s]+$/,
  WEEK_REGEX: /^\d{4}-\d{2}-\d{2}$/,
  YEAR_REGEX: /^\d{4}$/,
  MONTH_REGEX: /^[A-Za-z]+\s\d{4}$/,
  WHITESPACES_AT_END:/\s+$/
};

export const MONTHS = [
  {
    key: "January",
    value: "1",
  },
  {
    key: "February",
    value: "2",
  },
  {
    key: "March",
    value: "3",
  },
  {
    key: "April",
    value: "4",
  },
  {
    key: "May",
    value: "5",
  },
  {
    key: "June",
    value: "6",
  },
  {
    key: "July",
    value: "7",
  },
  {
    key: "August",
    value: "8",
  },
  {
    key: "September",
    value: "9",
  },
  {
    key: "October",
    value: "10",
  },
  {
    key: "November",
    value: "11",
  },
  {
    key: "December",
    value: "12",
  },
];

export const CURRENCY_SYMBOLS = {
  EUR: "€",
  USD: "$",
  INR: "₹",
  GBP: "£",
  AED: "د.إ",
  LKR: "Rs",
  CAD: "C$"
};

export const SPECIAL_SYMBOLS = {
  ARROW_RIGHT: "→",
};

export const API_RESPONSES = {
  ERROR: "Error",
  SESSION_EXPIRED: "required session is not found or expired.",
};

export const HTTPS_STATUS_CODES = {
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500,
  GONE: 410,
};
export const REQUEST_METHODS = {
  GET: "get",
  POST: "post",
  PATCH: "patch",
  DELETE: "delete",
  PUT: "put",
};

export const SUCCESS = 200;

export const GENDER = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};

export const PROMISES = {
  CANCELLED: "CANCELLED",
};

export const USER_AUTH_FIELDS = {
  PHONE: "phone",
  PIN: "pin",
  EMAIL: "email",
  PASSWORD: "password",
  CONFIRMPASSWORD: "confirmPassword",
  SUBSCRIBE: "subscribe",
};

export const BOOKING_STATUS = {
  ALL: "ALL",
  CONFIRMED: "CONFIRMED",
  IN_REVIEW: "IN_REVIEW",
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED",
  PAID: "PAID",
  UNPAID: "UNPAID",
  PARTIAL_PAYMENT: "PARTIAL PAYMENT",
  AWAITING_PAYMENT: "AWAITINGPAYMENT", //Todo AWAITINGPAYMENT --> AWAITING_PAYMENT
  PROCESSING: "PROCESSING",
  CAPTURED: "CAPTURED",
  FAILED: "FAILED",
  PARTIALLY_CONFIRMED:"PARTIALLY_CONFIRMED"
};

export const AUTH_DATA = {
  USER: "user",
  ADDRESS_INFO: "addressInfo",
  TRAVELERS_INFO: "travelersInfo",
  AUTH: "auth",
};

export const TRIP_TYPES = {
  ONE_WAY: "oneWay",
  ROUND_TRIP: "roundTrip",
  MULTI_CITY: "multiCity",
};

export const TRIP_TYPES_ARRAY = [
  { id: 1, value: "oneWay", label: "searchSection.tripTypes.oneWay" },
  { id: 2, value: "roundTrip", label: "searchSection.tripTypes.roundTrip" },
  { id: 3, value: "multiCity", label: "searchSection.tripTypes.multiCity" },
];

export const FLIGHT_TRIP_TYPES = {
  ONE_WAY: "ONE_WAY",
  ROUND_TRIP: "ROUND_TRIP",
  MULTI_CITY: "MULTI_CITY",
};

export const JOURNEY_TYPES = {
  DEPART: "depart",
  RETURN: "return",
};

export const COLOR_TYPE = {
  PRIMARY: "primary",
  CONTRAST: "contrast",
  SECONDARY: "secondary",
};

export const DEFAULT_COLORS = {
  primaryHexCode: "#642ec8",
  contrastHexCode: "#111827",
  secondaryHexCode: "#d4c3f5",
};

export const CURRENT_DATE = new Date();

export const NEXT_DAY_DATE = new Date(Date.now() + 86400000);

export const RETURN_DATE = new Date(Date.now() + 259200000);

export const JOURNEY_DEFAULT_VALUES = {
  originCode: {
    cityName: "Delhi",
    iata: "DEL",
    airportName: "Indira Gandhi Int Airport",
  },
  destCode: {
    cityName: "Mumbai",
    iata: "BOM",
    airportName: "Chhatrapati Shivaji Maharaja Airport",
  },
  departureDate: NEXT_DAY_DATE
    .toISOString()
    .split("T")[DEFAULT_VALUES.ZERO],
  departureTime: "",
  returnDate: "",
  returnTime: "",
};

export const DEPLOYED_IMAGE_BASE_URL =
  "https://devtavajsapptemplate.s3.ap-south-1.amazonaws.com/trip-tava/";

export const FARE_TYPES = {
  REGULAR: {
    label: "fareTypes.regular",
    value: "REGULAR",
  },
  ARMED_FORCES: {
    label: "fareTypes.armedForces",
    value: "ARMED_FORCES",
  },
  STUDENT: {
    label: "fareTypes.student",
    value: "STUDENT",
  },
  SENIOR_CITIZEN: {
    label: "fareTypes.seniorCitizen",
    value: "SENIOR_CITIZEN",
  },
};

export const DEFAULT_CURRENCY_CODE = "INR";
export const DEFAULT_EXCHANGE_RATE = 1;
export const DEFAULT_LANGUAGE_CODE = "en";
export const DEFAULT_USER_LANGUAGE = "English";
export const DEFAULT_USER_COUNTRY_NAME = "India";
export const DEFAULT_USER_COUNTRY_CODE = "IN";
export const DEFAULT_CURRENCY = {
  code: "INR",
  symbol: "₹",
  name: "Indian rupee",
  exchangeRate: 1,
};

export const CACHE_KEYS = {
  USER_COUNTRY_INFO: "userCountryInfo",
  FLIGHT_INFORMATION: "flightInformation",
  X_REQUEST_ID: "X-Request-Id",
  FLIGHT_PRICE_REQUEST_BODY: "flightPriceRequestBody",
  TRAVELERS_INFORMATION: "travelersInformation",
  BOOKING_INFORMATION: "bookingInformation",
  HOTEL_BOOK_REQUEST_BODY: "hotelBookRequestBody",
  BOOKING_API_REQUEST: "bookingAPIRequest",
  HOTEL_INFO_REQUEST_BODY: "hotelInfoRequestBody",
  USER_INFO: "userInfo",
  TBO_TOKEN_ID: "tboTokenId",
  USER: "user",
  SEARCH_FILTERS: "searchFilters",
  AUTH: "auth",
  SESSION_ID: "sessionId",
  SEARCH: "search",
  HOTEL_SEARCH_FILTERS: "hotelSearchFilters",
  PRICE_POLICY_REQUEST_BODY: "pricePolicyRequestBody",
  CURRENCY_INFO: "currencyInfo",
  PERMISSIONS: "permissions",
  CURRENT_USER_INFO: "currentUserInfo",
  TIMELOG:  "timeLog",
  SHOW_TIMER: "showTimer",
  FLIGHT_SEARCH_TIME: "flightSearchTime",
  HOTEL_SEARCH_TIME: "hotelSearchTime",
  TRIP_TIME_EXPIRY: "tripTimeExpiry",
};

export const DEFAULT_LIMIT = 10;

export const SEARCH_RESULT_CARD_TABS = {
  FLIGHT_DETAILS: "FLIGHT DETAILS",
  FARE_SUMMARY: "FARE SUMMARY",
  CANCELLATION: "CANCELLATION",
  DATE_CHANGE: "DATE CHANGE",
};

export const TRAVELER_TYPE = {
  ADULT: "Adult",
  CHILD: "Child",
  HELD_INFANT: "Infant",
};

export const MAX_TRAVELER_AGES = {
  Adult: 150,
  Child: 12,
  Infant: 2,
};

const INFANT_MIN_AGE_IN_DAYS = 15;

export const MIN_TRAVELER_AGES = {
  Adult: 12,
  Child: 2,
  Infant: INFANT_MIN_AGE_IN_DAYS,
};

export const DEFAULT_BOOKING_DETAILS = {
  DEFAULT_BOOKING_TYPE: "INSTANT",
  DEFAULT_RESULT_SOURCE: "AM",
  DEFAULT_DOCUMENT_TYPE: "PASSPORT",
};

export const INITIAL_SORTING_VALUES = {
  stops: {},
  airlines: {},
  departureTime: {
    minTime: 0,
    maxTime: 1439,
  },
  arrivalTime: {
    minTime: 0,
    maxTime: 1439,
  },

  price: {
    minPrice: "",
    maxPrice: "",
    currency: "",
  },
  layoverAirports: {},
};

export const INITIAL_SORT_FILTERS = {
  stops: [],
  airlines: [],
  layoverAirports: [],
  departureTime: {
    minTime: 0,
    maxTime: 1439,
  },
  arrivalTime: {
    minTime: 0,
    maxTime: 1439,
  },
  price: { maxPrice: "", minPrice: "" },
};

export const INITIAL_FILTERS = {
  isolated: {
    outbound: INITIAL_SORT_FILTERS,
    inbound: INITIAL_SORT_FILTERS,
  },
  packages: INITIAL_SORT_FILTERS,
};

export const DEFAULT_FLIGHT_SEARCH_OPTIONS = {
  packages: INITIAL_SORTING_VALUES,
  isolated: {
    outbound: INITIAL_SORTING_VALUES,
    inbound: INITIAL_SORTING_VALUES,
  },
};

export const WINDOWS_EVENTS = {
  CLICK: "click",
  COPY: "copy",
  PASTE: "paste",
  POPSTATE: "popstate",
  STORAGE: "storage",
  BEFORE_UNLOAD: "beforeunload"
};

export const MOCK_RAZORPAY_ORDER_ID = "MOCK_RAZORPAY_ORDER_ID";
export const RAZORPAY_CHECKOUT_SCRIPT_URL =
  "https://checkout.razorpay.com/v1/checkout.js";

export const SEAT_TYPE = {
  WINDOW_SEAT: "Window Seat",
  MIDDLE_SEAT: "Middle Seat",
  AISLE_SEAT: "Aisle Seat",
};

export const KEY_CODES = {
  KEY_DOWN: "ArrowDown",
  KEY_UP: "ArrowUp",
  ENTER: "Enter",
};

export const LOCATION_TYPE = {
  FROM: "From",
  TO: "To",
};

export const DATA_COUNT_OPTIONS = [
  { limit: 5, label: "Limit 5" },
  { limit: 10, label: "Limit 10" },
  { limit: 15, label: "Limit 15" },
  { limit: 20, label: "Limit 20" },
  { limit: 25, label: "Limit 25" },
  { limit: 50, label: "Limit 50" },
];

export const slides = [
  { id: 1, imgName: "slide1", city: "Paris, France", price: "$1,397" },
  { id: 2, imgName: "slide2", city: "Maui, Hawaii", price: "$1,397" },
  { id: 3, imgName: "slide3", city: "Beijing, China", price: "$1,397" },
  { id: 4, imgName: "slide4", city: "Bergen, Norway", price: "$1,397" },
  { id: 5, imgName: "slide5", city: "Reykjavik, Iceland", price: "$1,397" },
  { id: 6, imgName: "slide6", city: "Phuket, Thailand", price: "$1,397" },
];

export const sliderArray = [
  {
    id: 1,
    title: "homepage.liveNow",
    subTitle: "homepage.discount",
    buttonPrevClass: "sale-button-prev ",
    buttonNextClass: "sale-button-next",
    sliderClass: "sale-slider",
    slides: slides,
  },
  {
    id: 2,
    title: "homepage.jackpot",
    subTitle: "homepage.saveExtra",
    buttonPrevClass: "top-selling-button-prev ",
    buttonNextClass: "top-selling-button-next",
    sliderClass: "top-selling-slider",
    slides: slides,
  },
  {
    id: 3,
    title: "homepage.internalDestinations",
    subTitle: "homepage.exploreWorld",
    buttonPrevClass: "internal-destinations-button-prev ",
    buttonNextClass: "internal-destinations-button-next",
    sliderClass: "internal-destinations-slider",
    slides: slides,
  },
];

export const DATE_PICKER_OPTIONS = [
  {
    title: "Exact Dates",
    value: "",
  },
  {
    title: "1 Day",
    value: "",
  },
  {
    title: "2 Days",
    value: "",
  },
  {
    title: "3 Days",
    value: "",
  },
  {
    title: "7 Days",
    value: "",
  },
];

export const DATE_TYPE_TITLES = {
  DEPARTURE: "Departure",
  RETURN: "Return",
};

export const DRAWER_SIZE = {
  SM: "small",
  MD: "medium",
  FULL: "fullScreen",
  LARGE: "large",
};

export const SSO_PROVIDER = {
  APPLE: "apple",
  FACEBOOK: "facebook",
  GOOGLE: "google",
};

export const travelBaggagesDetails = [
  {
    id: "1",
    label: "NonStop",
    price: "488",
    checked: false,
  },
  {
    id: "2",
    label: "Carry-on included",
    price: "432",
    checked: false,
  },
  {
    id: "3",
    label: "No cancel fee",
    price: "397",
    checked: false,
  },
  {
    id: "4",
    label: "No change fee",
    price: "432",
    checked: false,
  },
  {
    id: "5",
    label: "Low CO2 Emissions",
    price: "432",
    checked: false,
  },
  {
    id: "6",
    label: "Meals Included",
    price: "432",
    checked: false,
  },
];

export const internationalFlightsDetails = [
  { id: "1", label: "Passport Required", isChecked: false },
  { id: "2", label: "Tourist Visa Required", isChecked: false },
  { id: "3", label: "Vaccinations Required", isChecked: false },
  { id: "4", label: "Currency Restrictions", isChecked: false },
];

export const SUPPORTED_LANGUAGES = [
  {
    name: "English",
    code: "en",
  },
  {
    name: "हिंदी",
    code: "hi",
  },
  {
    name: "தமிழ்",
    code: "ta",
  },
  {
    name: "සිංහල",
    code: "si",
  },
  {
    name: "Español",
    code: "es",
  },
];

export const ROLE_LABEL ={
  ADMIN: "admin",
  USER:"user"
}

export const FLIGHTS_RESULT_TYPE = {
  OUTBOUND_RESULT: "outbound",
  INBOUND_RESULT: "inbound",
  PACKAGE_RESULT: "packages",
};

export const FLIGHT_ISOLATED_SECTIONS = {
  OUTBOUND: "outbound",
  INBOUND: "inbound",
};

export const MODAL_SIZE = {
  MD: "medium",
  LG: "largeScreen",
  XL: "extraLargeScreen",
  XXL: "doubleExtraLargeScreen",
  FULL: "fullScreen",
};

export const MODAL_POSITION = {
  TOP: "top",
  CENTER: "center",
};

export const MODAL_TYPES = {
  MANAGE_TRIP: "Manage Trip",
  CREATE_TRIP: "Create Trip",
  PAYMENT_MODE: "Payment Mode",
  CREATE_NEW_TRIP: "Create New Trip",
};

export const ESCAPE = "Escape";

export const KEYDOWN = "keydown";

export const SEARCH_SECTION = {
  FLIGHT: "flight",
  HOTEL: "hotel",
  CARS: "cars",
};

export const KEYBOARD_KEY_CODES = {
  UP_KEY_CODE: 38,
  DOWN_KEY_CODE: 40,
  ENTER_KEY_CODE: 13,
};

export const USER_INITIAL_VALUE ={
  ROLEID: "roleId",
  IS_WALLET_ENABLED: "isWalletEnabled"
}

export const DEFAULT_USER_IP_ADDRESS = "182.78.207.162";

export const DEBOUNCE_TIME = 300;

export const INITIAL_HOTEL_VALUES = {
  price: {
    minPrice: "",
    maxPrice: "",
    currency: "",
  },
  starRatings: {},
  categories: {},
  locality: {},
};

export const INITIAL_HOTEL_FILTERS = {
  price: { minPrice: "", maxPrice: "" },
  starRatings: [],
  categories: [],
  locality: [],
};

export const HOTEL_DESTINATION_TYPES = {
  CITY: 1,
  HOTEL: 2,
};

export const HOTEL_DEFAULT_VALUES = {
  roomGuests: [{ noOfAdults: 1, noOfChild: 0, childAge: [] }],
  noOfRooms: DEFAULT_VALUES.ONE,
  checkIn: new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0],
  targetDate: new Date(
    new Date().setDate(new Date().getDate() + DEFAULT_VALUES.TWO)
  )
    .toISOString()
    .split("T")[DEFAULT_VALUES.ZERO],
  noOfRooms: DEFAULT_VALUES.ONE,
  noOfNights: DEFAULT_VALUES.ONE,
  guestNationality: {
    isoCode: "IN",
    name: "India",
  },
  destination: {
    countryCode: "IN",
    destinationId: "119513",
    cityName: "Gurugram",
    type: HOTEL_DESTINATION_TYPES.CITY,
  },
};

export const POSITIONS = {
  LEFT: "left",
  RIGHT: "right",
};

export const FILTERS_FIELDS = [
  { interface: "price", label: "price" },
  { interface: "time", label: "arrivalTime" },
  { interface: "time", label: "departureTime" },
  { interface: "stops", label: "stops" },
  { interface: "airline", label: "airline" },
];
export const DATE_FORMAT = "YYYY-MM-DD";

export const FLIGHT_SERVICE_TYPE = {
  AMADEUS: "AMADEUS",
  TBO: "TBO",
};

export const ENDPOINTS = {
  TBO_BOOKING_URL: "/book",
  AMADEUS_BOOKING_URL: "/book",
  TBO_REISSUANCE_URL: "/reissuance",
};

export const AUTH_ROLES = {
  USER: "USER",
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SUPERADMIN",
};

export const LOG_RESULT_TABLE_COLUMNS = [
  {
    dataField: "correlationId",
    text: "Correlation Id",
  },
  {
    dataField: "url",
    text: "URL",
  },
  {
    dataField: "created",
    text: "Created At",
  },
  {
    dataField: "serviceType",
    text: "Service type",
  },
  {
    dataField: "logType",
    text: "Log type",
  },
  {
    dataField: "bookingId",
    text: "Booking Id",
  },
  {
    dataField: "logData",
    text: "Log Data",
  },
];

export const BOOKING_TABLE_COLUMNS = {
  tavaBookingId: "Booking Id",
  userEmail: "Email",
  provider: "Supplier",
  status: "Booking Status",
  paymentStatus: "Payment Status",
  createdAt: "Booked At",
  updatedAt: "Modified At",
  pnr: "PNR",
  ticketingStatus: "Ticketing Status",
  bookingData: "Booking data",
};

export const FLIGHT_PROVIDERS = {
  TBO: "TBO",
  AMADEUS: "AMADEUS",
};

export const ADMIN_BOOKING_TABS = [
  "All",
  "Confirmed",
  "Pending",
  "Rejected",
  "Cancelled",
];

export const MINI_FARE_RULES = {
  REISSUE: "Reissue",
  CANCELLATION: "Cancellation",
};

export const INITIAL_FLIGHT_COUNT = {
  packages: DEFAULT_VALUES.ZERO,
  inbound: DEFAULT_VALUES.ZERO,
  outbound: DEFAULT_VALUES.ZERO,
};

export const TRAVELER_BUTTON_TYPES = {
  DECREMENT: "decrement",
  INCREMENT: "increment",
};

export const SEAT_TYPE_INFO = {
  0: "NotSet",
  1: "Window",
  2: "Aisle",
  3: "Middle",
  4: "WindowRecline",
  5: "WindowWing",
  6: "WindowExitRow",
  7: "WindowReclineWing",
  8: "WindowReclineExitRow",
  9: "WindowWingExitRow",
  10: "AisleRecline",
  11: "AisleWing",
  12: "AisleExitRow",
  13: "AisleReclineWing",
  14: "AisleReclineExitRow",
  15: "AisleWingExitRow",
  16: "MiddleRecline",
  17: "MiddleWing",
  18: "MiddleExitRow",
  19: "MiddleReclineWing",
  20: "MiddleReclineExitRow",
  21: "MiddleWingExitRow",
  22: "WindowReclineWingExitRow",
  23: "AisleReclineWingExitRow",
  24: "MiddleReclineWingExitRow",
  25: "WindowBulkhead",
  26: "WindowQuiet",
  27: "WindowBulkheadQuiet",
  28: "MiddleBulkhead",
  29: "MiddleQuiet",
  30: "MiddleBulkheadQuiet",
  31: "AisleBulkhead",
  32: "AisleQuiet",
  33: "AisleBulkheadQuiet",
  34: "CentreAisle",
  35: "CentreMiddle",
  36: "CentreAisleBulkhead",
  37: "CentreAisleQuiet",
  38: "CentreAisleBulkheadQuiet",
  39: "CentreMiddleBulkhead",
  40: "CentreMiddleQuiet",
  41: "CentreMiddleBulkheadQuiet",
  42: "WindowBulkheadWing",
  43: "WindowBulkheadExitRow",
  44: "MiddleBulkheadWing",
  45: "MiddleBulkheadExitRow",
  46: "AisleBulkheadWing",
  47: "AisleBulkheadExitRow",
};

export const TIME_PERIODS = [
  { period: "Morning", icon: Morning },
  { period: "Noon", icon: Noon },
  { period: "Evening", icon: Evening },
];

export const FARE_DETAILS = {
  ["ARMED_FORCES"]: `fareTypeDetails.armedForces`,
  ["STUDENT"]: `fareTypeDetails.student`,
  ["SENIOR_CITIZEN"]: `fareTypeDetails.seniorCitizen`,
};

export const SSR_BUTTONS = {
  TRAVELERS: "travelers",
  SEATS: "seats",
  MEALS: "meals",
  BAGGAGES: "baggages",
  SAVE: "save",
};

export const TBO_FARE_TYPES = {
  REGULAR: "RegularFare",
  ARMED_FORCES: "MilitaryFare",
  STUDENT: "StudentFare",
  SENIOR_CITIZEN: "SeniorFare",
};

export const SORTING_HEADERS = {
  cheapest: {
    name: "Cheapest",
    title: "sorting.cheapest.header",
  },
  nonStopFirst: {
    name: "Non Stop First",
    title: "sorting.nonStopfirst.header",
  },
  fastest: {
    name: "Fastest",
    title: "sorting.fastest.header",
  },
  best: {
    name: "Best",
    title: "sorting.best.header",
  },
  arrival: {
    name: "Arrival",
    title: "sorting.arrival.header",
  },
  departure: {
    name: "Departure",
    title: "sorting.departure.header",
  },
};

export const ACTIVE_SORT_MESSAGE = {
  ["Cheapest"]: "sorting.cheapest.message",
  ["Non Stop First"]: "sorting.nonStopfirst.message",
  ["Fastest"]: "sorting.fastest.message",
  ["Best"]: "sorting.best.message",
  ["Arrival"]: "sorting.arrival.message",
  ["Departure"]: "sorting.departure.message",
};

export const FOOTER_CONTENT = [
  {
    logo: logo,
    title: "footerContent.whyTripTava.title",
    content: "footerContent.whyTripTava.content",
    imageType: "image",
  },
  {
    logo: DeparturePlaneIcon,
    title: "footerContent.booking.title",
    content: "footerContent.booking.content",
    imageType: "svg",
  },
  {
    logo: bookingIcon,
    title: "footerContent.domestic.title",
    content: "footerContent.domestic.content",
    imageType: "svg",
  },
];

export const CATEGORY_TITLES = {
  31: {
    header: "Reissue/Changes Details",
    dateInfo: "Date and time Details",
    penalityInfo: "Penalities Applied for",
    restrictionInfo: "Reissue Restrictions",
  },
  33: {
    header: "Refund Details",
    penalityInfo: "Penalities Applied for",
    restrictionInfo: "Refund Restrictions",
  },
};

export const TITLES = {
  Adult: ["Mr", "Mrs", "Ms"],
  Child: ["Mr", "Ms"],
  Infant: ["Mstr", "Mr", "Ms"],
};

export const ROUNDTRIP_RESULT_FORMATS = {
  ISOLATED: "isolated",
  PACKAGES: "packages",
};

export const BOOKING_FAILURE_MESSAGE = [
  {
    title: "Booking Unsuccessful",
    text: "We regret to inform you that, unfortunately, we were unable to confirm your recent booking at this time. If payment was deducted, a full refund will be processed within 6-8 working days. Please note that the exact processing time may vary depending on your selected payment method and financial institution.",
  },
  {
    title: "Customer Support",
    text: '    For any additional concerns or inquiries, our dedicated customer support team is here to assist you. Feel free to reach out to us at <span className="text-primary-600"> <a href="mailto:support@tavatrip.com">support@tavatrip.com</a></span>. Your understanding is greatly appreciated.',
  },
  {
    title: "Thank You",
    text: "Thank you for considering our services. We look forward to the opportunity to assist you in the future. Safe travels!",
  },
];
const { EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;

export const INITIAL_CONTACT_DETAILS = {
  email: EMPTY_STRING,
  phoneNumber: EMPTY_STRING,
  phoneCode: EMPTY_STRING,
};

export const INITIAL_ADDRESS_DETAILS = {
  address: EMPTY_STRING,
  city: EMPTY_STRING,
  state: EMPTY_STRING,
  nationality: { isoCode: EMPTY_STRING },
};

export const INITIAL_TRAVELER_VALIDATION_SCHEMA = {
  [ADULT]: EMPTY_OBJECT,
  [CHILD]: EMPTY_OBJECT,
  [HELD_INFANT]: EMPTY_OBJECT,
};

export const ENVIRONMENTS = {
  PRODUCTION: "production",
  PROD: "prod",
  DEVELOPMENT: "development",
  DEV: "dev",
  STAGING: "staging",
};

const { REACT_APP_ENV, REACT_APP_SHOULD_ENABLE_HOTELS, REACT_APP_MODE, REACT_APP_AI_SEARCH_FLAG } =
  process.env;
export const isProdEnv =
  REACT_APP_ENV === ENVIRONMENTS.PRODUCTION ||
  REACT_APP_ENV === ENVIRONMENTS.PROD;
export const shouldEnableHotels = JSON.parse(
  REACT_APP_SHOULD_ENABLE_HOTELS || false
);

export const shouldEnableAISearch = JSON.parse(
  REACT_APP_AI_SEARCH_FLAG || false
);

const MODE_LOGOS = {
  ALPHA: logoWithBrandAlpha,
  BETA: logoWithBrandBeta,
};
export const LOGO_WITH_BRAND = Object.keys(MODE_LOGOS).includes(REACT_APP_MODE)
  ? MODE_LOGOS[REACT_APP_MODE]
  : logoWithBrand;

export const WEEK_DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const BOOKING_STATUS_CODES = {
  CONFIRMED: "CONFIRMED",
  FAILED: "FAILED",
  CANCELLED: "CANCELLED",
  PARTIALLY_CONFIRMED: "PARTIALLY_CONFIRMED",
  PARTIALLY_CANCELLED: "PARTIALLY_CANCELLED",
  DRAFT: "DRAFT",
  PENDING: "PENDING"
};

export const BOOKING_CANCELLATION_STATES = {
  CANCEL: "cancel",
  CONFIRM: "confirm",
  REFUND: "refund",
  SUCCESS: "success",
  FAILURE: "failure",
};

export const BOOKING_CATEGORIES = {
  CONFIRMED: "CONFIRMED",
  UPCOMING: "UPCOMING",
  UNSUCCESSFUL: "UNSUCCESSFUL",
  CANCELED: "CANCELED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESS",
  AWAITING_PAYMENT: "AWAITINGPAYMENT",
  PAST: "PAST",
  REJECTED: "REJECTED",
};

export const HOTEL_BOOKING_CATEGORIES = {
  HOTEL_CONFIRMED: "Confirmed",
  HOTEL_CANCELED: "CANCELED",
  HOTEL_AWAITING_PAYMENT: "AWAITING PAYMENT",
};

export const BOOKING_SEARCH_OPTIONS = {
  BOOKING_ID: "tavaBookingId",
  PNR: "pnr",
};

export const PG_PROCESSING_FEE_RATE = 0.02;
export const GST_RATE = 0.18;

export const FORMATTED_API_ENDPOINTS = {
  PRICE: "/flights/price",
  BOOKING: "booking",
  SESSION: "session",
  HOTEL_PRICE: "hotels/price",
  VERIFY_PRICE_POLICY: "hotels/verify-price-policy",
};

export const CANCELLATION_CHARGE_TYPES = {
  AMOUNT: 1,
  PERCENTAGE: 2,
  NIGHTS: 3,
};

export const BOOKING_TYPES = {
  VOUCHER_BOOKING: "voucher",
  NON_VOUCHER_BOOKING: "non-voucher",
};

export const GUEST_TYPES = {
  ADULT: 1,
  CHILD: 2,
};

export const FARE_TYPE_MAPPING = {
  Regular: "RegularFare",
  ArmedForces: "MilitaryFare",
  Student: "StudentFare",
  "Senior Citizen": "SeniorFare",
};

export const DEFAULT_COUNTRY_INFO = {
  name: "India",
  code: "IN",
  capital: "New Delhi",
  region: "AS",
  currency: {
    code: "INR",
    name: "Indian rupee",
    symbol: "₹",
  },
  language: {
    name: "English",
    code: "en",
  },
  flag: "https://flagcdn.com/48x36/in.png",
  ip: "",
};

export const razorpayContainerClass = ".razorpay-container";

// Reference: https://developers.amadeus.com/api-library/soap/functional-doc/581/doc-read/140586?serviceVersion=24.1
export const REFUNDABILITY_CODES = {
  NON_REFUNDABLE: "70",
  PENALTY_REFUNDABLE: "73",
  PERCENT_PENALTY_REFUNDABLE: "74",
};

export const REQUEST_STATUS_MAPPING = {
  0: "Unable to fetch the status",
  1: "Cancellation Request is Pending.",
  2: "Cancellation Request is In Progress and will be cancelled within the next 7 days.",
  3: "Booking Cancelled Successfully",
  4: "Booking Cancellation Rejected",
};

export const SKELETON_COLOR = {
  BASE_COLOR: "#e5e7eb",
  HIGHLIGHT_COLOR: "#9ca3af",
};

export const TRAVELLER_INFORMATION_SECTIONS = {
  CONTACT_INFO: "CONTACT_INFO",
  ADDRESS_INFO: "ADDRESS_INFO",
};

export const TAVATRIP_FEE_DETAIL = "1.18 (0.18 GST @18%)";
export const PLATFORM_FEE = 1;
export const PLATFORM_FEE_GST = 0.18;

export const NAVIGATION_KEYS = ["dashboard", "bookings", "approvals", "myTrips", "myCompanyTrips"];
export const ACCOUNT_KEYS = [
  "policies",
  "costCodes",
  "users",
  "billing",
  "settings",
  "permissions",
  "transactions",
];

export const AVAILABLE_PERMISSIONS = {
  CAN_VIEW_DASHBOARD: "canViewDashboard",
  CAN_EDIT_COMPANY_ADDRESS: "canEditCompanyAddress",
  CAN_SET_UP_PAYMENT_METHOD: "canSetPaymentMethod",
  CAN_SET_UP_POLICY: "canSetupPolicy",
  CAN_INVITE_TEAM: "canInviteTeam",
  CAN_VIEW_TOTAL_SPENT: "canViewTotalSpent",
  CAN_VIEW_TOTAL_TRIPS: "canViewTotalTrips",
  CAN_VIEW_TOTAL_BOOKINGS: "canViewTotalBookings",
  CAN_VIEW_APPROVALS: "canViewApprovals",
  CAN_VIEW_BOOKINGS: "canViewBookings",
  CAN_VIEW_POLICIES: "canViewPolicies",
  CAN_VIEW_COST_CODES: "canViewCostCodes",
  CAN_VIEW_USERS: "canViewUsers",
  CAN_VIEW_TRANSACTIONS: "canViewTransactions",
  CAN_VIEW_SETTINGS: "canViewSettings",
  CAN_VIEW_ANALYTICS_CHART: "canViewAnalyticsChart",
  CAN_VIEW_TRANSACTIONS_DETAILS: "canViewTransactionsDetails",
  CAN_EDIT_POLICIES: "canEditPolicy",
  CAN_RESET_POLICIES: "canResetPolicies",
  CAN_MANAGE_DEPARTMENT_EXCEPTION: "canManageDepartmentException",
  CAN_VIEW_ROLES: "canViewRoles",
  CAN_UPDATE_PASSWORD: "canUpdatePassword",
  CAN_BOOK: "canBook",
  CAN_VIEW_WALLET: "canViewWallet",
  CAN_UPDATE_WALLET: "canUpdateWallet",
  CAN_CREATE_WALLET: "canCreateWallet",
  CAN_VIEW_COST_CODES:"canViewCostCodes",
  CAN_CREATE_COST_CODES:"canCreateCostCodes",
  CAN_DELETE_COST_CODES:"canDeleteCostCodes",
  CAN_MANAGE_COST_CODES:"canManageCostCodes",
  CAN_UPDATE_ALL_USER_DETAILS:"canUpdateAllUsersDetails",
  CAN_UPDATE_OWN_PROFILE_DETAILS:"canUpdateOwnProfileDetails",
  CAN_VIEW_TENANT_USERS: 'canViewTenantUsers',
  CAN_SHOW_ALL_BOOKINGS: 'canShowAllBookings',
  CAN_SHOW_OWN_BOOKINGS:'canShowOwnBookings'
};

export const ACTION_MODAL_TYPES = {
  CREATE_MODAL: "createModal",
  EDIT_MODAL: "editModal",
  DELETE_MODAL: "deleteModal",
  ADD_MODAL: "addModal",
};

export const BOOK_NEW_TRIP_TABS = {
  FLIGHT: "Flight",
  HOTEL: "Hotel",
};

export const CURRENCY_TYPES = [
  { id: 1, code: "INR", symbol: "₹" },
  { id: 2, code: "USD", symbol: "$" },
  { id: 3, code: "EUR", symbol: "€" },
  { id: 4, code: "GBP", symbol: "£" },
  { id: 5, code: "AED", symbol: "د.إ" },
  { id: 6, code: "LKR", symbol: "Rs" },
];
export const NAV_ROUTES_NAME = {
  "/dashboard": "Dashboard",
  "/bookings": "Bookings",
  "/cost-codes": "Cost Codes",
  "/account": "Account",
  "/profile": "Profile",
};

export const CLICKABLE_NAV_ROUTE = [
  "/dashboard",
  "/bookings",
  "/cost-codes",
  "/profile",
];

export const INVITATION_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
};

export const ERROR_MESSAGES = {
  ERROR_SENDING_INVITE: "Failed to send invite, please try again later",
  SOMETHING_WENT_WRONG: "Something went wrong. Please try again!",
  ERROR_GETING_HOTEL_RESULTS:
    "Sorry, we couldn't find any results for your hotel request. Please provide more details or clarify your prompt for better results.",
  ERROR_GETING_FLIGHT_RESULTS:
    "Sorry, we couldn't find any results for your flight request. Please provide more details or clarify your prompt for better results.",
  ERROR_WALLET_DISABLED:
    "Your wallet is currently disabled. Please enable your wallet to proceed.",
  ERROR_WALLET_BALANCE_LOW:
    "Your wallet balance is insufficient. Please add funds to your wallet to proceed.",
};

export const SIDEPANEL_TABS = {
  dashboard: {
    id: "dashboard",
    name: "Dashboard",
  },
  bookings: {
    id: "bookings",
    name: "Bookings",
  },
  approvals: {
    id: "approvals",
    name: "Approvals",
  },
  policies: {
    id: "policies",
    name: "Policies",
  },
  costCodes: {
    id: "costCodes",
    name: "Cost Codes",
  },
  users: {
    id: "users",
    name: "Users",
  },
  billing: {
    id: "billing",
    name: "Billing",
  },
  transactionsDetails: {
    id: "transactions",
    name: "Transactions",
  },
  settings: {
    id: "settings",
    name: "Account",
  },
  permissions: {
    id: "permissions",
    name: "Permissions",
  },
  notifications: {
    id: "notifications",
    name: "Notifications",
  },
  profile: {
    id: "profile",
    name: "Profile",
  },
  logOut: {
    id: "logOut",
    name: "Log Out",
  },
};

export const ANALYTICS_PARAM = {
  INTERVAL: {
    MONTH: "month",
    YEAR: "year",
    QUARTER: "quarter",
    CUSTOM: "custom",
  },
  REFERENCE_PERIOD: {
    CURRENT: "current",
    LAST: "last",
  },
};

export const NOT_AVAILABLE = "N/A";

export const BADGE_LABELS = {
  DRAFT: "DRAFT",
  IN_REVIEW: "IN_REVIEW",
  CONFIRMED: "CONFIRMED",
  AWAITING_PAYMENT: "AWAITING_PAYMENT",
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
  FAILED: "FAILED",
  CANCELLED:"CANCELLED",
  PARTIALLY_CONFIRMED: "PARTIALLY_CONFIRMED"
};

export const TRIPS_CATEGORIES = [
  { key: "DRAFT", name: "Draft" },
  { key: "CONFIRMED", name: "Confirmed" },
  { key: "ALL", name: "All" },
];

export const POLICY_SERVICE = {
  FLIGHTS: "FLIGHTS",
  HOTELS: "HOTELS",
};
export const BOOKING_TYPE = {
  FLIGHT: "FLIGHT",
  HOTEL: "HOTEL",
  FLIGHT_HOTEL: "FLIGHT_HOTEL",
};

export const POLICY_CATAGORY = {
  COST: "cost",
  AIRLINES: "airlines",
  RATING: "rating"
};

export const BOOKING_DOCUMENTS_TYPE = {
    PASSPORT: 'PASSPORT',
    PAN: 'PAN'
}

export const MIN_MONTHS_DIFF_FOR_PASSPORT_EXPIRY = 6

export const DEFAULT_PAYMENT_GATEWAY_CHARGE = {
    DEFAULT_GATEWAY_CHARGE: 0.02,
    DEFAULT_GST_GATEWAY_CHARGE: 0.0036,
}

export const INVITE_TEMPLATE_URL = 
    "https://devtavajsapptemplate.s3.ap-south-1.amazonaws.com/trip_tava_b2b/invite-template-1.csv"

export const RE_ISSURANCE_FLIGHTS_CODE = ["6E", "SG", "G8"];

export const ALLOWED_COLUMNS = {
  "Trip Name": "TripName",
  "Dates": "CreatedAt",
  "Date":"CreatedAt"
};

export const DEFAULT_SORT_FIELD = 'CreatedAt';
export const DEFAULT_SORT_ORDER = 'desc';

export const SORT_ORDER = {
  DESCENDING: 'desc',
  ASCENDING: 'asc'
}

export const FLIGHT_CATEGORIES = {
  INTERNATIONAL: "international",
  DOMESTIC: "domestic",
 }

 export const CONTACT_EMAIL ='support@tavatrip.com';

 export const UPDATE_DATA_CONSENT_MESSAGE = {
   UPDATE_OWN_DATA:
     "By clicking this, you agree to permanently update your own data",
   UPDATE_USERS_DATA:
     "By clicking this, you agree to permanently update the user's data",
 };

 export const MINI_FARE_RULES_CHARGES = {
  NO_REFUND: "100%",
  FULL_REFUND: "nil",
};

export const MINI_FARE_RULES_TYPES = {
  CANCELLATION: "cancellation",
  REISSUE: "reissue",
};

export  const HOTEL_RESULT_SORT_OPTIONS = {
  STAR_ASCENDING: "starAscending",
  STAR_DESCENDING: "starDescending",
  PRICE_ASCENDING: "priceAscending",
  PRICE_DESCENDING: "priceDescending",
};

export const USERS_SORT_FIELDS = {
  "User": "Name",
  "Created At": "CreatedAt",
};

export const INVITE_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  EXPIRED: "EXPIRED",
};

export const USER_FIELDS = {
  CREATED_AT : "CreatedAt",
  NAME : "Name",
}

export const EMPTY_LAYOUT_TITLE = "No Users Found";

export const USER_COLUMN_HEADERS = {
  USER: "User",
  ROLE: "Role",
  STATUS: "Status",
  CREATED_AT: "Created At",
  UPDATED_AT: "Updated At",
  EXPIRY_DATE: "Expiry Date",
};

export const ANALYTICS_TIME_PERIODS = [
  {
    id: 1,
    name: "This Month",
    referencePeriod: "current",
    interval: "month",
  },
  {
    id: 2,
    name: "Last Month",
    referencePeriod: "last",
    interval: "month",
  },
  {
    id: 3,
    name: "This Year",
    referencePeriod: "current",
    interval: "year",
  },
  {
    id: 4,
    name: "Last Year",
    referencePeriod: "last",
    interval: "year",
  },
  {
    id: 5,
    name: "This Quarter",
    referencePeriod: "current",
    interval: "quarter",
  },
  {
    id: 6,
    name: "Last Quarter",
    referencePeriod: "last",
    interval: "quarter",
  },
  {
    id: 7,
    name: "Custom Date",
    referencePeriod: null,
    interval: "custom",
  },
];

export const DATE_PICKER_WEEKDAYS  = ["S", "M", "T", "W", "T", "F", "S"];

export const GUESTS_TITLES = {
  Adult: ["Mr", "Mrs", "Ms"],
  Child: ["Mr", "Miss"],
  Infant: ["Mstr", "Miss"],
};

export const ANALYTICS_INFO_BUTTON = {
  WEEKLY_BOOKINGS_TOOLTIP:
    "This graph shows weekly bookings based on trip creation dates",
  MONTHLY_BOOKINGS_TOOLTIP:
    "This graph shows monthly bookings based on trip creation dates",
  YEARLY_BOOKINGS_TOOLTIP:
    "This graph shows yearly bookings based on trip creation dates",
};
export const INDIAN_NUMERIC_UNITS = {
  THOUSAND: 'K',
  LAKH: 'L',
  CRORE: 'Cr'
};

export const US_NUMERIC_UNITS = {
  MILLION: 'M',
  BILLION: 'B'
}

