import { camelCase, mapKeys } from "lodash";
import { HotelsDarkIcon, LocationMarker, RenderSVG } from "../../assets/icons";
import { HOTEL_DESTINATION_TYPES } from "../../constants";

const { CITY } = HOTEL_DESTINATION_TYPES;

const HotelListItem = ({ hotelData }) => {
  const { hotelName, stateProvince, countryName, cityName, type } = mapKeys(
    hotelData,
    (value, key) => camelCase(key)
  );
  
  return (
    <div>
      {type === CITY ? (
        <div className='flex gap-2'>
          <div className='icon shrink-0'>
            <RenderSVG
              Svg={LocationMarker}
              width='24'
              height='24'
              className='text-contrast-400'
              alt='location-icon'
            />
          </div>
          <div className='flex-1 flex gap-3 items-center w-full'>
            <div className='flex-1'>
              <h6 className='text-base font-semibold text-contrast-900 mb-1 w-full truncate'>
                {cityName}
              </h6>
              <span className='text-xs text-contrast-500 flex'>
                Hotels in {stateProvince && ` ${stateProvince},`} {countryName}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex gap-2'>
          <div className='icon shrink-0'>
            <RenderSVG
              Svg={HotelsDarkIcon}
              width='24'
              height='24'
              className='text-contrast-400'
              alt='hotel-icon'
            />
          </div>
          <div className='flex-1 flex gap-3 items-center w-full'>
            <div className='flex-1'>
              <h6 className='text-base font-semibold text-contrast-900 mb-1 w-full truncate'>
                {hotelName}
              </h6>
              <span className='text-xs text-contrast-500 flex'>
                Hotel in {cityName}, {stateProvince || countryName}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelListItem;
