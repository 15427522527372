import Users from "./Users";
import * as slice from "./users.reducers";
import * as selectors from "./users.selectors";
import * as userActions from "./users.actions";

export const { getUsers, updateUser, deleteUser, getInvitedUsersList } = userActions;

export const { name, reducer } = slice;

export const { selectUsersData, selectInvitesData } = selectors;

export default Users;
