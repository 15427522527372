import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_VALUES } from "../../constants";
import { getPolicies } from "./policies.actions";

const { EMPTY_ARRAY } = DEFAULT_VALUES;

const initialState = {
  allServicesPolicy: EMPTY_ARRAY,
  selectedServicePolicyId: null,
};

const slice = createSlice({
  name: "policies",
  initialState,
  reducers: {
    setSelectedServicePolicyId(state, action) {
      state.selectedServicePolicyId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPolicies.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.allServicesPolicy = action.payload;
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
