import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../../components/organisms/Tabs";
import Flights from "./Flights";
import Hotels from "./Hotels";
import { RenderSVG, FlightsGray, HotelBed } from "../../assets/icons";
import { DEFAULT_VALUES } from "../../constants";
import {
  selectAllServicesPolicy,
  getPolicies,
  selectSelectedServicePolicyId,
} from "./index";
import { actions } from "./policies.reducer";
import { selectCurrentUserInfo } from "../Auth";

const { setSelectedServicePolicyId } = actions;

const { EMPTY_ARRAY, EMPTY_OBJECT, ZERO } = DEFAULT_VALUES;
const FLIGHTS = "FLIGHTS";
const HOTELS = "HOTELS";

const Title = ({ data }) => {
  const dispatch = useDispatch();
  const { serviceName: title, id } = data;
  const getTabsIcon = () => {
    switch (title) {
      case FLIGHTS:
        return FlightsGray;
      case HOTELS:
        return HotelBed;
      default:
        return FlightsGray;
    }
  };
  const handleTabChange = () => dispatch(setSelectedServicePolicyId(id));

  return (
    <div
      className='flex justify-center p-6 gap-3 items-center text-sm'
      onClick={handleTabChange}
    >
      <RenderSVG Svg={getTabsIcon()} height={20} width={20} color='inherit' />
      <span className='font-semibold text-current text-sm'>{title}</span>
    </div>
  );
};

const POLICIES_TABS = [
  { element: <Flights />, tabName: FLIGHTS },
  { element: <Hotels />, tabName: HOTELS },
];

const Policies = () => {
  const dispatch = useDispatch();
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const allServicesPolicy = useSelector(selectAllServicesPolicy);
  const selectedServicePolicyId = useSelector(selectSelectedServicePolicyId);
  const [tabDetails, setTabDetails] = useState(EMPTY_ARRAY);

  useEffect(() => {
    if (!currentUserInfo) return;
    const { tenantId } = currentUserInfo;
    tenantId && dispatch(getPolicies(tenantId));
  }, [currentUserInfo]);

  const createTabs = (allServicesPolicy) =>
    POLICIES_TABS.map((tab) => {
      const { tabName } = tab;
      let isDefault;
      const policy = allServicesPolicy.find(
        ({ serviceName }) => serviceName === tabName
      );
      const { id } = policy || EMPTY_OBJECT;
      if (!selectedServicePolicyId && tabName === FLIGHTS) {
        isDefault = true;
        dispatch(setSelectedServicePolicyId(id));
      }
      if (selectedServicePolicyId === id) isDefault = true;

      return {
        id,
        default: isDefault,
        title: <Title data={policy} />,
        ...tab,
      };
    });

  useEffect(() => {
    if (!allServicesPolicy.length) return;
    const tabs = createTabs(allServicesPolicy);
    setTabDetails(tabs);
  }, [allServicesPolicy]);

  return (
    <div className='h-full w-full bg-contrast-50'>
      <div className=' border border-contrast-200 bg-white'>
        {!!tabDetails.length && <Tabs tabs={tabDetails} className='!p-0' />}
      </div>
    </div>
  );
};

export default Policies;
