import React, { useRef, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const createOptions = (maxGraphValue) => ({
  responsive: true,
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        callback: () => "",
      },
    },
    y: {
      grid: {
        drawOnChartArea: false,
      },
      beginAtZero: true,
      ticks: {
        callback: function(value) {
          if (Number.isInteger(value)) {
            return value;
          }
        }
      },
      suggestedMax: maxGraphValue + (maxGraphValue*0.1),
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        padding: 15,
        usePointStyle: true,
      },
      onHover: function (event, legendItem) {
          document.body.style.cursor = 'pointer';
        },
        onLeave: function () {
          document.body.style.cursor = 'default';
        },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const { label, dataset, parsed } = context;
          return ` ${dataset.label} - ${parsed.y}`;
        },
      },
      backgroundColor: "",
    },
  },
  maintainAspectRatio: false,
});

const hotelDatasetConfig = {
  label: "Hotel Bookings",
  borderColor: "#1F2937",
  backgroundColor: (context) => {
    const ctx = context.chart.ctx;
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#8BD3D0");
    gradient.addColorStop(0.5, "#5FBCB1");
    gradient.addColorStop(1, "#3D9C9A");
    return gradient;
  },
  hoverBackgroundColor: "#8BD3D0",
  barThickness: 15,
  borderRadius: 5,
};

const flightDatasetConfig = {
  label: "Flight Bookings",
  borderColor: "#1F2937",
  backgroundColor: (context) => {
    const ctx = context.chart.ctx;
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#3B82F6");
    gradient.addColorStop(0.5, "#60A5FA");
    gradient.addColorStop(1, "#3B82F6");
    return gradient;
  },
  hoverBackgroundColor: "#1D4ED8",
  barThickness: 15,
  borderRadius: 5,
};

const UserTripsBarChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      chart.update("none");
      const xScale = chart.scales.x;

      const container = chart.canvas.parentNode;
      const imageDivs = [];

      data.forEach((_, index) => {
        const imgSrc = data[index].userProfile || `https://robohash.org/${data[index].userId}.png?size=500x500&&bgset=bg2`;
        const name = data[index].userName || "";

        const div = document.createElement("div");
        div.style.position = "absolute";
        div.style.width = "35px";
        div.style.height = "35px";
        div.style.backgroundImage = `url(${imgSrc})`;
        div.style.backgroundSize = "cover";
        div.style.backgroundPosition = "center";
        div.style.borderRadius = "50%";
        div.style.marginTop = "15px";
        div.style.marginBottom = "20px";
        div.style.transition = "transform 0.3s ease"; 
        div.style.border = "2px solid #e5e7eb"; 

        const imgLabelArrow = document.createElement('div');
        imgLabelArrow.style.height="0px";
        imgLabelArrow.style.width="0px";
        imgLabelArrow.style.borderWidth="5px";
        imgLabelArrow.style.borderColor="transparent transparent black transparent";
        imgLabelArrow.style.margin="auto"
        imgLabelArrow.style.marginTop="25px"
        imgLabelArrow.style.animation="append-label 0.3s linear"

        const imgLabel = document.createElement('div');
        imgLabel.innerHTML = name;
        imgLabel.style.backgroundColor = "black";
        imgLabel.style.color="#FDFDFD";
        imgLabel.style.fontSize="8px";
        imgLabel.style.fontWeight="bold";
        imgLabel.style.padding="4px";
        imgLabel.style.borderRadius="5px";
        imgLabel.style.zIndex="100";
        imgLabel.style.whiteSpace = 'nowrap';
        imgLabel.style.position="absolute";
        imgLabel.style.left="50%";
        imgLabel.style.marginTop="35px";
        imgLabel.style.transform="translateX(-50%)";
        imgLabel.style.animation="append-label 0.3s linear";

        imgLabel.addEventListener('mouseenter', () => {
          div.removeChild(imgLabel);
          div.removeChild(imgLabelArrow);
        });

        div.addEventListener('mouseenter', () => {
          div.appendChild(imgLabel);
          div.appendChild(imgLabelArrow);
          div.style.transform = "scale(1.1)";
        });

        div.addEventListener('mouseleave', () => {
          div.removeChild(imgLabel);
          div.removeChild(imgLabelArrow);
          div.style.transform = "scale(1)";
        });

        container.appendChild(div);

        imageDivs.push(div);
      });

      const updateImagePositions = () => {
        imageDivs.forEach((div, index) => {
          const x = xScale.getPixelForTick(index) - 20;
          const y = chart.scales.y.bottom + 1;
          div.style.left = `${x}px`;
          div.style.top = `${y}px`;
        });
      };

      updateImagePositions();

      return () => {
        imageDivs.forEach((div) => div.remove());
      };
    }
  }, [chartRef]);

  const labels = data.map((item) => item.userName);
  const hotelBookings = data.map((item) => item.hotelBookingCount);
  const flightBookings = data.map((item) => item.flightBookingCount);
  const maxGraphValue = Math.max(...hotelBookings, ...flightBookings)

  const barChartData = {
    labels,
    datasets: [
      {
        ...hotelDatasetConfig,
        data: hotelBookings,
      },
      {
        ...flightDatasetConfig,
        data: flightBookings,
      },
    ],
  };
  const options = createOptions(maxGraphValue);

  return (
    <div className='relative w-full h[50vh]'>
      <ScrollMenu>
        <div
          className='relative h-[50vh] pb-8 min-w-[800px]'
          style={{ width: data.length * 80, marginBottom:"15px"}}
        >
          <Bar ref={chartRef} options={options} data={barChartData} />
        </div>
      </ScrollMenu>
    </div>
  );
};

export default UserTripsBarChart;
