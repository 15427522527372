import { get, isEmpty } from "lodash";
import { DEFAULT_VALUES } from "../../constants";

const SSR_FIELD = {
  seatDynamic: { stateKey: "seatDynamic", defaultCode: "NoSeat" },
  mealDynamic: { stateKey: "mealDynamic", defaultCode: "NoMeal" },
  baggage: { stateKey: "baggage", defaultCode: "NoBaggage" },
};

const { ZERO, EMPTY_OBJECT } = DEFAULT_VALUES;
// TODO: Need to make these keys generic

const SEAT_DYNAMIC = "seatDynamic";
const MEAL_DYNAMIC = "mealDynamic";
const BAGGAGE = "baggage";
const NO_MEAL = "NoMeal";
const NO_BAGGAGE = "NoBaggage";
const combineFlights = (flights) => {
  const routeKeys = Object.keys(flights);
  const result = {};
  const combinedRoutes = new Set();

  routeKeys.forEach((route1) => {
    if (combinedRoutes.has(route1)) return;

    let combinedArray = [...flights[route1]];
    let combinedKey = route1;

    routeKeys.forEach((route2) => {
      if (route1 === route2 || combinedRoutes.has(route2)) return;

      if (flights[route1][0].flightNumber === flights[route2][0].flightNumber) {
        combinedKey = `${route1.split('-')[0]}-${route2.split('-')[1]}`;
        combinedArray = mergeUniqueObjects(combinedArray, flights[route2]);
        combinedRoutes.add(route2);
      }
    });

    result[combinedKey] = combinedArray;
    combinedRoutes.add(route1);
  });

  return result;
};

const mergeUniqueObjects = (arr1, arr2) => {
  const combined = [...arr1, ...arr2];
  const uniqueObjects = [];

  const objectMap = combined.reduce((acc, obj) => {
    const key = `${obj.flightNumber}-${obj.code}`;
    if (!acc[key]) {
      acc[key] = obj;
      uniqueObjects.push(obj);
    }
    return acc;
  }, {});

  return uniqueObjects;
};

const checkSameFlightNumber = (flights) => {
  const routeKeys = Object.keys(flights);

  if (routeKeys.length === 0) return false;

  const firstFlightNumber = flights[routeKeys[0]][0].flightNumber;

  for (let i = 1; i < routeKeys.length; i++) {
    const currentFlightNumber = flights[routeKeys[i]][0].flightNumber;
    if (currentFlightNumber !== firstFlightNumber) {
      return false;
    }
  }

  return true;
};
const getFormattedData = (rawData, type, state) => {
  const { stateKey, defaultCode } = SSR_FIELD[type];
  const data = rawData.filter(
    ({ code }) => code !== NO_MEAL || code !== NO_BAGGAGE
  );
  return data.reduce((acc, segment) => {
    const initialState = { filteredData: {}, defaultValue: {} };
    const { filteredData, defaultValue } = segment.reduce((acc, each) => {
      const { flightNumber, destination, origin } = each;
      
      const filteredDataKey = `${origin}-${destination}`;
      if (each.code === defaultCode) acc.defaultValue[flightNumber] = each;
      else {
        acc.filteredData[filteredDataKey] = [
          ...(acc.filteredData[filteredDataKey]
            ? acc.filteredData[filteredDataKey]
            : []),
          each,
        ];
      }

      return acc;
    }, initialState);

    if (!isEmpty(defaultValue)) {
      state.SSRDefaultValuesLCC = {
        ...state.SSRDefaultValuesLCC,
        [stateKey]: {
          ...state.SSRDefaultValuesLCC[stateKey],
          ...defaultValue,
        },
      };
    }
    let requiredFilteredData = [];
    if (checkSameFlightNumber(filteredData)) {
      requiredFilteredData = Object.values(combineFlights(filteredData));
    } else requiredFilteredData = Object.values(filteredData);
    if (requiredFilteredData.length) acc = [...acc, ...requiredFilteredData];
    return acc;
  }, []);
};
const getFormattedSeatData = (flights, type, state) => {
  const { stateKey, defaultCode } = SSR_FIELD[type];
  const initialState = { filteredSeats: [] };
  const { defaultValue, ...restFilteredData } = flights.reduce((acc, each) => {
    acc.flightNumber = each.seats[ZERO].flightNumber;
    if (each.seats[ZERO].code === defaultCode)
      acc.defaultValue = each.seats[ZERO];
    else acc.filteredSeats = [...acc.filteredSeats, each];

    return acc;
  }, initialState);

  if (defaultValue) {
    const flightNo = defaultValue.flightNumber;
    state.SSRDefaultValuesLCC = {
      ...state.SSRDefaultValuesLCC,
      [stateKey]: {
        ...state.SSRDefaultValuesLCC[stateKey],
        [flightNo]: defaultValue,
      },
    };
  }
  return restFilteredData;
};

const getDecks = (flights, state) => {
  let tabs = [];
  flights.forEach((flight) => {
    const segmentTabs = flight.segmentSeat.map((seatsMatrix) => {
      const { filteredSeats, flightNumber } = getFormattedSeatData(
        seatsMatrix.rowSeats,
        SEAT_DYNAMIC,
        state
      );

      return {
        flightNumber,
        rowSeats: filteredSeats.reduce((acc, { seats }) => {
          const { rowNo } = get(seats, "0");
          const deckConfiguration = {
            width: filteredSeats.length,
            startSeatRow: rowNo,
          };
          return [
            ...acc,
            {
              row: rowNo,
              deckConfiguration,
              seats: seats,
            },
          ];
        }, []),
      };
    });
    tabs = [...tabs, ...segmentTabs];
  });
  return tabs;
};

export const getFormattedSpecialServicesData = (
  specialServicesApiRes,
  state
) => {
  const getFlightData = (flights = []) =>
    flights.map((flightData) => {
      const { isLCC, resultIndex } = flightData || EMPTY_OBJECT;
      const {
        seatDynamic = [],
        mealDynamic = [],
        baggage = [],
        seatPreference = [],
        meal = [],
      } = flightData;

      return isLCC
        ? {
            isLCC,
            resultIndex,
            data: {
              seatDynamic: getDecks(seatDynamic, state),
              mealDynamic: getFormattedData(mealDynamic, MEAL_DYNAMIC, state),
              baggage: getFormattedData(baggage, BAGGAGE, state),
            },
          }
        : {
            isLCC,
            resultIndex,
            data: {
              seatPreference: seatPreference.map((each) => ({
                ...each,
                resultIndex,
              })),
              meal: meal.reduce((acc, each) => {
                if (each.code !== NO_MEAL) {
                  acc.push({ ...each, resultIndex });
                }
                return acc;
              }, []),
            },
          };
    });

  return getFlightData(specialServicesApiRes);
};
