import Profile from "./Profile";
import * as slice from "./profile.reducer";
import * as selectors from "./profile.selector";
import * as profileActions from "./profile.actions";

export const {
  addTraveler,
  getTravelers,
  deleteTraveler,
  updateTraveler,
  getRateExchanges,
  updatePassword,
  getCostCodes,
  addCostCodes,
} = profileActions;

export const {
  name,
  reducer,
  actions: {
    setTravelerInfo,
    setUpdatedTravelers,
    setCountryInfo,
    setUserInfo,
  },
} = slice;

export const {
  selectTravelerInfo,
  selectCountryInfo,
  selectUserInfo,
  selectExchangeRates,
  selectCostCodes,
} = selectors;

export default Profile;
