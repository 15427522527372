import { BookingHeadersPropTypes } from "../../../prop-types";

const FailedBookingHeader = ({ bookingStatus, bookingId, t }) => (
  <div className='relative bg-red-200 rounded-xl'>
    <div className='absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-red-900/50 rounded-xl'></div>
    <div className='container px-8 pt-12 pb-20 mx-auto relative flex flex-col items-start'>
      <h4 className='text-xl text-contrast-900 mb-1 font-bold'>
        {t(`bookingResult.statusMsg.${bookingStatus}`)}
      </h4>
      <div className='flex items-center gap-2 mb-7'>
        <p className='text-contrast-600 text-sm'>
          {t("bookingResult.referenceNumber")}
          <span className='mx-1 font-semibold text-contrast-900'>
            {bookingId}
          </span>
        </p>
        <span className='font-semibold text-xs px-3 py-1 rounded-3xl text-red-800 bg-red-100'>
          {t(`bookingResult.status.${bookingStatus}`)}
        </span>
      </div>
      <div className='w-full text-md bg-red-100 p-4 rounded-md text-red-600 my-1 font-medium'>
        {t("bookingResult.failureMsg")}
      </div>
    </div>
  </div>
);

const PartialBookingHeader = ({
  bookingStatus,
  bookingId,
  t,
}) => (
  <div className='relative print:hidden bg-yellow-200 rounded-xl'>
    <div className='absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-yellow-900/50 rounded-xl'></div>
    <div className='container px-8 pt-12 pb-20 mx-auto relative flex flex-col items-start'>
      <h4 className='text-xl text-contrast-900 mb-1 font-bold'>
        {t(`bookingResult.statusMsg.${bookingStatus}`)}
      </h4>
      <div className='flex items-center gap-2 mb-7'>
        <p className='text-contrast-600 text-sm'>
          {t("bookingResult.confirmationNumber")}
          <span className='mx-1 font-semibold text-contrast-900'>
            {bookingId}
          </span>
        </p>
        <span className='font-semibold text-xs px-3 py-1 rounded-3xl text-teal-800 bg-teal-100'>
          {t(`bookingResult.status.${bookingStatus}`)}
        </span>
      </div>
      <div className='w-full text-md bg-yellow-100 p-4 rounded-md text-yellow-800 my-1 font-medium'>
        {t("bookingResult.partialFailureMsg")}
      </div>
    </div>
  </div>
);

const SuccessBookingHeader = ({ bookingId, bookingStatus, t }) => (
  <div className='relative print:hidden bg-teal-200 rounded-xl'>
    <div className='absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-teal-900/50 rounded-xl'></div>
    <div className='container px-8 pt-12 pb-20 mx-auto relative flex flex-col items-start'>
      <h4 className='text-xl text-contrast-900 mb-1 font-bold'>
        {t(`bookingResult.statusMsg.${bookingStatus}`)}
      </h4>
      <div className='flex items-center gap-2 mb-7'>
        <p className='text-contrast-600 text-sm'>
          {t("bookingResult.confirmationNumber")}
          <span className='mx-1 font-semibold text-contrast-900'>
            {bookingId}
          </span>
        </p>
        <span className='font-semibold text-xs px-3 py-1 rounded-3xl text-teal-800 bg-teal-100'>
          {t(`bookingResult.status.${bookingStatus}`)}
        </span>
      </div>
    </div>
  </div>
);

FailedBookingHeader.propTypes = BookingHeadersPropTypes
PartialBookingHeader.popTypes = BookingHeadersPropTypes
SuccessBookingHeader.propTypes = BookingHeadersPropTypes

export { FailedBookingHeader, PartialBookingHeader, SuccessBookingHeader };
