import classNames from "classnames";
import {
  RenderSVG,
  Transactions,
  Wallet,
  WalletIcon,
  Payment,
} from "../../assets/icons";
import { get } from "lodash";
import { PAYMENT_PROVIDERS, ANALYTICS_PARAM} from "../../constants";
import CountUp from "react-countup";
import { selectUserInfo } from "../Profile";
import { useSelector } from "react-redux";
const { INTERVAL: { CUSTOM } } = ANALYTICS_PARAM;
const { WALLET } = PAYMENT_PROVIDERS;

const TransactionList = ({
  analyticsInfo,
  currencySymbol,
  referencePeriod,
}) => {
  const transactions = get(
    analyticsInfo,
    `data.${referencePeriod === CUSTOM ? 'current' : referencePeriod}`,
    [{ provider: "", amount: 0 }]
  );
  const {isWalletEnabled=false} = useSelector(selectUserInfo);
  return (
    <div className='w-full h-full'>
      <div className='flex flex-col h-full border border-contrast-300 rounded-lg bg-white'>
        <div className='p-6 flex flex-wrap'>
          <RenderSVG
            Svg={Transactions}
            alt='Transactions'
            className='none bg-primary-600 p-1 rounded-lg mr-2 my-2'
            color='white'
            width='30'
            height='30'
          />
          <div className='col-span-4 flex justify-start items-center capitalize text-[#111827] text-base font-medium font-inter'>
            Transactions
          </div>
        </div>
        <div className='flex flex-col py-4 px-6 '>
          {transactions.map((transaction, index) => {
            const isWallet = transaction.provider === WALLET;
            const SvgComponent = isWallet ? WalletIcon : Payment;
            const label = isWallet ? "Wallet" : "Payment Gateway";
            if (!isWalletEnabled && isWallet) {
              return null; 
            }
            return (
              <div key={index} className='flex justify-between py-1 flex-wrap'>
                <div className='flex items-center'>
                  <RenderSVG
                    Svg={SvgComponent}
                    alt={label}
                    className={isWallet ? "none" : "w-6 h-6"}
                  />
                  <div
                    className={classNames("text-xs md:text-sm", {
                      "ml-2": isWallet,
                      "ml-1": !isWallet,
                    })}
                  >
                    {label}
                  </div>
                </div>
                <div className='flex items-center text-xs md:text-sm'>
                  <div className='font-semibold'>{currencySymbol}&nbsp;</div>
                  <div className='font-semibold'>
                  <CountUp duration={2} className="counter" end={transaction.amount} decimals={2} useIndianSeparators={true}/>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default TransactionList;
