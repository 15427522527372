import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { Carousel } from "@material-tailwind/react";
import { v4 as uuid } from "uuid";
import { selectHotelInfo } from "../../../../screens/HotelInfo";
import Modal, { setSelectedModal } from "../../Modal";
import { Cross } from "../../../../assets/icons";
import { RenderSVG } from "../../../../assets/icons";
import { DEFAULT_VALUES, MODAL_SIZE } from "../../../../constants";
import CarouselImage from "../../../molecules/CarouselImage/CarouselImage";

const { EMPTY_STRING, EMPTY_ARRAY, ZERO } = DEFAULT_VALUES;
const { LG } = MODAL_SIZE;
const CarousalImageModal = ({ imageIndex = ZERO }) => {
  const dispatch = useDispatch();
  const hotelInfo = useSelector(selectHotelInfo);
  const hotelDetails = get(
    hotelInfo,
    "priceResult.hotelDetails",
    EMPTY_ARRAY
  );
  const { hotelName = EMPTY_STRING, images = EMPTY_ARRAY } = hotelDetails;

  const handleClose = () => dispatch(setSelectedModal(null));
  const rotateArray = (arr, index) => {
    if (index < ZERO || index >= arr.length) {
      return arr;
    }
    return [...arr.slice(index), ...arr.slice(ZERO, index)];
  };

  const orderedImages = [...rotateArray(images, imageIndex)];
  const imageCount = images.length;
  return (
    <Modal
      size={LG}
      shouldShowModalFromProps
      handleClose={handleClose}
      shouldCloseOnClickOutside
      shouldShowBorder={false}
    >
      <button onClick={handleClose} className="absolute z-50 right-0 bg-white">
        <RenderSVG Svg={Cross} className='text-contrast-900' />
      </button>
      <div className='font-[inter] overflow-auto font-medium text-xs sm:text-sm text-contrast-900 flex flex-col md:flex-row'>
        <div className='w-full'>
          <div className='bg-contrast-200 relative h-full'>
            {!isEmpty(orderedImages) && imageCount > 1 ? (
              <Carousel className='relative' navigation={() => ""}>
                {orderedImages.map((imageUrl, index) => (
                  <CarouselImage
                    imageUrl={imageUrl}
                    hotelName={hotelName}
                    imagesLength={imageCount}
                  />
                ))}
              </Carousel>
            ) : (
              <CarouselImage
                imageUrl={orderedImages[0]}
                hotelName={hotelName}
                imagesLength={imageCount}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CarousalImageModal;
