import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectActiveSpinners } from "./spinner.selectors";

const ProgressBar = ({ name, showProgressBar }) => {
  const activeSpinners = useSelector(selectActiveSpinners);
  const [shouldShowProgressBar, setShouldShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const isActive = Array.isArray(name)
      ? name.some((spinnerName) => activeSpinners.includes(spinnerName))
      : activeSpinners.includes(name);

    setShouldShowProgressBar(isActive);
  }, [name, activeSpinners]);

  useEffect(() => {
    let interval;
    if (showProgressBar || shouldShowProgressBar) {
      setProgress(1);
      interval = setInterval(() => {
        setProgress((progress) => (progress += (100 - progress) / 20));
      }, 300);
    }
    return () => {
      clearInterval(interval);
      setProgress((progress) => (progress ? 100 : 0));
      setTimeout(() => {
        setProgress(0);
      }, 300);
    };
  }, [shouldShowProgressBar, showProgressBar]);

  if (progress)
    return (
      <div className='bg-white rounded'>
        <div
          className='bg-primary-600 duration-300 h-1 rounded'
          style={{ width: `${progress}%` }}
        />
      </div>
    );
};

export default ProgressBar;
