import { TotalAnalyticsCard } from "../../components/molecules/AnalyticsCard";
import {AnalyticDataSkeleton} from '../../components/organisms/AppSkeletons/DashboardSkeleton'
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
const {ANALYTICS_SPENT_DATA} = SPINNER_NAMES

const TotalAnalytics = ({ analyticsInfo }) => {
  const { totals } = analyticsInfo;
  const totalsKeys = totals ? Object.keys(totals) : [];

  return (
    <div className={`px-6 gap-6 w-full grid grid-cols-${totalsKeys.length}`}>
      {totalsKeys.map((total) => (
        <Spinner
          key={total}
          size="w-full"
          name={ANALYTICS_SPENT_DATA}
          showSkeleton={true}
          loaderComponent={<AnalyticDataSkeleton name={total} />}
          className="inline-block"
        >
          <TotalAnalyticsCard
            name={total}
            value={totals[total]?.value}
            difference={totals[total]?.difference}
            count={totalsKeys.length}
          />
        </Spinner>
      ))}
    </div>
  );
};

export default TotalAnalytics;
