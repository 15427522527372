export const COLORS = {
  AeroBlue: "#D1FAE5",
  BlueGreen: "#064E3B",
  PapayaWhip: "#FFEDD5",
  SmokeyTopaz: "#7C2D12",
  EerieBlack: "#111827",
  DeepPeach: "#f7c7a0",
  Waterspout: "#9ff5ef",
  YankeesBlue: "#1F2937",
};
