import { isEmpty } from "lodash";
import { BOOKING_DOCUMENTS_TYPE } from "../constants";

const { PASSPORT } = BOOKING_DOCUMENTS_TYPE;
const IS_PASSPORT_MANDATORY = 'isPassportMandatory';
const IS_PAN_MANDATORY = 'isPanMandatory';

export const getHotelDocumentRequirement = (hotelRoomsDetails, documentType) => {
    if(isEmpty(hotelRoomsDetails)) return false;
    const documentCheckField = documentType === PASSPORT ? IS_PASSPORT_MANDATORY : IS_PAN_MANDATORY;
    return hotelRoomsDetails.every(hotelRoomDetails => hotelRoomDetails[documentCheckField]);
}
