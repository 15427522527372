import { DEFAULT_VALUES } from "../constants";
import { getTravelerType } from "./getTravelerType";

const { EMPTY_OBJECT } = DEFAULT_VALUES;

export const getCategorizedTravelers = (travelers, user) => {
  return travelers?.map((traveler) => {
    const travelerType = getTravelerType(traveler?.dateofbirth);

    const issueCountry =
      user?.country?.Code === traveler?.countrycode
        ? user.country
        : EMPTY_OBJECT;
    return {
      travelerType,
      profileDetails: {
        title: traveler.title,
        firstName: traveler.firstname,
        middleName: traveler.middlename,
        lastName: traveler.lastname,
        dateOfBirth: traveler.dateofbirth,
        email: traveler.email,
        phoneNumber: traveler.phonenumber,
        gender: traveler.gender,
        nationality: traveler.nationality,
      },
      passportDetails: {
        passport: traveler.passportnumber,
        issueCountry,
        issuedDate: traveler.issuancedate,
        expireDate: traveler.expirydate,
      },
      isPrimary: traveler.primary,
      travelerId: traveler.id,
    };
  });
};
