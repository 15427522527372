import AdminNavigationDrawer from "./AdminNavigationDrawer";
import AddUserDrawer from "../../molecules/MultiSelectField/AddUserDrawer";
import AddCostCodeDrawer from "../../molecules/MultiSelectField/AddCostCodeDrawer";
import SelectedTravelerInfo from "../../molecules/MultiSelectField/SelectedTravelerInfo";
import { DRAWERS } from "./drawer.constants";

const {
  SHOW_ADMIN_NAVIGATION_DRAWER,
  SHOW_ADD_USER_DRAWER,
  SHOW_ADD_COSTCODE_DRAWER,
  SHOW_SELECTED_USER_INFO_DRAWER,
} = DRAWERS;

const renderDrawer = (name) =>
  ({
    [SHOW_ADMIN_NAVIGATION_DRAWER]: <AdminNavigationDrawer />,
    [SHOW_ADD_USER_DRAWER]: <AddUserDrawer />,
    [SHOW_ADD_COSTCODE_DRAWER]: <AddCostCodeDrawer />,
    [SHOW_SELECTED_USER_INFO_DRAWER]: <SelectedTravelerInfo />,
  }[name || null]);

export default renderDrawer;
