import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { REQUEST_METHODS } from "../../constants";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";

const { PERMISSIONS, GET_ROLES_PERMISSIONS } = SPINNER_NAMES;
const { GET, POST } = REQUEST_METHODS;

export const getPermissions = createAsyncThunk(
  "get-permissions",
  async (_, thunkArgs) => {
    return await asyncAction({
      url: "permissions",
      methodType: GET,
      spinner: PERMISSIONS,
      showErrorToast: true,
      errorMessage: "Failed to fetch permissions",
      abortOnPageChange: false,
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const getTenantsRoles = createAsyncThunk(
  "get-tenant-roles",
  async (tenantId, thunkArgs) => {
    return await asyncAction({
      url: `/tenants/${tenantId}/roles`,
      methodType: GET,
      spinner: GET_ROLES_PERMISSIONS,
      showErrorToast: true,
      errorMessage: "Failed to fetch roles",
      abortOnPageChange: false,
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const updateRolesPermission = createAsyncThunk(
  "update-rolePermissions",
  async (requestBody, thunkArgs) => {
    const { queryParams, updatePermissionReq } = requestBody;
    const { roleId, tenantId } = queryParams;
    return await asyncAction({
      url: `/tenants/${tenantId}/roles/${roleId}/permissions`,
      methodType: POST,
      body: updatePermissionReq,
      spinner: `Update_${roleId}`,
      showErrorToast: true,
      errorMessage: "Failed to update roles permissions",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);
