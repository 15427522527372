import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { REQUEST_METHODS } from "../../constants";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";

const { GET, PATCH } = REQUEST_METHODS;
const { GET_POLICY, UPDATE_POLICY } = SPINNER_NAMES;

export const getPolicies = createAsyncThunk(
  "policies/get",
  async (tenantId, thunkArgs) => {
    return await asyncAction({
      url: `tenants/${tenantId}/policies`,
      methodType: GET,
      spinner: GET_POLICY,
      isAuthRequired: true,
      errorMessage:
        "Unable to fetch policies at this moment, please try again later.",
      showErrorToast: true,
      abortOnPageChange: false,
      ...thunkArgs,
    });
  }
);

export const updatePolicies = createAsyncThunk(
  "policies/update",
  async ({ tenantId, policyId, body }, thunkArgs) => {
    return await asyncAction({
      url: `tenants/${tenantId}/policies/${policyId}`,
      methodType: PATCH,
      spinner: UPDATE_POLICY,
      body,
      isAuthRequired: true,
      errorMessage:
        "Unable to update policies at this moment, please try again later.",
      showErrorToast: true,
      ...thunkArgs,
    });
  }
);
