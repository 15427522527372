import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { selectTenantDetails } from "../Auth";
import get from "lodash/get";
import PersonalInfoSection from "./PersonalInfoSection";
import { selectUserInfo } from "./index";
import { AVAILABLE_PERMISSIONS } from "../../constants";

const { CAN_UPDATE_PASSWORD } = AVAILABLE_PERMISSIONS;

const Profile = () => {
  const [canUpdatePassword, setCanUpdatePassword] = useState(false);
  const userInfo = useSelector(selectUserInfo);
  const tenantDetails = useSelector(selectTenantDetails);
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const { brandName } = tenantConfig;

  useEffect(() => {
    if (get(userInfo, "permissions", []).includes(CAN_UPDATE_PASSWORD))
      setCanUpdatePassword(true);
  }, [userInfo]);

  return (
    <div className='w-full bg-contrast-100 px-8 pt-4'>
      <Helmet>
        <title>{brandName} | Profile</title>
      </Helmet>
      <div className='w-full'>
        <PersonalInfoSection canUpdatePassword={canUpdatePassword}/>
      </div>
    </div>
  );
};

export default Profile;
