import { useSelector } from "react-redux";
import classNames from "classnames";
import { isEmpty, get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  getAirlineIconUrlByCarrierCode,
  getFormattedArrivalAndDepartureDate,
  getLayoverDuration,
  getTotalTimeDifference,
  getCityNameByIata,
} from "../../../helper";
import { selectIsPrePackagedFlights } from "../../../screens/FlightResults";
import {
  RenderSVG,
  FlightDarkIcon,
  Baggage,
  CalendarOutline,
  Seat,
} from "../../../assets/icons";
import { DEFAULT_VALUES } from "../../../constants";
import Tabs from "../../organisms/Tabs";

const { ONE, ZERO, EMPTY_STRING } = DEFAULT_VALUES;

const getOriginDestinationIataCodes = (itinerary = []) => {
  const originIata = get(
    itinerary,
    "segments.0.departure.iataCode",
    EMPTY_STRING
  );
  const lastSegment = get(itinerary, "segments", []).at(-1);
  const destinationIata = get(lastSegment, "arrival.iataCode", EMPTY_STRING);

  return { originIata, destinationIata };
};

const RenderSegments = ({ segments, layoverDuration }) => {
  const { t } = useTranslation();
  const showPackages = useSelector(selectIsPrePackagedFlights);

  return segments.map(
    (
      {
        carrierCode,
        carrierName,
        flightNumber,
        departure,
        arrival,
        cabinClass,
        aircraftCode,
        baggageInfo,
      },
      index
    ) => (
      <div
        className='w-full p-4 pb-6 bg-white rounded-lg'
        key={departure.iataCode}
      >
        <div className='grid grid-cols-12 gap-x-1'>
          <div className='items-center mb-3 col-span-2'>
            <img
              className='rounded border-contrast-300 w-12 h-12'
              src={getAirlineIconUrlByCarrierCode(carrierCode)}
              alt={`${carrierCode}_logo`}
            />
            <div className='text-sm text-contrast-900 font-semibold'>
              <div>{carrierName}</div>
              {carrierCode} {flightNumber} {aircraftCode}
            </div>
          </div>
          <div className='grid grid-cols-12 col-span-10'>
            <div className='col-span-3'>
              <div className='font-semibold'>
                {departure.time}
                &nbsp;
              </div>
              <div className='text-contrast-800 text-xs'>
                {getFormattedArrivalAndDepartureDate(departure.date)}
              </div>
              <div className='font-semibold'>
                {getCityNameByIata(departure.iataCode)}
              </div>
              {departure.terminal && (
                <div className='text-contrast-800 text-xs'>
                  {t("flightResults.journeyDetails.terminal")}&nbsp;
                  {departure.terminal}
                </div>
              )}
              {departure.airportName && (
                <div className='text-contrast-800 text-xs'>
                  {departure.airportName}
                </div>
              )}
            </div>
            <div
              className={classNames("col-span-6 text-center", {
                "md:col-span-6": !showPackages,
              })}
            >
              <div className='text-contrast-500 px-4 md:px-0'>
                {getTotalTimeDifference(
                  departure.iataCode,
                  departure.date,
                  departure.time,
                  arrival.iataCode,
                  arrival.date,
                  arrival.time
                )}
              </div>
              <div className='border-t-2 border-orange-500 my-2 w-4/5 h-6 mx-auto flex justify-center relative'>
                <RenderSVG
                  Svg={FlightDarkIcon}
                  className='rotate-90 absolute bottom-3.5'
                  alt='Flight-Icon'
                />
              </div>
            </div>
            <div className='col-span-3'>
              <div className='font-semibold'>{arrival.time}</div>
              <div className='text-contrast-800 text-xs'>
                {getFormattedArrivalAndDepartureDate(arrival.date)}
              </div>
              <div className='font-semibold'>
                {getCityNameByIata(arrival.iataCode)}
              </div>
              {arrival.terminal && (
                <div className='text-contrast-800 text-xs'>
                  {t("flightResults.journeyDetails.terminal")}&nbsp;
                  {arrival.terminal}
                </div>
              )}
              {arrival.airportName && (
                <div className='text-contrast-800 text-xs'>
                  {arrival.airportName}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='grid grid-cols-12 mt-5'>
          {baggageInfo.checkInBaggage && (
            <div className='col-span-2 text-[10px] h-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center mr-2'>
              <RenderSVG
                Svg={Baggage}
                width='14'
                height='14'
                className='text-blue-900'
                alt='reissue'
              />
              <span className='ml-1'>
                {t("flightResults.journeyDetails.baggage")}:{" "}
                {baggageInfo.checkInBaggage}
              </span>
            </div>
          )}
          {baggageInfo.cabinBaggage && (
            <div className='col-span-2 text-[10px] h-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center mr-2'>
              <RenderSVG
                Svg={CalendarOutline}
                width='14'
                height='14'
                className='text-blue-900'
                alt='reissue'
              />
              <span className='ml-1'>
                {t("flightResults.journeyDetails.checkIn")}:{" "}
                {baggageInfo.cabinBaggage}
              </span>
            </div>
          )}
          {cabinClass && (
            <div className='col-span-2 text-[10px] h-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center mr-2'>
              <RenderSVG
                Svg={Seat}
                width='14'
                height='14'
                className='text-blue-900'
                alt='reissue'
              />
              <span className='ml-1'>
                {t("flightResults.journeyDetails.cabin")}: {cabinClass}
              </span>
            </div>
          )}
        </div>
        {!isEmpty(layoverDuration) && segments[index + ONE] && (
          <div className='flex items-center mt-4'>
            <div className='flex-grow border-t-2 border-contrast-300'></div>
            <div className='rounded p-2 mx-2'>
              <div className='flex items-center'>
                <div className='font-semibold text-primary-900 text-sm'>
                  {t("flightResults.journeyDetails.changeOfPlanes")}
                </div>
                <div className='flex items-center'>
                  <div className='flex-grow'>
                    <div className='border-t-2 border-contrast-300'></div>
                  </div>
                  <div className='ml-2 text-sm'>
                    {t("flightResults.journeyDetails.layoverDuration")}:
                  </div>
                  <div className='ml-2 font-semibold text-sm'>
                    {layoverDuration[index]}
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-grow border-t-2 border-contrast-300'></div>
          </div>
        )}
      </div>
    )
  );
};

const JourneyDetails = ({ itineraries = [], segments }) => {
  const layoverDuration =
    itineraries.length > ONE
      ? itineraries.map((itinerary) => getLayoverDuration(itinerary.segments))
      : [getLayoverDuration(segments)];

  const tabs = itineraries.map((itinerary, index) => {
    const { originIata, destinationIata } =
      getOriginDestinationIataCodes(itinerary);

    return {
      id: index,
      default: index === ZERO,
      title: `${originIata}-${destinationIata}`,
      element: (
        <RenderSegments
          segments={itinerary.segments}
          layoverDuration={layoverDuration[index]}
          duration={itinerary.duration}
        />
      ),
    };
  });

  return (
    <div className='bg-contrast-50'>
      <div
        className={classNames("p-2 font-semibold border-b-[1px] bg-white", {
          "rounded py-3 font-normal text-black": itineraries.length > ONE,
        })}
      >
        {itineraries.length > ZERO && (
          <>
            {itineraries.length > ONE ? (
              <Tabs tabs={tabs} className='h-10 pt-0' />
            ) : (
              <RenderSegments
                segments={itineraries[ZERO]?.segments}
                layoverDuration={layoverDuration[ZERO]}
                duration={itineraries[ZERO]?.duration}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default JourneyDetails;
