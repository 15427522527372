import { get, isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import TravelerDetailsCard from "../../../components/organisms/Traveler/TravelerDetailsCard";
import GstDetailCard from "../../../components/organisms/Traveler/GstDetailCard";
import {
  ChevronDown,
  ChevronUp,
  Plus,
  RenderSVG,
  EditIcon,
} from "../../../assets/icons";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  TRAVELER_TYPE,
  TBO_FARE_TYPES,
  FLIGHT_SERVICE_TYPE,
  INDEX,
  MAX_TRAVELER_AGES,
  TRAVELLER_INFORMATION_SECTIONS,
} from "../../../constants";
import { selectTravelerInfo } from "../../Profile";
import { setToSessionStorage, isValidMinDateOfBirth } from "../../../helper";
import ManageBookingTraveler from "./ManageBookingTraveler";
import {
  selectSelectedTravelers,
  setSelectedTravelers,
  selectBookingTravelers,
  setBookingTravelers,
} from "./index";
import {
  selectFlightPriceInfo,
  selectIsInternationalFlight,
  selectFlightPriceReq,
} from "../../FlightResults";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import ContactInformation from "./ContactInformation";
import AddressInformation from "./AddressInformation";
import { selectCurrentUserInfo } from "../../Auth";
import { selectSearchFilters } from "../../../components/organisms/Search";

const {
  ZERO,
  EMPTY_OBJECT,
  TIMEOUT_DURATION,
  EMPTY_STRING,
  MILLISECONDS_PER_YEAR,
  EMPTY_ARRAY,
} = DEFAULT_VALUES;
const { LAST } = INDEX;
const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;
const { TRAVELERS_INFORMATION } = CACHE_KEYS;
const { REGULAR, SENIOR_CITIZEN } = TBO_FARE_TYPES;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;
const { CONTACT_INFO, ADDRESS_INFO } = TRAVELLER_INFORMATION_SECTIONS;

const MIN_AGE_FOR_SENIOR_CITIZEN = 60;

const travelerTypeKeys = {
  Adult: "adult",
  Child: "children",
  Infant: "infants",
};

const checkIsAgeValid = (traveler, startJourneyDate, lastDepartureDate) => {
  const dateOfBirth = new Date(traveler.profileDetails.dateOfBirth);
  const journeyStartDate = new Date(startJourneyDate);
  const journeyEndDate = new Date(lastDepartureDate);

  const yearsDifference =
    (journeyEndDate - dateOfBirth) / MILLISECONDS_PER_YEAR;

  const isMaxAgeInvalid =
    yearsDifference > (MAX_TRAVELER_AGES[traveler.travelerType] || 0);
  const isMinAgeInvalid = !isValidMinDateOfBirth(
    journeyStartDate,
    dateOfBirth,
    traveler.travelerType
  );

  return isMaxAgeInvalid || isMinAgeInvalid;
};
const RenderTraveler = ({
  traveler,
  handleEditTraveler,
  checkboxDisabled,
  handleTravelerSelection,
  documentsRequired,
  isReissuanceFlight,
}) => {
  const selectedFlightPriceInfo = useSelector(selectFlightPriceInfo);
  const selectedTravelers = useSelector(selectSelectedTravelers);

  const { profileDetails = {}, travelerId = EMPTY_STRING } = traveler;
  const { title, firstName, middleName, lastName } = profileDetails;
  const fareType = get(
    selectedFlightPriceInfo,
    "0.flightPriceInfo.fareType",
    REGULAR
  );
  const showFareCategoryDocuments = fareType !== REGULAR;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 100,
    defaultExpanded: false,
  });

  const isPaxPresent = Boolean(
    selectedTravelers.find((pax) => pax.travelerId === travelerId)
  );

  return (
    <div
      key={travelerId}
      className={classNames(
        "rounded-lg border flex-col justify-center items-center my-3 mx-4",
        {
          "bg-primary-100 border-primary-600": isPaxPresent,
          "bg-white border-contrast-200": !isPaxPresent,
        }
      )}
    >
      <div className='flex p-4 justify-between flex-col md:flex-row items-center gap-4 w-full min-h-[100%]'>
        <div className='flex items-center justify-center gap-2 px-0 text-contrast-900 text-lg font-normal flex-wrap'>
          <input
            className={classNames(
              "w-6 h-6 rounded-md cursor-pointer disabled:bg-contrast-300 disabled:cursor-not-allowed",
              {
                "bg-primary-600": isPaxPresent,
              }
            )}
            type='checkbox'
            value={isPaxPresent}
            checked={isPaxPresent}
            disabled={checkboxDisabled && !isPaxPresent}
            onChange={(e) =>
              handleTravelerSelection(traveler, e.target.checked)
            }
          />
          <div className='flex flex-wrap truncate'>
            {title && `${title}.`}&nbsp;
            {firstName}&nbsp;
            {middleName}&nbsp;
            {lastName}&nbsp;
          </div>
        </div>
        {!isReissuanceFlight && (
          <div className='justify-between items-center gap-4 flex'>
            <button
              onClick={() => handleEditTraveler(travelerId)}
              className='flex text-contrast-700 text-xs py-2 px-4 items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm font-medium'
            >
              Edit
            </button>
            <div
              className='p2 justify-center items-center flex'
              {...getToggleProps()}
            >
              <RenderSVG
                className='w-5 h-5 relative'
                Svg={isExpanded ? ChevronUp : ChevronDown}
                alt='chevron-up-down'
                color='bg-contrast-700'
              />
            </div>
          </div>
        )}
      </div>
      <div {...getCollapseProps()}>
        <TravelerDetailsCard
          traveler={traveler}
          shouldShowPassportDetails={documentsRequired}
          showFareCategoryDocuments={showFareCategoryDocuments}
        />
      </div>
    </div>
  );
};

const RenderAddNewTraveler = ({
  isCheckboxDisabled,
  showErrorMessage,
  openManageTravelerModal,
  paxType,
}) => {
  const { t } = useTranslation();
  return (
    <button
      className='py-2 px-4 rounded-md bg-primary-100 hover:bg-primary-200 text-primary-700 text-md leading-tight mx-4'
      onClick={() =>
        isCheckboxDisabled ? showErrorMessage() : openManageTravelerModal()
      }
    >
      <RenderSVG className='w-5 h-5 pr-1 inline' Svg={Plus} alt='plus' />
      <span>
        {t("travelerInfo.add")} {paxType}
      </span>
    </button>
  );
};

const RenderTravelerSection = ({
  label,
  values,
  documentsRequired,
  isReissuanceFlight,
  isGoingToNepal,
  validationSchema,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showManageTraveler, setShowManageTraveler] = useState(false);
  const [currentTravelerId, setCurrentTravelerId] = useState(null);
  const [showError, setShowError] = useState(false);
  const selectedTravelerDetails = useSelector(selectSelectedTravelers);
  const selectedFlightPriceInfo = useSelector(selectFlightPriceInfo);
  const [isErrorPresent, setIsErrorPresent] = useState(false);
  const isInternationalFlight = useSelector(selectIsInternationalFlight);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;
  const searchFilters = useSelector(selectSearchFilters);

  const { passengersCount = EMPTY_OBJECT } = searchFilters || EMPTY_OBJECT;
  const { provider = EMPTY_STRING, flightPriceInfo = EMPTY_OBJECT } =
    selectedFlightPriceInfo[ZERO] || EMPTY_OBJECT;
  const { fareType = REGULAR } = flightPriceInfo;

  const isInternationalAMFlight = provider === AMADEUS && isInternationalFlight;
  const isFareTypeDocumentRequired = fareType !== REGULAR;
  const travelerCountKey = travelerTypeKeys[label];
  const travelerCount = +passengersCount[travelerCountKey];

  const {
    isPanRequiredAtBook,
    isPanRequiredAtTicket,
    isPassportRequiredAtBook,
    isPassportRequiredAtTicket,
  } = documentsRequired;

  const TRAVELER_MAPPING = {
    [ADULT]: t("travelerInfo.adult"),
    [CHILD]: t("travelerInfo.child"),
    [HELD_INFANT]: t("travelerInfo.infant"),
  };

  const AGE_MAPPING = {
    [ADULT]: t("searchSection.ages.adult"),
    [CHILD]: t("searchSection.ages.children"),
    [HELD_INFANT]: t("searchSection.ages.infants"),
  };

  let selectedTravelersCount = selectedTravelerDetails.filter(
    ({ travelerType }) => travelerType === label
  ).length;

  const isSelectedTravellersMax = selectedTravelersCount > travelerCount;
  if (isSelectedTravellersMax) {
    dispatch(setSelectedTravelers(EMPTY_ARRAY));
    selectedTravelersCount = ZERO;
  }
  const isCheckboxDisabled = travelerCount <= selectedTravelersCount;

  const openManageTravelerModal = (travelerId) => {
    setShowManageTraveler(true);
    setCurrentTravelerId(travelerId);
  };

  const showErrorMessage = () => {
    setShowError(true);
    setTimeout(() => setShowError(false), TIMEOUT_DURATION);
  };

  const lastFlightSegment =
    flightPriceReq
      .slice(LAST)
      [ZERO]?.price?.itineraries?.slice(LAST)
      [ZERO]?.segments?.slice(LAST)[ZERO] || EMPTY_OBJECT;

  const firstFlightSegment = get(
    flightPriceReq,
    `[0].price.itineraries[0].segments[0]`,
    EMPTY_OBJECT
  );

  const lastDepartureDate = get(
    lastFlightSegment,
    "departure.date",
    EMPTY_STRING
  );

  const startJourneyDate = get(
    firstFlightSegment,
    "departure.date",
    EMPTY_STRING
  );

  const handleTravelerSelection = (traveler, isSelected) => {
    let updatedSelectedTravelers;
    if (isSelected) {
      const passportDetails = Object.values(traveler.passportDetails).every(
        (value) => value
      );
      const documentDetails =
        traveler.document &&
        Object.values(traveler.document).every((value) => value);

      const isAgeInvalid = checkIsAgeValid(
        traveler,
        startJourneyDate,
        lastDepartureDate
      );
      if (isAgeInvalid) setIsErrorPresent(true);

      const isPassportDetailsMissing =
        (isPassportRequiredAtBook ||
          isPassportRequiredAtTicket ||
          isInternationalAMFlight ||
          (isGoingToNepal && traveler.travelerType !== HELD_INFANT)) &&
        !passportDetails;
      const isDocumentDetailsMissing =
        isFareTypeDocumentRequired && !documentDetails;
      const isInvalidDateOfBirth =
        fareType === SENIOR_CITIZEN &&
        Math.abs(
          moment(new Date(), "YYYY-MM-DD").diff(
            traveler.profileDetails.dateOfBirth,
            "years"
          )
        ) < MIN_AGE_FOR_SENIOR_CITIZEN;
      if (
        isPassportDetailsMissing ||
        isDocumentDetailsMissing ||
        isInvalidDateOfBirth ||
        isAgeInvalid
      ) {
        openManageTravelerModal(traveler.travelerId);
        return;
      }
      const isPanCardRequired = isPanRequiredAtBook || isPanRequiredAtTicket;
      const isPassportRequired =
        isPassportRequiredAtBook || isPassportRequiredAtTicket;
      const travelerWithRequirements = {
        ...traveler,
        isPanCardRequired,
        isPassportRequired,
      };
      updatedSelectedTravelers = [
        ...selectedTravelerDetails,
        travelerWithRequirements,
      ];
    } else {
      updatedSelectedTravelers = selectedTravelerDetails.filter(
        ({ travelerId }) => travelerId !== traveler.travelerId
      );
    }
    dispatch(setSelectedTravelers(updatedSelectedTravelers));
    setToSessionStorage(TRAVELERS_INFORMATION, updatedSelectedTravelers);
  };

  if (travelerCount)
    return (
      <>
        <div key={label} className='border rounded-lg'>
          <div className='flex justify-between border-b p-2 font-semibold'>
            <div className='text-contrast-900 flex flex-row gap-2'>
              {TRAVELER_MAPPING[label]} {AGE_MAPPING[label]}
              {isCheckboxDisabled && showError && (
                <ErrorMessage
                  errorMessage={`Already ${travelerCount} ${TRAVELER_MAPPING[label]}${travelerCount > 1 ? "s" : EMPTY_STRING} selected.
                                 Please unselect to choose another one`}
                  timeoutDuration={3000}
                />
              )}
            </div>
            <div className='flex row'>
              <span className='text-contrast-900'>{`${selectedTravelersCount}/${travelerCount}`}</span>
              &nbsp;
              <span className='text-contrast-400 font-normal'>
                {t("travelerInfo.added")}
              </span>
            </div>
          </div>
          <div className='p-2'>
            {!isEmpty(values)
              ? values.map((value) => (
                  <RenderTraveler
                    key={value.travelerId}
                    traveler={value}
                    handleEditTraveler={openManageTravelerModal}
                    handleTravelerSelection={handleTravelerSelection}
                    checkboxDisabled={isCheckboxDisabled}
                    documentsRequired={
                      isPassportRequiredAtBook || isPassportRequiredAtTicket
                    }
                    isReissuanceFlight={isReissuanceFlight}
                  />
                ))
              : !isReissuanceFlight && (
                  <div className='p-4 flex justify-center content-center'>
                    <RenderAddNewTraveler
                      isCheckboxDisabled={isCheckboxDisabled}
                      showErrorMessage={showErrorMessage}
                      openManageTravelerModal={openManageTravelerModal}
                      paxType={TRAVELER_MAPPING[label]}
                    />
                  </div>
                )}
            {!isEmpty(values) && !isReissuanceFlight && (
              <div>
                <RenderAddNewTraveler
                  isCheckboxDisabled={isCheckboxDisabled}
                  showErrorMessage={showErrorMessage}
                  openManageTravelerModal={openManageTravelerModal}
                  paxType={TRAVELER_MAPPING[label]}
                />
              </div>
            )}
          </div>
        </div>
        {showManageTraveler && (
          <ManageBookingTraveler
            travelerType={label}
            showManageTraveler={showManageTraveler}
            setShowManageTraveler={setShowManageTraveler}
            travelerId={currentTravelerId}
            documentsRequired={documentsRequired}
            dateErrorMessage={isErrorPresent}
            setDateErrorMessage={setIsErrorPresent}
            journeyStartDate={startJourneyDate}
            returnDate={lastDepartureDate}
            isGoingToNepal={isGoingToNepal}
            validationSchema={validationSchema}
            travelerCount={travelerCount}
          />
        )}
      </>
    );
};

const BookingTravelers = ({
  selectionProgress,
  setSelectionProgress,
  contactDefaultValues,
  addressDefaultValues,
  gstFormRef,
  addressFormRef,
  contactFormRef,
  isReissuanceFlight,
  isGoingToNepal,
  travelersSortTravelerType,
  travelerValidationSchema = {},
  highlightErrorBorder = false,
  bookingTravellerErrorSections = {},
}) => {
  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, setExpanded, isExpanded } =
    useCollapse({
      duration: 400,
      defaultExpanded: false,
    });
  const { t } = useTranslation();
  const travelersSectionRef = useRef();
  const contactSectionRef = useRef();
  const addressSectionRef = useRef();
  const selectedTravelerInfo = useSelector(selectTravelerInfo);
  const selectedBookingTraveler = useSelector(selectBookingTravelers);
  const selectedFlightPriceInfo = useSelector(selectFlightPriceInfo);
  const selectedCurrentUserInfo = useSelector(selectCurrentUserInfo);
  const searchFilters = useSelector(selectSearchFilters);
  const selectedTravelerDetails = useSelector(selectSelectedTravelers);

  const [bookingRequirements, setBookingRequirements] = useState(EMPTY_OBJECT);

  const { GSTAllowed } = bookingRequirements;
 
  useEffect(() => {
    const profileTravelers = !isEmpty(selectedTravelerInfo)
      ? selectedTravelerInfo
      : selectedBookingTraveler;

    dispatch(setBookingTravelers(profileTravelers));
  }, [dispatch, selectedCurrentUserInfo, selectedTravelerInfo]);

  useEffect(() => {
    if (highlightErrorBorder) {
      const sectionRef = getErrorSectionRef(bookingTravellerErrorSections);
      sectionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [highlightErrorBorder, bookingTravellerErrorSections]);

  const getErrorSectionRef = (errorSection) => {
    return !isEmpty(errorSection)
      ? {
          [CONTACT_INFO]: contactSectionRef,
          [ADDRESS_INFO]: addressSectionRef,
        }[Object.keys(errorSection)[0]]
      : travelersSectionRef;
  };

  useEffect(() => {
    const bookingRequirements = get(
      selectedFlightPriceInfo,
      "0.flightPriceInfo.documentsRequired",
      EMPTY_OBJECT
    );
    setBookingRequirements(bookingRequirements);
  }, [selectedFlightPriceInfo]);

  useEffect(() => {
    setExpanded(!selectionProgress?.travelerSelection);
  }, [selectionProgress?.travelerSelection]);

  return (
    <div className='w-full'>
      <fieldset disabled={isReissuanceFlight}>
        <div
          className={classNames(
            "text-contrast-900 text-lg font-bold p-6 border bg-white border-contrast-200 flex justify-between",
            {
              "rounded-t-lg": isExpanded,
            },
            {
              "rounded-lg": !isExpanded,
            }
          )}
        >
          <span>{t("travelerInfo.title")}</span>
          {selectionProgress?.travelerSelection && (
            <button
              onClick={() =>
                setSelectionProgress({
                  ...selectionProgress,
                  travelerSelection: false,
                })
              }
            >
              <RenderSVG
                Svg={EditIcon}
                className='w-6 h-6'
                alt='Toggle dropdown'
                {...getToggleProps()}
              />
            </button>
          )}
        </div>
        <div
          className='col-span-12 lg:col-span-7 bg-white border border-contrast-200 w-full rounded-b-lg'
          {...getCollapseProps()}
        >
          <div
            className={classNames("p-4 flex flex-col gap-3", {
              "border-2 error-animate bg-red-100/20 m-1":
                highlightErrorBorder && isEmpty(bookingTravellerErrorSections),
            })}
            ref={travelersSectionRef}
          >
            {Object.entries(travelersSortTravelerType).map(([key, values]) => (
              <RenderTravelerSection
                key={key}
                label={key}
                values={values}
                documentsRequired={bookingRequirements}
                isReissuanceFlight={isReissuanceFlight}
                isGoingToNepal={isGoingToNepal}
                validationSchema={travelerValidationSchema[key]}
              />
            ))}
          </div>
          {GSTAllowed && (
            <div className='border-t border-contrast-200'>
              <GstDetailCard
                documentsRequired={bookingRequirements}
                gstFormRef={gstFormRef}
              />
            </div>
          )}
          <div
            className={classNames("pt-1 border-t border-contrast-200", {
              "border-2 error-animate bg-red-100/20 m-1":
                bookingTravellerErrorSections[CONTACT_INFO],
            })}
            ref={contactSectionRef}
          >
            <ContactInformation
              defaultValues={contactDefaultValues}
              contactFormRef={contactFormRef}
            />
          </div>
          <div
            className={classNames("my-2 border-t border-contrast-200", {
              "border-2 error-animate bg-red-100/20 m-1":
                bookingTravellerErrorSections[ADDRESS_INFO],
            })}
            ref={addressSectionRef}
          >
            <AddressInformation
              defaultValues={addressDefaultValues}
              addressFormRef={addressFormRef}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default BookingTravelers;
