import reducer from "./profile.reducer";

export const selectSlice = (state) => state[reducer.name];

export const selectTravelerInfo = (state) => selectSlice(state).travelerInfo;

export const selectCountryInfo = (state) => selectSlice(state).countryInfo;

export const selectUserInfo = (state) => selectSlice(state).userInfo;

export const selectExchangeRates = (state) => selectSlice(state).exchangeRates;

export const selectCostCodes = (state) => selectSlice(state).costCodes
