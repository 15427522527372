import CostCodes from "./CostCodes";
import * as costActions from "./cost.actions"
import * as slice from "./cost.reducer";
import * as selectors from "./cost.selector";

export const {
  fetchCostCodes,
  createCostCode,
  getCostCodeById,
  updateCostCode,
  deleteCostCode
} = costActions

export const {
  selectSelectedTest,
} = selectors

export const {
  name,
  reducer,
  actions: {
    setcostCodes
  },
} = slice;

export default CostCodes;
