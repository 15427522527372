import { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce, get } from "lodash";
import classNames from "classnames";
import {
  deleteSearchTimeLog,
  deleteShowTimer,
  deleteShowTimerBanner,
  selectSelectedTrip,
  selectSelectedTripId,
  setSelectedTripDetail,
  setShowTimerExpireBanner,
} from "../../../screens/Booking/Trips";
import { MODALS } from "../AppModals";
import { setSelectedModal } from "../Modal";
import {
  CLICKABLE_NAV_ROUTE,
  NAV_ROUTES_NAME,
  ROUTES,
  DEFAULT_VALUES,
  BOOKING_STATUS,
  CACHE_KEYS,
  ANALYTICS_PARAM,
} from "../../../constants";
import {
  InfoIcon,
  PlusIcon,
  RenderSVG,
  RightArrow,
  UserCircle,
  ChevronDown,
  Menu,
} from "../../../assets/icons";
import {
  selectCurrentUserInfo,
  selectIsSidePanelCollapsed,
  selectTenantDetails,
} from "../../../screens/Auth";
import { selectHotelInfoReqBody } from "../../../screens/HotelInfo";
import { selectActiveSpinners, SPINNER_NAMES } from "../Spinner";
import {
  getFromLocalStorage,
  getNextLogWithMinimumTimeLeft,
} from "../../../helper";
import { selectTripStatus } from "../../../screens/Booking/FlightBookings";
import PaymentButton from "./PaymentButton";

const { TIMELOG, SHOW_TIMER } = CACHE_KEYS;
const { BOOKINGS, HOME, WALLET_TRANSACTIONS, DASHBOARD, CURRENT_USER_BOOKINGS, ACCOUNT, POLICIES, PROFILE, USERS, COST_CODES, TRANSACTIONS } = ROUTES;
const { CREATE_TRIP_MODAL, PAYMENT_METHODS_MODAL } = MODALS;
const { PROCESS_PAYMENT, BOOK_CALLBACK, FETCH_TRIPS } = SPINNER_NAMES;
const { ZERO, ONE, TWO, SIXTY, THOUSAND, INFINITE } = DEFAULT_VALUES;
const { CONFIRMED, CANCELLED, IN_REVIEW, DRAFT } = BOOKING_STATUS;
const BOOKINGS_NAV_TITLE = "Bookings";
const HOTEL = "hotel";
const FLIGHT = "flight";
const FIVE = "05";
const DOUBLE_ZERO = "00";
const bookingPathPattern = /^(\/users\/[^\/]+\/bookings\/[^\/]+|\/bookings\/[^\/]+)$/;
const statusConfig = {
  CONFIRMED: {
    bgColor: "bg-green-100",
    textColor: "text-green-800",
    label: "Confirmed",
  },
  IN_REVIEW: {
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-800",
    label: "Partially Confirmed",
  },
  DRAFT: {
    bgColor: "bg-gray-600",
    textColor: "text-white",
    label: "Draft",
  },
  FAILED: {
    bgColor: "bg-red-100",
    textColor: "text-red-800",
    label: "Failed",
  },
  CANCELLED: {
    bgColor: "bg-red-100",
    textColor: "text-red-600",
    label: "Cancelled",
  },
  PARTIALLY_CONFIRMED: {
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-800",
    label: "Partially Confirmed",
  },
};
const {
  INTERVAL: { CUSTOM, MONTH, YEAR, QUARTER },
  REFERENCE_PERIOD: { CURRENT, LAST },
} = ANALYTICS_PARAM;

const NavBar = ({ isSidePanelOpen, setIsSidePanelOpen }) => {
  const location = useLocation();
  const { userId: currentUserId } = useParams();
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { service } = useParams();
  const isCollapsed = useSelector(selectIsSidePanelCollapsed);
  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedTripId = useSelector(selectSelectedTripId);
  const selectedHotelInfoReqBody = useSelector(selectHotelInfoReqBody);
  const tenantDetails = useSelector(selectTenantDetails);
  const activeSpinners = useSelector(selectActiveSpinners);
  const tripStatus = useSelector(selectTripStatus);

  const tenantConfig = get(tenantDetails, "tenantConfig");
  const { logo = "", primaryColor } = tenantConfig;
  const { hotelCode } = selectedHotelInfoReqBody;
  const BOOKING_INFO = `/bookings/${selectedTripId}`;
  const MY_TRIPS_BOOKING_INFO = `/users/${currentUserId}/bookings/${selectedTripId}`;
  const FLIGHT_RESULTS = `/bookings/${selectedTripId}/flights`;
  const HOTEL_RESULTS = `/bookings/${selectedTripId}/hotels`;
  const HOTEL_INFO = `/bookings/${selectedTripId}/hotels/${hotelCode}`;
  const HOTEL_PAYMENT = `/bookings/${selectedTripId}/hotels/${hotelCode}/price`;
  const FLIGHT_PAYMENT = `/bookings/${selectedTripId}/flights/price`;
  const TRIP_CONFIRMATION = `/bookings/${selectedTripId}/payment/confirm`;
  const MY_TRIPS_FLIGHT_RESULTS = `/users/${currentUserId}/bookings/${selectedTripId}/flights`;
  const MY_TRIPS_HOTEL_RESULTS = `/users/${currentUserId}/bookings/${selectedTripId}/hotels`;
  const MY_TRIPS_HOTEL_INFO = `/users/${currentUserId}/bookings/${selectedTripId}/hotels/${hotelCode}`;
  const MY_TRIPS_HOTEL_PAYMENT = `/users/${currentUserId}/bookings/${selectedTripId}/hotels/${hotelCode}/price`;
  const MY_TRIPS_FLIGHT_PAYMENT = `/users/${currentUserId}/bookings/${selectedTripId}/flights/price`;
  const MY_TRIPS_TRIP_CONFIRMATION = `/users/${currentUserId}/bookings/${selectedTripId}/payment/confirm`;
  const MY_TRIPS_HOTEL_CANCELLATION = `/users/${currentUserId}/bookings/${selectedTripId}/preview/hotel/cancellation`;
  const MY_TRIPS_FLIGHT_CANCELLATION = `/users/${currentUserId}/bookings/${selectedTripId}/preview/flight/cancel`;
  const FLIGHT_CANCELLATION = `/bookings/${selectedTripId}/preview/flight/cancel`;
  const HOTEL_CANCELLATION = `/bookings/${selectedTripId}/preview/hotel/cancellation`;
  const PREVIEW = `/bookings/${selectedTripId}/preview/${service}`;
  const MY_TRIPS_PREVIEW_INVOICE = `/users/${currentUserId}/bookings/${selectedTripId}/preview/invoice`;
  const INVOICE = `/bookings/${selectedTripId}/preview/invoice`;
  const [timer, setTimer] = useState({
    minutes: DOUBLE_ZERO,
    seconds: DOUBLE_ZERO,
  });
  const { REACT_APP_BOOKING_TIMER = INFINITE } = process.env;
  const { isAISearchInitiated } = tripStatus;

  const shouldDisablePaymentButton =
    activeSpinners.includes(BOOK_CALLBACK) ||
    activeSpinners.includes(PROCESS_PAYMENT) ||
    (REACT_APP_BOOKING_TIMER !== INFINITE &&
      timer.minutes === DOUBLE_ZERO &&
      timer.seconds === DOUBLE_ZERO);
  const PATHS = [BOOKING_INFO, MY_TRIPS_BOOKING_INFO];
  const [shouldShowTimer, setShouldShowTimer] = useState();
  const showTimer = getFromLocalStorage(SHOW_TIMER);
  const timeLog = getFromLocalStorage(TIMELOG);
  const filterById =
    (timeLog && timeLog?.find((log) => log.id === selectedTripId)) || {};
  const existTimer = showTimer?.filter(
    (filterTime) => filterTime.id === selectedTripId
  );
  const showTime = get(existTimer, "[0].showTime");

  const tripTimerLogs = filterById.logs || [];

  const getUpdateExpiredState = (tripTimerLogs) => {
    const updateTimerBanner = {
      flight: "",
      hotel: "",
    };
    tripTimerLogs?.forEach((log) => {
      const timeLeft =
        log.startTime + Number(REACT_APP_BOOKING_TIMER) - Date.now();
      const isExpired = timeLeft <= ZERO;
      switch (log.type) {
        case FLIGHT:
          updateTimerBanner.flight = isExpired;
          break;
        case HOTEL:
          updateTimerBanner.hotel = isExpired;
          break;
      }
    });
    dispatch(
      setShowTimerExpireBanner({
        id: selectedTripId,
        flight: updateTimerBanner.flight,
        hotel: updateTimerBanner.hotel,
      })
    );
  };

  useEffect(() => {
    let timerInterval;
    if (REACT_APP_BOOKING_TIMER !== INFINITE) {
      const startNextTimer = (logIndex) => {
        if (logIndex === -1) {
          return;
        }

        const log = tripTimerLogs[logIndex];
        const targetTime = log.startTime;

        timerInterval = setInterval(() => {
          const timeRemaining =
            targetTime + Number(REACT_APP_BOOKING_TIMER) - Date.now();
          if (timeRemaining > ZERO) {
            const minutes = String(
              Math.floor((timeRemaining / THOUSAND / SIXTY) % SIXTY)
            ).padStart(TWO, ZERO);
            const seconds = String(
              Math.floor((timeRemaining / THOUSAND) % SIXTY)
            ).padStart(TWO, ZERO);
            setTimer({ minutes, seconds });
          } else {
            clearInterval(timerInterval);
            setTimer({ minutes: DOUBLE_ZERO, seconds: DOUBLE_ZERO });
            getUpdateExpiredState();
            const nextLogIndex = getNextLogWithMinimumTimeLeft(tripTimerLogs);
            startNextTimer(nextLogIndex);
          }
        }, 1000);
      };

      if (tripTimerLogs.length > ZERO) {
        getUpdateExpiredState(tripTimerLogs);
        const initialLogIndex = getNextLogWithMinimumTimeLeft(tripTimerLogs);
        startNextTimer(initialLogIndex);
      }
    }
    return () => clearInterval(timerInterval);
  }, [tripTimerLogs]);

  useEffect(() => {
    setShouldShowTimer(PATHS.includes(window.location.pathname));
  }, [shouldShowTimer, window.location.pathname]);

  const toggleSidePanel = () => {
    setIsSidePanelOpen((prev) => !prev);
  };

  const resultsPaths = [
    FLIGHT_RESULTS,
    HOTEL_RESULTS,
    HOTEL_INFO,
    HOTEL_PAYMENT,
    FLIGHT_PAYMENT,
    HOTEL_CANCELLATION,
    MY_TRIPS_FLIGHT_RESULTS,
    MY_TRIPS_HOTEL_RESULTS,
    MY_TRIPS_HOTEL_INFO,
    MY_TRIPS_HOTEL_PAYMENT,
    MY_TRIPS_FLIGHT_PAYMENT,
    MY_TRIPS_HOTEL_CANCELLATION,
  ];
  const isResultsPage = resultsPaths.includes(location.pathname);
  const isConfirmationPage = location.pathname === TRIP_CONFIRMATION || location.pathname === MY_TRIPS_TRIP_CONFIRMATION;
  const isResultOrConfirmationPage = isResultsPage || isConfirmationPage;
  const buttonLabel = isResultsPage ? "Back" : "Home";
  const navigatePath = isAISearchInitiated ? BOOKING_INFO : (isResultsPage ? -1 : HOME);

  const formatName = (name = '') => {
    const maxLength = 8; 
    return name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
  };
  
  const generateBreadcrumbs = (pathname, isClickable = false) => {
    const pathParts = pathname.split("/").filter((part) => part);
    let pathAccumulator = "";
    const routeLength = pathParts.length;
    return pathParts.map((part, index) => {
     let modifiedPart = currentUserId === part ? formatName(currentUserInfo?.firstName) : part
      pathAccumulator += `/${part}`;
      const route = `/${pathParts.slice(0, index + 1).join("/")}`;
      const isLastPart = index === routeLength - 1;
      return {
        path: pathAccumulator,
        name: NAV_ROUTES_NAME[route] || modifiedPart,
        clickable:
          isClickable && !isLastPart
            ? true
            : [
                ...CLICKABLE_NAV_ROUTE,
                `/users/${currentUserId}/bookings`,
              ].includes(route) ||
              route.startsWith(`/users/${currentUserId}/bookings/`),
      };
    });
  };

  const addBreadcrumbs = (pathname) => {
    const pathPrefix = location.pathname.startsWith(`/users/${currentUserInfo?.id}/bookings`)
      ? `/users/${currentUserInfo?.id}/bookings`
      : "/bookings";
    switch (pathname) {
      case BOOKINGS:
        return [
          {
            path: BOOKINGS,
            name: BOOKINGS_NAV_TITLE,
            clickable: true,
          },
        ];
      case FLIGHT_RESULTS:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/flights`, true);
      case HOTEL_RESULTS:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/hotels`, true);
      case HOTEL_INFO:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/hotel`, true);
      case HOTEL_PAYMENT:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/hotel/price`, true);
      case FLIGHT_PAYMENT:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/flights/price`, true);
      case FLIGHT_CANCELLATION:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/preview/flight/cancel`, true);
      case HOTEL_CANCELLATION:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/preview/hotel/cancellation`, true);
      case WALLET_TRANSACTIONS:
        return generateBreadcrumbs(`/account/wallet/transactions`, true);
      case PREVIEW:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/preview/${service}`, true);
      case MY_TRIPS_PREVIEW_INVOICE:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/preview`, false);
      case INVOICE:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/preview`, true);
      case  MY_TRIPS_TRIP_CONFIRMATION:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/confirm`, false); 
      case MY_TRIPS_FLIGHT_CANCELLATION:
        return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/preview/flight/cancel`,true);
      case MY_TRIPS_HOTEL_CANCELLATION:
         return generateBreadcrumbs(`${pathPrefix}/${selectedTripId}/preview/hotel/cancellation`,true);
      default:
        return generateBreadcrumbs(pathname);
    }
  };
  
  const selectedTripStatus = selectedTrip?.status;

  useEffect(() => {
    if (selectedTripStatus && selectedTripStatus !== DRAFT) {
      dispatch(deleteSearchTimeLog(selectedTripId));
      dispatch(deleteShowTimer(selectedTripId));
      dispatch(deleteShowTimerBanner(selectedTripId));
    }
  }, [dispatch, selectedTripStatus]);

  const shouldShowPaymentandMenuOption = () => {
    const flightBooking = get(selectedTrip, "flights.0");
    const hotelBooking = get(selectedTrip, "hotels.0");
    const bookingStatus = get(selectedTrip, "status", CANCELLED);
    return (flightBooking || hotelBooking) && bookingStatus === DRAFT;
  };

  const menuOptions = [
    {
      id: 1,
      label: "Edit",
      // TODO: Manage Trip modal will open when update trip API will be finalized by backend.
      // onClick: () => dispatch(setSelectedModal(MANAGE_TRIP_MODAL)),
    },
    {
      id: 2,
      label: "Delete",
      onClick: () => {},
    },
  ];
  const breadcrumbs = addBreadcrumbs(location.pathname);
  
  const paymentTab = bookingPathPattern.test(window.location.pathname);
  const ICON_WRAPPER =
    "w-[38px] h-[38px] p-[9px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center gap-2 inline-flex";

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setSearchInput("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const resolvePath = (path) => {
    const pathPrefix = location.pathname.startsWith(`/users/${currentUserInfo?.id}/bookings`)
      ? `/users/${currentUserInfo?.id}/bookings`
      : "/bookings";
      const containsFlight = location.pathname?.endsWith(`/flight/cancel`);
      const containsHotel = location.pathname?.endsWith(`/hotel/cancellation`);
      let services = service;
if(!service){
  if(containsFlight)  services= 'flight';
  if(containsHotel)  services= 'hotel';
}

    const pathMappings = {
      [`${pathPrefix}/${selectedTripId}/preview`]: `${pathPrefix}/${selectedTripId}/preview/${services}`,
      [`${pathPrefix}/${selectedTripId}/${services}`]: `${pathPrefix}/${selectedTripId}/preview/${services}`,
      [`${pathPrefix}/${selectedTripId}/hotel`]: `${pathPrefix}/${selectedTripId}/hotels/${hotelCode}`,
      "/account/wallet": "/account",
    };
  
    return pathMappings[path] || path;
  };  

  return (
    <nav
      className={classNames(
        "p-4 border-contrast-200 z-50 top-0 md:h-[60px] flex items-center bg-white border-b w-full justify-between print:hidden sticky"
      )}
    >
      <div className='text-sm md:text-xl font-semibold leading-7 flex items-center gap-1 flex-wrap capitalize ml-4'>
        {breadcrumbs.map((breadcrumb, index) => (
          <div
            key={index}
            className={classNames("flex flex-wrap", {
              "text-contrast-900": index === breadcrumbs.length - ONE,
              "text-contrast-600": index !== breadcrumbs.length - ONE,
            })}
          >
            {breadcrumb.clickable && index < breadcrumbs.length - ONE ? (
              <Link
                className='cursor-pointer'
                to={resolvePath(breadcrumb.path)}
              >
                {breadcrumb.name}
              </Link>
            ) : (
              <div>{breadcrumb.name}</div>
            )}
            {index < breadcrumbs.length - ONE && (
              <RenderSVG
                Svg={RightArrow}
                className='min-w-[20px] w-7 h-7 text-primary-600'
                alt='right-arrow-icon'
              />
            )}
          </div>
        ))}
      </div>

      <div className='pl-4 items-center gap-2 flex ml-auto'>
        {(window.location.pathname === BOOKINGS || window.location.pathname === `/users/${currentUserId}/bookings` || window.location.pathname === DASHBOARD || window.location.pathname === USERS || window.location.pathname === PROFILE || window.location.pathname === POLICIES || window.location.pathname === ACCOUNT || window.location.pathname === COST_CODES || window.location.pathname === TRANSACTIONS) && (
          <div className=' pl-4 border-l border-contrast-100'>
            <div
              className='pl-[15px] pr-[17px] py-[9px] bg-primary-600 rounded-md shadow justify-center items-center gap-2 flex cursor-pointer'
              onClick={() => {
                dispatch(setSelectedTripDetail({}));
                dispatch(setSelectedModal(CREATE_TRIP_MODAL));
              }}
            >
              <div className='w-5 h-5 relative'>
                <RenderSVG
                  Svg={PlusIcon}
                  className='min-w-[20px]'
                  alt='right-arrow-icon'
                />
              </div>
              <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                New Trip
              </div>
            </div>
          </div>
        )}
        {isResultOrConfirmationPage && (
          <button
            className='px-4 py-2 sm:py-1 mr-2 bg-primary-600 text-white rounded-md shadow hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500'
            onClick={() => navigate(navigatePath)}
          >
            {buttonLabel}
          </button>
        )}
        {showTime &&
          shouldShowTimer &&
          REACT_APP_BOOKING_TIMER !== INFINITE && (
            <TimerDisplay timer={timer} />
          )}
        {paymentTab && (
          <>
            {!activeSpinners.includes(FETCH_TRIPS) && (
              <StatusDisplay status={selectedTripStatus} />
            )}
            {shouldShowPaymentandMenuOption() && (
              <PaymentButton shouldDisable={shouldDisablePaymentButton} />
            )}
          </>
        )}
        {!isSidePanelOpen && (
          <button
            onClick={() => toggleSidePanel(setIsSidePanelOpen)}
            className='block md:hidden'
          >
            <RenderSVG
              Svg={Menu}
              width='32'
              height='32'
              alt='Menu icon'
              stroke={primaryColor}
            />
          </button>
        )}
      </div>
    </nav>
  );
};

const TimerDisplay = ({ timer }) => {
  return (
    <div className=' text-sm px-3 py-0.5 rounded-full text-white flex justify-center '>
      <div
        className={classNames(
          "flex flex-col items-center justify-center w-fit mx-1 rounded p-0.5",
          {
            "bg-red-900": timer.minutes < FIVE,
            "bg-green-900": timer.minutes >= FIVE,
          }
        )}
      >
        <div className='text-2xl font-bold animate-pulse'>{timer.minutes}</div>
      </div>
      <div
        className={classNames(
          "flex flex-col items-center justify-center w-fit mx-1 rounded p-0.5",
          {
            "bg-red-900": timer.minutes < FIVE,
            "bg-green-900": timer.minutes >= FIVE,
          }
        )}
      >
        <div className='text-2xl font-bold animate-pulse'>{timer.seconds}</div>
      </div>
    </div>
  );
};

const StatusDisplay = ({ status }) => {
  const config = statusConfig[status];
  return config ? (
    <div
      className={`${config.bgColor} h-6 text-sm ${config.textColor} px-3 py-0.5 rounded-full`}
    >
      {config.label}
    </div>
  ) : null;
};

export default NavBar;
