import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FlightFilters from "../../../../screens/FlightResults/FlightFilters";
import { selectCurrentFlightType } from "../../../../screens/FlightResults";
import { Cross, Filter, RenderSVG } from "../../../../assets/icons";
import { useTranslation } from "react-i18next";
import { DEFAULT_VALUES } from "../../../../constants";
import { SPINNER_NAMES, selectActiveSpinners } from "../../Spinner";

const { ZERO } = DEFAULT_VALUES;
const { FETCH_FLIGHTS_RESULTS } = SPINNER_NAMES;

const FiltersModal = ({ resultType, filtersCount }) => {
  const currentFlightType = useSelector(selectCurrentFlightType);
  const activeSpinners = useSelector(selectActiveSpinners);
  const ref = useRef();
  const { t } = useTranslation();
  const [shouldShowFilters, setShouldShowFilters] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShouldShowFilters(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [shouldShowFilters]);

  const isSearchInProgress = activeSpinners.some(
    (spinner) => spinner === FETCH_FLIGHTS_RESULTS
  );

  return (
    <div ref={ref}>
      <div className='flex gap-2 bg-primary-100'>
        <button
          className='text-primary-600 text-sm font-medium text-center leading-tight cursor-pointer  pl-[15px] pr-[17px] py-[9px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center gap-2 flex relative disabled:cursor-not-allowed'
          onClick={() => setShouldShowFilters(!shouldShowFilters)}
          disabled={isSearchInProgress}
        >
          <RenderSVG Svg={Filter} alt='Filter Icon' />
          <span>{`${t("hotelResults.filter")} (${filtersCount || ZERO})`}</span>
        </button>
      </div>
      {shouldShowFilters && (
        <div className='w-80 bg-white rounded-2xl absolute top-12 right-0 shadow-xl border-r border-contrast-200 flex-col justify-start items-start inline-flex z-99999 overflow-hidden'>
          <div className='px-6 py-4 flex items-center gap-2 border-b border-contrast-200 w-full'>
            <h4 className='flex-1 text-2xl font-bold text-contrast-900'>
              {t("hotelResults.filter")}
            </h4>
            <span
              type='button'
              className='cursor-pointer'
              onClick={() => setShouldShowFilters(false)}
            >
              <RenderSVG Svg={Cross} alt='Cross Icon' />
            </span>
          </div>
          <div className='filter-body max-h-[550px] overflow-auto divide-y divide-contrast-200 w-full'>
            <FlightFilters
              currentFlightType={currentFlightType}
              resultType={resultType}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FiltersModal;
