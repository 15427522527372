import { POLICY_SERVICE } from "../constants";

const { FLIGHTS } = POLICY_SERVICE;

const getPreferredAirlinesCode = (policies) => {
  const flightsPolicy = policies.find((policy) => policy.service === FLIGHTS );

  if (flightsPolicy && flightsPolicy.policy.airlines.allowed.isEnabled) {
    return flightsPolicy.policy.airlines.allowed.data.map(
      (airline) => airline.code
    );
  }

  return [];
};

export default getPreferredAirlinesCode;
