import { useSelector } from "react-redux";
import { get } from "lodash";
import { generateColorVariables } from "./helper/generateColorVariables";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { selectTenantDetails } from "./screens/Auth";
import { DEFAULT_COLORS, COLOR_TYPE, DEFAULT_VALUES } from "./constants";

const { EMPTY_STRING } = DEFAULT_VALUES;
const { CONTRAST, SECONDARY, PRIMARY } = COLOR_TYPE;
const { primaryHexCode, contrastHexCode, secondaryHexCode } = DEFAULT_COLORS;

const TenantThemeProvider = ({ children }) => {
  const tenantDetails = useSelector(selectTenantDetails);
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const { primaryColor, contrast, secondaryColor } = tenantConfig;
  const isDefaultTheme =
    primaryColor.toLowerCase() === primaryHexCode &&
    secondaryColor.toLowerCase() === secondaryHexCode &&
    contrast.toLowerCase() === contrastHexCode;
  const contrastColorVariables = generateColorVariables(
    contrast,
    CONTRAST,
    isDefaultTheme
  );
  const secondaryColorVariables = generateColorVariables(
    secondaryColor,
    SECONDARY,
    isDefaultTheme
  );
  const primaryColorVariables = generateColorVariables(
    primaryColor,
    PRIMARY,
    isDefaultTheme
  );
  const getCSSVariables = () => {
    if (
      primaryColorVariables &&
      contrastColorVariables &&
      secondaryColorVariables
    )
      return primaryColorVariables
        .concat(contrastColorVariables)
        .concat(secondaryColorVariables)
        .join(EMPTY_STRING);
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <style type='text/css'>{`:root {${getCSSVariables()} }`}</style>
        </Helmet>
      </HelmetProvider>
      {children}
    </>
  );
};

export default TenantThemeProvider;
