import moment from "moment";
import { DEFAULT_VALUES } from "../constants";

const { ZERO, ONE, EMPTY_STRING, TWO, TEN, EMPTY_OBJECT } = DEFAULT_VALUES;

const ZERO_STRING = "0";

const formatHoursMinutes = (time) => (time < 10 ? ZERO_STRING + time : time);

export const getCurrentDateTime = () => {
  const date = new Date();
  return date.toISOString().split("T")[ZERO];
};

export const formatDepartureArrivalTimings = (timing) => {
  const time = timing ? new Date(timing) : EMPTY_STRING;
  const hours = time ? time.getHours() : EMPTY_STRING;
  const minutes = time ? formatHoursMinutes(time.getMinutes()) : EMPTY_STRING;
  return `${hours}:${minutes}`;
};

export const getFormattedDateTime = (date) => {
  const dateTime = new Date(date);
  const dateString = dateTime.toISOString().split("T")[ZERO];
  const timeString = dateTime.toISOString().split("T")[ONE].split(".")[ZERO];

  return [dateString, timeString];
};

export const getFormattedTime = (date) => {
  const dateTime = new Date(date);
  const hours = dateTime.getHours().toString().padStart(2, "0");
  const minutes = dateTime.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const getFormattedLongDate = (date, options) => {
  if (date) {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString(undefined, options);
  } else return EMPTY_STRING;
};

export const getMinDateForDOB = (years) => {
  let date = new Date();
  date.setFullYear(date.getFullYear() - years);
  const yearDate = new Date(date);
  const formattedDate = yearDate.toISOString().split("T")[ZERO];
  return formattedDate.toString();
};

export const getMinDateForExpire = () => {
  const currentDate = new Date();
  return (
    currentDate.getFullYear() +
    "-" +
    (currentDate.getMonth() + 1) +
    "-" +
    currentDate.getDate()
  );
};

export const getFormattedDate = (dateString) =>
  moment(dateString, "YYYY-MM-DD").format("YYYY-MM-DD");

export const formatDateWithLeadingZeros = (dateString) => {
  const parts = dateString.split("T");
  const [year, month, day] = parts[ZERO].split("-");
  const formattedDateString = `${year}-${parseInt(month, TEN)
    .toString()
    .padStart(TWO, ZERO_STRING)}-${parseInt(day, TEN)
    .toString()
    .padStart(TWO, ZERO_STRING)}T${parts[ONE]}`;
  return formattedDateString;
};

export const getNextDate = (currDate) => {
  let date = new Date(currDate);
  date.setDate(date.getDate() + ONE);
  return date;
};

export const getFormattedDateAndTime = (inputDate) => {
  inputDate = new Date(inputDate);
  const options = {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return inputDate.toLocaleDateString("en-US", options);
};

export const getFormattedDayDateMonthYear = (inputDate) => {
  const dateParts = inputDate.split("/");
  const dateObject = new Date(
    `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
  );

  const day = dateObject.toLocaleDateString("en-US", { weekday: "short" });
  const month = dateObject.toLocaleDateString("en-US", { month: "short" });
  const date = dateObject
    .getDate()
    .toLocaleString("en-US", { minimumIntegerDigits: 2 });
  const year = dateObject.getFullYear();

  return `${day} ${date} ${month} ${year}`;
};

export const getFutureDateAfterDays = (
  dateInString = String(new Date()),
  daysToSkip = 1
) => {
  if (daysToSkip === 0) return dateInString;
  const [day, month, year] = dateInString.split("/");
  const date = new Date(year, month - 1, day);
  date.setTime(date.getTime() + daysToSkip * 24 * 60 * 60 * 1000);
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(date);

  return formattedDate;
};

export const getFormattedDateObject = (dateRateObject = {}) => {
  const dateObject = new Date(dateRateObject);
  const day = dateObject.toLocaleDateString("en-US", { weekday: "short" });
  const month = dateObject.toLocaleDateString("en-US", { month: "short" });
  const date = dateObject
    .getDate()
    .toLocaleString("en-US", { minimumIntegerDigits: 2 });
  const year = dateObject.getFullYear();
  return { day, month, date, year };
};

export const getFutureFormattedDateObject = (
  dateRateObject = {},
  daysToSkip = 1
) => {
  const dateObject = new Date(dateRateObject);
  dateObject.setDate(dateObject.getDate() + daysToSkip);
  const day = dateObject.toLocaleDateString("en-US", { weekday: "short" });
  const month = dateObject.toLocaleDateString("en-US", { month: "short" });
  const date = dateObject
    .getDate()
    .toLocaleString("en-US", { minimumIntegerDigits: 2 });
  const year = dateObject.getFullYear();
  return { day, month, date, year };
};

export const calculateDate = ({
  days = 0,
  months = 0,
  years = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}) => {
  let currentDate = new Date();

  currentDate.setDate(currentDate.getDate() + days);
  currentDate.setMonth(currentDate.getMonth() + months);
  currentDate.setFullYear(currentDate.getFullYear() + years);
  currentDate.setHours(currentDate.getHours() + hours);
  currentDate.setMinutes(currentDate.getMinutes() + minutes);
  currentDate.setSeconds(currentDate.getSeconds() + seconds);

  return currentDate.toISOString();
};

export const getDateDetails = (dateString) => {
   if (!dateString) return EMPTY_OBJECT;
  const [day, month, year] = dateString?.split("-");
  //The month is adjusted by subtracting 1 because JavaScript's Date object uses zero-based indexing.
  const dateObject = new Date(year, month - ONE, day);
  const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "short" });
  const monthShort = dateObject.toLocaleString("en-US", { month: "short" });
  return {
    date: day,
    day: dayOfWeek,
    month: monthShort,
    year: parseInt(year, TEN),
  };
};
