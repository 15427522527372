import Modal from "../../Modal";
import { isEmpty, get } from "lodash";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  getAirlineIconUrlByCarrierCode,
  getLayoverDuration,
  getFormattedArrivalAndDepartureDate,
  getCityNameByIata,
  getTotalTimeDifference,
} from "../../../../helper";
import {
  RenderSVG,
  FlightDarkIcon,
  Baggage,
  CalendarOutline,
  Seat,
  Cross,
  Information,
  ChevronUp,
  ChevronDown,
} from "../../../../assets/icons";
import { selectIsPrePackagedFlights } from "../../../../screens/FlightResults";
import {
  DEFAULT_VALUES,
  MODAL_SIZE,
  FLIGHT_TRIP_TYPES,
} from "../../../../constants";
import Tabs from "../../../organisms/Tabs";
import { useCollapse } from "react-collapsed";
import SSRDetails from "./SSRDetails";

const { XL } = MODAL_SIZE;
const { ONE, ZERO, EMPTY_STRING } = DEFAULT_VALUES;
const { ROUND_TRIP, MULTI_CITY } = FLIGHT_TRIP_TYPES;

const RenderSegments = ({
  segments = [],
  layoverDuration = "",
  travelerData = [],
  isLCC,
}) => {
  const { t } = useTranslation();
  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });
  const showPackages = useSelector(selectIsPrePackagedFlights);
  
  const mealDetails = travelerData.map((traveler) => {
    const values = traveler?.mealDetails?.value || [];
    if (values.length > 1 && values[0].flightNumber === values[1].flightNumber) {
        return [values[0]];
    }
    return values;
});

const seatDetails = travelerData.map((traveler) => {
    const values = traveler?.seatDetails?.value || [];
    if (values.length > 1 && values[0].flightNumber === values[1].flightNumber) {
        return [values[0]];
    }
    return values;
});


  const baggageDetails = travelerData.map((traveler) => {
    return traveler?.baggageDetails?.value || null;
  });
  return (
    <>
      <div>
        {segments.map(
          (
            {
              carrierCode,
              carrierName,
              flightNumber,
              departure,
              arrival,
              cabinClass,
              aircraftCode,
              baggageInfo,
            },
            index
          ) => (
            <div
              className='w-full p-4 pb-6 bg-white rounded-lg'
              key={departure.iataCode}
            >
              <div className='grid grid-cols-12 gap-x-1'>
                <div className='items-center mb-3 col-span-12 sm:col-span-2'>
                  <img
                    className='rounded border-contrast-300 w-12 h-12'
                    src={getAirlineIconUrlByCarrierCode(carrierCode)}
                    alt={`${carrierCode}_logo`}
                  />
                  <div className='text-sm text-contrast-900 font-semibold'>
                    <div>{carrierName}</div>
                    {carrierCode} {flightNumber} {aircraftCode}
                  </div>
                </div>
                <div className='grid grid-cols-12 col-span-10 mb-3'>
                  <div className='col-span-3'>
                    <div className='font-semibold'>{departure.time}&nbsp;</div>
                    <div className='text-contrast-800 text-xs'>
                      {getFormattedArrivalAndDepartureDate(departure.date)}
                    </div>
                    <div className='font-semibold'>
                      {getCityNameByIata(departure.iataCode)}
                    </div>
                    {departure.terminal && (
                      <div className='text-contrast-800 text-xs'>
                        {t("flightResults.journeyDetails.terminal")}&nbsp;
                        {departure.terminal}
                      </div>
                    )}
                    {departure.airportName && (
                      <div className='text-contrast-800 text-xs'>
                        {departure.airportName}
                      </div>
                    )}
                  </div>
                  <div
                    className={classNames("col-span-6 text-center", {
                      "md:col-span-6": !showPackages,
                    })}
                  >
                    <div className='text-contrast-500 px-4 md:px-0'>
                      {getTotalTimeDifference(
                        departure.iataCode,
                        departure.date,
                        departure.time,
                        arrival.iataCode,
                        arrival.date,
                        arrival.time
                      )}
                    </div>
                    <div className='border-t-2 border-orange-500 my-2 w-4/5 h-6 mx-auto flex justify-center relative'>
                      <RenderSVG
                        Svg={FlightDarkIcon}
                        className='rotate-90 absolute bottom-3.5'
                        alt='Flight-Icon'
                      />
                    </div>
                  </div>
                  <div className='col-span-3'>
                    <div className='font-semibold'>{arrival.time}</div>
                    <div className='text-contrast-800 text-xs'>
                      {getFormattedArrivalAndDepartureDate(arrival.date)}
                    </div>
                    <div className='font-semibold'>
                      {getCityNameByIata(arrival.iataCode)}
                    </div>
                    {arrival.terminal && (
                      <div className='text-contrast-800 text-xs'>
                        {t("flightResults.journeyDetails.terminal")}&nbsp;
                        {arrival.terminal}
                      </div>
                    )}
                    {arrival.airportName && (
                      <div className='text-contrast-800 text-xs'>
                        {arrival.airportName}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap gap-2 sm:gap-0'>
                {baggageInfo?.checkInBaggage && (
                  <div className='col-span-2 text-[10px] h-fit w-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center mr-2'>
                    <RenderSVG
                      Svg={Baggage}
                      width='14'
                      height='14'
                      className='text-blue-900'
                      alt='reissue'
                    />
                    <span className='ml-1'>
                      {t("flightResults.journeyDetails.baggage")}:{" "}
                      {baggageInfo?.checkInBaggage}
                    </span>
                  </div>
                )}
                {baggageInfo?.cabinBaggage && (
                  <div className='col-span-2 text-[10px] h-fit w-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center mr-2'>
                    <RenderSVG
                      Svg={CalendarOutline}
                      width='14'
                      height='14'
                      className='text-blue-900'
                      alt='reissue'
                    />
                    <span className='ml-1'>
                      {t("flightResults.journeyDetails.checkIn")}:{" "}
                      {baggageInfo?.cabinBaggage}
                    </span>
                  </div>
                )}
                {cabinClass && (
                  <div className='col-span-2 text-[10px] h-fit w-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center mr-2'>
                    <RenderSVG
                      Svg={Seat}
                      width='14'
                      height='14'
                      className='text-blue-900'
                      alt='reissue'
                    />
                    <span className='ml-1'>
                      {t("flightResults.journeyDetails.cabin")}: {cabinClass}
                    </span>
                  </div>
                )}
              </div>
              {!isEmpty(layoverDuration) && segments[index + ONE] && (
                <div className='flex items-center mt-4'>
                  <div className='flex-grow border-t-2 border-contrast-300'></div>
                  <div className='rounded p-2 mx-2'>
                    <div className='flex items-center'>
                      <div className='font-semibold text-primary-900 text-sm'>
                        {t("flightResults.journeyDetails.changeOfPlanes")}
                      </div>
                      <div className='flex items-center'>
                        <div className='flex-grow'>
                          <div className='border-t-2 border-contrast-300'></div>
                        </div>
                        <div className='ml-2 text-sm'>
                          {t("flightResults.journeyDetails.layoverDuration")}:
                        </div>
                        <div className='ml-2 font-semibold text-sm'>
                          {layoverDuration[index]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex-grow border-t-2 border-contrast-300'></div>
                </div>
              )}
            </div>
          )
        )}
      </div>
      <div>
        <div className='bg-white rounded-xl border border-contrast-300 overflow-hidden mt-6'>
          <div
            className='px-6 py-4 flex items-center border-b border-contrast-300 bg-contrast-100'
            {...getToggleProps()}
          >
            <h4 className='text-base font-bold text-contrast-900 flex-1'>
              {t("bookingResult.travelers")}
            </h4>
            <h4 className='flex gap-3 items-center text-base font-medium text-contrast-900'>
              <span>{travelerData.length}</span>
              <button className='p-2 cursor-pointer shrink-0'>
                <RenderSVG
                  Svg={isExpanded ? ChevronUp : ChevronDown}
                  className='text-contrast-800'
                  width='20'
                />
              </button>
            </h4>
          </div>
          <div
            className='flex flex-col divide-y divide-contrast-300'
            {...getCollapseProps()}
          >
            {travelerData.map(
              ({
                id,
                title = EMPTY_STRING,
                givenName = EMPTY_STRING,
                familyName = EMPTY_STRING,
                travelerType = EMPTY_STRING,
                gender = EMPTY_STRING,
                email = EMPTY_STRING,
                phoneNumber = EMPTY_STRING,
              }) => (
                <div key={id} className='divide-y divide-contrast-300'>
                  <div className='flex items-center flex-wrap gap-2 justify-between px-6 py-6'>
                    <div className='flex flex-col gap-1'>
                      <h6 className='text-sm font-bold text-contrast-900'>{`${title} ${givenName} ${familyName}`}</h6>
                      <div className='text-xs text-contrast-500'>
                        <span>
                          {email}, {phoneNumber}
                        </span>
                      </div>
                    </div>

                    <span className='text-xs font-semibold text-primary-800 bg-primary-100 px-3 py-1 rounded-3xl capitalize'>
                      {t(`bookingResult.travelerType.${travelerType}`)} -{" "}
                      {(title.toLowerCase().trim() === "ms" || title.toLowerCase().trim() === "mrs" || title.toLowerCase().trim() === "miss")
                        ? t(`profilePage.female`)
                        : t(`profilePage.male`)
                      }
                    </span>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <SSRDetails
          mealDetails={mealDetails}
          seatDetails={seatDetails}
          baggageDetails={baggageDetails}
          isLCC={isLCC}
          segments={segments}
        />
      </div>
    </>
  );
};

const FlightSummaryModal = ({
  title = "",
  handleClose = () => {},
  flights = [],
}) => {
  const getOriginDestinationIataCodes = (itineraries = []) => {
    const originIata = get(
      itineraries,
      "0.segments.0.departure.iataCode",
      EMPTY_STRING
    );
    const lastSegment = get(itineraries, "0.segments", []).at(-1);
    const destinationIata = get(lastSegment, "arrival.iataCode", EMPTY_STRING);
    return { originIata, destinationIata };
  };

  const formattedFlightDetails = flights?.reduce((acc, flight) => {
    const { itineraries, travelerDetails, isLCC } =
      flight?.bookingJson?.journeyDetails[0] || {};
    acc.push({
      itineraries: itineraries,
      travelerDetails: travelerDetails,
      isLCC: isLCC,
      journeyType: flight.journeyType,
    });
    return acc;
  }, []);

  const { travelerDetails, journeyType, itineraries, isLCC } =
    formattedFlightDetails[0];

  const isPackagedFlight =
    (journeyType === ROUND_TRIP && itineraries.length > ONE) ||
    journeyType === MULTI_CITY;

  const tabs = isPackagedFlight
    ? itineraries.map((itinerary, itineraryIndex) => {
        const { originIata, destinationIata } = getOriginDestinationIataCodes([
          itinerary,
        ]);

        return {
          id: itineraryIndex,
          default: itineraryIndex === ZERO,
          title: `${originIata}-${destinationIata}`,
          element: (
            <RenderSegments
              key={itineraryIndex}
              segments={itinerary.segments}
              layoverDuration={getLayoverDuration(itinerary.segments)}
              duration={itinerary.duration}
              travelerData={travelerDetails}
              isLCC={isLCC}
              journeyType={journeyType}
            />
          ),
        };
      })
    : formattedFlightDetails.map((item, index) => {
        const { itineraries, travelerDetails, isLCC, journeyType } = item;
        const { originIata, destinationIata } =
          getOriginDestinationIataCodes(itineraries);

        return {
          id: index,
          default: index === ZERO,
          title: `${originIata}-${destinationIata}`,
          element: itineraries.map((itinerary, itineraryIndex) => {
            return (
              <RenderSegments
                key={itineraryIndex}
                segments={itinerary.segments}
                layoverDuration={getLayoverDuration(itinerary.segments)}
                duration={itinerary.duration}
                travelerData={travelerDetails}
                isLCC={isLCC}
                journeyType={journeyType}
              />
            );
          }),
        };
      });

  return (
    <Modal
      shouldShowModalFromProps
      handleClose={handleClose}
      modalClassname='no-scrollbar h-[75vh]'
      size={XL}
      shouldCloseOnClickOutside={true}
    >
      <div className='flex flex-col divide-y divide-contrast-300'>
        <div className='flex justify-between items-center p-6 top-0 sticky border-b bg-white z-50'>
          <div className='flex gap-5 items-center'>
            <div className='rounded-full bg-primary-100 p-2 w-10 h-10'>
              <RenderSVG
                Svg={Information}
                width='24'
                height='24'
                className='text-[25px]'
              />
            </div>
            <div className='text-2xl leading-8 font-bold'>{title}</div>
          </div>
          <button onClick={handleClose}>
            <RenderSVG Svg={Cross} alt='cross' className='text-contrast-900' />
          </button>
        </div>
        <div className='p-6'>
          <Tabs tabs={tabs} />
        </div>
      </div>
    </Modal>
  );
};

export default FlightSummaryModal;
