import { ErrorMessage, Field } from "formik";
import Asterisk from "../../../atoms/Asterisk";
const CostPolicyManager = ({ policyName, policyType }) => {
  return (
    <>
      <div className='flex items-start justify-between gap-2'>
        <div>
          <label className='text-lg font-semibold mb-1 capitalize'>
            {policyType + " " + policyName}
          </label>
          <div className='text-sm font-normal text-gray-500'>
            {`Update the ${policyType} ${policyName} value`}<Asterisk />
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <label className='relative inline-flex items-center cursor-pointer'>
            <Field type='checkbox' name='isEnabled' className='sr-only peer' />
            <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
          </label>
        </div>
      </div>
      <div className='flex w-full rounded-md focus:outline-none'>
        <div className='w-full text-contrast-500'>
          <Field
            name='value'
            type="text"
            className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg '
          />
          <ErrorMessage
            name='value'
            component='div'
            className='text-red-500 text-sm'
          />
        </div>
      </div>
    </>
  );
};

export default CostPolicyManager;
