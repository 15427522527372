import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDrawer } from "../../organisms/Drawer";
import Asterisk from "../../atoms/Asterisk";
import {
  addCostCodes,
  getCostCodes,
  selectUserInfo,
} from "../../../screens/Profile";
import { setSelectedTripDetail } from "../../../screens/Booking/Trips";
import { selectTripDetail } from "../../../screens/Booking/Trips/trips.selector";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";
import { DEFAULT_VALUES, REGEX } from "../../../constants";

const { STRING, FIVE, TWENTY_FIVE, HUNDRED } = DEFAULT_VALUES;
const { ADD_COST_CODES } = SPINNER_NAMES;
const { WHITE_SPACES_DETECTION_REGEX, COST_CODE_VALIDATION_REGEX } = REGEX;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(WHITE_SPACES_DETECTION_REGEX, "Cost code name cannot be just spaces")
    .trim()
    .min(FIVE, "Cost code name must be at least 5 characters")
    .matches(COST_CODE_VALIDATION_REGEX, "Cost code name can conatins only alphabets(a-z,A-Z), digits(0-9), dash(-) and underscore(_)")
    .max(TWENTY_FIVE, "Cost code name must be atmost 25 characters")
    .required("Cost code name is required"),
  costCode: Yup.string()
    .matches(WHITE_SPACES_DETECTION_REGEX, "Cost code value cannot be just spaces")
    .trim()
    .min(FIVE, "Cost code must be at least 5 characters")
    .matches(COST_CODE_VALIDATION_REGEX, "Cost code can conatins only alphabets(a-z,A-Z), digits(0-9), dash(-) and underscore(_)")
    .max(TWENTY_FIVE, "Cost code must be atmost 25 characters")
    .required("Cost code value is required"),
  description: Yup.string()
    .matches(WHITE_SPACES_DETECTION_REGEX, "Cost code description cannot be just spaces")
    .trim()
    .min(FIVE, "Description must be at least 5 characters")
    .max(HUNDRED, "Cost code description must be atmost 100 characters")
    .required("Cost code description is required"),
});
const initialValues = {
  name: "",
  costCode: "",
  description: "",
};

export default function AddCostCode({ handleClose }) {
  const dispatch = useDispatch();
  const newTripDetail = useSelector(selectTripDetail);
  const userInfo = useSelector(selectUserInfo);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const { tenantId } = userInfo;

  const handleOnSubmit = (values) => {
    const trimmedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [
        key,
        typeof value === STRING ? value.trim() : value,
      ])
    );
    
    const requestBody = {
      ...trimmedValues,
      tenantId: tenantId,
    };

    dispatch(addCostCodes(requestBody)).then((res) => {
      if (res.error) return;
      dispatch(setSelectedDrawer(null));
      dispatch(getCostCodes(tenantId));
      dispatch(setSelectedTripDetail({...newTripDetail, costCode : res.payload}));
      toast.success("Cost code added successfully!");
    });
  };

  return (
    <div className='p-6'>
      <div className='mb-4 font-semibold'>Cost Code Information</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, isValid, dirty }) => (
          <Form>
            <div className='col-span-12 flex flex-col gap-4'>
              <div className='form-group'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  Cost Code Name
                  <Asterisk />
                </label>
                <Field
                  id='name'
                  name='name'
                  type='text'
                  className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                  placeholder='Enter cost code name'
                />
                {errors.name && touched.name && (
                  <div className='text-red-500 text-xs mt-1'>{errors.name}</div>
                )}
              </div>

              <div className='form-group'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  Cost Code
                  <Asterisk />
                </label>
                <Field
                  id='costCode'
                  name='costCode'
                  type='text'
                  className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                  placeholder='Enter cost code'
                />
                {errors.costCode && touched.costCode && (
                  <div className='text-red-500 text-xs mt-1'>
                    {errors.costCode}
                  </div>
                )}
              </div>

              <div className='form-group'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  Description
                  <Asterisk />
                </label>
                <Field
                  id='description'
                  name='description'
                  as='textarea'
                  className='form-control max-h-28 min-h-14 block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                  placeholder='Enter description'
                />
                {errors.description && touched.description && (
                  <div className='text-red-500 text-xs mt-1'>
                    {errors.description}
                  </div>
                )}
              </div>
            </div>

            <div className='flex gap-4 justify-end p-4'>
              <button
                onClick={handleClose}
                type='button'
                className='bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300 h-10 px-4 py-2'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='text-white font-medium disabled:cursor-not-allowed disabled:opacity-75 disabled:bg-primary-400 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm'
                disabled={isSpinnerActive ||!isValid ||!dirty }
              >
                <Spinner
                  name={ADD_COST_CODES}
                  setIsSpinnerActive={setIsSpinnerActive}
                  persistSize={true}
                >
                  Add Cost Code
                </Spinner>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
