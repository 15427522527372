import { Field, useFormikContext } from "formik";
import { renderFieldError } from "../../helper";
import Asterisk from "../../components/atoms/Asterisk";
import { useState, useEffect } from "react";
import { GUESTS_TITLES } from "../../constants";

const GuestPrimaryDetails = ({ isChildTitle }) => {
  const { errors, touched, setFieldValue, setFieldTouched, values } = useFormikContext();
  const { Adult, Child } = GUESTS_TITLES;
  const optionFields = isChildTitle ? Child : Adult;
  useEffect(() => {
      setFieldValue("panCardDetails.surname", values.profileDetails?.lastName || "");
      setFieldValue('panCardDetails.isChild', values.profileDetails?.isChild || false);
  }, [values.profileDetails?.lastName, values.profileDetails?.isChild]);

  useEffect(() => {
    !isChildTitle &&
      Child.includes(values.profileDetails?.title) &&
      setFieldValue("profileDetails.title", Adult[0]);

    if (isChildTitle) {
      if (Adult.includes(values.profileDetails?.title))
        setFieldValue("profileDetails.title", Child[0]);
      if (values.profileDetails?.title === Adult[2])
        setFieldValue("profileDetails.title", Child[1]);
    }
  }, [isChildTitle]);

  const primaryFields = [
    {
      name: "firstName",
      label: "First Name",
      isRequired: true,
    },
    {
      name: "middleName",
      label: "Middle Name",
      isRequired: false,
    },
    {
      name: "lastName",
      label: "Last Name",
      isRequired: true,
    },
  ];

  return (
    <>
      <div className='col-span-12 sm:col-span-6 md:col-span-5'>
        <div className='form-group'>
          <label
            htmlFor='title'
            className='block text-sm font-medium mb-1 text-contrast-900'
          >
            Title
          </label>
          <select
            id='title'
            name='profileDetails.title'
            value={values?.profileDetails?.title}
            className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500'
            onChange={(e) =>
              setFieldValue("profileDetails.title", e.target.value)
            }
          >
            {optionFields.map((title) => (
              <option key={title} value={title}>
                {title}
              </option>
            ))}
          </select>
        </div>
      </div>
      {primaryFields.map(({ name, label, isRequired }) => (
        <div key={name} className='col-span-12 sm:col-span-6 md:col-span-5'>
          <div className='form-group'>
            <label
              htmlFor={name}
              className='block text-sm font-medium mb-1 text-contrast-900'
            >
              {label}
              {isRequired && <Asterisk />}
            </label>
            <div>
              <Field
                id={name}
                type='text'
                name={`profileDetails.${name}`}
                className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500'
                placeholder={label}
                onBlur={(e) =>{
                  setFieldValue(`profileDetails.${name}`, e.target.value.trim());
                  setFieldTouched(`profileDetails.${name}`, true); 
                }}             
              />
            </div>
            <div>
              {renderFieldError(`profileDetails.${name}`, errors, touched)}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default GuestPrimaryDetails;
