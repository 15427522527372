import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import {
  selectActiveFilters,
  selectFlights,
} from "../../../components/organisms/Search";
import { DEFAULT_VALUES, INDEX, TRIP_TYPES } from "../../../constants";
import { actions as filterActions } from "../../../components/organisms/Search/search.reducer";
import { actions as flighAction } from "../flightResults.reducer";
import { selectSelectedTripType } from "../flightResults.selectors";

const { setFilteredFlights } = filterActions;
const { setSelectedFilteredFlights } = flighAction;
const { ZERO, ONE, EMPTY_ARRAY, EMPTY_STRING } = DEFAULT_VALUES;
const { FIRST, LAST } = INDEX;
const { ROUND_TRIP, ONE_WAY, MULTI_CITY } = TRIP_TYPES;

const convertToMinutes = (time) => {
  const [hours, minutes] = time.split(":");
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
};

const getNoOfStops = ({ firstFlight, itenaryIndex = ZERO }) =>
  get(firstFlight, `itineraries[${itenaryIndex}].segments`, []).length - ONE;

const getFlightTime = (flight, isDepartureTime = true) => {
  const itenaryIndex = isDepartureTime ? ZERO : flight.itineraries.length - 1;
  const segments = flight.itineraries[itenaryIndex].segments;

  const segmentIndex = isDepartureTime ? ZERO : segments.length - 1;
  const location = isDepartureTime ? "departure.time" : "arrival.time";
  const time = get(
    flight,
    `itineraries[${itenaryIndex}].segments[${segmentIndex}].${location}`,
    "ZERO"
  );

  return convertToMinutes(time);
};

const getFlightPrice = (flight) => +get(flight, "price.totalPrice", ZERO);

const getLayoverAirports = (flight) => {
  const segments = get(flight, "itineraries[0].segments", EMPTY_ARRAY).slice(
    FIRST,
    LAST
  );
  const stopLocations = [];
  for (const segment of segments) {
    const stopCode = get(segment, "arrival.iataCode", EMPTY_STRING);
    if (stopCode) {
      stopLocations.push(stopCode);
    }
  }

  return stopLocations;
};

const getAirlineCode = (flight) =>
  get(flight, "itineraries[0].segments[0].carrierCode", "");

const getFilteredFlight = ({ flight = [], filters = {} }) => {
  const firstFlight = flight[ZERO];
  const flightPrice = getFlightPrice(firstFlight);
  const departureTime = getFlightTime(firstFlight, true);
  const arrivalTime = getFlightTime(firstFlight, false);
  const checkItinerary = (itinerary, index) => {
    const {
      stops,
      airlines,
      layoverAirports: filterLayoverAirports,
      departureTime: filterDepartureTime,
      arrivalTime: filterArrivalTime,
      price,
    } = filters;
    const layoverAirports = getLayoverAirports({ firstFlight, itinerary });
    const noOfStops = getNoOfStops({ firstFlight, itenaryIndex: index });
    const hasMatchingStops =
      !isEmpty(stops) && !stops.includes(String(noOfStops));
    const hasMatchingAirline =
      !isEmpty(airlines) && !airlines.includes(getAirlineCode(firstFlight));
    const hasMatchingLayover =
      !isEmpty(filterLayoverAirports) &&
      !filterLayoverAirports.some((airport) =>
        layoverAirports.includes(airport)
      );
    const isWithinDepartureTimeRange = !(
      filterDepartureTime?.minTime <= departureTime &&
      filterDepartureTime?.maxTime >= departureTime
    );
    const isWithinArrivalTimeRange = !(
      filterArrivalTime?.minTime <= arrivalTime &&
      filterArrivalTime?.maxTime >= arrivalTime
    );
    const isAboveMaxPrice = price?.maxPrice && +flightPrice > +price?.maxPrice;
    const isBelowMinPrice = price?.minPrice && +flightPrice < +price?.minPrice;

    return !(
      hasMatchingStops ||
      hasMatchingAirline ||
      isAboveMaxPrice ||
      isBelowMinPrice ||
      hasMatchingLayover ||
      isWithinDepartureTimeRange ||
      isWithinArrivalTimeRange
    );
  };
  const allItinerariesSatisfy = firstFlight.itineraries.every(checkItinerary);

  return allItinerariesSatisfy;
};

const FlightActiveFilters = () => {
  const dispatch = useDispatch();
  const totalFlights = useSelector(selectFlights) || {};
  const activeFilters = useSelector(selectActiveFilters);
  const tripType = useSelector(selectSelectedTripType);

  useEffect(() => {
    if (isEmpty(totalFlights) || isEmpty(activeFilters)) return;

    const { packages = [], isolated = {} } = totalFlights;
    const { inbound = [], outbound = [] } = isolated;
    const { packages: packagesFilters } = activeFilters;
    if (tripType === ONE_WAY || tripType === MULTI_CITY) {
      const filteredFlights = packages.filter((flight) =>
        getFilteredFlight({ flight, filters: packagesFilters })
      );

      dispatch(setFilteredFlights({ packages: filteredFlights }));
      const packagedFlights = get(filteredFlights, "0.0");
      if (!isEmpty(packagedFlights)) {
        dispatch(setSelectedFilteredFlights({ packages: packagedFlights }));
      }
    } else if (tripType === ROUND_TRIP) {
      const { isolated: isolatedFilters = {} } = activeFilters;
      const inboundFilters = isolatedFilters.inbound || {};
      const outboundFilters = isolatedFilters.outbound || {};
      const filteredInboundFlights = inbound.filter((flight) =>
        getFilteredFlight({ flight, filters: inboundFilters })
      );
      const filteredOutboundFlights = outbound.filter((flight) =>
        getFilteredFlight({ flight, filters: outboundFilters })
      );
      const filteredPrePackagedFlights = packages.filter((flight) =>
        getFilteredFlight({ flight, filters: packagesFilters })
      );
      
      dispatch(
        setFilteredFlights({
          isolated: {
            outbound: filteredOutboundFlights,
            inbound: filteredInboundFlights,
          },
          packages: filteredPrePackagedFlights,
        })
      );

      const selectedInboundFlight = get(filteredInboundFlights,"0.0");
      const selectedOutboundFlight = get(filteredOutboundFlights,"0.0");
      if (!isEmpty(selectedInboundFlight) && !isEmpty(selectedOutboundFlight)) {
        dispatch(
          setSelectedFilteredFlights({
            outbound: selectedOutboundFlight,
            inbound: selectedInboundFlight,
          })
        );
      }
    }
  }, [totalFlights, activeFilters, tripType, dispatch]);

  return null;
};

export default FlightActiveFilters;
