import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEmpty, get, size, differenceWith, toPairs, isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import {
  getSortedFlights,
  calculateNumberOfNonStops,
  getObjectFromQueryParams,
} from "../../helper";
import { LeftIcon, RightIcon, RenderSVG } from "../../assets/icons";
import FlightSearch, {
  selectFlights,
  selectFlightsCount,
  selectSearchFilters,
  selectActiveSortOrder,
  selectFilteredFlights,
  selectActiveFilters,
} from "../../components/organisms/Search";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  selectCurrentFlightType,
  selectSelectedTripType,
  selectIsPrePackagedFlights,
  selectSelectedFlightOptions,
} from "./index";
import SelectedFlightsCard from "./SelectedFlightsCard";
import { selectSelectedFlightId } from "./flightResults.selectors";
import FlightsChart from "./FlightsChart";
import FlightLoader from "./FlightLoader";
import RoundTripFlights from "./RoundTripFlights";
import PackagedFlights from "./PackagedFlights";
import { actions as searchActions } from "../../components/organisms/Search/search.reducer";
import { actions as flightResultsActions } from "./flightResults.reducer";
import { selectTripStatus, setTripStatus } from "../Booking/FlightBookings";
import {
  TRIP_TYPES,
  FLIGHTS_RESULT_TYPE,
  DEFAULT_VALUES,
  INITIAL_SORT_FILTERS,
  ROUNDTRIP_RESULT_FORMATS,
} from "../../constants";
import { selectTenantDetails } from "../Auth";
import EmptyLayout from "../../components/molecules/EmptyLayout";
import NoFlightResult from "./NoFlightResult";

const { FETCH_FLIGHTS_RESULTS, PENDING } = SPINNER_NAMES;
const { ROUND_TRIP, ONE_WAY, MULTI_CITY } = TRIP_TYPES;
const { ZERO, EMPTY_OBJECT } = DEFAULT_VALUES;
const { OUTBOUND_RESULT, INBOUND_RESULT, PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const { PACKAGES, ISOLATED } = ROUNDTRIP_RESULT_FORMATS;
const CAROUSEL_HANDLERS = {
  LEFT: "left",
  RIGHT: "right",
};
const { setSortedFlights } = searchActions;
const { setCurrentFlightType, setRoundTripResultFormat } = flightResultsActions;

const FlightResults = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const tenantDetails = useSelector(selectTenantDetails);
  const selectedFlightId = useSelector(selectSelectedFlightId);
  const flights = useSelector(selectFlights);
  const currentFlightType = useSelector(selectCurrentFlightType);
  const tripType = useSelector(selectSelectedTripType);
  const flightFilters = useSelector(selectActiveFilters);
  const filteredFlights = useSelector(selectFilteredFlights) || EMPTY_OBJECT;
  const flightsCount = useSelector(selectFlightsCount);
  const isPrePackaged = useSelector(selectIsPrePackagedFlights);
  const selectedActiveSortOrder = useSelector(selectActiveSortOrder);
  const selectedFlightOptions = useSelector(selectSelectedFlightOptions);
  const tripStatus = useSelector(selectTripStatus);

  const [showPackages, setShowPackages] = useState(isPrePackaged);
  const [showSpinner, setShowSpinner] = useState(true);
  const [totalNonStops, setTotalNonStops] = useState(ZERO);
  const [filtersCount, setFiltersCount] = useState();

  const areFlightsAvailable =
    get(flightsCount, "packages", ZERO) ||
    get(flightsCount, "inbound", ZERO) ||
    get(flightsCount, "outbound", ZERO);

  const tenantConfig = get(tenantDetails, "tenantConfig");

  const isReissuanceFlight =
    search && getObjectFromQueryParams(search).searchType === "1";

  const isMultiCity = tripType === MULTI_CITY;
  const { packages = [], isolated = {} } = filteredFlights;
  const { inbound = [], outbound = [] } = isolated;

  const { brandName } = tenantConfig;

  const shouldShowFlightsChart = useMemo(() => {
    if (isMultiCity) return false;
    else return !isEmpty(packages) || !isEmpty(inbound) || !isEmpty(outbound);
  }, [tripType, packages, inbound, outbound]);

  const updatePackageFlights = (updatedFilteredFlights = {}) => {
    if (updatedFilteredFlights.packages && selectedActiveSortOrder.packages)
      return {
        ...updatedFilteredFlights,
        packages: getSortedFlights(
          updatedFilteredFlights.packages,
          selectedActiveSortOrder.packages
        ),
      };
    return updatedFilteredFlights;
  };

  const updateInboundFlights = (updatedFilteredFlights = {}) => {
    if (
      updatedFilteredFlights.isolated &&
      updatedFilteredFlights.isolated.inbound &&
      selectedActiveSortOrder.inbound
    )
      return {
        ...updatedFilteredFlights,
        isolated: {
          ...updatedFilteredFlights.isolated,
          inbound: getSortedFlights(
            updatedFilteredFlights.isolated.inbound,
            selectedActiveSortOrder.inbound
          ),
        },
      };
    return updatedFilteredFlights;
  };

  const updateOutboundFlights = (updatedFilteredFlights = {}) => {
    if (
      updatedFilteredFlights.isolated &&
      updatedFilteredFlights.isolated.outbound &&
      selectedActiveSortOrder.outbound
    )
      return {
        ...updatedFilteredFlights,
        isolated: {
          ...updatedFilteredFlights.isolated,
          outbound: getSortedFlights(
            updatedFilteredFlights.isolated.outbound,
            selectedActiveSortOrder.outbound
          ),
        },
      };
    return updatedFilteredFlights;
  };

  useEffect(() => {
    dispatch(setRoundTripResultFormat(showPackages ? PACKAGES : ISOLATED));
  }, [showPackages, tripType]);

  useEffect(() => {
    let updatedFilteredFlights = filteredFlights;
    updatedFilteredFlights = updatePackageFlights(updatedFilteredFlights);
    updatedFilteredFlights = updateInboundFlights(updatedFilteredFlights);
    updatedFilteredFlights = updateOutboundFlights(updatedFilteredFlights);
    dispatch(setSortedFlights(updatedFilteredFlights));
  }, [filteredFlights]);

  const isFilterResultAvailable = !!(
    filteredFlights?.isolated?.outbound?.length ||
    filteredFlights?.isolated?.inbound?.length ||
    filteredFlights?.packages?.length
  );

  const shouldShowSelectedFlightsCard =
    !isEmpty(selectedFlightOptions) && isFilterResultAvailable;

  useEffect(() => {
    if (isEmpty(flights)) return;
    const { isolated: { inbound, outbound }, packages } = flights;
    !isEmpty(packages) && (isEmpty(inbound) && isEmpty(outbound)) && setShowPackages(true);
    isEmpty(packages) && !(isEmpty(inbound) && isEmpty(outbound)) && setShowPackages(false);
  }, [tripType, flights]);

  const handleFlightResultsSwipe = useCallback(
    (handle) =>
      dispatch(
        setCurrentFlightType(
          handle === CAROUSEL_HANDLERS.LEFT ? OUTBOUND_RESULT : INBOUND_RESULT
        )
      ),
    [dispatch]
  );

  useEffect(() => {
    const numberOfNonStops = calculateNumberOfNonStops(
      filteredFlights,
      PACKAGE_RESULT
    );
    setTotalNonStops(numberOfNonStops);
  }, [showPackages, filteredFlights]);

  useEffect(() => {
    const { flightSearchQuery } = tripStatus;
    let key = "isFlightSearchInitiated";
    let value = true;
    !isEmpty(flightSearchQuery) && dispatch(setTripStatus({ key, value }));
  }, []);

  const renderFlightsCard = useMemo(() => {
    let FlightsType = RoundTripFlights;
    if (isEmpty(filteredFlights)) return null;
    let props = {
      selectedFlightId,
    };
    if (showPackages) {
      FlightsType = PackagedFlights;
      props = {
        numberOfNonStops: totalNonStops,
        selectedFlightId: selectedFlightId.packages,
        showOneWayCard: tripType === ONE_WAY,
        isReissuanceFlight,
      };
    }
    return <FlightsType {...props} />;
  }, [
    showPackages,
    tripType,
    filteredFlights,
    selectedFlightId,
    totalNonStops,
  ]);

  const handleSpinner = useCallback(
    (name, spinners) => {
      const showSpinner =
        !areFlightsAvailable &&
        spinners.some((spinner) => spinner === name || spinner === PENDING);
      setShowSpinner(showSpinner);
      return showSpinner; // refactor
    },
    [flightsCount, areFlightsAvailable]
  );

  const renderEmptyLayout = () =>
    search ? <NoFlightResult /> : <EmptyLayout />;

  return (
    <div className='flex-grow bg-white h-full'>
      <Helmet>
        <title>{brandName} | Flights Results</title>
      </Helmet>
      <div className='sticky top-14 w-full z-30 pt-4 bg-contrast-100'>
        <div className='bg-white -mt-4'>
          <div className='flex-center px-6 py-4 border border-contrast-200'>
            <FlightSearch />
          </div>
        </div>
      </div>
      <div className='min-h-[60vh] flex flex-col align-middle relative bg-white'>
        <Spinner
          name={FETCH_FLIGHTS_RESULTS}
          loaderComponent={<FlightLoader />}
          customSpinnerMethod={handleSpinner}
          showSpinner={showSpinner}
          showSkeleton={true}
        >
          {!isEmpty(flights) ? (
            <div className='p-4 pt-2 md:p-2'>
              <div className='flex-col m-2 max-w-auto justify-center items-center'>
                {shouldShowFlightsChart && (
                  <div
                    className={classNames({ "m-2": tripType === ROUND_TRIP })}
                  >
                    <FlightsChart
                      showPackages={showPackages}
                      tripType={tripType}
                    />
                  </div>
                )}
                <Tooltip
                  className='!bg-primary-900 !z-30 !rounded-lg !hidden md:!block'
                  id='refundability-tag'
                />
                <div>
                  <div className='flex flex-col gap-6'>{renderFlightsCard}</div>
                </div>
                {tripType === ROUND_TRIP &&
                  !isEmpty(isolated) &&
                  !showPackages && (
                    <>
                      {/* TODO: Update logic for swiper, TBD */}
                      <div className='block md:hidden'>
                        <button
                          className='search-result-button-prev w-12 h-12 rounded-full bg-primary-400  hover:bg-primary-500 grid place-content-center fixed left-2 top-1/2 z-10 mt-10 mb-10 cursor-pointer disabled:bg-contrast-400 disabled:opacity-50 disabled:cursor-not-allowed'
                          disabled={currentFlightType === OUTBOUND_RESULT}
                          onClick={() =>
                            handleFlightResultsSwipe(CAROUSEL_HANDLERS.LEFT)
                          }
                        >
                          <RenderSVG
                            Svg={LeftIcon}
                            width='30'
                            height='30'
                            alt='left-icon'
                          />
                        </button>
                        <button
                          className='search-result-button-next w-12 h-12 rounded-full bg-primary-400  hover:bg-primary-500 grid place-content-center fixed right-2 top-1/2 z-10 mt-10 mb-10 cursor-pointer disabled:bg-contrast-400 disabled:opacity-50 disabled:cursor-not-allowed'
                          disabled={currentFlightType === INBOUND_RESULT}
                          onClick={() =>
                            handleFlightResultsSwipe(CAROUSEL_HANDLERS.RIGHT)
                          }
                        >
                          <RenderSVG
                            Svg={RightIcon}
                            width='30'
                            height='30'
                            alt='right-icon'
                          />
                        </button>
                      </div>{" "}
                    </>
                  )}
                {shouldShowSelectedFlightsCard && (
                  <SelectedFlightsCard
                    selectedFlightOptions={selectedFlightOptions}
                    currentFlightType={currentFlightType}
                  />
                )}
              </div>
            </div>
          ) : (
            renderEmptyLayout()
          )}
        </Spinner>
      </div>
    </div>
  );
};

export default FlightResults;
