import CryptoJS from "crypto-js";
import { DEFAULT_VALUES } from "../constants";

const { FIFTY } = DEFAULT_VALUES;
const secretKey = process.env.REACT_APP_CLIENT_VALIDATION_KEY;
const expirationTimeSeconds = FIFTY;

export const generateReferrerID = () => {
  try {
    const expirationTimestamp =
      Math.floor(Date.now() / 1000) + expirationTimeSeconds;
    const combinedKey = secretKey + "." + expirationTimestamp;
    const encryptedKey = CryptoJS.AES.encrypt(
      combinedKey,
      secretKey
    ).toString();
    const finalKey =
      encryptedKey +
      "." +
      CryptoJS.AES.encrypt(
        expirationTimestamp.toString(),
        secretKey
      ).toString();
    return finalKey;
  } catch (error) {
    console.error(error);
  }
};
