import { ROUTES } from "../constants";

const { LOGIN } = ROUTES;

export const signout = (timeout = 0) => {
  localStorage.clear();
  sessionStorage.clear();

  setTimeout(() => {
    window.location.assign(LOGIN);
  }, timeout);
};
