const getFormattedAmount = (data) => {
  const { Amount, DecimalPlaces } = data;
  const amountAsFloat = parseFloat(Amount);

  if (DecimalPlaces === "0") return amountAsFloat;
  else {
    const divisor = Math.pow(10, parseInt(DecimalPlaces, 10));
    return parseFloat((amountAsFloat / divisor).toFixed(2));
  }
};

export default getFormattedAmount;
