import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { selectSelectedTrip } from "../../../screens/Booking/Trips";
import { DEFAULT_VALUES } from "../../../constants";
import { MODALS } from "../AppModals";
import { setSelectedModal } from "../Modal";

const { EMPTY_OBJECT } = DEFAULT_VALUES;
const { TRIP_PAYMENT_MODAL } = MODALS;

const PaymentButton = ({ shouldDisable }) => {
  const dispatch = useDispatch();
  const selectedTrip = useSelector(selectSelectedTrip);

  const { bookType } = selectedTrip || EMPTY_OBJECT;

  return (
    <>
      {(
        <div className='pl-4 border-l border-gray-100'>
          <div
            className={classNames(
              "pl-[15px] pr-[17px] py-[9px] rounded-md shadow justify-center items-center gap-2 flex",
              {
                "cursor-pointer": !shouldDisable,
                "cursor-not-allowed": shouldDisable,
                "bg-contrast-300": shouldDisable,
                "bg-indigo-600": !shouldDisable,
              }
            )}
            onClick={() =>
              !shouldDisable && dispatch(setSelectedModal(TRIP_PAYMENT_MODAL))
            }
          >
            <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
              Proceed to Payment
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentButton;
