import { isEmpty, get } from "lodash";
import { useTranslation } from "react-i18next";
const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
}
const maleTitle = 'Mr'
const { MALE, FEMALE } = GENDER

const TravelerDetailsCard = ({
  traveler,
  shouldShowPassportDetails,
  showFareCategoryDocuments,
}) => {
  const { t } = useTranslation();

  const profileDetails = get(traveler, "profileDetails", {});
  const { email, phoneNumber, title } = profileDetails;
  const gender = profileDetails.gender || (title === maleTitle ? MALE : FEMALE);

  return (
    <div className='flex-col px-4 border-contrast-100 border-b'>
      <div className='pb-2 border-t border-contrast-200'>
        <div className='flex flex-col gap-4 pb-6 pt-4 border-b border-contrast-200'>
          <h5 className='text-base text-contrast-900 font-bold'>
            {t("profilePage.demographics")}
          </h5>
          <div className='flex gap-1 items-center flex-wrap'>
            <h6 className='text-base text-contrast-900 flex-1 whitespace-nowrap'>
              {t("profilePage.dob")}
            </h6>
            <span className='text-base text-contrast-900 font-medium '>
              {traveler.profileDetails.dateOfBirth}
            </span>
          </div>
          <div className='flex gap-1 items-center flex-wrap'>
            <h6 className='text-base text-contrast-900 flex-1 whitespace-normal'>
              {t("profilePage.gender")}
            </h6>
            <span className='text-base text-contrast-900 font-medium capitalize'>
              {gender}
            </span>
          </div>
        </div>
        {email && phoneNumber && (
          <div className='flex flex-col gap-4 pb-6 pt-4 border-b border-contrast-200'>
            <h5 className='text-base text-contrast-900 font-bold'>
              {t("profilePage.contactDetails")}
            </h5>
            <div className='flex gap-1 items-center flex-wrap'>
              <h6 className='text-base text-contrast-900 flex-1 whitespace-nowrap'>
                Email Address
              </h6>
              <span className='text-base text-contrast-900 font-medium truncate'>
                {email}
              </span>
            </div>
            <div className='flex gap-1 items-center flex-wrap'>
              <h6 className='text-base text-contrast-900 flex-1 whitespace-nowrap'>
                Phone Number
              </h6>
              <span className='text-base text-contrast-900 font-medium'>
                {phoneNumber}
              </span>
            </div>
          </div>
        )}
        {!isEmpty(shouldShowPassportDetails) && (
          <div className='flex flex-col gap-4 pb-6 pt-4 border-b border-contrast-200'>
            <h5 className='text-base text-contrast-900 font-bold'>
              {t("profilePage.passportDetails")}
            </h5>
            <div className='flex gap-1 items-center flex-wrap'>
              <h6 className='text-base text-contrast-900 flex-1 whitespace-nowrap'>
                Passport Number
              </h6>
              <span className='text-base text-contrast-900 font-medium '>
                {traveler.passportDetails.passport}
              </span>
            </div>
            <div className='flex gap-1 items-center flex-wrap'>
              <h6 className='text-base text-contrast-900 flex-1 whitespace-nowrap'>
                {t("profilePage.issuingCountry")}
              </h6>
              <span className='text-base text-contrast-900 font-medium '>
                {traveler.passportDetails.issueCountry.name}
              </span>
            </div>
            <div className='flex gap-1 items-center flex-wrap'>
              <h6 className='text-base text-contrast-900 flex-1 whitespace-nowrap'>
                {t("profilePage.issueDate")}
              </h6>
              <span className='text-base text-contrast-900 font-medium '>
                {traveler.passportDetails.issuedDate}
              </span>
            </div>
            <div className='flex gap-1 items-center flex-wrap'>
              <h6 className='text-base text-contrast-900 flex-1 whitespace-nowrap'>
                {t("profilePage.expireDate")}
              </h6>
              <span className='text-base text-contrast-900 font-medium '>
                {traveler.passportDetails.expireDate}
              </span>
            </div>
          </div>
        )}
        {showFareCategoryDocuments && !isEmpty(traveler.document) && (
          <div className='flex flex-col gap-4 pb-6 mb-4 border-b border-contrast-200'>
            <h5 className='text-base text-contrast-900 font-bold'>
              Identity Information
            </h5>
            <div className='flex gap-2 items-center'>
              <h6 className='text-base text-contrast-900 flex-1'>
                {traveler.document.type?.name}
              </h6>
              <span className='text-base text-contrast-900 font-medium '>
                {traveler.document.number}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TravelerDetailsCard;
