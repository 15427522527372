import { createAsyncThunk } from "@reduxjs/toolkit";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { DEFAULT_VALUES, REQUEST_METHODS } from "../../constants";
import asyncAction from "../../infrastructure/asyncAction";

const { COST_CODES, DELETE_COST_CODE } = SPINNER_NAMES;
const { GET, POST, PATCH, DELETE } = REQUEST_METHODS;
const { EMPTY_STRING } = DEFAULT_VALUES;

const createCostCodeParams = (pageSize, pageNumber, searchKey = EMPTY_STRING) => {
  return pageSize && pageNumber || searchKey ? `?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=${searchKey}` : EMPTY_STRING;
}

export const fetchCostCodes = createAsyncThunk(
  "fetch-cost-codes",
  async (queryParams, thunkArgs) => {
    const { pageNumber, pageSize, searchKey, tenantId } = queryParams;
    const params = createCostCodeParams(pageSize, pageNumber, searchKey);
    return await asyncAction({
      url: `tenants/${tenantId}/costCodes${params}`,
      methodType: GET,
      isAuthRequired: true,
      spinner: COST_CODES,
      errorMessage: "Unable to fetch cost code at this moment, please try again later.",
      showErrorToast: true,
      ...thunkArgs,
    });
  }
);

export const createCostCode = createAsyncThunk(
  "create-cost-code",
  async (requestBody, thunkArgs) => {
    const { tenantId } = requestBody;
    return await asyncAction({
      url: `tenants/${tenantId}/costCodes`,
      methodType: POST,
      body: JSON.stringify(requestBody),
      isAuthRequired: true,
      abortOnPageChange: false,
      errorMessage: "Unable to fetch cost code at this moment, please try again later.",
      showErrorToast: true,
      ...thunkArgs,

    })
  }
);

export const getCostCodeById = createAsyncThunk(
  "fetch-cost-code-By-Id",
  async (requestBody, thunkArgs) => {
    const { tenantId, id } = requestBody;
    return await asyncAction({
      url: `tenants/${tenantId}/costCodes/${id}`,
      methodType: GET,
      isAuthRequired: true,
      abortOnPageChange: false,
      errorMessage: "Unable to fetch cost code at this moment, please try again later.",
      showErrorToast: true,
      ...thunkArgs,
    });
  }
);

export const updateCostCode = createAsyncThunk(
  "update-cost-code",
  async (requestBody, thunkArgs) => {
    const { tenantId, id } = requestBody;
    return await asyncAction({
      url: `tenants/${tenantId}/costCodes/${id}`,
      methodType: PATCH,
      body: JSON.stringify(requestBody),
      isAuthRequired: true,
      abortOnPageChange: false,
      errorMessage: "Unable to fetch cost code at this moment, please try again later.",
      showErrorToast: true,
      ...thunkArgs,
    });
  }
);

export const deleteCostCode = createAsyncThunk(
  "delete-cost-code",
  async (requestBody, thunkArgs) => {
    const { tenantId, id } = requestBody
    return await asyncAction({
      url: `tenants/${tenantId}/costCodes/${id}`,
      methodType: DELETE,
      isAuthRequired: true,
      abortOnPageChange: false,
      spinner: DELETE_COST_CODE,
      errorMessage: "Unable to fetch cost code at this moment, please try again later.",
      showErrorToast: true,
      ...thunkArgs,
    });
  }
);
