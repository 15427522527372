import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { get } from "lodash";
import Modal from "../../Modal";
import { getWallet, selectWalletInfo } from "../../../../screens/Account";
import { useCollapse } from "react-collapsed";
import classNames from "classnames";
import {
  MODAL_SIZE,
  DEFAULT_VALUES,
  DEFAULT_CURRENCY_CODE,
  CURRENCY_SYMBOLS,
  PAYMENT_PROVIDERS,
  ERROR_MESSAGES,
  TAVATRIP_FEE,
  DEFAULT_PAYMENT_GATEWAY_CHARGE,
  PLATFORM_FEE,
  PLATFORM_FEE_GST
} from "../../../../constants";
import {
  fetchPaymentProviders,
  selectPaymentProviders,
} from "../../../../screens/Booking/Trips";
import { selectUserInfo } from "../../../../screens/Profile";
import { priceFormatter } from "../../../../helper";
import Spinner, { SPINNER_NAMES } from "../../Spinner";
import {
  Cloud,
  RenderSVG,
  Baggage,
  ChevronDown,
  ChevronUp,
  InfoIcon,
} from "../../../../assets/icons";
import { formatPriceForCurrency } from "../../../../helper/priceFormatter";

const PLATFORMFEE = '1.00'
const { MD } = MODAL_SIZE;
const { ZERO, EMPTY_STRING, SINGLE_SPACE_STRING } = DEFAULT_VALUES;
const { ERROR_WALLET_DISABLED, ERROR_WALLET_BALANCE_LOW } = ERROR_MESSAGES;
const { RAZORPAY, WALLET } = PAYMENT_PROVIDERS;
const { PAYMENT_PROVIDER, PROCESS_PAYMENT, GET_WALLET } = SPINNER_NAMES;
const { DEFAULT_GATEWAY_CHARGE, DEFAULT_GST_GATEWAY_CHARGE } =
  DEFAULT_PAYMENT_GATEWAY_CHARGE;

const PAYMENT_GATEWAY_METHOD_NAME = "PAYMENT GATEWAY";
const WALLET_PAYMENT_PROVIDER = "wallet";
const PAYMENT_GATEWAY_CHARGES =
  process.env.PAYMENT_GATEWAY_CHARGES || DEFAULT_GATEWAY_CHARGE;
const GST_ON_PAYMENT_GATEWAY_CHARGES =
  process.env.GST_ON_PAYMENT_GATEWAY_CHARGES || DEFAULT_GST_GATEWAY_CHARGE;

const PROVIDER_INTIAL_VALUES = { id: EMPTY_STRING, name: EMPTY_STRING };

const PaymentProviderModal = ({ payableAmount, handleOnPay, handleClose }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(selectUserInfo);
  const providers = useSelector(selectPaymentProviders);
  const walletInfo = useSelector(selectWalletInfo);
  const [isPayButtonClicked, setIsPayButtonClicked] = useState(false)
  const [provider, setProvider] = useState(PROVIDER_INTIAL_VALUES);
  const [totalCharges, setTotalCharges] = useState(ZERO);
  const [gatewayCharges, setGatewayCharges] = useState(ZERO);
  const [isPaymentProviderSpinnerActive, setIsPaymentProviderSpinnerActive] =
    useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const currency = get(userInfo, "tenant.currency", DEFAULT_CURRENCY_CODE);
  const currencySymbol = CURRENCY_SYMBOLS[currency];
  const { balance = EMPTY_STRING, isEnabled = false } = walletInfo;

  useEffect(() => {
    if (!payableAmount) return;
    const amountWithTavaTrip = payableAmount + PLATFORM_FEE + PLATFORM_FEE_GST;
    const gatewayCharges = (
      PAYMENT_GATEWAY_CHARGES * amountWithTavaTrip +
      GST_ON_PAYMENT_GATEWAY_CHARGES * amountWithTavaTrip
    ).toFixed(2);
    setGatewayCharges(parseFloat(gatewayCharges));
  }, [payableAmount]);

  useEffect(() => {
    dispatch(fetchPaymentProviders());
    dispatch(getWallet());
  }, []);

  const checkWalletStatus = () => {
    const hasSufficientBalance = payableAmount <= parseFloat(balance);

    if (!isEnabled) setErrorMessage(ERROR_WALLET_DISABLED);
    else if (!hasSufficientBalance) setErrorMessage(ERROR_WALLET_BALANCE_LOW);
  };

  const handleOnSubmit = () => {
    setIsPayButtonClicked(true)
    const paymentGatewayCharges = provider.name === RAZORPAY ? gatewayCharges : 0;
    handleOnPay({ provider, totalCharges, gatewayCharges: paymentGatewayCharges.toString() });
  };

  const handleOnChange = (provider) => {
    setErrorMessage(null);
    let charges = payableAmount + PLATFORM_FEE + PLATFORM_FEE_GST;
    const { name } = provider;
    setProvider(provider);

    if (name === WALLET) checkWalletStatus();
    if (name === RAZORPAY) charges += gatewayCharges;

    const requiredCharges = charges.toFixed(2);
    setTotalCharges(requiredCharges);
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 500,
    defaultExpanded: false,
  });

  return (
    <Modal
      size={MD}
      title='Payment Method'
      shouldShowModalFromProps
      handleClose={handleClose}
      titleClassname='pt-6 px-6'
    >
      <div
        className={classNames({
          "min-h-44 mt-16": isPaymentProviderSpinnerActive,
        })}
      >
        <Spinner
          setIsSpinnerActive={setIsPaymentProviderSpinnerActive}
          name={[PAYMENT_PROVIDER, GET_WALLET]}
        >
          <div>
            <div className='w-full py-6 px-3 sm:px-6 border-t border-contrast-200'>
              <div className='flex flex-col gap-4'>
                {providers.map((item) => {
                  const isWalletInput =
                    item.name.toLowerCase() === WALLET_PAYMENT_PROVIDER;
                  if (item.name === WALLET && !userInfo.isWalletEnabled)
                    return null;
                  return (
                    <div className='checbox-tab' key={item.id}>
                      <label className='cursor-pointer relative'>
                        <input
                          type='radio'
                          className='peer hidden'
                          name='paymentProvider'
                          value={item.name}
                          onChange={() => handleOnChange(item)}
                          checked={provider.id === item.id}
                        />
                        <div
                          className={classNames(
                            "w-4 h-4 shrink-0 rounded-full grid place-content-center border-[5px] absolute left-3 top-1/2 -translate-y-1/2",
                            {
                              "peer-checked:border-primary-600": !errorMessage,
                              "peer-checked:border-red-600":
                                errorMessage && isWalletInput,
                            }
                          )}
                        ></div>
                        <div
                          className={classNames(
                            "flex justify-between gap-1 border-2 p-4 rounded-lg pl-10",
                            {
                              "peer-checked:bg-blue-50 peer-checked:border-primary-600 peer-checked:text-primary-600":
                                !errorMessage,
                              "peer-checked:bg-red-50 peer-checked:border-red-600 peer-checked:text-red-600":
                                errorMessage && isWalletInput,
                            }
                          )}
                        >
                          <h6 className='text-sm font-semibold flex uppercase'>
                            {!isWalletInput
                              ? PAYMENT_GATEWAY_METHOD_NAME
                              : item.name}
                          </h6>
                          {provider.name === WALLET && item.name === WALLET && (
                            <h6 className='text-sm font-semibold text-green-600'>
                              Available balance:{SINGLE_SPACE_STRING}
                              {formatPriceForCurrency(balance, currency)}
                            </h6>
                          )}
                        </div>
                      </label>
                      {provider.id === item.id &&
                        isWalletInput &&
                        errorMessage && (
                          <div className='text-red-600 text-[14px] mt-2'>
                            {errorMessage}
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
              {provider.name && (
                <div className='rounded-lg mb-6'>
                  <div className='px-8 mt-4 gap-2'>
                    <div className='flex justify-between pt-2'>
                      <span>Trip Amount</span>
                      <span>
                        {formatPriceForCurrency(payableAmount , currency)}
                      </span>
                    </div>
                    <ul className='flex flex-col text-sm !divide-y'>
                      <li className='py-3 flex justify-between'>
                        <h4 className='text-contrast-900 text-base'>
                          Other Charges
                        </h4>
                        <div
                          {...getToggleProps()}
                          className='flex items-center gap-1'
                        >
                          <h4>
                            <RenderSVG
                              Svg={isExpanded ? ChevronUp : ChevronDown}
                              className='w-4 h-4'
                              alt={isExpanded ? "Up Icon" : "Down Icon"}
                            />
                          </h4>
                          <h4>
                            {formatPriceForCurrency(
                              PLATFORM_FEE + PLATFORM_FEE_GST +
                                (provider.name === RAZORPAY
                                  ? Number(gatewayCharges)
                                  : 0),
                                  currency
                            )}
                          </h4>
                        </div>
                      </li>
                      <div {...getCollapseProps()}>
                        {provider.name === RAZORPAY && (
                          <li className='flex items-center gap-2  py-3'>
                            <div className='mr-auto flex flex-col'>
                              <span className='text-contrast-900'>
                                Payment Gateway Charges
                              </span>
                            </div>
                            <span className='whitespace-nowrap'>
                              {formatPriceForCurrency(gatewayCharges , currency)}
                            </span>
                          </li>
                        )}
                        <li className='flex items-center gap-2  py-3'>
                          <div className='mr-auto flex flex-col'>
                            <span className='text-contrast-900'>Platform Fee</span>
                          </div>
                          <span className='whitespace-nowrap'>
                            {currencySymbol}&nbsp;
                            {PLATFORMFEE}
                          </span>
                        </li>
                        <li className='flex items-center gap-2  py-3'>
                          <div className='mr-auto flex flex-col'>
                            <span className='text-contrast-900'>GST</span>
                          </div>
                          <span className='whitespace-nowrap'>
                            {currencySymbol}&nbsp;
                            {(PLATFORM_FEE_GST)}
                          </span>
                        </li>
                      </div>
                    </ul>
                    <div className='flex justify-between pt-2 font-semibold'>
                      <span>Total</span>
                      <span>
                        {formatPriceForCurrency(totalCharges , currency)}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className='flex justify-end'>
                <div className='p-6 pt-3 flex gap-4'>
                  <button
                    type='submit'
                    className='w-[150px] text-white font-medium disabled:cursor-not-allowed disabled:bg-primary-500 p-3 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm'
                    onClick={handleOnSubmit}
                    disabled={isPayButtonClicked || errorMessage || !provider.id}
                  >
                    <Spinner name={PROCESS_PAYMENT} size='w-20 h-6'>
                      {totalCharges
                        ? `Pay ${formatPriceForCurrency(totalCharges , currency)}`
                        : "Pay"}
                    </Spinner>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Spinner>
      </div>
    </Modal>
  );
};

export default PaymentProviderModal;
