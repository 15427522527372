import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessionInfo } from "../../screens/session";
import { actions as searchActions } from "../../components/organisms/Search/search.reducer";
import { actions as flightResultsActions } from "../../screens/FlightResults/flightResults.reducer";
import { actions as flightBookingsActions } from "../../screens/Booking/FlightBookings/flightBookings.reducer";
import { actions as hotelBookingActions } from "../../screens/HotelBooking/hotelBooking.reducer";
import { actions as tripDataActions } from "../../screens/Booking/Trips/trips.reducer";
import { actions as spinnerActions } from "../../components/organisms/Spinner/spinner.reducers";
import { actions as hotelInfoActions } from "../../screens/HotelInfo/hotelInfo.reducer";
import { SessionProviderPropTypes } from "../../prop-types";
import {
  selectAuthInfo,
  setAuthInfo,
  setCurrentUserInfo,
  setIsUserAuthenticated,
} from "../../screens/Auth";
import {
  selectCountryInfo,
  selectExchangeRates,
  selectUserInfo,
  setCountryInfo,
  getTravelers,
} from "../../screens/Profile";
import axios from "axios";
import { get, isEmpty } from "lodash";
import Loader from "../../components/organisms/Spinner/Loader";
import SessionExpiredModal from "./SessionExpiredModal";
import {
  getFromLocalStorage,
  setToLocalStorage,
  setToSessionStorage,
  setUserCurrentCountryConfigs,
} from "../../helper";
import {
  CACHE_KEYS,
  ROUTES,
  API_RESPONSES,
} from "../../constants";
import { setModalData } from "../../components/organisms/Modal";

const { AUTH, PERMISSIONS, SESSION_ID, CURRENT_USER_INFO } =
  CACHE_KEYS;
const { SESSION_EXPIRED } = API_RESPONSES;
const { BOOKINGS, FLIGHT_PRICE, FLIGHTS } = ROUTES;

const { setSearch } = searchActions;
const { setFlightResults } = flightResultsActions;
const { setHotelState } = hotelInfoActions;
const { setFlightBooking } = flightBookingsActions;
const { setHotelBook } = hotelBookingActions;
const { setTripData } = tripDataActions;
const secretKey = process.env.REACT_APP_CLIENT_VALIDATION_KEY;

const SessionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countryInfo = useSelector(selectCountryInfo);
  const selectedUserInfo = useSelector(selectUserInfo);
  const authInfo = useSelector(selectAuthInfo)

  const [shouldAllow, setShouldAllow] = useState(!authInfo);
  const [sessionId, setSessionId] = useState();
  const [isExpired, setIsExpired] = useState(false);

  const populateReduxWithSessionData = (
    sessionData,
    sessionParams,
    tripType
  ) => {
    if (tripType === "flight") {
      const { search, pricing, booking, trips, modal } = sessionData;
      const flightPriceReq = pricing?.flightPriceReq;
      const searchFilterURL = search?.searchFilterURL;
      const currentURL = window.location.href;

      if (currentURL.includes(FLIGHT_PRICE) && !flightPriceReq) {
        const flightResultURL = `${FLIGHTS}${searchFilterURL}&sessionId=${sessionParams}`;
        window.location.assign(flightResultURL);
      }

      dispatch(setSearch(search));
      dispatch(setFlightResults(pricing));
      dispatch(setFlightBooking(booking));
      dispatch(setTripData(trips));
      dispatch(setModalData(modal))
    } else {
      const { search, pricing, booking, hotelInfo, hotelBooking, trips, modal } =
      sessionData;
      dispatch(setSearch(search));
      dispatch(setFlightResults(pricing));
      dispatch(setFlightBooking(booking));
      dispatch(setHotelState(hotelInfo));
      dispatch(setHotelBook(hotelBooking));
      dispatch(setTripData(trips));
      dispatch(setModalData(modal))
    }

    setShouldAllow(true);
  };

  const getSessionDetails = (data) => {
    const [id, encodedParentIP] = data.split("_") || [];
    const decodedParentIP = encodedParentIP && atob(encodedParentIP);
    return { decodedParentIP, id, encodedParentIP };
  };

  const fetchSystemIpAndCountryInfo = async (tenant) => {
    let countryCode = tenant?.country || "IN"; // Default to "IN" if no user country
    let ip;

    // Attempt 1: To get system IP
    try {
      const ipInfoRes = await axios.get("https://api.ipify.org/?format=json");
      ip = get(ipInfoRes, "data.ip", "");
    } catch (e) {
      console.log("Unable to get system IP via /api.ipify.org", e);
    }

    // Attempt 2: To get IP & country info
    try {
      const countryInfoRes = await axios.get(`https://api.country.is`);
      if (!ip) ip = get(countryInfoRes, "data.ip", "");

      const fetchedCountryCode = get(countryInfoRes, "data.country", "IN");
      if (fetchedCountryCode !== countryCode) {
        countryCode = tenant?.country || fetchedCountryCode;
      }
      if (countryCode === "USA") {
        countryCode = "US";
      }
      const countryInfo = setUserCurrentCountryConfigs(countryCode, ip);
      dispatch(setCountryInfo(countryInfo));
    } catch (e) {
      console.log(
        "Unable to get IP, countryCode via /api.country.is & /api.ipify.org",
        e
      );
    }
  };

  const handleSessionInfoResponse = (
    res,
    sessionId,
    sessionParams,
    decodedParentIP,
    encodedParentIP,
    currentIp
  ) => {
    const message = get(res, "payload.error", "").toLowerCase().trim();
    const isSessionExpired = message === SESSION_EXPIRED;

    if (isSessionExpired) {
      setIsExpired(true);
      return;
    }

    const { payload } = res;
    if (!payload) return;

    const encodedCurrentIP = btoa(currentIp);
    if (currentIp !== decodedParentIP) {
      const url = new URL(window.location.href);
      url.searchParams.set(SESSION_ID, sessionParams);
      window.history.replaceState(null, null, url);
    }

    const sessionDetails = {
      sessionParams,
      encodedParentIP,
      encodedCurrentIP,
      decodedParentIP,
      sessionId,
    };

    const url = new URL(window.location.href);
    const tripType = url.pathname.includes("hotels") ? "hotel" : "flight";
    const storageKey = tripType === "hotel" ? "hotelsSession" : SESSION_ID;

    setToSessionStorage(storageKey, sessionDetails);
    if (payload) {
      populateReduxWithSessionData(payload, sessionParams, tripType);
    } else {
      sessionStorage.removeItem("sessionId");
      url.searchParams.delete("sessionId");
      window.location.assign(url);
    }
  };

  useEffect(() => {
    const currentUser = getFromLocalStorage(CURRENT_USER_INFO);
    const data = selectedUserInfo?.tenant || currentUser?.tenant;

    const fetchInfo = async (data) => {
      await fetchSystemIpAndCountryInfo(data);
    };
    if (!isEmpty(data) && isEmpty(countryInfo)) fetchInfo(data);
  }, [countryInfo, selectedUserInfo]);

  useEffect(() => {
    if (sessionId) return;
    const searchParams = new URLSearchParams(window.location.search);
    let sessionIdFromURL = searchParams.get(SESSION_ID);

    if (!sessionIdFromURL) {
      const redirectTo = searchParams.get('redirectTo');

      if (redirectTo && authInfo) {
        try {
          const decodedRedirectTo = decodeURIComponent(redirectTo);
          const fullURL = new URL(decodedRedirectTo, window.location.origin);
          const redirectToParams = new URLSearchParams(fullURL.search);
          sessionIdFromURL = redirectToParams.get(SESSION_ID);
        } catch (error) {
          console.error('Invalid redirectTo URL:', error);
        }
      }
    }
    
    if (!sessionIdFromURL) {
      setShouldAllow(true);
    } else {
      setSessionId(sessionIdFromURL);
    }
  }, [authInfo, sessionId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const refreshToken = queryParams.get("refreshToken");
    const accessToken = queryParams.get("accessToken");
    if (!refreshToken || !accessToken) return;
    const decryptedRefreshToken = CryptoJS.AES.decrypt(
      refreshToken,
      secretKey
    ).toString(CryptoJS.enc.Utf8);
    const decryptedAccessToken = CryptoJS.AES.decrypt(
      accessToken,
      secretKey
    ).toString(CryptoJS.enc.Utf8);
    const decryptedId = CryptoJS.AES.decrypt(id, secretKey).toString(
      CryptoJS.enc.Utf8
    );

    const authInfo = {
      id: decryptedId,
      refreshToken: decryptedRefreshToken,
      accessToken: decryptedAccessToken,
    };
    setToLocalStorage(AUTH, authInfo);
    dispatch(setIsUserAuthenticated(!!authInfo.id));
    dispatch(setAuthInfo(authInfo));
    navigate(BOOKINGS);
    dispatch(getTravelers(authInfo.id)).then((res) => {
      const userInfo = {
        ...authInfo,
        ...res.payload,
      };
      setToLocalStorage(CURRENT_USER_INFO, userInfo);
      setToLocalStorage(PERMISSIONS, res?.payload?.permissions);
      dispatch(setCurrentUserInfo(userInfo));
    });
  }, []);

  useEffect(() => {
    const currentUserInfo = getFromLocalStorage(CURRENT_USER_INFO);
    if (currentUserInfo) {
      dispatch(setCurrentUserInfo(currentUserInfo));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!sessionId) return;
    const fetchIpAndSessionDetails = async () => {
      try {
        let currentIp;

        if (countryInfo)
          currentIp = countryInfo.ip;
        else {
          const ipResponse = await axios.get("https://api.ipify.org/?format=json");
          currentIp = ipResponse.data.ip;
        }

        const { decodedParentIP, id, encodedParentIP } = getSessionDetails(sessionId);
        const encodedCurrentIP = btoa(currentIp);
        const sessionParams = `${id}_${encodedCurrentIP}`;

        dispatch(fetchSessionInfo({ id: sessionParams, parentId: sessionId })).then(
          (res) =>
            handleSessionInfoResponse(
              res,
              id,
              sessionParams,
              decodedParentIP,
              encodedParentIP,
              currentIp
            )
        );
      } catch (e) {
        console.log("Unable to get system IP via /api.ipify.org", e);
      }
    };

    fetchIpAndSessionDetails();
  }, [dispatch, sessionId]);

  if (isExpired) {
    return <SessionExpiredModal />;
  }

  if (shouldAllow) {
    return <>{children}</>;
  }

  return (
    <div className='w-full items-center flex justify-center h-screen flex-col gap-3'>
      <Loader size='w-12 h-10' />
      <span className=''>Loading... Please wait!</span>
    </div>
  );
};

SessionProvider.propTypes = SessionProviderPropTypes;

export default SessionProvider;
