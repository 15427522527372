import { useState } from "react";
import { get } from "lodash";
import { FlightIcon, RenderSVG } from "../../../assets/icons";
import ApprovalsModal from "../../../components/organisms/AppModals/ApprovalsModal";
import { MODALS } from "../../../components/organisms/AppModals";
import JourneyDetailsCard from "./JourneyDetailsCard";
import DeleteConfirmationModal from "../../../components/organisms/AppModals/DeleteConfirmationModal";

const { APPROVALS_MODAL, DELETE_CONFIRMATION_MODAL } = MODALS;

const FlightDetails = ({ flightDetails }) => {
  const [selectedModal, setSelectedModal] = useState(null);
  const journeyDetails = get(flightDetails, "journeyDetails", []);
  const approvalTitle = "Confirm Partial Trip Approval";
  const approvalPrimaryButtonText = "Partially Approve Trip";
  const rejectTitle = "Reject Flight";
  const rejectPrimaryButtonText = "Reject";

  const handleClose = () => setSelectedModal(null);

  return (
    <div className='p-4 rounded-lg border border-contrast-200 bg-white grid grid-cols-12 xl:gap-10 lg:gap-8 gap-4 mb-4'>
      <div className='col-span-4'>
        <div className='flex gap-2 items-center mb-4'>
          <div className='icon w-6 h-6 grid place-content-center rounded-full bg-blue-100'>
            <RenderSVG
              Svg={FlightIcon}
              width='16'
              alt={"Flight Icon"}
              className='text-primary-600'
            />
          </div>
          <span className='text-contrast-900 text-lg font-semibold'>Flight</span>
        </div>
        <div className='flex gap-2'>
          <button
            className='flex-1 shadow-sm hover:bg-contrast-50 active:bg-white border-contrast-300 border rounded-md py-2 px-4 text-sm text-contrast-700 font-medium'
            onClick={() => setSelectedModal(DELETE_CONFIRMATION_MODAL)}
          >
            Reject Flight
          </button>
          <button
            className='flex-1 shadow-sm hover:bg-contrast-50 active:bg-white border-contrast-300 border rounded-md py-2 px-4 text-sm text-contrast-700 font-medium'
            onClick={() => setSelectedModal(APPROVALS_MODAL)}
          >
            Approve Flight
          </button>
        </div>
      </div>
      <div className='col-span-8'>
        {journeyDetails.map((journey, index) => (
          <div key={index} className='py-2'>
            <JourneyDetailsCard journey={journey} />
          </div>
        ))}
      </div>
      {selectedModal === APPROVALS_MODAL && (
        <ApprovalsModal
          title={approvalTitle}
          primaryButtonText={approvalPrimaryButtonText}
          handleSubmit={() => {}}
          handleClose={handleClose}
        >
          <div>
            <p>Are you sure you want to partially approve this trip?</p>
            <p>
              This action can not be undone, the trip will be sent back to{" "}
              <strong className='font-bold text-black'>Cody</strong> to
              resubmit.
            </p>
          </div>
        </ApprovalsModal>
      )}
      {selectedModal === DELETE_CONFIRMATION_MODAL && (
        <DeleteConfirmationModal
          title={rejectTitle}
          primaryButtonText={rejectPrimaryButtonText}
          handleSubmit={() => {}}
          handleClose={handleClose}
          iconName={FlightIcon}
        >
          <div>
            <p className='text-sm pb-1'>Rejection Reason</p>
            <textarea className='resize-none w-full h-28 rounded-lg' />
          </div>
        </DeleteConfirmationModal>
      )}
    </div>
  );
};

export default FlightDetails;
