import { useDispatch, useSelector } from "react-redux";
import { LeftIcon, RenderSVG, SlashTicket } from "../../assets/icons";
import { selectSelectedTrip } from "../Booking/Trips";
import { Tooltip } from "react-tooltip";
import HotelDetailSection from "./HotelDetailSection";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { DEFAULT_VALUES } from "../../constants";

import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { selectCountryInfo } from "../Profile";
import { cancelHotelBooking } from "../MyTrips";
import { MODALS } from "../../components/organisms/AppModals";
import { get, isEmpty } from "lodash";
import {
  selectSelectedModal,
  setSelectedModal,
} from "../../components/organisms/Modal";
import RenderCancellationSteps from "./RenderCancellationSteps";
import HotelCancellationModal from "../../components/organisms/AppModals/HotelCancellationModal";

const { ONE, TWO, ZERO, THREE, FOUR } = DEFAULT_VALUES;
const { HOTEL_CANCELLATION } = SPINNER_NAMES;
const { HOTEL_CANCELLATION_MODAL } = MODALS;
const cancellationSteps = [
  {
    step: ONE,
    label: "Select Reason for Cancellation",
    btnText: "View Refunds",
  },
  { step: TWO, label: "View Refunds", btnText: "Cancel Booking" },
];
const OTHER = "Other";

const getBookingAndRefundAmount = (hotelDetails) => {
  const todayDate = new Date();
  const currentTime = todayDate.getTime();
  const finalCharges = hotelDetails.reduce(
    (
      finalCharges,
      { price, dayRates, lastCancellationDate, cancellationPolicies }
    ) => {
      const { discount, publishedPrice, currencyCode } = price;
      finalCharges.discount += discount;
      const totalPrice = publishedPrice;
      finalCharges.currency = currencyCode;
      finalCharges.totalPrice += totalPrice;
      const lastCancellationTime = new Date(lastCancellationDate).getTime();

      if (lastCancellationTime > currentTime) {
        finalCharges.cancellationCharges =
          (finalCharges.cancellationCharges || ZERO) + ZERO;
        return finalCharges;
      }

      cancellationPolicies.forEach(
        ({ charge, toDate, fromDate, chargeType }) => {
          const fromTime = new Date(fromDate).getTime();
          const toTime = new Date(toDate).getTime();
          const isCurrentChargeApplicable =
            fromTime < currentTime && currentTime < toTime;
          if (isCurrentChargeApplicable) {
            let charges;
            switch (chargeType) {
              case ONE:
                charges = charge;
                break;
              case TWO:
                charges = totalPrice * (charge / 100);
                break;
              case THREE:
                charges = (totalPrice / dayRates.length) * charge;
                break;
              default:
                break;
            }
            finalCharges.cancellationCharges =
              (finalCharges.cancellationCharges || ZERO) + charges;
          }
        }
      );
      return finalCharges;
    },
    {
      totalPrice: ZERO,
      currency: "",
      discount: ZERO,
      cancellationCharges: null,
    }
  );
  return finalCharges;
};

const getRemarks = (selectedReason) => {
  const { listedReason, otherReason } = selectedReason;
  if (listedReason !== OTHER) return listedReason;
  return isEmpty(otherReason) ? listedReason : otherReason;
};

const HotelCancellation = () => {
  const [currentStep, setCurrentStep] = useState(ONE);
  const [hotelCancellationStatus, setHotelCancellationStatus] = useState("");
  const [selectedReason, setSelectedReason] = useState({
    listedReason: "",
    otherReason: "",
  });
  const [otherReasonError, setOtherReasonError] = useState(false);
  const errorRef = useRef(null);
  const { bookingId } = useParams();
  const selectedModal = useSelector(selectSelectedModal);
  const dispatch = useDispatch();
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const { ip } = selectedCountryInfo || {};
  useEffect(() => {
    if (otherReasonError && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [otherReasonError]);

  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedTripHotel = selectedTrip?.hotels[0];

  const totalRooms = get(
    selectedTripHotel,
    "bookingReqJson.hotelRoomsDetails.length",
    []
  );
  const amountDetails =
    selectedTripHotel &&
    getBookingAndRefundAmount(
      get(
        selectedTripHotel,
        "blockRoomResJson.blockRoomResult.hotelRoomsDetails",
        []
      )
    );

  const handleCancellation = () => {
    const requestBody = {
      remarks: getRemarks(selectedReason),
      endUserIp: ip,
      bookingId: selectedTripHotel.providerBookingId,
    };
    dispatch(cancelHotelBooking({ requestBody, bookingId })).then((res) => {
      setHotelCancellationStatus("");
      const status = get(res, "payload.changeRequestStatus", ZERO);
      if (!status || status === FOUR) {
        setHotelCancellationStatus("failed");
        return;
      } else if (status === THREE) {
        setHotelCancellationStatus("success");
      } else {
        setHotelCancellationStatus("success");
      }
    });
  };

  const handleNextStep = () => {
    if (
      selectedReason.listedReason === OTHER &&
      isEmpty(selectedReason.otherReason)
    )
      setOtherReasonError(true);
    else
      currentStep === cancellationSteps.length
        ? dispatch(setSelectedModal(HOTEL_CANCELLATION_MODAL))
        : setCurrentStep(currentStep + ONE);
  };

  const handleStepsAndCancellation = (step = ZERO) => {
    if (
      selectedReason.listedReason === OTHER &&
      isEmpty(selectedReason.otherReason)
    )
      setOtherReasonError(true);
    else {
      step !== ZERO
        ? (currentStep !== ONE || selectedReason.listedReason) &&
          setCurrentStep(step)
        : dispatch(setSelectedModal(HOTEL_CANCELLATION_MODAL));
    }
  };

  return (
    <div>
      <header className='relative bg-green-200 '>
        <div className='absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-green-900/50' />
        <div className='container px-8 pt-12 pb-20 mx-auto relative'>
          <div className='grid grid-cols-12 gap-4 max-w-5xl mx-auto'>
            <div className='col-span-12 md:col-span-4 lg:col-span-7'>
              <h4 className='text-xl text-teal-950 mb-1 font-bold'>
                Hotel Cancellation
              </h4>
              <p className='text-contrast-600 text-sm'>
                Reference Number:
                <span className='mx-1 font-semibold text-contrast-900'>
                  {bookingId}
                </span>
              </p>
            </div>
          </div>
        </div>
      </header>
      <main className='pb-16 relative'>
        <div className='container px-4 mx-auto -mt-12'>
          <div className='w-full'>
            <div className='bg-white rounded-lg shadow-lg max-w-5xl flex flex-col justify-center mx-auto min-h-[50vh]'>
              <div className='flex gap-2 px-4 py-2 border-b border-dashed border-gray-300 items-center'>
                <div className='shrink-0'>
                  <RenderSVG
                    Svg={SlashTicket}
                    fill='none'
                    height={20}
                    width={20}
                  />
                </div>
                <div className='flex-1'>
                  <h4 className='text-gray-900 font-bold text-base'>
                    Cancel your stay
                  </h4>
                </div>
              </div>
              <HotelDetailSection />
              <div className='p-4'>
                {" "}
                <div className='flex gap-2 items-center mb-6'>
                  {cancellationSteps.map(({ step, label }) => (
                    <button
                      key={step}
                      onClick={() => handleStepsAndCancellation(step)}
                      className={classNames(
                        "flex gap-2 items-center rounded-3xl p-2 cursor-pointer",
                        {
                          "bg-primary-100 text-primary-700 ring-1 ring-primary-700":
                            step === currentStep,
                          "bg-gray-100 text-gray-900": step !== currentStep,
                          "!cursor-not-allowed":
                            currentStep === ONE &&
                            isEmpty(selectedReason.listedReason) &&
                            step !== ONE,
                        }
                      )}
                    >
                      <span
                        className={classNames(
                          "text-xs rounded-full h-5 w-5 grid place-content-center font-bold",
                          {
                            "bg-primary-700 text-white": step === currentStep,
                            "bg-black/10 text-gray-900": step !== currentStep,
                          }
                        )}
                      >
                        {step}
                      </span>
                      <span className='text-xs font-semibold'>{label}</span>
                    </button>
                  ))}
                </div>
                <RenderCancellationSteps
                  currentStep={currentStep}
                  selectedReason={selectedReason}
                  setSelectedReason={setSelectedReason}
                  totalRooms={totalRooms}
                  amountDetails={amountDetails}
                  otherReasonError={otherReasonError}
                  setOtherReasonError={setOtherReasonError}
                />
                <div className='flex justify-between'>
                  {currentStep > ONE && (
                    <button
                      onClick={() => setCurrentStep(currentStep - ONE)}
                      className='py-3 px-4 rounded-md bg-white hover:bg-primary-100 active:bg-white shadow-sm text-sm text-primary-900 font-semibold flex items-center gap-2'
                    >
                      <RenderSVG Svg={LeftIcon} width='20' height='20' />
                      Back
                    </button>
                  )}
                  <div>
                    {otherReasonError && (
                      <span
                        ref={errorRef}
                        className='text-red-500 text-[10px] md:text-sm'
                      >
                        Please enter a reason for cancellation.
                      </span>
                    )}
                  </div>
                  {isEmpty(selectedReason.listedReason) && (
                    <Tooltip
                      id='cancellation-reason-tooltip'
                      className='!w-56 !sm:w-80 !bg-primary-600 !rounded-lg !z-50 !opacity-75'
                    />
                  )}
                  <button
                    onClick={handleNextStep}
                    className='py-3 px-4 rounded-md bg-primary-600 disabled:bg-primary-400 disabled:cursor-not-allowed hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium'
                    disabled={isEmpty(selectedReason.listedReason)}
                    data-tooltip-id='cancellation-reason-tooltip'
                    data-tooltip-place='bottom'
                    data-tooltip-content='Please select the reason for cancellation first.'
                  >
                    {cancellationSteps[currentStep - ONE]?.btnText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {selectedModal === HOTEL_CANCELLATION_MODAL && (
        <HotelCancellationModal
          handleCancellation={handleCancellation}
          spinnerType={HOTEL_CANCELLATION}
          selectedTripHotel={selectedTripHotel}
          hotelCancellationStatus={hotelCancellationStatus}
        />
      )}
    </div>
  );
};

export default HotelCancellation;
