import { RenderSVG, TicketOutline } from "../../../../assets/icons";

const Ticket = () => {
  return (
    <div className='border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6 print:hidden'>
      <div className='p-6'>
        <h4 className='font-bold text-contrast-900 text-base mb-5'>Tickets</h4>
        <ul className='flex flex-col gap-3'>
          <li>
            <button
              className='flex gap-2 items-center text-sm font-medium text-primary-600 disabled:cursor-not-allowed'
              onClick={() => window.print()}
            >
              <div className='icon'>
                <RenderSVG Svg={TicketOutline} width='20' />
              </div>
              <span>Print Receipt</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Ticket;
