import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { get, cloneDeep, isEmpty, debounce, isEqual } from "lodash";
import {
  selectActiveFilters,
  selectFlights,
  selectFlightSearchOptions,
  selectSearchFilters,
} from "../../../components/organisms/Search";
import {
  DEFAULT_VALUES,
  INITIAL_SORTING_VALUES,
  INDEX,
  DEBOUNCE_TIME,
  TRIP_TYPES,
  FILTERS_FIELDS,
  DEFAULT_CURRENCY_CODE,
  INITIAL_FILTERS,
  DEFAULT_FLIGHT_SEARCH_OPTIONS,
  FLIGHTS_RESULT_TYPE,
} from "../../../constants";
import {
  selectIsInternationalFlight,
  selectRoundTripResultsFormat,
  selectSelectedTripType,
} from "..";
import renderFilters from "../../../components/organisms/Drawer/drawers.helpers";
import getUpdatedFlightFilters from "../../../helper/getUpdatedFlightFilters";
import { actions as filterActions } from "../../../components/organisms/Search/search.reducer";
import { selectCountryInfo } from "../../Profile";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { FIRST, LAST } = INDEX;
const MINUTES_IN_DAY = 1439;
const { ROUND_TRIP, ONE_WAY, MULTI_CITY } = TRIP_TYPES;
const { OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;
const { setFilteredFlights, setActiveFilters, setFlightSearchOptions } =
  filterActions;

const FlightFilters = ({ currentFlightType, resultType }) => {
  const dispatch = useDispatch();
  const totalFlights = useSelector(selectFlights) || EMPTY_OBJECT;
  const tripType = useSelector(selectSelectedTripType);
  const activeFilters = useSelector(selectActiveFilters);
  const prevActiveFiltersRef = useRef(activeFilters);
  const flightSearchOptions = useSelector(selectFlightSearchOptions);
  const roundTripResultFormat = useSelector(selectRoundTripResultsFormat);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedSearchFilter = useSelector(selectSearchFilters);
  const isInternationalFlight = useSelector(selectIsInternationalFlight);

  const { isDirectFlight = false } = selectedSearchFilter || EMPTY_OBJECT;

  const getTime = (requiredPath, defaultValue) =>
    get(activeFilters, `${requiredPath}`, defaultValue);

  useEffect(() => {
    if (isEmpty(activeFilters)) {
      dispatch(setActiveFilters(INITIAL_FILTERS));
      dispatch(setFlightSearchOptions(DEFAULT_FLIGHT_SEARCH_OPTIONS));
    }
  }, [dispatch, activeFilters]);

  const convertToMinutes = (time) => {
    const [hours, minutes] = time.split(":");
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  };

  const getNoOfStops = ({ firstFlight, itenaryIndex = ZERO }) =>
    get(firstFlight, `itineraries[${itenaryIndex}].segments`, []).length - ONE;

  const getFlightTime = (flight, isDepartureTime = true) => {
    const itenaryIndex = isDepartureTime ? ZERO : flight.itineraries.length - 1;
    const segments = flight.itineraries[itenaryIndex].segments;

    const segmentIndex = isDepartureTime ? ZERO : segments.length - 1;
    const location = isDepartureTime ? "departure.time" : "arrival.time";
    const time = get(
      flight,
      `itineraries[${itenaryIndex}].segments[${segmentIndex}].${location}`,
      "ZERO"
    );

    return convertToMinutes(time);
  };

  const getFlightPrice = (flight) =>
    +get(flight, "price.totalPrice", ZERO) + get(flight, "price.tavaMarkup");

  const getLayoverAirports = (flight) => {
    const segments = get(flight, "itineraries[0].segments", EMPTY_ARRAY).slice(
      FIRST,
      LAST
    );
    const stopLocations = [];
    for (const segment of segments) {
      const stopCode = get(segment, "arrival.iataCode", EMPTY_STRING);
      if (stopCode) {
        stopLocations.push(stopCode);
      }
    }

    return stopLocations;
  };

  const getAirlineCode = (flight) =>
    get(flight, "itineraries[0].segments[0].carrierCode", "");

  const getAirlineName = (flight) =>
    get(flight, "itineraries[0].segments[0].carrierName", "");

  const getFilteredFlight = ({ flight = [], filters = {} }) => {
    const firstFlight = flight[ZERO];
    const flightPrice = getFlightPrice(firstFlight);
    const departureTime = getFlightTime(firstFlight, true);
    const arrivalTime = getFlightTime(firstFlight, false);
    const checkItinerary = (itinerary, index) => {
      const {
        stops,
        airlines,
        layoverAirports: filterLayoverAirports,
        departureTime: filterDepartureTime,
        arrivalTime: filterArrivalTime,
        price,
      } = filters;
      const layoverAirports = getLayoverAirports({ firstFlight, itinerary });
      const noOfStops = getNoOfStops({ firstFlight, itenaryIndex: index });
      const hasMatchingStops =
        !isEmpty(stops) && !stops.includes(String(noOfStops));
      const hasMatchingAirline =
        !isEmpty(airlines) && !airlines.includes(getAirlineCode(firstFlight));
      const hasMatchingLayover =
        !isEmpty(filterLayoverAirports) &&
        !filterLayoverAirports.some((airport) =>
          layoverAirports.includes(airport)
        );
      const isWithinDepartureTimeRange = !(
        filterDepartureTime?.minTime <= departureTime &&
        filterDepartureTime?.maxTime >= departureTime
      );
      const isWithinArrivalTimeRange = !(
        filterArrivalTime?.minTime <= arrivalTime &&
        filterArrivalTime?.maxTime >= arrivalTime
      );
      const isAboveMaxPrice =
        price?.maxPrice && +flightPrice > +price?.maxPrice;
      const isBelowMinPrice =
        price?.minPrice && +flightPrice < +price?.minPrice;

      return !(
        hasMatchingStops ||
        hasMatchingAirline ||
        isAboveMaxPrice ||
        isBelowMinPrice ||
        hasMatchingLayover ||
        isWithinDepartureTimeRange ||
        isWithinArrivalTimeRange
      );
    };
    const allItinerariesSatisfy = firstFlight.itineraries.every(checkItinerary);

    return allItinerariesSatisfy;
  };

  const processFlightResults = (flights = []) => {
    return flights?.reduce((values, flight) => {
      const firstFlight = flight[ZERO];
      const noOfStops = getNoOfStops({ firstFlight });
      const price = getFlightPrice(firstFlight);
      const airlineName = getAirlineName(firstFlight);
      const layoverAirport = getLayoverAirports(firstFlight);
      const airlineCode = getAirlineCode(firstFlight);

      values.price.minPrice = values.price.minPrice
        ? Math.min(values.price.minPrice, price)
        : price;

      values.price.maxPrice = values.price.maxPrice
        ? Math.max(values.price.maxPrice, price)
        : price;

      values.stops[noOfStops] = (values.stops[noOfStops] || ZERO) + ONE;
      values.airlines[airlineCode] = {
        count: (values.airlines[airlineCode]?.count || ZERO) + ONE,
        name: airlineName,
      };

      layoverAirport.forEach((airport) => {
        values.layoverAirports[airport] =
          (values.layoverAirports[airport] || ZERO) + ONE;
      });

      return values;
    }, cloneDeep(INITIAL_SORTING_VALUES));
  };

  useEffect(() => {
    if (isEmpty(totalFlights)) return;
    const { isolated = {}, packages = [] } = totalFlights;
    const inboundSortValues = processFlightResults(isolated?.inbound);
    const outboundSortValues = processFlightResults(isolated?.outbound);
    const packagesSortValues = processFlightResults(packages);

    const currencyCode = get(
      selectedCountryInfo,
      "currency.code",
      DEFAULT_CURRENCY_CODE
    );

    if (inboundSortValues) inboundSortValues.price.currency = currencyCode;
    if (outboundSortValues) outboundSortValues.price.currency = currencyCode;
    if (packagesSortValues) packagesSortValues.price.currency = currencyCode;

    dispatch(
      setFlightSearchOptions({
        isolated: {
          inbound: inboundSortValues,
          outbound: outboundSortValues,
        },
        packages: packagesSortValues,
      })
    );
  }, [currentFlightType, tripType, totalFlights]);


  useEffect(() => {
    if (isEmpty(totalFlights) || isEqual(activeFilters, prevActiveFiltersRef.current)) return;
    const { packages = [], isolated = {} } = totalFlights;
    const { inbound = [], outbound = [] } = isolated;
    const { packages: packagesFilters } = activeFilters;
    if (tripType === ONE_WAY || tripType === MULTI_CITY) {
      const filteredFlights = packages?.filter((flight) =>
        getFilteredFlight({
          flight,
          filters: packagesFilters,
        })
      );
      dispatch(setFilteredFlights({ packages: filteredFlights }));
      prevActiveFiltersRef.current = activeFilters;

    } else if (tripType === ROUND_TRIP) {
      const { isolated = {} } = activeFilters;
      const inboundFilters = isolated?.inbound || {};
      const outboundFilters = isolated?.outbound || {};
      const filteredInboundFlights =
        inbound.filter((flight) =>
          getFilteredFlight({
            flight,
            filters: inboundFilters,
          })
        ) || [];
      const filteredOutboundFlights =
        outbound.filter((flight) =>
          getFilteredFlight({
            flight,
            filters: outboundFilters,
          })
        ) || [];
      const filteredPrePackagedFlights = packages?.filter((flight) =>
        getFilteredFlight({
          flight,
          filters: packagesFilters,
        })
      );
      dispatch(
        setFilteredFlights({
          isolated: {
            outbound: filteredOutboundFlights,
            inbound: filteredInboundFlights,
          },
          packages: filteredPrePackagedFlights,
        })
      )
      prevActiveFiltersRef.current = activeFilters;
    };
  }, [activeFilters, totalFlights, currentFlightType, tripType]);

  const handleFilterChange = (e, filter, type) => {
    if (!filter) return;
    const id = e.target.id;
    const value = e.target.value;
    const updatedFilters = { ...filter };

    if (e.target.checked) updatedFilters[id] = [...updatedFilters[id], value];
    else
      updatedFilters[id] = updatedFilters[id].filter(
        (filterValue) => filterValue !== value
      );
    type
      ? dispatch(
        setActiveFilters({
          ...activeFilters,
          isolated: { ...activeFilters.isolated, [type]: updatedFilters },
        })
      )
      : dispatch(
        setActiveFilters({
          ...activeFilters,
          packages: updatedFilters,
        })
      );
  };

  const handleTimeFilterChange = debounce(({ filter, flightType, type }) => {
    const updatedFlightFilters = getUpdatedFlightFilters({
      filters: activeFilters,
      roundTripResultFormat: roundTripResultFormat,
      updatedFilters: filter,
      boundType: flightType,
      type: type,
    });
    dispatch(setActiveFilters(updatedFlightFilters));
  }, DEBOUNCE_TIME);

  const shouldShowCombinedFilters =
    tripType === ONE_WAY || isInternationalFlight;

  return (
    <div
      className={classNames(
        "-start-80 xl:start-0 top-0 flex flex-col border-contrast-200 transition-all ease-in-out duration-300 bg-blue-contrast-50 border-b",
        {
          "bg-white": shouldShowCombinedFilters,
          "gap-5": !shouldShowCombinedFilters,
        }
      )}
    >
      {shouldShowCombinedFilters ? (
        <div className='filter-body flex-1 px-6 divide-y divide-contrast-300 max-h-screen overflow-y-auto no-scrollbar w-full'>
          {FILTERS_FIELDS.map((each) => {
            const { label, interface: filterInterface } = each;
            return (
              <div key={label}>
                {renderFilters({
                  interfaceType: filterInterface,
                  label,
                  flightSearchOptions: flightSearchOptions?.packages,
                  activeFiltersSearchOptions: activeFilters?.packages,
                  handleFilterChange: (e) =>
                    handleFilterChange(e, activeFilters?.packages),
                  handleTimeFilterChange,
                  minTime: getTime(`packages.${label}.minTime`, ZERO),
                  maxTime: getTime(`packages.${label}.maxTime`, ZERO),
                  tripType,
                  isDirectFlight,
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <>
          <div className='flex-1 bg-white w-full'>
            {resultType === OUTBOUND_RESULT ? (
              <div className='filter-body flex-1 overflow-y-auto px-6 divide-y divide-contrast-300 scrollbar-light'>
                {FILTERS_FIELDS.map((each) =>
                  renderFilters({
                    interfaceType: each.interface,
                    label: each.label,
                    handleFilterChange: (e) =>
                      handleFilterChange(
                        e,
                        activeFilters?.isolated?.outbound,
                        "outbound"
                      ),
                    activeFiltersSearchOptions:
                      activeFilters?.isolated?.outbound,
                    handleTimeFilterChange,
                    minTime: get(
                      activeFilters,
                      `isolated.outbound.${each.label}.minTime`,
                      ZERO
                    ),
                    maxTime: get(
                      activeFilters,
                      `isolated.outbound.${each.label}.maxTime`,
                      MINUTES_IN_DAY
                    ),
                    flightSearchOptions:
                      flightSearchOptions?.isolated?.outbound,
                    flightType: "outbound",
                    isDirectFlight,
                  })
                )}
              </div>
            ) : (
              <div className='filter-body flex-1 overflow-y-auto px-6 divide-y divide-contrast-300 scrollbar-light w-full'>
                {FILTERS_FIELDS.map((each) =>
                  renderFilters({
                    interfaceType: each.interface,
                    label: each.label,
                    activeFiltersSearchOptions:
                      activeFilters?.isolated?.inbound,
                    handleFilterChange: (e) =>
                      handleFilterChange(
                        e,
                        activeFilters?.isolated?.inbound,
                        "inbound"
                      ),
                    handleTimeFilterChange,
                    minTime: get(
                      activeFilters,
                      `isolated.inbound.${each.label}.minTime`,
                      ZERO
                    ),
                    maxTime: get(
                      activeFilters,
                      `isolated.inbound.${each.label}.maxTime`,
                      MINUTES_IN_DAY
                    ),
                    flightSearchOptions: flightSearchOptions?.isolated?.inbound,
                    flightType: "inbound",
                    isDirectFlight,
                  })
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FlightFilters;
