import { createSlice } from "@reduxjs/toolkit";
import {
  getAmadeusRefundCharges,
  getTBORefundCharges,
  getFlightCancellationCharges,
  processRefund,
  processPayment,
  getUserBookingInfo,
} from "./booking.actions";
import { DEFAULT_VALUES } from "../../constants";

const { EMPTY_OBJECT, EMPTY_ARRAY } = DEFAULT_VALUES;

const initialState = {
  cancellationChargesAM: EMPTY_OBJECT,
  cancellationChargesTBO: EMPTY_OBJECT,
  processRefundResponse: EMPTY_OBJECT,
  selectedBooking: EMPTY_OBJECT,
  processPaymentResponse: EMPTY_OBJECT,
  userBookingInformation: EMPTY_OBJECT,
  isGenerateVoucherInitiated: false,
  cancellationCharges: EMPTY_ARRAY,
};

const slice = createSlice({
  name: "mytrip",
  initialState,
  reducers: {
    setSelectedBooking: (state, action) => {
      state.selectedBooking = action.payload;
    },
    setUserBookingInformation: (state, action) => {
      state.userBookingInformation = action.payload;
    },
    setIsGenerateVoucherInitiated: (state, action) => {
      state.isGenerateVoucherInitiated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAmadeusRefundCharges.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.cancellationChargesAM = action.payload;
      })
      .addCase(getTBORefundCharges.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.cancellationChargesTBO = action.payload;
      })
      .addCase(processRefund.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.processRefund = action.payload;
      })
      .addCase(processPayment.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.processPaymentResponse = action.payload;
      })
      .addCase(getUserBookingInfo.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.userBookingInformation = action.payload;
      })
      .addCase(getFlightCancellationCharges.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.cancellationCharges = action.payload;
      });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
