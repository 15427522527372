import { createSlice } from "@reduxjs/toolkit";
import { isEmpty, get } from "lodash";
import {
  regenerateAccessToken,
  invitationDetails,
  login,
  acceptInvitation,
  fetchTenantDetails,
} from "./auth.actions";
import { setToLocalStorage } from "../../helper";
import { AUTH_DATA } from "../../constants";
import config from "../../../src/config.json";

const { AUTH } = AUTH_DATA;

const initialState = {
  authInfo: null,
  currentUserInfo: null,
  invitationId: "",
  invitationDetails: {},
  isUserAuthenticated: false,
  tenantDetails: { tenantConfig: config },
  isSidePanelCollapsed: false
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo(state, action) {
      state.authInfo = action.payload;
    },
    setCurrentUserInfo(state, action) {
      state.currentUserInfo = action.payload;
    },
    setInvitationId(state, action) {
      state.invitationId = action.payload;
    },
    setIsUserAuthenticated(state, action) {
      state.isUserAuthenticated = action.payload;
    },
    setIsSidePanelCollapsed(state, action) {
      state.isSidePanelCollapsed = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(regenerateAccessToken.fulfilled, (state, action) => {
      if (!action.payload) return;
      const tokens = action.payload;
      const newAccessToken = tokens.accessToken;
      const newRefreshToken = tokens.refreshToken;
      const updatedAuthDetails = state.authInfo && {
        ...state.authInfo,
        accessToken: newAccessToken,
        refreshToken: newRefreshToken,
      };
      state.authInfo = updatedAuthDetails;
      setToLocalStorage(AUTH, updatedAuthDetails);
    });
    builder.addCase(invitationDetails.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.invitationDetails = action.payload;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.currentUserInfo = action.payload;
    });
    builder.addCase(acceptInvitation.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.currentUserInfo = action.payload;
    });
    builder.addCase(fetchTenantDetails.fulfilled, (state, action) => {
      const hostName = get(action, "meta.arg.hostName", null);
      if(!hostName) return
      if (isEmpty(action.payload) || !action.payload) return;
      state.tenantDetails = action.payload;
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
