import { useSelector } from "react-redux";
import { selectCountryInfo } from "../screens/Profile";
import { get } from "lodash";
import { CURRENCY_SYMBOLS } from "../constants";
const { INR } = CURRENCY_SYMBOLS;
export const generatePolicyViolationMessage = ({
  trip,
  costPolicyViolation,
  ratingPolicyViolation,
}) => {
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currency = get(selectedCountryInfo, "currency.symbol", INR);
  if (!costPolicyViolation && !ratingPolicyViolation) return null;

  const generateMessage = ({ violationType, violatingDifference }, type) => {
    if (violatingDifference <= 0) return;
    const formattedAmount = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(violatingDifference);
    const policy =
      violationType.toLowerCase() === "min"
        ? "below the minimum"
        : "above the maximum";

    const amount = type === "rating" ? `${violatingDifference} star` : `${currency}${formattedAmount}`;
    return `The ${trip} ${type} is ${policy} policy by ${amount}`;
  };

  let messages = [];
  
  if (costPolicyViolation) {
    messages.push(generateMessage(costPolicyViolation, "cost"));
  }
  
  if (ratingPolicyViolation) {
    messages.push(generateMessage(ratingPolicyViolation, "rating"));
  }

  return messages.join(" and ");
};
