import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce, get, isEmpty } from "lodash";
import {
  fetchCostCodes,
  createCostCode,
  updateCostCode,
  deleteCostCode,
  setcostCodes
} from "./index";
import { selectSelectedCostCodes } from "./cost.selector";
import { checkIfUserHasPermission, getFormattedDate } from "../../helper";
import { selectCurrentUserInfo } from "../Auth";
import {
  DEFAULT_VALUES,
  AVAILABLE_PERMISSIONS,
  ACTION_MODAL_TYPES,
  DEFAULT_LIMIT,
} from "../../constants";
import { cancelAllAPIRequests } from "../../infrastructure/httpMethods/requestingMethods";
import { selectUserInfo } from "../Profile";
import Spinner, {
  ProgressBar,
  SPINNER_NAMES,
} from "../../components/organisms/Spinner";
import {
  RenderSVG,
  PlusIcon,
  SearchIconGray,
  Trash,
  EditIcon,
} from "../../assets/icons";
import DeleteConfirmationModal from "../../components/organisms/AppModals/DeleteConfirmationModal";
import ManageCostCodeModal from "../../components/organisms/AppModals/ManageCostCodeModal/ManageCostCodeModal";
import EditCostCodeModel from "../../components/organisms/AppModals/ManageCostCodeModal/EditCostCodeModel";
import Pagination from "../../components/organisms/Pagination";
import EmptyLayout from "../../components/molecules/EmptyLayout";
import classNames from "classnames";
import Table from "../../components/molecules/Table/Table";

const showDepartmentVisibility = false;
const { COST_CODES, DELETE_COST_CODE } = SPINNER_NAMES;
const { EDIT_MODAL, CREATE_MODAL, DELETE_MODAL } = ACTION_MODAL_TYPES;
const {
  CAN_VIEW_COST_CODES,
  CAN_CREATE_COST_CODES,
  CAN_DELETE_COST_CODES,
  CAN_MANAGE_COST_CODES,
} = AVAILABLE_PERMISSIONS;
const { EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY, ONE, TWO, THREE_HUNDRED } =
  DEFAULT_VALUES;

const availableDepartments = [
  { name: "Human Resources" },
  { name: "Finance" },
  { name: "Product Management" },
  { name: "Support" },
  { name: "Engineering" },
  { name: "Marketing" },
  { name: "Sales" },
  { name: "Operations" },
];

const EMPTY_LAYOUT = {
  TITLE: "No Record Found",
  SUBTITLE: "Try to search another cost code ",
};

const CostCodes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const costCodes = useSelector(selectSelectedCostCodes);
  const selectedCurrentUserInfo = useSelector(selectUserInfo);
  const userInfo = useSelector(selectCurrentUserInfo);
  const filteredCostCodes = costCodes.result || EMPTY_ARRAY;
  const availablePermissions = get(
    selectedCurrentUserInfo,
    "permissions",
    EMPTY_OBJECT
  );
  const page = Number(costCodes?.countInfo?.currentPage) || ONE;

  const [searchValue, setSearchValue] = useState(EMPTY_STRING);
  const [selectedCostCodeInfo, setSelectedCostCodeInfo] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [paginationDetails, setPaginationDetails] = useState(EMPTY_OBJECT);
  const [showPagination, setShowPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(page);
  const [isSpinnerActive, setIsSpinnerActive] = useState(true);
  const pageSize = 10;

  useEffect(() => {
    setCurrentPage(ONE);
    const queryParams = {
      pageSize: DEFAULT_LIMIT,
      pageNumber: ONE,
      tenantId: userInfo.tenantId,
    };
    dispatch(fetchCostCodes(queryParams));
  }, []);

  useEffect(() => {
    const { countInfo } = costCodes;
    setShowPagination(countInfo?.count > pageSize);
    setPaginationDetails({ ...countInfo, currentPage: currentPage });
  }, [costCodes, currentPage, filteredCostCodes]);

  const debouncedGetCostCodes = useCallback(
    debounce((searchKey, pageNumber, scrollIntoView) => {
      if (scrollIntoView)
        document
          .getElementById("costCode-container")
          ?.scrollIntoView({ behavior: "smooth" });
      cancelAllAPIRequests();
      dispatch(
        fetchCostCodes({
          pageNumber,
          pageSize: DEFAULT_LIMIT,
          searchKey,
          tenantId: userInfo.tenantId,
        })
      );
    }, THREE_HUNDRED),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setCurrentPage(ONE);
    debouncedGetCostCodes(value, ONE);
  };

  const handleCloseModal = () => setSelectedModal(null);

  const getMenuWithPermissions = () => {
    const canEditCostCodes = checkIfUserHasPermission(
      CAN_MANAGE_COST_CODES,
      availablePermissions
    );
    const canDeleteCostCodes = checkIfUserHasPermission(
      CAN_DELETE_COST_CODES,
      availablePermissions
    );
    const menuOptions = [
      {
        id: ONE,
        label: <RenderSVG Svg={EditIcon} />,
        onClick: () => setSelectedModal(EDIT_MODAL),
      },
      {
        id: TWO,
        label: <RenderSVG Svg={Trash} />,
        onClick: () => setSelectedModal(DELETE_MODAL),
      },
    ];
    const res = canEditCostCodes && canDeleteCostCodes;

    if (res) return menuOptions;
    else if (canEditCostCodes) return [menuOptions[0]];
    else if (canDeleteCostCodes) return [menuOptions[1]];
    else return null;
  };
  const menuOptions = getMenuWithPermissions();

  const handleCreateCostCode = (values) => {
    const reqPayload = {
      name: values.name?.trim(),
      costCode: values.costCode?.trim(),
      description: values.description?.trim(),
      tenantId: userInfo.tenantId,
    };
    dispatch(createCostCode(reqPayload));
    handlePagination(ONE);
    handleCloseModal();
  };

  const handleSaveCostCode = (values) => {
    const reqPayload = {
      tenantId: values.tenantId,
      id: values.id,
      name: values.name?.trim(),
      costCode: values.costCode?.trim(),
      description: values.description?.trim(),
    };
    values.updatedAt = new Date().toISOString();
    const costCodeCountInfo = costCodes.countInfo
    const updatedCostCodes = [...costCodes.result];
    const updatedCostCodeIndex = updatedCostCodes.findIndex((costCodeData) => costCodeData.id === values.id);
    if (updatedCostCodeIndex !== -1) {
      updatedCostCodes[updatedCostCodeIndex] = {
        ...updatedCostCodes[updatedCostCodeIndex],
        ...values,
      };
    }
    dispatch(setcostCodes({countInfo: costCodeCountInfo, result: updatedCostCodes}));
    dispatch(updateCostCode(reqPayload)).then(() => fetchCostCodes());
    handleCloseModal();
  };

  const handleDeleteCostCode = () => {
    const reqPayload = {
      id: selectedCostCodeInfo.id,
      tenantId: selectedCostCodeInfo.tenantId,
    };
    dispatch(deleteCostCode(reqPayload));
    if(costCodes.countInfo.count == 1)
      handlePagination(ONE);
    else if((costCodes.countInfo.count % 10) == 1)
      handlePagination(currentPage-1);
    else
      handlePagination(currentPage);
    handleCloseModal();
  };

  const handlePagination = (currentPage) => {
    setCurrentPage(currentPage);
    debouncedGetCostCodes(searchValue, currentPage);
  };

  const openCostCodeModal = () => {
    setSelectedModal(CREATE_MODAL);
  };

  const renderSelectedModal = () =>
    ({
      [CREATE_MODAL]: (
        <ManageCostCodeModal
          title='Create Cost Code'
          primaryButtonText='Create'
          handleClose={handleCloseModal}
          handleSubmit={handleCreateCostCode}
          availableDepartments={availableDepartments}
        />
      ),
      [EDIT_MODAL]: (
        <EditCostCodeModel
          title='Manage Cost Code'
          handleClose={handleCloseModal}
          handleSubmit={handleSaveCostCode}
          savedInitialValues={selectedCostCodeInfo}
          availableDepartments={availableDepartments}
        />
      ),
      [DELETE_MODAL]: (
        <DeleteConfirmationModal
          title='Delete Cost Code?'
          handleClose={handleCloseModal}
          handleSubmit={handleDeleteCostCode}
          spinnerName={DELETE_COST_CODE}
        >
          <div className='flex flex-col gap-5 text-sm text-contrast-600'>
            <div>
              Are you sure you want to delete cost code{" "}
              <strong>{selectedCostCodeInfo?.code} </strong>
            </div>
            <div>This action can not be undone.</div>
          </div>
        </DeleteConfirmationModal>
      ),
    }[selectedModal]);
    const CodeFormatter = ({ row }) => {
      const { costCode } = row;
      return (
          <div className="">
              <h6 className='font-medium text-contrast-900 text-sm'>{costCode}</h6>
          </div>
      );
  };
  const NameFormatter = ({ row }) => {
      const { name } = row;
      return (
          <div className="">
              <h6 className='font-medium text-contrast-900 text-sm'>{name}</h6>
          </div>
      );
  };
  const DescriptionFormatter = ({ row }) => {
      const { description } = row;
      return (
          <div className="">
              <h6 className='font-medium text-contrast-900 text-sm'>{description}</h6>
          </div>
      );
  };
  const CreatedAtFormatter = ({ row }) => {
    const { createdAt } = row;
    return (
      <div className=''>
        <h6 className='font-medium text-contrast-900 text-sm'>
          {" "}
          {new Date(createdAt).toDateString()}
        </h6>
      </div>
    );
  };
  const UpdatedAtFormatter = ({ row }) => {
    const { updatedAt } = row;
    return (
      <div className=''>
        <h6 className='font-medium text-contrast-900 text-sm'>
          {" "}
          {new Date(updatedAt).toDateString()}
        </h6>
      </div>
    );
  };
  const DepartmentVisibilityFormatter = () => {
    {
      showDepartmentVisibility && (
        <td className='border border-contrast-200 px-4 py-4 '>
          <div className='flex gap-2'>
            {departmentVisibility.map((dept) => (
              <div
                key={dept}
                className='rounded-2xl py-0.5 px-[10px] bg-contrast-100 text-contrast-800 text-xs font-medium inline-flex items-center gap-[6px]'
              >
                <span>{dept}</span>
              </div>
            ))}
          </div>
        </td>
      );
    }
  };
  const ActionFormatter = (rowData) => {
    return (
      <div className='flex justify-around'>
        {menuOptions &&
          menuOptions.map(({ id, label, onClick }) => (
            <button
              key={id}
              type='button'
              className={classNames(
                "text-start p-3 h-50 w-50 text-base leading-6 hover:bg-contrast-200 rounded-full"
              )}
              onClick={() => {
                setSelectedCostCodeInfo(rowData.row);
                onClick();
              }}
            >
              {label}
            </button>
          ))}
      </div>
    );
  };

  const DEFAULT_COLUMNS = [
    {
      text: "Code",
      formatter: <CodeFormatter />,
    },
    {
      text: "Name",
      formatter: <NameFormatter />,
    },
    {
      text: "Description",
      formatter: <DescriptionFormatter />,
    },
    {
      text: "Create Date",
      formatter: <CreatedAtFormatter />,
    },
    {
      text: "Updated Date",
      formatter: <UpdatedAtFormatter />,
    },
    {
      text: "",
      formatter: <ActionFormatter />,
    },
    // {
    //     text: "",
    //     formatter: <DepartmentVisibilityFormatter />
    // },
  ];
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);
  const [rowData, setRowData] = useState(EMPTY_ARRAY);

  useEffect(() => {
    const formattedCostCodesDetails = filteredCostCodes.map((costCodesInfo) => {
      const {
        id,
        tenantId,
        costCode,
        name,
        description,
        createdAt,
        updatedAt,
        departmentVisibility
      } = costCodesInfo;
      const requiredDetails = {
        id,
        tenantId,
        costCode,
        name,
        description,
        createdAt,
        updatedAt
      };
      return requiredDetails;
    });
    setRowData(formattedCostCodesDetails);
  }, [filteredCostCodes]);

  return (
    checkIfUserHasPermission(CAN_VIEW_COST_CODES, availablePermissions) && (
      <div className='main flex-1 flex flex-col bg-white h-full'>
        {renderSelectedModal()}
        <div
          id='costCode-container'
          className='shadow-md p-4 px-6 flex gap-8 bg-white'
        >
          <div className='flex-1 relative'>
            <RenderSVG
              Svg={SearchIconGray}
              alt='Filter Icon'
              className='absolute top-1/2 -translate-y-1/2 left-3'
              width='20'
            />
            <input
              type='text'
              className='form-control block w-full text-sm pl-10 py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500 focus:border-primary-600 focus:ring-primary-600'
              value={searchValue}
              onChange={handleInputChange}
              placeholder='Search Cost Code / Cost Code Name'
            />
          </div>
          {checkIfUserHasPermission(
            CAN_CREATE_COST_CODES,
            availablePermissions
          ) && (
            <div className='relative'>
              <button
                onClick={openCostCodeModal}
                className='pl-[15px] pr-[17px] py-[9px] text-sm text-white font-medium leading-tight bg-primary-600 rounded-md shadow justify-center items-center gap-2 flex cursor-pointer'
              >
                <RenderSVG Svg={PlusIcon} />
                Create Cost Code
              </button>
            </div>
          )}
        </div>
        <ProgressBar name={!isEmpty(filteredCostCodes) && COST_CODES} />
        <div className='shadow-md flex-1 overflow-auto'>
          <div className='flex w-full rounded-md overflow-auto relative'>
            <div className='w-full overflow-auto bg-white'>
              <Spinner
                name={COST_CODES}
                message={t("costCodes.spinnerMessage")}
                spinnerClassName='w-full py-[32vh]'
                setIsSpinnerActive={setIsSpinnerActive}
              >
                {filteredCostCodes?.length ? (
                  <Table columnDefs={columns} rowData={rowData} />
                ) : (
                  <EmptyLayout
                    title={EMPTY_LAYOUT.TITLE}
                    subTitle={EMPTY_LAYOUT.SUBTITLE}
                  />
                )}
              </Spinner>
            </div>
          </div>
        </div>
        {!isSpinnerActive && (
          <Pagination
            data={paginationDetails}
            setCurrentPage={handlePagination}
          />
        )}
      </div>
    )
  );
};

export default CostCodes;
