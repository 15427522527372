import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, Tooltip, Legend, ArcElement } from "chart.js";
import { DEFAULT_VALUES } from "../../constants";

const { ZERO, ONE, TWO, FIVE } = DEFAULT_VALUES
Chart.register(Tooltip, Legend, ArcElement);

const DonutChart = ({data}) => {
  const filteredData = data.filter((item) => item.TotalSpent > 0);
  const sortedData = [...filteredData].sort((a, b) => b.TotalSpent - a.TotalSpent);
  const topFive = sortedData.slice(0, 5);
  const others = sortedData.slice(5);

  const otherTotalSpent = others.reduce((acc, curr) => acc + curr.TotalSpent, 0);
  const chartLabels = topFive.map((item) => item.Name);
  const chartDataValues = topFive.map((item) => item.TotalSpent);
  
  if (otherTotalSpent > 0) {
    chartLabels.push("Others");
    chartDataValues.push(otherTotalSpent);
  }
  const isSingleLabel = chartLabels.length === ONE;
  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        data: chartDataValues,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#C9CBCF",
        ],
        hoverBackgroundColor: [
          "#FF4F3A",
          "#357ABD",
          "#F48C06",
          "#3AE3B2",
          "#8E44AD",
          "#AAB7B8",
        ],
        hoverOffset: isSingleLabel ? ZERO : FIVE,
        borderWidth: isSingleLabel ? ZERO : TWO, 
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
        external: function(context) {
          let tooltipEl = document.getElementById('chartjs-tooltip');
  
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.color = 'white';
            tooltipEl.style.padding = '5px';
            tooltipEl.style.borderRadius = '4px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'opacity 0.2s ease, transform 0.1s ease';
            tooltipEl.style.width = '120px'; 
            tooltipEl.style.fontSize = '10px'; 
            tooltipEl.style.lineHeight = '1.2'; 
            tooltipEl.style.overflowWrap = 'break-word';
            tooltipEl.style.opacity = 0; 
            document.body.appendChild(tooltipEl);
          }
  
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }
  
          if (tooltipModel.body) {
            const index = tooltipModel.dataPoints[0].dataIndex;
            const titleLines = tooltipModel.title || [];
            let innerHtml = '<strong>' + titleLines.join(' ') + '</strong><br>';
  
            if (index < 5) {
              const item = topFive[index];
              innerHtml += `Total Bookings: ${item.TotalBooking}<br>`;
              innerHtml += `Total Spent: ${item.TotalSpent.toLocaleString()}`;
            } else {
              innerHtml += `Total Spent: ${otherTotalSpent.toLocaleString()}`;
            }
  
            tooltipEl.innerHTML = innerHtml;
          }
          context.chart.canvas.addEventListener('mousemove', function(event) {
            const canvasPosition = context.chart.canvas.getBoundingClientRect();
            const offsetX = event.clientX - canvasPosition.left;
            const offsetY = event.clientY - canvasPosition.top;

            tooltipEl.style.left = `${event.clientX}px`;
            tooltipEl.style.top = `${event.clientY - tooltipEl.offsetHeight - 10}px`;
            tooltipEl.style.transform = 'translate(-50%, 0)';
          });
          tooltipEl.style.opacity = 1;
          tooltipEl.style.transform = 'translate(-50%, 0)';
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 20,
          padding: 15,
          usePointStyle: true,
          font: {
            size: 14,
          },
          textAlign: 'left',          
        },
        onHover: function (event, legendItem) {
          document.body.style.cursor = 'pointer';
        },
        onLeave: function () {
          document.body.style.cursor = 'default';
        },
      },
    },
    cutout: "60%",
  };


  return (
    <div className="flex justify-center h-full p-6">
      <div className="relative w-full h-full max-w-[600px] max-h-[400px]">
        <Doughnut data={chartData} options={options} />
      </div>
    </div>
  );
};

export default DonutChart;
