import { isEmpty } from "lodash";

export const getFormattedAddress = (address = "") => {
  if (address === null) return "";
  
  address = address.replace(/,\s*/g, ", ");
  address = address.trim();
  
  if(address.startsWith(', '))
  address = address.slice(2);
  
  if(address.endsWith(','))
  address = address.slice(0, -1);
  
  return address;
};

export const getFormattedHotelAddress = (address = []) => {
  if (isEmpty(address)) return "";
  return `${address.join(", ")}.`;
};
