import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { DEFAULT_VALUES, WINDOWS_EVENTS } from "../../../constants";
import { Check, Home, RenderSVG } from "../../../assets/icons";

const { ONE, SIX } = DEFAULT_VALUES;
const ROOM = "1 Room";
const NO_OF_ROOMS = "noOfRooms";
const MAX_ROOM_CAN_BOOK = SIX;
const { CLICK } = WINDOWS_EVENTS;

const HotelRooms = () => {
  const { values, setFieldValue } = useFormikContext();
  const [noOfRooms, setNoOfRooms] = useState(ROOM);
  const [selectedNoOfRooms, setSelectedNoOfRooms] = useState(ROOM);
  const [show, setShow] = useState(false);
  const travelClassRef = useRef();
  const { t } = useTranslation();

  const handleTravelClassChange = (value) => {
    setFieldValue(NO_OF_ROOMS, value);
    setFieldValue("adult", value);
  };

  const roomsOptions = Array.from({ length: MAX_ROOM_CAN_BOOK }).map(
    (_, index) => ({
      id: index,
      label: `${index + 1} Room${index !== 0 ? "s" : ""}`,
      value: (index + ONE).toString(),
    })
  );

  useEffect(() => {
    const roomlabel = roomsOptions.find(
      ({ value }) => values.noOfRooms === value
    )?.label;
    setNoOfRooms(roomlabel || ROOM);
    setSelectedNoOfRooms(values.noOfRooms);
  }, [values.noOfRooms]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (travelClassRef.current) {
        if (!travelClassRef.current.contains(e.target)) {
          setShow(false);
        }
      }
    };
    document.addEventListener(CLICK, checkIfClickedOutside);

    return () => {
      document.removeEventListener(CLICK, checkIfClickedOutside);
    };
  }, [travelClassRef.current]);

  return (
    <div
      className='col-span-12 md:col-span-6 lg:col-span-2 text-black'
      ref={travelClassRef}
    >
      <div className='relative'>
        <button
          type='button'
          onClick={() => setShow(true)}
          className='rounded-lg w-full hover:ring-2 hover:ring-primary-50 bg-white border shadow-sm border-contrast-300 flex items-center gap-2 py-3 px-3'
        >
          <RenderSVG Svg={Home} alt='Ticket Icon' />
          <span className='text-contrast-900 whitespace-nowrap text-ellipsis overflow-hidden'>
            {t(noOfRooms)}
          </span>
        </button>
        {show && (
          <div className='absolute z-30 top-full bg-white py-4 rounded-lg border border-contrast-200 shadow-2xl mt-2 w-60  left-0'>
            <ul className='flex flex-col gap-2'>
              {roomsOptions.map(({ id, label, value }) => (
                <li
                  key={id}
                  onClick={() => {
                    handleTravelClassChange(value);
                    setShow(false);
                  }}
                >
                  <span className='flex gap-3 px-6 py-2 cursor-pointer'>
                    <span
                      className={classNames({
                        "font-semibold !flex": selectedNoOfRooms === value,
                      })}
                    >
                      {t(label)}
                    </span>
                    <RenderSVG
                      Svg={Check}
                      alt='Checkmark'
                      className={classNames("ms-auto hidden", {
                        "!flex": selectedNoOfRooms === value,
                      })}
                    />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotelRooms;
