import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, get, set } from "lodash";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Modal from "../../../organisms/Modal";
import { DEFAULT_VALUES, POLICY_CATAGORY } from "../../../../constants";
import Spinner, { SPINNER_NAMES } from "../../Spinner";
import {
  getPolicies,
  updatePolicies,
  selectAllServicesPolicy,
  selectSelectedServicePolicyId,
} from "../../../../screens/Policies";
import AirlinePolicyManager from "./AirlinePolicyManager";
import CostPolicyManager from "./CostPolicyManager";
import RatingPolicyManager from "./RatingPolicyManager";

const { UPDATE_POLICY, GET_POLICY } = SPINNER_NAMES;
const { EMPTY_OBJECT, EMPTY_ARRAY, ONE, STRING } = DEFAULT_VALUES;
const { COST, AIRLINES, RATING } = POLICY_CATAGORY;
const DEFAULT_INITIAL_VALUE = { data: EMPTY_ARRAY, isEnabled: false };
const MIN = "min";
const RATINGS = "Rating";
const COSTS = "Cost";
const ALLOWED = "allowed";

const createRequest = (
  values,
  selectedServicePolicy,
  policyName,
  policyType
) => {
  const payload = {
    ...selectedServicePolicy.policies,
    [policyName]: {
      ...selectedServicePolicy.policies[policyName],
      [policyType]: values,
    },
  };
  return payload;
};

const RenderSelectedPolicyDetails = ({
  policyName,
  policyType,
  policyData,
}) => {
  switch (policyName) {
    case COST:
      return (
        <CostPolicyManager policyName={policyName} policyType={policyType} />
      );
    case AIRLINES:
      return (
        <AirlinePolicyManager
          policyName={policyName}
          policyType={policyType}
          policyData={policyData}
        />
      );
    case RATING:
      return (
        <RatingPolicyManager
          policyName={policyName}
          policyType={policyType}
          policyData={policyData}
        />
      );
  }
};

const createValidationSchema = (policyType, max, min, message) => {
  let validationSchema = yup.object().shape({
      value: yup
        .number()
        .typeError(`Please enter a valid ${message.toLowerCase()} in numbers or figures only`)
  });

  if (policyType === MIN) {
    validationSchema = validationSchema.shape({
      value: yup
        .number()
        .typeError(`Please enter a valid ${message.toLowerCase()} in numbers or figures only`)
        .transform((value) => (isNaN(value) ? undefined : value))
        .required( `Enter a valid ${message} value`)
        .min(ONE, `${message} must be greater than 0`)
        .test(
          `check-${message.toLowerCase()}-value`,
          `${message} must be less than the maximum value`,
          (value) => !(value >= max)
        ),
    });
  } else {
    validationSchema = validationSchema.shape({
      value: yup
        .number()
        .typeError(`Please enter a valid ${message.toLowerCase()} in numbers or figures only`)
        .required( `Enter a valid ${message} value`)
        .min(ONE, `${message} must be greater than 0`)
        .test(
          `check-${message.toLowerCase()}-value`,
          `${message} must be greater than the minimum value`,
          (value) => !(value <= min)
        ),
    });
  }

  return validationSchema.shape({
    isEnabled: yup.boolean(),
  });
};

const ManagePolicyModal = ({ selectedServicePolicyData, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedPoliciesData, setSelectedPoliciesData] = useState();
  const [selectedServicePolicy, setSelectedServicePolicy] = useState();

  const allServicesPolicy = useSelector(selectAllServicesPolicy);
  const selectedServicePolicyId = useSelector(selectSelectedServicePolicyId);
  const [initialValue, setInitialValue] = useState(DEFAULT_INITIAL_VALUE);
  const [validationSchema, setValidationSchema] = useState(yup.object());
  const [showSpinner, setShowSpinner] = useState(false)
  const { policyName, policyType } = selectedServicePolicyData;

  useEffect(() => {
    if (!allServicesPolicy) return;
    const selectedServicePolicyData = allServicesPolicy.find(
      ({ id }) => id === selectedServicePolicyId
    );

    if (selectedServicePolicyData) {
      setSelectedServicePolicy(selectedServicePolicyData);
      const requiredPolicy = get(
        selectedServicePolicyData,
        `policies.${policyName}.${policyType}`,
        EMPTY_OBJECT
      );
      const { cost, rating } = get(
        selectedServicePolicyData,
        "policies",
        EMPTY_OBJECT
      );
      setSelectedPoliciesData(requiredPolicy);

      switch (policyName) {
        case COST: {
          const { value = 0, isEnabled } = requiredPolicy;
          const {
            max: { value: maxCost },
            min: { value: minCost },
          } = cost;
          const validationSchema = createValidationSchema(
            policyType,
            maxCost,
            minCost,
            COSTS
          );
          setValidationSchema(validationSchema);
          setInitialValue({ value, isEnabled });
          break;
        }
        case AIRLINES: {
          const { data = EMPTY_ARRAY, isEnabled } = requiredPolicy;
          setValidationSchema(
            yup.object().shape({
              data: yup.array().of(yup.object()),
              isEnabled: yup.boolean(),
            })
          );
          setInitialValue({ data, isEnabled });
          break;
        }
        case RATING: {
          const { value = 0, isEnabled } = requiredPolicy;
          const {
            max: { value: maxRating },
            min: { value: minRating },
          } = rating;

          const validationSchema = createValidationSchema(
            policyType,
            maxRating,
            minRating,
            RATINGS
          );
          setValidationSchema(validationSchema);
          setInitialValue({ value, isEnabled });
          break;
        }
      }
    }
  }, [allServicesPolicy, selectedServicePolicyId, selectedServicePolicyData]);

  const handleOnSave = (values) => {
    setShowSpinner(true)
    let updatedServicePolicy = cloneDeep(selectedServicePolicy);
    if (policyName === AIRLINES) {
      policyType === ALLOWED
        ? set(
            updatedServicePolicy,
            "policies.airlines.excluded.isEnabled",
            !values.isEnabled
          )
        : set(
            updatedServicePolicy,
            "policies.airlines.allowed.isEnabled",
            !values.isEnabled
          );
    }
    const trimmedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, typeof value === STRING ? value.trim() : value])
    );
    const updatePolicyRequest = createRequest(
      trimmedValues,
      updatedServicePolicy,
      policyName,
      policyType
    );
    dispatch(
      updatePolicies({
        tenantId: updatedServicePolicy.tenantId,
        policyId: updatedServicePolicy.id,
        body: updatePolicyRequest,
      })
    )
      .then(({ payload }) => {
        if (!payload) return;
        const { tenantId } = payload;
        return dispatch(getPolicies(tenantId));
      })
      .then(handleClose);
  };

  return (
    <Formik enableReinitialize>
      {() => (
        <Modal
          title={t("policies.managePolicy")}
          shouldShowModalFromProps
          handleClose={handleClose}
        >
          <div className='overflow-scroll no-scrollbar'>
            <Formik
              initialValues={initialValue}
              onSubmit={handleOnSave}
              enableReinitialize
              validationSchema={validationSchema}
            >
              {({ isValid, dirty, ...formikProps }) => (
                <Form>
                  <div className='flex flex-col py-6 px-3 sm:px-6 border-t border-contrast-200 gap-y-4'>
                    {selectedPoliciesData && (
                      <RenderSelectedPolicyDetails
                        policyName={policyName}
                        policyType={policyType}
                        policyData={selectedPoliciesData}
                      />
                    )}
                  </div>
                  <div className='p-6 flex gap-4 justify-end border-t border-contrast-200'>
                    <button
                      type='reset'
                      className='border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium py-[10px] px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border'
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='text-white font-medium disabled:cursor-not-allowed disabled:opacity-75 disabled:bg-primary-400 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm'
                      disabled={showSpinner|| !dirty || !isValid}
                      >
                      <Spinner
                        showSpinner={showSpinner}
                        name={[UPDATE_POLICY, GET_POLICY]}
                        persistSize={true}
                        >
                        Save Policy
                      </Spinner>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default ManagePolicyModal;
