import { Luggage, RenderSVG } from "../../assets/icons";
import { AnalyticsOverviewCard } from "../../components/molecules/AnalyticsCard";

const ReportOverview = ({ analyticsInfo }) => {
  const { reportOverview } = analyticsInfo;

  return (
    <div className='w-full'>
      <div className='flex flex-col pt-[18px] px-8 pb-8 border border-contrast-300 rounded-lg gap-7 bg-white'>
        <div className='flex items-start'>
          <div className='grid grid-cols-5'>
            <RenderSVG Svg={Luggage} alt='luggage' className="none bg-primary-600 p-1 rounded-lg" color="white" width="30" height="30"/>
            <div className='col-span-4 flex justify-end items-center capitalize font-medium'>
              Travel Report Overview
            </div>
          </div>
        </div>

        <div className='grid grid-cols-2'>
          {reportOverview?.map(({ title, icon, value, unit, difference }) => (
            <AnalyticsOverviewCard
              key={title}
              title={title}
              icon={icon}
              value={value}
              unit={unit}
              difference={difference}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportOverview;
