import { getTravelersAgeFromDOB } from "./getTravelerAgeAndCategorization";
import { DEFAULT_VALUES } from "../constants";

const { ZERO, FIFTEEN, TWELVE, NINE } = DEFAULT_VALUES;
const EIGHTEEN = 18;

export const getPaxWarningMessage = (selected) => {
  if (selected.length === ZERO) {
    return "";
  }
  let hasFlightAdult = false;
  let hasHotelAdult = false;
  let isTravelerEligibleToBookFlight = true;

  selected.forEach((traveler) => {
    const age = getTravelersAgeFromDOB(traveler?.dateOfBirth) || 18;

    if (age >= TWELVE) hasFlightAdult = true;
    if (age >= EIGHTEEN) hasHotelAdult = true;
    if (age?.days < FIFTEEN) isTravelerEligibleToBookFlight = false;
  });

  if (!isTravelerEligibleToBookFlight)
    return "* Warning: One or more travelers are below 15 days old and are not eligible to travel for flights. However, you may proceed with booking for hotels.";
  if (selected.length > NINE)
    return "* Warning: You can select a maximum of 9 passengers for flight booking. You may continue to book for hotels.";
  if (!hasFlightAdult && !hasHotelAdult)
    return "* Warning: You need to select an adult for booking hotels and flights.";
  if (hasFlightAdult && !hasHotelAdult)
    return "* Warning: You need to select an adult for hotel booking.";
  return "";
};
