import get from "lodash/get";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES, CACHE_KEYS, DEFAULT_VALUES } from "../../constants";
import { getFromLocalStorage } from "../../helper";

const {
  BOOKINGS,
  LOGIN,
  PRIVACY_POLICY,
  TERMS_AND_CONDITION,
  COOKIE_POLICY,
  CONTACT_US,
  AUTH_CONFIRMATION,
  SET_PASSWORD,
  FORGOT_PASSWORD,
  HOME,
  CURRENT_USER_BOOKINGS
} = ROUTES;
const { AUTH, CURRENT_USER_INFO} = CACHE_KEYS;
const { EMPTY_STRING } = DEFAULT_VALUES;
const AUTH_ROUTES = [LOGIN, AUTH_CONFIRMATION, SET_PASSWORD, FORGOT_PASSWORD];
const UNSECURED_ROUTES = [
  ...AUTH_ROUTES,
  PRIVACY_POLICY,
  TERMS_AND_CONDITION,
  COOKIE_POLICY,
  CONTACT_US,
];

const PrivateRoute = ({ children }) => {
  const location = useLocation();

  let authId = null;
  const authInfo = getFromLocalStorage(AUTH);
  const userInfo = getFromLocalStorage(CURRENT_USER_INFO)
  const isUnsecuredRoute = UNSECURED_ROUTES.includes(location.pathname);
  const isAuthRoute = AUTH_ROUTES.includes(location.pathname);
  const isHomeRoute = location.pathname === HOME;

  if (authInfo) authId = get(authInfo, "id", EMPTY_STRING);
  if (!authId && !isUnsecuredRoute) {
    const redirectTo = location.pathname + location.search;
    const encodedRedirectTo = encodeURIComponent(redirectTo);
    const redirectParam = redirectTo !== '/' ? `?redirectTo=${encodedRedirectTo}` : '';    
    window.location.assign(`${LOGIN}${redirectParam}`);
    return null; // Prevent rendering until redirection happens

  }
  if (authId && (isAuthRoute || isHomeRoute))
    return <Navigate to={(CURRENT_USER_BOOKINGS.replace(":userId",userInfo?.id))} state={{ from: location }} replace={true} />;

  return children;
};

export default PrivateRoute;
