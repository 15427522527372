import { CheckTeal, RenderSVG } from "../../assets/icons";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DEFAULT_VALUES, ROUTES, USER_AUTH_FIELDS } from "../../constants";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { resetPasswordByOtp } from "./auth.actions";

const { RESET_PASSWORD_BY_OTP } = SPINNER_NAMES;
const { EMAIL } = USER_AUTH_FIELDS;
const { FORGOT_PASSWORD } = ROUTES;
const { EMPTY_STRING } = DEFAULT_VALUES;
const RESEND_TIMER_DEFAULT_TIME = 45;

const AuthConfirmation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [resendOtpTimer, setResendOtpTimer] = useState(
    RESEND_TIMER_DEFAULT_TIME
  );
  const [shouldResendOtp, setShouldResendOtp] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const userEmail = decodeURIComponent(queryParams.get(EMAIL));

  useEffect(() => {
    if (resendOtpTimer === 0) {
      setShouldResendOtp(true);
    } else {
      const timer = setTimeout(() => {
        setResendOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [resendOtpTimer]);

  const handleOnClick = () => {
    if (userEmail) {
      dispatch(resetPasswordByOtp({ provider: EMAIL, email: userEmail })).then(
        (res) => {
          if (res.payload === EMPTY_STRING) {
            toast.success(t("forgotPassword.otpSent"));
          }
          setResendOtpTimer(RESEND_TIMER_DEFAULT_TIME);
          setShouldResendOtp(false);
        }
      );
    } else {
      toast.error("Enter Your Email Address");
      navigate(FORGOT_PASSWORD);
    }
  };

  return (
    <div className='font-inter bg-primary-50'>
      <div className='py-8 px-6 min-h-screen flex justify-center items-center'>
        <div className='flex-1 bg-white rounded-lg shadow-md p-6 max-w-sm flex flex-col gap-6'>
          <div className=''>
            <div className='pb-8 text-center'>
              <div className='w-12 h-12 rounded-full bg-green-100 grid place-content-center mx-auto'>
                <RenderSVG Svg={CheckTeal} width='24' height='24' alt='check' />
              </div>
            </div>
            <div className='text-center'>
              <h1 className='text-2xl text-contrast-900 font-bold mb-2'>
                Email Sent
              </h1>
              <p className='text-sm text-contrast-500'>
                We sent an email to{" "}
                <span className='font-bold'>{userEmail}</span> with instructions
                on resetting your password
              </p>
            </div>
          </div>
          {shouldResendOtp ? (
            <button
              type='button'
              onClick={() => handleOnClick()}
              className='py-[10px] px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium'
            >
              <Spinner
                name={RESET_PASSWORD_BY_OTP}
                setIsSpinnerActive={setIsSpinnerActive}
                size='w-5 h-5'
              />
              {!isSpinnerActive && <span> Resend Email</span>}
            </button>
          ) : (
            <div className='timer flex flex-row justify-center'>
              <div className='rounded-xl bg-gradient-to-r from-green-400 to-blue-500 py-2 flex items-center justify-around gap-1 px-2 w-full'>
                <p className='text-base font-manrope font-semibold text-center flex flex-row gap-1'>
                  Resend Otp in:
                </p>
                <div className='flex flex-col'>
                  <h4 className='countdown-element seconds font-manrope font-semibold text-xl text-white text-center'>
                    {resendOtpTimer}
                  </h4>
                  <span className='text-xs font-bold'>seconds </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthConfirmation;
