import { createAsyncThunk } from "@reduxjs/toolkit";
import { isEmpty, get } from "lodash";
import axios from "axios";
import asyncAction from "../../../infrastructure/asyncAction";
import { createQueryString } from "../../../helper/createQueryString";
import { SPINNER_NAMES, addSpinner, removeSpinner } from "../Spinner";
import { DEFAULT_VALUES, REQUEST_METHODS } from "../../../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;
const {
  FETCH_FLIGHTS_RESULTS,
  FETCH_HOTEL_RESULTS,
  FETCH_LOCATIONS,
  FETCH_HOTEL_STATIC_DATA,
} = SPINNER_NAMES;
const { POST } = REQUEST_METHODS;
const MAX_HOTEL_RESULTS_PER_PAGE = 50;
const {
  REACT_APP_HOTEL_SEARCH_URL = "",
  REACT_APP_ALGOLIA_API_KEY = "",
  REACT_APP_ALGOLIA_APP_ID = "",
} = process.env;

export const fetchFlights = createAsyncThunk(
  "get-flights",
  async ({ body }, thunkArgs) => {
    return await asyncAction({
      url: "flights/search",
      methodType: "post",
      body: JSON.stringify(body),
      spinner: FETCH_FLIGHTS_RESULTS,
      isAuthRequired: true,
      errorMessage: "Failed to load flight details.",
      ...thunkArgs,
    });
  }
);

export const getIATALocations = createAsyncThunk(
  "get-city-codes",
  async (queryParams, thunkArgs) => {
    const { dispatch } = thunkArgs;
    const type = get(queryParams, "type", EMPTY_STRING);
    let response = [];
    dispatch(addSpinner(FETCH_LOCATIONS));
    const fetchData = async (url) => {
      try {
        const res = await axios.get(url);
        return get(res, "data.hits.hit", []);
      } catch (error) {
        console.error("Error Fetching Data:", error);
        return [];
      } finally {
        dispatch(removeSpinner(FETCH_LOCATIONS));
      }
    };

    let hits = await fetchData(
      `https://airports.tava.cloud?q=${queryParams.search}*`
    );

    if (isEmpty(hits) && queryParams.search) {
      hits = await fetchData(
        `https://airports.tava.cloud?q=${queryParams.search}`
      );
    }
    if (!isEmpty(hits)) {
      const capitalizeString = (value) =>
        value
          ? value
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")
          : value;
      response = hits
        .filter(
          (each) => each.fields.iata && each.fields.iata.toLowerCase() !== "n/a"
        )
        .map((each) => ({
          id: each.id,
          iata: each.fields.iata.toUpperCase(),
          cityName: capitalizeString(each.fields.city),
          airportName: capitalizeString(each.fields.name),
          country: capitalizeString(each.fields.country),
          countryCode: capitalizeString(each.fields.countryCode),
        }));
    }
    return { output: response, type };
  }
);

export const getCalendarDatePrices = createAsyncThunk(
  "get-calendar-date-prices",
  async ({ queryParams, body }, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `/fare-calendar${queryString}`,
      body,
      methodType: "post",
      errorMessage: "Failed to get calendar date prices.",
      ...thunkArgs,
    });
  }
);

export const getHotels = createAsyncThunk(
  "get-hotel-search",
  async ({ body }, thunkArgs) => {
    return await asyncAction({
      url: "/hotels/search",
      methodType: "post",
      body: JSON.stringify(body),
      spinner: FETCH_HOTEL_RESULTS,
      isAuthRequired: true,
      errorMessage: "Failed to load hotel details.",
      ...thunkArgs,
    });
  }
);

export const getHotelsStaticData = createAsyncThunk(
  "get-hotel-static-Data",
  async ({ body }, thunkArgs) => {
    return await asyncAction({
      url: "/hotels/static-data",
      methodType: POST,
      body: JSON.stringify(body),
      spinner: FETCH_HOTEL_STATIC_DATA,
      isAuthRequired: true,
      errorMessage: "Failed to load hotel static data.",
      ...thunkArgs,
    });
  }
);

export const getHotelLocations = createAsyncThunk(
  "get-hotel-locations",
  async (body, thunkArgs) => {
    const { key: searchKeyword } = body;
    return await asyncAction({
      url: REACT_APP_HOTEL_SEARCH_URL,
      methodType: POST,
      body: {
        params: `query=${searchKeyword}&hitsPerPage=${MAX_HOTEL_RESULTS_PER_PAGE}`,
      },
      httpHeaders: {
        headers: {
          "X-Algolia-API-Key": REACT_APP_ALGOLIA_API_KEY,
          "X-Algolia-Application-Id": REACT_APP_ALGOLIA_APP_ID,
        },
      },
      spinner: FETCH_LOCATIONS,
      shouldAllowCustomHeaders: false,
      errorMessage: "Failed to load hotel locations.",
      ...thunkArgs,
    });
  }
);
