import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { REQUEST_METHODS, ERROR_MESSAGES } from "../../constants";

const {
  AUTH,
  ACCEPT_INVITATION,
  RESEND_INVITATION,
  INVITATION_DETAIL,
  RESET_PASSWORD_BY_OTP,
  FORGOT_PASSWORD,
  LOGIN,
  ADD_USER,
  TENANT_DETAILS
} = SPINNER_NAMES;
const { POST, GET } = REQUEST_METHODS;
const { ERROR_SENDING_INVITE } = ERROR_MESSAGES;

export const signUp = createAsyncThunk(
  "auth/sign-up",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: `sign-up`,
      spinner: AUTH,
      methodType: POST,
      body,
      errorMessage: "Unable to register your account, please try again later.",
      showErrorToast: true,
      ...thunkArgs,
    });
  }
);

export const login = createAsyncThunk("login", async (body, thunkArgs) => {
  return await asyncAction({
    url: "login",
    methodType: POST,
    spinner: LOGIN,
    errorMessage: "Unable to login, please try again later",
    showErrorToast: true,
    body,
    ...thunkArgs,
  });
});

export const resetPasswordByOtp = createAsyncThunk(
  "reset-password-by-otp",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: "auth/password/sendOtp",
      methodType: POST,
      spinner: RESET_PASSWORD_BY_OTP,
      errorMessage: "Unable to send otp, please try again later",
      showErrorToast: true,
      body,
      ...thunkArgs,
    });
  }
);

export const verifyOtp = createAsyncThunk(
  "verify-otp",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: "verify-otp",
      methodType: POST,
      spinner: AUTH,
      errorMessage: "Unable to verify-otp, please try again later",
      showErrorToast: true,
      body,
      ...thunkArgs,
    });
  }
);

export const forgotPassword = createAsyncThunk(
  "forgot-password",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: "auth/password/reset",
      methodType: POST,
      spinner: FORGOT_PASSWORD,
      errorMessage: "Unable to reset password, please try again later",
      showErrorToast: true,
      body,
      ...thunkArgs,
    });
  }
);

export const regenerateAccessToken = createAsyncThunk(
  "auth/regenerate-access-token",
  async ({ refreshToken, spinner }, thunkArgs) => {
    return await asyncAction({
      url: "regenrate-token",
      methodType: POST,
      body: { refreshToken },
      abortOnPageChange: false,
      errorMessage: "Failed to refresh the session details.",
      spinner,
      ...thunkArgs,
    });
  }
);

export const sendInvitation = createAsyncThunk(
  "send-invitation",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: "invitation/send",
      methodType: POST,
      body,
      errorMessage: ERROR_SENDING_INVITE,
      showErrorToast: true,
      isAuthRequired: true,
      spinner: ADD_USER,
      ...thunkArgs,
    });
  }
);

export const acceptInvitation = createAsyncThunk(
  "accept-invitation",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: "invitation/accept",
      methodType: POST,
      body,
      errorMessage: "Failed to save your password, please try again later",
      showErrorToast: true,
      spinner: ACCEPT_INVITATION,
      ...thunkArgs,
    });
  }
);

export const resendInvitation = createAsyncThunk(
  "resend-invitation",
  async (body, thunkArgs) => {
    
    return await asyncAction({
      url: "invitation/resend",
      methodType: POST,
      body,
      abortOnPageChange: false,
      isAuthRequired:true,
      errorMessage: "Failed to resend invitation, please try again later",
      showErrorToast: true,
      spinner: RESEND_INVITATION,
      ...thunkArgs,
    });
  }
);

export const invitationDetails = createAsyncThunk(
  "invitation-detail",
  async (invitationId, thunkArgs) => {
    return await asyncAction({
      url: `invitation/${invitationId}`,
      methodType: GET,
      spinner: INVITATION_DETAIL,
      showErrorToast: true,
      errorMessage: "Failed to get invitation details, please try again later",
      ...thunkArgs,
    });
  }
);

export const fetchTenantDetails = createAsyncThunk(
  "tenant-config",
  async ({ hostName, id }, thunkArgs) => {
    const url = hostName
      ? `tenant/config?primaryDomain=${hostName}`
      : `tenant/config?id=${id}`;
    return await asyncAction({
      url,
      methodType: GET,
      spinner: TENANT_DETAILS,
      abortOnPageChange: false,
      ...thunkArgs,
    });
  }
);
