import { useTranslation } from "react-i18next";
import { TriangleExclaimation, RenderSVG } from "../assets/icons";

const PermissionDenied = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <div className='mb-2'>
        <RenderSVG
          Svg={TriangleExclaimation}
          height='60'
          width='60'
          color='red'
        />
      </div>
      <div className='text-contrast-900 text-4xl font-semibold leading-tight tracking-tight'>
        {t("permissionDenied.heading1")}
      </div>
      <div className='text-contrast-900 text-2xl font-semibold leading-[48px] text-center'>
        {t("permissionDenied.heading2")}
      </div>
    </div>
  );
};

export default PermissionDenied;
