import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Country } from "country-state-city";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import moment from "moment";
import classNames from "classnames";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import { DocumentRequiredSkeleton } from "../../../components/organisms/AppSkeletons";
import {
  getFormattedDate,
  getFormattedDateTime,
  getFormattedTravelerInitialDate,
  renderFieldError,
  setToSessionStorage,
} from "../../../helper";
import { selectBookingTravelers, selectSelectedTravelers } from ".";
import { actions as bookingActions } from "./flightBookings.reducer";
import {
  DEFAULT_VALUES,
  TRAVELER_TYPE,
  MODAL_SIZE,
  CACHE_KEYS,
  TBO_FARE_TYPES,
  FLIGHT_SERVICE_TYPE,
  TITLES,
  AVAILABLE_PERMISSIONS,
  UPDATE_DATA_CONSENT_MESSAGE,
} from "../../../constants";
import {
  selectFlightPriceInfo,
  selectFlightPriceReq,
  selectIsInternationalFlight,
} from "../../FlightResults";
import Asterisk from "../../../components/atoms/Asterisk";
import DropdownSelectField from "../../../components/atoms/DropdownSelectField";
import SelectDocumentTypeField from "../../../components/atoms/SelectDocumentTypeField";
import Modal from "../../../components/organisms/Modal";
import DateSearch from "../../../components/atoms/DateSearch";
import TravelerPrimaryDetailFeilds from "../../../components/organisms/Traveler/TravelerPrimaryDetailFeilds";
import { Tooltip } from "react-tooltip";
import { RenderSVG, Information } from "../../../assets/icons";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import { selectUserInfo } from "../../Profile";
import { updateUser } from "../../Users/users.actions";
import { updateTraveler } from "../../Profile/profile.actions";

const { UPDATE_OWN_DATA, UPDATE_USERS_DATA } = UPDATE_DATA_CONSENT_MESSAGE;
const { EMPTY_STRING, EMPTY_ARRAY, ONE, ZERO, TWO, TWELVE, FIFTEEN, EMPTY_OBJECT } =
  DEFAULT_VALUES;
const { TRAVELERS_INFORMATION } = CACHE_KEYS;
const { PRICE } = SPINNER_NAMES;
const { AMADEUS, TBO } = FLIGHT_SERVICE_TYPE;
const MALE = "MALE";
const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;
const { LG } = MODAL_SIZE;
const { REGULAR, ARMED_FORCES, SENIOR_CITIZEN, STUDENT } = TBO_FARE_TYPES;
const { CAN_UPDATE_OWN_PROFILE_DETAILS, CAN_UPDATE_ALL_USER_DETAILS } =
  AVAILABLE_PERMISSIONS;
const MIN_AGE_FOR_SENIOR_CITIZEN = 60;
const SHOW_ERROR_DURATION = 3000;

const FARE_TYPES_DOCUMENTS = {
  [ARMED_FORCES]: [{ name: "Defence ID", value: "Armed Forced ID" }],
  [STUDENT]: [{ name: "Student ID", value: "StudentId" }],
  [SENIOR_CITIZEN]: [{ name: "Senior Citizen ID", value: "SeniorCitizenId" }],
};

const { setBookingTravelers, setSelectedTravelers } = bookingActions;

const ManageBookingTraveler = ({
  travelerType,
  showManageTraveler,
  setShowManageTraveler,
  travelerId,
  documentsRequired,
  dateErrorMessage,
  setDateErrorMessage,
  journeyStartDate,
  returnDate,
  isGoingToNepal,
  validationSchema,
  travelerCount,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedBookingTraveler = useSelector(selectBookingTravelers);
  const selectedTravelers = useSelector(selectSelectedTravelers);
  const allFlightPriceRes = useSelector(selectFlightPriceInfo);
  const isInternational = useSelector(selectIsInternationalFlight);
  const flightPriceReq = useSelector(selectFlightPriceReq);
  const userInfo = useSelector(selectUserInfo);
  const [errorMessage, setErrorMessage] = useState("");
  const [isInternationalAMFlight, setIsInternationalAMFlight] = useState(false);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [isUserAgreed, setIsUserAgreed] = useState(false);

  const hasPermission = (permission) =>
    userInfo.permissions.includes(permission);
  const hasUpdateAllUsersDetails = hasPermission(CAN_UPDATE_ALL_USER_DETAILS);
  const hasUpdateOwnProfileDetails = hasPermission(
    CAN_UPDATE_OWN_PROFILE_DETAILS
  );

  const { fareType = REGULAR } = get(
    allFlightPriceRes,
    "0.flightPriceInfo",
    EMPTY_OBJECT
  );
  const { source = EMPTY_STRING } = get(flightPriceReq, "0", EMPTY_OBJECT);
  const isFareTypeDocumentRequired = fareType !== REGULAR;
  const bookingTravelerInfo = selectedBookingTraveler || EMPTY_ARRAY;
  const parsedReturnDate = new Date(returnDate);
  // Passport 6-Month Rule: Traveller's passport must be valid for another six months before depart for international travel.
  const minExpiryDateForPassport = new Date(
    parsedReturnDate.getFullYear(),
    parsedReturnDate.getMonth() + 6,
    parsedReturnDate.getDate() + 1
  );

  const {
    isPanRequiredAtBook,
    isPanRequiredAtTicket,
    isPassportRequiredAtBook,
    isPassportRequiredAtTicket,
    isPassportFullDetailRequiredAtBook,
  } = documentsRequired;

  const initialFormValues = {
    travelerType: travelerType || EMPTY_STRING,
    fareType: fareType || REGULAR,
    profileDetails: {
      firstName: EMPTY_STRING,
      middleName: EMPTY_STRING,
      lastName: EMPTY_STRING,
      dateOfBirth: getFormattedTravelerInitialDate(
        travelerType,
        fareType,
        journeyStartDate
      ),
      gender: MALE,
      nationality: { isoCode: EMPTY_STRING },
      title: TITLES[travelerType][ZERO],
    },
    passportDetails: {
      passport: EMPTY_STRING,
      issueCountry: { isoCode: EMPTY_STRING },
      issuedDate: getFormattedDateTime(new Date())[ZERO],
      expireDate: getFormattedDateTime(minExpiryDateForPassport)[ZERO],
    },
    panCardDetails: {
      panCardNumber: EMPTY_STRING,
    },
    document: {
      number: EMPTY_STRING,
      type: { name: EMPTY_STRING },
    },
    isPrimary: false,
    phoneCode: EMPTY_STRING,
  };
  const isPassportRequired =
    isPassportRequiredAtBook || isPassportRequiredAtTicket;

  const [initialValues, setInitialValues] = useState(initialFormValues);

  const countryList = Country.getAllCountries();

  const getValidatedDates = (years, travelerType, daysToSkip = ZERO) => {
    let currentDate;
    if (
      (travelerType === HELD_INFANT && years === ZERO) ||
      years === MIN_AGE_FOR_SENIOR_CITIZEN
    )
      currentDate = new Date();
    else {
      currentDate = new Date(journeyStartDate);
    }
    currentDate.setFullYear(currentDate.getFullYear() - years);
    currentDate.setDate(currentDate.getDate() - daysToSkip);
    return currentDate;
  };

  const dateValidation = {
    [ADULT]: {
      maxDate: getValidatedDates(
        fareType === SENIOR_CITIZEN ? MIN_AGE_FOR_SENIOR_CITIZEN : TWELVE,
        ADULT,
        ONE
      ),
      minDate: EMPTY_STRING,
    },
    [HELD_INFANT]: {
      maxDate: getValidatedDates(ZERO, HELD_INFANT, FIFTEEN),
      minDate: getValidatedDates(TWO, HELD_INFANT),
    },
    [CHILD]: {
      maxDate: getValidatedDates(TWO, CHILD, ONE),
      minDate: getValidatedDates(TWELVE),
    },
  };

  useEffect(() => {
    source === AMADEUS && isInternational && setIsInternationalAMFlight(true);
  }, [source, isInternational]);

  useEffect(() => {
    if (travelerId) {
      const travelers = bookingTravelerInfo;
      const selectedTraveler = travelers.find(
        (eachTraveler) => eachTraveler.travelerId === travelerId
      );
      if (selectedTraveler) {
        const issuedDate =
          selectedTraveler.passportDetails.issuedDate ||
          initialFormValues.passportDetails.issuedDate;
        const expireDate =
          selectedTraveler.passportDetails.expireDate ||
          initialFormValues.passportDetails.expireDate;
        setInitialValues({
          ...selectedTraveler,
          travelerType,
          passportDetails: {
            ...selectedTraveler.passportDetails,
            issuedDate,
            expireDate,
          },
        });
      }
      const invalidDateOfBirth =
        moment(getFormattedDate(new Date()), "YYYY-MM-DD").diff(
          selectedTraveler.profileDetails.dateOfBirth,
          "years"
        ) < MIN_AGE_FOR_SENIOR_CITIZEN;
      if (fareType === SENIOR_CITIZEN && invalidDateOfBirth) {
        setInitialValues((prev) => ({
          ...prev,
          profileDetails: {
            ...prev.profileDetails,
            dateOfBirth: initialFormValues.profileDetails.dateOfBirth,
          },
        }));
      }
    } else setInitialValues({ ...initialFormValues, travelerType });
  }, [travelerId, bookingTravelerInfo]);

  const updateTravelers = (
    travelers,
    travelerId,
    values,
    valuesAddOns = {}
  ) => {
    const existingTravelerData = travelers.find(
      (traveler) => traveler.travelerId === travelerId
    );
    if (existingTravelerData) {
      const updatedPassengers = travelers.map((traveler) =>
        traveler.travelerId === travelerId
          ? { ...values, ...valuesAddOns }
          : traveler
      );
      return updatedPassengers;
    } else return [...travelers, { ...values, ...valuesAddOns, travelerId }];
  };

  const showError = (error) => {
    setErrorMessage(error);
    setTimeout(() => setErrorMessage(EMPTY_STRING), SHOW_ERROR_DURATION);
  };

  const handleOnSubmit = (values, { resetForm }) => {
    let canAddTraveler = true;

    if (bookingTravelerInfo) {
      canAddTraveler = !bookingTravelerInfo.some(
        (traveler) =>
          traveler.profileDetails.travelerId !==
            values.profileDetails.travelerId &&
          traveler.profileDetails.firstName ===
            values.profileDetails.firstName &&
          traveler.profileDetails.lastName === values.profileDetails.lastName &&
          traveler.profileDetails.dateOfBirth ===
            values.profileDetails.dateOfBirth
      );
    }

    if (canAddTraveler) {
      let updatedTravelers = bookingTravelerInfo
        ? [...bookingTravelerInfo]
        : EMPTY_ARRAY;

      const isPanCardRequired = isPanRequiredAtBook || isPanRequiredAtTicket;
      const valuesAddOns = {
        isPanCardRequired,
        isPassportRequired,
      };
      const id = travelerId || uuid();
      updatedTravelers = updateTravelers(updatedTravelers, id, values);
      const isTravelerAlreadySelected = selectedTravelers.some(
        (element) => element.travelerId == id
      );
      const filteredTraveler = updatedTravelers.find(
        (traveler) => traveler.travelerId === id
      );
      const { isUser, isPaxPresent } = filteredTraveler;

      const formattedTraveler = isPaxPresent
        ? {
            ...(isUser
              ? {
                  name: `${filteredTraveler.profileDetails.firstName} ${
                    filteredTraveler.profileDetails.middleName || ""
                  } ${filteredTraveler.profileDetails.lastName}`,
                  country: filteredTraveler.country,
                  phone: filteredTraveler.phoneNumber,
                }
              : {
                  countryName: filteredTraveler.country,
                  phoneNumber: filteredTraveler.phoneNumber,
                  title: filteredTraveler.profileDetails.title,
                }),
            firstName: filteredTraveler.profileDetails.firstName,
            middleName: filteredTraveler.profileDetails.middleName || "",
            lastName: filteredTraveler.profileDetails.lastName,
            PassportIssueCountry:
              filteredTraveler.passportDetails.issueCountry.isoCode,
            gender: filteredTraveler.profileDetails.gender,
            passportNumber: filteredTraveler.passportDetails.passport,
            passportIssuanceDate: filteredTraveler.passportDetails.issuedDate,
            passportExpiryDate: filteredTraveler.passportDetails.expireDate,
            panNumber: filteredTraveler.panCardDetails.panCardNumber,
            dateOfBirth: filteredTraveler.profileDetails.dateOfBirth,
            city: filteredTraveler.city,
            address: filteredTraveler.address,
          }
        : null;

      if (
        (hasUpdateOwnProfileDetails || hasUpdateAllUsersDetails) &&
        isUserAgreed &&
        isPaxPresent
      ) {
        isUser
          ? dispatch(updateUser({ id, body: formattedTraveler }))
          : dispatch(updateTraveler({ body: formattedTraveler, id }));
      }
      if (
        selectedTravelers.length < travelerCount ||
        isTravelerAlreadySelected
      ) {
        const updatedSelectedTravelers = updateTravelers(
          selectedTravelers,
          id,
          values,
          valuesAddOns
        );
        dispatch(setSelectedTravelers(updatedSelectedTravelers));
        setToSessionStorage(TRAVELERS_INFORMATION, updatedSelectedTravelers);
      }
      dispatch(setBookingTravelers(updatedTravelers));
      handleFormClose(resetForm);
    } else {
      const error =
        "This passenger has been previously added with the same information.";
      showError(error);
    }
  };

  const handleFormClose = (resetForm = () => {}) => {
    setShowManageTraveler(false);
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleOnSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        isValid,
        handleChange,
        setFieldTouched,
        setFieldValue,
        setFieldError,
        validateForm,
        resetForm,
      }) => (
        <Modal
          size={LG}
          shouldShowModalFromProps={showManageTraveler}
          handleClose={() => handleFormClose(resetForm)}
          title={
            <p className='flex-1 text-2xl font-bold text-contrast-900'>
              {t("profilePage.manageTraveler")}
            </p>
          }
        >
          <Form>
            <div className='py-6 px-3 sm:px-6 '>
              <div className='grid grid-cols-12 gap-6 mb-5'>
                <TravelerPrimaryDetailFeilds travelerType={travelerType} />
                <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                  <div className='w-full'>
                    <label className='block text-sm font-medium mb-1 text-contrast-900'>
                      {t("profilePage.dob")}
                      <Asterisk />
                    </label>
                    <div className='bg-white rounded-md text-contrast-900 text-sm font-normal w-full'>
                      <DateSearch
                        name='profileDetails.dateOfBirth'
                        values={values}
                        touched={touched}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        setFieldError={setFieldError}
                        maxDate={dateValidation[travelerType].maxDate}
                        minDate={dateValidation[travelerType].minDate}
                        required
                        showCalendarIcon={true}
                        dateErrorMessage={dateErrorMessage}
                        setDateErrorMessage={setDateErrorMessage}
                        fixMainPosition={true}
                      />
                    </div>
                    <div>
                      {renderFieldError(
                        "profileDetails.dateOfBirth",
                        errors,
                        touched,
                        !!dateErrorMessage
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {source === TBO && (
                <Spinner
                  name={PRICE}
                  loaderComponent={<DocumentRequiredSkeleton />}
                  setIsSpinnerActive={setIsSpinnerActive}
                ></Spinner>
              )}
              <div
                className={classNames("grid grid-cols-12 gap-6 pt-5", {
                  "border-t border-t-1 border-contrast-200":
                    isPassportRequiredAtTicket ||
                    (source === AMADEUS && isInternational),
                })}
              >
                {(isInternationalAMFlight ||
                  isPassportRequiredAtBook ||
                  isPassportRequiredAtTicket ||
                  isPassportFullDetailRequiredAtBook ||
                  (isGoingToNepal && travelerType !== HELD_INFANT)) && (
                  <>
                    <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                      <div className='form-group'>
                        <label className='block text-sm font-medium mb-1 text-contrast-900'>
                          {t("profilePage.passport")}
                          <Asterisk />
                        </label>
                        <Field
                          id='passport'
                          name='passportDetails.passport'
                          type='text'
                          className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                          placeholder={t("profilePage.placeholders.passport")}
                        />
                        <div>
                          {renderFieldError(
                            "passportDetails.passport",
                            errors,
                            touched
                          )}
                        </div>
                      </div>
                    </div>
                    {(isPassportFullDetailRequiredAtBook ||
                      source === AMADEUS) && (
                      <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                        <div className='form-group'>
                          <label className='block text-sm font-medium mb-1 text-contrast-900'>
                            {t("profilePage.issueDate")}
                            {<Asterisk />}
                          </label>
                          <div>
                            <div className='mb-1 h-9 bg-white rounded-md text-contrast-900 text-sm font-normal w-full'>
                              <DateSearch
                                name='passportDetails.issuedDate'
                                noOfMonth={ONE}
                                showCalendarIcon={true}
                                maxDate={new Date()}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                values={values}
                                position='top-right'
                              />
                            </div>
                            <div>
                              {renderFieldError(
                                "passportDetails.issuedDate",
                                errors,
                                touched
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                      <div className='form-group'>
                        <label className='flex text-sm font-medium mb-1 text-contrast-900'>
                          {t("profilePage.expireDate")}
                          {<Asterisk />}
                          {
                            <RenderSVG
                              Svg={Information}
                              className='ml-2 cursor-pointer'
                              data-tooltip-id='passport-expiry'
                            />
                          }
                          <Tooltip
                            id='passport-expiry'
                            content={t("profilePage.errors.expireDate")}
                            className='!w-56 !sm:w-72 !bg-primary-600 !rounded-lg !z-10'
                          ></Tooltip>
                        </label>
                        <div className='flex flex-col'>
                          <div className='mb-1 h-9 bg-white rounded-md text-contrast-900 text-sm font-normal w-full'>
                            <DateSearch
                              name='passportDetails.expireDate'
                              noOfMonth={ONE}
                              showCalendarIcon={true}
                              minDate={minExpiryDateForPassport}
                              values={values}
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          <div>
                            {renderFieldError(
                              "passportDetails.expireDate",
                              errors,
                              touched
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {(isPassportFullDetailRequiredAtBook ||
                      source === AMADEUS) && (
                      <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                        <div className='form-group'>
                          <label className='block text-sm font-medium mb-1 text-contrast-900'>
                            {t("profilePage.issuingCountry")}
                            {<Asterisk />}
                          </label>
                          <DropdownSelectField
                            name='passportDetails.issueCountry'
                            value={values.passportDetails.issueCountry}
                            handleChange={handleChange}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            selectableValues={countryList}
                            valueToShow='isoCode'
                            validateForm={validateForm}
                            placeholder={t(
                              "profilePage.placeholders.issueCountry"
                            )}
                          />
                          <div>
                            {renderFieldError(
                              "passportDetails.issueCountry.isoCode",
                              errors,
                              touched
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {(isPanRequiredAtBook || isPanRequiredAtTicket) && (
                  <div className='col-span-12 sm:col-span-6 md:col-span-3'>
                    <div className='form-group'>
                      <label className='block text-sm font-medium mb-1 text-contrast-900'>
                        {t("profilePage.panCardNumber")}
                        <Asterisk />
                      </label>
                      <Field
                        id='PanCard'
                        name='passportDetails.panCardNumber'
                        type='text'
                        className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                        placeholder={t("profilePage.placeholders.panCard")}
                      />
                      <div>
                        {renderFieldError(
                          "passportDetails.panCardNumber",
                          errors,
                          touched
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {isFareTypeDocumentRequired && (
                  <>
                    <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                      <div className='form-group'>
                        <label className='block text-sm font-medium mb-1 text-contrast-900'>
                          Document Type
                          <Asterisk />
                        </label>
                        <SelectDocumentTypeField
                          name='document.type'
                          value={get(values, "document.type")}
                          handleChange={handleChange}
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                          selectableValues={FARE_TYPES_DOCUMENTS[fareType]}
                          valueToShow='name'
                          validateForm={validateForm}
                        />
                        <div>
                          {renderFieldError(
                            "document.type.name",
                            errors,
                            touched
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                      <div className='form-group'>
                        <label className='block text-sm font-medium mb-1 text-contrast-900'>
                          Document ID Number
                          <Asterisk />
                        </label>
                        <Field
                          id='number'
                          name='document.number'
                          type='text'
                          className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                          placeholder='Document ID Number'
                        />
                        <div>
                          {renderFieldError("document.number", errors, touched)}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <ErrorMessage errorMessage={errorMessage} className=' mb-2' />
            </div>
            <div className='p-6 flex gap-4 justify-between items-center border-t border-contrast-200'>
              <div className='form-group col-span-12'>
                {travelerId &&
                  (hasUpdateAllUsersDetails || hasUpdateOwnProfileDetails) && (
                    <label className='inline-flex items-center'>
                      <Field
                        type='checkbox'
                        name='isUserAgressOnSavingDetails'
                        className='form-checkbox h-4 w-4 text-primary-600 transition duration-150 ease-in-out'
                        onChange={(e) => setIsUserAgreed(e.target.checked)}
                        checked={isUserAgreed}
                      />
                      <div className='flex flex-col'>
                        <span className='ml-2 text-sm text-contrast-900 font-semibold'>
                          {userInfo.id === travelerId
                            ? UPDATE_OWN_DATA
                            : UPDATE_USERS_DATA}
                        </span>
                      </div>
                    </label>
                  )}
              </div>

              <div className='flex gap-2'>
                <button
                  type='reset'
                  className='py-[10px] px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium'
                  onClick={() => setShowManageTraveler(false)}
                >
                  {t("profilePage.actions.cancel")}
                </button>
                <button
                  className='py-[10px] px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium disabled:cursor-not-allowed disabled:bg-primary-400'
                  type='submit'
                  disabled={isSpinnerActive}
                >
                  {travelerId
                    ? t("profilePage.actions.update")
                    : t("profilePage.actions.save")}
                </button>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default ManageBookingTraveler;
