import { get } from "lodash";

export const getHotelBookingAmount = (hotels) => {
  const hotelRoomDetails =
    get(hotels, "0.blockRoomReqJson.hotelRoomsDetails", []) ||
    get(trips, "0.blockRoomResJson.blockRoomResult.hotelRoomsDetails", []);
  const totalFare = hotelRoomDetails.reduce((acc, room) => {
    const priceDetails = room.price || {};
    const roomPrice = priceDetails.roomPrice || 0;
    const tax = priceDetails.tax || 0;
    const tavaMarkup = priceDetails.tavaMarkup || 0;
    const otherCharges = priceDetails.otherCharges || 0;
    const totalPrice = roomPrice + tax + tavaMarkup + otherCharges;
    return acc + totalPrice;
  }, 0);
  return totalFare;
};
