import { DEFAULT_CURRENCY_CODE } from "../../constants";

const getProcessRefundRequestMapping = (
  bookingInfo,
  currency = DEFAULT_CURRENCY_CODE
) => {
  const { pnr } = bookingInfo;
  const refundRequest = {
    pnr,
    bookingId: "",
    isPartialRefund: "",
    refundableAmount: "",
    remark: "",
    currency,
  };

  return [refundRequest];
};

export default getProcessRefundRequestMapping;
