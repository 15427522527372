import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { camelCase, get, isEmpty, mapKeys } from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import ErrorMessage from "../../atoms/ErrorMessage";
import HotelListItem from "../../atoms/HotelListItem";
import { selectHotelLocations } from "../../organisms/Search";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";
import { LocationMarker, RenderSVG } from "../../../assets/icons";
import popularCitiesData from "../../../assets/json/popularHotelLocations.json";
import {
  DEFAULT_VALUES,
  WINDOWS_EVENTS,
  SEARCH_SECTION,
  KEYBOARD_KEY_CODES,
} from "../../../constants";

const { EMPTY_STRING, EMPTY_OBJECT, ZERO, ONE } = DEFAULT_VALUES;
const { UP_KEY_CODE, DOWN_KEY_CODE, ENTER_KEY_CODE } = KEYBOARD_KEY_CODES;
const { CLICK } = WINDOWS_EVENTS;
const { HOTEL } = SEARCH_SECTION;
const { FETCH_LOCATIONS } = SPINNER_NAMES;

const HotelsLocationPicker = ({ name, handleLocationChange, source }) => {
  const {
    validateForm,
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    values,
  } = useFormikContext();
  const hotelLocations = useSelector(selectHotelLocations);
  const [searchValue, setSearchValue] = useState(
    get(values, `${name}.hotelName`) ||
      get(values, `${name}.cityName`, EMPTY_STRING)
  );
  const [selectedValueIndex, setSelectedValueIndex] = useState(null);
  const [show, setShow] = useState();

  const locationsOptionsRef = useRef(null);
  const inputRef = useRef();
  const { t } = useTranslation();
  const sourceLocationCode = get(values, name, EMPTY_OBJECT);
  const KEY_CODES = [UP_KEY_CODE, DOWN_KEY_CODE, ENTER_KEY_CODE];

  const renderFieldError = () =>
    get(errors, name, false) &&
    get(touched, name, false) && (
      <ErrorMessage errorMessage={get(errors, name)} />
    );

  const handleDestinationCodeChange = (data) => {
    const formattedValue = mapKeys(data, (value, key) => camelCase(key));
    setFieldValue(name, formattedValue);
  };

  const handleLocationSelect = (item) => {
    const { countryName, stateProvince, ...locationData } = item;
    handleDestinationCodeChange(locationData);
    setShow(false);
  };

  useEffect(() => {
    !show &&
      setSearchValue(
        get(sourceLocationCode, "hotelName") ||
          get(sourceLocationCode, "cityName", EMPTY_STRING)
      );
    validateForm();
  }, [show, sourceLocationCode]);

  const locationChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
    handleLocationChange({ inputValue });
  };

  const handleKeyDown = ({ keyCode }) => {
    if (KEY_CODES.includes(keyCode)) {
      let updatedSelectedIndex = selectedValueIndex;

      if (selectedValueIndex !== null) {
        if (
          keyCode === DOWN_KEY_CODE &&
          selectedValueIndex < hotelLocations?.length - ONE
        )
          updatedSelectedIndex = +selectedValueIndex + ONE;
        else if (keyCode === UP_KEY_CODE && selectedValueIndex !== ZERO)
          updatedSelectedIndex = +selectedValueIndex - ONE;
        else if (keyCode === ENTER_KEY_CODE) {
          updatedSelectedIndex = +selectedValueIndex;
          document.body.click();
        }
      } else if (
        (selectedValueIndex === null ||
          selectedValueIndex > hotelLocations?.length - ONE) &&
        keyCode === DOWN_KEY_CODE
      )
        updatedSelectedIndex = ZERO;

      const selectedLocation = hotelLocations[updatedSelectedIndex];
      handleDestinationCodeChange(selectedLocation);
      setSearchValue(selectedLocation?.hotelName || selectedLocation?.cityName);
      setSelectedValueIndex(updatedSelectedIndex);
    }
  };

  useEffect(() => {
    const selected = locationsOptionsRef.current?.querySelector(".active");

    if (selected)
      selected.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
  }, [selectedValueIndex]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (inputRef.current?.contains(e.target)) setShow(true);
      else setShow(false);
    };
    document.addEventListener(CLICK, checkIfClickedOutside);

    return () => {
      document.removeEventListener(CLICK, checkIfClickedOutside);
    };
  }, [searchValue]);

  useEffect(() => {
    !show && setFieldTouched(name, true);
  }, [show]);

  const renderHotelLocations = (hotelLocations) => (
    <div className='flex flex-col' ref={locationsOptionsRef}>
      {hotelLocations.map((item, index) => (
        <button
          key={item.destinationId}
          className={classNames(
            "py-2 px-3 flex items-center gap-3 hover:bg-blue-300 cursor-pointer text-start",
            { "bg-blue-300 active": selectedValueIndex === index }
          )}
          onClick={() => handleLocationSelect(item)}
        >
          <HotelListItem hotelData={item} />
        </button>
      ))}
    </div>
  );

  return (
    <div
      className={classNames("col-span-2 sm:col-span-1 text-black", {
        "lg:col-span-4 col-span-2": source === HOTEL,
      })}
    >
      <div>
        <div className='relative'>
          <div className='icon absolute left-3 top-1/2 -translate-y-1/2'>
            <RenderSVG
              Svg={LocationMarker}
              className='text-contrast-400'
              alt='Location Icon'
            />
          </div>
          <input
            autoComplete='off'
            type='search'
            className='bg-white rounded-md shadow-sm hover:ring-2 hover:ring-primary-50 border border-contrast-300 w-full flex items-center gap-2 py-3.5 px-3 pl-10'
            placeholder={t(`searchSection.whereTo`)}
            ref={inputRef}
            value={searchValue}
            onChange={locationChange}
            onFocus={(e) => {
              if(show) return;
              e.target.select();
              setSearchValue("");
              handleLocationChange({ inputValue: "" });
            }}
            onKeyDown={handleKeyDown}
            selected
          />
          <div>{renderFieldError()}</div>
        </div>
        {show && (
          <div
            className={classNames(
              `p-6 bg-white shadow-2xl rounded-lg absolute z-30 w-full sm:min-w-[498px] max-h-80 min-h-[150px] overflow-y-scroll top-full mt-2`,
              {
                "no-scrollbar": isEmpty(hotelLocations),
                "scrollbar-light": !isEmpty(hotelLocations),
              }
            )}
          >
            {searchValue && (
              <Spinner name={FETCH_LOCATIONS}>
                {!isEmpty(hotelLocations) ? (
                  renderHotelLocations(hotelLocations)
                ) : (
                  <p className='text-center'>No results found</p>
                )}
              </Spinner>
            )}
            {(!searchValue || isEmpty(hotelLocations)) && (
              <div>
                <div className='text-light font-bold'>Popular Destinations</div>
                {renderHotelLocations(popularCitiesData)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HotelsLocationPicker;
