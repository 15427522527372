import { DEFAULT_VALUES, TBO_FARE_TYPES, TRAVELER_TYPE } from "../constants";

const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;
const { SENIOR_CITIZEN } = TBO_FARE_TYPES;
const { ZERO, ONE, TWO, TWELVE, FIFTEEN } = DEFAULT_VALUES;
const MIN_AGE_FOR_SENIOR_CITIZEN = 60;

const getValidatedDate = (
  years,
  daysToSkip,
  departureDate,
  travelerType,
  fareType
) => {
  const currentDate =
    !departureDate ||
    travelerType === HELD_INFANT ||
    fareType === SENIOR_CITIZEN
      ? new Date()
      : new Date(departureDate);
  currentDate.setFullYear(currentDate.getFullYear() - years);
  currentDate.setDate(currentDate.getDate() - daysToSkip);
  return currentDate;
};

const getFormattedTravelerInitialDate = (
  travelerType,
  fareType,
  departureDate
) => {
  const minYears = {
    [ADULT]:
      fareType && fareType === SENIOR_CITIZEN
        ? MIN_AGE_FOR_SENIOR_CITIZEN
        : TWELVE,
    [CHILD]: TWO,
    [HELD_INFANT]: ZERO,
  }[travelerType];
  const daysToSkip = (fareType && fareType === SENIOR_CITIZEN) ? TWO : (travelerType == HELD_INFANT ? FIFTEEN : ONE);
  const date = getValidatedDate(
    minYears,
    daysToSkip,
    departureDate,
    travelerType,
    fareType
  );
  const year = date.getFullYear();
  const month = String(date.getMonth() + ONE).padStart(TWO, "0");
  const day = String(date.getDate()).padStart(TWO, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export default getFormattedTravelerInitialDate;
