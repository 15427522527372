import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { LeftIcon, RenderSVG } from "../../../assets/icons";
import { DEFAULT_VALUES, FLIGHT_TRIP_TYPES } from "../../../constants";
import { MODALS } from "../../../components/organisms/AppModals";
import {
  selectFlightCancellationCharges,
  getFlightCancellationCharges,
} from "../../MyTrips";
import { selectCountryInfo } from "../../Profile";
import { selectSelectedTrip } from "../Trips";
import {
  selectSelectedModal,
  setSelectedModal,
} from "../../../components/organisms/Modal";
import JourneyInfo from "./JourneyInfo";
import RenderCancellationSteps from "./RenderCancellationSteps";
import { cancelFlightBooking } from "../../MyTrips/booking.actions";
import { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import FlightCancellationModal from "../../../components/organisms/AppModals/CancellationModal";

const { ZERO, ONE, TWO, EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } =
  DEFAULT_VALUES;
const { ROUND_TRIP } = FLIGHT_TRIP_TYPES;
const { FLIGHT_CANCELLATION_MODAL } = MODALS;
const { FLIGHT_CANCELLATION } = SPINNER_NAMES;

const cancellationSteps = [
  {
    step: ONE,
    label: "Select Reason for Cancellation",
    btnText: "View Refunds",
  },
  { step: TWO, label: "View Refunds", btnText: "Cancel Booking" },
];
const OTHER = "Other";

const getRemarks = (selectedReason) => {
  const { listedReason, otherReason } = selectedReason;
  if (listedReason !== OTHER) return listedReason;
  return isEmpty(otherReason) ? listedReason : otherReason;
};

const getValidationMessage = (
  listedReason,
  shouldSelectJourney,
  selectedCancellationCharges
) => {
  if (isEmpty(listedReason))
    return "Please select the reason for cancellation first.";
  else if (shouldSelectJourney)
    return "Please select the flight for cancellation.";
  else if (isEmpty(selectedCancellationCharges)) return "Unable to view refund";
};

const FlightCancellation = () => {
  const dispatch = useDispatch();
  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedModal = useSelector(selectSelectedModal);
  const selectedCancellationCharges = useSelector(
    selectFlightCancellationCharges
  );
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const [PNRList, setPNRList] = useState(EMPTY_ARRAY);
  const [currentStep, setCurrentStep] = useState(ONE);
  const [selectedReason, setSelectedReason] = useState({
    listedReason: "",
    otherReason: "",
  });
  const [otherReasonError, setOtherReasonError] = useState(false);
  const [selectedJourneyPnr, setSelectedJourneyPnr] = useState(EMPTY_ARRAY);
  const [cancellationDetails, setCancellationDetails] = useState(EMPTY_OBJECT);
  const [totalAmount, setTotalAmount] = useState(ZERO);
  const [cancellationCharges, setCancellationCharges] = useState(ZERO);
  const [cancellationStatus, setCancellationStatus] = useState();
  const [selectedCancelDetails, setSelectedCancelDetails] = useState([]);
  const [canViewDetails, setCanViewDetails] = useState(true);
  const errorRef = useRef(null);

  const { ip, currency } = selectedCountryInfo || EMPTY_OBJECT;
  const { id: tavaBookingId, flights = EMPTY_ARRAY } =
    selectedTrip || EMPTY_OBJECT;
  const tripType = flights[ZERO]?.journeyType;
  const isRoundTripDomestic = tripType === ROUND_TRIP && flights.length > ONE;
  const grandTotal =
    flights[ZERO]?.bookingJson?.journeyDetails[ZERO]?.price?.grandTotal;

  const itineraries = flights.reduce((itineraryAcc, flight) => {
    const journeyDetails = get(
      flight,
      "bookingJson.journeyDetails.0",
      EMPTY_OBJECT
    );

    const pnr = get(flight, "pNR", EMPTY_STRING);
    const tenantStatus = get(flight, "tenantStatus", EMPTY_STRING);

    const itinerariesForFlight = get(
      journeyDetails,
      "itineraries",
      EMPTY_ARRAY
    );

    const enrichedItineraries = itinerariesForFlight.map((itinerary) => ({
      ...itinerary,
      journeyDetails: journeyDetails,
      pnr,
      tenantStatus,
    }));

    return [...itineraryAcc, ...enrichedItineraries];
  }, []);

  useEffect(() => {
    if (!selectedTrip) return;
    setPNRList(flights.map((each) => each.pNR));
    dispatch(
      getFlightCancellationCharges({
        bookingId: tavaBookingId,
        endUserIp: ip,
      })
    ).then((res) => {
      const errorMessage = get(res, "payload[0].error.message", EMPTY_STRING);
      !isEmpty(errorMessage) && setCanViewDetails(false) && toast.error(errorMessage);
    });
  }, [selectedTrip]);

  useEffect(() => {
    if (isRoundTripDomestic) {
      const amount = flights.reduce((acc, flight) => {
        const pnr = flight?.pNR;
        const { price } = flight?.bookingJson?.journeyDetails[ZERO];
        const grandTotal = selectedJourneyPnr.includes(pnr)
          ? price.grandTotal
          : ZERO;
        return acc + grandTotal;
      }, ZERO);

      const cancellationCharges = flights.reduce((acc, flight, index) => {
        const pnr = flight?.pNR;
        const charges = selectedJourneyPnr.includes(pnr)
          ? selectedCancellationCharges[index]?.cancellationCharge || ZERO
          : ZERO;
        return acc + charges;
      }, ZERO);

      setTotalAmount(amount);
      setCancellationCharges(cancellationCharges);
    }
  }, [selectedJourneyPnr, selectedCancelDetails, isRoundTripDomestic, selectedCancellationCharges]);

  useEffect(() => {
    if (!isEmpty(selectedCancellationCharges)) {
      const cancellationCharges = selectedCancellationCharges.reduce(
        (acc, item) => {
          const { pNR, cancellationCharge, refundAmount } = item;
          acc[pNR] = {
            cancellationCharge,
            refundAmount,
          };
          return acc;
        },
        {}
      );
      setCancellationDetails(cancellationCharges);
    }
  }, [selectedCancellationCharges]);

  const handleNextStep = () => {
    if (
      selectedReason.listedReason === OTHER &&
      isEmpty(selectedReason.otherReason)
    )
      setOtherReasonError(true);
    else
      currentStep === cancellationSteps.length
        ? dispatch(setSelectedModal(FLIGHT_CANCELLATION_MODAL))
        : setCurrentStep(currentStep + ONE);
  };

  const handleStepsAndCancellation = (step = ZERO) => {
    if (
      selectedReason.listedReason === OTHER &&
      isEmpty(selectedReason.otherReason)
    )
      setOtherReasonError(true);
    else {
      step !== ZERO
        ? (currentStep !== ONE || selectedReason.listedReason) &&
          setCurrentStep(step)
        : dispatch(setSelectedModal(FLIGHT_CANCELLATION_MODAL));
    }
  };

  const handleFlightCancellation = () => {
    const requestBody = {
      endUserIp: ip,
      flightCancellationRequests: isRoundTripDomestic
        ? selectedCancelDetails?.map((selectedCancelDetail) => {
            return {
              pNR: selectedCancelDetail?.pnr,
              remarks: getRemarks(selectedReason),
              cancellationDetails: selectedCancelDetail?.cancellationDetails 
            };
          })
        : [
            {
              pNR: PNRList[ZERO],
              remarks: getRemarks(selectedReason),
              cancellationDetails: {cancellationCharge: amountDetails?.cancellationCharges , refundAmount: (amountDetails?.totalPrice - amountDetails?.cancellationCharges)}
            },
          ],
    };

    dispatch(cancelFlightBooking({ requestBody, tavaBookingId })).then((res) =>
      setCancellationStatus(res.payload.data)
    );
  };

  const handleJourneySelection = (pnr) => {
    setSelectedCancelDetails((prevSelected) => {
      const pnrExists = selectedCancelDetails.some((cancelDetails) => cancelDetails.pnr === pnr)
      if (pnrExists) {
        return prevSelected.filter(({ item }) => item !== pnr)
      } else {
        return [...prevSelected, { pnr, cancellationDetails: cancellationDetails[`${pnr}`] }]
      }
    });

    setSelectedJourneyPnr((prevSelected) =>
      prevSelected.includes(pnr)
        ? prevSelected.filter((item) => item !== pnr)
        : [...prevSelected, pnr]
    );
  };

  const amountDetails = {
    totalPrice: isRoundTripDomestic ? totalAmount : grandTotal,
    currency: currency?.code,
    cancellationCharges: isRoundTripDomestic
      ? cancellationCharges || 0
      : cancellationDetails[PNRList[ZERO]]?.cancellationCharge || 0,
    discount: "",
  };

  return (
    <div className='p-4'>
      <header className='relative bg-primary-200 '>
        <div className='absolute w-full h-full top-0 left-0 bg-gradient-to-r from-gray-900/0 to-teal-900/50' />
        <div className='container px-4 pt-12 pb-20 mx-auto relative'>
          <div className='grid grid-cols-12 gap-4  max-w-5xl mx-auto'>
            <div className='col-span-12 md:col-span-4 lg:col-span-7'>
              <h4 className='text-xl text-teal-950 mb-1 font-bold'>
                Flight Cancellation
              </h4>
            </div>
          </div>
        </div>
      </header>
      <div className='pb-16 relative'>
        <div className='container px-4 mx-auto -mt-12'>
          <div className='w-full'>
            <div className='bg-white rounded-lg shadow-lg max-w-5xl flex flex-col justify-center mx-auto min-h-[50vh]'>
              <div
                className={classNames("flex flex-col gap-4 rounded-lg p-4", {
                  "flex flex-col !gap-0": !isRoundTripDomestic,
                })}
              >
                {itineraries.map((itinerary, index) => (
                  <JourneyInfo
                    key={index}
                    pnrList={PNRList}
                    itinerary={itinerary}
                    cancellationDetails={cancellationDetails}
                    index={index}
                    isRoundTripDomestic={isRoundTripDomestic}
                    selectedJourneyPnr={selectedJourneyPnr}
                    currentStep={currentStep}
                    handleJourneySelection={handleJourneySelection}
                  />
                ))}
              </div>
              <div className='p-4'>
                <div className='flex gap-2 items-center mb-6'>
                  {cancellationSteps.map(({ step, label }) => (
                    <button
                      key={step}
                      onClick={() => handleStepsAndCancellation(step)}
                      className={classNames(
                        "flex gap-2 items-center rounded-3xl p-2 cursor-pointer",
                        {
                          "bg-primary-100 text-primary-700 ring-1 ring-primary-700":
                            step === currentStep,
                          "bg-gray-100 text-gray-900": step !== currentStep,
                          "!cursor-not-allowed":
                            currentStep === ONE &&
                            (isEmpty(selectedJourneyPnr) ||
                              isEmpty(selectedReason.listedReason)) &&
                            step !== ONE,
                        }
                      )}
                      disabled={
                        currentStep === ONE &&
                        step !== ONE &&
                        (isEmpty(selectedJourneyPnr) ||
                          isEmpty(selectedReason.listedReason))
                      }
                    >
                      <span
                        className={classNames(
                          "text-xs rounded-full h-5 w-5 grid place-content-center font-bold",
                          {
                            "bg-primary-700 text-white": step === currentStep,
                            "bg-black/10 text-gray-900": step !== currentStep,
                          }
                        )}
                      >
                        {step}
                      </span>
                      <span className='text-xs font-semibold'>{label}</span>
                    </button>
                  ))}
                </div>
                <RenderCancellationSteps
                  currentStep={currentStep}
                  selectedReason={selectedReason}
                  setSelectedReason={setSelectedReason}
                  totalRooms={ZERO}
                  amountDetails={amountDetails}
                  otherReasonError={otherReasonError}
                  setOtherReasonError={setOtherReasonError}
                  showAdditionalDetails={false}
                />
                <div className='flex justify-between'>
                  {currentStep > ONE && (
                    <button
                      onClick={() => setCurrentStep(currentStep - ONE)}
                      className='py-3 px-4 rounded-md bg-white hover:bg-primary-100 active:bg-white shadow-sm text-sm text-primary-900 font-semibold flex items-center gap-2'
                    >
                      <RenderSVG Svg={LeftIcon} width='20' height='20' />
                      Back
                    </button>
                  )}
                  <div>
                    {otherReasonError && (
                      <span
                        ref={errorRef}
                        className='text-red-500 text-[10px] md:text-sm'
                      >
                        Please enter a reason for cancellation.
                      </span>
                    )}
                  </div>
                  {(isEmpty(selectedReason.listedReason) ||
                    isEmpty(selectedCancellationCharges) ||
                    (isRoundTripDomestic && isEmpty(selectedJourneyPnr))) && (
                    <Tooltip
                      id='cancellation-reason-tooltip'
                      className='!w-56 !sm:w-80 !bg-primary-600 !rounded-lg !z-50 !opacity-75'
                    />
                  )}
                  <button
                    onClick={handleNextStep}
                    className='py-3 px-4 rounded-md bg-primary-600 disabled:bg-primary-400 disabled:cursor-not-allowed hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium'
                    disabled={
                      isEmpty(selectedReason.listedReason) ||
                      isEmpty(selectedCancellationCharges) ||
                      (isRoundTripDomestic && isEmpty(selectedJourneyPnr))
                      || !canViewDetails
                    }
                    data-tooltip-id='cancellation-reason-tooltip'
                    data-tooltip-place='bottom'
                    data-tooltip-content={getValidationMessage(
                      selectedReason.listedReason,
                      isRoundTripDomestic && selectedJourneyPnr,
                      selectedCancellationCharges
                    )}
                  >
                    {cancellationSteps[currentStep - ONE]?.btnText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedModal === FLIGHT_CANCELLATION_MODAL && (
        <FlightCancellationModal
          handleCancellation={handleFlightCancellation}
          spinnerName={FLIGHT_CANCELLATION}
          isRoundTripDomestic={isRoundTripDomestic}
          itineraries={itineraries}
          flightCancellationStatus={cancellationStatus}
          selectedJourneyPnr={selectedJourneyPnr}
        />
      )}
    </div>
  );
};

export default FlightCancellation;
