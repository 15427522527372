import { getCityNameByIata } from "./getAirportInfoByIata";
import { get } from "lodash";
import { DEFAULT_VALUES } from "../constants";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;

export const getSegmentCityName = (segment) =>
  `${getCityNameByIata(segment[ZERO]?.origin)} - ${getCityNameByIata(
    segment[ZERO]?.destination
  )}`;

export const getSegmentsTitle = (priceReq, resultIndex) => {
  const requiredPriceReq = priceReq.find(
    (req) => req.resultIndex === resultIndex
  );
  const requiredPriceReqPrice = get(requiredPriceReq, "price", EMPTY_OBJECT);
  const { itineraries = EMPTY_ARRAY } = requiredPriceReqPrice;
  return itineraries.reduce((acc, oneWayFlight, index) => {
    const segmentTitleArray = oneWayFlight.segments.reduce((segTitle, seg) => {
      if (segTitle.length) {
        const arrivalCityName = getCityNameByIata(seg.arrival.iataCode);
        segTitle[ONE] = arrivalCityName;
      } else {
        const departureCityName = getCityNameByIata(seg.departure.iataCode);
        const arrivalCityName = getCityNameByIata(seg.arrival.iataCode);
        segTitle[ZERO] = departureCityName;
        segTitle[ONE] = arrivalCityName;
      }
      return segTitle;
    }, []);
    acc[index] = `${segmentTitleArray[ZERO]}-${segmentTitleArray[ONE]}`;
    return acc;
  }, []);
};
