import { TRAVELER_TYPE, DEFAULT_VALUES, SEARCH_SECTION } from "../constants";

const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;
const { ZERO, TWO, TWELVE, FIFTEEN } = DEFAULT_VALUES;
const { FLIGHT, HOTEL } = SEARCH_SECTION;
const TRAVELER_NOT_ELIGIBLE ="notEligible";
const EIGHTEEN=18;

export const getTravelersAgeFromDOB = (dob) => {
  if (!dob) return null;
  const currentDate = new Date();
  const birthDate = new Date(dob);
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDifference = currentDate.getMonth() - birthDate.getMonth();

  if (
    monthDifference < ZERO ||
    (monthDifference === ZERO && currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  let days = null;
  if (!age) {
    const timeDifferenceInMilliseconds = currentDate - birthDate;
    days = Math.floor(timeDifferenceInMilliseconds / (24 * 60 * 60 * 1000));
  }
  return !age ? { age, days } : age;
};

export const getTravelerCategory = (dob, scenario) => {
  const ageDetails = getTravelersAgeFromDOB(dob);
  if (ageDetails === null) return ADULT;

  if (typeof ageDetails === "object") {
    const { days } = ageDetails;

    switch (scenario) {
      case FLIGHT:
        {
          if (days >= FIFTEEN) return HELD_INFANT;
          else return TRAVELER_NOT_ELIGIBLE;
        }
      case HOTEL:
        return CHILD;
    }
  } else {
    const age = ageDetails;

    switch (scenario) {
      case FLIGHT: {
        if (age >= TWELVE) return ADULT;
        if (age >= TWO) return CHILD;
        return HELD_INFANT;
      }

      case HOTEL: {
        if (age < EIGHTEEN) return CHILD;
        else return ADULT;
      }

      default:
        return null;
    }
  }
};

export const getCategorizedCountForTravelers = (passengers, scenario) => {
  const categories = passengers?.map((passenger) => {
    const dob = passenger.travelers?.dateOfBirth;
    return getTravelerCategory(dob, scenario);
  });

  const adultCount = categories.filter((category) => category === ADULT).length;
  const childCount = categories.filter((category) => category === CHILD).length;
  const infantCount = categories.filter(
    (category) => category === HELD_INFANT
  ).length;

  return {
    adultCount,
    childCount,
    infantCount,
  };
};
