import { useTranslation } from "react-i18next";
import Modal from "../../../organisms/Modal";
import { MODAL_SIZE, } from "../../../../constants";
import { selectPricePolicyInfo } from "../../../../screens/HotelInfo/hotelInfo.selector";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { parseDescription } from "../../../../screens/HotelInfo/HotelInfo";

const { LG } = MODAL_SIZE;

const HotelPolicyModal = ({ handleClose = () => { } }) => {
    const { t } = useTranslation();
    const hotelData = useSelector(selectPricePolicyInfo);
    const hotelPolicyData = get(hotelData, 'blockRoomResult.hotelPolicyDetail', '');
    const hotelPolicyParsedData = parseDescription(hotelPolicyData)

    return (
        <Modal
            size={LG}
            shouldShowModalFromProps
            title={t('Hotel Policy')}
            handleClose={handleClose}
            modalClassname='no-scrollbar h-[75vh]'
            titleClassname='top-0 sticky border-b bg-white z-50'
        >
        <div className="border-contrast-200 m-5 p-2" >{hotelPolicyParsedData}</div>
        </Modal>
    );
};

export default HotelPolicyModal;
