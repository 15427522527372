import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { DEFAULT_VALUES, SKELETON_COLOR } from "../../../constants";

const { ONE } = DEFAULT_VALUES;
const { BASE_COLOR, HIGHLIGHT_COLOR } = SKELETON_COLOR;

const TBOAPICallSkeleton = () => {
  return (
    <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHLIGHT_COLOR}>
      <Skeleton count={ONE} height={10} width={"100%"} />
    </SkeletonTheme>
  );
};

export default TBOAPICallSkeleton;
