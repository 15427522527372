import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import { selectSelectedTrip } from "../Trips";
import config from "../../../config.json";
import {
  DEFAULT_VALUES,
  BOOKING_STATUS_CODES,
  SEARCH_SECTION,
} from "../../../constants";
import Header from "./Header";
import Flight from "./Flight";
import Hotel from "./Hotel";

const { ZERO, ONE, EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { FLIGHT, HOTEL } = SEARCH_SECTION;
const {
  CONFIRMED,
  PARTIALLY_CONFIRMED,
  PARTIALLY_CANCELED,
} = BOOKING_STATUS_CODES;
const { brandName } = config;

const getBookingStatus = (selectedService, service) => {
  const allStatus = selectedService.map(
    ({ tenantBookingStatus, tenantStatus }) =>
      service == HOTEL ? tenantBookingStatus : tenantStatus
  );
  const uniqueCollectiveStatus = new Set(allStatus);
  if ([...uniqueCollectiveStatus].length === ONE)
    return [...uniqueCollectiveStatus][ZERO];
  else {
    if ([...uniqueCollectiveStatus].includes(CONFIRMED))
      return PARTIALLY_CONFIRMED;
    return PARTIALLY_CANCELED;
  }
};

const RenderPreviewDetails = ({ service }) => {
  switch (service) {
    case FLIGHT:
      return <Flight />;
    case HOTEL:
      return <Hotel />;
  }
};

const Preview = () => {
  const selectedTrip = useSelector(selectSelectedTrip);
  const { service } = useParams();

  const [selectedService, setSelectedService] = useState(EMPTY_ARRAY);
  const { id = EMPTY_STRING } = selectedTrip || EMPTY_OBJECT;
  const bookingStatus = getBookingStatus(selectedService, service);

  useEffect(() => {
    if (!selectedTrip) return;
    const { flights = EMPTY_ARRAY, hotels = EMPTY_ARRAY } = selectedTrip;
    if (service === FLIGHT) setSelectedService(flights);
    if (service === HOTEL) setSelectedService(hotels);
  }, [selectedTrip, service]);


  return (
    <div>
      <Helmet>
        <title>{brandName} | Preview</title>
      </Helmet>
      <Header id={id} status={bookingStatus} />
      <div className='pb-16 pt-2 relative'>
        <div className='container px-4 mx-auto'>
          <div className='main flex-1 flex flex-col'>
            <div className='bg-white border border-contrast-200 rounded-xl'>
              <ul className='nav-tab flex items-center justify-center'>
                {" "}
                <li className='flex-1'>
                  <button className='flex justify-center px-6 py-4 gap-3 items-center cursor-default border-b-2 w-full text-sm border-primary-500 text-primary-500'>
                    <span className='font-semibold text-current text-sm capitalize'>
                      {service}
                    </span>
                  </button>
                </li>
              </ul>
              <RenderPreviewDetails service={service} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
