import HotelInfo from "./HotelInfo";
import * as actions from "./hotelInfo.actions";
import * as slice from "./hotelInfo.reducer";
import * as selectors from "./hotelInfo.selector";

export const { getHotelInfo } = actions;
export const {
  name,
  reducer,
  actions: {
    setHotelInfoReqBody,
    setSelectedRoomInfo,
    setPricePolicyReqBody,
    setPricePolicyInfo,
    setHotelInfo,
    resetHotelInfo,
    setHotelState
  },
} = slice;
export const {
  selectHotelInfo,
  selectHotelInfoReqBody,
  selectSelectedRoomInfo,
  selectPricePolicyReqBody,
  selectPricePolicyInfo,
} = selectors;

export default HotelInfo;
