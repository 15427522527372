import React, {
  useState,
  useRef,
  useImperativeHandle,
  useCallback,
} from "react";
import classNames from "classnames";
import { DEFAULT_VALUES } from "../../../constants";
import { LeftArrow, RightArrow, RenderSVG } from "../../../assets/icons";

const { TWO, THREE, EMPTY_STRING } = DEFAULT_VALUES;

const CarouselIcon = ({ icon, className, handleScroll }) => (
  <div className={`bg-white w-12 h-full ${className} pt-5`}>
    <button
      type='button'
      className='flex-none w-8 h-8 rounded-full ml-2 bg-primary-600 justify-center items-center grid'
      onClick={handleScroll}
    >
      <RenderSVG
        Svg={icon}
        className='grid place-content-center text-white'
        alt='Previous'
      />
    </button>
  </div>
);

const Tabs = React.forwardRef(
  (
    {
      tabs = EMPTY_ARRAY,
      showCarousel = TWO,
      className = EMPTY_STRING,
      tabStyles,
    },
    ref
  ) => {
    const scrollRef = useRef();

    const defaultSelectedTab = tabs.find((tab) => tab.default);

    const [selectedTabId, setSelectedTabId] = useState(defaultSelectedTab?.id);

    const {
      showFullBottomBorder = false,
      tabBottomBorderWidth = "4",
      tabSectionWidth = "full",
      showInactiveBottomBorder = true,
    } = tabStyles || {};

    useImperativeHandle(ref, () => ({
      setSelectedTabId,
    }));

    const scrollLeftRight = (scrollValue) =>
      scrollRef.current?.scrollBy({ behavior: "smooth", left: scrollValue });

    const getSelectedTabElement = useCallback(
      () => tabs.find((tab) => tab.id === selectedTabId)?.element,
      [selectedTabId, tabs]
    );

    return (
      <>
        <div
          className={classNames("flex bg-white", {
            "border-b-2": showFullBottomBorder,
          })}
        >
          {showCarousel > THREE && (
            <CarouselIcon
              icon={LeftArrow}
              className='rounded-tl-2xl'
              handleScroll={() => scrollLeftRight(-200)}
            />
          )}
          <div
            className={`w-${tabSectionWidth} flex items-center justify-between overflow-x-scroll no-scrollbar`}
            ref={scrollRef}
          >
            {tabs.map(({ id, title }) => (
              <button
                key={id}
                className={classNames(
                  "text-sm font-semibold w-full w-min-100px text-center px-6 py-4 text-contrast-600 cursor-pointer transition-all duration-500 truncate",
                  {
                    [`border-primary-600 text-primary-600 border-b-${tabBottomBorderWidth}`]:
                      selectedTabId === id,
                    [`border-b-${tabBottomBorderWidth}`]:
                      showInactiveBottomBorder,
                  },
                  className
                )}
                onClick={(e) => {
                  setSelectedTabId(id);
                  e.stopPropagation();
                }}
              >
                {title}
              </button>
            ))}
          </div>
          {showCarousel > THREE && (
            <CarouselIcon
              icon={RightArrow}
              className='rounded-tr-2xl'
              handleScroll={() => scrollLeftRight(200)}
            />
          )}
        </div>
        {getSelectedTabElement()}
      </>
    );
  }
);

export default Tabs;
