import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { Range } from "react-range";
import { useCollapse } from "react-collapsed";
import classNames from "classnames";
import { ChevronDown, RenderSVG } from "../../../assets/icons";
import { priceFormatter } from "../../../helper";
import { DEFAULT_VALUES, CURRENCY_SYMBOLS } from "../../../constants";
import { selectCountryInfo } from "../../Profile";
import { selectTenantDetails } from "../../Auth";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import { isEmpty } from "lodash";

const { ZERO, ONE, INFINITY, HUNDRED, EMPTY_STRING } = DEFAULT_VALUES;

const PriceFilter = ({
  priceData,
  type,
  activeTypeFilter,
  handlePriceChange,
  flightType,
}) => {
  const { t } = useTranslation();
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const tenantDetails= useSelector(selectTenantDetails)
  const tenantConfig = get(
    tenantDetails,
    "tenantConfig"
  );

  let minPriceRange = priceData?.minPrice || ONE;
  const maxPriceRange = priceData?.maxPrice || INFINITY;

  const isSliderDisabled = priceData?.minPrice === priceData?.maxPrice;

  minPriceRange =
    priceData?.minPrice === priceData?.maxPrice
      ? minPriceRange - ONE
      : minPriceRange;

  const [priceRangeValues, setPriceRangeValues] = useState([
    activeTypeFilter?.minPrice > minPriceRange? activeTypeFilter?.minPrice : minPriceRange,
    activeTypeFilter?.maxPrice < maxPriceRange? activeTypeFilter?.maxPrice : maxPriceRange,
  ]);
  const [sliderValues, setSliderValues] = useState([
    activeTypeFilter?.minPrice > minPriceRange? activeTypeFilter?.minPrice : minPriceRange,
    !isEmpty(activeTypeFilter?.maxPrice) && activeTypeFilter?.maxPrice < maxPriceRange? activeTypeFilter?.maxPrice : maxPriceRange,
  ]);
  const [showError, setShowError] = useState(false);
  
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  const currencySymbol = get(
    selectedCountryInfo,
    "currency.symbol",
    CURRENCY_SYMBOLS.INR
  );

  useEffect(() => {
    if (!priceData || !activeTypeFilter) return;
    const { minPrice, maxPrice } = activeTypeFilter;
    setSliderValues([
      minPrice &&
      priceData.minPrice <= minPrice &&
      priceData.maxPrice >= minPrice
        ? minPrice
        : priceData.minPrice,
      maxPrice &&
      priceData.minPrice <= maxPrice &&
      priceData.maxPrice >= maxPrice
        ? maxPrice
        : priceData.maxPrice,
    ]);
  }, [priceData, activeTypeFilter]);

  useEffect(() => {
    setPriceRangeValues(sliderValues);
  }, [sliderValues]);

  useEffect(() => {
    const [minPrice, maxPrice] = priceRangeValues;
    if (
      (+minPrice <= +maxPrice &&
        +minPrice >= +minPriceRange &&
        +maxPrice <= +maxPriceRange &&
        +maxPrice > +minPriceRange) ||
      !minPrice ||
      !maxPrice
    )
      setShowError(false);
    else setShowError(true);
  }, [priceRangeValues, minPriceRange, maxPriceRange]);

  const handleSliderUpdate = useCallback((values) => {
    setSliderValues(values);
    handlePriceChange({
      filter: {
        minPrice: values[ZERO] !== minPriceRange ? values[ZERO] : EMPTY_STRING,
        maxPrice: values[ONE] !== maxPriceRange ? values[ONE] : EMPTY_STRING,
      },
      flightType,
      type,
    });
  });

  const handleMinValueChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    setPriceRangeValues([value, priceRangeValues[ONE]]);
    if (
      value >= minPriceRange &&
      value < maxPriceRange &&
      value < priceRangeValues[ONE]
    )
      handleSliderUpdate([value, priceRangeValues[ONE]]);
  };

  const handleMaxValueChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    setPriceRangeValues([priceRangeValues[ZERO], value]);
    if (
      value >= minPriceRange &&
      value <= maxPriceRange &&
      value > priceRangeValues[ZERO]
    )
      handleSliderUpdate([priceRangeValues[ZERO], value]);
  };

  const getTrackBackground = ({ values, colors, min, max }) => {
    if (values) {
      const progressValues = sliderValues
        .slice(ZERO)
        .sort((a, b) => a - b)
        .map((value) => ((value - min) / (max - min)) * HUNDRED);

      const middle = progressValues.reduce(
        (acc, point, index) =>
          `${acc}, ${colors[index]} ${point}%, ${
            colors[index + ONE]
          } ${point}%`,
        ""
      );

      return `linear-gradient(to right, ${colors[ZERO]} 0% ${middle}, ${
        colors[colors.length - ONE]
      } 100%)`;
    }
  };

  return (
    <>
      <div className='flex items-center gap-2' {...getToggleProps()}>
        <h4 className='flex-1 text-lg font-semibold text-contrast-800'>
          {t("flightResults.filters.priceRange.header")}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt='Chevron Down Icon'
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className='pt-6'>
          <div className='range-slider mt-10 relative h-1 mb-6'>
            <div className='slider-main h-full absolute w-full px-3'>
              <Range
                min={minPriceRange}
                max={maxPriceRange}
                values={sliderValues}
                onChange={handleSliderUpdate}
                disabled={isSliderDisabled}
                renderTrack={({
                  props: { onMouseDown, onTouchStart, ref },
                  children,
                }) => (
                  <div onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
                    <div
                      ref={ref}
                      className='h-1 mx-4 rounded bg-contrast-300 self-center'
                      style={{
                        background: getTrackBackground({
                          values: [0, 24],
                          colors: ["#D1D5DB", tenantConfig.primaryColor, "#D1D5DB"],
                          min: minPriceRange,
                          max: maxPriceRange,
                        }),
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, index }) => (
                  <div
                    {...props}
                    className={classNames(
                      "h-4 w-4 bg-white flex justify-center items-center rounded-[50%] border border-solid border-contrast-300 !cursor-pointer",
                      { "!cursor-not-allowed": isSliderDisabled }
                    )}
                  >
                    <div className='absolute tooltip tooltip-left !translate-x-0 whitespace-nowrap'>
                      {currencySymbol}&nbsp;
                      {priceFormatter(sliderValues[index])}
                    </div>
                    <div />
                  </div>
                )}
              />
            </div>
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <div className='col-span-1'>
              <div className='form-group'>
                <label className='text-xs text-contrast-900 font-medium mb-1'>
                  {t("flightResults.filters.priceRange.minimum")}
                </label>
                <input
                  type='text'
                  placeholder='Min Price'
                  value={priceFormatter(priceRangeValues[ZERO])}
                  onChange={handleMinValueChange}
                  className='w-full h-auto text-xs py-[9px] px-3 rounded-md shadow-sm border border-contrast-300 text-contrast-900'
                  disabled={isSliderDisabled}
                />
              </div>
            </div>
            <div className='col-span-1'>
              <div className='form-group'>
                <label className='text-xs text-contrast-900 font-medium mb-1'>
                  {t("flightResults.filters.priceRange.maximum")}
                </label>
                <input
                  type='text'
                  placeholder='Max Price'
                  value={priceFormatter(priceRangeValues[ONE])}
                  onChange={handleMaxValueChange}
                  className='w-full h-auto text-xs py-[9px] px-3 rounded-md shadow-sm border border-contrast-300 text-contrast-900'
                  disabled={isSliderDisabled}
                />
              </div>
            </div>
          </div>
          {showError && (
            <ErrorMessage
              errorMessage={`Minimum price < Maximum price and within specified range.[${currencySymbol} ${priceFormatter(
                minPriceRange
              )}
               - ${currencySymbol} ${priceFormatter(maxPriceRange)}]`}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PriceFilter;
