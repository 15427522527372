import { DEFAULT_VALUES, WEEK_DAYS } from "../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;

export const getDayDifference = (startDate, endDate) => {
  const startDay = new Date(startDate);
  const endDay = new Date(endDate);
  startDay.setHours(0, 0, 0, 0);
  endDay.setHours(0, 0, 0, 0);

  const differenceInMilliseconds = endDay.getTime() - startDay.getTime();
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const differenceInDays = Math.floor(
    differenceInMilliseconds / millisecondsInADay
  );

  return differenceInDays;
};

export const getDayFromDate = (dateString) => {
  const date = new Date(dateString).getDay();
  return WEEK_DAYS[date] || EMPTY_STRING;
};
