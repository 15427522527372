import classNames from "classnames";
import { isEmpty } from "lodash";
import { getCityNameByIata } from "../../../../helper";
import { RenderSVG, ChevronUp, ChevronDown } from "../../../../assets/icons";
import { useCollapse } from "react-collapsed";

const MEAL = "meal";
const SEAT = "seat";
const BAGGAGE = "baggage";

const SSRDetailsAccordian = ({
  details,
  title,
  itemType,
  isLCC,
  doesFlightExist,
}) => {
  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });
  return (
    <div className='bg-white rounded-xl border border-contrast-300 overflow-hidden mt-6'>
      <div
        className='px-6 py-4 flex items-center border-b border-contrast-300 bg-contrast-100'
        {...getToggleProps()}
      >
        <h4 className='text-base font-bold text-contrast-900 flex-1'>
          {title}
        </h4>
        <button className='p-2 cursor-pointer shrink-0'>
          <RenderSVG
            Svg={isExpanded ? ChevronUp : ChevronDown}
            className='text-contrast-800'
            width='20'
          />
        </button>
      </div>
      <div
        className='flex flex-col divide-y divide-contrast-300'
        {...getCollapseProps()}
      >
        {renderDetails(details, itemType, isLCC, doesFlightExist)}
      </div>
    </div>
  );
};

const renderDetails = (details, itemType, isLCC, doesFlightExist) => {
  return details?.map((detail, index) => (
    <div
      key={index}
      className={classNames("p-6", {
        "!p-0": isEmpty(detail),
      })}
    >
      {!isEmpty(detail) && (
        <>
          <span>Passenger {index + 1}: </span>
          {isLCC ? (
            renderLCCDetails(detail, itemType, doesFlightExist)
          ) : (
            <span className="text-primary-600 text-sm font-semibold font-['Inter'] leading-7">
              {detail.description
                ? detail.description
                : `No ${itemType === SEAT ? SEAT : MEAL} Selected`}
            </span>
          )}
        </>
      )}
    </div>
  ));
};

const renderFlightDetail = (item, itemType) => {
  const renderCityNames = () => (
    <span className="text-primary-600 text-sm font-semibold font-['Inter'] leading-7">
      {getCityNameByIata(item.origin)} - {getCityNameByIata(item.destination)} :
    </span>
  );

  switch (itemType) {
    case MEAL:
      return (
        <>
          {renderCityNames()}
          <span>
            {item.airlineDescription
              ? `${item.airlineDescription} (${item.quantity})`
              : "No Meal Selected"}
          </span>
        </>
      );
    case SEAT:
      return (
        <>
          {renderCityNames()}
          <span>
            {item.code
              ? `${item.code} (Flight Number: ${item.flightNumber})`
              : "No Seat Selected"}
          </span>
        </>
      );
    case BAGGAGE:
      return (
        <>
          {renderCityNames()}
          <span>
            {item.weight ? `${item.weight}kg` : "No Baggage Selected"}
          </span>
        </>
      );
    default:
      return null;
  }
};

const renderLCCDetails = (detail, itemType, doesFlightExist) => {
  return (
    <div>
      {detail?.map((item, index) => (
        <div key={index}>
          {doesFlightExist(item.flightNumber) &&
            renderFlightDetail(item, itemType)}
        </div>
      ))}
    </div>
  );
};

const SSRDetails = ({
  mealDetails,
  seatDetails,
  baggageDetails,
  isLCC,
  segments,
}) => {
  const hasNonEmptyDetails = (details) =>
    !isEmpty(details) && details.some((detail) => !isEmpty(detail));
  
  const flightNumberCheck = (flightNo)=>{
    const parts = flightNo.split('_');
    return parts[parts.length - 1];
  };

  const doesFlightExist = (flightNo) => {
    return segments.some(({ flightNumber }) => {
      return flightNumber === flightNumberCheck(flightNo)
    });
  };

  const hasMealDetails = hasNonEmptyDetails(mealDetails);
  const hasSeatDetails = hasNonEmptyDetails(seatDetails);
  const hasBaggageDetails = hasNonEmptyDetails(baggageDetails);

  return (
    <>
      {hasMealDetails && (
        <SSRDetailsAccordian
          details={mealDetails}
          title='Meal Details'
          itemType={MEAL}
          isLCC={isLCC}
          doesFlightExist={doesFlightExist}
        />
      )}
      {hasSeatDetails && (
        <SSRDetailsAccordian
          details={seatDetails}
          title='Seat Details'
          itemType={SEAT}
          isLCC={isLCC}
          doesFlightExist={doesFlightExist}
        />
      )}
      {hasBaggageDetails && (
        <SSRDetailsAccordian
          details={baggageDetails}
          title='Baggage Details'
          itemType={BAGGAGE}
          isLCC={isLCC}
          doesFlightExist={doesFlightExist}
        />
      )}
    </>
  );
};

export default SSRDetails;
