import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal, { selectSelectedModal, setSelectedModal } from "../../Modal";
import { Information, RenderSVG } from "../../../../assets/icons";
import { parseDescription } from "../../../../screens/HotelInfo/HotelInfo";
import { selectSelectedTripId } from "../../../../screens/Booking/Trips";
import { MODALS } from "../modals.constants";
import { DEFAULT_VALUES } from "../../../../constants";
import { selectCurrentUserInfo } from "../../../../screens/Auth";

const { HOTEL_DETAILS_CHANGE_MODAL } = MODALS;
const { ONE } = DEFAULT_VALUES;

const DetailsChangeModal = ({
  handleClose = () => {},
  title = "",
  message = "",
}) => {
  const navigate = useNavigate();
  const selectedTripId = useSelector(selectSelectedTripId);
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const myTripHotelSearchRoute = `/users/${currentUserInfo?.id}/bookings/${selectedTripId}/hotels`;
  const hotelSearchRoute = `/bookings/${selectedTripId}/hotels`;

  return (
    <div className='overflow-auto'>
      <Modal shouldShowModalFromProps handleClose={handleClose} title={title}>
        <div className='flex justify-center items-center py-2 mt-4'>
          <RenderSVG
            Svg={Information}
            alt='information-icon'
            className='w-[80px] h-[80px] md:w-[100px] md:h-[100px]'
          />
        </div>
        <div className='flex justify-center items-center py-8'>
          <div className='text-sm md:text-base min-w-[65px] px-10 max-h-60 overflow-y-scroll'>
            {message}
          </div>
        </div>
        <div className='flex justify-center items-center px-10 gap-4 mb-4'>
          <div className='py-4 text-center'>
            <button
              className='btn bg-transparent border border-contrast-400 hover:opacity-90'
              onClick={() => {
                if (
                  window.location.pathname.includes(
                    `/users/${currentUserInfo?.id}/bookings`
                  )
                ) {
                  navigate(myTripHotelSearchRoute);
                } else {
                  navigate(hotelSearchRoute);
                }
              }}
            >
              Search Again
            </button>
          </div>
          <div className='py-4 text-center'>
            <button className='btn btn-primary' onClick={() => handleClose()}>
              Agree, Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const HotelDetailsChangeModal = ({
  havePoliciesChanged,
  hasPriceChanged,
  updatedPrice,
  updatedCancellationPolicy,
}) => {
  const dispatch = useDispatch();
  const selectedModal = useSelector(selectSelectedModal);
  const splittedHotelDesctiption = updatedCancellationPolicy;

  const handleModalClose = () => dispatch(setSelectedModal(null));

  return (
    selectedModal === HOTEL_DETAILS_CHANGE_MODAL && (
      <>
        {hasPriceChanged && (
          <DetailsChangeModal
            handleClose={handleModalClose}
            title={"Room Prices have updated, please review before proceeding."}
            message={`New price is: ${updatedPrice}`}
          />
        )}
        {havePoliciesChanged && (
          <DetailsChangeModal
            handleClose={handleModalClose}
            title='Hotel Policies updated, please review before proceeding.'
            message={parseDescription(splittedHotelDesctiption)}
          />
        )}
      </>
    )
  );
};

export default HotelDetailsChangeModal;
