import { useLocation } from "react-router-dom";
import { useFormikContext } from "formik";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { FARE_TYPES, FARE_DETAILS, DEFAULT_VALUES } from "../../../constants";
import { Tooltip } from "react-tooltip";
import { FareTypeSelectorPropTypes } from "../../../prop-types";

const REGULAR = "REGULAR";
const { EMPTY_OBJECT } = DEFAULT_VALUES;

const FareTypeSelector = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { values, setFieldValue } = useFormikContext();

  const { isReissuance = false } = state || EMPTY_OBJECT;

  return (
    <div className='flex items-center mt-6 gap-2 text-base'>
      <div className='font-bold whitespace-nowrap w-fit text-xs sm:text-base'>
        {t(`searchSection.fareType`)}
      </div>
      <div className='flex gap-3 flex-wrap rounded-1'>
        {Object.entries(FARE_TYPES).map(([key, { value, label }]) => (
          <div key={key}>
            <Tooltip
              id='fareRule-tooltip'
              className='!w-56 !sm:w-72 !bg-primary-600 !rounded-lg !z-10'
              render={({ content }) => (
                <span className='text-xs text-justify'>{content}</span>
              )}
            />
            <div
              className='flex gap-1 items-center px-1 py-0'
              data-tooltip-id='fareRule-tooltip'
              data-tooltip-content={t(FARE_DETAILS[key])}
              data-tooltip-place='bottom'
              data-tooltip-hidden={key === REGULAR}
              data-tooltip-class-name='!border !border-contrast-300'
            >
              <input
                type='radio'
                id={key}
                disabled={isReissuance}
                className={classNames(
                  "shadow-none text-contrast-600 cursor-pointer",
                  { "checked:bg-gray-700": isReissuance }
                )}
                checked={values.fareType === value}
                onChange={() => setFieldValue("fareType", value)}
              />
              <label
                htmlFor={key}
                className='ml-1 cursor-pointer whitespace-nowrap text-xs sm:text-base'
              >
                {t(`searchSection.${t(label)}`)}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

FareTypeSelector.propTypes = FareTypeSelectorPropTypes;

export default FareTypeSelector;
