import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { selectSelectedLCCBaggages } from "../index";
import { actions } from "../flightBookings.reducer";
import { Cross, RenderSVG } from "../../../../assets/icons";
import { DEFAULT_VALUES } from "../../../../constants";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { setSelectedLCCBaggages } = actions;

const ReducedSelectedBaggages = ({
  tabId,
  selectedBaggages = EMPTY_OBJECT,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentFlightBaggages = selectedBaggages[tabId] || EMPTY_ARRAY;

  let baggageCountMapping = currentFlightBaggages.reduce(
    (baggageMapping, baggage) => {
      baggageMapping[baggage.code] = {
        ...baggage,
        count: (baggageMapping[baggage.code]?.count || ZERO) + ONE,
      };
      return baggageMapping;
    },
    {}
  );

  const handleRemoveBaggage = (codeToRemove) => {
    const filteredSelectedBaggages = currentFlightBaggages.filter(
      ({ code }) => code != codeToRemove
    );
    dispatch(
      setSelectedLCCBaggages({
        ...selectedBaggages,
        [tabId]: filteredSelectedBaggages,
      })
    );
  };

  return (
    <div>
      {!isEmpty(baggageCountMapping) ? (
        Object.values(baggageCountMapping).map(({ weight, code, count }) => (
          <div
            key={code}
            className='text-xs flex justify-between gap-2 items-center my-1'
          >
            <div>
              {t("baggageSelection.additional")} {weight}kg
              <span className='font-semibold'> ({count})</span>
            </div>
            <button
              className='text-base bg-primary-600 text-white px-1 rounded-full'
              onClick={() => handleRemoveBaggage(code)}
            >
              <RenderSVG Svg={Cross} className='text-white w-3' />
            </button>
          </div>
        ))
      ) : (
        <div className='text-xs text-contrast-700'>
          {t("baggageSelection.noBaggage")}
        </div>
      )}
    </div>
  );
};

const SelectedBaggageDetails = ({ tabs }) => {
  const selectedBaggages = useSelector(selectSelectedLCCBaggages);
  const { t } = useTranslation();

  return (
    <div className='xl:w-1/3 max-lg:w-full'>
      <div className='p-3 sm:pl-3 sm:p-5 bg-white rounded-lg border border-contrast-200 '>
        <div className='justify-start items-start gap-2 inline-flex'>
          <div className="mb-2 h-7 text-contrast-900 text-base font-bold font-['Inter'] leading-7">
            {t("baggageSelection.selectedBaggages")}
          </div>
        </div>
        <div className='pt-4 border-t border-contrast-200'>
          <div className='w-full p-4 rounded-lg border border-primary-600'>
            {tabs.map(({ id, title }) => (
              <div key={id} className='mb-4'>
                <div className="text-primary-600 text-sm font-semibold font-['Inter'] leading-7">
                  {title}
                </div>
                <ReducedSelectedBaggages
                  tabId={id}
                  selectedBaggages={selectedBaggages}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedBaggageDetails;
