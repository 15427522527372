import { EmptyLayoutIcon } from "../../../assets/icons";

const EmptyLayout = ({
  title,
  subTitle,
  icon,
  buttonLabel,
  showButton = true,
  handleOnClick = () => {},
}) => {
  return (
    <div className='flex-1 overflow-auto'>
      <div className='bg-white border-0 border-contrast-200 py-10 px-8 flex justify-center gap-3 items-center border-x-0'>
        <div className='text-center'>
          <div className='mb-10'>
            {title && (
              <h4 className='text-2xl font-extrabold text-contrast-900 mb-4'>
                {title}
              </h4>
            )}
            {subTitle && (
              <p className='text-sm text-contrast-500 font-medium mb-4'>
                {subTitle}
              </p>
            )}
            {buttonLabel && showButton && (
              <button
                className='inline-flex items-center gap-2 py-[10px] px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium'
                onClick={handleOnClick}
              >
                {icon && <span>{icon}</span>} {buttonLabel}
              </button>
            )}
          </div>
          <EmptyLayoutIcon />
        </div>
      </div>
    </div>
  );
};

export default EmptyLayout;
