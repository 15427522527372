import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectFlights,
  selectFlightsCount,
} from "../../components/organisms/Search";
import { RenderSVG, TriangleExclaimation } from "../../assets/icons";
import { selectIsPrePackagedFlights } from "./index";
import { DEFAULT_VALUES } from "../../constants";

const { EMPTY_OBJECT } = DEFAULT_VALUES;

const NoFlightResult = () => {
  const { t } = useTranslation();
  const flights = useSelector(selectFlights);
  const flightsCount = useSelector(selectFlightsCount) || EMPTY_OBJECT;
  const isPrePackaged = useSelector(selectIsPrePackagedFlights);

  const { packages = 0, inbound = 0, outbound = 0 } = flightsCount;
  const totalFlights = isPrePackaged ? packages : inbound && outbound;

  return (
    <div className='bg-white p-6 mt-15 text-center'>
      {totalFlights ? (
        <>
          <div className='text-primary-600 text-sm mb-6 font-semibold uppercase leading-tight tracking-tight'>
            {t("flightResults.noFlights.noResults")}
          </div>
          <div className='text-contrast-900 text-4xl font-semibold'>
            {t("flightResults.noFlights.noMatchFilters")}
          </div>
          <div className='text-contrast-500 text-base font-normal leading-normal mt-1'>
            {t("flightResults.noFlights.adjustFilters")}
          </div>
        </>
      ) : (
        <div className='text-center'>
          {flights ? (
            <div>
              <div className='text-primary-600 text-sm mb-6 font-semibold uppercase leading-tight tracking-tight'>
                {t("flightResults.noFlights.noResults")}
              </div>
              <div className='text-contrast-900 text-4xl font-semibold'>
                {t("flightResults.noFlights.noMatchSearch")}
              </div>
              <div className='text-contrast-500 text-base font-normal leading-normal mt-1'>
                {t("flightResults.noFlights.anotherSearch")}
              </div>
            </div>
          ) : (
            <div>
              <div className='flex justify-center mb-5'>
                <RenderSVG
                  Svg={TriangleExclaimation}
                  alt='exclaimation-icon'
                  className='h-10 w-10'
                />
              </div>
              <div className=' h-20 flex-col justify-start items-center gap-2 flex'>
                <div className=' text-contrast-900 text-4xl font-semibold leading-[48px]'>
                  {t("flightResults.noFlights.somethingWrong")}
                </div>
                <div className=' text-contrast-500 text-base font-normal leading-normal mt-1'>
                  {t("flightResults.noFlights.tryLater")}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default NoFlightResult;
