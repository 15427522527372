import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getFromLocalStorage } from "../../../../helper";
import { updatePassword } from "../../../../screens/Profile";
import { InputField } from "../../../../components/molecules";
import { CACHE_KEYS } from "../../../../constants";
import { values } from "lodash";
import Spinner, {
  SPINNER_NAMES,
} from "../../../../components/organisms/Spinner";
import Modal, { setSelectedModal } from "../../Modal";

const { UPDATE_PASSWORD } = SPINNER_NAMES;
const { CURRENT_USER_INFO } = CACHE_KEYS;

const PASSWORD_DEFAULT_VALUE = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};
const passwordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Enter old password")
    .max(100, "Password cannot exceed 100 characters."),
  newPassword: Yup.string()
    .required("Enter new password")
    .matches(/^[^\s]{8,}$/, "Password must be at least 8 characters long and should not include spaces.")
    .notOneOf(
      [Yup.ref("oldPassword")],
      "New password must be different from old password"
    )
    .max(100, "Password cannot exceed 100 characters."),
  confirmPassword: Yup.string()
    .required("Confirm your new password")
    .oneOf(
      [Yup.ref("newPassword")],
      "Confirm password must match new passwords"
    )
    .max(100, "Password cannot exceed 100 characters."),
});

const ManagePasswordModal = () => {
  const user = getFromLocalStorage(CURRENT_USER_INFO);
  const dispatch = useDispatch();
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);

  const handlePasswordUpdate = (values, { resetForm }) => {
    dispatch(
      updatePassword({
        body: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          email: user.email,
        },
        id: user.id,
      })
    ).then((res) => {
      if (res.payload) {
        toast.success("Password Updated successfully!");
        resetForm();
      }
    });
  };

  const handleCloseModal = () => dispatch(setSelectedModal(null));

  return (
    <Modal
      title='Password Management'
      shouldShowModalFromProps
      handleClose={handleCloseModal}
    >
      <div className='bg-white rounded-2xl divide-y-2 divide-tertiary-200'>
        <Formik
          initialValues={PASSWORD_DEFAULT_VALUE}
          onSubmit={(values, { resetForm }) => {
            handlePasswordUpdate(values, { resetForm });
          }}
          validationSchema={passwordValidationSchema}
        >
          {({dirty}) => (
            <Form className='divide-y-2 divide-tertiary-200'>
              <div className='px-5 pt-6'>
                <InputField
                  id='oldPassword'
                  name='oldPassword'
                  value={values.oldPassword}
                  type='password'
                  label='Old Password'
                  placeholder='Old Password'
                  passwordToggleClassname='text-primary-700'
                />
                <InputField
                  id='newPassword'
                  name='newPassword'
                  type='password'
                  value={values.newPassword}
                  label='New Password'
                  placeholder='New Password'
                  passwordToggleClassname='text-primary-700'
                />
                <InputField
                  id='confirmPassword'
                  name='confirmPassword'
                  type='password'
                  value={values.confirmPassword}
                  label='Confirm Password'
                  placeholder='Confirm Password'
                  passwordToggleClassname='text-primary-700'
                />
              </div>
              <div className='w-full flex justify-end px-5 py-4'>
                <button
                  type='submit'
                  className='bg-primary-600 text-white text-base px-4 py-2 rounded-md disabled:bg-primary-400 disabled:cursor-not-allowed'
                  disabled={isSpinnerActive || !dirty}
                >
                  <Spinner
                    name={UPDATE_PASSWORD}
                    setIsSpinnerActive={setIsSpinnerActive}
                    persistSize={true}
                  >
                    Save Changes
                  </Spinner>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ManagePasswordModal;
