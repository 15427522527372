import { combineReducers } from "redux";
import * as search from "./components/organisms/Search";
import * as spinner from "./components/organisms/Spinner";
import * as pricing from "./screens/FlightResults";
import * as drawers from "./components/organisms/Drawer";
import * as profile from "./screens/Profile";
import * as booking from "./screens/Booking/FlightBookings";
import * as modal from "./components/organisms/Modal/";
import * as admin from "./screens/Admin";
import * as auth from "./screens/Auth";
import * as mytrips from "./screens/MyTrips";
import * as hotels from "./screens/HotelInfo";
import * as hotelBooking from "./screens/HotelBooking";
import * as policies from "./screens/Policies";
import * as users from "./screens/Users";
import * as dashboard from "./screens/Dashboard";
import * as permissions from "./screens/Permissions";
import * as transactions from "./screens/Transactions";
import * as account from "./screens/Account";
import * as trips from "./screens/Booking/Trips";
import * as session from "./screens/session";
import * as costCodes from "./screens/CostCodes"

export default combineReducers({
  [search.name]: search.reducer,
  [pricing.name]: pricing.reducer,
  [booking.name]: booking.reducer,
  [profile.name]: profile.reducer,
  [auth.name]: auth.reducer,
  [drawers.name]: drawers.reducer,
  [modal.name]: modal.reducer,
  [spinner.name]: spinner.reducer,
  [admin.name]: admin.reducer,
  [mytrips.name]: mytrips.reducer,
  [hotels.name]: hotels.reducer,
  [hotelBooking.name]: hotelBooking.reducer,
  [policies.name]: policies.reducer,
  [users.name]: users.reducer,
  [dashboard.name]: dashboard.reducer,
  [permissions.name]: permissions.reducer,
  [transactions.name]: transactions.reducer,
  [account.name]: account.reducer,
  [trips.name]: trips.reducer,
  [session.name]: session.reducer,
  [costCodes.name]: costCodes.reducer,
});
