import { get } from "lodash";
import OneWayApprovalsCard from "./OneWayApprovalsCard";

const Trip = ({ itineraries }) => {
  if (itineraries.length === 1) {
    const segment = itineraries[0].segments[0];
    return (
      <div>
        {segment.departure.cityName}, {segment.departure.iataCode} -{" "}
        {segment.arrival.cityName}, {segment.arrival.iataCode}
      </div>
    );
  } else {
    return (
      <div>
        {itineraries
          .map(
            (itinerary) =>
              `${itinerary.segments[0].departure.iataCode}-${
                itinerary.segments[itinerary.segments.length - 1].arrival
                  .iataCode
              }`
          )
          .join(" | ")}
      </div>
    );
  }
};

const JourneyDetailsCard = (journeyDetails) => {
  const tripType = get(journeyDetails, "journey.tripType", "");
  const flightPrice = get(journeyDetails, "journey.price.grandTotal", 0);
  const itineraries = get(journeyDetails, "journey.itineraries", []);
  return (
    <div class='border border-contrast-200 rounded-lg overflow-hidden'>
      <div className='flex items-center justify-between bg-contrast-50 p-4 border-contrast-200 border-b'>
        <h6 className='text-xl font-semibold text-contrast-900'>
          <Trip itineraries={itineraries} />
        </h6>
        <div className='flex items-center gap-4'>
          <div className='text-end'>
            <p className='font-semibold text-contrast-900  text-2xl '>
              {flightPrice}
            </p>
            <span className='text-xs text-contrast-600 inline-block'>
              {tripType}{" "}
            </span>
          </div>
        </div>
      </div>
      <div className='bg-white divide-y divide-contrast-200'>
        {itineraries.map((itinerary, index) => (
          <OneWayApprovalsCard key={index} itinerary={itinerary} />
        ))}
      </div>
    </div>
  );
};

export default JourneyDetailsCard;
