import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES, DEFAULT_VALUES } from "../constants";
import { useTranslation } from "react-i18next";
import { TriangleExclaimation, RenderSVG } from "../assets/icons";
import { useDispatch } from "react-redux";
import {
  clearSelectedValues,
  setSpecialServices,
} from "./Booking/FlightBookings";

const { BOOKINGS } = ROUTES;
const { EMPTY_ARRAY } = DEFAULT_VALUES;

const PageNotFound = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleGoToSearch = () => navigate(BOOKINGS);

  useEffect(() => {
    dispatch(setSpecialServices(EMPTY_ARRAY));
    dispatch(clearSelectedValues());
  }, []);

  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <div className='mb-2'>
        <RenderSVG
          Svg={TriangleExclaimation}
          height='60'
          width='60'
          color='red'
        />
      </div>
      <div className='text-contrast-900 text-4xl font-semibold leading-tight tracking-tight'>
        {t("errorPage.heading1")}
      </div>
      <div className='text-contrast-900 text-2xl font-semibold leading-[48px] text-center'>
        {t("errorPage.heading2")}
      </div>
      <div className='text-contrast-500 text-base font-normal leading-normal text-center mt-1'>
        {t("errorPage.heading3")}
      </div>
      <button
        className='pl-2 pr-2 py-2 bg-primary-600 rounded-md shadow mt-6 text-white text-sm font-medium'
        onClick={handleGoToSearch}
      >
        Go to Bookings
      </button>
    </div>
  );
};

export default PageNotFound;
