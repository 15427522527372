import { memo } from "react";
import get from "lodash/get";
import { Country } from "country-state-city";
import { useTranslation } from "react-i18next";
import { Field, useFormikContext } from "formik";
import { renderFieldError } from "../../helper";
import Asterisk from "../../components/atoms/Asterisk";
import DropdownSelectField from "../../components/atoms/DropdownSelectField";
import DateSearch from "../../components/atoms/DateSearch";
import { DEFAULT_VALUES, MIN_MONTHS_DIFF_FOR_PASSPORT_EXPIRY } from "../../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;
const countryList = Country.getAllCountries();

const RenderPassportFields = ({ colSpan = '6' }) => {
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleChange,
  } = useFormikContext();

  const passportIssuedDate = get(values, 'passportDetails.issuedDate', EMPTY_STRING);
  let sixMonthsAheadDate = passportIssuedDate ? new Date(passportIssuedDate) : new Date;
  sixMonthsAheadDate = new Date(sixMonthsAheadDate.setMonth(sixMonthsAheadDate.getMonth() + MIN_MONTHS_DIFF_FOR_PASSPORT_EXPIRY))

  return (
    <>
      <div className={`col-span-12 lg:col-span-${colSpan}`}>
        <div className='form-group'>
          <label
            htmlFor='passportNumber'
            className='block text-sm font-medium mb-1 text-contrast-900'
          >
            Passport Number
            <Asterisk />
          </label>
          <div>
            <Field
              id='passportNumber'
              type='text'
              name='passportDetails.passportNumber'
              className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500'
              placeholder='Passport Number'
            />
          </div>
          <div>
            {renderFieldError(
              "passportDetails.passportNumber",
              errors,
              touched
            )}
          </div>
        </div>
      </div>
      <div className={`col-span-12 lg:col-span-${colSpan}`}>
        <div className='form-group'>
          <label
            htmlFor='issueCountry'
            className='block text-sm font-medium mb-1 text-contrast-900'
          >
            Issuing Country
            <Asterisk />
          </label>
          <div>
            <DropdownSelectField
              id='issueCountry'
              name='passportDetails.issueCountry'
              value={values.passportDetails.issueCountry}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              selectableValues={countryList}
              valueToShow='name'
              validateForm={validateForm}
              placeholder={t("profilePage.placeholders.issueCountry")}
            />
          </div>
          <div>
            {renderFieldError(
              "passportDetails.issueCountry.isoCode",
              errors,
              touched
            )}
          </div>
        </div>
      </div>
      <div className={`col-span-12 lg:col-span-${colSpan}`}>
        <div className='form-group'>
          <label
            htmlFor='issueDate'
            className='block text-sm font-medium mb-1 text-contrast-900'
          >
            Issue Date
            <Asterisk />
          </label>
          <div>
            <DateSearch
              id='issueDate'
              name='passportDetails.issuedDate'
              noOfMonth={1}
              showCalendarIcon={true}
              maxDate={new Date()}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              values={values}
              position='top-right'
              placeholder="YYYY-MM-DD"
            />
          </div>
          <div>
            {renderFieldError("passportDetails.issueDate", errors, touched)}
          </div>
        </div>
      </div>
      <div className={`col-span-12 lg:col-span-${colSpan}`}>
        <div className='form-group'>
          <label
            htmlFor='expiryDate'
            className='block text-sm font-medium mb-1 text-contrast-900'
          >
            Expiry Date
            <Asterisk />
          </label>
          <div>
            <DateSearch
              id='expiryDate'
              name='passportDetails.expiryDate'
              noOfMonth={1}
              showCalendarIcon={true}
              minDate={sixMonthsAheadDate}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              values={values}
              placeholder="YYYY-MM-DD"
            />
          </div>
          <div>
            {renderFieldError("passportDetails.expiryDate", errors, touched)}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(RenderPassportFields);
