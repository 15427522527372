import { useMemo, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { get, debounce, isEmpty, isEqual } from "lodash";
import { v4 as uuid } from "uuid";
import { Formik, Form, useFormikContext } from "formik";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { selectActiveSpinners, SPINNER_NAMES } from "../../Spinner";
import {
  getObjectFromQueryParams,
  getQueryParams,
  getNumberOfNights,
  getISODateString,
  getDefaultReturnDate,
  setToLocalStorage,
} from "../../../../helper";
import {
  ChevronDown,
  ChevronUp,
  RenderSVG,
  SearchIcon,
} from "../../../../assets/icons";
import {
  RoomAndGuestsCount,
  DateSelector,
  HotelsLocationPicker,
} from "../../../molecules";
import { setHotels, setHotelsStaticData, setIsHotelsLoaded, setIsStaticDataLoaded, setSearchHotelFilters } from "../index";
import { getHotelLocations } from "../search.actions";
import { selectCountryInfo } from "../../../../screens/Profile";
import {
  SEARCH_SECTION,
  DEFAULT_VALUES,
  DEBOUNCE_TIME,
  HOTEL_DEFAULT_VALUES,
  DATE_FORMAT,
  HOTEL_DESTINATION_TYPES,
  CACHE_KEYS,
  CURRENT_DATE,
  TRAVELER_TYPE
} from "../../../../constants";
import { actions as modalActions } from "../../Modal/modal.reducers";
import { actions as drawerActions } from "../../Drawer/drawers.reducers";
import { SearchHotelSectionPropTypes } from "../../../../prop-types";
import { cancelAllAPIRequests } from "../../../../infrastructure/httpMethods/requestingMethods";
import {
  selectSelectedTrip,
  selectSelectedTripId,
  setSearchTimeLog,
  setSelectedTripId,
  setShowTimer,
} from "../../../../screens/Booking/Trips";
import {getTravelerCategory ,getTravelersAgeFromDOB} from "../../../../helper";
import { setOtherGuests } from "../../../../screens/HotelBooking";

const { HOTEL } = SEARCH_SECTION;
const { EMPTY_STRING, EMPTY_OBJECT, ZERO, ONE, FOUR, EMPTY_ARRAY, SIX } =
  DEFAULT_VALUES;
const { FETCH_HOTEL_RESULTS } = SPINNER_NAMES;
const { CITY } = HOTEL_DESTINATION_TYPES;
const { setSelectedDrawer } = drawerActions;
const { setSelectedModal } = modalActions;
const { HOTEL_SEARCH_TIME } = CACHE_KEYS;
const { ADULT, CHILD } = TRAVELER_TYPE;
const MAX_NUMBER_OF_NIGHTS = 31;

const formattedDate = (inputDate) => {
  const date = inputDate ? new Date(inputDate) : new Date();
  const day = date.getDate();
  const month = date.getMonth() + ONE;
  const year = date.getFullYear();

  return `${String(day).padStart(2, "0")}/${String(month).padStart(
    2,
    "0"
  )}/${year}`;
};

const SearchButton = ({ isRequestUpdated }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const date = new Date();
  const { initialValues, values } = useFormikContext();
  const activeSpinners = useSelector(selectActiveSpinners);
  const selectedTripId = useSelector(selectSelectedTripId);

  const noOfNights = getNumberOfNights(
    new Date(values?.checkIn),
    new Date(values?.targetDate)
  );

  const isDisabled = noOfNights > MAX_NUMBER_OF_NIGHTS 

  const getToolTipMessage = () => {
    if (activeSpinners.some((spinner) => spinner === FETCH_HOTEL_RESULTS))
      return `${t("hotelResults.searching")}`;
    else if (noOfNights > MAX_NUMBER_OF_NIGHTS)
      return `${t("hotelResults.maxNightsWarning")}`;
    else return null;
  };

  return (
    <button
      className={classNames(
        "py-3 px-4 w-full h-full items-center flex gap-2 rounded-md justify-center text-sm font-medium text-white bg-secondary-600 max-h-[54px] cursor-pointer",
        {
          "opacity-75": isDisabled,
        }
      )}
      onClick={() => {
        const timerObject = {
          id: selectedTripId,
          type: HOTEL,
          timer: date.getTime(),
        };
        setToLocalStorage(HOTEL_SEARCH_TIME, timerObject);
      }}
      type='submit'
      disabled={isDisabled}
      data-tooltip-id='hotel-tooltip'
      data-tooltip-place='bottom'
      data-tooltip-content={getToolTipMessage()}
    >
      {isDisabled && (
        <Tooltip
          id='hotel-tooltip'
          className='!w-56 !sm:w-72 !bg-primary-600 !rounded-lg !z-50'
        />
      )}
      <RenderSVG Svg={SearchIcon} className='text-white' alt='Search Icon' />
      <span>{t("searchSection.findHotels")}</span>
    </button>
  );
};

const RenderSearchHotelSection = ({ isRequestUpdated }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + ONE);

  const minCheckoutDate = getDefaultReturnDate(get(values, "checkIn"), ONE);

  const debouncedLocationChange = useMemo(() => {
    const fetchHotels = (mainLocationSearchValue) => {
      const keyword = mainLocationSearchValue.inputValue
        ? mainLocationSearchValue.inputValue.replace(
            /[^a-zA-Z ]/g,
            EMPTY_STRING
          )
        : EMPTY_STRING;
      keyword && dispatch(getHotelLocations({ key: keyword }));
    };
    return debounce(fetchHotels, DEBOUNCE_TIME);
  }, []);

  const handlecheckInChange = (values) => {
    const startDate = get(values, "0");
    const endDate = get(values, "1");
    const formattedStartDate = startDate?.format(DATE_FORMAT);
    const formattedEndDate = endDate?.format(DATE_FORMAT);
    setFieldValue("checkIn", formattedStartDate);

    setFieldValue(
      "targetDate",
      formattedEndDate === formattedStartDate
        ? getDefaultReturnDate(formattedStartDate, ONE)
        : formattedEndDate
    );
  };

  const handlecheckOutChange = (values) => {
    const endDate = get(values, "1");
    const formattedEndDate = endDate?.format(DATE_FORMAT);
    setFieldValue("targetDate", formattedEndDate);
  };

  const handleLocationChange = (mainLocationSearchValue) =>
    debouncedLocationChange(mainLocationSearchValue);

  const handleCloseDatePicker = () => {
    const departureDate = get(values, "checkIn");
    const endDate = get(values, "targetDate");
    !endDate &&
      setFieldValue("targetDate", getDefaultReturnDate(departureDate, ONE));
  };

  return (
    <div className='grid grid-cols-12 gap-4 2xl:gap-5 mt-[10px]'>
      <div className='col-span-12 md:col-span-6 lg:col-span-3 gap-3 relative'>
        <HotelsLocationPicker
          name='destination'
          handleLocationChange={handleLocationChange}
          source={HOTEL}
        />
      </div>
      <div className='col-span-12 md:col-span-6 lg:col-span-4'>
        <div className='flex gap-3'>
          <span
            type='button'
            className='flex-1 rounded-lg bg-white flex items-center gap-3'
          >
            <DateSelector
              showPrices={false}
              placeholder='Check In'
              name='checkIn'
              height='53px'
              travelType={HOTEL}
              range={true}
              showCalendarIcon={true}
              minDate={CURRENT_DATE}
              defaultDates={[get(values, "checkIn"), get(values, "targetDate")]}
              handleChangeFromProps={handlecheckInChange}
              onClose={handleCloseDatePicker}
            />
          </span>
          <span
            type='button'
            className='flex-1 rounded-lg bg-white flex items-center gap-3'
          >
            <DateSelector
              showPrices={false}
              placeholder='Check Out'
              name='targetDate'
              height='53px'
              travelType={HOTEL}
              showCalendarIcon={true}
              minDate={minCheckoutDate}
              defaultDates={[get(values, "targetDate")]}
              handleChangeFromProps={handlecheckOutChange}
              onClose={handleCloseDatePicker}
            />
          </span>
        </div>
      </div>
      <div className='col-span-12 md:col-span-6 lg:col-span-3 relative text-black rounded-md hover:ring-2 hover:ring-primary-50'>
        <RoomAndGuestsCount />
      </div>
      <div className='col-span-12 md:col-span-6 lg:col-span-2 flex flex-col md:flex-row gap-3 2xl:gap-4'>
        <SearchButton isRequestUpdated={isRequestUpdated} />
      </div>
    </div>
  );
};

const HotelSummary = () => {
  const { values } = useFormikContext();
  const {
    destination: { cityName, hotelName },
    checkIn,
    targetDate,
  } = values;

  return (
    <div className='flex flex-center 2xl:gap-6 gap-3 me-auto 2xl:text-base lg:text-sm text-xs text-contrast-900'>
      <span>{hotelName || cityName}</span>
      <span>•</span>
      <span className='whitespace-pre'>
        {checkIn}
        {targetDate && <span> - {targetDate}</span>}
      </span>
    </div>
  );
};

const getDestinationData = (destination) =>
  destination.type === CITY
    ? { cityId: destination.destinationId }
    : {
        hotelCode: destination.destinationId,
        hotelName: destination.hotelName,
      };

const getMappedRoomGuests = (roomGuests) =>
  roomGuests.map(({ noOfAdults, noOfChild, childAge }) => ({
    noOfAdults,
    noOfChild,
    ...(!isEmpty(childAge) && { childAge }),
  }));

const Hotel = ({ showEditSearch = false, defaultExpandedValue = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { id } = useParams();
  const tripDetails = useSelector(selectSelectedTrip);
  const [passengers, setPassengers] = useState([]);
  
  const { tripStartDate, tripEndDate, passengers: updatedPassengers = [] } = tripDetails || EMPTY_OBJECT;
  if(!isEqual(passengers, updatedPassengers))
    setPassengers(updatedPassengers);
  const selectedCountryInfo = useSelector(selectCountryInfo) || {};
  const { ip: endUserIp } = selectedCountryInfo;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 100,
    defaultExpanded: defaultExpandedValue,
  });
  const [initialValues, setInitialValues] = useState(HOTEL_DEFAULT_VALUES);
  const [queryString, setQueryString] = useState(EMPTY_STRING);
  const [isRequestUpdated, setIsRequestUpdated] = useState(false);

  useEffect(() => {
    if (!tripDetails) {
      dispatch(setSelectedTripId(id));
    }
  }, [tripDetails]);

  useEffect(() => {
    dispatch(setSelectedDrawer(null));
    dispatch(setSelectedModal(null));
  }, [dispatch]);

  useEffect(() => {
    if (!search) {
      let updatedValues = { ...HOTEL_DEFAULT_VALUES };
      let roomGuests = [];
      let childRoomIndex = ZERO; 
    
      if (passengers.length >= ONE) {
        passengers.forEach((passenger) => {
          const dob = passenger.travelers?.dateOfBirth;
          const travelerCategory = getTravelerCategory(dob, HOTEL);
          const isAdult = travelerCategory === ADULT || passenger?.users;
          const isChild = travelerCategory === CHILD;
    
          if (isAdult) {
            if (roomGuests.length < SIX) {
              roomGuests.push({
                noOfAdults: ONE,
                noOfChild: ZERO,
                childAge: [],
              });
            } else {
              const baseAdultsPerRoom = Math.floor(passengers.length / SIX);
              const extraAdults = passengers.length % SIX;
  
              roomGuests = Array.from({ length: SIX }, (_, index) => ({
                noOfAdults: baseAdultsPerRoom + (index < extraAdults ? ONE : ZERO),
                noOfChild: ZERO,
                childAge: [],
              }));
            }
          }
          if (isChild) {
            const ageDetails = getTravelersAgeFromDOB(dob);
            const childAge = typeof ageDetails === "object" ? [ONE] : [ageDetails];
    
            if (roomGuests.length === ZERO || roomGuests[childRoomIndex].noOfChild >= FOUR)  {
              roomGuests.push({
                noOfAdults: ONE,
                noOfChild: ONE,
                childAge: childAge,
              });
            } else {
              roomGuests[childRoomIndex].noOfChild += ONE;
              roomGuests[childRoomIndex].childAge.push(...childAge);
              childRoomIndex = (childRoomIndex + ONE) % roomGuests.length;
            }
          }
        });
        updatedValues.roomGuests = roomGuests;
        updatedValues.noOfRooms = roomGuests.length;
      }
      if (tripStartDate) {
        const todaysDate = getISODateString(new Date());
        if (getISODateString(tripStartDate) < todaysDate) {
          updatedValues.checkIn = todaysDate;
        } else {
          updatedValues.checkIn = getISODateString(tripStartDate);
        }
      }
      if (tripEndDate) {
        const todaysDate = getISODateString(new Date());
        if (getISODateString(tripEndDate) < todaysDate) {
          updatedValues.targetDate = getISODateString(
            getDefaultReturnDate(new Date(), ONE)
          );
        } else {
          updatedValues.targetDate =
            tripStartDate === tripEndDate
              ? getISODateString(getDefaultReturnDate(tripEndDate, ONE))
              : getISODateString(tripEndDate);
        }
      }

      setInitialValues(updatedValues);
    } else {
      const sessionId = uuid();
      const {
        checkInDate,
        checkOutDate,
        noOfNights,
        countryCode,
        guestNationality,
        cityId,
        maxPrice,
        hotelCode,
        hotelName,
        noOfRooms,
        roomGuests,
        cityName,
        destinationType,
        guestCountry,
        preferredCurrency,
        maxRating,
        minRating,
      } = getObjectFromQueryParams(search);

      const formattedInitialValues = {
        checkIn: checkInDate || EMPTY_STRING,
        noOfNights: noOfNights || ONE,
        guestNationality: {
          isoCode: guestNationality || EMPTY_STRING,
          name: guestCountry || EMPTY_STRING,
        },
        destination: {
          countryCode: countryCode || EMPTY_STRING,
          destinationId:
            Number(destinationType) === CITY
              ? Number(cityId)
              : Number(hotelCode),
          cityName: cityName,
          type: Number(destinationType),
          ...(hotelName && { hotelName }),
        },
        noOfRooms: noOfRooms || ONE,
        cityName: cityName || EMPTY_STRING,
        targetDate: checkOutDate || EMPTY_STRING,
        roomGuests,
      };
      const filtersFromQueryParam = {
        checkInDate: formattedDate(checkInDate),
        checkOutDate: formattedDate(checkOutDate),
        noOfNights,
        countryCode,
        hotelCode,
        ...(!hotelCode && { cityId }),
        preferredCurrency,
        guestNationality,
        noOfRooms,
        roomGuests: getMappedRoomGuests(roomGuests),
        maxPrice,
        maxRating,
        minRating,
        endUserIp,
        sessionId,
        isProviderMapped: "true",
        source: "TBO",
      };
      tripDetails && search && dispatch(setSearchHotelFilters(filtersFromQueryParam));
      setInitialValues(formattedInitialValues);
    }
  }, [search, tripDetails, passengers]);

  const handleNavigate = (queryString) =>
    navigate(`?${queryString}`, { replace: true });

  const handleSearchHotels = useCallback(
    (values) => {
      const checkInDate = new Date(values.checkIn);
      const checkOutDate = new Date(values.targetDate);  
      const numberOfNights = getNumberOfNights(checkInDate, checkOutDate);
      values.noOfNights = numberOfNights.toString();
      const {
        checkIn,
        noOfNights,
        guestNationality,
        noOfRooms,
        destination,
        targetDate,
        roomGuests,
      } = values;

      const queryFilters = {
        checkInDate: formattedDate(checkIn),
        checkOutDate: formattedDate(targetDate),
        noOfNights: noOfNights,
        countryCode: destination.countryCode,
        preferredCurrency: get(selectedCountryInfo, "currency.code", "INR"),
        guestNationality: guestNationality.isoCode,
        noOfRooms: noOfRooms,
        roomGuests,
        maxRating: 5,
        minRating: 0,
        isProviderMapped: "true",
        source: "TBO",
        endUserIp,
        destinationType: destination.type,
        ...getDestinationData(destination),
        timestamp: new Date().toISOString()  
      };
      const queryString = getQueryParams({
        ...queryFilters,
        cityName: destination.cityName,
        guestCountry: get(guestNationality, "name", EMPTY_STRING),
        checkOutDate: formattedDate(targetDate),
      });
      cancelAllAPIRequests();
      dispatch(setHotels(EMPTY_OBJECT));
      dispatch(setHotelsStaticData(EMPTY_ARRAY))
      dispatch(setIsHotelsLoaded(false));
      dispatch(setIsStaticDataLoaded(false));
      dispatch(setOtherGuests([]));
      setQueryString(queryString);
      handleNavigate(queryString);
    },
    [dispatch, selectedCountryInfo]
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSearchHotels}
        enableReinitialize
      >
        {() => (
          <Form>
            <div className='flex sm:flex-row flex-col gap-4 sm:items-center w-full'>
              <div>
                <RenderSearchHotelSection isRequestUpdated={isRequestUpdated} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

Hotel.propTypes = SearchHotelSectionPropTypes;

export default Hotel;
