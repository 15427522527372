import { createSlice } from "@reduxjs/toolkit";
import { fetchCostCodes, createCostCode, updateCostCode, deleteCostCode } from "./cost.actions";
import { toast } from "react-toastify";
import { DEFAULT_VALUES } from "../../constants";

const { EMPTY_OBJECT, ONE } = DEFAULT_VALUES;
const initialState = {
  costCodes: EMPTY_OBJECT
};

const slice = createSlice({
  name: "cost-codes",
  initialState,
  reducers: {
    setcostCodes: (state, action) => {
      state.costCodes = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCostCodes.fulfilled, (state, action) => {
        if (!action.payload){ 
          toast.error(`Cannot fetch records at the moment, please try again later!`)
          return;
        }
        else
          state.costCodes = action.payload;
      })
      .addCase(createCostCode.fulfilled, (state, action) => {
        if (!action.payload) {
          toast.error(`Cannot create code at the moment, please try again later!`);
          return;
        }
        else {
          state.costCodes.result.unshift(action.payload);
          state.costCodes.countInfo.count += ONE;
          const code = action.payload.costCode;
          toast.success(`Code ${code} was created!`);
        }
      })
      .addCase(updateCostCode.fulfilled, (state, action) => {
        if (!action.payload) {
          toast.error(`Cannot update code at the moment, please try again later!`)
          return;
        }
        else {
          state.costCodes.result.filter((currentCostCode) => {
            if (currentCostCode.id === action.payload.id){
              currentCostCode.name = action.payload.name,
              currentCostCode.costCode = action.payload.costCode,
              currentCostCode.description = action.payload.description;
            }
          })
          const code = action.payload.costCode;
          toast.success(`Code ${code} updated!`);
        }
      })
      .addCase(deleteCostCode.fulfilled, (state, action) => {
        if (!action.payload) {
          toast.error(`Cannot delete code at the moment, please try again later!`)
          return;
        }
        else {
          state.costCodes.result = state.costCodes.result.filter((currentCostCode) => {
            return currentCostCode.id !== action.payload.id;
          })
          state.costCodes.countInfo.count -= ONE;
          const code = action.payload.costCode;
          toast.success(`Code ${code} is deleted!`);
        }
      })
  },
});

export const { name, reducer, actions } = slice;
export default slice;
