import { useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getRequiredErrorMessage } from "../../helper";
import { resetPasswordByOtp } from "./auth.actions";
import { DEFAULT_VALUES, ROUTES, USER_AUTH_FIELDS } from "../../constants";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { selectTenantDetails } from ".";
import { get } from "lodash";
import Asterisk from "../../components/atoms/Asterisk";

const { LOGIN } = ROUTES;
const { RESET_PASSWORD_BY_OTP } = SPINNER_NAMES;
const { EMPTY_STRING } = DEFAULT_VALUES;
const { EMAIL } = USER_AUTH_FIELDS;
const { AUTH_CONFIRMATION } = ROUTES;

const initialValues = {
  email: EMPTY_STRING,
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tenantDetails = useSelector(selectTenantDetails);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("validationSchemas.loginAndSignup.invalidEmail"))
      .required(getRequiredErrorMessage("loginAndSignup.formFields.email", t)),
  });

  const handleOnSubmit = (values, { setSubmitting, resetForm }) => {
    const { email } = values;
    dispatch(resetPasswordByOtp({ provider: EMAIL, email: email })).then(
      (res) => {
        if (res.payload === EMPTY_STRING) {
          toast.success(t("forgotPassword.otpSent"));
          navigate(`${AUTH_CONFIRMATION}?email=${encodeURIComponent(email)}`);
          resetForm();
        }
      }
    );
    setSubmitting(true);
  };
  const { logoWithBrand } = tenantConfig;
  const handleSignInButton = () => navigate(LOGIN)
  return (
    <div className='font-inter bg-primary-50'>
      <div className='py-8 px-6 min-h-screen flex justify-center items-center'>
        <div className='flex-1 bg-white rounded-lg shadow-md p-6 max-w-md flex flex-col gap-6'>
          <div className=''>
            <div className='pb-8 text-center'>
              <img
                src={logoWithBrand}
                width='105'
                height='24'
                className='mx-auto'
                alt='logo'
              />
            </div>
            <div className='text-center'>
              <h1 className='text-2xl text-contrast-900 font-bold mb-2'>
                Forgot Password?
              </h1>
              <p className='text-base text-contrast-500'>
                Enter your email address to reset your password.
              </p>
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              dirty,
              isValid,
            }) => (
              <Form className='flex flex-col gap-6'>
                <div className='form-group'>
                  <label
                    htmlFor='email'
                    className='text-black text-sm font-medium mb-1'
                  >
                    Email Address
                    <Asterisk />
                  </label>
                  <Field
                    id='email'
                    name='email'
                    className={classNames(
                      "border-contrast-300 rounded-md shadow-sm text-base py-2 px-3 block w-full",
                      {
                        "border-red-600": touched.email && errors.email,
                      }
                    )}
                    type='email'
                    placeholder='Your Email'
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                </div>
                <button
                  type='submit'
                  className='py-[10px] px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium'
                  disabled={isSubmitting || !dirty || !isValid}
                >
                  <Spinner
                    name={RESET_PASSWORD_BY_OTP}
                    setIsSpinnerActive={setIsSubmitting}
                  >
                    Continue
                  </Spinner>
                </button>
                <p className='text-sm text-contrast-500 text-center ml-3'>
                  Have an account?
                  <button className='text-primary-600  text-sm ml-2' onClick={() => { handleSignInButton() }}>
                    SignIn
                  </button>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
