import { BOOKING_TYPE } from "../constants";
import { get } from "lodash";

const { FLIGHT_HOTEL, FLIGHT, HOTEL } = BOOKING_TYPE;

export const getBookingType = (trips) => {
  const HotelBooking = get(trips, "hotels");
  const FlightBooking = get(trips, "flights");

  const hasHotelBooking = HotelBooking.length > 0;
  const hasFlightBooking = FlightBooking.length > 0;

  if (hasHotelBooking && hasFlightBooking) {
    return FLIGHT_HOTEL;
  } else if (hasFlightBooking) {
    return FLIGHT;
  } else if (hasHotelBooking) {
    return HOTEL;
  } else {
    return null;
  }
};
