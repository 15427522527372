import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  Dollar,
  TicketBasic,
  BaggageOutlined,
  GreenArrowUp,
  RedArrowDown,
  BedOutlined,
  FlightBlueIcon,
  Car,
  ApprovalRating,
  Wallet,
  LuggageLarge,
  BookingImg,
  PlaneImg,
  CarImg,
  HotelImg,
  ThumbImg,
  RenderSVG,
} from "../../../assets/icons";
import {
  ANALYTICS_PARAM,
  INDIAN_NUMERIC_UNITS,
  DEFAULT_VALUES,
  CURRENCY_SYMBOLS,
  DEFAULT_CURRENCY_CODE,
  CURRENCY_TYPES,
  US_NUMERIC_UNITS
} from "../../../constants";
import { selectAnalyticsQueryParams } from "../../../screens/Dashboard";
import { selectCountryInfo, selectUserInfo } from "../../../screens/Profile";
import { isEmpty } from "lodash";
import CountUp from "react-countup";


const {
  REFERENCE_PERIOD: { CURRENT, LAST },
} = ANALYTICS_PARAM;
const { THOUSAND, LAKH, CRORE } = INDIAN_NUMERIC_UNITS;
const { MILLION, BILLION } = US_NUMERIC_UNITS;
const {
  EMPTY_STRING,
  SINGLE_SPACE_STRING,
  ZERO,
  ONE,
  TWO,
  NEG_TWO,
  NEG_THREE,
  EXPONENT_THREE,
  EXPONENT_FIVE,
  EXPONENT_SIX,
  EXPONENT_SEVEN,
  EXPONENT_NINE,
  DOUBLE_DECIMAL_ZERO,
  SINGLE_DECIMAL_ZERO
} = DEFAULT_VALUES;
const { INR, USD } = CURRENCY_SYMBOLS;

const CURRENCY = "currency";
const PERCENTAGE = "percentage";
const ICON_TYPE = {
  BED: "bed",
  FLIGHT: "flight",
  CAR: "car",
  RATING: "rating",
};
const TOTAL_ANALYTIC = {
  SPENT: "spent",
  TRIPS: "trips",
  BOOKINGS: "bookings",
};
const THIS = "this";

const SvgIcon = ({ icon }) => {
  switch (icon) {
    case ICON_TYPE.BED:
      return (
        <div className='w-6 h-6 p-1 flex justify-center items-center rounded-full bg-pink-100'>
          <RenderSVG Svg={BedOutlined} className="none"/>
        </div>
      );
    case ICON_TYPE.FLIGHT:
      return (
        <div className='w-6 h-6 p-1 flex justify-center items-center rounded-full bg-blue-100'>
          <RenderSVG Svg={FlightBlueIcon} className="none"/>
        </div>
      );
    case ICON_TYPE.CAR:
      return (
        <div className='w-6 h-6 p-1 flex justify-center items-center rounded-full bg-contrast-100'>
          <RenderSVG Svg={Car} className="none"/>
        </div>
      );
    case ICON_TYPE.RATING:
      return (
        <div className='w-6 h-6 p-1 flex justify-center items-center rounded-full bg-green-100'>
          <RenderSVG Svg={ApprovalRating} className="none"/>
        </div>
      );
  }
};

const svgImage = (img) => {
  switch (img) {
    case ICON_TYPE.BED:
      return HotelImg;
    case ICON_TYPE.FLIGHT:
      return PlaneImg;
    case ICON_TYPE.CAR:
      return CarImg;
    case ICON_TYPE.RATING:
      return ThumbImg;
  }
};

const renderUnitValue = (value, unit, currency) => {
  switch (unit) {
    case CURRENCY:
      return <>{`${currency}`}&nbsp;<CountUp duration={1} className="counter" end={value} decimals={2} useIndianSeparators={true} /></>;
    case PERCENTAGE:
      return <>{value}%</>;
    default:
      return <>{value}</>;
  }
};

const getExpenseDetails = (type) => {
  const userInfo = useSelector(selectUserInfo);
  switch (type) {
    case TOTAL_ANALYTIC.SPENT:
      return {
        icon: Dollar,
        img: Wallet,
        description: "Add funds to your account to begin managing trips.",
        btnText: "Add Company Wallet",
        showButton: !userInfo?.tenant?.walletId,
        isCompleted: true,
      };
    case TOTAL_ANALYTIC.TRIPS:
      return {
        icon: TicketBasic,
        img: LuggageLarge,
        description:
          "Customize travel expense rules for each of your departments.",
        btnText: "Set Up Travel Policy",
        isCompleted: false,
        showButton: isEmpty(userInfo?.policies),
      };
    case TOTAL_ANALYTIC.BOOKINGS:
      return {
        icon: BaggageOutlined,
        img: BookingImg,
        description: "Manage employee travel with ease!",
        showButton: false,
        isCompleted: false,
      };
  }
};

const PercentComparison = ({ difference }) => {
  const { referencePeriod, interval } = useSelector(selectAnalyticsQueryParams);

  return (
    <div className='flex gap-2 px-6 pb-6 flex-col sm:flex-row'>
      <div className='flex gap-0.5'>
        <RenderSVG
          Svg={difference < 0 ? RedArrowDown : GreenArrowUp}
          alt='arrow'
        />
        <p
          className={classNames("text-sm font-medium", {
            "text-green-700": difference > 0,
            "text-red-700": difference < 0,
          })}
        >
          {Math.abs(difference)}%
        </p>
      </div>
      <p className='text-contrast-500 text-sm'>
        vs {referencePeriod === CURRENT ? LAST : THIS} {interval}
      </p>
    </div>
  );
};

const AnalyticsOverviewCard = ({ title, value, unit, difference, icon }) => {
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencySymbol = selectedCountryInfo?.currency?.symbol

  return (
    <div className='col-span-2 md:col-span-1 lg:col-span-1 flex flex-col items-start gap-4 mb-6 lg:mb-0'>
      <div className='gap-2 flex items-center'>
        <SvgIcon icon={icon} />
        <p className='text-contrast-500 font-inter text-sm'>{title}</p>
      </div>
      <div className='flex gap-5 items-center'>
        {!value && (
          <RenderSVG Svg={svgImage(icon)} className='w-20 h-20' />
        )}

        <div className='text-4xl'>
          {value ? renderUnitValue(value, unit, currencySymbol) : "0"}
        </div>
      </div>
      {!!(value && difference) && <PercentComparison difference={difference} />}
    </div>
  );
};
const formatUSD = (value) => {
  if (typeof value !== "number") return `${USD} ZERO`;
  
  const absValue = Math.abs(value);
  let formattedValue;

  if (absValue >= EXPONENT_NINE)
    formattedValue =
      (value / EXPONENT_NINE).toFixed(TWO) + SINGLE_SPACE_STRING + BILLION;
  else if (absValue >= EXPONENT_SIX)
    formattedValue =
      (value / EXPONENT_SIX).toFixed(TWO) + SINGLE_SPACE_STRING + MILLION;
  else if (absValue >= EXPONENT_THREE)
    formattedValue =
      (value / EXPONENT_THREE).toFixed(TWO) + SINGLE_SPACE_STRING + THOUSAND;
  else formattedValue = value.toFixed(TWO)+ SINGLE_SPACE_STRING;

  const parts = formattedValue.split(SINGLE_SPACE_STRING);
  const numberPart = parts[ZERO];

  if (numberPart.endsWith(DOUBLE_DECIMAL_ZERO)) {
    return `${USD} ${numberPart.slice(ZERO, NEG_THREE)}${parts[ONE] ? SINGLE_SPACE_STRING + parts[ONE] : EMPTY_STRING}`;
  } else if (numberPart.endsWith(SINGLE_DECIMAL_ZERO)) {
    return `${USD}${numberPart.slice(ZERO, NEG_TWO)}${parts[ONE] ? SINGLE_SPACE_STRING + parts[ONE] : EMPTY_STRING}`;
  }
  return `${USD}${formattedValue}`;
};

const formatINR = (value) => {
  if (typeof value !== "number") return `${INR} ZERO`;
  const absValue = Math.abs(value);
  let formattedValue;

  if (absValue >= EXPONENT_SEVEN)
    formattedValue =
      (value / EXPONENT_SEVEN).toFixed(TWO) + SINGLE_SPACE_STRING + CRORE;
  else if (absValue >= EXPONENT_FIVE)
    formattedValue =
      (value / EXPONENT_FIVE).toFixed(TWO) + SINGLE_SPACE_STRING + LAKH;
  else if (absValue >= EXPONENT_THREE)
    formattedValue =
      (value / EXPONENT_THREE).toFixed(TWO) + SINGLE_SPACE_STRING + THOUSAND;
  else formattedValue = value.toFixed(TWO) + SINGLE_SPACE_STRING;

  const parts = formattedValue.split(SINGLE_SPACE_STRING);
  const numberPart = parts[ZERO];

  if (numberPart.endsWith(DOUBLE_DECIMAL_ZERO)) {
    return `${INR} ${numberPart.slice(ZERO, NEG_THREE)} ${parts[ONE]}`;
  } else if (numberPart.endsWith(SINGLE_DECIMAL_ZERO)) {
    return `${INR} ${numberPart.slice(ZERO, NEG_TWO)} ${parts[ONE]}`;
  }
  return `${INR} ${formattedValue}`;
};

const TotalAnalyticsCard = ({ name, value, difference, count }) => {
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const { icon, img, description, showButton, isCompleted, btnText } =
    getExpenseDetails(name);

  const currencySymbol = selectedCountryInfo?.currency?.symbol 

  return (
    <div className={`lg:col-span-1 col-span-${count} h-full`}>
      <div className='flex flex-col border min-h-full border-contrast-300 rounded-lg bg-white'>
        <div className='flex flex-col sm:flex-row p-6'>
          <RenderSVG Svg={icon} alt={`total ${name}`} className="stroke-current bg-primary-600 p-1 rounded-lg mr-2 flex flex-col" color="white" width="30" height="30"/>
          <div className='flex justify-start items-center capitalize text-[#111827] text-base font-medium font-inter'>
            Total {name}
          </div>
        </div>

        {value ? (
          <div className='flex flex-col items-start self-stretch'>
            <div className='self-stretch sm:text-xl md:text-2xl lg:text-3xl lg:whitespace-normal lg:break-words p-6 font-semibold overflow-hidden text-ellipsis'>
              {name === TOTAL_ANALYTIC.SPENT
                ? selectedCountryInfo?.currency?.code===DEFAULT_CURRENCY_CODE
                   ? formatINR(value)
                   : selectedCountryInfo?.currency?.code===CURRENCY_TYPES[ONE].code
                   ? formatUSD(value)  
                   :<> 
                      {currencySymbol}&nbsp;
                        <CountUp
                          duration={2} 
                          className="counter"
                          end={value} 
                          decimals={2}
                          useIndianSeparators={true}
                        />
                    </>
                : <CountUp duration={2} className="counter" end={value} />}
            </div>
            {difference && <PercentComparison difference={difference} />}
          </div>
        ) : (
          <div className='flex flex-col gap-4 lg:flex-col xl:flex-row px-6 pb-6'>
            <div className="">
              <RenderSVG
                Svg={img}
                alt={`total ${name}`}
                fill='none'
                className='w-[5vw] h-[5vw]'
              />
            </div>

            <div>
              <div className='text-4xl font-medium mb-[21.5px]'>0</div>
              <div>
                <div className=" text-[14px] text-[#637381]">
                {description}
                </div>
                {showButton && (
                  <button
                    className={classNames(
                      "pt-[6px] pb-[6px] pl-[22px] pr-[22px] mb-[24px] flex justify-center rounded-lg text-white self-stretch text-sm font-medium mt-[21.5px]",
                      {
                        "bg-primary-600": !isCompleted,
                        "bg-contrast-600": isCompleted,
                      }
                    )}
                    disabled={isCompleted}
                  >
                    {btnText}
                  </button>
                )}
              </div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { TotalAnalyticsCard, AnalyticsOverviewCard };
