import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, get, debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Table from "../../components/molecules/Table/Table.js";
import Pagination from "../../components/organisms/Pagination/Pagination.js";
import TransactionsEmptyLayout from "../../components/molecules/EmptyLayout";
import {
  fetchWalletTransactions,
  selectWalletInfo,
  selectWalletTransactions,
} from "./index";
import { getFormattedDate, priceFormatter } from "../../helper/index.js";
import {
  CURRENCY_SYMBOLS,
  DEFAULT_LIMIT,
  DEFAULT_VALUES,
  NOT_AVAILABLE,
  ROUTES,
} from "../../constants.js";
import Spinner, {
  SPINNER_NAMES,
  ProgressBar,
} from "../../components/organisms/Spinner";
import { WalletTransactionsPropsTypes } from "../../prop-types/WalletTransactionsPropType.js";
import { RenderSVG, UserCircle } from "../../assets/icons/index.js";
import Badge from "../../components/atoms/Badge.js";

const { FETCH_WALLET_TRANSACTIONS, GET_WALLET } = SPINNER_NAMES;
const { CREDIT, DEBIT, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { ACCOUNT } = ROUTES;

const TransactionIdFormatter = ({ row }) => {
  const { transactionId } = row;
  return transactionId ? (
    <h6 className='font-medium text-contrast-900 text-sm'>{transactionId}</h6>
  ) : (
    NOT_AVAILABLE
  );
};

const AmountFormatter = ({ row }) => {
  const { currency, amount } = row;
  return amount ? (
    <h6 className='font-medium text-contrast-900 text-sm'>
      {CURRENCY_SYMBOLS[currency] + " " + priceFormatter(amount)}
    </h6>
  ) : (
    NOT_AVAILABLE
  );
};

const CreatedAtFormatter = ({ row }) => {
  const { createdAt } = row;
  return isEmpty(createdAt) ? (
    NOT_AVAILABLE
  ) : (
    <div className='font-medium text-contrast-900 text-sm'>
      {new Date(createdAt).toDateString()}
    </div>
  );
};

const TypeFormatter = ({ row }) => {
  const { type } = row;

  return (
    <div
      className={classNames("py-0.5 px-[10px] inline-flex gap-1 rounded-3xl", {
        "bg-green-100": type === CREDIT,
        "bg-red-100": type !== CREDIT,
      })}
    >
      <span
        className={classNames("text-xs font-medium", {
          "text-green-900": type === CREDIT,
          "text-red-900": type !== CREDIT,
        })}
      >
        {type === CREDIT ? CREDIT : DEBIT}
      </span>
    </div>
  );
};

const StatusFormatter = ({ row }) => {
  const { status } = row;
  return status ? <Badge value={status} /> : NOT_AVAILABLE;
};

const UserDetailsFormatter = ({ row: { users: { Name = "NULL", Email = "NULL", userProfilePic = "" } = {} } }) => (
  <div className='flex gap-4'>
      <div className='avatar shrink-0'>
        {userProfilePic ? (
          <img
            src={userProfilePic}
            className='rounded-full'
            width='40'
            alt='user-icon'
          />
        ) : (
          <img className="rounded-full" src={`https://robohash.org/${Name}.png?size=500x500&&bgset=bg2`} width='40' height='40' alt='user-icon' />
        )}
      </div>

      <div className='flex items-center font-medium text-contrast-900 text-sm'>
      {Name ? (<div>
        <h6 className="font-medium text-contrast-900 text-sm">{Name}</h6>
        <p className="font-medium text-contrast-500 text-xs">{Email}</p>
        </div>
      ) : (<h6 className="font-medium text-contrast-900 text-sm">{Email}</h6>)}
      </div>
    </div>
);

const columns = [
  {
    text: "Transaction ID",
    formatter: <TransactionIdFormatter />,
  },
  {
    text: "User",
    formatter: <UserDetailsFormatter />,
  },
  {
    text: "TIMESTAMP",
    formatter: <CreatedAtFormatter />,
  },
  {
    text: "Amount",
    formatter: <AmountFormatter />,
  },
  {
    text: "Type",
    formatter: <TypeFormatter />,
  },
  {
    text: "Status",
    formatter: <StatusFormatter />,
  },
];

const WalletTransactions = ({searchValue}) => {
  const dispatch = useDispatch();
  const walletTransactions = useSelector(selectWalletTransactions);
  const walletTransactionsData = get(walletTransactions, "data", []);
  const walletInfo = useSelector(selectWalletInfo);
  const [showPagination, setShowPagination] = useState(false);
  const [currentPage , setCurrentPage] = useState();
  const [paginationDetails, setPaginationDetails] = useState(EMPTY_OBJECT);
  const navigate = useNavigate();

  const { id } = walletInfo;

  useEffect(() => {
    setCurrentPage(ONE);
  }, [searchValue]);

  useEffect(() => {
    if (!id) return;
    const formattedQueryParams = `pageSize=${DEFAULT_LIMIT}&pageNumber=${ONE} &searchKey=${""}`;
    dispatch(fetchWalletTransactions({ formattedQueryParams, id }));
  }, [id]);

  const debouncedFetchWalletTransactions = useCallback(
    debounce((currentPage, scrollIntoView, searchKey) => {
      if (scrollIntoView)
        document
          .getElementById("wallet-transactions")
          ?.scrollIntoView({ behavior: "smooth" });
      const formattedQueryParams = `pageSize=${DEFAULT_LIMIT}&pageNumber=${currentPage}&searchKey=${searchKey}`;

      dispatch(
        fetchWalletTransactions({
          formattedQueryParams,
          id,
        })
      );
    }, 300),
    [dispatch, id]
  );

  const handlePagination = (currentPage) => {
    setCurrentPage(currentPage);
    debouncedFetchWalletTransactions(currentPage, true, searchValue);
  };

  useEffect(() => {
    if (!walletTransactions) return;
    const { countInfo } = walletTransactions;
    setShowPagination(countInfo?.count > DEFAULT_LIMIT);
    setPaginationDetails({ ...countInfo, currentPage });
  }, [walletTransactions, currentPage]);

  const handleEmptyLayout = () => navigate(ACCOUNT);

  return (
    <div id='wallet-transactions' className='flex-1 h-full'>
      <ProgressBar
        name={!isEmpty(walletTransactionsData) && FETCH_WALLET_TRANSACTIONS}
      />
      <div className='flex flex-col h-full justify-between'>
        <div className='w-full overflow-auto relative flex flex-col'>
          <Spinner
            name={
              isEmpty(walletTransactionsData) && [
                FETCH_WALLET_TRANSACTIONS,
                GET_WALLET,
              ]
            }
          >
            {!isEmpty(walletTransactionsData) ? (
              <Table columnDefs={columns} rowData={walletTransactionsData} />
            ) : (
              <TransactionsEmptyLayout
                title={"No Transactions Yet"}
                subTitle={
                  "Fund your company wallet to start tracking your travel expenses."
                }
                buttonLabel={"Set up Your Wallet"}
                handleOnClick={handleEmptyLayout}
              />
            )}
          </Spinner>
        </div>
        {!!showPagination && !isEmpty(walletTransactionsData) && (
          <Pagination
            data={paginationDetails}
            setCurrentPage={handlePagination}
          />
        )}
      </div>
    </div>
  );
};

WalletTransactions.propTypes = WalletTransactionsPropsTypes;

export default WalletTransactions;
