import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { debounce, isEmpty, isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { deleteUser, updateUser } from "./users.actions";
import { resendInvitation, sendInvitation } from "../Auth/auth.actions.js";
import {
  capitalizeFirstLetterOfWord,
  getFormattedDate,
  getFromLocalStorage,
} from "../../helper";
import ManageUserModal from "../../components/organisms/AppModals/ManageUserModal";
import {
  ACTION_MODAL_TYPES,
  CACHE_KEYS,
  ROLE_LABEL,
  DEFAULT_VALUES,
  SORT_ORDER,
  NOT_AVAILABLE,
  USERS_SORT_FIELDS,
  DEFAULT_SORT_FIELD,
  DEFAULT_SORT_ORDER,
  INVITE_STATUS,
  USER_FIELDS,
  EMPTY_LAYOUT_TITLE,
} from "../../constants";
import { setUserInfo } from "../Profile/index.js";
import DeleteConfirmationModal from "../../components/organisms/AppModals/DeleteConfirmationModal";
import Pagination from "../../components/organisms/Pagination";
import Spinner, {
  ProgressBar,
  SPINNER_NAMES,
} from "../../components/organisms/Spinner";
import {
  RenderSVG,
  SearchIcon,
  PlusWhite,
  EditIcon,
  SortAscIcon,
  SortDescIcon,
  ResendInvitation,
} from "../../assets/icons";
import { selectUserInfo } from "../Profile/index.js";
import { selectRolesPermissionsAssociationData } from "../Permissions/permissions.selector.js";
import EmptyLayout from "../../components/molecules/EmptyLayout/EmptyLayout.js";
import AddUserModal from "../../components/organisms/AppModals/AddUserModal/AddUserModal.js";
import { selectIsUserAuthenticated } from "../Auth/index.js"; 
import { signout } from "../../helper";
import { cancelAllAPIRequests } from "../../infrastructure/httpMethods/requestingMethods.js";
import { selectCurrentUserInfo } from "../Auth/auth.selector.js";

const { AUTH } = CACHE_KEYS;
const { EDIT_MODAL, DELETE_MODAL, ADD_MODAL } = ACTION_MODAL_TYPES;
const { FETCH_USERS, ADD_USER, UPDATE_USERS, FETCH_INVITES, DELETE_USERS } =
  SPINNER_NAMES;
  const { ZERO, ONE, EMPTY_OBJECT,EMPTY_STRING } = DEFAULT_VALUES;
const { ADMIN, USER } = ROLE_LABEL;
const { DESCENDING, ASCENDING } = SORT_ORDER;
const { PENDING, ACCEPTED, EXPIRED } = INVITE_STATUS;
const { CREATED_AT } = USER_FIELDS;
const pageSize = 10;

const addUserInitialValue = {
  firstName: "",
  lastName: "",
  email: "",
  roleId: "",
  isWalletEnabled: false,
};

const renderUserStatus = (isActive) => (
  <div
    className={classNames("py-0.5 px-[10px] inline-flex gap-1 rounded-3xl", {
      "bg-green-100": isActive,
      "bg-red-100": !isActive,
    })}
  >
    <span
      className={classNames("text-xs font-medium", {
        "text-green-900": isActive,
        "text-red-900": !isActive,
      })}
    >
      {isActive ? "Active" : "InActive"}
    </span>
  </div>
);

const formatDate = (input) => {
  const date = new Date(input);
  return !!date ? date.toDateString() : NOT_AVAILABLE;
};

const renderInviteStatus = (status) => {
  const isPending = status === PENDING;
  const isAccepted = status === ACCEPTED;
  const isExpired = status === EXPIRED;

  return (
    <div
      className={classNames("py-0.5 px-[10px] inline-flex gap-1 rounded-3xl", {
        "bg-yellow-100": isPending,
        "bg-green-100": isAccepted,
        "bg-red-100": isExpired,
      })}
    >
      <span
        className={classNames("text-xs font-medium", {
          "text-yellow-900": isPending,
          "text-green-900": isAccepted,
          "text-red-900": isExpired,
        })}
      >
        {isPending ? "Pending" : isAccepted ? "Accepted" : "Expired"}
      </span>
    </div>
  );
};

const SingleRowItem = ({
  userInfo,
  setSelectedUserInfo,
  setSelectedModal,
  isInvitesList,
  getUsers,
  pageNumber,
  searchValue,
}) => {
  const dispatch = useDispatch();
  let {
    id : invitationId,
    name,
    email,
    profilePic,
    role,
    isActive,
    status,
    createdAt,
    updatedAt,
    expirationDateTime,
    firstName,
    lastName,
    middleName,
  } = userInfo;
  const expirationDate = new Date(expirationDateTime);
  const currentDate = new Date();
  status =
    expirationDate < currentDate && status !== ACCEPTED
      ? EXPIRED
      : status;
  const Username = `${firstName || "Unnamed"} ${
    middleName ? middleName + " " : ""
  }${lastName || ""}`;
  const senderUserInfo = useSelector(selectUserInfo);
  const { tenantId, tenant, name: senderName } = senderUserInfo;
  const { id, email: senderEmail } = getFromLocalStorage(AUTH);
  const requestBody = {
    firstName,
    lastName,
    id:invitationId,
    email: email?.toLowerCase(),
    invitedBy: id,
    senderEmail,
    senderName: capitalizeFirstLetterOfWord(senderName),
    organizationName: tenant?.name,
    tenantId,
  };

  const handleResend = () => {
    dispatch(resendInvitation(requestBody)).then((res) => {
      if (res.error) return;
      dispatch(
        getUsers({
          pageNumber: pageNumber,
          pageSize: pageSize,
          searchKey: searchValue,
          tenantId,
        })
      );

      toast.success(res?.payload?.Success);
    });
  };
  return (
    <tr className="hover:bg-contrast-100/80 hover:shadow-lg hover:cursor-default border border-contrast-200 border-x-0 text-xs">
      <td className=" p-4 pl-12 flex gap-3 flex-row items-center">
        <img className="h-8 w-8 rounded-full" src={profilePic || `https://robohash.org/${name}.png?size=500x500&&bgset=bg2`} alt='user-icon'/>
        <div className="">
          <h6 className="font-medium text-contrast-900 text-sm">{Username ||' '}</h6>
          <p className="font-medium text-contrast-500 text-xs">{email}</p>
        </div>
      </td>
      <td className=" p-4 font-medium ">
        <p>{role}</p>
      </td>
      <td className=" p-4">
        {isInvitesList ? renderInviteStatus(status) : renderUserStatus(isActive)}
      </td>
      <td className=" p-4 ">
        <div className="py-0.5 text-xs font-medium inline-flex items-center gap-[6px]">
          <span>{formatDate(createdAt)}</span>
        </div>
      </td>
      <td className='p-4'>
        <div className='py-0.5 text-xs font-medium inline-flex items-center gap-[6px]'>
          <span>{formatDate(updatedAt)}</span>
        </div>
      </td>
      {isInvitesList && (
        <td className=" p-4 ">
          <div className="py-0.5 text-xs font-medium inline-flex items-center gap-[6px]">
            <span>{formatDate(expirationDateTime)}</span>
          </div>
        </td>
      )}
      {isInvitesList && (
        <td className='p-2'>
          <div className='py-0.5 text-xs font-medium inline-flex items-center gap-[6px]'>
              <RenderSVG
                Svg={ResendInvitation}
                onClick={handleResend}
                width="40"
                height="40"
                className="hover:bg-contrast-200 hover:cursor-pointer rounded-full p-1"
              />
          </div>
        </td>
      )}
      {!isInvitesList && (
        <td className="cursor-pointer pl-12 items-center justify-center">
          <RenderSVG
            Svg={EditIcon}
            onClick={() => {
                setSelectedModal(EDIT_MODAL)
                setSelectedUserInfo(userInfo)
            }}
            className="hover:bg-contrast-200 rounded-full p-3"
            width="50"
            height="50"
          />
        </td>
      )}
    </tr>
  );
};

const UsersList = ({
  id,
  userSelector,
  tableHeaders,
  isInvitesList = false,
  getUsers = () => { },
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const users = useSelector(userSelector);
  const {t} = useTranslation();
  const currentPage = Number(users?.countInfo?.currentPage) || 1;
  const isUserAuthenticated = useSelector(selectIsUserAuthenticated);
  const [pageNumber, setPageNumber] = useState(currentPage);
  const [searchValue, setSearchValue] = useState("");
  const [selectedModal, setSelectedModal] = useState(null);
  const [selectedUserInfo, setSelectedUserInfo] = useState(null);
  const [showPagination, setShowPagination] = useState(false);
  const [paginationDetails, setPaginationDetails] = useState(EMPTY_OBJECT);
  const [sortConfig, setSortConfig] = useState({ header: DEFAULT_SORT_FIELD, order: DEFAULT_SORT_ORDER });
  const [onSearchKey, setOnSearchKey] = useState(false);
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const selectedRoles = useSelector(selectRolesPermissionsAssociationData);
  const { tenantId, tenant, name: senderName} = userInfo;
  const handleCloseModal = () => setSelectedModal(null);

  const handleManageUser = (values) => {
    const {
      id,
      firstName,
      lastName, 
      isActive,
      provider,
      roleId,
      isWalletEnabled
    } = values

    const body = {
      firstName: firstName.trim(),
      lastName: lastName.trim(), 
      isActive,
      provider,
      roleId,
      isWalletEnabled ,
      updatedAt: new Date().toISOString()
    }

    {(currentUserInfo.id === values.id) && dispatch(setUserInfo(values))}
    dispatch(updateUser({ id, body })).then((res) => {
      if (!res.payload) return;
      const { firstName, lastName } = res.payload;
      dispatch(
        getUsers({
          pageNumber: pageNumber,
          pageSize: pageSize,
          searchKey: searchValue,
          tenantId,
        })
      );
      toast.success(`User ${firstName} ${lastName ? lastName : ''} was updated! `);
      handleCloseModal();
      if((userInfo.id == id) && !isActive){
        return isUserAuthenticated && signout();
      };
    });
  };

  const handleDeleteUser = () => {
    const body = { ...selectedUserInfo, IsDeleted: true };

    dispatch(deleteUser({ body })).then((res) => {
      if (!res.payload) return;
      const { message } = res.payload;
      setPageNumber(ONE);
      dispatch(
        getUsers({
          pageNumber: pageNumber,
          pageSize: pageSize,
          searchKey: searchValue,
          tenantId,
        })
      );
      toast.success(message);
      handleCloseModal();
    });
  };

  const handleAddUser = (values) => {
    const { id, email: senderEmail} = getFromLocalStorage(AUTH);

    const requestBody = values.map(({ firstName = EMPTY_STRING, lastName = EMPTY_STRING, email = EMPTY_STRING, roleId = EMPTY_STRING, isWalletEnabled }) => ({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email?.toLowerCase().trim(),
      invitedBy: id,
      senderEmail,
      senderName: capitalizeFirstLetterOfWord(senderName),
      roleId,
      isWalletEnabled,
      organizationName: tenant?.name,
      tenantId,
    }));

    dispatch(sendInvitation(requestBody)).then((res) => {
      if (res.error) return;  
      dispatch(
        getUsers({
          pageNumber: pageNumber,
          pageSize: pageSize,
          searchKey: searchValue,
          tenantId,
        })
      );
      toast.success(res?.payload?.Success);
      handleCloseModal();
    });
  };

  const availableRoles = selectedRoles
  .filter(({ name }) => name.toLowerCase() === ADMIN || name.toLowerCase() === USER)
  .map(({ name, id }) => ({
    label: name,
    class: "",
    value: id,
  }));

 const userRole = selectedRoles.find(({name}) => name.toLowerCase() === USER)
  
  addUserInitialValue.roleId = userRole?.id || selectedRoles[0]?.id;

  const availableStatuses = [
    { label: "Active", class: "" },
    { label: "Inactive", class: "" },
  ];

  const renderSelectedModal = () =>
    ({
      [EDIT_MODAL]: (
        <ManageUserModal
          title="Manage User"
          primaryButtonText="Update"
          handleCloseModal={handleCloseModal}
          handleSubmit={handleManageUser}
          availableRoles={availableRoles}
          availableStatuses={availableStatuses}
          savedInitialValues={selectedUserInfo}
          spinnerName={UPDATE_USERS}
        />
      ),
      [ADD_MODAL]: (
        <AddUserModal
          title="Invite User"
          primaryButtonText="Invite"
          handleCloseModal={handleCloseModal}
          handleSubmit={handleAddUser}
          availableRoles={availableRoles}
          availableStatuses={availableStatuses}
          savedInitialValues={addUserInitialValue}
          spinnerName={ADD_USER}
        />
      ),
      [DELETE_MODAL]: (
        <DeleteConfirmationModal
          title="Delete User?"
          handleClose={handleCloseModal}
          handleSubmit={handleDeleteUser}
          spinnerName={DELETE_USERS}
        >
          <div className="flex flex-col gap-5 text-sm text-contrast-600">
            <div>
              Are you sure you want to delete User{" "}
              <strong>{selectedUserInfo?.name} </strong>
            </div>
            <div>This action cannot be undone.</div>
          </div>
        </DeleteConfirmationModal>
      ),
    }[selectedModal]);

  const debouncedGetUsers = useCallback(
    debounce((searchKey, pageNumber, scrollIntoView,header,order) => {
      if (scrollIntoView)
        document
          .getElementById("user-container")
          ?.scrollIntoView({ behavior: "smooth" });
      cancelAllAPIRequests();
      dispatch(
        getUsers({
          pageNumber,
          pageSize,
          searchKey,
          tenantId,
          sortBy:order,
          sortOn:header 
        })
      );
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setOnSearchKey(true);
    setPageNumber(1);
    debouncedGetUsers(value, 1);
  };

  const handlePagination = (pageNumber) => {
    setPageNumber(pageNumber);
    const { header, order } = sortConfig;
    debouncedGetUsers(searchValue, pageNumber, true, header, order);
  };

  const toggleSortOrder = (header = EMPTY_STRING, sortOrder, index) => {
    setSortConfig(prevConfig => {
      const newHeader = isEmpty(header) ? CREATED_AT : header;
      let newSortOrder = DESCENDING;
      if (isEqual(sortOrder, DESCENDING)) newSortOrder = ASCENDING;
      tableHeaders[index].sortOrder = newSortOrder;

      if (onSearchKey)
        debouncedGetUsers(searchValue, pageNumber, true, newHeader, newSortOrder);
      else handleOrdering({ header: newHeader, order: newSortOrder });

    	return { header: newHeader, order: newSortOrder };
    });
  };

  const handleOrdering = ({ header, order }) => {
    dispatch(
      getUsers({
        pageNumber: 1,
        pageSize,
        searchKey: '',
        tenantId,
        sortOn: header,
        sortBy: order
      })
    );
};

  useEffect(() => {
    setPageNumber(ONE);
  }, [searchValue]);

  useEffect(() => {
    dispatch(
      getUsers({
        pageNumber: 1,
        pageSize,
        searchKey: "",
        tenantId,
      })
    );
  }, [tenantId]);

  useEffect(() => {
    const paginationData = users?.countInfo;
    setShowPagination(paginationData?.count > pageSize);
    setPaginationDetails(paginationData);
  }, [users]);

  return (
    <div className="flex-1 flex flex-col">
      <div
        id='user-container'
        className='p-4 flex gap-8 bg-white border-contrast-200 border-b'
      >
        <div className='flex-1 relative'>
          <RenderSVG
            Svg={SearchIcon}
            alt='Search Icon'
            color='#D1D5DB'
            className='absolute top-2 left-3'
          />
          <input
            type='text'
            className='form-control block w-full text-sm pl-10 py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500'
            placeholder='Enter Username / Email'
            value={searchValue}
            onChange={handleInputChange}
          />
        </div>
        <button
          onClick={() => {
            setSelectedModal(ADD_MODAL);
          }}
          className='py-2 px-4 flex items-center gap-2 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 border-none shadow-sm text-sm text-white font-medium'
        >
          <RenderSVG
            Svg={PlusWhite}
            alt='Plus White Icon'
            color='#D1D5DB'
            className=''
          />{" "}
          Invite User
        </button>
      </div>
      <div className="h-full">
        <ProgressBar
          name={users?.data && (id === "Users" ? FETCH_USERS : FETCH_INVITES)}
        />
        <div className='w-full overflow-auto relative flex flex-col bg-contrast-100 h-full justify-between bg-white '>
          <div className='flex w-full overflow-auto relative'>
            <div className='w-full overflow-auto bg-white'>
              <Spinner
                name={
                  !users?.data && (id === "Users" ? FETCH_USERS : FETCH_INVITES)
                }
                message={t("users.spinnerMessage")}
                spinnerClassName='py-[28vh]'
              >
                {users?.data?.length ? (
                  <table className='border-collapse w-full rounded-lg bg-white  text-sm shadow-sm'>
                    <thead className='bg-contrast-50 bg-contrast-200'>
                      <tr>
                        {tableHeaders.map(({ text: header, allowSorting = false, sortOrder}, index) => (
                          <th
                            key={index}
                            className={classNames('first:pl-12 first:w-4/12 text-xs font-semibold p-4 text-left uppercase hover:bg-contrast-500/40',{
                              "cursor-pointer":(USERS_SORT_FIELDS[header]),
                              "cursor-default":!(USERS_SORT_FIELDS[header])
                            })}
                            onClick={() => {
                              if(USERS_SORT_FIELDS[header]){
                              searchValue !== "" ? setOnSearchKey(true):setOnSearchKey(false);
                              toggleSortOrder(USERS_SORT_FIELDS[header], sortOrder, index);
                            }
                            }}
                            style={{cursor:`${(USERS_SORT_FIELDS[header]) && "pointer" }`}}
                          >
                            <div className="flex gap-2" >
                              <div className="w-10/12">
                                {header}
                              </div>
                              <div className="w-2/12 flex justify-center">
                                <button className="transition-transform duration-300"> 
                                  {allowSorting &&(
                                    <RenderSVG
                                    Svg={sortOrder === ASCENDING ? SortAscIcon : SortDescIcon}
                                    alt='Plus White Icon'
                                    color='#D1D5DB'
                                    className={classNames(
                                      'height-1/8',
                                      {
                                        'fill-none stroke-indigo-500': sortConfig.header === USERS_SORT_FIELDS[header]
                                      })}
                                    />
                                  )}
                                </button>
                              </div> 
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {users?.data?.map((user) => (
                        <SingleRowItem
                          key={user.id}
                          userInfo={user}
                          setSelectedUserInfo={setSelectedUserInfo}
                          setSelectedModal={setSelectedModal}
                          isInvitesList={isInvitesList}
                          getUsers={getUsers}
                          pageNumber={pageNumber}
                          searchValue={searchValue}
                        />
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <EmptyLayout title={EMPTY_LAYOUT_TITLE} />
                )}
              </Spinner>
              {renderSelectedModal()}
            </div>
          </div>
          {!!showPagination && (
            <Pagination
              data={paginationDetails}
              setCurrentPage={handlePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersList;
