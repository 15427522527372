import { useTranslation } from "react-i18next";
import { AirlineContactInfoPropTypes } from "../../../prop-types";
import {
  getAirlineDetailsByCode,
  getAirlineIconUrlByCarrierCode,
  getValidatingAirlineCodes,
} from "../../../helper";

const AirlineContactInfo = ({ itineraries }) => {
  const { t } = useTranslation();
  const airlineCodesList =
    itineraries && getValidatingAirlineCodes(itineraries);

  return (
    <div className='border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6'>
      <div className='p-6'>
        <h4 className='font-bold text-contrast-900 text-base mb-5'>
          {t("bookingResult.airlineContact")}
        </h4>
        <ul className='flex flex-col gap-3'>
          {airlineCodesList?.map((code) => {
            const { airlineName, airlineSupportLink, customerServicePhone } =
              getAirlineDetailsByCode(code);

            return (
              <li className='' key={airlineName}>
                <div className='flex items-center gap-3 '>
                  <div className='logo'>
                    <img
                      src={getAirlineIconUrlByCarrierCode(code)}
                      width='30'
                      className='rounded border border-contrast-300'
                      alt=''
                    />
                  </div>
                  <div className='flex-1'>
                    <p className='text-xs text-contrast-600 mb-1'>
                      {airlineName}
                    </p>
                    <h5 className='text-sm font-semibold text-primary-600 underline'>
                      <a href={`tel:${customerServicePhone}`} target='_blank'>
                        {customerServicePhone}
                      </a>
                    </h5>
                    <h5 className='text-sm font-semibold text-primary-600 underline'>
                      <a target='_blank' href={airlineSupportLink}>
                        {t("bookingResult.contactSupport")}
                      </a>
                    </h5>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

AirlineContactInfo.propTypes = AirlineContactInfoPropTypes;

export default AirlineContactInfo;
