import { useState } from "react";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import get from "lodash/get";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  ROUTES,
  DEFAULT_VALUES,
  CACHE_KEYS,
  USER_AUTH_FIELDS,
  ENVIRONMENTS,
} from "../../constants";
import {
  getFromLocalStorage,
  getQueryParams,
  getRequiredErrorMessage,
  setToLocalStorage,
} from "../../helper";
import { login, setAuthInfo, setIsUserAuthenticated } from "./index";
import {
  Google,
  Microsoft,
  RenderSVG,
  HidePasswordIcon,
  ShowPasswordIcon,
} from "../../assets/icons";
import { fetchTenantDetails } from "./auth.actions";
import { selectTenantDetails } from "./auth.selector";
import Asterisk from "../../components/atoms/Asterisk";
import FlightVideo from "../../assets/video/flightWindow.mp4";

const { LOGIN, TENANT_DETAILS } = SPINNER_NAMES;
const { FORGOT_PASSWORD, BOOKINGS } = ROUTES;
const { EMPTY_STRING } = DEFAULT_VALUES;
const { AUTH, PERMISSIONS, CURRENT_USER_INFO } = CACHE_KEYS;
const { EMAIL } = USER_AUTH_FIELDS;
const isSignUpEnable = false;
const protocol =
  process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION ? "https" : "http";
const secretKey = process.env.REACT_APP_CLIENT_VALIDATION_KEY;

const LogIn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tenantDetails = useSelector(selectTenantDetails);

  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const tenantConfig = get(tenantDetails, "tenantConfig");

  const { logoWithBrand } = tenantConfig;
  const redirectTo = new URLSearchParams(location.search).get('redirectTo') || BOOKINGS;

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("validationSchemas.loginAndSignup.invalidEmail"))
      .required(getRequiredErrorMessage("loginAndSignup.formFields.email", t)),
    password: Yup.string()
      .min(8, t("validationSchemas.loginAndSignup.minPassword"))
      .max(20, t("validationSchemas.loginAndSignup.maxPassword"))
      .required(
        getRequiredErrorMessage("loginAndSignup.formFields.password", t)
      ),
  });

  const authenticateUser = (user) => {
    if (user) {
      const { accessToken, refreshToken, id, email, permissions } = user;
      const auth = { refreshToken, accessToken, id, email };
      if (accessToken && refreshToken) {
        setToLocalStorage(AUTH, auth);
        setToLocalStorage(CURRENT_USER_INFO, user);
        setToLocalStorage(PERMISSIONS, permissions);
        dispatch(setIsUserAuthenticated(!!id));
        dispatch(setAuthInfo(auth));
      } else {
        toast.error(t("loginAndSignup.unableToAuthenticate"));
      }
    }
  };

  const handleOnSubmit = (values, { setSubmitting }) => {
    const { email, password } = values;
    const requestBody = { email, password, provider: EMAIL };
    dispatch(login(requestBody)).then((res) => {
      authenticateUser(res.payload);
      if (res?.payload) {
        const { tenantId } = res.payload;
        dispatch(fetchTenantDetails({ id: tenantId })).then((res) => {
          const { primaryDomain = EMPTY_STRING } = res?.payload || {};
          if (!primaryDomain) {
            localStorage.clear();
            sessionStorage.clear();
            toast.error(
              "Failed to retrieve domain information. Please try again."
            );
            return;
          }

          if (primaryDomain !== window.location.host) {
            const authInfo = getFromLocalStorage(AUTH);
            const { accessToken, refreshToken, id } = authInfo;
            const encryptedId = CryptoJS.AES.encrypt(id, secretKey).toString();
            const encryptedAccessToken = CryptoJS.AES.encrypt(
              accessToken,
              secretKey
            ).toString();
            const encryptedRefreshToken = CryptoJS.AES.encrypt(
              refreshToken,
              secretKey
            ).toString();

            const queryParams = getQueryParams({
              accessToken: encryptedAccessToken,
              refreshToken: encryptedRefreshToken,
              id: encryptedId,
            });
            process.env.ENV === ENVIRONMENTS.PRODUCTION && localStorage.clear();
            process.env.ENV === ENVIRONMENTS.PRODUCTION && sessionStorage.clear();
            window.location.href = `${protocol}://${primaryDomain}/login?${queryParams}`;
          } else navigate(redirectTo, { replace: true });
        });
      }
    });
    setSubmitting(false);
  };

  const renderSignUpOptions = () => {
    return (
      <div>
        <div className='flex items-center gap-4 mb-6'>
          <div className='border-b border-contrast-200 flex-1'></div>
          <span className='text-contrast-700 text-sm font-medium'>Or</span>
          <div className='border-b border-contrast-200 flex-1'></div>
        </div>
        <div className='flex flex-col gap-3'>
          <button
            className='flex items-center justify-center gap-3 py-[10px] px-3 w-full rounded-lg border border-contrast-300 hover:bg-contrast-50 active:bg-white'
            type='button'
          >
            <RenderSVG
              Svg={Google}
              width='24'
              height='24'
              className='flex-shrink-0'
              alt='google'
            />
            <span className='text-base text-contrast-700 font-medium'>
              Continue with Google
            </span>
          </button>
          <button
            className='flex items-center justify-center gap-3 py-[10px] px-3 w-full rounded-lg border border-contrast-300 hover:bg-contrast-50 active:bg-white'
            type='button'
          >
            <RenderSVG
              Svg={Microsoft}
              width='24'
              height='24'
              className='flex-shrink-0'
              alt='microsoft'
            />
            <span className='text-base text-contrast-700 font-medium'>
              Continue with Microsoft
            </span>
          </button>
        </div>
        <p className='text-sm text-contrast-500 text-center mt-6'>
          Don’t have an account?
          <Link className='text-primary-700 font-medium pl-2'>Sign up</Link>
        </p>
      </div>
    );
  };

  return (
    <div className='font-inter bg-primary-50'>
      <div className='min-h-screen flex flex-row-reverse items-center'>
        <div className='shadow-md px-6 py-16  flex flex-col gap-6 h-screen justify-center items-center w-full overflow-hidden  md:w-[550px]'>
          <div className='gap-8 w-full md:w-[450px] '>
            <div className='pb-8 text-center'>
              <img
                src={logoWithBrand}
                width='105'
                height='24'
                className='mx-auto'
                alt='logo'
              />
            </div>
            <div className='w-full'>
              <h1 className='text-2xl text-contrast-900 font-bold mb-2'>
                Log In
              </h1>
              <p className='text-base text-contrast-500'>
                Welcome back! Please enter your login details.
              </p>
            </div>
          </div>

          <Formik
            enableReinitialize
            validateOnMount={true}
            initialValues={{
              email: EMPTY_STRING,
              password: EMPTY_STRING,
            }}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form className='flex flex-col gap-6 flex-initial w-full md:w-[450px]'>
                <div className='form-group'>
                  <label
                    htmlFor='email'
                    className='text-black text-sm font-medium mb-1'
                  >
                    Email
                    <Asterisk />
                  </label>
                  <Field
                    id='email'
                    name='email'
                    className={classNames(
                      "border-contrast-300 rounded-md shadow-sm text-base py-2 px-3 block w-full placeholder-[#6B7280]",
                      {
                        "border-red-600": touched.email && errors.email,
                      }
                    )}
                    type='text'
                    placeholder='Your Email Address'
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='password'
                    className='text-black text-sm font-medium mb-1'
                  >
                    Password
                    <Asterisk />
                  </label>
                  <div className='relative flex'>
                    <Field
                      id='password'
                      name='password'
                      className={classNames(
                        "border-contrast-300 rounded-md shadow-sm text-base py-2 px-3 block w-full placeholder-[#6B7280]",
                        {
                          "border-red-600": touched.password && errors.password,
                        }
                      )}
                      type={shouldShowPassword ? "text" : "password"}
                      placeholder='Your Password'
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <div className='absolute inset-y-0 right-0 mr-3 flex items-center cursor-pointer'>
                      <RenderSVG
                        Svg={
                          shouldShowPassword
                            ? ShowPasswordIcon
                            : HidePasswordIcon
                        }
                        onClick={() => setShouldShowPassword((prev) => !prev)}
                        fill={shouldShowPassword ? "" : "transparent"}
                      />
                    </div>
                  </div>

                  <ErrorMessage
                    name='password'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                </div>
                <Link
                  to={FORGOT_PASSWORD}
                  className='text-primary-600 text-sm underline font-medium'
                >
                  Forgot password?
                </Link>

                <button
                  type='submit'
                  className='py-[10px] px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium'
                  disabled={isSpinnerActive}
                >
                  <Spinner
                    name={[LOGIN, TENANT_DETAILS]}
                    setIsSpinnerActive={setIsSpinnerActive}
                  >
                    Log In
                  </Spinner>
                </button>
                {isSignUpEnable ? renderSignUpOptions() : ""}
              </Form>
            )}
          </Formik>
        </div>

        <div className='h-screen flex-1 overflow-hidden'>
          <video
            className='h-screen w-full object-cover object-top'
            autoPlay
            muted
            loop
          >
            <source src={FlightVideo} type='video/mp4' />
          </video>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
