import { getPriceWithConversion } from "./priceFormatter";
import { getHotelBookingAmount } from "./getHotelBookingAmount";
import { getFlightBookingAmount } from "./getFlightBookingAmount";
import { DEFAULT_VALUES, DEFAULT_EXCHANGE_RATE } from "../constants";

const { ZERO } = DEFAULT_VALUES;

export const getTotalBookingAmountForTrip = (
  trips,
  tenantCurrency,
  exchangeRate
) => {
  let flightTotalPrice = ZERO;
  let hotelTotalPrice = ZERO;

  const currencyExchangeRate =
    exchangeRate[tenantCurrency] || DEFAULT_EXCHANGE_RATE;

  const flightBooking = getFlightBookingAmount(trips?.flights || []);

  const hotelBooking = getHotelBookingAmount(trips?.hotels || []);

  const flightPrice = trips?.flights?.length ? parseFloat(flightBooking) : ZERO;
  const hotelPrice = trips?.hotels?.length ? parseFloat(hotelBooking) : ZERO;
  if (!isNaN(hotelPrice) && hotelPrice > 0)
        hotelTotalPrice = hotelPrice;

  if (!isNaN(flightPrice) && flightPrice > 0) 
    flightTotalPrice = getPriceWithConversion(
      flightPrice,
      currencyExchangeRate
    );

  const totalSum = trips.totalPrice || (flightTotalPrice + hotelTotalPrice);
  const totalAmount = Math.round(totalSum * 100) / 100;
  return totalAmount;
};

export const getTotalFlightPriceForTrip = (
  trips,
  tenantCurrency,
  exchangeRate

) => {
  let totalFlightPrice = ZERO;
  const currencyExchangeRate = exchangeRate[tenantCurrency] || DEFAULT_EXCHANGE_RATE;
  const flightBooking = getFlightBookingAmount(trips?.flights || []);
  const flightPrice = trips?.flights?.length ? parseFloat(flightBooking) : ZERO;
  if (!isNaN(flightPrice) && flightPrice > 0) {
    totalFlightPrice = getPriceWithConversion(flightPrice, currencyExchangeRate);
  }
  
  const res = Math.round(totalFlightPrice * 100) / 100;
  return res;

}

export const getTotalHotelPriceForTrip = (
  trips,
  tenantCurrency,
  exchangeRate
) => {
  let totalHotelPrice = ZERO;
  const currencyExchangeRate = exchangeRate[tenantCurrency] || DEFAULT_EXCHANGE_RATE;
  const hotelBooking = getHotelBookingAmount(trips?.hotels || []);
  const hotelPrice = trips?.hotels?.length ? parseFloat(hotelBooking) : ZERO;
  if (!isNaN(hotelPrice) && hotelPrice > 0) 
    totalHotelPrice = hotelPrice
  const res = Math.round(totalHotelPrice * 100) / 100;
  return res;
}
