import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import mapboxgl from "mapbox-gl";
import Modal from "../../Modal";
import { selectCountryInfo } from "../../../../screens/Profile";
import Map from "./Map";
import HotelCard from "./HotelCard";
import { selectHotels, selectHotelSearchFilters } from "../../Search";
import { setHotelInfoReqBody } from "../../../../screens/HotelInfo";
import { setToSessionStorage } from "../../../../helper";
import { Cross, RenderSVG } from "../../../../assets/icons";
import { CACHE_KEYS } from "../../../../constants";
import classNames from "classnames";
import HotelsMapSkeleton from "../../../organisms/AppSkeletons/HotelsMapSkeleton";
import Spinner from "../../../organisms/Spinner";

const { HOTEL_INFO_REQUEST_BODY } = CACHE_KEYS;
const MARGIN = 0.075;

const HotelsMapModal = ({
  hotels,
  handleClose = () => {},
  defaultSelectedHotel = {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedHotelResults = useSelector(selectHotels);
  const [activeHotel, setActiveHotel] = useState(defaultSelectedHotel);
  const [visibleHotels, setVisibleHotels] = useState(hotels);
  const [showHotelCardComponent, setshowHotelCardComponent] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);
  const searchFilters = useSelector(selectHotelSearchFilters);

  useEffect(() => {
    if (defaultSelectedHotel?.hotelCode) {
      const cardEle = document.getElementById("hotel-card");
      const ele = document.querySelector(".active-hotel-card");
      if (cardEle && ele) {
        cardEle.scroll(0, ele.offsetTop);
      }
    }
  }, [defaultSelectedHotel?.hotelCode]);

  const sourceData = get(selectedHotelResults, "sourceData", []);
  const findTraceId = (sourceData, source) => {
    const data = sourceData.find((item) => item.source === source);
    return data ? data.traceId : null;
  };

  const handleSelectHotel = (hotel) => {
    const { resultIndex, hotelCode, supplierHotelCodes, source } = hotel;
    const categoryId = get(supplierHotelCodes, "[0].categoryId", "");
    const { ip: endUserIp } = selectedCountryInfo;
    const traceId = findTraceId(sourceData, source);
    const {checkInDate,checkOutDate} = searchFilters;

    const hotelInfoAPIReq = {
      resultIndex,
      hotelCode,
      categoryId,
      endUserIp,
      traceId,
      checkInDate:checkInDate.replaceAll("/","-"),
      checkOutDate:checkOutDate.replaceAll("/","-"),
      source: "TBO",
    };
    dispatch(setHotelInfoReqBody(hotelInfoAPIReq));
    setToSessionStorage(HOTEL_INFO_REQUEST_BODY, hotelInfoAPIReq);
    navigate(hotelCode);
  };

  const [ showHotelSkeleton, setShowHotelSkeleton ] = useState(true);
  const handleBoundsChange = (bounds) => {    
    const extendedBounds = new mapboxgl.LngLatBounds(
      [bounds.getWest() - MARGIN, bounds.getSouth() - MARGIN],
      [bounds.getEast() + MARGIN, bounds.getNorth() + MARGIN]
    );

    const newVisibleHotels = hotels.filter((hotel) => {
      const { latitude, longitude } = hotel;
      return extendedBounds.contains([longitude, latitude]);
    });
    setVisibleHotels(newVisibleHotels);
    setshowHotelCardComponent(true);
    setShowHotelSkeleton(false)    
  };
  
  return (
    <Modal
      shouldShowModalFromProps
      modalClassname='!h-[96dvh] flex flex-col !w-[96vw] z-50 mr-4'
    >
      <div className='flex grid-cols-12 flex-1 overflow-scroll border-contrast-200'>
        <div
          onClick={handleClose}
          className='z-50 absolute rounded-full bg-white top-2 right-2 p-2 shadow-lg cursor-pointer w-10 h-10 flex items-center justify-center'
        >
          <RenderSVG
            Svg={Cross}
            className='text-primary-500 hover:text-primary-800'
            width='18'
          />
        </div>
        <div
          id='hotel-card'
          className='col-span-4 w-1/3 scrollbar scroll-smooth overflow-scroll p-1'
        >
          <Spinner 
            showSpinner={showHotelSkeleton} 
            showSkeleton={true} 
            loaderComponent={<HotelsMapSkeleton />}>
            {!isEmpty(visibleHotels) ? (
              visibleHotels.map((hotel) => (
                <div
                  key={hotel.hotelCode}
                  className={classNames("w-full p-2", {
                    "active-hotel-card":
                      defaultSelectedHotel?.hotelCode === hotel.hotelCode,
                  })}
                  onMouseEnter={() => setActiveHotel(hotel)}
                  onMouseLeave={() => setActiveHotel(null)}
                >
                  {showHotelCardComponent && <HotelCard
                    hotel={hotel}
                    handleSelectHotel={handleSelectHotel}
                    defaulSelectedHotel={defaultSelectedHotel}
                  />}
                </div>
              ))
            ) : (
              <div className='text-primary-800 text-md font-semibold w-full h-full p-2 flex items-center justify-center'>
                No hotels available for this specific area.
              </div>
            )}
          </Spinner>
        </div>
        <div
          className='col-span-8 w-2/3 '
          style={{
            pointerEvents: isAnimating ? "none" : "auto",
          }}
        >
          {!isEmpty(hotels) && (
            <Map
              hotels={hotels}
              activeHotel={activeHotel}
              handleSelectHotel={handleSelectHotel}
              onBoundsChange={handleBoundsChange}
              setIsAnimating={setIsAnimating}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default HotelsMapModal;
