import { get } from "lodash";
import { HotelBedIcon, RenderSVG } from "../../../assets/icons";
import DeleteConfirmationModal from "../../../components/organisms/AppModals/DeleteConfirmationModal";
import { MODALS } from "../../../components/organisms/AppModals";
import ApprovalsModal from "../../../components/organisms/AppModals/ApprovalsModal";
import { useState } from "react";
const { APPROVALS_MODAL, DELETE_CONFIRMATION_MODAL } = MODALS;

const HotelDetails = ({ hotelDetails }) => {
  const [selectedModal, setSelectedModal] = useState(null);
  const approvalTitle = "Confirm Partial Trip Approval";
  const approvalPrimaryButtonText = "Partially Approve Trip";
  const rejectTitle = "Reject Hotel";
  const rejectPrimaryButtonText = "Reject";

  const handleClose = () => setSelectedModal(null);
  const hotelName = get(hotelDetails, "hotelName", "");
  const hotelddress = get(hotelDetails, "hotelAddress", "");
  const hotelPrice = get(hotelDetails, "hotelsPrice", 0);
  const hotelPhoneNo = get(hotelDetails, "phoneNo", 0);
  const nights = get(hotelDetails, "noOfNights", 0);
  const checkInDate = get(hotelDetails, "checkIn", "");
  const checkOutDate = get(hotelDetails, "checkOut", "");

  return (
    <div className='p-4 rounded-lg border border-contrast-200 bg-white grid grid-cols-12 xl:gap-10 lg:gap-8 gap-4 mb-4'>
      <div className='col-span-4'>
        <div className='flex gap-2 items-center mb-4'>
          <div className='icon w-6 h-6 grid place-content-center rounded-full bg-pink-100'>
            <RenderSVG
              Svg={HotelBedIcon}
              width='16'
              alt={"Hotel Icon"}
              fill='#9D174D'
            />
          </div>
          <span className='text-contrast-900 text-lg font-semibold'>Hotel</span>
        </div>
        <div className='flex gap-2'>
          <button
            className='flex-1 shadow-sm hover:bg-contrast-50 active:bg-white border-contrast-300 border rounded-md py-2 px-4 text-sm text-contrast-700 font-medium'
            onClick={() => setSelectedModal(DELETE_CONFIRMATION_MODAL)}
          >
            Reject Hotel
          </button>
          <button
            className='flex-1 shadow-sm hover:bg-contrast-50 active:bg-white border-contrast-300 border rounded-md py-2 px-4 text-sm text-contrast-700 font-medium'
            onClick={() => setSelectedModal(APPROVALS_MODAL)}
          >
            Approve Hotel
          </button>
        </div>
      </div>
      <div className='col-span-8'>
        <div className='border border-contrast-200 rounded-lg overflow-hidden'>
          <div className='flex justify-between bg-contrast-50 p-4 border-contrast-200 border-b'>
            <div>
              <h6 className='text-xl font-semibold text-contrast-900'>
                {hotelName}
              </h6>
              <p className='text-base text-contrast-500'>{hotelddress}</p>
            </div>
            <div className='flex items-center gap-4'>
              <div className='text-end'>
                <p className='font-semibold text-contrast-900  text-2xl'>
                  {hotelPrice}
                </p>
                <span className='text-xs text-contrast-600'>
                  Total Nights : {nights}
                </span>
              </div>
            </div>
          </div>
          <div className='p-4 bg-white'>
            <div className='grid grid-cols-12 gap-4'>
              <div className='col-span-3'>
                <img
                  src='../images/hotel.jpg'
                  className='aspect-video rounded-lg'
                  alt=''
                />
              </div>
              <div className='col-span-9 '>
                <div className='grid grid-cols-2 gap-4 mb-3'>
                  <div className='col-span-1'>
                    <h6 className='text-base 2xl:text-lg text-contrast-900 font-semibold'>
                      {" "}
                      {checkInDate}
                    </h6>
                    <p className='text-xs text-contrast-600'>Check-In</p>
                  </div>
                  <div className='col-span-1'>
                    <h6 className='text-base 2xl:text-lg text-contrast-900 font-semibold'>
                      {" "}
                      {checkOutDate}
                    </h6>
                    <p className='text-xs text-contrast-600'>Check-Out</p>
                  </div>
                </div>
                <div>
                  <h6 className='text-base 2xl:text-lg text-contrast-900 font-semibold'>
                    {hotelPhoneNo}
                  </h6>
                  <p className='text-xs text-contrast-600'>Phone Number</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedModal === APPROVALS_MODAL && (
        <ApprovalsModal
          title={approvalTitle}
          primaryButtonText={approvalPrimaryButtonText}
          handleSubmit={() => {}}
          handleClose={handleClose}
        >
          <div>
            <p>Are you sure you want to partially approve this trip?</p>
            <p>
              This action can not be undone, the trip will be sent back to{" "}
              <strong className='font-bold text-black'>Cody</strong> to
              resubmit.
            </p>
          </div>
        </ApprovalsModal>
      )}
      {selectedModal === DELETE_CONFIRMATION_MODAL && (
        <DeleteConfirmationModal
          title={rejectTitle}
          primaryButtonText={rejectPrimaryButtonText}
          handleSubmit={() => {}}
          handleClose={handleClose}
          iconName={HotelBedIcon}
        >
          <div>
            <p className='text-sm pb-1'>Rejection Reason</p>
            <textarea className='resize-none w-full h-28 rounded-lg' />
          </div>
        </DeleteConfirmationModal>
      )}
    </div>
  );
};

export default HotelDetails;
