import Permissions from "./Permissions";
import * as actions from "./permissions.actions";
import * as slice from "./permissions.reducer";
import * as selectors from "./permissions.selector";

export const { getPermissions } = actions;

export const {
  name,
  reducer,
  actions: { setSelectedRolesPermissions },
} = slice;

export const { selectPermissions } = selectors;

export default Permissions;
