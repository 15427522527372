import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import classNames from "classnames";
import get from "lodash/get";
import { createPopper } from "@popperjs/core";
import { Check, RenderSVG, Ticket } from "../../../assets/icons";
import { DEFAULT_VALUES, WINDOWS_EVENTS, TRIP_TYPES } from "../../../constants";

const { ZERO, ONE, TWO, THREE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { ROUND_TRIP, MULTI_CITY, ONE_WAY } = TRIP_TYPES;
const ECONOMY = "Economy";
const TRAVEL_CLASS = "travelClass";
const { CLICK } = WINDOWS_EVENTS;

const FlightClass = () => {
  const { state } = useLocation();
  const { values, setFieldValue } = useFormikContext();
  const [travelClass, setTravelClass] = useState(ECONOMY);
  const [show, setShow] = useState(false);
  const travelClassRef = useRef();
  const selectRef = useRef();
  const dropdownRef = useRef();
  const popperInstance = useRef(null);
  const { isReissuance = false } = state || EMPTY_OBJECT;

  const { t } = useTranslation();
  const { tripType } = values;

  const handleTravelClassChange = (value) => setFieldValue(TRAVEL_CLASS, value);

  const travelClasses = [
    { id: ZERO, label: "searchSection.travelClass.ECONOMY", value: "ECONOMY" },
    {
      id: ONE,
      label: "searchSection.travelClass.PREMIUM_ECONOMY",
      value: "PREMIUM_ECONOMY",
    },
    { id: TWO, label: "searchSection.travelClass.BUSINESS", value: "BUSINESS" },
    { id: THREE, label: "searchSection.travelClass.FIRST", value: "FIRST" },
  ];

  useEffect(() => {
    const travelClassLabel = travelClasses.find(
      ({ value }) => values.travelClass === value
    )?.label;
    setTravelClass(travelClassLabel || ECONOMY);
  }, [values.travelClass]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (travelClassRef.current && !travelClassRef.current.contains(e.target))
        setShow(false);
    };

    document.addEventListener(CLICK, checkIfClickedOutside);

    return () => {
      document.removeEventListener(CLICK, checkIfClickedOutside);
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [travelClassRef.current]);

  useEffect(() => {
    if (show) {
      popperInstance.current = createPopper(
        selectRef.current,
        dropdownRef.current,
        {
          placement: "bottom",
        }
      );
    }

    return () => {
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [show]);

  const isBottom = get(
    popperInstance,
    'current.state.placement.includes("bottom")',
    ""
  );

  return (
    <div
      ref={travelClassRef}
      className={classNames("text-black text-[16px]", {
        "col-span-12 md:col-span-6 lg:col-span-2": tripType === MULTI_CITY,
        "col-span-12 md:col-span-4 lg:col-span-5 xl:col-span-4":
          tripType === ROUND_TRIP || tripType === ONE_WAY,
      })}
    >
      <div
        className={classNames("relative rounded-md", {
          "hover:ring-2 hover:ring-primary-50": !isReissuance,
        })}
      >
        <button
          ref={selectRef}
          type='button'
          onClick={() => setShow(!show)}
          disabled={isReissuance}
          className='rounded-md w-full bg-white border shadow-sm border-contrast-300 flex items-center gap-2 px-3 py-3.5 disabled:bg-gray-200'
        >
          <RenderSVG
            Svg={Ticket}
            className='text-contrast-400'
            alt='Ticket Icon'
          />
          <span className='text-contrast-900 whitespace-nowrap text-ellipsis overflow-hidden'>
            {t(travelClass)}
          </span>
        </button>
        {show && (
          <div
            ref={dropdownRef}
            className={classNames(
              "dropdown-menu absolute z-30 bg-white py-4 rounded-lg border border-contrast-200 shadow-2xl my-4 w-60 left-0",
              {
                "top-full": !isBottom,
                "bottom-full": isBottom,
              }
            )}
          >
            <ul className='flex flex-col gap-2'>
              {travelClasses.map(({ id, label, value }) => (
                <li
                  key={id}
                  onClick={() => {
                    handleTravelClassChange(value);
                    setShow(false);
                  }}
                >
                  <span className='flex gap-3 px-6 py-2 cursor-pointer'>
                    <span
                      className={classNames({
                        "font-semibold !flex": values.travelClass === value,
                      })}
                    >
                      {t(label)}
                    </span>
                    <RenderSVG
                      Svg={Check}
                      width='20'
                      alt='Checkmark'
                      className={classNames("ms-auto hidden", {
                        "!flex": values.travelClass === value,
                      })}
                    />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlightClass;
