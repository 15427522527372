import { useRef, useState } from "react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import classNames from "classnames";
import { useClickOutside } from "../../helper";
import ErrorMessage from "./ErrorMessage";
import { ChevronDown, RenderSVG } from "../../assets/icons";

const Dropdown = ({ options,label, onChange, fieldName, value, placeHolder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const dropDownRef = useRef();

  const { errors, touched } = useFormikContext();

  useClickOutside(dropDownRef, setIsOpen);

  const renderFieldError = () =>
    get(errors, fieldName, false) &&
    get(touched, fieldName, false) && (
      <ErrorMessage errorMessage={get(errors, fieldName)} />
    );

  const handleOptionClick = (label) => {
    setSelectedOption(label);
    onChange(label);
    setIsOpen(false);
  };

  return (
    <div className='form-group mb-6'>
      <label
        htmlFor={fieldName}
        className='block text-sm font-medium mb-1 text-contrast-900'
      >
        {label || fieldName}
      </label>
      <div ref={dropDownRef} className='relative'>
        <input
          value={selectedOption}
          onClick={() => setIsOpen(!isOpen)}
          className='border border-contrast-300 rounded-md px-4 text-base py-[9.5px] cursor-pointer w-full'
          placeholder={placeHolder}
        />
        <div
          className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer'
          onClick={() => setIsOpen(!isOpen)}
        >
          <RenderSVG
            Svg={ChevronDown}
            className='text-contrast-700'
            width='20'
            height='20'
          />
        </div>
        {isOpen && (
          <div className='absolute w-full bg-white border border-contrast-300 rounded shadow-md z-50'>
            {options.map(({ label, class: optionClasses }) => (
              <div
                key={label}
                className='px-4 w-full py-3 cursor-pointer hover:bg-contrast-100'
                onClick={() => handleOptionClick(label)}
              >
                <div
                  className={classNames(
                    "rounded-2xl py-0.5 px-[10px] text-xs font-medium inline-flex items-center",
                    optionClasses
                  )}
                >
                  {label}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div>{renderFieldError()}</div>
    </div>
  );
};

export default Dropdown;
