import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { useNavigate } from "react-router-dom";
import Modal from "../../Modal";
import { actions as modalActions } from "../../Modal/modal.reducers";
import { DateSelector } from "../../../molecules";
import { resetFlights, selectReIssuanceFlightData } from "../../Search";
import {
  DATE_FORMAT,
  DATE_TYPE_TITLES,
  DEFAULT_VALUES,
  TRIP_TYPES,
} from "../../../../constants";
import { getQueryParams } from "../../../../helper";
import { selectCountryInfo } from "../../../../screens/Profile";

const { setSelectedModal } = modalActions;
const { DEPARTURE } = DATE_TYPE_TITLES;
const { ONE_WAY } = TRIP_TYPES;
const { ZERO, ONE, TWO, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;

const getNextDate = (dateString) => {
  const originalDate = new Date(dateString);
  const oneDayAfter = new Date(originalDate);
  oneDayAfter.setDate(originalDate.getDate() + ONE);
  return oneDayAfter.toISOString().split("T")[ZERO];
};

const FlightReIssuanceModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedReIssuanceFlight = useSelector(selectReIssuanceFlightData);
  const { ip: endUserIp } = useSelector(selectCountryInfo) || EMPTY_OBJECT;

  const itineraries =
    selectedReIssuanceFlight.bookingJSON.bookingRequest.itineraries;
  const isRoundTripSelected = itineraries.length === TWO;

  const handleClose = () => {
    dispatch(setSelectedModal(null));
  };

  const handleCloseDatePicker = (values, setFieldValue) => {
    const { departureDate, returnDate } = values;
    isRoundTripSelected &&
      !returnDate &&
      setFieldValue("returnDate", getNextDate(departureDate));
  };

  const handleDepartureDateChange = (values, setFieldValue) => {
    const startDate = get(values, "0");
    const formattedStartDate = startDate?.format(DATE_FORMAT);
    setFieldValue("departureDate", formattedStartDate);
    if (isRoundTripSelected) {
      const endDate = get(values, "1");
      const formattedEndDate = endDate?.format(DATE_FORMAT);
      setFieldValue("returnDate", formattedEndDate);
    }
  };

  const handleSubmit = (values) => {
    const { itineraries, travelerDetails, price } =
      selectedReIssuanceFlight.bookingJSON.bookingRequest;
    const { pnr, provider } = selectedReIssuanceFlight;
    const bookingId =
      selectedReIssuanceFlight.ticketingJSON.ticketingResponse.BookingId;
    const journeys = [
      {
        id: ONE,
        originCode: {
          iata: itineraries[ZERO].segments[ZERO].departure.iataCode,
        },
        destCode: {
          iata: itineraries[isRoundTripSelected ? ONE : ZERO].segments[
            itineraries[isRoundTripSelected ? ONE : ZERO].segments.length - ONE
          ].arrival.iataCode,
        },
        departureDate: values.departureDate,
        departureTime: EMPTY_STRING,
        returnDate: isRoundTripSelected ? values.returnDate : EMPTY_STRING,
      },
    ];

    const passengersCount = travelerDetails.reduce(
      (passengersCount, { travelerType }) => {
        switch (travelerType) {
          case "ADULT":
            passengersCount.adult = (+passengersCount.adult + ONE).toString();
            break;
          case "CHILD":
            passengersCount.children = (
              +passengersCount.children + ONE
            ).toString();
            break;
          case "HELD_INFANT":
            passengersCount.infants = (+passengersCount.infants + ONE).toString;
            break;
        }
        return passengersCount;
      },
      {
        adult: "0",
        children: "0",
        infants: "0",
      }
    );

    //TODO: Need to update the travelCLass based on the previously selected flight.
    // const travelClass = itineraries[0].segments[0].cabinClass;

    const searchReq = {
      journeys,
      passengersCount,
      pnr,
      searchType: "1",
      bookingId,
      provider,
      tripType: isRoundTripSelected ? "roundTrip" : "oneWay",
      travelClass: "ECONOMY",
      currencyCode: price.currency,
      fareType: "Regular",
      isDirectFlight: false,
      endUserIp,
    };

    const queryString = getQueryParams(searchReq);
    dispatch(resetFlights());
    navigate(`?${queryString}`);
    handleClose();
  };

  return (
    <div>
      <Formik
        initialValues={{
          departureDate: new Date(
            new Date().setDate(new Date().getDate() + ONE)
          )
            .toISOString()
            .split("T")[ZERO],
          returnDate: isRoundTripSelected
            ? getNextDate(new Date())
            : EMPTY_STRING,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Modal handleClose={handleClose} title={t("reissuanceModal.title")}>
              <div className='px-10 py-6'>
                <div className='col-span-12 md:col-span-5 lg:col-span-3'>
                  <div className='flex'>
                    <DateSelector
                      showPrices={false}
                      placeholder={DEPARTURE}
                      name='departureDate'
                      defaultDates={[values.departureDate, values.returnDate]}
                      tripType={ONE_WAY}
                      showCalendarIcon={true}
                      minDate={new Date()}
                      height='53px'
                      inputClasses='!rounded-md !w-full !text-left'
                      portal={true}
                      handleChangeFromProps={(values) =>
                        handleDepartureDateChange(values, setFieldValue)
                      }
                      onClose={() =>
                        handleCloseDatePicker(values, setFieldValue)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='flex justify-end p-6 gap-2'>
                <button
                  type='button'
                  className='py-[10px] px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium'
                  onClick={handleClose}
                >
                  {t("reissuanceModal.cancel")}
                </button>
                <button
                  type='submit'
                  className='py-[10px] px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium disabled:cursor-not-allowed disabled:bg-primary-400'
                >
                  {t("reissuanceModal.submit")}
                </button>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FlightReIssuanceModal;
