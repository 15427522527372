import { BOOKING_STATUS } from "../../../constants";
const {
  CONFIRMED,
  AWAITING_PAYMENT,
  IN_REVIEW,
  DRAFT,
  CANCELLED,
  FAILED,
  PENDING,
} = BOOKING_STATUS;
export const getBorderClassForStatus = (status) => {
  switch (status) {
    case CONFIRMED:
      return "border-green-600";
    case IN_REVIEW:
    case AWAITING_PAYMENT || PENDING:
      return "border-yellow-600";
    case DRAFT:
      return "border-gray-600";
    case CANCELLED:
    case FAILED:
      return "border-red-600";
    case PENDING:
      return "border-yellow-600";
    default:
      return "border-white";
  }
};
export const getBackgroundClassForStatus = (status) => {
  switch (status) {
    case CONFIRMED:
      return "bg-green-600";
    case IN_REVIEW:
    case AWAITING_PAYMENT:
      return "bg-yellow-600";
    case DRAFT:
      return "bg-gray-600";
    case CANCELLED:
    case FAILED:
      return "bg-red-600";
    case PENDING:
      return "bg-yellow-600";
    default:
      return "bg-white";
  }
};
export const getBackgroundClassForFlightorHotelName = (status) => {
  switch (status) {
    case CONFIRMED:
      return "bg-green-50";
    case IN_REVIEW:
    case AWAITING_PAYMENT || PENDING:
      return "bg-yellow-50";
    case DRAFT:
      return "bg-gray-200";
    case CANCELLED:
    case FAILED:
      return "bg-red-50";
    case PENDING:
      return "bg-yellow-50";
    default:
      return "bg-white";
  }
};
