import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Asterisk from "../../../atoms/Asterisk";
import Modal, { setSelectedModal } from "../../Modal";
import * as Yup from "yup";
import {
  MODAL_SIZE,
  DEFAULT_VALUES,
  BOOKING_SEARCH_OPTIONS,
  MODAL_TYPES,
} from "../../../../constants";
import {
  createTrip,
  selectNewCostCode,
  selectSelectedTrip,
} from "../../../../screens/Booking/Trips";
import { actions as tripsActions } from "../../../../screens/Booking/Trips/trips.reducer";
import { actions } from "../../Drawer/drawers.reducers";
import Spinner, { SPINNER_NAMES } from "../../Spinner";
import { renderFieldError, setToSessionStorage } from "../../../../helper";
import { Field, Formik } from "formik";
import TravelerSelector from "../../../molecules/MultiSelectField/TravalerSelector";
import AddCostCodeSelector from "../../../molecules/MultiSelectField/AddCostCodeSelector";
import { selectCurrentUserInfo } from "../../../../screens/Auth";
import { getPaxWarningMessage } from "../../../../helper";

const { setSelectedTripId } = tripsActions;
const { setSelectedDrawer } = actions;

const { LG } = MODAL_SIZE;
const { EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY, ZERO, SIXTY } = DEFAULT_VALUES;
const { MANAGE_TRIP, CREATE_NEW_TRIP } = MODAL_TYPES;
const { CREATE_TRIP } = SPINNER_NAMES;
const { BOOKING_ID } = BOOKING_SEARCH_OPTIONS;
const isManagingTrip = false;

const CreateAndManageTripModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const { pathname, search } = location;
  const [selected, setSelected] = useState([]);
  const selectedTrip = useSelector(selectSelectedTrip);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [selectedCostCodeID, setSelectedCostCodeID] = useState(null);
  const { tripName, tripEndDate, tripStartDate } = selectedTrip || EMPTY_OBJECT;
  const warningMessage = getPaxWarningMessage(selected) || EMPTY_STRING;

  const validationSchema = Yup.object({
    tripName: Yup.string()
      .trim()
      .required("Trip name is required.")
      .max(100, "Trip name must be at most 100 characters."),
    costCodes: Yup.object().shape({
      name: Yup.string().required("Cost code is required."),
    }),
    travelers: Yup.array().max(SIXTY, "Maximum 60 travelers are allowed"),
  });

  
  const [initialValues, setInitialValues] = useState({
    tripName: EMPTY_STRING,
    travelers: EMPTY_ARRAY,
    costCodes: EMPTY_OBJECT,
  });
 
  useEffect(() => {
    if (isManagingTrip && result[ZERO]) {
      setInitialValues({
        tripName: tripName || EMPTY_STRING,
        travelers: [],
        codeCodes: EMPTY_OBJECT,
      });
    }
  }, [isManagingTrip, tripName, tripStartDate, tripEndDate]);

  const handleClose = () => {
    dispatch(setSelectedModal(null));
    dispatch(setSelectedDrawer(null));
  };

  const handleOnSubmit = (newTripInput) => {
    const { tripName, travelers } = newTripInput;
    const formattedTravelers = travelers.map(traveler => ({
      id: traveler.id,
      type: traveler.type,
    }));
    const requestBody = {
      tripName: tripName.trim(),
      travelers: formattedTravelers,
    };
    const id = selectedCostCodeID;
    requestBody.costCodeId=id

    dispatch(createTrip({ tripDetails: requestBody })).then((res) => {
      if (res.payload) {
        const tripId = res.payload.id;
        if (!tripId) return;
        if (window.location.pathname.includes(`/users/${currentUserInfo?.id}/bookings`)) {
          navigate(`/users/${currentUserInfo?.id}/bookings/${tripId}`);
        } else {
          navigate(`/bookings/${tripId}`);
        }
        dispatch(setSelectedTripId(tripId));
        setToSessionStorage(BOOKING_ID, tripId);
        handleClose();
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      validateOnMount
    >
      {({
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        errors,
        isValid,
      }) => {
        return (
          <Modal
            size={LG}
            title={isManagingTrip ? MANAGE_TRIP : CREATE_NEW_TRIP}
            shouldShowModalFromProps
            handleClose={handleClose}
            modalClassname="!overflow-visible"
          >
            <>
              <div className=' w-full py-4 px-3 sm:px-6 border-t border-contrast-200 overflow-visible no-scrollbar'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  Trip Name
                  <Asterisk />
                </label>
                <div className='flex w-full border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500 mt-1'>
                  <Field
                    type='text'
                    name='tripName'
                    className=' form-control block w-full text-sm rounded-md py-2 px-3 border-0 placeholder:text-blue-contrast-500 shadow-none'
                    placeholder='Enter Trip Name'
                    onChange={handleChange}
                  />
                </div>
                {renderFieldError("tripName", errors, touched)}
                <label className='block text-sm font-medium mb-1 text-contrast-900 mt-2'>
                  Cost Code
                  <Asterisk />
                </label>
                <AddCostCodeSelector
                  setFieldValue={setFieldValue}
                  name='costCodes'
                  setSelectedCostCodeID={setSelectedCostCodeID}
                />
                {renderFieldError("costCodes.name", errors, touched)}
                <label className='block text-sm font-medium mb-1 text-contrast-900 mt-2'>
                  Traveler
                </label>
                <TravelerSelector
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  name='travelers'
                  values={values}
                  selected={selected}
                  setSelected={setSelected}
                />
                {renderFieldError("travelers", errors, touched)}
                {warningMessage && (
                  <div className='text-[10px] sm:text-sm text-orange-600 bg-yellow-200 rounded-lg mt-2 p-2'>
                    {warningMessage}
                  </div>
                )}
              </div>
              <div className=' flex border-t border-contrast-200 justify-end '>
                <div className='p-6 flex gap-4 '>
                  <button
                    type='reset'
                    className='border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium py-[10px] px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-100 active:bg-white border'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    className='text-white font-medium disabled:cursor-not-allowed disabled:bg-primary-400 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm'
                    type='submit'
                    disabled={!isValid || isSpinnerActive}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <Spinner
                      name={CREATE_TRIP}
                      persistSize={true}
                      setIsSpinnerActive={setIsSpinnerActive}
                    >
                      {isManagingTrip ? "Save" : "Create Trip"}
                    </Spinner>{" "}
                  </button>
                </div>
              </div>
            </>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CreateAndManageTripModal;
