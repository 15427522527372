import { memo, useEffect, useState } from "react";
import { isEmpty, get } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SortingHeaders from "./SortingHeaders";
import OneWayCard from "./OneWayCard";
import { SwiperScript } from "../../utils";
import { DEFAULT_VALUES, FLIGHTS_RESULT_TYPE } from "../../constants";
import {
  selectSortedFlights,
  selectFilteredFlights,
} from "../../components/organisms/Search";
import { getRearrangedFlights } from "../../helper";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { TBOAPICallSkeleton } from "../../components/organisms/AppSkeletons";
import FlightHeader from "./FlightHeader";

const { ZERO, EMPTY_OBJECT } = DEFAULT_VALUES;
const { FETCH_FLIGHTS_RESULTS } = SPINNER_NAMES;
const { OUTBOUND_RESULT, INBOUND_RESULT } = FLIGHTS_RESULT_TYPE;

const getArrangedFlights = (flights = {}) => {
  return [
    { flightType: OUTBOUND_RESULT, flightsData: flights.outbound },
    { flightType: INBOUND_RESULT, flightsData: flights.inbound },
  ];
};

const RoundTripFlights = ({ selectedFlightId }) => {
  const { t } = useTranslation();
  const sortedFlights = useSelector(selectSortedFlights);
  const filteredFlights = useSelector(selectFilteredFlights);
  const individualSortedFlights = get(sortedFlights, "isolated", EMPTY_OBJECT);
  const individualFilteredFlights = get(
    filteredFlights,
    "isolated",
    EMPTY_OBJECT
  );
  const [flights, setFlights] = useState(
    getArrangedFlights(individualSortedFlights)
  );
  useEffect(() => {
    SwiperScript();
  }, []);

  useEffect(() => {
    const applySortingLogic = () => {
      let updatedFlights;
      const selectedInbound = selectedFlightId?.inbound;
      const selectedOutbound = selectedFlightId?.outbound;
      if (
        !selectedInbound &&
        !selectedOutbound &&
        !isEmpty(individualSortedFlights)
      ) {
        updatedFlights = {
          ...individualSortedFlights,
        };
      } else if (!selectedInbound) {
        const rearrangedOutBoundResult = getRearrangedFlights(
          individualFilteredFlights?.outbound,
          selectedOutbound
        );
        updatedFlights = {
          outbound: rearrangedOutBoundResult,
          inbound: individualSortedFlights?.inbound,
        };
      } else if (!selectedOutbound) {
        const rearrangedInBoundResult = getRearrangedFlights(
          individualFilteredFlights?.inbound,
          selectedInbound
        );
        updatedFlights = {
          outbound: individualSortedFlights?.outbound,
          inbound: rearrangedInBoundResult,
        };
      } else if (selectedInbound && selectedOutbound) {
        const rearrangedOutBoundResult = getRearrangedFlights(
          individualFilteredFlights.outbound,
          selectedOutbound
        );
        const rearrangedInBoundResult = getRearrangedFlights(
          individualFilteredFlights.inbound,
          selectedInbound
        );
        updatedFlights = {
          outbound: rearrangedOutBoundResult,
          inbound: rearrangedInBoundResult,
        };
      }
      const arrangedFlights = [
        { flightType: OUTBOUND_RESULT, flightsData: updatedFlights.outbound },
        { flightType: INBOUND_RESULT, flightsData: updatedFlights.inbound },
      ];
      setFlights(arrangedFlights);
    };
    applySortingLogic();
  }, [selectedFlightId, individualSortedFlights, individualFilteredFlights]);

  return (
    <div>
      <div className='swiper flight-results-slider flex flex-row min-h-[800px]'>
          <div className='swiper-wrapper flex justify-between gap-2 '>
            {flights.map(({ flightType, flightsData }) => {
              return (
                <div
                  className=' md:flex-1 md:max-w-[50%]  min-h-60'
                  key={flightType}
                >
                  <FlightHeader resultType={flightType} />
                  {!isEmpty(flightsData) ? (
                    <div className='bg-contrast-50  rounded-xl border border-contrast-200 mr-1'>
                      <SortingHeaders type={flightType} />
                      <Spinner
                        name={FETCH_FLIGHTS_RESULTS}
                        showSkeleton={true}
                        loaderComponent={<TBOAPICallSkeleton />}
                      ></Spinner>
                      <div className='flex flex-col gap-2 pb-2 px-3 '>
                        {flightsData.map((flights) => (
                          <div key={flights[ZERO].flightId}>
                            <OneWayCard
                              flights={flights}
                              currentFlightType={flightType}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className='bg-contrast-50 rounded-xl border border-contrast-200 mr-1 flex justify-center p-5'>
                      <div className='text-center p-5'>
                        <div className='text-primary-600 text-sm mb-6 font-semibold uppercase leading-tight tracking-tight'>
                          {t("flightResults.noFlights.noResults")}
                        </div>
                        <div className='text-contrast-900 lg:text-3xl font-semibold text-xl'>
                          {t("flightResults.noFlights.noMatchFilters")}
                        </div>
                        <div className='text-contrast-500 text-base font-normal leading-normal mt-1'>
                          {t("flightResults.noFlights.adjustFilters")}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
      </div>
    </div>
  );
};

export default memo(RoundTripFlights);
