import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  TRIP_TYPES,
  TRIP_TYPES_ARRAY,
  DEFAULT_VALUES,
  JOURNEY_DEFAULT_VALUES,
} from "../../../constants";
import { getDefaultReturnDate, getISODateString } from "../../../helper";
import { selectSelectedTrip } from "../../../screens/Booking/Trips";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

const { MULTI_CITY, ROUND_TRIP, ONE_WAY } = TRIP_TYPES;
const { EMPTY_STRING, ZERO, EMPTY_OBJECT, TWO } = DEFAULT_VALUES;
const JOURNEY = "journey";

const TripTypeSelector = () => {
  const { state } = useLocation();
  const { values, setFieldValue, setTouched, handleChange } =
    useFormikContext();
  const { t } = useTranslation();
  const tripDetails = useSelector(selectSelectedTrip);
  const { tripEndDate, tripStartDate } = tripDetails || EMPTY_OBJECT;

  const { tripType } = values;
  const { isReissuance = false } = state || EMPTY_OBJECT;

  const handleTripTypeChange = (e) => {
    handleChange(e);
    const type = e.target.value;
    switch (type) {
      case MULTI_CITY:
        setTouched(EMPTY_OBJECT);
        const { departureDate, destCode } = values.journey[ZERO];
        const updatedJourneyValues = [
          { ...values.journey[ZERO], returnDate: EMPTY_STRING },
          {
            ...JOURNEY_DEFAULT_VALUES,
            originCode: destCode,
            destCode: EMPTY_STRING,
            departureDate,
          },
        ];
        setFieldValue(JOURNEY, updatedJourneyValues);
        break;
      case ROUND_TRIP:
        const defaultReturnDate = getDefaultReturnDate(
          values.journey[ZERO].departureDate,
          TWO
        );
        let returnDate = getISODateString(tripEndDate);
        if (tripStartDate && tripEndDate)
          if (
            new Date(values.journey[ZERO].departureDate) > new Date(returnDate)
          )
            returnDate = defaultReturnDate;
        setFieldValue(JOURNEY, [{ ...values.journey[ZERO], returnDate }]);
        break;
      case ONE_WAY:
        setFieldValue(JOURNEY, [
          { ...values.journey[ZERO], returnDate: EMPTY_STRING },
        ]);
    }
  };

  return (
    <div className=' flex flex-wrap items-center gap-4 me-auto'>
      {TRIP_TYPES_ARRAY.map(({ id, value, label }) => (
        <div key={id} className='checkbox'>
          {value !== MULTI_CITY && <label className='flex items-center gap-2 cursor-pointer'>
            <input
              className={classNames(
                "no-shadow text-contrast-600 cursor-pointer",
                { "checked:bg-gray-700": isReissuance }
              )}
              type='radio'
              name='tripType'
              value={value}
              disabled={isReissuance}
              checked={tripType === value}
              onChange={handleTripTypeChange}
            />
            <span
              className={`lg:text-md text-sm cursor-pointer text-contrast-900`}
            >
              {t(label)}
            </span>
          </label>}
        </div>
      ))}
    </div>
  );
};

export default TripTypeSelector;
