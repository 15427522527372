import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedTripId,
  setSearchTimeLog,
  setShowTimer,
} from "../../../../screens/Booking/Trips";
import { SPINNER_NAMES } from "../../Spinner";
import classNames from "classnames";
import { RenderSVG, SearchIcon } from "../../../../assets/icons";
import { DEFAULT_VALUES, TRIP_TYPES } from "../../../../constants";
import { SearchButtonPropTypes } from "../../../../prop-types";

const { FETCH_FLIGHTS_RESULTS } = SPINNER_NAMES;
const { ONE_WAY, ROUND_TRIP, MULTI_CITY } = TRIP_TYPES
const { EMPTY_STRING } = DEFAULT_VALUES

const searchButtonClassnames = {
  [ONE_WAY]: {
    className:
      "col-span-12 md:col-span-3 lg:col-span-2 xl:col-span-4 py-3 px-2 h-full items-center flex gap-2 rounded-md  justify-center text-sm font-medium text-white bg-primary-600 max-h-[54px] disabled:cursor-not-allowed",
    inputClassName: "lg:hidden xl:flex",
  },
  [ROUND_TRIP]: {
    className:
      "col-span-12 md:col-span-3 lg:col-span-2 xl:col-span-4 py-3 px-2 h-full items-center flex gap-2 rounded-md  justify-center text-sm font-medium text-white bg-primary-600 max-h-[54px] disabled:cursor-not-allowed",
    inputClassName: "lg:hidden xl:flex whitespace-nowrap",
  },
  [MULTI_CITY]: {
    className:
      "py-3 px-4 h-full items-center flex gap-2 rounded-md justify-center text-sm font-medium text-white bg-primary-600 w-full sm:w-fit disabled:cursor-not-allowed",
    inputClassName: EMPTY_STRING,
  },
};

const getToolTipMessage = (isSearchingFlights) =>
  isSearchingFlights ? "Searching flights..." : null;
const BUTTON_LABEL = "Find Flights";

const SearchButton = ({ tripType }) => {
  const dispatch = useDispatch();
  const selectedTripId = useSelector(selectSelectedTripId);
  const date = new Date();

  return (
    <button
      className={classNames(searchButtonClassnames[tripType].className)}
      onClick={() => {
        dispatch(
          setSearchTimeLog({ id: selectedTripId, time: date.getTime() })
        );
        dispatch(setShowTimer(true));
      }}
      type='submit'
      data-tooltip-id='spinner-tooltip'
      data-tooltip-place='bottom'
    >
      <RenderSVG Svg={SearchIcon} alt='Search Icon' color='white' />
      <span className={searchButtonClassnames[tripType].inputClassName}>
        {BUTTON_LABEL}
      </span>
    </button>
  );
};

SearchButton.propTypes = SearchButtonPropTypes

export default SearchButton;
