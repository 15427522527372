import { v4 as uuid } from "uuid";
import { CACHE_KEYS, DEFAULT_USER_IP_ADDRESS, ROUTES, DEFAULT_VALUES } from "../constants";
import { resetFlightPriceInfo } from "../screens/FlightResults";
import { actions } from "../screens/FlightResults/flightResults.reducer";
import { getFromSessionStorage, setToSessionStorage } from "./getDataFromBrowserStorage";
import { setSessionFlag } from "../screens/session";
import { setIsFlightSearchInitiated } from "../screens/Booking/FlightBookings";
const { setFlightPriceReq } = actions;
const { FLIGHT_PRICE } = ROUTES
const { FLIGHT_PRICE_REQUEST_BODY, SESSION_ID } = CACHE_KEYS;
const PRICE = "price";
const { EMPTY_OBJECT } = DEFAULT_VALUES

const getUpdatedRequest = (data, flightsData, isReissuanceFlight, sessionId, url) => {
  const { source, resultIndex, flightId, itineraries, price, isLCC, baggage } =
    data;
  const { travelerPricing, totalPrice, basePrice, taxPrice, tavaMarkup } =
    price;
  const { traceId, tokenId } = flightsData;
  const updatedPrice = {
    grandTotal: totalPrice + tavaMarkup,
    basePrice,
    taxPrice,
  };

  const travelerDetails = travelerPricing.map((traveler) => {
    const {
      priceDetails: { totalPrice, basePrice },
      travelerType,
      travelerId,
    } = traveler;

    return {
      id: travelerId,
      travelerType,
      price: { total: totalPrice, base: basePrice },
    };
  });

  const flightDetails = {
    flightId,
    itineraries,
    price: updatedPrice,
    travelerDetails,
  };

  const user_ip_address = btoa(DEFAULT_USER_IP_ADDRESS)

  const session_Id_IP = `${sessionId}_${user_ip_address}`;
  const updatedRequest = {
    journeyId: uuid(),
    source,
    isReissuanceFlight,
    resultIndex,
    isLCC,
    endUserIp: DEFAULT_USER_IP_ADDRESS,
    traceId,
    tokenId,
    price: flightDetails,
    session: sessionId,
    key: PRICE,
    baggage,
    url
  };
  return updatedRequest;
};

export const getFormattedPriceRequest = (
  data,
  flightsData,
  navigate,
  dispatch,
  isReissuanceFlight,
  bookingId,
  currentUserId
) => {
  const { sessionParams, sessionId } =
  getFromSessionStorage(SESSION_ID) || EMPTY_OBJECT;
  const queryParams = `${SESSION_ID}=${sessionParams}`;
  const flightPriceURL = `${FLIGHT_PRICE}?${queryParams}`;
  const updatedRequest = Array.isArray(data)
  ? data.map((flightData) =>
    getUpdatedRequest(flightData, flightsData, isReissuanceFlight, sessionId, flightPriceURL)
)
: [getUpdatedRequest(data, flightsData, isReissuanceFlight, sessionId, flightPriceURL)];

  setToSessionStorage(FLIGHT_PRICE_REQUEST_BODY, updatedRequest);
  dispatch(resetFlightPriceInfo());
  dispatch(setFlightPriceReq(updatedRequest));
  dispatch(setSessionFlag(`session_updated_on_${Date()}`));
  dispatch(setIsFlightSearchInitiated(false));
  if (
    window.location.pathname.includes(
      `/users/${currentUserId}/bookings`
    )
  ) {
    navigate(`/users/${currentUserId}/bookings/${bookingId}/flights/price?${queryParams}`);
  } else {
    navigate(`/bookings/${bookingId}/flights/price?${queryParams}`);
  }
};
