import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { v4 as uuid } from "uuid";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import classNames from "classnames";
import {
  getFormattedDateTime,
  getPrimaryPaxValidationSchema,
  getPanValidationSchema,
  getPassportValidationSchema,
  renderFieldError,
} from "../../helper";
import { RenderSVG, EditIcon, noUserAdded, EditFill } from "../../assets/icons";
import ErrorMessage from "../../components/atoms/ErrorMessage";
import RenderPassportFields from "./RenderPassportFields";
import Modal from "../../components/organisms/Modal";
import { selectOtherGuests } from "../HotelBooking";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import { actions as hotelBookActions } from "../HotelBooking/hotelBooking.reducer";
import GuestPrimaryDetails from "./GuestPrimaryDetails";
import {
  MODAL_SIZE,
  DEFAULT_VALUES,
  MIN_MONTHS_DIFF_FOR_PASSPORT_EXPIRY,
  AVAILABLE_PERMISSIONS,
  UPDATE_DATA_CONSENT_MESSAGE,
} from "../../constants";
import { selectUserInfo } from "../Profile";
import { updateTraveler } from "../Profile/profile.actions";
import { updateUser } from "../Users/users.actions";
import Asterisk from "../../components/atoms/Asterisk";
import { setSessionFlag } from "../session";

const { UPDATE_OWN_DATA, UPDATE_USERS_DATA } = UPDATE_DATA_CONSENT_MESSAGE;
const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const {CAN_UPDATE_ALL_USER_DETAILS, CAN_UPDATE_OWN_PROFILE_DETAILS} = AVAILABLE_PERMISSIONS
const { LG } = MODAL_SIZE;
const { setOtherGuests } = hotelBookActions;
const SHOW_ERROR_DURATION = 3000;

const initialFormValues = {
  profileDetails: {
    title: "Mr",
    firstName: EMPTY_STRING,
    middleName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    isChild: false,
  },
  isSelected: true,
  passportDetails: {
    passportNumber: EMPTY_STRING,
    issueCountry: { isoCode: EMPTY_STRING },
    issuedDate: getFormattedDateTime(new Date())[ZERO],
    expiryDate: getFormattedDateTime(new Date(new Date().setMonth(new Date().getMonth() + MIN_MONTHS_DIFF_FOR_PASSPORT_EXPIRY)))[ZERO],
  },
  panCardDetails: {
    panCardNumber: EMPTY_STRING,
    surname: EMPTY_STRING,
    isChild: false
  },
};

const formatName = (name) => {
  const maxLength = 20; 
  return name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
};

const RenderGuestsList = ({
  type,
  guests = [],
  roomIndex,
  handleEdit = () => { },
  handleSelection = () => { },
  selectedId,
  setSelectedId,
}) => {
  const filteredGuests = guests.filter(
    ({ roomIndex: guestRoomIndex }) =>
      guestRoomIndex === roomIndex || guestRoomIndex === null
  );
  const handleEditClick = (id) => {
    setSelectedId(id);
    handleEdit(id);
  };
  return (
    !isEmpty(filteredGuests) && (
      <div className='mb-4 px-4 py-18'>
        <div className='font-bold text-contrast-800 text-sm mb-4 leading-normal'>
          {type}
        </div>
        <div className='flex flex-col gap-3 mb-2'>
          {filteredGuests.map(({ id, isSelected, profileDetails }) => {
            const { title, firstName, middleName, lastName, isChild } =
              profileDetails;
            const fullName = `${title} ${firstName} ${middleName} ${lastName}`;
            const formattedName = formatName(fullName);
            return (
              <div key={id} className='flex justify-between items-center w-max gap-5'>
                <div className='flex items-center'>
                  <input
                    type='checkbox'
                    checked={isSelected}
                    onChange={() => handleSelection(id, isChild, isSelected)}
                    className='h-5 w-5 mr-4 border-contrast-300 !rounded-sm cursor-pointer checked:bg-primary-700 focus:ring-0'
                  />
                  <span className='capitalize text-contrast-800'>{formattedName}</span>
                </div>
                <button type='button' onClick={() => handleEditClick(id)}>
                  <RenderSVG
                    Svg={EditFill}
                    className={`h-5 w-5 ${
                      selectedId === id ? "text-primary-600" : "text-gray-400"
                      }`}
                  />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

const HotelGuestModal = ({
  handleClose = () => {},
  documentsRequirements,
  roomIndex,
  totalNoOfAdults,
  totalNoOfChildren,
  handleGuestEdit,
  guestDetails,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const otherGuestsInfo = useSelector(selectOtherGuests);
  const [errorMessage, setErrorMessage] = useState("");
  const [isUserAgreed, setIsUserAgreed] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const searchFilters = useSelector(selectHotelSearchFilters);
  const userInfo = useSelector(selectUserInfo);
  const { roomGuests = [], noOfRooms = "" } = searchFilters || {};
  const searchedAdultsCount = roomGuests[roomIndex].noOfAdults;
  const searchedChildrenCount = roomGuests[roomIndex].noOfChild;
  const title = noOfRooms <= 1 ? `Room Guests` : `Room ${roomIndex + 1} Guests`;
  const CHILDREN = "children";
  const ADULTS = "adults";
  const { isPANRequired, isPassportRequired, isSamePanForAllAllowed } =
    documentsRequirements;
  const {
    guestIdToBeEdited,
    setGuestIdToBeEdited,
    shouldConvertToChild,
    setShouldConvertToChild,
    editingGuestValues,
  } = guestDetails;
  const hasPermission = (permission) =>
    userInfo.permissions.includes(permission);
  const hasUpdateAllUsersDetails = hasPermission(CAN_UPDATE_ALL_USER_DETAILS);
  const hasUpdateOwnProfileDetails = hasPermission(
    CAN_UPDATE_OWN_PROFILE_DETAILS
  );
  const passportValidationSchema = getPassportValidationSchema();
  const panValidationSchema = getPanValidationSchema();
  const adultsFilter = ({
    isSelected,
    roomIndex: guestRoomIndex,
    profileDetails: { isChild },
  }) => isSelected && !isChild && guestRoomIndex === roomIndex;
  const childrenFilter = ({
    isSelected,
    roomIndex: guestRoomIndex,
    profileDetails: { isChild },
  }) => isSelected && isChild && guestRoomIndex === roomIndex;

  const baseValidationSchema = {
    profileDetails: Yup.object().shape(getPrimaryPaxValidationSchema(t)),
  };

  const guestValidationSchema = Yup.object().shape({
    ...baseValidationSchema,
    ...(isPassportRequired && passportValidationSchema),
    ...(isPANRequired && !isSamePanForAllAllowed && panValidationSchema.fields),
  });

  const adultGuests = otherGuestsInfo.filter(
    ({ profileDetails: { isChild } }) => !isChild
  );
  const childGuests = otherGuestsInfo
  .filter(({ profileDetails: { isChild } }) => isChild)
  .map(guest => {
    if (guest.profileDetails.title === "Ms") {
      return {
        ...guest,
        profileDetails: {
          ...guest.profileDetails,
          title: "Miss",
        }
      };
    }
    return guest;
  });


  const getFilteredGuests = (guests) => {
    return guests.filter(
      ({ roomIndex: guestRoomIndex }) =>
        guestRoomIndex === roomIndex || guestRoomIndex === null
    );
  };
  
  const checkDuplicateGuests = (guests, profileDetails) => {
    const { firstName, lastName , title } = profileDetails;

    return guests.some(
      ({ profileDetails }) =>
        profileDetails.firstName.toLowerCase() === firstName.toLowerCase() &&
        profileDetails.lastName.toLowerCase() === lastName.toLowerCase() &&
        profileDetails.title.toLowerCase() === title.toLowerCase()
    );
  };
  const handleSubmit = (values, { resetForm }) => {
    const { profileDetails } = values
    const { isChild } = profileDetails || {};

    const adultFilteredGuests = getFilteredGuests(adultGuests)
    const childrenFilteredGuests = getFilteredGuests(childGuests)
    
    const isDuplicateAdult = checkDuplicateGuests(adultFilteredGuests, profileDetails) && !isChild
    const isDuplicateChildren = checkDuplicateGuests(childrenFilteredGuests, profileDetails) && isChild

    if ( isDuplicateAdult || isDuplicateChildren) {
      showError("Guest with the same first name and last name already exists.")
      return;
    }
    let selectedChildrenCount = ZERO, selectedAdultsCount = ZERO;

    if (guestIdToBeEdited) {
      otherGuestsInfo.forEach(guest => {
        if (guest.isSelected && guest.roomIndex === roomIndex) {
          if (guest.profileDetails.isChild) {
            selectedChildrenCount += ONE;
          } else {
            selectedAdultsCount += ONE;
          }
        }
      });

      const updatedGuests = otherGuestsInfo.map(guest => {
        if (guest.id === guestIdToBeEdited) {
          let isSelected;
          if (isChild) {
            isSelected = searchedChildrenCount > selectedChildrenCount;
          } else {
            isSelected = searchedAdultsCount > selectedAdultsCount
          }
          return {
            ...guest,
            ...values,
            isSelected: isSelected,
            roomIndex: isSelected ? roomIndex : null,
          };
        }
        return guest;
      });
      dispatch(setOtherGuests(updatedGuests))
      dispatch(setSessionFlag(`session_updated_on_${Date()}`));
      const id = guestIdToBeEdited;
      const filteredGuests = updatedGuests.filter((guest) => guest.id === id);
      const filteredGuest =
        filteredGuests.length > 0 ? filteredGuests[0] : null;
      const { isUser ,isPaxPresent}=filteredGuest;

      const formattedGuest = isPaxPresent
        ? {
            ...(isUser
              ? {
                  name: `${filteredGuest.profileDetails.firstName} ${
                    filteredGuest.profileDetails.middleName || ""
                  } ${filteredGuest.profileDetails.lastName}`,
                  country: filteredGuest.passportDetails.issueCountry.isoCode,
                  phone: filteredGuest.phoneNumber,
                }
              : {
                  countryName:
                    filteredGuest.passportDetails.issueCountry.isoCode,
                  phoneNumber: filteredGuest.phoneNumber,
                  title: filteredGuest.profileDetails.title,
                }),
            firstName: filteredGuest.profileDetails.firstName,
            middleName: filteredGuest.profileDetails.middleName || "",
            lastName: filteredGuest.profileDetails.lastName,
            PassportIssueCountry:
              filteredGuest.passportDetails.issueCountry.isoCode,
            gender: filteredGuest.profileDetails.gender || "",
            passportNumber: filteredGuest.passportDetails.passportNumber?.trim(),
            passportIssuanceDate: filteredGuest.passportDetails.issuedDate,
            passportExpiryDate: filteredGuest.passportDetails.expiryDate,
            panNumber: filteredGuest.panCardDetails.panCardNumber,
            dateOfBirth: filteredGuest.profileDetails.dateOfBirth,
            city: filteredGuest.city,
            address: filteredGuest.address,
          }
        : null;

      if (
        (hasUpdateOwnProfileDetails || hasUpdateAllUsersDetails) &&
        isUserAgreed &&
        isPaxPresent
      ) {
        isUser
          ? dispatch(updateUser({ id, body: formattedGuest }))
          : dispatch(updateTraveler({ body: formattedGuest, id }));
      }
      setGuestIdToBeEdited(null);
    } else {
      const isSelected = isChild
        ? otherGuestsInfo.filter(childrenFilter).length < searchedChildrenCount
        : otherGuestsInfo.filter(adultsFilter).length < searchedAdultsCount;
      const newGuest = {
        id: uuid(),
        ...values,
        roomIndex: isSelected ? roomIndex : null,
        isSelected,
      };
      dispatch(setOtherGuests([...otherGuestsInfo, newGuest]))
      dispatch(setSessionFlag(`session_updated_on_${Date()}`));
    }
    setShouldConvertToChild(true);
    resetForm();
    setSelectedId(null);
  };

  const showError = (error) => {
    setErrorMessage(error);
    setTimeout(() => setErrorMessage(EMPTY_STRING), SHOW_ERROR_DURATION);
  };


  const handleSelection = (guestId, isChild, isSelected) => {
    const filterCriteria = (detail) =>
      detail.isSelected &&
      detail.roomIndex === roomIndex &&
      (isChild === detail.profileDetails.isChild);

    const selectedGuestsCount = otherGuestsInfo.filter(filterCriteria).length;
    let error = null;
    if (
      !isSelected &&
      selectedGuestsCount >= (isChild ? searchedChildrenCount : searchedAdultsCount)
    ) {
      error = `You have already selected the required number of ${isChild ? CHILDREN : ADULTS}`;
    }
    if (!isSelected && isChild && !searchedChildrenCount) {
      error = "Please select a child before adding.";
    }
    if (error) {
      return showError(error);
    }

    const updatedGuestsArray = otherGuestsInfo.map((guest) => {
      if (guest.id === guestId) {
        const isSelected = !guest.isSelected;
        return {
          ...guest,
          isSelected,
          roomIndex: isSelected ? roomIndex : null,
        };
      }
      return guest;
    });


    const selectedChildrenAfterUpdate = updatedGuestsArray.filter(guest => guest.isSelected && guest.profileDetails.isChild && guest.roomIndex === roomIndex).length;
    const selectedAdultsAfterUpdate = updatedGuestsArray.filter(guest => guest.isSelected && !guest.profileDetails.isChild && guest.roomIndex === roomIndex).length;

    if (selectedChildrenAfterUpdate > searchedChildrenCount || selectedAdultsAfterUpdate > searchedAdultsCount) {
      const revertedGuestsArray = updatedGuestsArray.map((guest) => {
        if (guest.id === guestId) {
          const isSelected = guest.isSelected;
          return {
            ...guest,
            isSelected: !isSelected,
            roomIndex: isSelected ? null : guest.roomIndex,
          };
        }
        return guest;
      });
      dispatch(setOtherGuests(revertedGuestsArray))
      dispatch(setSessionFlag(`session_updated_on_${Date()}`));
      return showError(`You have already selected the required number of ${isChild ? CHILDREN : ADULTS}. To change the selection, please uncheck the traveler first.`);

    }

    dispatch(setOtherGuests(updatedGuestsArray));
    dispatch(setSessionFlag(`session_updated_on_${Date()}`));
    if(!isSelected)
      handleClose();

  };

  return (
    <Modal
      title={title}
      size={LG}
      shouldShowModalFromProps
      handleClose={handleClose}
    >
      <div>
        <div className='border rounded max-h-[80%] overflow-y-scroll'>
          <div>
            <div className=' border-contrast-500 border-dashed border-l md:border-l md:border-l-1 p-0 m-0' />
            <div className='grid md:col-span-7 col-span-12 overflow-y-auto no-scrollbar'>
              <div className='rounded-lg'>
                <Formik
                  initialValues={
                    guestIdToBeEdited ? editingGuestValues : initialFormValues
                  }
                  validationSchema={guestValidationSchema}
                  validateOnMount={true}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ errors, touched, isValid, dirty, values, setFieldValue }) => (
                    <Form>
                      <div className='flex border-b-1 w-full'>
                        <div
                          className={classNames("md:col-span-4 col-span-10 px-4 md:px-10 py-5", {
                            "hidden md:block": isEmpty(otherGuestsInfo),
                          })}
                        >
                          {isEmpty(otherGuestsInfo) ? (
                            <div className='w-full h-full flex flex-col items-center justify-center'>
                              <RenderSVG
                                Svg={noUserAdded}
                                width='120'
                                height='120'
                                color='gray'
                              />
                              <p className='font-semibold lg:bottom-16 '>
                                No Guest Added
                              </p>
                            </div>
                          ) : (
                            <>
                              <ErrorMessage
                                errorMessage={errorMessage}
                                className='mb-2'
                              />
                              <RenderGuestsList
                                type='Adults'
                                guests={adultGuests}
                                roomIndex={roomIndex}
                                handleEdit={handleGuestEdit}
                                handleSelection={handleSelection}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                              />
                              <RenderGuestsList
                                type='Children'
                                guests={childGuests}
                                roomIndex={roomIndex}
                                handleEdit={handleGuestEdit}
                                handleSelection={handleSelection}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                              />
                            </>
                          )}
                        </div>
                        <div className='border border-dashed' />
                        <div className='flex gap-2 flex-col px-4 md:px-10 py-5'>
                          <div className='flex justify-start mb-2'>
                            <div className='text-lg pl-6 font-bold text-contrast-800'>
                              {guestIdToBeEdited
                                ? "Update Guest Info"
                                : "Add New Guest"}
                            </div>
                          </div>
                          <div className='grid grid-cols-12 gap-2 pl-6'>
                            <GuestPrimaryDetails isChildTitle={values?.profileDetails.isChild}/>
                            {isPassportRequired && <RenderPassportFields />}
                            {isPANRequired && !isSamePanForAllAllowed && !values.profileDetails.isChild && (
                              <div className='col-span-12 sm:col-span-6 md:col-span-5'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='panCardNumber'
                                    className='block form-control text-sm font-medium mb-1 text-contrast-900'
                                  >
                                    PAN Number
                                    <Asterisk />
                                  </label>
                                  <Field
                                    id='pandCardNumber'
                                    type='text'
                                    name='panCardDetails.panCardNumber'
                                    className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500'
                                    placeholder='PAN Number'
                                    onChange={(e) => {
                                      const uppercasedValue =
                                        e.target.value.toUpperCase();
                                      setFieldValue(
                                        "panCardDetails.panCardNumber",
                                        uppercasedValue
                                      );
                                    }}
                                  />
                                </div>
                                <div>
                                  {renderFieldError(
                                    "panCardDetails.panCardNumber",
                                    errors,
                                    touched
                                  )}
                                </div>
                              </div>
                            )}
                            <div className='col-span-full my-2'>
                              <Field
                                id='isChildCheckbox'
                                type='checkbox'
                                className='form-control h-5 w-5 cursor-pointer focus:ring-0 !rounded-sm checked:bg-primary-700'
                                name='profileDetails.isChild'
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  setFieldValue('profileDetails.isChild', isChecked);
                                  setFieldValue('panCardDetails.isChild', isChecked);
                                }}
                                disabled={!shouldConvertToChild}
                                checked={values?.profileDetails.isChild}
                                onClick={()=>
                                  setFieldValue('profileDetails.isChild', !values?.profileDetails.isChild)
                                }
                              />
                              <label
                                htmlFor='isChildCheckbox'
                                className='ml-2 text-sm font-medium text-contrast-900'
                              >
                                Below 18 years of age
                              </label>
                              <div className='form-group col-span-12'>
                                {guestIdToBeEdited &&
                                  (hasUpdateAllUsersDetails ||
                                    hasUpdateOwnProfileDetails) && (
                                    <label className='inline-flex items-center'>
                                      <Field
                                        type='checkbox'
                                        name='isUserAgressOnSavingDetails'
                                        className='form-checkbox h-5 w-5 !rounded-sm text-primary-600 transition duration-150 ease-in-out'
                                        onChange={(e) =>
                                          setIsUserAgreed(e.target.checked)
                                        }
                                        checked={isUserAgreed}
                                      />
                                      <div className='flex flex-col'>
                                        <span className='ml-2 text-sm text-contrast-900 font-semibold'>
                                          {userInfo.id === guestIdToBeEdited
                                            ? UPDATE_OWN_DATA
                                            : UPDATE_USERS_DATA}
                                        </span>
                                      </div>
                                    </label>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className='justify-end w-full flex'>
                              <button
                                type='submit'
                                className='py-2 w-fit px-4 items-center justify-center gap-2 rounded-lg bg-primary-600 hover:bg-primary-600 disabled:cursor-not-allowed disabled:opacity-75 shadow-sm text-sm text-white hover:shadow-lg'
                                disabled={!isValid || !dirty}
                              >
                                {guestIdToBeEdited || isUserAgreed
                                  ? "Update Details"
                                  : "Add Guest"}
                              </button>
                            </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className='p-2 py-6 pr-4 flex gap-4 justify-end w-full items-center border-t border-contrast-200'>
                  <button
                    type='close'
                    onClick={handleClose}
                    className='py-2 w-fit px-4 items-center justify-center gap-2 rounded-lg bg-primary-600 hover:bg-primary-700 isabled:cursor-not-allowed disabled:opacity-75 shadow-sm text-sm text-white hover:shadow-lg'
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HotelGuestModal;
