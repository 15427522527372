import reducer from "./auth.reducer";

export const selectSlice = (state) => state[reducer.name];

export const selectAuthInfo = (state) => selectSlice(state).authInfo;

export const selectCurrentUserInfo = (state) =>
  selectSlice(state).currentUserInfo;

export const selectInvitationId = (state) => selectSlice(state).invitationId;

export const selectInvitationDetails = (state) =>
  selectSlice(state).invitationDetails;

export const selectIsUserAuthenticated = (state) =>
  selectSlice(state).isUserAuthenticated;

export const selectTenantDetails = (state) => selectSlice(state).tenantDetails;

export const selectIsSidePanelCollapsed = (state) => selectSlice(state).isSidePanelCollapsed;
