import { useState, useEffect } from "react";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import get from "lodash/get";
import {
  PLATFORM_FEE,
  PLATFORM_FEE_GST,
  CURRENCY_SYMBOLS,
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
  PAYMENT_PROVIDERS,
  DEFAULT_PAYMENT_GATEWAY_CHARGE,
} from "../../../constants";
import { ChevronUp, ChevronDown, RenderSVG } from "../../../assets/icons";
import { priceFormatter, getTotalBookingAmountForTrip } from "../../../helper";
import { selectExchangeRates } from "../../Profile";
import { formatPriceForCurrency } from "../../../helper/priceFormatter";

const PLATFORMFEE = '1.00'
const { ZERO, EMPTY_STRING } = DEFAULT_VALUES;
const { RAZORPAY } = PAYMENT_PROVIDERS;
const { DEFAULT_GATEWAY_CHARGE, DEFAULT_GST_GATEWAY_CHARGE } = DEFAULT_PAYMENT_GATEWAY_CHARGE;

const PAYMENT_GATEWAY_CHARGES = process.env.PAYMENT_GATEWAY_CHARGES || DEFAULT_GATEWAY_CHARGE;
const GST_ON_PAYMENT_GATEWAY_CHARGES = process.env.GST_ON_PAYMENT_GATEWAY_CHARGES || DEFAULT_GST_GATEWAY_CHARGE;

const TripPriceInfo = ({ bookCallbackResponse, selectedTrip, userInfo }) => {
  const { t } = useTranslation();

  const exchangeRate = useSelector(selectExchangeRates);

  const currency = get(userInfo, "tenant.currency", DEFAULT_CURRENCY_CODE);
  const currencySymbol = CURRENCY_SYMBOLS[currency];

  const [totalTripAmount, setTotalTripAmount] = useState(ZERO);
  const [paymentGatewayCharges, setPaymentGatewayCharges] = useState(ZERO);
  const [showGatewayCharges, setShowGatewayCharges] = useState(false);

  const { isExpanded, getCollapseProps, getToggleProps } = useCollapse({
    duration: 200,
  });

  useEffect(() => {
    if (selectedTrip) {
      const tripAmount =
        getTotalBookingAmountForTrip(selectedTrip, currency, exchangeRate) +
        PLATFORM_FEE + PLATFORM_FEE_GST;

      const gatewayPaymentCharges = (
        PAYMENT_GATEWAY_CHARGES * tripAmount +
        GST_ON_PAYMENT_GATEWAY_CHARGES * tripAmount
      ).toFixed(2);
      setPaymentGatewayCharges(
        showGatewayCharges ? parseFloat(gatewayPaymentCharges) : 0
      );
  
      const totalAmount =
        parseFloat(tripAmount.toFixed(2)) +
        (showGatewayCharges ? parseFloat(gatewayPaymentCharges) : 0);
      setTotalTripAmount(totalAmount.toFixed(2));
    }
  }, [selectedTrip, showGatewayCharges, currency, exchangeRate]);
  
  useEffect(() => {
    let payments = get(bookCallbackResponse, "payments", []);

    if (!bookCallbackResponse && selectedTrip)
      payments = get(selectedTrip, "payments", []);

    const lastTransaction = payments.at(-1);
    const isGatewayTransaction =
      get(lastTransaction, "paymentProvider.name", EMPTY_STRING) === RAZORPAY;
    setShowGatewayCharges(isGatewayTransaction);
  }, [bookCallbackResponse, selectedTrip]);

  return (
    <div className='bg-white rounded-lg border border-contrast-300'>
    {showGatewayCharges && paymentGatewayCharges && (<div>
      <div {...getToggleProps()} className='p-4 flex items-center gap-4'>
        <h4 className='text-base font-bold text-contrast-900 flex-1'>
          {t("bookingResult.totalPaid")}
        </h4>
        <div className='flex items-center gap-3'>
          <span className='text-lg font-bold text-primary-700'>
            {formatPriceForCurrency(totalTripAmount , currency)}
          </span>
          <button
            className='flex p-2 cursor-pointer shrink-0'
            {...getToggleProps()}
          >
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              className='text-contrast-800'
              width='20'
            />
          </button>
        </div>
      </div>
      <ul className='flex flex-col text-sm divide-y' {...getCollapseProps()}>
        <li className='flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid'>
          <span className='mr-auto text-contrast-900'>Trip Amount</span>
          <span className='font-medium'>
            {(
              formatPriceForCurrency(parseFloat(
                (
                  totalTripAmount -
                  (PLATFORM_FEE + PLATFORM_FEE_GST + paymentGatewayCharges)
                )
              ) , currency)
            )}
          </span>
        </li>
        <li className='flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid'>
          <div className='mr-auto text-contrast-900'>
            <div>Platform Fee</div>
          </div>
          <span className='font-medium'>
            {currencySymbol}&nbsp;
            {PLATFORMFEE}
          </span>
        </li>
        <li className='flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid'>
          <div className='mr-auto text-contrast-900'>
            <div>GST</div>
          </div>
          <span className='font-medium'>
            {currencySymbol}&nbsp;
            {PLATFORM_FEE_GST}
          </span>
        </li>
        <li className='flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid'>
            <div className='mr-auto text-contrast-900'>
              <div>Gateway</div>
              <div className='text-xs'>(@2% Platform fees)</div>
            </div>
            <span className='font-medium'>
              {formatPriceForCurrency(paymentGatewayCharges , currency)}
            </span>
        </li>
        
        <li className='flex items-center gap-2 py-3 px-4 bg-primary-100/50 border-0 border-b border-contrast-300 border-solid'>
          <span className='text-base font-bold mr-auto text-primary-600'>
            {t("bookingResult.totalPaid")}
          </span>
          <span className='text-base font-bold text-primary-600'>
            {formatPriceForCurrency(totalTripAmount , currency)}
          </span>
        </li>
      </ul>
      </div>
    )}
    </div>
  );
};

export default TripPriceInfo;
