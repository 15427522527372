import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Cross, Filter, RenderSVG, Map } from "../../../assets/icons";
import StarRatingFilters from "./StarRatingFilters";
import CategoryFilters from "./CategoryFilters";
import PriceFilter from "./PriceFilter";
import { selectSelectedDrawer } from "../../../components/organisms/Drawer";
import { DRAWERS } from "../../../components/organisms/AppDrawers/drawer.constants";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { DEFAULT_VALUES } from "../../../constants";
import classNames from "classnames";
import { selectIsSidePanelCollapsed } from "../../Auth";
import HotelsMapModal from "../../../components/organisms/AppModals/HotelsMapModal/HotelsMapModal";
import { selectFilteredHotels, selectHotelsCount, selectHotelsSortingValues } from "../../../components/organisms/Search";

const { SHOW_HOTEL_FILTERS_DRAWER } = DRAWERS;
const { ZERO } = DEFAULT_VALUES;

const FilterHotelsDropDown = ({
  resetFilters,
  setResetFilters,
  showSideFilters = false,
  shouldShowHotelMapModal = false,
  setShouldShowHotelMapModal
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const selectedDrawer = useSelector(selectSelectedDrawer);
  const filteredHotels = useSelector(selectFilteredHotels);
  const sortingValues = useSelector(selectHotelsSortingValues)
  const filtersCount = useSelector(selectHotelsCount);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowFilters(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showFilters]);

  const hotelsWithLocation = filteredHotels.filter(
    (hotel) => !isEmpty(hotel.latitude) && !isEmpty(hotel.longitude)
  );

  const handleMapModalClose = () => setShouldShowHotelMapModal(false);

  return (
    <div ref={ref} className="w-full">
      {!isEmpty(hotelsWithLocation) && (
        <div className='py-2 hidden lg:block w-full'>
          <div className='flex relative rounded-2xl w-full h-full items-center justify-center'>
            <RenderSVG
              Svg={Map}
              className='rounded-2xl'
              width='w-full'
              height='h-full'
            />
            <div className='absolute flex flex-col items-center justify-center'>
              <div className='custom-marker absolute -top-9 min-w-8 min-h-8'>
                <div className='custom-marker-inner !bg-primary-600 hover:!bg-primary-800 hover:cursor-default'></div>
              </div>
              <div
                onClick={() => {
                  setShouldShowHotelMapModal(true);
                }}
                className='border border-primary-600 py-1.5 px-2 bg-primary-600 hover:bg-primary-800 absolute whitespace-nowrap top-2 text-sm font-medium text-white rounded-md hover:cursor-pointer'
              >
                Explore on map
              </div>
            </div>
          </div>
        </div>
      )}
      {!showSideFilters && (
        <div
          className='text-primary-600 text-sm font-medium text-center leading-tight cursor-pointer flex gap-2'
          onClick={() => setShowFilters(!showFilters)}
        >
          <RenderSVG Svg={Filter} alt='Filter Icon' />
          <span>{`${t("hotelResults.filter")} (${filtersCount || ZERO})`}</span>
        </div>
      )}
      {(showFilters || showSideFilters) && (
        <div className={classNames('bg-white rounded-2xl shadow-lg border-r border-contrast-200 flex-col justify-start items-start text-start inline-flex', {
          "w-80 absolute top-12 right-0": !showSideFilters,
          "w-full": showSideFilters,
        })}>
          {selectedDrawer !== SHOW_HOTEL_FILTERS_DRAWER && (
            <div className='px-3 py-4 flex items-center gap-2 border-b border-contrast-200 w-full'>
              <h4 className='flex-1 text-base font-semibold text-contrast-900'>
                {t("hotelResults.filter")}
              </h4>
              {!showSideFilters && (
                <span
                  type='button'
                  className='cursor-pointer'
                  onClick={() => setShowFilters(false)}
                >
                  <RenderSVG Svg={Cross} alt='Cross Icon' />
                </span>
              )}
            </div>
          )}
          <div className='filter-body flex-1 no-scrollbar overflow-x-auto overflow-y-auto pl-6 pr-7 divide-y divide-contrast-200 min-w-[300px] max-h-[380px]'>
            <div className='py-6'>
              <PriceFilter
                header='hotelResults.filters.priceRangeHeader'
                resetFilters={resetFilters}
                setResetFilters={setResetFilters}
              />
            </div>
            {!isEmpty(sortingValues.starRatings) && (
              <div className='py-6'>
                <StarRatingFilters
                  resetFilters={resetFilters}
                  setResetFilters={setResetFilters}
                />
              </div>
            )}
            {/* {!isEmpty(sortingValues.categories) && (
              <div className='py-6'>
                <CategoryFilters
                  header='hotelResults.filters.category.header'
                  categories={sortingValues.categories}
                  resetFilters={resetFilters}
                  setResetFilters={setResetFilters}
                />
              </div>
            )} */}
            {!isEmpty(sortingValues.locality) && (
              <div className='py-6'>
                <CategoryFilters
                  header='hotelResults.filters.locality.header'
                  categories={sortingValues.locality}
                  setResetFilters={setResetFilters}
                  isLocalityFilter={true}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {shouldShowHotelMapModal && (
        <HotelsMapModal
          hotels={hotelsWithLocation}
          handleClose={handleMapModalClose}
        />
      )}
    </div>
  );
};

export default FilterHotelsDropDown;
