import { useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ChevronDown, Checkbox, RenderSVG } from "../../../assets/icons";
import { travelBaggagesDetails, CURRENCY_SYMBOLS } from "../../../constants";
import { priceFormatter } from "../../../helper/priceFormatter";
import { selectCountryInfo } from "../../Profile";

const TravelBaggagesFilters = () => {
  const { t } = useTranslation();
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const [travelBaggage, setTravelBaggage] = useState(travelBaggagesDetails);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  const handleCheckboxChange = (checkboxId) => {
    const updatedTravelBaggage = travelBaggage.map((Baggage) =>
      checkboxId === Baggage.Code
        ? { ...Baggage, checked: !Baggage.checked }
        : Baggage
    );
    setTravelBaggage(updatedTravelBaggage);
  };

  const currencySymbol = get(
    selectedCountryInfo,
    "currency.symbol",
    CURRENCY_SYMBOLS.INR
  );

  return (
    <div>
      <div className='flex items-center gap-2'>
        <h4 className='flex-1 text-lg font-semibold text-contrast-800'>
          {t("flightResults.filters.travelAndBaggage.header")}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt='Chevron Down Icon'
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className='pt-6'>
          <div className='grid grid-cols-2 gap-4'>
            {travelBaggage.map(({ Code, Weight, price, checked }) => (
              <div className='col-span-2' key={Code}>
                <div className='checbox-tab'>
                  <label className='cursor-pointer'>
                    <input
                      type='checkbox'
                      className='peer hidden'
                      id={Code}
                      value={Weight}
                      onChange={() => handleCheckboxChange(Code)}
                      checked={checked}
                    />
                    <div
                      className={classNames(
                        "flex items-center gap-2  border-2  p-4 rounded-lg",
                        {
                          "bg-primary-100 border-primary-600 text-primary-900":
                            checked,
                          "border-contrast-200 text-contrast-900": !checked,
                        }
                      )}
                    >
                      <div
                        className={classNames(
                          "w-4 h-4 rounded grid place-content-center border",
                          {
                            "bg-primary-600 border-primary-600": checked,
                            "border-contrast-300": !checked,
                          }
                        )}
                      >
                        <RenderSVG
                          Svg={Checkbox}
                          width='9'
                          height='9'
                          alt='Checkbox Icon'
                        />
                      </div>
                      <div>
                        <span className='text-sm font-medium flex'>
                          {t(`flightResults.filters.travelAndBaggage.${Code}`)}
                        </span>
                        <span
                          className={classNames("text-sm flex", {
                            "text-primary-700": checked,
                            "text-contrast-500": !checked,
                          })}
                        >
                          {t(
                            "flightResults.filters.travelAndBaggage.startingAt"
                          )}{" "}
                          {currencySymbol}&nbsp;
                          {priceFormatter(price)}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelBaggagesFilters;
