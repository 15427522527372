import {
  RenderSVG,
  RightIcon,
  Information,
  ExclamationCircleIcon,
} from "../../../assets/icons";
import approvalDetails from "../../../assets/json/approvalDetails.json";
import FlightDetails from "./FlightDetails";
import HotelDetails from "./HotelDetails";
import { MODALS } from "../../../components/organisms/AppModals";
import ApprovalsModal from "../../../components/organisms/AppModals/ApprovalsModal";
import { useState } from "react";
import DeleteConfirmationModal from "../../../components/organisms/AppModals/DeleteConfirmationModal";

const { APPROVALS_MODAL, DELETE_CONFIRMATION_MODAL } = MODALS;

const ApprovalsDetails = () => {
  const [selectedModal, setSelectedModal] = useState(null);
  const { UserName, Status, TotalPrice, Bookings } =
    approvalDetails.ApprovalsDetails.ApprovedBookings;

  const handleClose = () => setSelectedModal(null);
  const approvalTitle = "Confirm Entire Trip Approval";
  const approvalPrimaryButtonText = "Approve Entire Trip";
  const rejectTitle = "Reject Entire Trip";
  const rejectPrimaryButtonText = "Reject Entire Trip";

  return (
    <main className='main flex-1 flex flex-col bg-contrast-100'>
      <div className='bg-white border-b border-contrast-200 p-4 flex justify-between'>
        <div className='flex gap-2 items-center'>
          <h4 className='text-base xl:text-xl font-semibold text-contrast-500'>
            Approvals
          </h4>
          <RenderSVG
            Svg={RightIcon}
            width='20'
            alt='Right Icon'
            fill='gray-500'
          />
          <h4 className='text-base xl:text-xl font-semibold text-contrast-900'>
            {UserName} Trip
          </h4>
        </div>
        <div className='flex gap-4 items-center'>
          <div className='rounded-2xl py-0.5 px-[10px] bg-contrast-100 text-contrast-800 text-xs font-medium inline-flex items-center gap-[6px]'>
            <span>{Status}</span>
          </div>
          <div className='pl-4 border-s border-contrast-200 flex gap-2'>
            <button
              className='py-2 px-4 flex items-center gap-2 rounded-md bg-white text-red-600 border border-red-600 hover:bg-red-50 active:bg-white shadow-sm text-sm  font-medium'
              onClick={() => setSelectedModal(DELETE_CONFIRMATION_MODAL)}
            >
              Reject Entire Trip
            </button>
            <button
              className='py-2 px-4 flex items-center gap-2 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 border-none shadow-sm text-sm text-white font-medium'
              onClick={() => setSelectedModal(APPROVALS_MODAL)}
            >
              Approve Entire Trip
            </button>
          </div>
        </div>
      </div>

      <div className='p-4 flex-1 overflow-auto px-8'>
        <div className='bg-white border border-contrast-200 rounded-lg p-4 flex justify-between gap-3 items-center mb-4'>
          <div className=''>
            <p className='text-xs text-contrast-600'>Cost Code</p>
            <h6 className='text-xl text-contrast-900 font-semibold'>
              Figma UX Conference
            </h6>
            <p className='text-base text-contrast-600 '>
              Hi! I’m going to the Figma UX Conference next month. Please let me
              know if you have any questions!
            </p>
          </div>

          <div className='text-end'>
            <p className='font-semibold text-contrast-900  text-2xl '>
              {TotalPrice}
            </p>
            <span className='text-xs text-contrast-600 inline-block'>
              Total Trip Cost
            </span>
          </div>
        </div>
        {Bookings.map((booking, index) => {
          return (
            <div key={index}>
              {booking.tripType === "flight" && (
                <FlightDetails flightDetails={booking.flightDetails} />
              )}
              {booking.tripType === "hotel" && (
                <HotelDetails hotelDetails={booking.hotelDetails} />
              )}
            </div>
          );
        })}
      </div>
      {selectedModal === APPROVALS_MODAL && (
        <ApprovalsModal
          title={approvalTitle}
          primaryButtonText={approvalPrimaryButtonText}
          handleSubmit={() => {}}
          handleClose={handleClose}
        >
          <div>
            <p>
              Are you sure you want to approve this entire trip for{" "}
              <strong className='font-bold text-black'>$2,557?</strong> This
              action can not be undone, all trip types will be booked once
              approved.
            </p>
          </div>
        </ApprovalsModal>
      )}

      {selectedModal === DELETE_CONFIRMATION_MODAL && (
        <DeleteConfirmationModal
          title={rejectTitle}
          primaryButtonText={rejectPrimaryButtonText}
          handleSubmit={() => {}}
          handleClose={handleClose}
          iconName={ExclamationCircleIcon}
        >
          <div>
            <p className='text-sm pb-4'>
              Are you sure you want to reject this entire trip? This action can
              not be undone.
            </p>
            <p className='text-sm pb-1'>Rejection Reason</p>
            <textarea className='resize-none w-full h-28 rounded-lg' />
          </div>
        </DeleteConfirmationModal>
      )}
    </main>
  );
};

export default ApprovalsDetails;
