import { useEffect, useRef, useState } from "react";
import { useCollapse } from "react-collapsed";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { renderFieldError, useClickOutside } from "../../../helper";
import {
  ChevronDown,
  Cross,
  RenderSVG,
  SearchIconGray,
} from "../../../assets/icons";
import { DEFAULT_VALUES } from "../../../constants";

const { EMPTY_ARRAY, EMPTY_STRING } = DEFAULT_VALUES;
const MAX_VISIBLE_VALUES = 15;

const CustomMultiSelectField = ({
  fieldName,
  uniqueKey,
  displayKey,
  label,
  rawData,
  placeholder,
  initialSelectedValues,
}) => {
  const dropDownRef = useRef();
  const componentRef = useRef();
  const selectRef = useRef();

  const { getToggleProps, getCollapseProps, setExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: false,
  });
  const { errors, touched, setFieldValue } = useFormikContext();
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);
  const [shouldShowAllValues, setShouldShowAllValues] = useState(false);
  const [searchValue, setSearchValue] = useState(EMPTY_STRING);
  const [filteredValues, setFilteredValues] = useState(rawData);
  const [minifiedValues, setMinifiedValues] = useState(EMPTY_ARRAY);

  useClickOutside(componentRef, () => setExpanded(false));

  useEffect(() => {
    setFieldValue(fieldName, selectedValues);
  }, [selectedValues]);

  const handleSelectAll = (e) => {
    const updatedValues = e.target.checked ? rawData : EMPTY_ARRAY;
    setSelectedValues(updatedValues);
  };

  const handleUnselectValue = (data) => {
    const updatedValues = selectedValues.filter(
      (each) => each[uniqueKey] !== data[uniqueKey]
    );
    setSelectedValues(updatedValues);
  };

  const handleSelectValue = (e, fieldData) => {
    const { checked } = e.target;
    let updatedValues;
    if (checked)
      updatedValues = Array.from(new Set([...selectedValues, fieldData]));
    else
      updatedValues = selectedValues.filter(
        (data) => data[uniqueKey] !== fieldData[uniqueKey]
      );
    setSelectedValues(updatedValues);
  };

  const toggleShowAllValues = () => {
    setShouldShowAllValues(!shouldShowAllValues);
  };

  const handleSearch = (e) => {
    const searchKeyWord = e.target.value.toLowerCase();
    const formattedSearchKeyword = searchKeyWord.trim();
    setSearchValue(searchKeyWord);
    const filteredResults = rawData.filter((each) =>
      each[displayKey].toLowerCase().includes(formattedSearchKeyword)
    );
    setFilteredValues(filteredResults);
  };

  useEffect(() => {
    //minified the large array
    const formattedValues = shouldShowAllValues
      ? selectedValues
      : selectedValues.slice(0, MAX_VISIBLE_VALUES);

    //sorted the airlines names alphabetically
    const sortedValues = formattedValues.toSorted((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });

    setMinifiedValues(sortedValues);
  }, [selectedValues, shouldShowAllValues]);

  return (
    <div className='form-group'>
      <label
        htmlFor={fieldName}
        className='block text-sm font-medium mb-1 text-contrast-900'
      >
        {label}
      </label>
      <div className='flex flex-col gap-4'>
        <div
          ref={dropDownRef}
          className='w-full z-1000 max-h-40 focus-within:border-gray-500'
        >
          <div
            className={classNames(
              " bg-white border border-gray-200 shadow-sm rounded-md"
            )}
          >
            <div className='flex flex-col opacity-100 max-h-40 overflow-y-auto'>
              <div className='flex-1 p-2 top-0 sticky bg-white rounded-md'>
                <RenderSVG
                  Svg={SearchIconGray}
                  alt='Filter Icon'
                  className='absolute top-1/2 -translate-y-1/2 left-3'
                  width='20'
                />
                <input
                  type='text'
                  placeholder='Search...'
                  value={searchValue}
                  onChange={handleSearch}
                  className='block w-full text-sm pl-10 py-2 px-3 border-gray-300 rounded-lg placeholder:text-blue-gray-50'
                />
              </div>
              <div className='flex gap-2 px-3 py-2 hover:bg-gray-100 cursor-pointer items-center'>
                <label className='w-full text-sm cursor-pointer'>
                  <input
                    id='checkAll'
                    type='checkbox'
                    className='!rounded checked:bg-primary-600 focus:checked:bg-primary-600 hover:checked:bg-primary-600 focus:ring-0'
                    checked={selectedValues.length === rawData.length}
                    onChange={handleSelectAll}
                  />
                  <span className='pl-2'>Select All</span>
                </label>
              </div>
              {filteredValues.map((data) => (
                <div
                  key={data[uniqueKey]}
                  className='flex gap-2 px-3 py-2 hover:bg-gray-100 items-center'
                >
                  <label className='rounded-2xl py-0.5 font-medium inline-flex items-center text-sm cursor-pointer'>
                    <input
                      type='checkbox'
                      className='!rounded checked:bg-primary-600 focus:checked:bg-primary-600 hover:checked:bg-primary-600 focus:ring-0 pl-2'
                      onChange={(e) => handleSelectValue(e, data)}
                      checked={selectedValues.find(
                        (each) => each[uniqueKey] === data[uniqueKey]
                      )}
                    />
                    <span className='pl-2'>{data[displayKey]}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        {!isEmpty(selectedValues) && (
          <div className='flex items-center focus-within:border-primary-500 border-2 rounded-md relative'>
            <div
              ref={selectRef}
              className='relative px-3 w-full cursor-pointer'
            >
              <div className='flex gap-x-2 gap-y-1 flex-wrap min-h-10 items-center py-1 z-50 max-h-36 overflow-auto no-scrollbar'>
                {minifiedValues.map((data) => (
                  <div
                    key={data[uniqueKey]}
                    className='flex items-center gap-1 h-fit text-sm px-2 py-0.5 rounded-xl capitalize bg-gray-100'
                  >
                    {data[displayKey]}
                    <button
                      type='button'
                      onClick={() => handleUnselectValue(data)}
                    >
                      <RenderSVG
                        Svg={Cross}
                        className='text-gray-500 hover:text-gray-700'
                        width='14'
                      />
                    </button>
                  </div>
                ))}
                {selectedValues.length > MAX_VISIBLE_VALUES && (
                  <button
                    type='button'
                    onClick={toggleShowAllValues}
                    className='text-sm text-blue-500'
                  >
                    {shouldShowAllValues ? "View Less" : "View More"}
                  </button>
                )}
              </div>
            </div>
            {renderFieldError(fieldName, errors, touched)}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomMultiSelectField;
