import config from "../../../../config.json";
const { logo } = config;

const TAVA_SUPPORT = {
  LABEL: "support@tavatrip.com",
  MAIL: "mailto:support@tavatrip.com",
};

const Contact = () => {
  return (
    <div className='border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6'>
      <div className='p-6'>
        <h4 className='font-bold text-contrast-900 text-base mb-5'>
          Contact Us
        </h4>
        <div className='flex items-center gap-3 '>
          <div className='logo'>
            <img
              src={logo}
              width='30'
              alt='tavatrip logo'
            />
          </div>
          <div className='flex-1'>
            <p className='text-xs text-contrast-600 mb-1'>
              TavaTrip Customer Support
            </p>
            <h5 className='text-sm font-semibold text-primary-600 underline'>
              <a target='_blank' href={TAVA_SUPPORT.MAIL}>
                {TAVA_SUPPORT.LABEL}
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
