import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import PermissionRow from "./PermissionRow";
import { selectRolesPermissionsAssociationData } from "./permissions.selector";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";

const { GET_ROLES_PERMISSIONS, PERMISSIONS } = SPINNER_NAMES;

const PermissionsTable = () => {
  const rolesPermissionsAssociationData = useSelector(
    selectRolesPermissionsAssociationData
  );

  const thead = ["Roles", "Permissions"];

  return (
    <Spinner name={[GET_ROLES_PERMISSIONS, PERMISSIONS]} size='w-12 h-8'>
      <div className='w-full relative'>
        <table className='w-full bg-white text-sm shadow-sm'>
          <thead className=' bg-contrast-50 border-collapse border border-contrast-200 z-10'>
            <tr>
              {thead.map((item, index) => (
                <th
                  key={index}
                  className={classNames(
                    "text-center border border-slate-200 text-xs font-semibold px-4 py-3 text-contrast-500 uppercase",
                    {
                      "w-auto": item === "Roles",
                    }
                  )}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='overflow-y-scroll max-h-96'>
            {rolesPermissionsAssociationData &&
              rolesPermissionsAssociationData.map(
                (rolePermissionsAssociationData, index) => (
                  <PermissionRow
                    key={index}
                    rolePermissionsAssociationData={
                      rolePermissionsAssociationData
                    }
                  />
                )
              )}
          </tbody>
        </table>
      </div>
    </Spinner>
  );
};

export default PermissionsTable;
