import { DEFAULT_VALUES } from "../constants";

const { NEG_ONE, ZERO, INFINITE } = DEFAULT_VALUES;

const { REACT_APP_BOOKING_TIMER = INFINITE } = process.env;

export const getNextLogWithMinimumTimeLeft = (tripTimerLogs) => {
  let nextLogIndex = NEG_ONE;
  let minimumTimeLeft = Infinity;
  tripTimerLogs.forEach((log, index) => {
    const timeLeft =
      log.startTime + Number(REACT_APP_BOOKING_TIMER) - Date.now();
    if (timeLeft > ZERO && timeLeft < minimumTimeLeft) {
      minimumTimeLeft = timeLeft;
      nextLogIndex = index;
    }
  });
  return nextLogIndex;
};
