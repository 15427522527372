import { RenderSVG } from "../../../assets/icons";

const NoSelectedTripData = ({
    icon,
    text
}) => (
    <div className='flex flex-col p-4 items-center border border-contrast-200 rounded-lg overflow-hidden bg-white '>
        <RenderSVG
            Svg={icon}
            width='56'
            height='56'
        />
        <div className="font-semibold text-xl py-4">
            {text}
        </div>
    </div>
)

export default NoSelectedTripData;
