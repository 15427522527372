import React, { useState, useCallback } from "react";
import Drawer, { selectSelectedDrawer } from "../../organisms/Drawer";
import AddTraveler from "./AddTraveler";
import { POSITIONS, DRAWER_SIZE } from "../../../constants";
import { actions } from "../../organisms/Drawer/drawers.reducers";
import { useDispatch, useSelector } from "react-redux";

const { setSelectedDrawer } = actions;

const { RIGHT } = POSITIONS;
const { MD } = DRAWER_SIZE;

export default function AddUserDrawer() {
  const dispatch = useDispatch();
  const selectedDrawer = useSelector(selectSelectedDrawer);
  const [show, setShow] = useState(true);

  const handleClose = useCallback(() => {
    dispatch(setSelectedDrawer(null));
    setShow(false);
  }, [dispatch]);

  return (
    <div>
      <Drawer
        position={RIGHT}
        size={MD}
        heading='Add a traveler'
        selectedDrawer={selectedDrawer}
        handleClose={handleClose}
        shouldOpen={show}
        customClasses={"!h-full !top-0 !z-[999]"}
      >
        {selectedDrawer === "showAddUserDrawer" && (
          <AddTraveler handleClose={handleClose} setShow={setShow} />
        )}
      </Drawer>
    </div>
  );
}
