import { useEffect, useState, useMemo } from "react";
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  FlightBlue,
  Hotel,
  RenderSVG,
  ReceiptSolid,
} from "../../../assets/icons";
import AISearchTrip from "./AISearchTrip";
import TripCard from "./TripCard";
import {
  fetchTripsById,
  selectSelectedTrip,
  selectSelectedTripId,
} from "../Trips";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import { selectExchangeRates, selectCountryInfo } from "../../Profile";
import {
  formatPriceWithCommas,
  getTotalBookingAmountForTrip,
} from "../../../helper";
import {
  DEFAULT_VALUES,
  BOOK_NEW_TRIP_TABS,
  BOOKING_STATUS,
  WINDOWS_EVENTS,
  shouldEnableAISearch
} from "../../../constants";
import { selectActiveSpinners } from "../../../components/organisms/Spinner";
import { resetFlightPriceInfo, setSelectedFilteredFlights } from "../../FlightResults";
import { setHotelInfoReqBody } from "../../HotelInfo";
import { setSearchHotelFilters, setFilteredHotelResult, setHotels, resetActiveFilters, setHotelsStaticData, setIsHotelsLoaded, setIsStaticDataLoaded } from "../../../components/organisms/Search";
import { setTripStatus, setFlightSearchQuery, setIsFlightSearchInitiated } from "../FlightBookings";
import { useNavigate } from "react-router-dom";

const { EMPTY_OBJECT, ZERO } = DEFAULT_VALUES;
const { FETCH_TRIPS, BOOK_CALLBACK, PROCESS_PAYMENT } = SPINNER_NAMES;
const { FLIGHT, HOTEL } = BOOK_NEW_TRIP_TABS;
const { DRAFT, CONFIRMED, PARTIALLY_CONFIRMED } = BOOKING_STATUS;
const { BEFORE_UNLOAD } = WINDOWS_EVENTS;

const SelectedTrip = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(ZERO);
  const selectedTripId = useSelector(selectSelectedTripId);
  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const exchangeRate = useSelector(selectExchangeRates);
  const activeSpinners = useSelector(selectActiveSpinners);

  const currencySymbol = selectedCountryInfo?.currency?.symbol;
  const currencyCode = selectedCountryInfo?.currency?.code;

  const { tripName, tripReason, status } = selectedTrip || EMPTY_OBJECT;

  const tripStatus = get(selectedTrip, "status", "");

  const TRIP_CARD_CONSTANTS = [
    {
      header: "Flight",
      icon: FlightBlue,
      field: "flights",
      show: !isEmpty(selectedTrip?.flights) || tripStatus === DRAFT,
    },
    {
      header: "Hotel",
      icon: Hotel,
      field: "hotels",
      show: !isEmpty(selectedTrip?.hotels) || tripStatus === DRAFT,
    },
  ];

  const shouldStopPageReload =
    activeSpinners.includes(BOOK_CALLBACK) ||
    activeSpinners.includes(PROCESS_PAYMENT);

  useEffect(() => {
    const handleBeforeUnload = (event) => event.preventDefault();

    shouldStopPageReload &&
      window.addEventListener(BEFORE_UNLOAD, handleBeforeUnload);
    return () => {
      shouldStopPageReload &&
        window.removeEventListener(BEFORE_UNLOAD, handleBeforeUnload);
    };
  }, [shouldStopPageReload]);

  useEffect(() => {
    if (!selectedTrip) return;
    const totalAmount = getTotalBookingAmountForTrip(
      selectedTrip,
      currencyCode,
      exchangeRate
    );
    setAmount(totalAmount);
  }, [selectedTrip, selectedCountryInfo]);

  useEffect(() => {
    if (!selectedTripId) return;
    dispatch(setHotels(EMPTY_OBJECT));
    dispatch(setHotelsStaticData([]));
    let key = "hotelSearchQuery";
    let value = EMPTY_OBJECT;
    dispatch(setTripStatus({ key, value }));
    dispatch(setFlightSearchQuery(EMPTY_OBJECT));
    dispatch(resetFlightPriceInfo());
    dispatch(resetActiveFilters());
    dispatch(setHotelInfoReqBody(EMPTY_OBJECT));
    dispatch(setSearchHotelFilters(EMPTY_OBJECT));
    dispatch(setFilteredHotelResult(EMPTY_OBJECT));
    dispatch(setSelectedFilteredFlights(EMPTY_OBJECT));
    dispatch(setIsFlightSearchInitiated(false));
    dispatch(fetchTripsById(selectedTripId));
    dispatch(setIsHotelsLoaded(false));
    dispatch(setIsStaticDataLoaded(false));
  }, [selectedTripId]);

  const shouldShowTripCard = (field) =>
    status === DRAFT ||
    (status !== DRAFT && selectedTrip && !isEmpty(selectedTrip[field]));

  const handlePreviewInvoice = () => {
    navigate(`preview/invoice`);
  };

  return (
    <div className='flex min-h-full'>
      <div className='main flex-1 flex flex-col bg-contrast-100'>
        <Spinner
          name={[FETCH_TRIPS, BOOK_CALLBACK]}
          message='Fetching Details...'
          spinnerClassName='w-full'
        >
          <div className='flex-1 overflow-auto px-8 pt-4'>
            <div className='bg-white border border-contrast-200 rounded-lg p-4 flex justify-between gap-3 items-center mb-4'>
              <div>
                <p className='text-xs text-contrast-600'>
                  Booking ID :{" "}
                  <span className='font-semibold'>{selectedTripId}</span>
                </p>
                <h6 className='text-xl text-contrast-900 font-semibold'>
                  {tripName}
                </h6>
                <p className='text-base text-contrast-600'>{tripReason}</p>
              </div>

              <div className='text-end'>
                <p className='font-semibold text-primary-700 text-2xl'>
                  {`${currencySymbol} ${formatPriceWithCommas(amount)}`}
                </p>
                {(tripStatus === CONFIRMED || tripStatus === PARTIALLY_CONFIRMED ) && (
                  <button
                    className='flex gap-2 items-center text-sm font-medium cursor-pointer mt-2 btn btn-primary p-2'
                    onClick={handlePreviewInvoice}
                  >
                    <div className='icon'>
                      <RenderSVG Svg={ReceiptSolid} width='12' />
                    </div>
                    <span>Preview Invoice</span>
                  </button>
                )}
              </div>
            </div>
            <div className='grid grid-cols-12 gap-8'>
              <div className='col-span-12'>
                {shouldEnableAISearch && status === DRAFT && (
                  <div className='p-1 mb-4 rounded bg-gradient-to-r from-primary-600 to-amber-500'>
                    <AISearchTrip />
                  </div>
                )}
                {TRIP_CARD_CONSTANTS.map(({ header, icon, field, show }) => (
                  <>
                    {shouldShowTripCard(field) && show && (
                      <TripCard key={header} header={header} icon={icon} />
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </Spinner>
      </div>
    </div>
  );
};

export default SelectedTrip;
