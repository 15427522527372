import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { get, isEmpty } from "lodash";
import * as Yup from "yup";
import {
  getPanValidationSchema,
  getRequiredErrorMessage,
  renderFieldError,
} from "../../helper";
import Asterisk from "../../components/atoms/Asterisk";
import { PhoneNumberInput } from "../../components/atoms/PhoneNumberInput";
import { selectOtherGuests, setLeadGuestInfo, setOtherGuests } from "../HotelBooking";
import { DEFAULT_VALUES, REGEX } from "../../constants";
import { selectBookingTravelers } from "../Booking/FlightBookings";
import { Country } from "country-state-city";
import { selectCountryInfo } from "../Profile";
import DropdownSelectField from "../../components/atoms/DropdownSelectField";
import StateDropdown from "../../components/atoms/StateDropdown";
import { setSessionFlag } from "../session";

const { EMPTY_STRING, ZERO } = DEFAULT_VALUES;
const { EMAIL, PHONE_NUMBER } = REGEX;
const INDIA = "India";

const initialFormValues = {
  profileDetails: {
    email: EMPTY_STRING,
    phoneNumber: EMPTY_STRING,
    phoneCode: EMPTY_STRING,
    state: EMPTY_STRING,
    nationality: { name: EMPTY_STRING, isoCode: EMPTY_STRING },
  },
  panCardDetails: {
    panCardNumber: EMPTY_STRING,
    surname: EMPTY_STRING
  },
};

const BookingPrimaryDetails = ({
  bookingPrimaryDetailsFormRef,
  isPANRequired,
  isSamePanForAllAllowed,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedBookingTravelers = useSelector(selectBookingTravelers);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const otherGuests = useSelector(selectOtherGuests);
  const countryList = Country.getAllCountries();
  const [initialValues, setInitialValues] = useState(initialFormValues);
  const firstSelectedGuest = otherGuests.find((guest) => guest.isSelected && guest.profileDetails?.isChild === false)
  let surname = EMPTY_STRING;
  if (firstSelectedGuest) {
    surname = firstSelectedGuest?.profileDetails.lastName || EMPTY_STRING;
  }
  const defaultNationality = countryList.find(
    (country) => country?.isoCode === selectedCountryInfo?.code
  );

  useEffect(() => { 
    if (isPANRequired && isSamePanForAllAllowed && bookingPrimaryDetailsFormRef.current) 
      bookingPrimaryDetailsFormRef.current.validateForm();
  }, [isPANRequired, isSamePanForAllAllowed]);

  useEffect(() => {
    if (isEmpty(selectedBookingTravelers)) {
      setInitialValues((prev) => ({
        ...prev,
        profileDetails: {
          ...prev.profileDetails,
          nationality: {
            isoCode: defaultNationality?.isoCode || EMPTY_STRING,
            name: defaultNationality?.name || EMPTY_STRING,
          },
        },
      }));
      return;
    }
    const selectedTraveler = selectedBookingTravelers[0];
    const { email, phoneNumber, panCardDetails, state, country } =
      selectedTraveler;
    const { panCardNumber } = panCardDetails;
    const updatedValues = {
      profileDetails: {
        email,
        phoneCode: phoneNumber.substring(ZERO, phoneNumber.indexOf("-")),
        phoneNumber: phoneNumber.replace("+", "").replace("-", ""),
        state,
        nationality: { isoCode: EMPTY_STRING, name: country },
      },
      panCardDetails: {
        panCardNumber,
        surname : surname
      },
    };    
    setInitialValues(updatedValues);
  }, [otherGuests, selectedBookingTravelers]);

  const baseValidationSchema = {
    profileDetails: Yup.object().shape({
      email: Yup.string()
        .matches(EMAIL, t("profilePage.errors.emailFormat"))
        .required(getRequiredErrorMessage("profilePage.email", t)),
      phoneNumber: Yup.string()
        .matches(PHONE_NUMBER, t("profilePage.errors.phone"))
        .required(getRequiredErrorMessage("profilePage.phone", t)),
      state: Yup.string().required(
        getRequiredErrorMessage("profilePage.state", t)
      ),
      nationality: Yup.object().shape({
        isoCode: Yup.string().required(
          getRequiredErrorMessage("profilePage.nationality", t)
        ),
      }),
    }),
  };
  const panValidationSchema = getPanValidationSchema();

  const validationSchema = Yup.object().shape({
    ...baseValidationSchema,
    ...(isPANRequired && isSamePanForAllAllowed  && panValidationSchema.fields),
  });

  const handleSaveGuestDetails = (values) => {
    dispatch(setLeadGuestInfo(values));
    dispatch(setSessionFlag(`session_updated_on_${Date()}`));
  };

  const handlePanCardChange = (e, setFieldValue) => {
    const uppercasedValue = e.target.value.toUpperCase();
    setFieldValue(
      "panCardDetails.panCardNumber",
      uppercasedValue
    );
    setFieldValue("panCardDetails.surname", surname);
    if(isPANRequired && isSamePanForAllAllowed) {
      const panCardNumber = uppercasedValue
      const guestIndex = otherGuests.findIndex(
        (guest) => guest.isSelected && !guest.profileDetails?.isChild
      );
      if (guestIndex !== -1) {
        const updatedGuests = [...otherGuests];
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          panCardDetails: {
            ...updatedGuests[guestIndex].panCardDetails,
            panCardNumber: panCardNumber,
          },
        };
        dispatch(setOtherGuests(updatedGuests))
      }
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSaveGuestDetails}
      enableReinitialize
      innerRef={bookingPrimaryDetailsFormRef}
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        validateForm,
        handleSubmit,
      }) => (
        <Form>
          <div>
            {isPANRequired && isSamePanForAllAllowed && (
              <div className='flex flex-col gap-2 py-4 text-contrast-900 text-md justify-between border-t border-contrast-300'>
                <div className='text-contrast-900'>
                  <div className='font-semibold'>Documents Required</div>
                </div>
                <div className='w-1/3'>
                  <div className='form-group'>
                    <label
                      htmlFor='panCardNumber'
                      className='block form-control text-sm font-medium mb-1 text-contrast-900'
                    >
                      PAN Number
                      <Asterisk />
                    </label>
                    <Field
                      id='panCardNumber'
                      type='text'
                      name='panCardDetails.panCardNumber'
                      className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500'
                      placeholder='PAN Number'
                      onChange={(e) => handlePanCardChange(e, setFieldValue, values)}
                      onBlur={() => setFieldTouched('panCardDetails.panCardNumber', true)}
                    />
                  </div>
                  <div>
                    {renderFieldError(
                      "panCardDetails.panCardNumber",
                      errors,
                      touched
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='flex flex-col gap-2 py-4 text-contrast-900 text-md justify-between border-t border-contrast-300'>
            <div className='text-contrast-900'>
              <div className='font-semibold'>Contact Information</div>
              <div className='text-xs font-light'>
                (Booking details will be sent to this)
              </div>
            </div>
            <div className='w-full flex flex-col'>
              <div className='w-full grid gap-6 grid-cols-6'>
                <div className='form-group col-span-6 md:col-span-2 lg:col-span-2'>
                  <div className='form-group'>
                    <label
                      htmlFor='phoneNumber'
                      className='block text-sm font-medium mb-1 text-contrast-900'
                    >
                      Phone Number
                      <Asterisk />
                    </label>
                    <PhoneNumberInput
                      id='phoneNumber'
                      name='profileDetails.phoneNumber'
                      values={values}
                      phoneCodePath='profileDetails.phoneCode'
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      handleOnBlur={() => { setFieldTouched('profileDetails.phoneNumber', true) }}
                      placeholder={"Phone Number"}
                    />
                    <div>
                      {renderFieldError(
                        "profileDetails.phoneNumber",
                        errors,
                        touched
                      )}
                    </div>
                  </div>
                </div>
                <div className='form-group col-span-6 md:col-span-2 lg:col-span-2'>
                  <div className='form-group'>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium mb-1 text-contrast-900'
                    >
                      Email Address
                      <Asterisk />
                    </label>
                    <Field
                      id='email'
                      type='email'
                      name='profileDetails.email'
                      placeholder='Email'
                      className='form-control block w-full text-sm py-1.5 px-3 border-contrast-300 rounded-lg'
                      onBlur={() => { setFieldTouched('profileDetails.email', true) }}
                      onChange={(e) => {
                        const lowercasedValue = e.target.value.toLowerCase();
                        setFieldValue("profileDetails.email", lowercasedValue);
                      }}
                    />
                    <div>
                      {renderFieldError(
                        "profileDetails.email",
                        errors,
                        touched
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full pt-8 grid gap-6 grid-cols-6'>
              <div className='form-group col-span-6 md:col-span-2 lg:col-span-2'>
                  <div className='form-group'>
                    <label
                      htmlFor='profileDetails.nationality'
                      className='block text-sm font-medium mb-1 text-contrast-900'
                    >
                      Country
                      <Asterisk />
                    </label>
                    <DropdownSelectField
                      name='profileDetails.nationality'
                      value={
                        values.profileDetails.nationality ||
                        defaultNationality.nationality
                      }
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      selectableValues={countryList}
                      valueToShow={
                        values.profileDetails.nationality.name
                          ? "name"
                          : "isoCode"
                      }
                      validateForm={validateForm}
                      placeholder={t("profilePage.placeholders.country")}
                      onChange={() => {
                        !get(errors, "profileDetails.state", false) &&
                          setFieldTouched("profileDetails.state", false);
                        setFieldValue("profileDetails.state", "");
                      }}
                      onBlur={() => { setFieldTouched('profileDetails.nationality.isoCode', true) }}
                    />
                    <div>
                      {renderFieldError(
                        "profileDetails.nationality.isoCode",
                        errors,
                        touched
                      )}
                    </div>
                  </div>
                </div>
                <div className='form-group col-span-6 md:col-span-2 lg:col-span-2'>
                  <div className='form-group'>
                    <label className='block text-sm font-medium mb-1 text-contrast-900'>
                      State
                      <Asterisk />
                    </label>
                    {values.profileDetails.nationality.name === INDIA ? (
                      <StateDropdown
                        onChange={(value) => {
                          setFieldValue("profileDetails.state", value);
                        }}
                        fieldName='profileDetails.state'
                        value={values.profileDetails.state}
                        setFieldTouched={setFieldTouched}
                        onBlur={() => { setFieldTouched('profileDetails.state', true) }}
                        placeHolder='profilePage.placeholders.state'
                        t={t}
                      />
                    ) : (
                      <>
                        <Field
                          id='state'
                          name='profileDetails.state'
                          type='text'
                          className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                          placeholder={t("profilePage.placeholders.state")}
                        />
                        <div>
                          {renderFieldError(
                            "profileDetails.state",
                            errors,
                            touched
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BookingPrimaryDetails;
