import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import Modal, { setSelectedModal } from "../../Modal";
import DateSearch from "../../../atoms/DateSearch";
import Dropdown from "../../../atoms/Dropdown";
import MultiSelectField from "../../../molecules/MultiSelectField/MultiSelectField";
import { MODAL_SIZE } from "../../../../constants";

const { MD } = MODAL_SIZE;

const statusList = [
  { label: "Waiting for Approval", class: "bg-contrast-100 text-contrast-800" },
  { label: "Approved", class: "bg-[#ECFDF3] text-[#027A48]" },
  { label: "Rejected", class: "bg-[#FEF3F2] text-[#B42318]" },
  { label: "Partially Approved", class: "bg-[#FFFAEB] text-[#B54708]" },
];
const tripList = [
  { name: "Flight", class: "bg-blue-100 text-blue-800" },
  { name: "Hotel", class: "bg-pink-100 text-pink-400" },
];

const ApprovalFilterModal = () => {
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    costCode: "",
    departureDate: "",
    returnDate: "",
    tripStatus: "",
    tripType: [],
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    costCode: Yup.string().required("Cost Code is required"),
    departureDate: Yup.string().required("Departure Date is required"),
    returnDate: Yup.string().required("Return Date is required"),
    tripStatus: Yup.string().required("Trip Status is required"),
    tripType: Yup.array().required("Select at least one type"),
  });

  const handleOnSubmit = (values, actions) => {};

  const handleClose = () => dispatch(setSelectedModal(null));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ values, setFieldValue, handleChange, resetForm }) => (
        <Form>
          <Modal
            title='Filter Approvals'
            size={MD}
            modalClassname='overflow-visible'
            shouldShowModalFromProps
            handleClose={handleClose}
          >
            <>
              <div className='p-4 flex-col w-full justify-start items-start gap-4'>
                <div className='text-contrast-500 text-sm leading-tight'>
                  <div className='w-full flex flex-col gap-4'>
                    <div className='form-group flex flex-col gap-2 w-full'>
                      <label
                        htmlFor='name'
                        className='text-sm font-medium text-contrast-900 leading-5'
                      >
                        Traveler(s) name
                      </label>
                      <Field
                        id='name'
                        name='name'
                        type='text'
                        className='form-control w-full text-base px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                        placeholder='Enter Name'
                      />
                      <ErrorMessage
                        name='name'
                        component='div'
                        className='text-red-500 text-xs'
                      />
                    </div>

                    <div className='form-group flex flex-col gap-2 w-full'>
                      <label
                        htmlFor='costCode'
                        className='text-sm font-medium text-contrast-900 leading-5'
                      >
                        Cost Code
                      </label>
                      <Field
                        id='costCode'
                        name='costCode'
                        type='text'
                        className='text-base px-3 w-full border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                        placeholder='Type to Search Code'
                      />
                      <ErrorMessage
                        name='costCode'
                        component='div'
                        className='text-red-500 text-xs'
                      />
                    </div>

                    <div className='form-group flex flex-col gap-2 w-full'>
                      <label className='text-sm font-medium text-contrast-900 leading-5'>
                        Trip Dates
                      </label>
                      <div className='w-full flex'>
                        <div className='w-full flex flex-col gap-2'>
                          <DateSearch
                            name='departureDate'
                            values={values}
                            onChange={handleChange}
                            setFieldValue={setFieldValue}
                            minDate={new Date()}
                            showCalendarIcon={true}
                            placeholder='Departure'
                            isApprovalDept={true}
                          />
                          <ErrorMessage
                            name='departureDate'
                            component='div'
                            className='text-red-500 text-xs'
                          />
                        </div>
                        <div className='w-full flex flex-col gap-2'>
                          <DateSearch
                            name='returnDate'
                            values={values}
                            onChange={handleChange}
                            setFieldValue={setFieldValue}
                            minDate={get(values, "departureDate")}
                            showCalendarIcon={false}
                            placeholder='Return'
                            isApprovalReturn={true}
                          />
                          <ErrorMessage
                            name='returnDate'
                            component='div'
                            className='text-red-500 text-xs'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col md:flex-row gap-8'>
                      <div className='form-group flex flex-col gap-1 w-full'>
                        <label className='text-sm font-medium text-contrast-900 leading-5'>
                          Trip Status
                        </label>
                        <Dropdown
                          options={statusList}
                          onChange={(value) =>
                            setFieldValue("tripStatus", value)
                          }
                          default=''
                          className='mt-4'
                        />
                        <ErrorMessage
                          name='tripStatus'
                          component='div'
                          className='text-red-500 text-xs'
                        />
                      </div>

                      <div className='form-group flex flex-col gap-1 w-full'>
                        <MultiSelectField
                          label={"Trip Type(s)"}
                          name={"tripType"}
                          selectableValues={tripList}
                          selectAll={false}
                          placeholder={"Select Type"}
                        />
                        <ErrorMessage
                          name='tripType'
                          component='div'
                          className='text-red-500 text-xs'
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        className='text-primary-600 font-medium'
                        onClick={resetForm}
                      >
                        Clear All Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full p-3 border-t border-contrast-200 justify-start items-center gap-2 inline-flex'>
                <div className='grow shrink basis-0 justify-end items-start gap-4 flex'>
                  <button
                    type='button'
                    className='px-4 py-3 bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300'
                    onClick={handleClose}
                  >
                    <div className="text-contrast-700 text-sm font-medium font-['Inter'] leading-tight">
                      Close
                    </div>
                  </button>
                  <button
                    type='submit'
                    className='px-4 py-3 rounded-md shadow justify-center items-center flex bg-primary-600 hover:bg-primary-700 active:bg-primary-600'
                  >
                    <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                      Apply Filter
                    </div>
                  </button>
                </div>
              </div>
            </>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ApprovalFilterModal;
