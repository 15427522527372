export const getFromLocalStorage = (fieldName) =>
  JSON.parse(localStorage.getItem(fieldName));

export const setToLocalStorage = (fieldName, data) =>
  localStorage.setItem(fieldName, JSON.stringify(data));

export const getFromSessionStorage = (fieldName) =>
  JSON.parse(sessionStorage.getItem(fieldName));

export const setToSessionStorage = (fieldName, data) =>
  sessionStorage.setItem(fieldName, JSON.stringify(data));
