import Policies from "./Policies";
import * as slice from "./policies.reducer";
import * as selectors from "./policies.selector";
import * as policiesActions from "./policies.actions";

export const { updatePolicies, getPolicies } = policiesActions;

export const {
  name,
  reducer,
  actions: { setSelectedServicePolicyId },
} = slice;

export const { selectAllServicesPolicy, selectSelectedServicePolicyId } =
  selectors;

export default Policies;
