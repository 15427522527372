import { DEFAULT_VALUES } from "../constants";
const { ZERO } = DEFAULT_VALUES;
export const getHotelImageUrl = (images) => {
  if (!images || typeof images !== 'object') {
    return null;
  }
  const categories = ['Exterial', 'LobbyView', 'Restaurant', 'FeatureImage', 'InteriorEntrance', 'HotelOutside'];
  const hotelImage = categories.find(category =>
    images[category]?.fullImage?.length > ZERO
  );

  if (hotelImage) {
    return images[hotelImage].fullImage[ZERO];
  }
  const firstAvailableFullImage = Object.values(images)
    .map(image => image?.fullImage?.[ZERO])
    .find(image => image !== undefined);

    return firstAvailableFullImage || null;
};
