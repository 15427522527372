import DatePicker from "react-multi-date-picker";
import get from "lodash/get";
import classNames from "classnames";
import { Calendar, RenderSVG } from "../../assets/icons";
import { DATE_FORMAT, DEFAULT_VALUES } from "../../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;

const DateSearch = ({
  name,
  values,
  setFieldValue,
  setFieldTouched = () => {},
  minDate,
  maxDate,
  height = "38px",
  handleChange = () => {},
  position = "bottom-left",
  setDateErrorMessage = () => {},
  showCalendarIcon = true,
  placeholder = "",
  isApprovalDept = false,
  isApprovalReturn = false,
  onDateChange = () => {},
  fixMainPosition = false,
}) => {

const selectedDate = get(values, `${name}`)

  const handleOnChange = (value) => {
    setFieldTouched(name, true, false);
    handleChange();
    const selectedDate = value.format(DATE_FORMAT);
    setFieldValue(name, selectedDate);
    setDateErrorMessage(EMPTY_STRING);
    onDateChange(selectedDate, values);
  };

  return (
    <div className='relative w-full flex items-center bg-white'>
      {showCalendarIcon && (
        <RenderSVG
          Svg={Calendar}
          className={classNames("absolute ml-1", {
            "text-contrast-400": isApprovalDept,
          })}
          alt='Calendar'
        />
      )}
      <DatePicker
        shadow={false}
        format={DATE_FORMAT}
        highlightToday={false}
        value={selectedDate}
        editable={false}
        onChange={handleOnChange}
        minDate={minDate}
        maxDate={maxDate}
        style={{
          width: "100%",
          boxSizing: "border-box",
          height,
          zIndex: "1",
        }}
        portal={true}
        zIndex={1000}
        inputClass={classNames("border border-contrast-300", {
          "rounded-md !pl-8": !isApprovalDept && !isApprovalReturn,
          "rounded-l-md !pl-8": isApprovalDept,
          "rounded-r-md": isApprovalReturn,
        })}
        containerClassName='calendar'
        calendarPosition={position}
        placeholder={placeholder}
        fixMainPosition={fixMainPosition}
      />
    </div>
  );
};
export default DateSearch;
