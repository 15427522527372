import Transactions from "./Transactions";
import TransactionsTable from "./TransactionsTable";
import * as actions from "./transactions.actions";
import * as slice from "./transactions.reducer";
import * as selectors from "./transactions.selectors";

export const {
  name,
  reducer,
  actions: {},
} = slice;

export const { fetchTransactionDetails } = actions;
export const { selectedTransactionDetails } = selectors;
export { TransactionsTable };

export default Transactions;
