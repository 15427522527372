import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import classNames from "classnames";
import { priceFormatter } from "../../../../helper";
import {
  CURRENCY_SYMBOLS,
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
  FLIGHTS_RESULT_TYPE,
} from "../../../../constants";
import {
  selectCurrentFlightType,
  setSelectedFlightOptions,
  selectSelectedFlightOptions,
} from "../../../../screens/FlightResults";
import { CheckGreen, Minus, RenderSVG } from "../../../../assets/icons";
import { setSelectedModal } from "../../Modal";

const { ZERO, EMPTY_ARRAY, TWO } = DEFAULT_VALUES;
const { PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;

const CANCELLATION = "Cancellation";
const priceRegex = /([A-Z]{3})\s+(\d+)/;

const getChargedFees = (rule) => {
  const match = rule?.Details.match(priceRegex);
  return match && match.length ? match[TWO] : null;
};

const FlightOptionCard = ({
  flight,
  currency = DEFAULT_CURRENCY_CODE,
  flightOptionsCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentFlightType = useSelector(selectCurrentFlightType);
  const selectedFlightOptions = useSelector(selectSelectedFlightOptions);

  const { baggage } = flight || {};
  const { weight, noOfBags, cabinBaggage } = baggage;
  const totalPrice =
    get(flight, "price.totalPrice", ZERO) +
    get(flight, "price.tavaMarkup", ZERO);
  const miniFareRules = flight.miniFareRules || [];

  const handleFlightSelect = (flight) => {
    if (currentFlightType === PACKAGE_RESULT)
      dispatch(
        setSelectedFlightOptions({
          [currentFlightType]: flight,
        })
      );
    else
      dispatch(
        setSelectedFlightOptions({
          [currentFlightType]: flight,
        })
      );
    dispatch(setSelectedModal(null));
  };

  return (
    <div
      role='button'
      className={classNames(
        "rounded-lg shadow-md flex flex-col border col-span-12 border-contrast-200 bg-white",
        {
          "lg:col-span-12": flightOptionsCount === 1,
          "lg:col-span-6": flightOptionsCount === 2,
          "lg:col-span-4": flightOptionsCount > 2,
        }
      )}
    >
      <div className='px-4 py-3 border-b border-contrast-200 flex gap-2'>
        <div>
          <h4 className='text-lg font-bold text-black mb-1'>
            {CURRENCY_SYMBOLS[currency]}&nbsp;
            {priceFormatter(totalPrice)}{" "}
          </h4>
        </div>
      </div>
      <div className='px-4 py-5 flex flex-col gap-8 flex-1'>
        <div>
          {(weight || noOfBags || cabinBaggage) && (
            <div className='font-bold text-sm mb-4 text-contrast-900'>
              {t("flightResults.baggage")}
            </div>
          )}
          <ul className='flex flex-col gap-3'>
            {(weight || noOfBags) && (
              <li className='flex items-center gap-2'>
                <div className='bg-teal-100 flex-shrink-0 grid place-content-center w-4 h-4 rounded-full'>
                  <RenderSVG Svg={CheckGreen} width='8' />
                </div>
                <span className='text-xs font-medium text-contrast-700'>
                  {t("flightResults.baggageAllowed")}: {weight || noOfBags}
                </span>
              </li>
            )}

            {cabinBaggage && (
              <li className='flex items-center gap-2'>
                <div className='bg-teal-100 flex-shrink-0 grid place-content-center w-4 h-4 rounded-full'>
                  <RenderSVG Svg={CheckGreen} width='8' />
                </div>
                <span className='text-xs font-medium text-contrast-700'>
                  {cabinBaggage} {t("flightResults.checkInBaggages")}
                </span>
              </li>
            )}
          </ul>
        </div>

        {miniFareRules.length > 0 && (
          <div>
            <div className='font-bold text-sm mb-4 text-contrast-900'>
              {t("flightResults.flexibility")}
            </div>
            <ul className='flex flex-col gap-3'>
              {miniFareRules.map(
                ({ fareRuleInfo, from, price, to, type, unit }) => {
                  const chargedFee = Number(price);
                  return (
                    <li className='flex gap-2'>
                      <div className='bg-amber-100 flex-shrink-0 grid place-content-center w-4 h-4 rounded-full'>
                        {Number(chargedFee) > 0 && !fareRuleInfo ? (
                          <RenderSVG Svg={Minus} width='8' />
                        ) : (
                          <RenderSVG Svg={CheckGreen} width='8' />
                        )}
                      </div>
                      <span className='text-xs font-medium text-contrast-700'>
                        {Number(chargedFee) > 0 && !fareRuleInfo ? (
                          <>
                            {type === CANCELLATION
                              ? t("flightResults.cancellationFeeStartsAt")
                              : t("flightResults.dateChangeFeeStartsAt")}{" "}
                            {CURRENCY_SYMBOLS[currency]}&nbsp;
                            {priceFormatter(chargedFee)}{" "}
                          </>
                        ) : (
                          <>
                            {type === CANCELLATION
                              ? t("flightResults.freeCancellation")
                              : t("flightResults.freeDateChange")}{" "}
                          </>
                        )}
                        {fareRuleInfo && <>{fareRuleInfo}</>}
                        {!!((from || to) && unit && !fareRuleInfo) && (
                          <>
                            ({to ? `${from}-${to}` : `${from}`} {unit}{" "}
                            {t("flightResults.beforeDeparture")})
                          </>
                        )}
                      </span>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        )}
      </div>
      <div className='border-t border-contrast-200 px-4 py-5 flex gap-3'>
        <button
          className='w-full py-2 px-4 flex-1 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium'
          onClick={() => handleFlightSelect(flight)}
        >
          {t("flightResults.selectFlight")}
        </button>
      </div>
    </div>
  );
};

export default FlightOptionCard;
