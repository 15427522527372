import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { ArrowLeft, Cross, RenderSVG } from "../../../../assets/icons";
import {
  getAirlineIconUrlByCarrierCode,
  getCityNameByIata,
  getDayFromDate,
  getFormattedLongDate,
} from "../../../../helper";
import Modal, { setSelectedModal } from "../../Modal";
import {
  selectCurrentFlightType,
  selectFlightInfo,
} from "../../../../screens/FlightResults";
import { selectCountryInfo } from "../../../../screens/Profile";
import { selectTenantDetails } from "../../../../screens/Auth";
import FlightOptionCard from "./FlightOptionCard";
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
  FLIGHTS_RESULT_TYPE,
  MODAL_SIZE,
} from "../../../../constants";

const { ZERO } = DEFAULT_VALUES;
const { PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const { XL } = MODAL_SIZE;

const RenderFlightOptions = ({ flightOptions = [], flightType, currency }) => {
  const { t } = useTranslation();
  const tenantDetails = useSelector(selectTenantDetails);
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const formattedFlightOptions = Array.isArray(flightOptions)
    ? flightOptions
    : [flightOptions];

  const tripItinerariesInfo = formattedFlightOptions[ZERO]
    ? formattedFlightOptions[ZERO].itineraries.map((itinerary) => {
        const {
          carrierCode,
          carrierName,
          departure: {
            time: departureTime,
            date: departureDate,
            iataCode: departureIataCode,
          },
        } = itinerary.segments[ZERO] || {};
        const {
          arrival: { time: arrivalTime, iataCode: arrivalIataCode },
        } = itinerary.segments.at(-1) || {};

        return {
          carrierCode,
          carrierName,
          departureTime,
          departureDate,
          departureIataCode,
          arrivalTime,
          arrivalIataCode,
        };
      })
    : [];

  return (
    <div key={flightType}>
      <div
        className={classNames("flex flex-col gap-2", {
          "my-6": flightType !== PACKAGE_RESULT,
          "mb-6": flightType === PACKAGE_RESULT,
        })}
      >
        <div className='flex flex-wrap gap-2 text-contrast-500'>
          <span className='text-contrast-900 font-bold text-sm'>Trip - </span>
          <div>
            {!isEmpty(tripItinerariesInfo) &&
              tripItinerariesInfo.map(
                ({
                  departureIataCode,
                  arrivalIataCode,
                  carrierCode,
                  carrierName,
                  departureDate,
                  departureTime,
                  arrivalTime,
                }) => (
                  <div className='flex items-center gap-2 mb-1'>
                    <div className='inline-flex gap-2 items-center'>
                      <img
                        src={getAirlineIconUrlByCarrierCode(carrierCode)}
                        width='20'
                        alt='carrier-icons'
                      />
                      <span className='text-xs text-contrast-500'>
                        {carrierName}
                      </span>
                    </div>
                    ▪
                    <div className='inline-flex gap-2'>
                      <span className='text-xs text-contrast-500'>
                        {getCityNameByIata(departureIataCode)}
                      </span>
                      <RenderSVG
                        Svg={ArrowLeft}
                        width='14'
                        height='14'
                        stroke={tenantConfig.contrast}
                      />
                      <span className='text-xs text-contrast-500'>
                        {getCityNameByIata(arrivalIataCode)}
                      </span>
                    </div>
                    ▪
                    <span className='text-xs text-contrast-500'>
                      {getDayFromDate(departureDate)},{" "}
                      {getFormattedLongDate(departureDate, {
                        weekday: "short",
                        day: "2-digit",
                        month: "short",
                        year: "2-digit",
                      })}
                    </span>
                    ▪
                    <span className='text-xs text-contrast-500'>
                      {t("flightResults.roundTripCard.departure")} at{" "}
                      {departureTime} - {t("sorting.arrival.header")} at{" "}
                      {arrivalTime}
                    </span>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      <div className='grid grid-cols-12 gap-4 mb-4'>
        {formattedFlightOptions?.map((flight) => (
          <FlightOptionCard
            flight={flight}
            currency={currency}
            flightType={flightType}
            flightOptionsCount={formattedFlightOptions.length}
          />
        ))}
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <>
      <span className='uppercase text-base font-bold text-primary-700'>
        More Fare Options
      </span>{" "}
      <span className='text-sm'>available for your trip</span>
    </>
  );
};

export const FlightOptionsModal = () => {
  const dispatch = useDispatch();
  const selectedFlightsInfo = useSelector(selectFlightInfo);
  const countryInfo = useSelector(selectCountryInfo);
  const currentFlightType = useSelector(selectCurrentFlightType);

  const currentFlightOptions = get(
    selectedFlightsInfo,
    `${
      currentFlightType === PACKAGE_RESULT
        ? PACKAGE_RESULT
        : `${currentFlightType}.flights`
    }`,
    []
  );
  const currency = get(countryInfo, "currency.code", DEFAULT_CURRENCY_CODE);

  const handleClose = () => dispatch(setSelectedModal(null));

  return (
    <Modal
      title={<Header />}
      handleClose={handleClose}
      size={XL}
      shouldCloseOnClickOutside={true}
      titleClassname="pl-4 pr-6"
    >
      <div className='p-4 bg-contrast-50 border-t'>
        <RenderFlightOptions
          flightOptions={currentFlightOptions}
          flightType={PACKAGE_RESULT}
          currency={currency}
        />
      </div>
    </Modal>
  );
};
