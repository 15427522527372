import { getAirlineIconUrlByCarrierCode } from "../../helper";
import { useTranslation } from "react-i18next";
import FlightDetailsTag from "../../components/atoms/FlightDetailsTag";
import { Cloud, RenderSVG, Baggage, Seat } from "../../assets/icons";
import { DEFAULT_VALUES } from "../../constants";

const { EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;

const PackagedFlightDetails = ({
  arrival,
  startLocation,
  hasHighCO2Emission,
  noOfAvailableSeats,
  isRefundable,
  miniFareRules,
  baggage = EMPTY_OBJECT,
}) => {
  const {
    carrierName,
    carrierCode,
    aircraftCode,
    flightNumber,
    departure = EMPTY_OBJECT,
  } = startLocation;

  const { t } = useTranslation();
  const baggageWeight = baggage.weight;

  return (
    <div className='flex flex-col'>
      <div className='flex items-center gap-2'>
        <div className='logo'>
          <img
            src={getAirlineIconUrlByCarrierCode(carrierCode)}
            className='rounded-lg border border-contrast-300 w-8 h-8'
            alt={`${carrierCode}_logo`}
          />
        </div>
        <div className='flex-1'>
          <h5 className='text-md font-semibold text-contrast-900'>
            {departure.time}
            &nbsp; -&nbsp;
            {arrival.time}
          </h5>
          <p className='text-xs text-contrast-600'>
            {carrierName || carrierCode}, {flightNumber}
            {aircraftCode ? `| ${aircraftCode}` : EMPTY_STRING}
          </p>
        </div>
      </div>
      <div className='flex flex-wrap gap-2'>
        <div className='flex flex-row gap-2'>
          {hasHighCO2Emission && (
            <div className='rounded-2xl py-0.5 px-2 h-fit bg-green-100 text-green-900 text-[10px] font-medium flex items-center gap-[6px]'>
              <RenderSVG Svg={Cloud} width='14' height='14' alt='cloud-icon' />
              <span>{t("flightResults.lowCo2")}</span>
            </div>
          )}
        </div>
      </div>
      <div className='mt-2 flex flex-row flex-wrap gap-2'>
        {!!baggageWeight && (
          <div className='text-[10px] h-fit font-semibold whitespace-nowrap bg-primary-100 text-primary-900 rounded-2xl py-0.5 px-2 flex items-center'>
            <RenderSVG Svg={Baggage} width='14' height='14' alt='baggage' />
            <span className='ml-1'>
              {t("flightResults.baggageAllowed")}
              {": "}
              {baggageWeight}
            </span>
          </div>
        )}
        <FlightDetailsTag
          refundableStatus={isRefundable}
          miniFareRules={miniFareRules}
          noOfAvailableSeats={noOfAvailableSeats}
        />
      </div>
    </div>
  );
};

export default PackagedFlightDetails;
