const CarouselImage = ({
  imageUrl,
  hotelName,
  imagesLength,
  handleOnClick,
}) => {
  return (
    <>
     { hotelName && <img
        src={imageUrl}
        alt={hotelName}
        className='h-full w-full object-cover'
      />}
      <div className='absolute bottom-2 left-2 text-white font-semibold text-base'>
        {imagesLength}
        {imagesLength > 1 ? " images" : " image"}
      </div>
      {handleOnClick && (
        <button
          className='top-0 left-0 w-full h-full bg-transparent absolute hidden xs:block'
          onClick={handleOnClick}
        ></button>
      )}
    </>
  );
};
export default CarouselImage;
