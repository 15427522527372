import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { get, uniqueId, isEmpty } from "lodash";
import { KEY_CODES, DEFAULT_VALUES, INDEX } from "../../constants";
import useClickOutside from "../../helper/useClickOutside";

const { LAST } = INDEX;
const { KEY_DOWN, KEY_UP, ENTER } = KEY_CODES;
const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;

const SelectDocumentTypeField = ({
  name,
  value,
  setFieldTouched,
  selectableValues,
  setFieldValue,
  valueToShow,
  validateForm,
}) => {
  const dropDownRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(LAST);
  const [inputValue, setInputValue] = useState(EMPTY_STRING);
  const [filteredValues, setFilteredValues] = useState([]);

  useClickOutside(dropDownRef, () => {
    setShowDropdown(false);
    setFieldTouched(`${name}.name`,true);
  }
  );

  useEffect(() => {
    setInputValue(get(value, valueToShow, EMPTY_STRING));
  }, [value, valueToShow]);

  useEffect(() => {
    validateForm();
  }, [value]);

  const handleKeyDown = (event) => {
    const KEY = event.key;
    if (KEY === KEY_UP)
      setSelectedIndex((prevIndex) => Math.max(prevIndex - ONE, ZERO));
    else if (KEY === ENTER && selectedIndex !== LAST) {
      const selectedItem = filteredValues[selectedIndex];
      setFieldValue(name, selectedItem);
      setInputValue(selectedItem[valueToShow]);
      setFieldTouched(`${name}.name`, true);
      document.body.click();
      event.preventDefault();
      setShowDropdown(false);
    } else if (KEY === KEY_DOWN)
      setSelectedIndex((prevIndex) =>
        Math.min(prevIndex + ONE, filteredValues.length - ONE)
      );
  };

  const setFieldValues = (each) => {
    setFieldValue(name, each);
    setFieldTouched(name, true);
    setInputValue(each[valueToShow]);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    const filtered = selectableValues.filter((item) =>
      item.name.toLowerCase().includes(inputValue)
    );
    setFilteredValues(filtered);
    setSelectedIndex(LAST);
    setInputValue(event.target.value);
    setFieldValue(name,'');
  };

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  return (
    <div className='col-span-2 sm:col-span-1 text-black lg:col-span-4 relative'>
      <div className='border rounded-md'>
        <input
          autoComplete='off'
          type='text'
          id={name}
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder='Select Document Type'
          onFocus={() => {
            !filteredValues.length && setFilteredValues(selectableValues);
            setFieldTouched(name, false);
          }}
          onClick={toggleDropdown}
          className='flex text-contrast-900 font-normal outline-none placeholder:text-blue-contrast-500 placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-blue-600 w-full h-full border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
        />
      </div>
      {showDropdown && (
        <div className='shadow-lg' ref={dropDownRef}>
          <div className='flex w-full shadow p-0 bg-white rounded-md absolute overflow-y-scroll !z-10'>
            {!isEmpty(filteredValues) ? (
              <div className='w-full'>
                {filteredValues.map((each, index) => (
                  <button
                    className={classNames(
                      "rounded-md px-5 text-left cursor-pointer w-full py-2",
                      {
                        "bg-contrast-300": index === selectedIndex,
                      }
                    )}
                    key={each.name}
                    onClick={() => {
                      setFieldValues(each);
                      setShowDropdown(false);
                    }}
                  >
                    <div>{each.name}</div>
                  </button>
                ))}
              </div>
            ) : (
              <p className='text-left py-2 px-5'>No results found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectDocumentTypeField;
