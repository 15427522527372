import classNames from "classnames";
import {
  getCancellationCharges,
  getFormattedLongDate,
  getFormattedTime,
  getCurrentDateTime,
  getFormattedDate,
} from "../../helper";
import { CANCELLATION_CHARGE_TYPES, DEFAULT_VALUES } from "../../constants";

const { AMOUNT, PERCENTAGE, NIGHTS } = CANCELLATION_CHARGE_TYPES;
const { ZERO, ONE, TWO } = DEFAULT_VALUES;

const CancellationPolicyInfo = ({ cancellationPolicies = [],  noOfNights=ZERO, hotelTotalFare=ZERO }) => {
  const nonRefundable = [];
  const partialRefundable = [];

  cancellationPolicies.forEach((item) => {
    if (item.charge === 100 && item.chargeType === PERCENTAGE) {
      nonRefundable.push(item);
    } else {
      partialRefundable.push(item);
    }
  });

  const nonRefundableToDate = nonRefundable[nonRefundable.length - ONE]?.toDate;
  const currentDate = getCurrentDateTime();
  let nonRefundableFromDate = nonRefundable[ZERO]?.fromDate;
  let isRefundVisible=false;

  const isCurrentAndnonRefundableFromDateSame = () => currentDate >= getFormattedDate(nonRefundableFromDate);
  const isCurrentDateCrossedPartialRefundDate = (() => {
    let refundDate = ""
    for (let i = 0; i < partialRefundable; i++)
      if (partialRefundable[i].charge != 0) refundDate = partialRefundable[i].fromDate;

    if (!refundDate) refundDate = nonRefundableFromDate;

    if (refundDate) return currentDate >= getFormattedDate(refundDate) ? true : false;
    else return false;

  })();

  const isChargeThreeMakingNonRefundable = (charge, fromDate) => {
    const res = (charge - noOfNights) == ZERO
    if (res) nonRefundableFromDate = fromDate;
    return res;
  }

  const isCompleteRefundDateValid = (() => {
    const res = !isCurrentDateCrossedPartialRefundDate && !isCurrentAndnonRefundableFromDateSame();
    if (res) isRefundVisible = true;
    return res;
  })();

  const isChargeOneValid = (charge,fromDate) => {
       const isChargeValid= (hotelTotalFare - charge) > ZERO
       if(!isChargeValid){
        nonRefundableFromDate=fromDate;
      }
      return isChargeValid;
  }

  const isPartialRefundSectionValid = (charge, chargeType, fromDate) => {
    const res = (charge && (chargeType == NIGHTS ? !isChargeThreeMakingNonRefundable(charge, fromDate) : true) && (chargeType == AMOUNT ? isChargeOneValid(charge, fromDate) : true));
    if (res) isRefundVisible = true;
    return res;
  }

  return (
    <div className='max-w-xs md:max-w-md flex items-center mb-14'>
      {isCompleteRefundDateValid && (
        <div className='w-full bg-green-700 text-[10px] text-white text-center rounded-s-2xl flex items-center py-0.5 px-1'>
          <div className='w-3 h-3 bg-white rounded-full border-contrast-200 relative'>
            <div className='absolute top-full left-1/2 -translate-x-1/2 whitespace-nowrap pt-2'>
              <h6 className='font-semibold text-contrast-800 text-[10px]'>Now</h6>
              <p className='text-[10px] text-contrast-500'>Pay ₹0</p>
            </div>
          </div>
          <div className='flex-1 whitespace-nowrap'>100% refund</div>
        </div>
      )}

      {partialRefundable.map(
          ({ charge, chargeType, fromDate, toDate, currency }, index) => {
            const refundability = `${getCancellationCharges(
              charge,
              chargeType,
              currency
            )} Charged`

          if (isPartialRefundSectionValid(charge, chargeType, fromDate)) {
            return (
              <div className="w-full" key={fromDate}>
                <div className='relative self-stretch'>
                  <div className='absolute top-full -translate-x-1/2 whitespace-nowrap pt-2'>
                    <h6 className='font-semibold text-contrast-800 text-[10px] mt-4'>
                      {getFormattedLongDate(fromDate, {
                        day: "numeric",
                        month: "short",
                      })}
                    </h6>
                    <h6 className='font-semibold text-contrast-800 text-[10px]'>
                      {getFormattedTime(fromDate)}
                    </h6>
                  </div>
                </div>
                <div
                  className={
                    classNames("flex-1 bg-amber-200 text-[10px] text-amber-800 text-center flex items-center py-0.5 px-2",
                      {
                        "border-l border-black rounded-none": isCompleteRefundDateValid,
                        "border-0 rounded-s-2xl ": !isCompleteRefundDateValid
                      }
                    )}
                >
                  {!isCompleteRefundDateValid && (
                    <div className="w-3 h-3 bg-white rounded-full border border-contrast-200 relative"></div>
                  )}
                  <div className='flex-1 whitespace-nowrap'>
                    {refundability}
                  </div>
                  {index === cancellationPolicies.length - 1 && (
                    <div className='relative'>
                      <div className='w-3 h-3 ml-1 bg-white rounded-full border-contrast-200 relative'></div>
                      <div className='absolute whitespace-nowrap'>
                        <h6 className='font-semibold text-contrast-800 text-[10px] mt-2'>
                          {getFormattedLongDate(toDate, {
                            day: "numeric",
                            month: "short",
                          })}
                        </h6>
                        <h6 className='font-semibold text-contrast-800 text-[10px]'>
                          {getFormattedTime(toDate)}
                        </h6>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }
          }
      )}

      <div className="w-full" key={nonRefundableFromDate}>
          <div className='relative self-stretch'>
            <div className={classNames("absolute top-full -translate-x-1/2 whitespace-nowrap pt-2",{
              "ml-3": (isCurrentAndnonRefundableFromDateSame() || (!isRefundVisible && !isCompleteRefundDateValid)),
            })}>
              {/* Handles the case if there is "no-refund" only, then from date for no refund should display from current Date  */}
            { isRefundVisible ?(<h6 className='font-semibold text-contrast-800 text-[10px] mt-4'>
                {getFormattedLongDate(nonRefundableFromDate, {
                  day: "numeric",
                  month: "short",
                })}
              </h6>):<h6 className='font-semibold text-contrast-800 text-[10px] mt-4'> {getFormattedLongDate(currentDate,{day:"numeric",month:"short"})}</h6>} 
             { <h6 className='font-semibold text-contrast-800 text-[10px]'>
                {isRefundVisible ?getFormattedTime(nonRefundableFromDate):"00:00"}
              </h6 > }
            </div>
          </div>
          <div
            className={classNames("flex-1 bg-red-200 rounded-e-2xl text-red-800 text-[10px] text-center flex items-center py-0.5 px-2", {
              "border-l border-black rounded-none" : !isCurrentAndnonRefundableFromDateSame(),
              "border-0 rounded-s-2xl": (!isRefundVisible && !isCompleteRefundDateValid),
            })}
          >
            {( !isRefundVisible && !isCompleteRefundDateValid) &&
              <div className='w-3 h-3 bg-white rounded-full border-contrast-200 relative'></div>
            }
            <div className='flex-1 whitespace-nowrap'>{"No Refund"}</div>
            <div className='relative'>
              <div className='w-3 h-3 ml-1 bg-white rounded-full border-contrast-200 relative'></div>
              <div className='absolute whitespace-nowrap -ml-2'>
                <h6 className='font-semibold text-contrast-800 text-[10px] mt-2'>
                  {getFormattedLongDate(nonRefundableToDate, {
                    day: "numeric",
                    month: "short",
                  })}
                </h6>
                <h6 className='font-semibold text-contrast-800 text-[10px]'>
                  {getFormattedTime(nonRefundableToDate)}
                </h6>
              </div>
            </div>
          </div>
      </div> 
    </div>
  );
};

export default CancellationPolicyInfo;
