import Dashboard from "./Dashboard";
import * as slice from "./dashboard.reducer";
import * as selectors from "./dashboard.selectors";
import * as dashboardActions from "./dashboard.actions";

export const {
  getAnalyticsSpentData,
  getAnalyticsTripData,
  getAnalyticsTransactionInfo,
  getAnalyticsGraphData,
  getAnalyticsGreetingsData,
  getCostCodeInfo,
  getAnalyticsAggregateData,
  getFrequentAirlines,
  getFrequentHotels,
  getUserUpcomingBookings,
  getUserBookingsInfo
} = dashboardActions;

export const {
  name,
  reducer,
  actions: {
    setAnalyticsQueryParams,
    setSelectedTimeRange,
    setSelectedUser,
    setFrequentlyUsedAirlines,
    setFrequentlyUsedHotels,
    setUserUpcomingBookings,
  },
} = slice;

export const {
  selectAnalyticsSpent,
  selectAnalyticsTripData,
  selectAnalyticsTransactions,
  selectAnalyticsGraphData,
  selectAnalyticsGreetings,
  selectAnalyticsAggregate,
  selectAnalyticsQueryParams,
  selectselectedTimeRange,
  selectSelectedUser,
  selectCostCodeInfo,
  selectFrequentlyUsedAirlines,
  selectFrequentlyUsedHotels,
  selectUserUpcomingBookings,
} = selectors;

export default Dashboard;
