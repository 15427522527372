import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_VALUES } from "../../constants";
import { getUsersList, getInvitedUsersList } from "./users.actions";

const { EMPTY_OBJECT } = DEFAULT_VALUES;

const initialState = {
  usersData: EMPTY_OBJECT,
  invites: EMPTY_OBJECT,
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.usersData = action.payload;
    });
    builder.addCase(getInvitedUsersList.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.invites = action.payload;
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
