import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { set } from "lodash";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import { createPopper } from "@popperjs/core";
import { RenderSVG, Users } from "../../../assets/icons";
import { DEFAULT_VALUES, WINDOWS_EVENTS } from "../../../constants";
import ErrorMessage from "../../atoms/ErrorMessage";
import { useSelector } from "react-redux";
import { selectSelectedTrip } from "../../../screens/Booking/Trips";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_STRING, TEN, EMPTY_OBJECT } =
  DEFAULT_VALUES;
const { CLICK } = WINDOWS_EVENTS;
const MAX_AGE_OF_CHILDREN = 17;
const MAX_ROOM_CAN_BOOK = 6;
const MAX_ADULTS_PER_ROOM = 8;
const MAX_CHILDREN_PER_ROOM = 4;
const MAX_GUESTS_PER_ROOM = 10;
const SHOW_ERROR_DURATION = 3000;
const NO_OF_CHILD = "noOfChild";
const NO_OF_ADULTS = "noOfAdults";

const childrenAgeRange = Array.from({ length: MAX_AGE_OF_CHILDREN + ONE }).map(
  (_, index) => ({
    label: index.toString(),
    value: index,
  })
);

const roomsOptions = Array.from({ length: MAX_ROOM_CAN_BOOK }).map(
  (_, index) => ({
    id: index,
    label: `${index + ONE} Room${index !== ZERO ? "s" : ""}`,
    value: index + ONE,
  })
);

const adultCountOptions = Array.from({ length: MAX_ADULTS_PER_ROOM }).map(
  (_, index) => ({
    id: index,
    label: `${index + ONE} Adult${index !== ZERO ? "s" : ""}`,
    value: index + ONE,
  })
);

const RoomAndGuestsCount = () => {
  const { t } = useTranslation();
  const guestsCountRef = useRef();
  const selectRef = useRef();
  const dropdownRef = useRef();
  const popperInstance = useRef(null);
  const [show, setShow] = useState(false);
  const [totalGuests, setTotalGuests] = useState(ONE);
  const { values, setFieldValue } = useFormikContext();
  const [errorMessage, setErrorMessage] = useState("");
  const [roomIndex, setRoomIndex] = useState()
  const { noOfRooms = ONE, roomGuests = EMPTY_ARRAY } = values;
  const tripDetails = useSelector(selectSelectedTrip);
  const { passengers = [] } = tripDetails || EMPTY_OBJECT;
  const passengersCount = passengers.length;
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        guestsCountRef.current &&
        !guestsCountRef.current.contains(e.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener(CLICK, checkIfClickedOutside);

    return () => {
      document.removeEventListener(CLICK, checkIfClickedOutside);
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [guestsCountRef.current]);

  useEffect(() => {
    const totalCount = roomGuests.reduce(
      (total, guestObject) =>
        total +
        parseInt(guestObject[NO_OF_ADULTS]) +
        parseInt(guestObject[NO_OF_CHILD]),
      ZERO
    );
    setTotalGuests(totalCount);
  }, [roomGuests]);

  useEffect(() => {
    if (show) {
      popperInstance.current = createPopper(
        selectRef.current,
        dropdownRef.current,
        {
          placement: "bottom",
        }
      );
    }

    return () => {
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [show]);

  const handleRoomCountChange = (e) => {
    const { value } = e.target;
    let newRoomGuestsArray = Array.from({ length: value }, () => ({
      noOfAdults: ZERO,
      noOfChild: ZERO,
      childAge: [],
    }));

    let totalAdults = ZERO;
    let totalChildren = ZERO;
    let childAges = [];

    roomGuests.forEach((room) => {
      if (room.noOfAdults || room.noOfChild || room.childAge.length) {
        totalAdults += room.noOfAdults;
        totalChildren += room.noOfChild;
        childAges.push(...room.childAge);
      }
    });
    
    if (value > roomGuests.length) {
      newRoomGuestsArray = [...roomGuests];
      for (let i = 0; i < value - roomGuests.length; i++) {
        newRoomGuestsArray.push({
          noOfAdults: ONE,
          noOfChild: ZERO,
          childAge: []
        });
      }
      setFieldValue("roomGuests", newRoomGuestsArray);
      setFieldValue("noOfRooms", value);
      return;
    }

    if (value < roomGuests.length) {
      if (totalAdults > (value * 8)) {
        const error = `There can only be 8 adults selected per room`;
        showError(error);
        totalAdults = value * 8;
      } else {
        totalAdults = totalAdults;
      }
      totalChildren = 0;
      childAges = [];
    }
    
    const baseAdultsPerRoom = Math.floor(totalAdults / value);
    const extraAdults = totalAdults % value;
    newRoomGuestsArray.forEach((room, index) => {
      room.noOfAdults = 
        baseAdultsPerRoom + (index < extraAdults ? 1 : 0);
    });
    setFieldValue("roomGuests", newRoomGuestsArray);
    setFieldValue("noOfRooms", value);
  };
  
  const showError = (error) => {
    setErrorMessage(error);
    setTimeout(() => setErrorMessage(EMPTY_STRING), SHOW_ERROR_DURATION);
  };

  const handleGuestCountChange = (value, roomIndex, type) => {
    setRoomIndex(roomIndex)
    let updatedGuestsCounts = [...roomGuests];
    let updatedChildAge = [...updatedGuestsCounts[roomIndex].childAge];
    const totalNoOfAdults = parseInt(updatedGuestsCounts[roomIndex].noOfAdults);
    const totalNoOfChildren = parseInt(updatedGuestsCounts[roomIndex].noOfChild);
    const valueInt = parseInt(value);
    
    const canAddGuest = (type === NO_OF_ADULTS && totalNoOfChildren + valueInt <= TEN) ||
      (type === NO_OF_CHILD && totalNoOfAdults + valueInt <= TEN);

    if (canAddGuest) {
      if (type === NO_OF_CHILD) {
        if (valueInt > updatedChildAge.length) {
          const extraAgesArr = Array(valueInt - updatedChildAge.length).fill(ONE);
          updatedChildAge = updatedChildAge.concat(extraAgesArr);
        } else if (valueInt < updatedChildAge.length) {
          updatedChildAge = updatedChildAge.slice(0, valueInt);
        }
        updatedGuestsCounts[roomIndex] = {
          ...updatedGuestsCounts[roomIndex],
          [type]: valueInt,
          childAge: updatedChildAge,
        };
      } else {
        updatedGuestsCounts[roomIndex] = {
          ...updatedGuestsCounts[roomIndex],
          [type]: valueInt,
        };
      }
    } else {
      let error = `Maximum Guest Limit Exceeded at room ${roomIndex + ONE}`
      showError(error)
    }
    setFieldValue("roomGuests", updatedGuestsCounts);
  };

  const handleChildAgeChange = (value, roomIndex, childIndex) => {
    let updatedGuestsCount = [...roomGuests];
    set(updatedGuestsCount, `${roomIndex}.childAge[${childIndex}]`, value);
    setFieldValue("roomGuests", updatedGuestsCount);
  };

  const renderCountPlaceholder = () => {
    return totalGuests > ONE
      ? t("searchSection.guests")
      : t("searchSection.guest");
  };

  const isBottom = get(
    popperInstance,
    'current.state.placement.includes("bottom")',
    ""
  );

  return (
    <div ref={guestsCountRef}>
      <button
        ref={selectRef}
        type='button'
        onClick={() => setShow(!show)}
        className='rounded-md w-full bg-white border shadow-sm border-contrast-300 flex items-center gap-2 p-3.5'
      >
        <RenderSVG Svg={Users} className='text-contrast-400' alt='User Icon' />
        <span className='text-contrast-900 whitespace-nowrap text-ellipsis overflow-hidden text-[17px]'>
          {noOfRooms} Room | {`${totalGuests} ${renderCountPlaceholder()}`}
        </span>
      </button>
      {show && (
        <div
          ref={dropdownRef}
          className={classNames(
            "dropdown-menu absolute z-30 bg-white px-6 py-4 rounded-lg border border-contrast-200 shadow-2xl my-2 w-full md:min-w-[450px] md:right-0 !mt-3 max-h-72 overflow-y-auto",
            {
              "top-full": !isBottom,
              "bottom-full": isBottom,
            }
          )}
        >
       <>
       <ErrorMessage errorMessage={errorMessage} className='mb-2 text-start' />
          <div className='flex justify-between'>
            <div className='font-semibold'>No. of Room(s)</div>
            <div className=' border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500'>
              <select
                className='w-32 rounded-md py-2 px-8 border-0 block text-sm shadow-none bg-primary-100/80'
                onChange={handleRoomCountChange}
                value={noOfRooms}
              >
                {roomsOptions.map((item) => (
                  <option key={item.id} value={item.value} className='text-sm'>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {Array.from({ length: noOfRooms }).map((_, index) => {
            const childrenCountOptions = Array.from({
              length:
                Math.min(
                  MAX_CHILDREN_PER_ROOM,
                  MAX_GUESTS_PER_ROOM - roomGuests[index]?.noOfAdults
                ) + ONE,
            }).map((_, index) => ({
              id: index,
              label: `${index} ${index <= ONE ? "Child" : "Children"}`,
              value: index,
            }));

            return (
              <div key={uuid()}>
                <ul className='divide-y divide-contrast-200'>
                  <li className='py-2 flex items-center justify-between'>
                    <div className='font-semibold'>Room {index + ONE} </div>
                    <div className='flex gap-2'>
                      <div className='border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500'>
                        <select
                          className='w-32 rounded-md py-2 px-8 border-0 block text-sm shadow-none bg-primary-100/80'
                          onChange={(e) =>
                            handleGuestCountChange(
                              e.target.value,
                              index,
                              NO_OF_ADULTS
                            )
                          }
                          value={roomGuests[index]?.noOfAdults}
                        >
                          {adultCountOptions.map(({ id, label, value }) => (
                            <option key={id} value={value} className='text-sm'>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500'>
                        <select
                          className='w-32 rounded-md py-2 px-8 border-0 block text-sm shadow-none bg-primary-100/80'
                          onChange={(e) =>
                            handleGuestCountChange(
                              e.target.value,
                              index,
                              NO_OF_CHILD
                            )
                          }
                          value={roomGuests[index]?.noOfChild}
                        >
                          {childrenCountOptions.map(({ id, label, value }) => (
                            <option key={id} value={value} className='text-sm'>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                </ul>
                {roomGuests[index]?.childAge.length !== ZERO && (
                  <div className='max-h-60 overflow-y-auto no-scrollbar'>
                    <div>
                      {roomGuests[index]?.childAge.map((age, id) => (
                        <div
                          key={uuid()}
                          className='flex gap-2 space-y-1 justify-between text-contrast-900 items-center'
                        >
                          <label
                            className='text-sm ml-auto'
                            htmlFor={`childAge-${id}`}
                          >
                            Child Age {id + ONE} (Years)
                          </label>
                          <div className='border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500'>
                            <select
                              id={`childAge-${id}`}
                              className='w-32 rounded-md  py-2 px-8 border-0 block text-sm shadow-none bg-primary-100/80'
                              onChange={(e) =>
                                handleChildAgeChange(e.target.value, index, id)
                              }
                              value={age}
                            >
                              {childrenAgeRange.map(({ label, value }) => (
                                <option
                                  key={label}
                                  value={value}
                                  className='text-sm'
                                >
                                  {label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          </>
        </div>
      )}
    </div>
  );
};

export default RoomAndGuestsCount;
