import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import {
  selectSelectedLCCMeals,
  selectSelectedNonLCCMeals,
  selectSelectedTravelers,
} from "../index";
import { RenderSVG, Plus, Minus } from "../../../../assets/icons";
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
  INDEX,
} from "../../../../constants";
import { Tooltip } from "react-tooltip";
import { showSSRPrice } from "../../../../helper";
import { actions } from "../flightBookings.reducer";
import { selectCountryInfo } from "../../../Profile";
import classNames from "classnames";

const { setSelectedLCCMeals, setSelectedNonLCCMeals } = actions;

const { ZERO, ONE, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;
const { LAST } = INDEX;

const MealsCard = ({ data, isLCC }) => {
  const dispatch = useDispatch();
  const {
    flightNumber,
    code,
    resultIndex,
    airlineDescription,
    description,
    price,
  } = data;
  const { t } = useTranslation();
  const selectedTravelers = useSelector(selectSelectedTravelers);
  const selectedLCCMeals = useSelector(selectSelectedLCCMeals);
  const selectedNonLCCMeals = useSelector(selectSelectedNonLCCMeals);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const travelersCount = selectedTravelers.length;
  const currencyCode = get(
    selectedCountryInfo,
    "currency.code",
    DEFAULT_CURRENCY_CODE
  );

  let currentFlightMeals = isLCC
    ? get(selectedLCCMeals, flightNumber, EMPTY_ARRAY)
    : get(selectedNonLCCMeals, resultIndex, EMPTY_ARRAY);

  const mealCount = currentFlightMeals.filter(
    (meal) => meal.code === code
  ).length;

  const isAddButtonDisabled = travelersCount <= currentFlightMeals.length;

  const getErrorMessage = () =>
    isAddButtonDisabled
      ? `You can choose only ${travelersCount} meal${
          travelersCount > ONE ? "s" : EMPTY_STRING
        }`
      : EMPTY_STRING;

  const handleMealSelection = useCallback(
    (isAdd) => {
      const updatedMealSelection = [...currentFlightMeals];
      if (isAdd) updatedMealSelection.push(data);
      else {
        const itemIndex = updatedMealSelection.findIndex(
          (meal) => meal.code === code
        );
        if (itemIndex !== LAST) updatedMealSelection.splice(itemIndex, ONE);
      }

      isLCC
        ? dispatch(
            setSelectedLCCMeals({
              ...selectedLCCMeals,
              [flightNumber]: updatedMealSelection,
            })
          )
        : dispatch(
            setSelectedNonLCCMeals({
              ...selectedNonLCCMeals,
              [resultIndex]: updatedMealSelection,
            })
          );
    },
    [
      dispatch,
      selectedLCCMeals,
      selectedNonLCCMeals,
      data,
      isLCC,
      currentFlightMeals,
    ]
  );

  return (
    <div>
      <div className='p-3 w-full rounded-lg border border-contrast-200 flex justify-center items-center mt-4'>
        <div className='grow shrink basis-0  justify-center items-center gap-2 flex'>
          <div className='grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex text-xs'>
            <div>{airlineDescription || description}</div>
            <div>{showSSRPrice(price, currencyCode)}</div>
          </div>
        </div>
        <div className='relative'>
          <Tooltip id='meal-tooltip' className='!bg-contrast-500 !rounded-lg' />
          <div className='h-full flex gap-1 border border-contrast-200 items-center justify-center p-1 rounded-md'>
            {!!mealCount && (
              <button
                disabled={!mealCount}
                onClick={() => handleMealSelection(ZERO)}
              >
                <RenderSVG Svg={Minus} height='15' alt='minus' />
              </button>
            )}
            {!!mealCount && <div className='cursor-default'>{mealCount}</div>}
            <button
              className={classNames("", {
                "flex items-center justify-center text-xs gap-1 px-2 py-1":
                  !mealCount,
                "cursor-not-allowed": isAddButtonDisabled,
              })}
              onClick={() => !isAddButtonDisabled && handleMealSelection(ONE)}
              data-tooltip-id='meal-tooltip'
              data-tooltip-content={getErrorMessage()}
            >
              {mealCount ? (
                <RenderSVG Svg={Plus} height='15' alt='plus' />
              ) : (
                t("travelerInfo.add")
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MealsCard;
