import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Cross, RenderSVG } from "../../../../assets/icons";
import Modal from "../../Modal";
import Spinner, { SPINNER_NAMES } from "../../Spinner";

const RESCHEDULE = "Reschedule";
const CANCEL = "Cancel";
const LOGOUT = "Log Out";
const RED = "red";
const { DELETE_TRAVELER, UPDATE_TRAVELER, DELETE_CHILD_BOOKING_BY_PARENT_ID } = SPINNER_NAMES;

const ConfirmationModal = ({
  data = {},
  color = RED,
  setIsModalOpen,
  isModalOpen,
  handleAction,
  children,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { type, header, description, button } = data;

  return (
    <Modal shouldShowModalFromProps={isModalOpen}>
      <div className='bg-white px-4 pb-2 pt-2 sm:p-6 sm:pb-4'>
        <div className='w-full p-3 border-b border-contrast-300 shadow-sm  justify-start items-center gap-4 inline-flex'>
          <div className={`p-3 rounded-full bg-${color}-100/50`}>
            <div
              className={`rounded-full bg-${color}-600 text-white w-5 h-5 text-center font-bold`}
            >
              !
            </div>
          </div>
          <div className="grow shrink basis-0 text-contrast-900 text-2xl font-bold font-['Inter'] leading-loose">
            {t(header)}
          </div>
          <button
            type='button'
            className='p-[9px] rounded-[19px] justify-center items-center flex hover:shadow hover:bg-zinc-300'
            onClick={() => setIsModalOpen(false)}
          >
            <RenderSVG Svg={Cross} alt='cross' className='relative text-contrast-900' />
          </button>
        </div>
        <div
          className={classNames(
            "p-4 flex-col justify-start items-start inline-flex",
            {
              "h-[450px] gap-4": type === RESCHEDULE,
            }
          )}
        >
          <div className="self-stretch text-contrast-500 text-sm font-normal font-['Inter'] leading-tight">
            {t(description)}
          </div>
          {children}
        </div>
        <div className='w-full p-3 justify-start items-center gap-2 inline-flex'>
          <div className='grow shrink basis-0 h-[38px] justify-end items-start gap-4 flex'>
            <button
              type='button'
              className='px-[17px] py-[12px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300'
              onClick={() => setIsModalOpen(false)}
            >
              <div className="text-contrast-700 text-sm font-medium font-['Inter'] leading-tight">
                {t("userBookings.close")}
              </div>
            </button>
            <button
              type='button'
              className={classNames(
                "px-[17px] py-[12px] h-[44px] w-[80px] rounded-md shadow justify-center items-center flex whitespace-nowrap",
                {
                  "bg-primary-600 hover:bg-primary-700":
                    type === RESCHEDULE || LOGOUT,
                  "bg-red-600 hover:bg-red-700": type === CANCEL,
                }
              )}
              onClick={() => handleAction(type)}
              disabled={isSubmitting}
            >
              <Spinner
                name={[DELETE_TRAVELER, UPDATE_TRAVELER, DELETE_CHILD_BOOKING_BY_PARENT_ID]}
                setIsSpinnerActive={setIsSubmitting}
              >
                <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                  {t(button)}
                </div>
              </Spinner>
            </button>
          </div>
        </div>  
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
