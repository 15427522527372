import { useEffect, useRef, useState } from "react";
import { useCollapse } from "react-collapsed";
import { useFormikContext } from "formik";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import { renderFieldError, useClickOutside } from "../../../helper";
import {
  ChevronDown,
  Cross,
  RenderSVG,
  SearchIconGray,
} from "../../../assets/icons";
import { createPopper } from "@popperjs/core";

const MultiSelectField = ({
  name,
  label,
  selectableValues,
  selectAll = true,
  placeholder,
}) => {
  const dropDownRef = useRef();
  const componentRef = useRef();
  const popperInstance = useRef(null);
  const selectRef = useRef();

  const { getToggleProps, getCollapseProps, setExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: false,
  });
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const [selectedValues, setSelectedValues] = useState(get(values, name, []));
  const [shouldShowAllValues, setShouldShowAllValues] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const MAX_VISIBLE_VALUES = 9;

  useClickOutside(componentRef, () => setExpanded(false));

  useEffect(() => {
    popperInstance.current = createPopper(
      selectRef.current,
      dropDownRef.current,
      {
        placement: "bottom",
      }
    );
    return () => {
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, []);

  useEffect(() => {
    setFieldValue(name, selectedValues);
  }, [selectedValues]);

  const handleSelectAll = (e) => {
    const updatedValues =
      selectedValues.length !== selectableValues.length
        ? selectableValues.map((item) => item.name)
        : [];
    setSelectedValues(updatedValues);
  };

  const handleUnselectValue = (value) => {
    const updatedValues = selectedValues.filter((val) => val !== value);
    setSelectedValues(updatedValues);
  };

  const handleSelectValue = (e) => {
    const { id: value, checked } = e.target;
    if (checked)
      setSelectedValues(Array.from(new Set([...selectedValues, value])));
    else handleUnselectValue(value);
  };

  const toggleShowAllValues = () => {
    setShouldShowAllValues(!shouldShowAllValues);
  };

  const filteredValues = selectableValues.filter((item) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const minifiedSelectedValues = shouldShowAllValues
    ? selectedValues
    : selectedValues.slice(0, MAX_VISIBLE_VALUES);

  return (
    <div className='form-group mb-6'>
      <label
        htmlFor={name}
        className='block text-sm font-medium mb-1 text-contrast-900'
      >
        {label}
      </label>
      <div
        ref={componentRef}
        className='flex items-center focus-within:border-primary-500 border-2 rounded-md relative'
      >
        <div ref={selectRef} className='relative px-3 w-full'>
          <div className='flex gap-x-2 gap-y-1 flex-wrap min-h-10 items-center py-1 z-50 max-h-60 overflow-auto no-scrollbar'>
            {placeholder && isEmpty(selectedValues) && (
              <span>{placeholder}</span>
            )}
            {minifiedSelectedValues &&
              minifiedSelectedValues.map((value) => (
                <div
                  key={value}
                  className='flex items-center gap-1 h-fit text-sm px-2 py-0.5 rounded-xl capitalize bg-contrast-100'
                >
                  {value}
                  <button
                    type='button'
                    onClick={() => handleUnselectValue(value)}
                  >
                    <RenderSVG
                      Svg={Cross}
                      className='text-contrast-500 hover:text-contrast-700'
                      width='14'
                    />
                  </button>
                </div>
              ))}
            {selectedValues.length > MAX_VISIBLE_VALUES && (
              <button
                type='button'
                onClick={toggleShowAllValues}
                className='text-sm text-blue-500'
              >
                {shouldShowAllValues ? "View Less" : "View More"}
              </button>
            )}
          </div>
          <div className='absolute right-3 top-1/4' {...getToggleProps()}>
            <RenderSVG Svg={ChevronDown} className='text-contrast-700' width='15' />
          </div>
        </div>
        <div ref={dropDownRef} className=' w-full z-50  max-h-72'>
          <div
            className={classNames(
              " bg-white border border-contrast-200 shadow-sm rounded-md"
            )}
            {...getCollapseProps()}
          >
            <div className='flex flex-col py-1 opacity-100 max-h-48 overflow-y-auto'>
              <div className='flex-1 relative p-2'>
                <RenderSVG
                  Svg={SearchIconGray}
                  alt='Filter Icon'
                  className='absolute top-1/2 -translate-y-1/2 left-3'
                  width='20'
                />
                <input
                  type='text'
                  placeholder='Search...'
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className='block w-full text-sm pl-10 py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-50'
                />
              </div>
              {selectAll && (
                <div className='flex gap-2 px-3 py-2 hover:bg-contrast-100 cursor-pointer items-center'>
                  <label className='w-full text-sm cursor-pointer'>
                    <input
                      id='checkAll'
                      type='checkbox'
                      className='!rounded checked:bg-primary-600 focus:checked:bg-primary-600 hover:checked:bg-primary-600 focus:ring-0'
                      checked={
                        selectedValues.length === selectableValues.length
                      }
                      onChange={handleSelectAll}
                    />
                    <span className='pl-2'>Select All</span>
                  </label>
                </div>
              )}
              {filteredValues.map(({ name, class: optionClasses }) => (
                <div
                  key={name}
                  className='flex gap-2 px-3 py-2 hover:bg-contrast-100 items-center'
                >
                  <label
                    className={classNames(
                      "rounded-2xl py-0.5 font-medium inline-flex items-center text-sm cursor-pointer",
                      optionClasses
                    )}
                  >
                    <input
                      id={name}
                      type='checkbox'
                      className='!rounded checked:bg-primary-600 focus:checked:bg-primary-600 hover:checked:bg-primary-600 focus:ring-0 pl-2'
                      checked={selectedValues.includes(name)}
                      onChange={handleSelectValue}
                    />
                    <span className='pl-2'>{name}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        {renderFieldError(name, errors, touched)}
      </div>
    </div>
  );
};

export default MultiSelectField;
