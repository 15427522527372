import { createSlice } from "@reduxjs/toolkit";
import {
  createWallet,
  updateWallet,
  getWallet,
  fetchWalletTransactions,
} from "./account.actions";

const initialState = {
  walletInfo: {},
  walletTransactions: [],
};

const slice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWallet.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.walletInfo = action.payload;
      })
      .addCase(createWallet.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.walletInfo = action.payload;
      })
      .addCase(updateWallet.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.walletInfo = action.payload;
      })
      .addCase(fetchWalletTransactions.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.walletTransactions = action.payload;
      });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
