import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Cross,
  RenderSVG,
  ExclamationCircleIcon,
} from "../../../../assets/icons";
import { setSelectedModal } from "../../Modal";

const FailureModal = ({ setCurrentModal }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setSelectedModal());
  };
  const { t } = useTranslation();

  return (
    <div className='bg-white'>
      <div className='w-full p-6 border-b border+-contrast-200 justify-start items-center gap-4 inline-flex'>
        <div className='rounded-full bg-red-50 p-2 w-10 h-10'>
          <RenderSVG
            Svg={ExclamationCircleIcon}
            width='24'
            height='24'
            className='text-[25px]'
          />
        </div>
        <div className="grow shrink basis-0 text-contrast-900 text-2xl font-bold font-['Inter'] leading-loose">
          {t("userBookings.cancel.failureModal.header")}
        </div>
        <button
          type='button'
          className='p-[9px] rounded-[19px] justify-center items-center flex'
          onClick={handleClose}
        >
          <RenderSVG Svg={Cross} alt='cross' className='relative' />
        </button>
      </div>

      <div className='p-6 flex-col justify-start items-start gap-4 inline-flex'>
        <div className="self-stretch text-contrast-500 text-sm font-normal font-['Inter'] leading-tight">
          <div className=' text-sm text-contrast-400 text-[#959aa4]'>
            <div>{t("userBookings.cancel.failureModal.description")}</div>
            <div>{t("userBookings.cancel.support")}</div>
            <a
              href='mailto:support@tavatrip.com'
              className='text-primary-600 hover:opacity-75 text-sm inline'
            >
              support@tavatrip.com
            </a>{" "}
            {t("userBookings.cancel.support1")}
          </div>
        </div>
      </div>
      <div className='w-full p-6 border-t border-contrast-200 justify-start items-center gap-2 inline-flex'>
        <div className='grow shrink basis-0 h-[38px] justify-end items-start gap-4 flex'>
          <button
            type='button'
            className='px-[17px] py-[9px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300'
            onClick={handleClose}
          >
            <div className="text-contrast-700 text-sm font-medium font-['Inter'] leading-tight">
              {t("userBookings.cancel.failureModal.button")}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FailureModal;
