import { useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { get, uniqueId } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import Modal from "../Modal";
import { selectFlightPriceInfo } from "../../../screens/FlightResults/index";
import {
  MODAL_SIZE,
  DEFAULT_VALUES,
  REGEX,
  CURRENCY_SYMBOLS,
  CATEGORY_TITLES,
} from "../../../constants";
import Tabs from "../Tabs/Tabs";
import {
  DATE_TIME_QUALIFIERS,
  QUALIFIERS,
} from "../../../ResponseMapper/FlightPriceMapping/qualifiers";
import { dateFormatter, priceFormatter, timeFormatter } from "../../../helper";
import { selectCountryInfo } from "../../../screens/Profile";

const { LG } = MODAL_SIZE;
const { EMPTY_ARRAY, EMPTY_STRING, ZERO } = DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;
const REISSUE_CATEGORY = "31";
const AMOUNT = "Amount";
const APPLICABLE = "Applicable";
const { DASH_SEPARATOR } = REGEX;

const RenderFareRulesHTML = ({ htmlString }) => {
  const styledTagReplacements = [
    {
      tag: DASH_SEPARATOR,
      replacement: "<br>",
    },
    {
      tag: "<table",
      replacement:
        '<table class="table-auto border-collapse w-full border border-contrast-300 rounded-lg shadow-md"',
    },
    {
      tag: "<th",
      replacement:
        '<th class="px-4 py-2 border border-contrast-300 bg-contrast-100 text-left font-medium"',
    },
    {
      tag: "<td",
      replacement: '<td class="px-4 py-2 border border-contrast-300 text-left"',
    },
    {
      tag: "<ul",
      replacement: '<ul class="list-disc pl-5 space-y-2"',
    },
    {
      tag: "<li",
      replacement: '<li class="mb-2 text-contrast-600 leading-relaxed block"',
    },
    {
      tag: "<h1",
      replacement: '<h1 class="text-3xl font-bold mb-4 text-contrast-900"',
    },
    {
      tag: "<h2",
      replacement: '<h2 class="text-2xl font-semibold mb-3 text-contrast-800"',
    },
    {
      tag: "<h3",
      replacement: '<h3 class="text-xl font-medium mb-2 text-contrast-700"',
    },
    {
      tag: "<p",
      replacement: '<p class="mb-4 text-contrast-600 leading-relaxed"',
    },
    {
      tag: "<a",
      replacement:
        '<a class="text-primary-600 hover:text-primary-800 underline"',
    },
    {
      tag: "<strong",
      replacement: '<strong class="font-semibold text-contrast-800"',
    },
    { tag: "<em", replacement: '<em class="italic text-contrast-700"' },
    {
      tag: "<blockquote",
      replacement:
        '<blockquote class="border-l-4 border-primary-500 pl-4 italic text-contrast-600"',
    },
  ];
  styledTagReplacements.forEach(({ tag, replacement }) => {
    htmlString = htmlString.replace(new RegExp(tag, "g"), replacement);
  });

  return (
    <div className='flex-1 overflow-auto px-10 pt-4 pb-10 gap-2 justify-center'>
      {parse(htmlString)}
    </div>
  );
};

const filteredRestrictions = (restrictions) =>
  restrictions.filter(
    (each) => !["BNW", "FFT", "ADW", "ANW"].includes(each.indicator)
  );

const RenderFareRulesAmadeus = ({ reissue, refund }) => {
  const renderSection = (item) => {
    const { monetaryDetails, category, restrictionsInfo } = item;
    const restrictions = filteredRestrictions(restrictionsInfo);

    return (
      <div key={category} className='m-3'>
        <div className='mx-4 my-2 font-bold border-b border-secondary-200 text-secondary-600'>
          {CATEGORY_TITLES[category]?.header}
        </div>

        <RenderListOfMonetoryDetails
          detailsList={monetaryDetails}
          category={category}
          dateInfo={
            Array.isArray(item.dateInfo) ? item.dateInfo : [item.dateInfo]
          }
          restrictionsInfo={restrictions}
        />
      </div>
    );
  };

  return (
    <div className='flex-1 overflow-y-scroll max-h-96'>
      {renderSection(reissue)}
      {renderSection(refund)}
    </div>
  );
};

const RenderListOfMonetoryDetails = ({
  detailsList = [],
  category,
  dateInfo = [],
  restrictionsInfo = [],
}) => {
  const filteredData = detailsList.filter((item) => {
    return (
      parseInt(item.adult) !== 0 &&
      (parseInt(item.child) !== 0 || parseInt(item.infant) !== 0)
    );
  });
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const renderTable = (columns, titleKey, tableData = []) => (
    <div className='mx-4'>
      <div className='mx-4 my-2 font-bold text-secondary-600'>
        {CATEGORY_TITLES[category]?.[titleKey]}
      </div>
      <div
        className={classNames("m-3 border rounded", {
          " max-h-60 overflow-y-scroll":
            titleKey !== "dateInfo" && tableData.length > 3,
        })}
      >
        <table className='table-fixed w-full border-collapse border rounded'>
          <thead className='sticky top-0 z-10 bg-secondary-200'>
            <tr className='text-secondary-600'>
              {columns.map(({ label, className }) => (
                <th key={label} className={className}>
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='overflow-y-scroll max-h-60'>
            {tableData.map((row) => (
              <tr key={uniqueId()} className='border-b'>
                {columns.map(({ cellClassName, label, renderCell }) => (
                  <td key={uniqueId()} className={cellClassName}>
                    {(label === AMOUNT || label === APPLICABLE) && (
                      <div className='border border-contrast-500 rounded-lg p-1 text-xs'>
                        {row.adult && (
                          <div className='text-center grid grid-cols-2 gap-2'>
                            <div>Adult:</div>
                            <div>
                              {row.currency
                                ? `${currencySymbol} ${priceFormatter(
                                    parseFloat(row.adult)
                                  )}`
                                : row.adult}
                            </div>
                          </div>
                        )}
                        {row.child && (
                          <div className='text-center grid grid-cols-2 gap-2'>
                            <div>Child:</div>
                            <div>
                              {row.currency
                                ? `${currencySymbol}${priceFormatter(
                                    parseFloat(row.child)
                                  )}`
                                : row.child}
                            </div>
                          </div>
                        )}
                        {row.infant && (
                          <div className='text-center grid grid-cols-2 gap-2'>
                            <div>Infant:</div>
                            <div>
                              {row.currency
                                ? `${currencySymbol} ${priceFormatter(
                                    parseFloat(row.infant)
                                  )}`
                                : row.infant}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {renderCell(row)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <>
      {category === REISSUE_CATEGORY &&
        renderTable(
          [
            {
              label: "Criteria",
              className: "flex-1 text-left px-6 py-2",
              cellClassName: "flex-1 text-xs px-6 py-2 font-bold",
              renderCell: (row) =>
                DATE_TIME_QUALIFIERS[row.qualifier] || row.qualifier,
            },
            {
              label: "Date",
              className: "flex-1 px-6 py-2 justify-center",
              cellClassName: "flex-1 text-xs px-6 py-2 text-center",
              renderCell: (row) => dateFormatter(row.date),
            },
            {
              label: "Time",
              className: "flex-1 px-6 py-2 justify-center",
              cellClassName: "flex-1 text-xs px-6 py-2 text-center",
              renderCell: (row) => timeFormatter(row.time),
            },
          ],
          "dateInfo",
          dateInfo
        )}

      {renderTable(
        [
          {
            label: "Criteria",
            className: "w-1/2 text-left px-6 py-2",
            cellClassName: "text-xs px-6 py-2 font-bold",
            renderCell: (row) => QUALIFIERS[row.indicator] || row.indicator,
          },
          {
            label: "Amount",
            className: "px-6 py-2 justify-center flex",
            cellClassName: "text-xs px-6 py-2 justify-center flex",
            renderCell: () => {},
          },
        ],
        "penalityInfo",
        filteredData
      )}
      {renderTable(
        [
          {
            label: "Criteria",
            className: "w-1/2 text-left px-6 py-2",
            cellClassName: "text-xs px-6 py-2 font-bold",
            renderCell: (row) => QUALIFIERS[row.indicator] || row.indicator,
          },
          {
            label: "Applicable",
            className: "px-6 py-2 justify-center flex",
            cellClassName: "text-xs px-6 py-2 justify-center flex",
            renderCell: () => {},
          },
        ],
        "restrictionInfo",
        restrictionsInfo
      )}
    </>
  );
};

const FareRulesModal = ({ shouldShowFareRules, setShouldShowFareRules }) => {
  let id = ZERO;
  let defaultIndex = ZERO;
  const { t } = useTranslation();

  const allFlightPriceRes = useSelector(selectFlightPriceInfo);

  const source = get(
    allFlightPriceRes,
    "0.flightPriceInfo.provider",
    EMPTY_STRING
  );

  const fareRulesTab = useMemo(() => {
    return allFlightPriceRes.flatMap(({ flightPriceInfo }) => {
      const { fareRules } = flightPriceInfo;
      let htmlString = EMPTY_STRING;
      return fareRules.map(({ Origin, Destination, FareRuleDetail }) => {
        htmlString = FareRuleDetail.includes("Please refer above")
          ? get(fareRules, "0.FareRuleDetail", EMPTY_STRING)
          : FareRuleDetail;
        const isDefault = defaultIndex === ZERO;
        if (isDefault) defaultIndex++;
        return {
          id: `${Origin}-${Destination}`,
          title: `${Origin} - ${Destination}`,
          element: <RenderFareRulesHTML htmlString={htmlString} />,
          default: isDefault,
        };
      });
    });
  }, [allFlightPriceRes]);

  const handleModelClose = useCallback(() => setShouldShowFareRules(false), []);

  return (
    <Modal
      size={LG}
      shouldShowModalFromProps={shouldShowFareRules}
      handleClose={handleModelClose}
      title={
        <p className='flex-1 text-2xl font-bold text-contrast-900'>
          {t("fareRules.modalTitle")}
        </p>
      }
    >
      <Tabs
        tabs={fareRulesTab}
        showCarousel={fareRulesTab}
        className='min-w-[35%]'
      />
      <div className='bg-red-100 border-l-4 border-red-500 p-1 pl-2 mb-3 mx-3 text-xs rounded'>
        <p className='text-red-700'>
          <span className='font-bold text-sm'>{t("fareRuleNote.note")} </span>
          {t("fareRuleNote.content")}
        </p>
      </div>
    </Modal>
  );
};

export default FareRulesModal;
