import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { get } from "lodash";
import "react-phone-input-2/lib/style.css";
import { DEFAULT_USER_COUNTRY_CODE } from "../../constants";
import { selectCountryInfo } from "../../screens/Profile";

export const PhoneNumberInput = ({
  name,
  values,
  placeholder,
  setFieldValue,
  setFieldTouched,
  showFormattedNumber = false,
  handleSubmit = () => {},
  handleOnBlur = () => {},
  t = () => {},
  phoneCodePath = "",
}) => {
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const phoneNumber = get(values, `${name}`, {});
  const countryCode = get(
    selectedCountryInfo,
    "code",
    DEFAULT_USER_COUNTRY_CODE
  );

  return (
    <PhoneInput
      onEnterKeyPress={handleSubmit}
      country={countryCode.toLowerCase()}
      jumpCursorToEnd
      value={phoneNumber}
      enableSearch={true}
      enableClickOutside={true}
      disableSearchIcon
      autoFormat
      placeholder={t(placeholder)}
      onChange={(phone, countryData) => {
        if (showFormattedNumber) {
          const phoneCodeLen = countryData.dialCode.length;
          const splicedPhoneNumber = phone.substring(
            phoneCodeLen,
            phone.length
          );
          const updatedPhoneNumber = `+${countryData.dialCode}-${splicedPhoneNumber}`;
          setFieldValue(name, updatedPhoneNumber);
        } else {
          setFieldValue(name, phone);
        }
        setFieldValue(phoneCodePath || "phoneCode", `+${countryData.dialCode}`);
      }}
      onBlur={() => {
        setFieldTouched(name, true);
        handleOnBlur();
      }}
      searchClass='!form-control'
      inputStyle={{ width: "100%", border: "none", height: "100%" }}
      containerClass='w-full border border-contrast-300 !rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500'
      dropdownClass='flex flex-col'
      buttonClass='!rounded-r-none disabled:bg-contrast-200'
    />
  );
};
