import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  getFormattedDuration,
  getLayoverDuration,
  calculateTotalDuration,
  getCityNameByIata,
  generatePolicyViolationMessage,
} from "../../helper";
import PackagedFlightDetails from "./PackagedFlightDetails";
import { selectAllServicesPolicy } from "../Policies";
import { getStops } from "../../utils/flights.utils";
import { DEFAULT_VALUES, INDEX, FLIGHT_SERVICE_TYPE, CURRENCY_SYMBOLS } from "../../constants";
import { MODALS } from "../../components/organisms/AppModals";
import { setSelectedFlightIndexInfo } from "./index";
import { setSelectedModal } from "../../components/organisms/Modal";
import PriceInfo from "./PriceInfo";
import {  RenderSVG, InfoIcon } from "../../assets/icons";
import { Tooltip } from "react-tooltip";
import { isEmpty } from "lodash";

const { ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { LAST } = INDEX;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;
const { VIEW_FLIGHT_DETAILS_MODAL } = MODALS;

const RoundTripCard = ({ flightData }) => {
  const {
    flights,
    isSelectedFlight,
    hasHighCO2Emission,
    handleSelectPackage,
    currency,
    flightIndex,
  } = flightData;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    itineraries,
    source,
    isRefundable,
    miniFareRules,
    price: { totalPrice = 0, publishedPrice = 0, tavaMarkup = 0 },
    costPolicyViolation = {},
  } = flights[flightIndex] || EMPTY_OBJECT;
  const areSimilarFlightsAvailable = flights.length > ONE;

  const message = generatePolicyViolationMessage({costPolicyViolation, trip: "flight"});

  const handleViewRoundTripDetails = (flightIndexInfo) => {
    dispatch(setSelectedFlightIndexInfo(flightIndexInfo));
    dispatch(setSelectedModal(VIEW_FLIGHT_DETAILS_MODAL));
  };

  return (
    <div
      className={classNames(
        "border rounded-lg shadow-lg transition duration-1000 ease-in-out",
        {
          "border-primary-600 bg-primary-100": isSelectedFlight,
          "border-red-200 bg-red-100 hover:border-red-400 bg-opacity-15":!isSelectedFlight && !isEmpty(message),
          "border-contrast-200 bg-white hover:border-contrast-400": !isSelectedFlight && isEmpty(message) ,
          "mb-3": areSimilarFlightsAvailable,
        }
      )}
    >
      {!isEmpty(message) && (
          <div className="top-0 left-0 flex gap-1 items-center w-1/2 right-0 bg-gradient-to-r from-red-500 to-transparent text-white px-2 text-xs font-bold py-1">
          Violates Cost Policy
          <div
            data-tooltip-id={flights[flightIndex]?.flightId}
            data-tooltip-place="top"
            data-tooltip-content = {message}
          >
            <Tooltip
              id={flights[flightIndex]?.flightId}
              className="!w-56 !sm:w-72 !bg-primary-900 !rounded-lg !z-50"
            />
            <RenderSVG
              Svg={InfoIcon}
              width="16"
              height="16"
              alt="Info icon"
            />
          </div>
        </div>
      )}
      <div className='flex p-4 flex-wrap'>
        {itineraries.map((itinerary, index) => {
          const { segments, duration } = itinerary;
          const startLocation = segments[ZERO];
          const {
            noOfAvailableSeats,
            departure = EMPTY_OBJECT,
            baggageDetails = EMPTY_OBJECT,
          } = startLocation;
          const finalDestination = segments.at(LAST);
          const { arrival } = finalDestination;
          const totalNumberOfStops = segments.length - ONE;
          const layoverDuration = getLayoverDuration(segments);

          return (
            <div
              key={`${departure.date}${departure.time}`}
              className='flex flex-col gap-4 flex-1 justify-between mb-3 md:mb-0'
            >
              <div
                className={classNames(
                  "pr-4 mr-4 flex flex-wrap gap-4 justify-between col-span-6",
                  {
                    "sm:border-r border-dashed border-contrast-700": index === ZERO,
                  }
                )}
              >
                <PackagedFlightDetails
                  arrival={arrival}
                  startLocation={startLocation}
                  hasHighCO2Emission={hasHighCO2Emission}
                  baggage={baggageDetails}
                  noOfAvailableSeats={noOfAvailableSeats}
                  isRefundable={isRefundable}
                  miniFareRules={miniFareRules}
                />
                <div className='text-end sm:text-start whitespace-nowrap'>
                  <div className='text-base text-contrast-900 font-semibold text-center'>
                    {source === AMADEUS
                      ? getFormattedDuration(duration)
                      : calculateTotalDuration(layoverDuration, duration)}
                    <div className='text-xs text-contrast-600 font-bold'>
                      {getCityNameByIata(departure.iataCode)}-
                      {getCityNameByIata(arrival.iataCode)}
                    </div>
                  </div>
                </div>
                <div className='flex text-base whitespace-nowrap'>
                  {!totalNumberOfStops ? (
                    <h5 className='text-contrast-900 font-semibold'>
                      {t("flightResults.stopTypes.nonStop")}
                    </h5>
                  ) : (
                    <div>
                      <h5 className='flex text-base text-contrast-900 font-semibold'>
                        {totalNumberOfStops} {t("flightResults.stopTypes.stop")}
                      </h5>
                      <div className='text-xs text-contrast-600'>
                        {getStops(segments)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div className='flex justify-between lg:flex-col lg:justify-start lg:ml-auto  gap-1 items-center lg:items-stretch lg:w-auto w-full py-2 lg:py-0 lg:-mt-1 flex-wrap'>
          <PriceInfo
            flightIndex={flightIndex}
            totalPrice={totalPrice}
            publishedPrice={publishedPrice}
            tavaMarkup={tavaMarkup}
            currencySymbol={currency}
            translationHandler={t}
          />
          <div className='flex flex-row'>
            <div className='flex flex-row gap-3 justify-end border-b-contrast-200'>
              <div className='flex'>
                <button
                  className={classNames(
                    "py-2 px-4 flex items-center gap-2 rounded-md hover:bg-primary-300 active:bg-primary-200 border-none shadow-sm text-sm text-primary-700 font-medium whitespace-nowrap",
                    {
                      "bg-primary-200": isSelectedFlight,
                      "bg-primary-100": !isSelectedFlight,
                    }
                  )}
                  onClick={() => handleSelectPackage(flights)}
                >
                  {t("flightResults.selectFlight")}
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={() => handleViewRoundTripDetails(flights[flightIndex])}
            className='flex items-center text-sm text-primary-700 font-medium whitespace-nowrap py-2 px-[12px]'
          >
            {t("flightResults.viewDetails")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoundTripCard;
