import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";
import Spinner from "../../Spinner";
import Modal, { setSelectedModal } from "../../Modal";
import {
  RenderSVG,
  CheckGreen,
  CrossRed,
  ExclamationCircleIcon,
} from "../../../../assets/icons";
import { DEFAULT_VALUES, MODAL_SIZE, ROUTES } from "../../../../constants";
import { getCityNameByIata, getFormattedLongDate } from "../../../../helper";

const { ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { BOOKINGS } = ROUTES;
const { LG } = MODAL_SIZE;

const FlightCancellationModal = ({
  handleCancellation,
  spinnerName,
  isRoundTripDomestic,
  itineraries,
  flightCancellationStatus,
  selectedJourneyPnr,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const handleClose = () =>
    !isSpinnerActive && dispatch(setSelectedModal(null));
  const navigateToBookings = () => {
    navigate(BOOKINGS);
    dispatch(setSelectedModal(null));
  };

  const RenderItinerary = ({
    itinerary,
    index,
    selectedJourneyPnr,
    flightCancellationStatus,
  }) => {
    const { segments, pnr } = itinerary;

    const departure = get(segments, "0.departure", EMPTY_OBJECT);
    const arrival = get(
      segments,
      `${segments.length - 1}.arrival`,
      EMPTY_OBJECT
    );
    const journeyStartDate = departure.date;

    return (
      <div key={index} className='flex flex-col'>
        {isRoundTripDomestic && selectedJourneyPnr.includes(pnr) && (
          <div className='flex gap-4'>
            {!isEmpty(flightCancellationStatus) && (
              <div>
                <RenderSVG
                  Svg={
                    flightCancellationStatus.length > ONE
                      ? flightCancellationStatus[index].cancellationStatus ===
                        "CONFIRMED"
                        ? CheckGreen
                        : CrossRed
                      : flightCancellationStatus[ZERO].cancellationStatus ===
                        "CONFIRMED"
                      ? CheckGreen
                      : CrossRed
                  }
                  fill='none'
                  width={20}
                  height={20}
                />
              </div>
            )}
            <div className='flex items-center text-sm font-bold text-contrast-900 mb-1'>
              {getCityNameByIata(departure.iataCode)}-
              {getCityNameByIata(arrival.iataCode)} |{" "}
              {getFormattedLongDate(journeyStartDate, {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </div>
          </div>
        )}
        {!isRoundTripDomestic && (
          <div className='flex gap-4'>
            {!isEmpty(flightCancellationStatus) && (
              <div>
                <RenderSVG
                  Svg={
                    flightCancellationStatus?.[index]?.cancellationStatus ===
                    "CONFIRMED"
                      ? CheckGreen
                      : CrossRed
                  }
                  fill='none'
                  width={20}
                  height={20}
                />
              </div>
            )}
            <div className='flex items-center text-sm font-bold text-contrast-900 mb-1'>
              {getCityNameByIata(departure.iataCode)}-
              {getCityNameByIata(arrival.iataCode)} |{" "}
              {getFormattedLongDate(journeyStartDate, {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='overflow-auto'>
      <Modal title='Confirm Cancellation' size={LG} handleClose={handleClose}>
        {isEmpty(flightCancellationStatus) ? (
          <>
            <div className='flex flex-col px-10 pt-6 pb-10'>
              <div className='icon mb-5'>
                <RenderSVG
                  Svg={ExclamationCircleIcon}
                  className='mx-auto'
                  fill='none'
                  width={50}
                  height={50}
                />
              </div>
              <div className='flex flex-col justify-center items-center'>
                <div className='font-bold text-xl mb-2 text-[#030E43]'>
                  This is the final step of cancellation
                </div>
                <div className='text-blue-contrast-500 text-sm'>
                  Once Cancelled, your booking cannot be reinstated
                </div>
              </div>
            </div>
            <div className='text-center px-10 pb-6'>
              <div className='flex gap-3 justify-center items-center bg-[#F8F8FA] p-4 border border-gray-200 rounded-lg'>
                <div className='flex flex-col'>
                  {itineraries.map((itinerary, index) => (
                    <RenderItinerary
                      key={index}
                      itinerary={itinerary}
                      index={index}
                      selectedJourneyPnr={selectedJourneyPnr}
                      flightCancellationStatus={flightCancellationStatus}
                    />
                  ))}
                </div>
              </div>
              <div className='flex gap-3 justify-end p-6'>
                <button
                  type='button'
                  className='rounded-md shadow-sm px-4 py-3 border border-contrast-300 text-sm leading-5'
                  onClick={handleClose}
                >
                  Back
                </button>
                <button
                  type='button'
                  className='rounded-md shadow-sm px-4 disabled:bg-red-300 disabled:cursor-not-allowed py-3 bg-red-600 text-white text-sm leading-5'
                  onClick={handleCancellation}
                  disabled={isSpinnerActive}
                >
                  <Spinner
                    name={spinnerName}
                    setIsSpinnerActive={setIsSpinnerActive}
                    size='w-5 h-5'
                  />
                  {!isSpinnerActive && <span> Confirm cancellation</span>}
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className='flex flex-col justify-center items-center gap-3 px-10'>
            <div className='flex gap-3 justify-center items-center bg-[#F8F8FA] p-4 border border-gray-200 rounded-lg'>
              <div className='flex flex-col'>
                {itineraries.map((itinerary, index) => (
                  <RenderItinerary
                    key={index}
                    itinerary={itinerary}
                    index={index}
                    selectedJourneyPnr={selectedJourneyPnr}
                    flightCancellationStatus={flightCancellationStatus}
                  />
                ))}
              </div>
            </div>
            <div className='text-sm text-gray-700'>
              Amount will be refunded within 6-8 working days.
            </div>
            <div className='flex justify-end mb-4 w-full p-6'>
              <button
                className='py-3 px-4 rounded-md bg-[#4f46e5] hover:bg-[#4338ca] active:bg-[#4f46e5] shadow-sm text-sm text-white font-medium'
                onClick={navigateToBookings}
              >
                Go to My Trips
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default FlightCancellationModal;
