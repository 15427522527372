import Modal from "../../Modal";
import {
  RenderSVG,
  Cross,
  Information,
  ChevronUp,
  ChevronDown,
} from "../../../../assets/icons";
import {
  DEFAULT_VALUES,
  MODAL_SIZE,
  TRAVELER_TYPE,
} from "../../../../constants";
import { useCollapse } from "react-collapsed";
import {
  getFormattedAddress,
  getFormattedDateObject,
  getFutureFormattedDateObject,
  getDayDifference,
  renderStars,
  formatPriceWithCommas,
  getRoomTypeDetails
} from "../../../../helper";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const ROOM = "Room";
const { LG } = MODAL_SIZE;
const { ADULT, CHILD } = TRAVELER_TYPE;

const PriceBreakdown = ({
  amount,
  taxAndServices,
  totalTavamarkup,
  roomPrice,
  currency,
  noOfRooms,
}) => {
  const priceBreakdown = [
    { label: `Rooms x ${noOfRooms}`, amount: roomPrice + totalTavamarkup},
    { label: "Tax & Service Fees", amount: taxAndServices },
    { label: "Total Amount", amount: amount },
  ];

  return (
    <div className='bg-white rounded-xl border border-contrast-300 overflow-hidden '>
      <CollapsibleSection title='Price Breakdown'>
        <div className='flex flex-col divide-y divide-contrast-300'>
          <div className='divide-y divide-contrast-300'>
            <div className='flex flex-col flex-wrap gap-2 justify-between px-6 py-6 '>
              {priceBreakdown.map(({ label, amount }, index) => (
                <div key={index} className='flex justify-between'>
                  <h6 className=''>{label}</h6>
                  <div className='flex'>
                    <p>{currency}</p>&nbsp;
                    <p>{formatPriceWithCommas(amount)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CollapsibleSection>
    </div>
  );
};

const GuestDetails = ({ totalGuests }) => (
  <div className='bg-white rounded-xl border border-contrast-300 overflow-hidden mt-6'>
    <CollapsibleSection title='Guest Details' count={totalGuests.length}>
      <div className='flex flex-col divide-y divide-contrast-300'>
        {totalGuests.map(
          ({
            id,
            title = EMPTY_STRING,
            firstName = EMPTY_STRING,
            lastName = EMPTY_STRING,
            email = EMPTY_STRING,
            phoneNumber = EMPTY_STRING,
            paxType,
          }) => (
            <div key={id} className='divide-y divide-contrast-300'>
              <div className='flex items-center flex-wrap gap-2 justify-between px-6 py-6'>
                <div className='flex flex-col gap-1'>
                  <h6 className='text-sm font-bold text-contrast-900'>{`${title} ${firstName} ${lastName}`}</h6>
                  <div className='text-xs text-contrast-500'>
                    <span>
                      {email}, {phoneNumber}
                    </span>
                  </div>
                </div>
                <span className='text-xs font-semibold text-primary-800 bg-primary-100 px-3 py-1 rounded-3xl capitalize'>
                  {paxType === ONE ? ADULT : CHILD}
                </span>
              </div>
            </div>
          )
        )}
      </div>
    </CollapsibleSection>
  </div>
);

const CollapsibleSection = ({ title, children, count }) => {
  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  return (
    <>
      <div
        className='px-6 py-4 flex items-center border-b border-contrast-300 bg-contrast-100'
        {...getToggleProps()}
      >
        <h4 className='text-base font-bold text-contrast-900 flex-1'>
          {title}
        </h4>
        <h4 className='flex gap-3 items-center text-base font-medium text-contrast-900'>
          {count && <span>{count}</span>}
          <button className='p-2 cursor-pointer shrink-0'>
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              className='text-contrast-800'
              width='20'
            />
          </button>
        </h4>
      </div>
      <div
        className='flex flex-col divide-y divide-contrast-300'
        {...getCollapseProps()}
      >
        {children}
      </div>
    </>
  );
};

const HotelSummaryModal = ({
  title = "",
  hotelInfo = [],
  bookingInfo = [],
  totalGuests = [],
  hotelRoomsDetails = [],
  handleClose = () => {},
}) => {
  const {
    addressLine1,
    hotelImages,
    hotelName,
    starRating,
    cancellationMsgArr,
    hotelPolicyDetail,
  } = hotelInfo;
  const {
    noOfRooms,
    totalRoomPrice,
    totalOfferedPrice,
    totalTaxesAndFees,
    currency,
    checkIn,
    checkOut
  } = bookingInfo;
  const roomTypeData = getRoomTypeDetails(hotelRoomsDetails)
  const roomLabel = noOfRooms > ONE ? ROOM + "s" : ROOM;
  const checkInDate = getFormattedDateObject(checkIn?.replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$2-$1'));
  const checkOutDate = getFormattedDateObject(checkOut?.replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$2-$1'));
  const totalDays = getDayDifference(
    checkIn,
    checkOut
  );
  const hotelBookingDuration = totalDays || ONE;
  const totalTavamarkup = hotelRoomsDetails.reduce((total, room) => {
    return total + (room.price.tavaMarkup || ZERO);
  }, ZERO);
  const [checkinTime, setCheckinTime] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");

  useEffect(() => {
    const extractCheckInOutTimes = () => {
      try {
        if (hotelPolicyDetail) {
          const segments = hotelPolicyDetail.split("|");
          segments?.forEach((segment) => {
            const trimmedSegment = segment
              .trim()
              .replace(/<\/?[^>]+(>|$)/g, "");
            if (trimmedSegment?.startsWith("CheckIn Time-Begin")) {
              const match = trimmedSegment?.match(/: ([\d:]+ [AP]M)/);
              if (match) setCheckinTime(match[1]);
            }
            if (trimmedSegment?.startsWith("CheckOut Time")) {
              const match = trimmedSegment?.match(/: ([\d:]+ [AP]M)/);
              if (match) setCheckoutTime(match[1]);
            }
          });
        }
      } catch (error) {
        console.error("Error in extractCheckInOutTimes:", error);
      }
    };
    extractCheckInOutTimes();
  }, [hotelPolicyDetail]);

  return (
    <Modal
      shouldShowModalFromProps
      handleClose={handleClose}
      modalClassname='no-scrollbar h-[75vh]'
      size={LG}
    >
      <div className='flex flex-col'>
        <div className='flex justify-between items-center p-6 top-0 sticky border-b bg-white z-50'>
          <div className='flex gap-5 items-center'>
            <div className='rounded-full bg-primary-100 p-2 w-10 h-10'>
              <RenderSVG
                Svg={Information}
                width='24'
                height='24'
                className='text-[25px]'
              />
            </div>
            <div className='text-2xl leading-8 font-bold'>{title}</div>
          </div>
          <button onClick={handleClose}>
            <RenderSVG Svg={Cross} alt='cross' className='text-contrast-900' />
          </button>
        </div>

        <div className='flex items-center p-6'>
          <div className='flex flex-col gap-1 w-full'>
            <div className='bg-white rounded-lg shadow-sm border'>
              <div className='flex gap-6 px-4 py-3 border-b border-dashed border-contrast-300 justify-between'>
                <div className='flex-1'>
                  <h4 className='text-contrast-900 font-bold text-base mb-2'>
                    {hotelName}
                  </h4>
                  <p className='text-contrast-500 text-xs mb-2'>
                    {getFormattedAddress(addressLine1)}
                  </p>
                  {roomTypeData && (<p className="text-xs text-contrast-500 mb-2 line-clamp-1"><span className="font-semibold">Room Type: </span> {roomTypeData}</p>)}
                  <div className='flex gap-2 items-center mb-2'>
                    <div className='flex items-center gap-0.5'>
                      {renderStars(starRating)}
                    </div>
                  </div>
                </div>
                {hotelImages && (
                  <div className='shrink-0'>
                    <img
                      src={hotelImages}
                      alt={hotelName}
                      className='w-full rounded-lg h-28'
                    />
                  </div>
                )}
              </div>
              <div className='p-4 bg-primary-100/30'>
                <div className='grid grid-cols-12 gap-4'>
                  <div className='col-span-12 md:col-span-8 2xl:col-span-8'>
                    <div className='flex gap-6 justify-between items-center'>
                      <div>
                        <span className='text-contrast-500 text-xs font-medium'>
                          Check In
                        </span>
                        <h5 className='text-sm md:text-sm  text-contrast-900 mb-1'>
                          {checkInDate ? (
                            <>
                              {checkInDate.day}{" "}
                              <strong className='text-sm'>
                                {`${checkInDate.date} ${checkInDate.month} `}
                              </strong>
                              {checkInDate.year}
                              {checkinTime && <div>{checkinTime}</div>}
                            </>
                          ) : (
                            "..."
                          )}
                        </h5>
                      </div>
                      <div className='icon shrink-0'>
                        <div className='bg-contrast-200 font-medium text-contrast-800 rounded-2xl px-3 py-0.5 text-[10px]'>
                          {`${hotelBookingDuration} Night${
                            hotelBookingDuration > 1 ? "s" : ""
                          }`}
                        </div>
                      </div>
                      <div>
                        <span className='text-contrast-500 text-xs font-medium'>
                          Check Out
                        </span>
                        <h5 className='text-xs md:text-sm  text-contrast-900 mb-1'>
                          {checkOutDate ? (
                            <>
                              {checkOutDate.day}{" "}
                              <strong className='text-sm'>
                                {`${checkOutDate.date} ${checkOutDate.month} `}
                              </strong>
                              {checkOutDate.year}
                              {checkoutTime && <div>{checkoutTime}</div>}
                            </>
                          ) : (
                            "..."
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-12 md:col-span-4 2xl:col-span-4 flex gap-4'>
                    <div className='flex-1 text-end'>
                      <h5 className='text-xs text-contrast-800 font-bold mb-1'>
                        {noOfRooms} {roomLabel}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {!isEmpty(cancellationMsgArr) && <div className='p-4 bg-amber-50 rounded-b-lg'>
                <div className='flex items-center gap-2 pl-2'>
                  <ul className='text-xs text-green-700 flex flex-col !list-disc'>
                    {cancellationMsgArr}
                  </ul>
                </div>
              </div>}
            </div>
          </div>
        </div>
        <div className='px-6'>
          <PriceBreakdown
            amount={totalOfferedPrice + totalTavamarkup}
            totalTavamarkup={totalTavamarkup}
            taxAndServices={totalTaxesAndFees}
            roomPrice={totalRoomPrice}
            currency={currency}
            noOfRooms={noOfRooms}
          />
        </div>
        <div className='px-6 pb-6'>
          <GuestDetails totalGuests={totalGuests} />
        </div>
      </div>
    </Modal>
  );
};

export default HotelSummaryModal;
