import React, { useState, useCallback } from "react";
import Drawer, { selectSelectedDrawer } from "../../organisms/Drawer";
import { POSITIONS, DRAWER_SIZE } from "../../../constants";
import { actions } from "../../organisms/Drawer/drawers.reducers";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedUserInfo } from "../../../screens/Booking/Trips";

const { setSelectedDrawer } = actions;
const { RIGHT } = POSITIONS;
const { MD } = DRAWER_SIZE;

export default function SelectedTravelerInfo() {
  const dispatch = useDispatch();
  const selectedDrawer = useSelector(selectSelectedDrawer);
  const {
    email,
    dateOfBirth,
    phone,
    type,
    profilePic,
    firstName,
    lastName,
    middleName,
    name,
    role,
  } = useSelector(selectSelectedUserInfo);
  const [show, setShow] = useState(true);

  const handleClose = useCallback(() => {
    dispatch(setSelectedDrawer(null));
    setShow(false);
  }, [dispatch]);

  const getInitials = (firstName, lastName, name) => {
    if (firstName && lastName)
      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;

    if (name) {
      const [firstPart, secondPart] = name.split(" ");
      return `${(firstPart || "")[0].toUpperCase()}${
        secondPart ? secondPart[0].toUpperCase() : ""
      }`;
    }

    return "UN";
  };

  const userInfoItems = [
    { label: "Email", value: email || "No email provided" },
    {
      label: "Date of Birth",
      value: dateOfBirth || "No date selected",
    },
    {
      label: "Ph. Number",
      value: phone || "Number not added",
    }
  ];

  const userName =
    name ||
    `${firstName || ""} ${middleName || ""} ${lastName || ""}`.trim() ||
    "No Name Provided";

  return (
    <Drawer
      position={RIGHT}
      size={MD}
      heading='User Info'
      selectedDrawer={selectedDrawer}
      handleClose={handleClose}
      shouldOpen={show}
      customClasses='!h-full !top-0 !z-[999]'
    >
      <div className='flex flex-col gap-6 p-8'>
        <div className='relative flex items-center justify-center'>
          {profilePic ? (
            <img
              className='rounded-full h-40 w-40'
              src={profilePic}
              alt='Profile'
            />
          ) : (
            <div className='bg-gradient-to-r from-indigo-500 via-violet-600 to-blue-500 rounded-full p-4 h-40 w-40 flex items-center justify-center text-white text-4xl'>
              {getInitials(firstName, lastName, name)}
            </div>
          )}
          <div className='absolute -bottom-2 flex items-center justify-center w-full'>
            <div className='bg-gray-200 px-4 py-2 rounded-full text-xs'>
              {type || role}
            </div>
          </div>
        </div>
        <div className='text-2xl font-bold'>{userName}</div>
        <div>
          <h2 className='text-xl font-semibold mb-4'>User Information</h2>
          {userInfoItems.map((item, index) => (
            <div key={index} className='mb-4 flex items-center'>
              <h3 className='text-sm font-medium text-gray-600 w-[30%]'>
                {item.label}
              </h3>
              <p className='text-sm text-gray-800 ml-4 w-[70%]'>{item.value}</p>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
}
