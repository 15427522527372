import { createSlice } from "@reduxjs/toolkit";
import { getFromLocalStorage, setToLocalStorage } from "../../../helper";
import {
  fetchTrips,
  initiatePayment,
  fetchPaymentProviders,
  initiateBookCallback,
  fetchTripsById,
  downloadEticket,
  deleteBookingById,
  updatePaymentStatus,
} from "./trips.actions";
import { CACHE_KEYS, DEFAULT_VALUES, TRIPS_CATEGORIES } from "../../../constants";

const { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING, ZERO, NEG_ONE } = DEFAULT_VALUES;
const { TIMELOG, SHOW_TIMER, TRIP_TIME_EXPIRY } = CACHE_KEYS;
const tripTimeExpiry = getFromLocalStorage(TRIP_TIME_EXPIRY);

const initialState = {
  selectedCategoryTrips: {
    countInfo: EMPTY_OBJECT,
    result: EMPTY_ARRAY,
  },
  selectedTrip: null,
  selectedTripId: null,
  paymentAPIResponse: null,
  paymentProviders: EMPTY_ARRAY,
  walletStatus: null,
  bookCallbackResponse: null,
  downloadEticket: EMPTY_OBJECT,
  tripCategory: TRIPS_CATEGORIES[ZERO].key,
  searchTimeLog: [],
  showTimer: [],
  showTimerExpireBanner: tripTimeExpiry ? tripTimeExpiry : [],
  selectedUserInfo: {},
  selectedHotelViewMore: {},
  currentPage: 1,
  searchKey: EMPTY_STRING,
  showStatusUpdate: false,
  newTripDetail: EMPTY_OBJECT,
};

const slice = createSlice({
  name: "trips",
  initialState,
  reducers: {
    setSelectedTripDetail: (state, action) => {
      state.newTripDetail = action.payload;
    },
    setSelectedTrip: (state, action) => {
      state.selectedTrip = action.payload;
    },
    setSelectedTripId: (state, action) => {
      state.selectedTripId = action.payload;
    },
    setBookCallbackResponse(state, action) {
      state.bookCallbackResponse = action.payload;
    },
    setTripCategory(state, action) {
      state.tripCategory = action.payload;
    },
    setSearchTimeLog: (state, action) => {
      if(!action.payload) return
      const { id, type, timer } = action.payload;
      const logExists = state.searchTimeLog?.some(
        (logEntry) => logEntry?.id === id
      );
      if (!logExists) {
        const newLogEntry = {
          id: id,
          logs: [{ type: type, startTime: timer }],
        };
        state.searchTimeLog.push(newLogEntry);
      } else {
        state.searchTimeLog.forEach((logEntry) => {
          if (logEntry?.id === id) {
            const logIndex = logEntry.logs.findIndex(
              (log) => log.type === type
            );
            if (logIndex !== NEG_ONE) {
              logEntry.logs[logIndex] = {
                type: type,
                startTime: timer,
              };
            } else {
              logEntry.logs.push({
                type: type,
                startTime: timer,
              });
            }
          }
        });
      }
      setToLocalStorage(TIMELOG, state.searchTimeLog);
    },
    deleteSearchTimeLog: (state, action) => {
      state.searchTimeLog = state.searchTimeLog.filter(
        (logEntry) => logEntry.id !== action.payload
      );
      setToLocalStorage(TIMELOG, state.searchTimeLog);
    },
    setShowTimer: (state, action) => {
      const { id, showTime } = action.payload;
      const timerExists = state.showTimer?.some((timer) => timer.id == id);
      if (!timerExists)
        state.showTimer = [
          ...state.showTimer,
          {
            id: id,
            showTime: showTime,
          },
        ];
      setToLocalStorage(SHOW_TIMER, state.showTimer);
    },
    deleteShowTimer: (state, action) => {
      state.showTimer = state.showTimer.filter(
        (timer) => timer.id !== action.payload
      );
      setToLocalStorage(SHOW_TIMER, state.showTimer);
    },

    setShowTimerExpireBanner: (state, action) => {
      const { id, flight, hotel } = action.payload;
      const exists = state.showTimerExpireBanner?.some(
        (logEntry) => logEntry?.id === id
      );
      if (!exists) {
        const newEntry = {
          id: id,
          timeExpiryStatus: {
            flight: flight ?? false,
            hotel: hotel ?? false,
          },
        };
        state.showTimerExpireBanner.push(newEntry);
      } else {
        state.showTimerExpireBanner = state.showTimerExpireBanner.map(
          (logEntry) => {
            if (logEntry.id === id) {
              const updatedBookType = {
                flight: flight ?? logEntry.timeExpiryStatus.flight,
                hotel: hotel ?? logEntry.timeExpiryStatus.hotel,
              };
              return {
                ...logEntry,
                timeExpiryStatus: updatedBookType,
              };
            }
            return logEntry;
          }
        );
      }
      setToLocalStorage(TRIP_TIME_EXPIRY, state.showTimerExpireBanner);
    },

    deleteShowTimerBanner: (state, action) => {
      state.showTimerExpireBanner = state.showTimerExpireBanner.filter(
        (logEntry) => logEntry.id !== action.payload
      );
      setToLocalStorage(TRIP_TIME_EXPIRY, state.showTimerExpireBanner);
    },
    setSelectedUserInfo: (state, action) => {
      state.selectedUserInfo = action.payload
    },
    setSelectedHotelViewMore: (state, action) => {
      state.selectedHotelViewMore = action.payload
    },
    setCurrentPage: (state,action)=>{
      state.currentPage=action.payload;
    },
    setSearchKey: (state,action) => {
      state.searchKey=action.payload;
    },
    setTripData(state, action) {
      if (action.payload) return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrips.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.selectedCategoryTrips = action.payload;
    });
    builder.addCase(fetchTripsById.fulfilled, (state, action) => {
      if (action.payload) state.selectedTrip = action.payload;
    });
    builder.addCase(initiatePayment.fulfilled, (state, action) => {
      if (action.payload) state.paymentAPIResponse = action.payload;
    });
    builder.addCase(fetchPaymentProviders.fulfilled, (state, action) => {
      if (action.payload) state.paymentProviders = action.payload;
    });
    builder.addCase(initiateBookCallback.fulfilled, (state, action) => {
      if (action.payload) state.bookCallbackResponse = action.payload;
    });
    builder.addCase(downloadEticket.fulfilled, (state, action) => {
      if (action.payload) state.downloadEticket = action.payload;
    });
    builder.addCase(updatePaymentStatus.fulfilled, (state, action) => {
      if (action.payload) state.showStatusUpdate = action.payload;
    })
    builder.addCase(deleteBookingById.fulfilled, (state, action) =>{
      if (!action.payload) return;
      const updatedTrips = state.selectedCategoryTrips.result.filter(
        (trip) => trip.id !== action.payload.id
      );
      state.selectedCategoryTrips = {
        ...state.selectedCategoryTrips,
        result: updatedTrips,
        countInfo : {
          ...state.countInfo,
          count: state.selectedCategoryTrips.countInfo.count - 1,
          currentPage: state.selectedCategoryTrips.countInfo.currentPageCount === 1 ? state.selectedCategoryTrips.countInfo.currentPage - 1 : state.selectedCategoryTrips.countInfo.currentPage,
          totalPage: state.selectedCategoryTrips.countInfo.currentPageCount === 1 ? state.selectedCategoryTrips.countInfo.totalPage - 1 : state.selectedCategoryTrips.countInfo.totalPage,
        }
      };
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
