import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { Range } from "react-range";
import { get } from "lodash";
import { RenderSVG, ChevronDown } from "../../../assets/icons";
import { selectTenantDetails } from "../../Auth";
import { DEFAULT_VALUES } from "../../../constants";
import classNames from "classnames";
import ErrorMessage from "../../../components/atoms/ErrorMessage";

const { ZERO, ONE, TWO, HUNDRED, EMPTY_STRING } = DEFAULT_VALUES;
const MINUTES_IN_HOUR = 60;
const initialMinTime = ZERO;
const initialMaxTime = 1439;

const TimeFrameFilters = ({
  type,
  header,
  minTime,
  maxTime,
  handleTimeChange,
  flightType,
  flightSearchOptions,
}) => {
  const { t } = useTranslation();
  const tenantDetails = useSelector(selectTenantDetails);
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const [timeRangeValues, setTimeRangeValues] = useState([
    {
      hr: minTime % MINUTES_IN_HOUR,
      min: Math.floor(minTime / MINUTES_IN_HOUR),
    },
    {
      hr: maxTime % MINUTES_IN_HOUR,
      min: Math.floor(maxTime / MINUTES_IN_HOUR),
    },
  ]);

  const [sliderValues, setSliderValues] = useState([minTime, maxTime]);
  const [showError, setShowError] = useState({
    minTime: false,
    maxTime: false,
  });

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  const checkIsTimeValid = (rangeValues) => {
    const minTime = rangeValues[ZERO];
    const maxTime = rangeValues[ONE];
    if (
      minTime.hr > maxTime.hr ||
      (minTime.hr === maxTime.hr && minTime.min >= maxTime.min)
    ) {
      setShowError((prevErrors) => ({ ...prevErrors, overlap: true }));
      return false;
    } else {
      setShowError((prevErrors) => ({ ...prevErrors, overlap: false }));
      return true;
    }
  };

  useEffect(() => {
    checkIsTimeValid(timeRangeValues);
  }, [timeRangeValues]);

  useEffect(() => {
    if (minTime === initialMinTime && maxTime === initialMaxTime) {
      const min = get(flightSearchOptions, `${header}.minTime`, EMPTY_STRING);
      const max = get(flightSearchOptions, `${header}.maxTime`, EMPTY_STRING);
      setSliderValues([min, max]);
    }
  }, [minTime, maxTime]);

  useEffect(() => {
    const [left, right] = sliderValues;
    const leftHr = Math.floor(left / MINUTES_IN_HOUR);
    const leftMin = left % MINUTES_IN_HOUR;
    const rightHr = Math.floor(right / MINUTES_IN_HOUR);
    const rightMin = right % MINUTES_IN_HOUR;

    setTimeRangeValues([
      { hr: leftHr, min: leftMin },
      { hr: rightHr, min: rightMin },
    ]);
  }, [sliderValues]);

  const handleSliderUpdate = (values) => {
    setSliderValues(values);
    handleTimeChange({
      filter: {
        minTime: values[ZERO],
        maxTime: values[ONE],
      },
      flightType,
      type,
    });
  };

  const handleMinValueChange = (timePart, value) => {
    const newTimeRangeValues = [
      { ...timeRangeValues[ZERO], [timePart]: +value },
      timeRangeValues[ONE],
    ];

    setTimeRangeValues(newTimeRangeValues);

    const timeInMinutes =
      newTimeRangeValues[ZERO].hr * MINUTES_IN_HOUR +
      newTimeRangeValues[ZERO].min;
    if (timeInMinutes >= initialMinTime && timeInMinutes <= initialMaxTime) {
      checkIsTimeValid(newTimeRangeValues) &&
        handleSliderUpdate([timeInMinutes, sliderValues[ONE]]);
      setShowError({ ...showError, minTime: false });
    } else setShowError({ ...showError, minTime: true });
  };

  const handleMaxValueChange = (timePart, value) => {
    const newTimeRangeValues = [
      timeRangeValues[ZERO],
      { ...timeRangeValues[ONE], [timePart]: +value },
    ];
    setTimeRangeValues(newTimeRangeValues);

    const timeInMinutes =
      newTimeRangeValues[ONE].hr * MINUTES_IN_HOUR +
      newTimeRangeValues[ONE].min;

    if (timeInMinutes >= initialMinTime && timeInMinutes <= initialMaxTime) {
      checkIsTimeValid(newTimeRangeValues) &&
        handleSliderUpdate([sliderValues[ZERO], timeInMinutes]);
      setShowError({ ...showError, maxTime: false });
    } else setShowError({ ...showError, maxTime: true });
  };

  const getTrackBackground = ({ colors, min, max }) => {
    const progress = sliderValues
      .slice(ZERO)
      .sort((a, b) => a - b)
      .map((value) => ((value - min) / (max - min)) * HUNDRED);

    const middle = progress.reduce(
      (acc, point, index) =>
        `${acc}, ${colors[index]} ${point}%, ${colors[index + ONE]} ${point}%`,
      ""
    );

    return `linear-gradient(to right, ${colors[ZERO]} 0% ${middle}, ${
      colors[colors.length - ONE]
    } 100%)`;
  };

  const convertToTime = (minutes) => {
    const hours = Math.floor(minutes / MINUTES_IN_HOUR);
    const mins = minutes % MINUTES_IN_HOUR;
    return `${String(hours).padStart(TWO, "0")}:${String(mins).padStart(
      TWO,
      "0"
    )}`;
  };

  return (
    <>
      <div className='flex items-center gap-2' {...getToggleProps()}>
        <h4 className='flex-1 text-lg font-semibold text-contrast-800'>
          {t(`flightResults.filters.${header}.header`)}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt='Chevron Down Icon'
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>

      <div {...getCollapseProps()}>
        <div className='pt-6'>
          <div className='range-slider mt-10 relative h-1 mb-6'>
            <div className='slider-main h-full absolute w-full px-2'>
              <Range
                min={get(flightSearchOptions, "arrivalTime.minTime", ZERO)}
                max={get(
                  flightSearchOptions,
                  "departureTime.maxTime",
                  initialMaxTime
                )}
                values={sliderValues}
                onChange={handleSliderUpdate}
                renderTrack={({
                  props: { onMouseDown, onTouchStart, ref },
                  children,
                }) => (
                  <div onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
                    <div
                      ref={ref}
                      className='h-1 mx-4 rounded bg-contrast-300 self-center'
                      style={{
                        background: getTrackBackground({
                          colors: [
                            "#D1D5DB",
                            tenantConfig.primaryColor,
                            "#D1D5DB",
                          ],
                          min: get(
                            flightSearchOptions,
                            "arrivalTime.minTime",
                            initialMaxTime
                          ),
                          max: get(
                            flightSearchOptions,
                            "departureTime.maxTime",
                            ZERO
                          ),
                        }),
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, index }) => (
                  <div
                    {...props}
                    className='h-4 w-4 bg-white flex justify-center items-center rounded-[50%] border border-solid border-contrast-300 !cursor-pointer'
                  >
                    <div className='absolute tooltip tooltip-left !translate-x-0 whitespace-nowrap'>
                      {convertToTime(sliderValues[index])}
                    </div>
                    <div />
                  </div>
                )}
              />
            </div>
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <div className='col-span-1'>
              <div className='form-group'>
                <label className='text-xs text-contrast-900 font-medium mb-1'>
                  {t(`flightResults.filters.${header}.earliest`)}
                </label>
                <div className='flex rounded-md items-center shadow-sm border border-contrast-300 text-contrast-900 overflow-hidden'>
                  <input
                    type='text'
                    placeholder='Earliest Time'
                    value={timeRangeValues[ZERO].hr || ZERO}
                    onChange={(e) => handleMinValueChange("hr", e.target.value)}
                    className='w-full flex-1 h-auto text-xs py-[9px] px-3 border-none outline-none focus:ring-0 text-right'
                  />
                  <span className='mt-1'> : </span>
                  <input
                    type='text'
                    placeholder='Earliest Time'
                    value={timeRangeValues[ZERO].min || ZERO}
                    onChange={(e) =>
                      handleMinValueChange("min", e.target.value)
                    }
                    className='w-full flex-1 h-auto text-xs py-[9px] px-3 border-none outline-none focus:ring-0 '
                  />
                </div>
              </div>
            </div>
            <div className='col-span-1'>
              <div className='form-group'>
                <label className='text-xs text-contrast-900 font-medium mb-1'>
                  {t(`flightResults.filters.${header}.latest`)}
                </label>
                <div className='flex items-center rounded-md shadow-sm border border-contrast-300 text-contrast-900 overflow-hidden'>
                  <input
                    type='text'
                    placeholder='Earliest Time'
                    value={timeRangeValues[ONE].hr || ZERO}
                    onChange={(e) => handleMaxValueChange("hr", e.target.value)}
                    className='w-full flex-1 h-auto text-xs py-[9px] px-3 border-none outline-none focus:ring-0 text-right'
                  />
                  <span className='mt-1'> : </span>
                  <input
                    type='text'
                    placeholder='Earliest Time'
                    value={timeRangeValues[ONE].min || ZERO}
                    onChange={(e) =>
                      handleMaxValueChange("min", e.target.value)
                    }
                    className='w-full flex-1 h-auto text-xs py-[9px] px-3 border-none outline-none focus:ring-0'
                  />
                </div>
              </div>
            </div>
          </div>
          {(showError.minTime || showError.overlap || showError.maxTime) && (
            <ErrorMessage
              errorMessage={
                showError.minTime || showError.maxTime
                  ? "Invalid Time Format"
                  : "The earliest time should be less than the latest time."
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TimeFrameFilters;
