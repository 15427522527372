import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Cross, RenderSVG } from "../../../../assets/icons";
import Modal from "../../Modal";
import InputField from "../../../molecules/InputField";
import MultiSelectField from "../../../molecules/MultiSelectField/MultiSelectField";
import { MODAL_SIZE } from "../../../../constants";
import { DEFAULT_VALUES, REGEX } from "../../../../constants";
import Asterisk from "../../../atoms/Asterisk";
const { MD } = MODAL_SIZE;
const { EMPTY_STRING,FIVE, TWENTY_FIVE, HUNDRED  } = DEFAULT_VALUES
const { WHITE_SPACES_DETECTION_REGEX, COST_CODE_VALIDATION_REGEX } = REGEX;

// ToDo:  Department category will be added later
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(WHITE_SPACES_DETECTION_REGEX, "Cost code name cannot be just spaces")
    .trim()
    .min(FIVE, "Cost code name must be at least 5 characters")
    .matches(COST_CODE_VALIDATION_REGEX, "Cost code name can conatins only alphabets(a-z,A-Z), digits(0-9), dash(-) and underscore(_)")
    .max(TWENTY_FIVE, "Cost code name must be atmost 25 characters")
    .required("Cost code name is required"),
  costCode: Yup.string()
    .matches(WHITE_SPACES_DETECTION_REGEX, "Cost code value cannot be just spaces")
    .trim()
    .min(FIVE, "Cost code must be at least 5 characters")
    .matches(COST_CODE_VALIDATION_REGEX, "Cost code can conatins only alphabets(a-z,A-Z), digits(0-9), dash(-) and underscore(_)")
    .max(TWENTY_FIVE, "Cost code must be atmost 25 characters")
    .required("Cost code value is required"),
  description: Yup.string()
    .matches(WHITE_SPACES_DETECTION_REGEX, "Cost code description cannot be just spaces")
    .trim()
    .min(FIVE, "Description must be at least 5 characters")
    .max(HUNDRED, "Cost code description must be atmost 100 characters")
    .required("Cost code description is required"),
  // departmentVisibility: Yup.array().required("Select at least one Department"),
});

const EditCostCodeModel = ({
  title = EMPTY_STRING,
  primaryButtonText = "Update",
  handleClose,
  handleSubmit,
  savedInitialValues = {},
  availableDepartments = [],
}) => (
  <Modal
    size={MD}
    shouldShowModalFromProps
    handleClose={handleClose}
    modalClassname='overflow-visible'
  >
    <div className='flex flex-col divide-y divide-contrast-300'>
      <div className='flex justify-between items-center p-6'>
        <div className='text-2xl leading-8 font-bold'>{title}</div>
        <div>
          <button onClick={handleClose}>
            <RenderSVG Svg={Cross} className='text-contrast-900' />
          </button>
        </div>
      </div>
      <Formik
        initialValues={savedInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ dirty, isValid }) => (
          <Form>

            <div className='px-6 pt-4 border-y border-contrast-300'>
              <InputField
                type='text'
                name='name'
                label={
                  <>
                    Name<Asterisk />
                  </>
                }
                placeholder='Enter Name'
              />
              <InputField
                type='text'
                name='costCode'
                label={
                  <>
                    Code<Asterisk />
                  </>
                }
                placeholder='Enter Code'
              />
              <InputField
                type='text'
                name='description'
                label={
                  <>
                  Description<Asterisk />
                  </>
                }
                placeholder='Enter Description'
              />
              {/* <MultiSelectField
                label={"Visible to Departments"}
                name={"departmentVisibility"}
                selectableValues={availableDepartments}
              /> */}
            </div>
            <div>
              <div className='flex gap-3 justify-end p-6'>
                <button
                  type='button'
                  className='rounded-md shadow-sm px-4 py-2 border border-contrast-300 text-sm leading-5'
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='rounded-md shadow-sm px-4 py-2 bg-primary-600 text-white text-sm leading-5 disabled:cursor-not-allowed disabled:opacity-75'
                  disabled={!dirty || !isValid}
                >
                  {primaryButtonText}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </Modal>
);

export default EditCostCodeModel;
