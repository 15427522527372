import { get, isEmpty } from "lodash";
import moment from "moment";
import { DEFAULT_VALUES } from "../../constants";

const { EMPTY_STRING, ZERO, ONE, TWO } = DEFAULT_VALUES;
const travelerMapping = { 1: "Adult", 2: "Child", 3: "Infant" };
const source = "TBO";
const cabinClassMapping = {
  1: "All",
  2: "Economy",
  3: "Economy Premium",
  4: "Business",
  5: "Premium Business",
  6: "First",
};

const formatMinutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${String(hours).padStart(TWO, "0")}:${String(
    remainingMinutes
  ).padStart(TWO, "0")}`;
};

const getTimeAndDate = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  const date = dateTime.format("YYYY-MM-DD");
  const time = dateTime.format("HH:mm");
  return { date, time };
};

const getUpdatedItineraries = (itineraries) =>
  itineraries.reduce((updatedItineraries, itinerary) => {
    let duration = ZERO;
    const segments = itinerary.map(
      ({
        Airline,
        Baggage,
        Origin,
        Destination,
        Duration,
        NoOfSeatAvailable,
        CabinClass,
        CabinBaggage,
      }) => {
        duration += Duration;
        const departure = {
          iataCode: Origin.Airport.AirportCode,
          airportName: Origin.Airport.AirportName,
          terminal: Origin.Airport.Terminal,
          ...getTimeAndDate(Origin.DepTime),
        };

        const arrival = {
          iataCode: Destination.Airport.AirportCode,
          airportName: Destination.Airport.AirportName,
          terminal: Destination.Airport.Terminal,
          ...getTimeAndDate(Destination.ArrTime),
        };

        const carrierCode = Airline.AirlineCode;
        const carrierName = Airline.AirlineName;
        const flightNumber = Airline.FlightNumber;
        const aircraftCode = EMPTY_STRING;
        const operatingCarrierCode = Airline.OperatingCarrier;
        const noOfAvailableSeats = NoOfSeatAvailable;
        const cabinClass = cabinClassMapping[CabinClass];
        const baggage = Baggage;
        const baggageDetails = {
          weight: Baggage?.replace("PC(s)", "Piece(s)"),
          cabinBaggage: CabinBaggage,
        };

        return {
          departure,
          baggage,
          arrival,
          carrierCode,
          carrierName,
          flightNumber,
          aircraftCode,
          operatingCarrierCode,
          noOfAvailableSeats,
          cabinClass,
          baggageDetails,
        };
      }
    );

    updatedItineraries.push({
      segments,
      duration: formatMinutesToTime(duration),
    });
    return updatedItineraries;
  }, []);

const getTravalerPricing = (FareBreakdown) =>
  FareBreakdown.reduce((travelerPricing, travalerFare) => {
    const { Tax, BaseFare, PassengerType, PassengerCount } = travalerFare;
    const traveler = {
      travelerType: travelerMapping[PassengerType],
      priceDetails: {
        totalPrice: BaseFare + Tax,
        taxPrice: Tax,
        basePrice: BaseFare,
      },
    };

    for (let index = ZERO; index < PassengerCount; index++) {
      const travelerId = travelerPricing.length + ONE;
      travelerPricing.push({ travelerId, ...traveler });
    }
    return travelerPricing;
  }, []);

export const getFormattedTBOResponse = (flightData, flightLength, fareType) => {
  if (isEmpty(flightData.result)) return [];

  const mappedResponse = flightData.result.map((flightResult, index) => {
    let flightIndex = flightLength[index] + ONE;

    return flightResult.reduce((updatedResponse, data) => {
      const {
        ResultIndex,
        Fare,
        Segments,
        FareBreakdown,
        IsLCC,
        IsRefundable,
        IsPanRequiredAtTicket,
        MiniFareRules,
        AirlineRemark,
        ResultFareType,
      } = data;
      if (ResultFareType !== fareType) return;
      const flightId = `${ResultIndex}${flightIndex++}`;

      const travelerPricing = getTravalerPricing(FareBreakdown);

      const price = {
        totalPrice: Fare.PublishedFare,
        taxPrice: Fare.Tax,
        basePrice: Fare.BaseFare,
        travelerPricing,
      };

      const baggage = {
        weight: get(Segments, "0.0.Baggage", EMPTY_STRING)?.replace(
          "PC(s)",
          "Piece(s)"
        ),
        cabinBaggage: get(Segments, "0.0.CabinBaggage"),
      };
      const itineraries = getUpdatedItineraries(Segments);

      updatedResponse.push([
        {
          flightId,
          resultIndex: ResultIndex,
          isLCC: IsLCC,
          isRefundable: IsRefundable,
          isPanRequiredAtTicket: IsPanRequiredAtTicket,
          price,
          itineraries,
          baggage,
          source,
          miniFareRules: MiniFareRules,
          airlineRemark: AirlineRemark,
        },
      ]);
      return updatedResponse;
    }, []);
  });

  return mappedResponse;
};
