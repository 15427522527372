import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import { isEmpty, get, debounce } from "lodash";
import {
  getAnalyticsGraphData,
  getAnalyticsGreetingsData,
  getAnalyticsSpentData,
  getAnalyticsTransactionInfo,
  getAnalyticsTripData,
  getCostCodeInfo,
  getAnalyticsAggregateData,
  selectAnalyticsAggregate,
  selectAnalyticsSpent,
  selectAnalyticsTripData,
  selectAnalyticsTransactions,
  selectAnalyticsGraphData,
  selectAnalyticsGreetings,
  selectAnalyticsQueryParams,
  setAnalyticsQueryParams,
  selectselectedTimeRange,
  setSelectedTimeRange,
  setSelectedUser,
  selectCostCodeInfo,
  selectFrequentlyUsedAirlines,
  selectFrequentlyUsedHotels,
  setFrequentlyUsedAirlines,
  setFrequentlyUsedHotels,
  selectUserUpcomingBookings,
  setUserUpcomingBookings,
} from "./index";
import { getUsersList } from "../../screens/Users/users.actions";
import { selectCountryInfo, selectUserInfo } from "../Profile";
import TotalAnalytics from "./TotalAnalytics";
import TransactionList from "./TransactionList";
import GreetingCardAnalytics from "./GreetingCardAnalytics";
import FrequentlyUsedAnalytics from "./FrequentlyUsedAnalytics";
import {ReportOverviewSkeleton, TransactionSkeleton, DonutSkeleton, LineChartSkeleton, GreetingsSkeleton, UserUpcomingBookingsSkeleton} from "../../components/organisms/AppSkeletons/DashboardSkeleton";
import ReportOverview from "./ReportOverview";
import {
  CalendarOutline,
  RenderSVG,
  ChevronDown,
  Expense,
  SetUpYourCompany,
  Check,
  TriangleExclaimation,
  InfoIcon
} from "../../assets/icons";
import { useClickOutside, getRoundOffValue, checkIfUserHasPermission } from "../../helper";
import {
  AVAILABLE_PERMISSIONS,
  ANALYTICS_PARAM,
  DATE_FORMAT,
  DEFAULT_VALUES,
  SEARCH_SECTION,
  ANALYTICS_TIME_PERIODS,
  DATE_PICKER_WEEKDAYS,
  ANALYTICS_INFO_BUTTON,
  REGEX
} from "../../constants";
import { LineChart, NoTripData } from "../../components/organisms/AppCharts";
import FilterDashboardModal from "../../components/organisms/AppModals/FilterDashboardModal/FilterDashboardModal";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import DatePicker from "react-multi-date-picker";
import { selectCurrentUserInfo, selectTenantDetails } from "../Auth";
import UserTripsBarChart from "./UserTripsBarChart";
import { selectSelectedUser } from "./dashboard.selectors";
import DonutChart from "./DonutChart";
import classNames from "classnames";
import { getFrequentAirlines, getFrequentHotels, getUserBookingsInfo, getUserUpcomingBookings } from "./dashboard.actions";
import UpcomingAndMonthlyBookings from "./UpcomingAndMonthlyBookings"
import { Tooltip } from "react-tooltip";

const { ZERO, TWO, ONE, TEN, SIX, EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;
const { FLIGHT, HOTEL } = SEARCH_SECTION;
const { WEEK_REGEX, MONTH_REGEX, YEAR_REGEX } = REGEX;
const ADMIN = "ADMIN";

const {
  CAN_VIEW_TOTAL_SPENT,
  CAN_VIEW_TOTAL_TRIPS,
  CAN_VIEW_TOTAL_BOOKINGS,
  CAN_VIEW_ANALYTICS_CHART,
  CAN_VIEW_USERS,
} = AVAILABLE_PERMISSIONS;
const {
  ANALYTICS_SPENT_DATA,
  ANALYTICS_GREETING_DATA,
  ANALYTICS_GRAPH_DATA,
  ANALYTICS_TRANSACTIONS_DATA,
  COST_CODES,
  ANALYTICS_AGGREGATE_DATA,
} = SPINNER_NAMES;
const {
  INTERVAL: { CUSTOM, MONTH, YEAR, QUARTER },
  REFERENCE_PERIOD: { CURRENT, LAST },
} = ANALYTICS_PARAM;
const { WEEKLY_BOOKINGS_TOOLTIP, MONTHLY_BOOKINGS_TOOLTIP, YEARLY_BOOKINGS_TOOLTIP } = ANALYTICS_INFO_BUTTON;

const REPORT_OVERVIEW_ANALYTICS = [
  {
    type: "hotel",
    title: "Avg Hotel Spend",
    icon: "bed",
    unit: "currency",
    value: 0,
    difference: 0,
  },
  {
    type: "flight",
    title: "Avg Flight Spend",
    icon: "flight",
    unit: "currency",
    value: 0,
    difference: 0,
  },
];

const noOfMonthsInCalendar = window.innerWidth > 750 ? TWO : ONE;

const CUSTOM_DATE = "Custom Date";

const getComparativeAnalysis = (current, last) => {
  if (current === 0) return 0;
  return (((getRoundOffValue(current) - getRoundOffValue(last)) / getRoundOffValue(current)) * 100).toFixed(2);
};


const getIntegrationStep = (permission, steps) =>
  steps?.find((step) => step.permission === permission);

const getReportOverviewInfo = (
  analyticsSpent,
  currentPeriod,
  comparativePeriod,
  showComparativeAnalytics
) =>
  REPORT_OVERVIEW_ANALYTICS.map((report) => {
    const reportType = report.type;
    const totalCurrentSpent = get(
      analyticsSpent,
      `data.${currentPeriod}.${reportType}.spent`,
      0
    );
    const totalCurrentCount = get(
      analyticsSpent,
      `data.${currentPeriod}.${reportType}.count`,
      0
    );
    const totalCurrentAvg = totalCurrentCount
      ? (totalCurrentSpent / totalCurrentCount)
      : 0;
    const totalLastSpent = get(
      analyticsSpent,
      `data.${comparativePeriod}.${reportType}.spent`,
      0
    );
    const totalLastCount = get(
      analyticsSpent,
      `data.${comparativePeriod}.${reportType}.count`,
      0
    );
    const totalLastAvg = totalLastCount
      ? getRoundOffValue(totalLastSpent / totalLastCount)
      : 0;

    const difference = showComparativeAnalytics
      ? getComparativeAnalysis(totalCurrentAvg, totalLastAvg)
      : null;

    return {
      ...report,
      value: totalCurrentAvg,
      difference,
    };
  });

const Dashboard = () => {
  const dropDownRef = useRef();
  const clickOutsideRef = useRef();
  const dispatch = useDispatch();

  const analyticsSpent = useSelector(selectAnalyticsSpent);
  const analyticsTripData = useSelector(selectAnalyticsTripData);
  const analyticsTransactions = useSelector(selectAnalyticsTransactions);
  const analyticsGraphData = useSelector(selectAnalyticsGraphData);
  const analyticsGreetings = useSelector(selectAnalyticsGreetings);
  const analyticsAggregate = useSelector(selectAnalyticsAggregate)
  const costCodeInfo = useSelector(selectCostCodeInfo);
  const { data = {} } = costCodeInfo;
  const userInfo = useSelector(selectUserInfo);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencySymbol = selectedCountryInfo?.currency?.symbol;
  const analyticsQueryParams = useSelector(selectAnalyticsQueryParams);
  const selectedTimeRange = useSelector(selectselectedTimeRange);
  const finalSelectedUser = useSelector(selectSelectedUser);
  const frequentlyUsedAirlines = useSelector(selectFrequentlyUsedAirlines);
  const frequentlyUsedHotels = useSelector(selectFrequentlyUsedHotels);
  const userUpcomingBookings = useSelector(selectUserUpcomingBookings);
  const currentUserInfo = useSelector(selectCurrentUserInfo);

  const tenantDetails = useSelector(selectTenantDetails);
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const datePickerRef = useRef();

  const [updatedAnalyticsInfo, setUpdatedAnalyticsInfo] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [shouldShowFilterModal, setShouldShowFilterModal] = useState(false);
  const [customDates, setCustomDates] = useState([]);
  const [disableApplyCustomDate, setDisableApplyCustomDate] = useState(true);

  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const userDropdownRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const { logo = "" } = tenantConfig;
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(finalSelectedUser);
  const [isTenantChecked, setIsTenantChecked] = useState(false);
  const [canViewUsers, setCanViewUsers] = useState(false);
  const [currentUserBookings, setCurrentUserBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const customDataSetLabelType = (label) => {
    const trimmedLabel = label.trim();
    if (WEEK_REGEX.test(trimmedLabel)) {
      return WEEKLY_BOOKINGS_TOOLTIP;
    } else if ( YEAR_REGEX.test(trimmedLabel)) {
      return YEARLY_BOOKINGS_TOOLTIP;
    } else if ( MONTH_REGEX.test(trimmedLabel)) {
      return MONTHLY_BOOKINGS_TOOLTIP;
    } else {
      return EMPTY_STRING;
    }
  };
  
  const tooltipMessage = () => {
    if (ANALYTICS_TIME_PERIODS[SIX]?.name === selectedTimeRange) {
      if (updatedAnalyticsInfo?.analyticsChart?.labels?.length > ZERO)
        return customDataSetLabelType(
          updatedAnalyticsInfo?.analyticsChart?.labels[ZERO]
        );
      else return EMPTY_STRING;
    } else {
      return [
        ANALYTICS_TIME_PERIODS[ZERO]?.name,
        ANALYTICS_TIME_PERIODS[ONE]?.name,
      ].includes(selectedTimeRange)
        ? WEEKLY_BOOKINGS_TOOLTIP
        : MONTHLY_BOOKINGS_TOOLTIP;
    }
  };
  const {
    id = "",
    role = "",
    name = "",
    profilePic = "",
    tenant = {},
  } = userInfo;
  const HasTenantChecked =
    finalSelectedUser.length === ONE && !isEmpty(finalSelectedUser[0]?.name) && !isEmpty(tenant) &&  finalSelectedUser[0].name === tenant.name;
  const [isDateCustom, setIsDateCustom] = useState(false);
  useClickOutside(dropDownRef, setIsDropdownOpen);
  const handleDropdownIconClick = () => setIsDropdownOpen(!isDropdownOpen);
  const pageRef = useRef(1);
  const searchInputRef = useRef(searchInput);
  const hasMoreRef = useRef(true);
  const isFetchingRef = useRef(false);

  useEffect(() => {
    if (finalSelectedUser.length === 0) return;
    const userIds = finalSelectedUser.map((u) => u.userId);
    const shouldPassUserId = !(userIds.length === 1 && userIds[0] === '');
    const userId = shouldPassUserId ? userIds : [currentUserInfo.id];
    if (analyticsQueryParams) {
        dispatch(
            getAnalyticsSpentData({ ...analyticsQueryParams,...(shouldPassUserId && { userId: userIds }), })
          );
        dispatch(
          getAnalyticsTripData({ ...analyticsQueryParams, ...(shouldPassUserId && { userId: userIds }),})
        );
        dispatch(
          getAnalyticsTransactionInfo({ ...analyticsQueryParams, ...(shouldPassUserId && { userId: userIds }), })
        );
        dispatch(
          getAnalyticsGraphData({ ...analyticsQueryParams,...(shouldPassUserId && { userId: userIds }), })
        );
        dispatch(
          getAnalyticsAggregateData({ ...analyticsQueryParams,...(shouldPassUserId && { userId: userIds }),})
        )
        dispatch(
          getCostCodeInfo({
            ...analyticsQueryParams,
            ...(shouldPassUserId && { userId: userIds }),
          })
        );
        if((userIds.length === 1)&&(userIds[0] ===id))
            dispatch(
              getAnalyticsGreetingsData({ ...analyticsQueryParams, userId: userIds })
            );
            dispatch(
              getUserBookingsInfo({
                ...analyticsQueryParams,
                userId,
              })
            ).then((res) => {
              setCurrentUserBookings(res?.payload?.data);
            });
        if (!shouldPassUserId) {
            dispatch(
                getFrequentAirlines({ ...analyticsQueryParams })
              )
            dispatch(
                getFrequentHotels({ ...analyticsQueryParams })
              )
            dispatch(setUserUpcomingBookings(null));
            dispatch(getUserUpcomingBookings())
        }
        else if (shouldPassUserId) {
            dispatch(setFrequentlyUsedAirlines(null));
            dispatch(setFrequentlyUsedHotels(null));
            dispatch(
                getUserUpcomingBookings({ userId: userIds })
            )
        }
    }

  }, [analyticsQueryParams, finalSelectedUser]);

  useEffect(() => {
    const { permissions = [] } = userInfo || {};
    setCanViewUsers(checkIfUserHasPermission(CAN_VIEW_USERS, permissions));
    if (
      !isEmpty(analyticsQueryParams)
    ) {
      analyticsQueryParams.interval === CUSTOM ? setIsDateCustom(true) : setIsDateCustom(false);

      const showComparativeAnalytics = analyticsQueryParams.interval !== CUSTOM;
      const currentReferencePeriod =
        analyticsQueryParams.referencePeriod === CURRENT ||
        analyticsQueryParams.interval === CUSTOM
          ? CURRENT
          : LAST;
      const comparativeReferencePeriod =
        analyticsQueryParams.referencePeriod === CURRENT ? LAST : CURRENT;

      const reportOverview = getReportOverviewInfo(
        analyticsSpent,
        currentReferencePeriod,
        comparativeReferencePeriod,
        showComparativeAnalytics
      );

      let dashboardAnalyticsInfo = {
        totals: {},
        analyticsChart: {
          data: [],
          labels: [],
        },
        reportOverview,
      };

      permissions.forEach((permission) => {
        switch (permission) {
          case CAN_VIEW_TOTAL_SPENT: {
            const current = (
              get(analyticsSpent, `data.${currentReferencePeriod}.spent`, 0)
            );
            const last = (
              get(analyticsSpent, `data.${comparativeReferencePeriod}.spent`, 0)
            );

            const spent = {
              value: current,
              difference: showComparativeAnalytics
                ? getComparativeAnalysis(current, last)
                : null,
            };

            dashboardAnalyticsInfo = {
              ...dashboardAnalyticsInfo,
              totals: {
                ...dashboardAnalyticsInfo.totals,
                spent,
              },
            };
            break;
          }
          case CAN_VIEW_TOTAL_TRIPS: {
            const current = get(
              analyticsTripData,
              `data.${currentReferencePeriod}`,
              0
            );
            const last = get(
              analyticsTripData,
              `data.${comparativeReferencePeriod}`,
              0
            );
            const trips = {
              value: current,
              difference: showComparativeAnalytics
                ? getComparativeAnalysis(current, last)
                : null,
            };
            dashboardAnalyticsInfo = {
              ...dashboardAnalyticsInfo,
              totals: {
                ...dashboardAnalyticsInfo.totals,
                trips,
              },
            };
            break;
          }
          case CAN_VIEW_TOTAL_BOOKINGS: {
            const current =
              get(
                analyticsSpent,
                `data.${currentReferencePeriod}.flight.count`,
                0
              ) +
              get(
                analyticsSpent,
                `data.${currentReferencePeriod}.hotel.count`,
                0
              );
            const last =
              get(
                analyticsSpent,
                `data.${comparativeReferencePeriod}.flight.count`,
                0
              ) +
              get(
                analyticsSpent,
                `data.${comparativeReferencePeriod}.hotel.count`,
                0
              );

            const bookings = {
              value: current,
              difference: showComparativeAnalytics
                ? getComparativeAnalysis(current, last)
                : null,
            };
            dashboardAnalyticsInfo = {
              ...dashboardAnalyticsInfo,
              totals: {
                ...dashboardAnalyticsInfo.totals,
                bookings,
              },
            };
            break;
          }
          case CAN_VIEW_ANALYTICS_CHART: {
            const labels = get(analyticsAggregate, "data.current", []).map(
              (info) => info.period
            );
            const data = get(analyticsAggregate, "data.current", []).map(
              (info) => info.count
            );

            dashboardAnalyticsInfo = {
              ...dashboardAnalyticsInfo,
              analyticsChart: {
                labels,
                data,
              },
            };

            const userData =
            analyticsGraphData?.data?.[analyticsQueryParams.referencePeriod] ??
            [];
            dashboardAnalyticsInfo = {
              ...dashboardAnalyticsInfo,
              tripsPerUserChart: {data: userData}
            }
            break;
          }
          default:
            break;
        }
      });
      setUpdatedAnalyticsInfo(dashboardAnalyticsInfo);
      setIsLoading(false);
    }
  }, [analyticsTripData, analyticsAggregate, analyticsGraphData, analyticsSpent, userInfo, analyticsQueryParams]);

  const handleIntervalSelection = (name, interval, referencePeriod) => {
    dispatch(setSelectedTimeRange(name));
    setIsDropdownOpen(false);

    if (interval === CUSTOM) {
      setCustomDates([]);
      datePickerRef.current?.openCalendar();
    } else dispatch(setAnalyticsQueryParams({ interval, referencePeriod }));
  };

  const onApplyCustomDate = () => {
    if (customDates.length > 1) {
      const startDate = customDates[0].format("YYYY-MM-DD");
      const endDate = customDates[1].format("YYYY-MM-DD");

      dispatch(
        setAnalyticsQueryParams({ interval: CUSTOM, startDate, endDate })
      );
    }
    datePickerRef.current.closeCalendar();
  };

  const handleCustomDateSelection = (selectedDates) => {
    if (selectedDates.length > 1) {
      setCustomDates(selectedDates);
      setDisableApplyCustomDate(false);
    } else setDisableApplyCustomDate(true);
  };

  const renderRangeText = () => (
    <div className='text-xs md:text-xs w-fit font-medium'>
      {selectedTimeRange === CUSTOM_DATE && !!customDates.length
        ? `${customDates[0].format("YYYY-MM-DD")} to ${customDates[1].format(
            "YYYY-MM-DD"
          )}`
        : selectedTimeRange}
    </div>
  );

  const handleUserDropdownClick = () => {
    if (role === ADMIN) {
      setShowUserDropdown(!showUserDropdown);
    }
  };
  const handleTenantCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (!isChecked) { 
      setSelectedUsers(EMPTY_OBJECT);
      return;
    }
    setIsTenantChecked(isChecked);
    setSelectedUsers([{ name: tenant?.name, profilePic: logo, userId: "" }]);
    setSearchInput("");
  };

  const handleUserCheckboxChange = (user, isChecked) => {
    if (isChecked) {
      setSelectedUsers((prev) => {
        const isUserAlreadySelected = Array.isArray(prev) && prev.some(
          (finalSelectedUser) => finalSelectedUser.userId === user.id
        );

        if (isUserAlreadySelected) {
          return prev;
        }
        const userName = user.name || `${user.firstName || ''} ${user.lastName || ''}`.trim();
        return [
          ...(Array.isArray(prev) ? prev.filter((finalSelectedUser) => finalSelectedUser.userId !== EMPTY_STRING) : EMPTY_ARRAY),
          { name: userName, profilePic: user.profilePic, userId: user.id },
        ];
      });
      setIsTenantChecked(false);
    } else {
      setSelectedUsers((prev) =>
        Array.isArray(prev) ? prev.filter((finalSelectedUser) => finalSelectedUser.userId !== user.id) : EMPTY_ARRAY
      );
    }
  };

  const fetchUsers = useCallback(() => {
    if (!hasMoreRef.current || isFetchingRef.current) {
      if (!searchInputRef.current) {
        if (!users.length) {
          pageRef.current = ONE;
          hasMoreRef.current = true;
        } else pageRef.current += ONE;
      } else return;
    }
    isFetchingRef.current = true;
    dispatch(
      getUsersList({
        pageNumber: pageRef.current,
        pageSize: 10,
        searchKey: searchInputRef.current,
        action: "DASHBOARD_USER_SEARCH",
      })
    ).then((response) => {
      const fetchedUsers = response?.payload?.data || [];
      if (fetchedUsers.length < TEN) {
        hasMoreRef.current = false;
      }
      if (pageRef.current === ONE && !!searchInputRef.current)
        setUsers(fetchedUsers);
      else if (isEmpty(users) && !searchInput) setUsers(fetchedUsers);
      else {
        setUsers((prevResults) => {
          const uniqueUsers = new Set([...prevResults, ...fetchedUsers]);
          return Array.from(uniqueUsers);
        });
      }
      isFetchingRef.current = false;
      pageRef.current = pageRef.current + ONE;
    });
  }, [dispatch, searchInputRef, hasMoreRef, isFetchingRef, pageRef]);

  // Initialize debounce once without dependencies to avoid recreations
  const debouncedFetchUsers = useRef(debounce(fetchUsers, 300)).current;

    useEffect(() => {
      searchInputRef.current = searchInput;
      debouncedFetchUsers.cancel();
      canViewUsers && debouncedFetchUsers();
    }, [searchInput, debouncedFetchUsers, canViewUsers]);

  const handleScroll = () => {
    if (userDropdownRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = userDropdownRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 10) {
        debouncedFetchUsers();
      }
    }
  };

  useEffect(() => {
    if (showUserDropdown && userDropdownRef.current) {
      userDropdownRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (userDropdownRef.current) {
        userDropdownRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [showUserDropdown, handleScroll]);

  const usersDetailSearchHandler = () => {
    if (JSON.stringify(finalSelectedUser) !== JSON.stringify(selectedUsers)) {
      dispatch(setSelectedUser(selectedUsers));
      setShowUserDropdown(false);
    }
  }

  useEffect(() => {
    if (isEmpty(finalSelectedUser)) {
        if (role === ADMIN) {
            setIsTenantChecked(true);
            dispatch(
              setSelectedUser([{ name: tenant?.name, profilePic: logo, userId: "" }])
            );
        } else dispatch(setSelectedUser([{ name, profilePic, userId: id }]));
    }
  }, [tenant?.name, logo, finalSelectedUser]);
  useEffect(() => {
    if (selectedUsers.length === 0 && role === "ADMIN") {
      setIsTenantChecked(true);
      setSelectedUsers([{ name: tenant?.name, profilePic: logo, userId: "" }]);
    }
  }, [selectedUsers, role]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (clickOutsideRef.current && !clickOutsideRef.current.contains(event.target)) {
        setShowUserDropdown(false);
        setSearchInput("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedUsers]);

  return (
    <div>
      {!isEmpty(updatedAnalyticsInfo) || isLoading ?  (
        <div className='flex flex-col items-start self-stretch gap-4 px-8 mt-4'>
          {/* Commenting the component for future use */}
          {/* <IntegrationSteps analyticsInfo={updatedAnalyticsInfo} /> */}
          <div className='flex flex-col pb-6 items-start gap-5 self-stretch bg-[#F5F6F8] border border-contrast-300 rounded-lg'>
            <div className='w-full flex flex-col md:flex-row p-4 justify-between item-start md:items-center bg-white rounded-t-lg border-b-contrast-300 border md:gap-0 gap-2'>
              <div className='flex items-center gap-2 font-medium text-xs md:text-base lg:text-4xl'>
                <div className='col-span-1 rounded-full p-3 flex justify-center items-center bg-primary-50'>
                  <RenderSVG Svg={Expense} className="none bg-primary-600 p-1 rounded-lg" color="white" width="30" height="30" />
                </div>
                Expense Metrics
              </div>

              <div className="flex flex-row gap-4">
                <div className='relative'>
                  <div
                    className='relative flex py-2 pr-3 pl-5 gap-2 w-auto justify-center items-center border border-contrast-300 rounded-lg bg-white cursor-pointer'
                    onClick={handleUserDropdownClick}
                  >
                    {finalSelectedUser && finalSelectedUser.length > 0 && (
                      <div className='flex items-center'>
                        {finalSelectedUser.slice(0, 3).map((user, index) => (
                          <div key={user.userId} className='group relative -ml-2'>
                            {user.profilePic ? (
                              <img
                                src={user.profilePic}
                                alt={user.name}
                                className='w-6 h-6 rounded-full border border-white '
                              />
                            ) : (
                              <img className="w-6 h-6 rounded-full border border-white" src={`https://robohash.org/${user.name}.png?size=500x500&&bgset=bg2`} alt='user-icon' />
                            )}
                            <div className="bg-black z-10 w-fit text-nowrap text-xs text-white rounded-md p-1 absolute -right-3 -top-6 hidden group-hover:block">{user.name}</div>
                          </div>
                        ))}
                        {[... new Set(finalSelectedUser)].length > 3 && (
                          <div className='w-6 h-6 rounded-full bg-gray-200 border border-white flex items-center justify-center text-xs font-medium'>
                            +{finalSelectedUser.length - 3}
                          </div>
                        )}
                      </div>
                    )}

                    {role === ADMIN ? (
                      <RenderSVG
                        Svg={ChevronDown}
                        className='w-3'
                        alt='show options'
                      />
                    ):<span className='w-2'></span>}
                  </div>
                  {showUserDropdown && (
                    <div
                      ref={clickOutsideRef}
                      className='absolute right-0 z-10 top-[50px] rounded-lg shadow-2xl bg-white px-3 pt-3 border w-64 border-r-0 border-contrast-300'
                    >
                      <div ref={userDropdownRef} className="relative max-h-80 overflow-y-auto ">
                        <div className="sticky top-0 z-10 bg-white">
                          <input
                            type='text'
                            value={searchInput}
                            onChange={(e) => {
                                pageRef.current = 1;
                                hasMoreRef.current = true;
                                setSearchInput(e.target.value)
                            }}
                            placeholder='Search...'
                            className='mb-2 px-2 py-1 border text-sm rounded w-full'
                          />
                          <div
                            className='flex justify-between items-center gap-2 border-b sticky top-0 z-10 bg-white'
                            onClick={() => {
                              const newCheckedState = !isTenantChecked;
                              setIsTenantChecked(newCheckedState);
                              if (newCheckedState) {
                                setSelectedUsers([{ name: tenant?.name, profilePic: logo, userId: "" }]);
                                setSearchInput("");
                              }
                            }}
                          >
                            <input
                              type='checkbox'
                              checked={isTenantChecked}
                              onChange={handleTenantCheckboxChange}
                              className='order-3 mr-2 peer'
                            />
                            <img src={logo} className='order-1 w-6 h-6 rounded-full' />
                            <span
                              className='order-2 peer-checked:font-bold text-sm font-semibold text-contrast-900 text-left py-1 cursor-pointer w-full hover:text-primary-700'
                              title={tenant?.name}
                            >
                              {tenant?.name}
                            </span>
                          </div>
                        </div>
                        <div className="relative">
                          <ul className='bg-white divide-y flex flex-col'>
                            {users.length > 0 ? (
                              users.map((user) => (
                                <li key={user.id} className='group mb-1 last:mb-0 flex flex-row items-center justify-between'>
                                  <div
                                    className='flex items-center justify-between gap-2 w-full border-b'
                                    onClick={() => handleUserCheckboxChange(user,!selectedUsers || !Object.keys(selectedUsers).length || !selectedUsers?.some((u) => u.userId === user.id))}
                                  >
                                    <input
                                      type='checkbox'
                                      checked={!selectedUsers || !Object.keys(selectedUsers).length ? false :selectedUsers?.some(
                                        (u) => u.userId === user.id
                                      )}
                                      onChange={(e) => {
                                        handleUserCheckboxChange(
                                          user,
                                          e.target.checked
                                        );
                                      }}
                                      className='order-3 mr-2 peer hover:cursor-pointer'
                                    />
                                    {user.profilePic ? (
                                      <img
                                        src={user.profilePic}
                                        className='w-6 h-6 rounded-full hover:cursor-pointer'
                                      />
                                    ) : (
                                      <img className="order-1 hover:cursor-pointer rounded-full w-6 h-6" src={`https://robohash.org/${user.name}.png?size=500x500&&bgset=bg2`} alt='user-icon' />
                                    )}
                                    <span
                                      className='order-2 group-hover:text-primary-700 peer-checked:font-bold text-sm font-light text-contrast-900 text-left py-1 w-full text-nowrap text-ellipsis overflow-hidden cursor-pointer'
                                      title={user.firstName}
                                    >
                                      {user.firstName + ' ' + (user.lastName || '')}
                                    </span>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li className='text-sm text-contrast-900 px-4 py-1 text-nowrap'>
                                No user found
                              </li>
                            )}
                            {isFetchingRef.current && (
                            <li className='p-2 flex items-center justify-center w-full'>
                              <Spinner showSpinner={true} />
                            </li>
                          )}
                        </ul>
                        </div>
                      </div>
                      <div className="sticky bottom-0 right-0 w-full bg-white flex justify-end py-4 pr-1 border-t border-contrast-100">
                          <button
                            className="w-fit px-3 py-2 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 border-none shadow-sm text-xs text-white font-medium  disabled:bg-primary-300 disabled:cursor-not-allowed"
                            disabled={
                              !selectedUsers ||
                              Object.keys(selectedUsers).length === 0 ||
                              JSON.stringify(finalSelectedUser) === JSON.stringify(selectedUsers)
                            }
                            onClick={usersDetailSearchHandler}
                          >Go</button>
                      </div>
                    </div>
                  )}
                </div>

                <div className='relative  border-l pl-4 sm:flex-grow md:flex-grow-0 gap-2 '>
                  <div
                    onClick={handleDropdownIconClick}
                    className='flex py-2.5 px-3 gap-1 w-auto justify-center items-center border border-contrast-300 rounded-lg bg-contrast-100 hover:bg-contrast-200 ease-linear cursor-pointer text-primary-600'
                  >
                    <RenderSVG Svg={CalendarOutline} alt='calender' />
                    <DatePicker
                      ref={datePickerRef}
                      numberOfMonths={noOfMonthsInCalendar}
                      shadow
                      range
                      rangeHover
                      format={DATE_FORMAT}
                      weekDays={DATE_PICKER_WEEKDAYS}
                      highlightToday={false}
                      value={customDates}
                      render={renderRangeText}
                      onChange={(dates) => handleCustomDateSelection(dates)}
                      maxDate={new Date()}
                      editable={false}
                      className={`date-selector mr-8 !border-none ${
                        selectedTimeRange === CUSTOM_DATE
                          ? "block text-xs"
                          : "hidden"
                      }`}
                      zIndex={1000}
                      containerClassName='black !w-fit !overflow-hidden calendar'
                    >
                      <div className='flex pb-4 gap-4 justify-end mr-4'>
                        <button
                          type='button'
                          className='px-4 py-2 rounded-md bg-primary-50 active:bg-primary-600 shadow-sm text-sm text-primary-600 font-medium'
                          onClick={() => datePickerRef.current.closeCalendar()}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={disableApplyCustomDate}
                          type='button'
                          className='px-4 py-2 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium disabled:cursor-not-allowed disabled:bg-primary-400'
                          onClick={onApplyCustomDate}
                        >
                          Apply
                        </button>
                      </div>
                    </DatePicker>
                    <RenderSVG Svg={ChevronDown} alt='show options' className="-ml-2.5"/>

                  </div>
                  {isDropdownOpen && (
                    <div
                      ref={dropDownRef}
                      className='absolute right-[-28px] z-10 top-[64px] rounded-lg shadow-2xl bg-white p-2 pr-1 border-t border-r '
                    >
                      <ul className='bg-white divide-y w-max flex flex-col gap-1'>
                        {ANALYTICS_TIME_PERIODS.map(
                          ({ id, name, interval, referencePeriod }) => (
                            <li key={id}>
                              <div className='flex items-center justify-between gap-4'>
                                <button
                                  className='text-sm font-light text-contrast-900 text-left px-3 py-1 cursor-pointer w-full hover:text-primary-700'
                                  onClick={() =>
                                    handleIntervalSelection(
                                      name,
                                      interval,
                                      referencePeriod
                                    )
                                  }
                                >
                                  {name}
                                </button>
                                {selectedTimeRange === name && (
                                  <RenderSVG
                                    Svg={Check}
                                    alt='Check'
                                    color={"#4F46E5"}
                                    className='mb-1 h-7 w-7'
                                  />
                                )}
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  {/* TODO: Need to integrate filter analytics functionality, then this button would be visible */}
                  {/* <div className="relative">
                    <button
                      onClick={() => setShouldShowFilterModal(true)}
                      className="py-2.5 px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-primary-600 font-medium"
                    >
                      <RenderSVG Svg={FilterIcon} className="text-primary-600" alt="Filter Icon" />
                      Filters
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            {/* TODO: Need to update this Greeting card for Users and Admin separately */}

              <div className="flex w-full px-6 gap-6">
                <div className='w-full'>
                  {finalSelectedUser.length === 1 &&
                    finalSelectedUser[0].userId === id && (
                      <Spinner
                        size='w-full'
                        name={ANALYTICS_GREETING_DATA}
                        showSkeleton={true}
                        loaderComponent={<GreetingsSkeleton />}
                      >
                        <GreetingCardAnalytics analyticsInfo={analyticsGreetings} />
                      </Spinner>
                    )}
                </div>
              </div>
              <TotalAnalytics analyticsInfo={updatedAnalyticsInfo} />
            <div className='flex w-full px-6 gap-6'>
              <div className='w-2/3'>
                <Spinner
                  size='w-full'
                  name={ANALYTICS_SPENT_DATA}
                  showSkeleton={true}
                  loaderComponent={<ReportOverviewSkeleton analyticsInfo={updatedAnalyticsInfo} />}
                  className='inline-block'
                >
                  <ReportOverview analyticsInfo={updatedAnalyticsInfo} analyticsQueryParams={analyticsQueryParams} />
                </Spinner>
              </div>
              <div className='w-1/3'>
                <Spinner
                  size='w-full'
                  name={ANALYTICS_TRANSACTIONS_DATA}
                  showSkeleton={true}
                  loaderComponent={<TransactionSkeleton />}
                  className='inline-block'
                >
                  <TransactionList
                    analyticsInfo={analyticsTransactions}
                    currencySymbol={currencySymbol}
                    referencePeriod={isDateCustom ? CUSTOM : analyticsQueryParams.referencePeriod}
                  />
                </Spinner>
              </div>
            </div>

            <div className='px-6 w-full grid grid-cols-2 gap-6'>
              {!isEmpty(frequentlyUsedAirlines) &&
                !!frequentlyUsedAirlines.data?.totalFlightBookings && (
                  <div className='lg:col-span-1 col-span-2'>
                    <FrequentlyUsedAnalytics
                      frequentlyUsedData={frequentlyUsedAirlines}
                      type={FLIGHT}
                    />
                  </div>
                )}
              {!isEmpty(frequentlyUsedHotels) &&
                !!frequentlyUsedHotels.data?.totalHotelBookings && (
                  <div className='lg:col-span-1 col-span-2'>
                    <FrequentlyUsedAnalytics
                      frequentlyUsedData={frequentlyUsedHotels}
                      type={HOTEL}
                    />
                  </div>
                )}
            </div>
            {!isEmpty(finalSelectedUser) && (
              <UpcomingAndMonthlyBookings
                finalSelectedUser={finalSelectedUser}
                userUpcomingBookings={
                  (userUpcomingBookings && userUpcomingBookings?.data) || {}
                }
                currentUserBookings={currentUserBookings}
                setCurrentUserBookings={setCurrentUserBookings}
              />
            )}
            {HasTenantChecked && (
                <div className='px-6 w-full'>
                  <div className='border border-contrast-300 rounded-lg gap-7 bg-white'>
                    <div className='border-b px-4 flex items-start'>
                      <div className='p-6 text-sm font-semiboldc font-inter text-[14px] font-[500] text-[#111827] text-contrast-600'>
                        Total Trips By Employee
                      </div>
                    </div>
                    <div className='p-[26.3px]'>
                    <Spinner
                      size='w-full'
                      name={ANALYTICS_GRAPH_DATA}
                      showSkeleton={true}
                      loaderComponent={<LineChartSkeleton />}
                      className='inline-block'
                    >
                      {(updatedAnalyticsInfo?.tripsPerUserChart?.data?.length > 0 || (isDateCustom && analyticsGraphData?.data?.current.length > 0))
                        ? (
                          <UserTripsBarChart
                            data={isDateCustom ? analyticsGraphData?.data?.current : updatedAnalyticsInfo?.tripsPerUserChart.data}
                          />
                        ) : (
                          <NoTripData
                            description={
                              "No data available for the selected period."
                            }
                            imgName={SetUpYourCompany}
                          />
                        )}
                    </Spinner>
                    </div>
                  </div>
                </div>
            )}
            <div className='px-6 w-full min-h-[65vh] flex h-full'>
              {HasTenantChecked && (
                <div className='w-2/3'>
                  <div className='h-full border border-contrast-300 rounded-lg gap-7 bg-white'>
                    <div className='border-b px-4 flex items-start'>
                      <div className='p-6 text-sm font-semiboldc font-inter text-[14px] font-[500] text-[#111827] text-contrast-600'>
                        Total Company Trips
                        {updatedAnalyticsInfo?.analyticsChart?.data?.length > 0 && (
                          <div
                            className='inline-block w-5 h-5 ml-2 relative align-middle'
                            data-tooltip-id='total-company-trips'
                            data-tooltip-place='top'
                            data-tooltip-content={tooltipMessage()}
                          >
                            <RenderSVG
                              Svg={InfoIcon}
                              className='relative min-w-[20px] cursor-pointer'
                              alt='info-icon'
                            />
                            <Tooltip
                              id='total-company-trips'
                              className='!w-56 !sm:w-72 !bg-primary-900 !rounded-lg !z-50'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="p-4">
                      <Spinner
                        size='w-full'
                        name={ANALYTICS_AGGREGATE_DATA}
                        showSkeleton={true}
                        loaderComponent={<LineChartSkeleton />}
                        className='inline-block'
                      >
                        {updatedAnalyticsInfo?.analyticsChart?.data?.length ? (
                          <LineChart
                            labels={updatedAnalyticsInfo.analyticsChart.labels}
                            data={updatedAnalyticsInfo.analyticsChart.data}
                            color={tenantConfig.primaryColor}
                          />
                        ) : (
                          <NoTripData
                            description={
                              "No data available for the selected period."
                            }
                            imgName={SetUpYourCompany}
                          />
                        )}
                      </Spinner>
                    </div>
                  </div>
                </div>
                )}
               <div className={classNames( 'w-1/3',{'pl-6': HasTenantChecked,
                  'w-full':!HasTenantChecked
                })}>
                    <div className='flex flex-col h-full border border-contrast-300 rounded-lg bg-white'>
                      <div className='border-b px-4 flex items-start'>
                        <div className='p-6 text-sm font-semiboldc font-inter text-[14px] font-[500] text-[#111827] text-contrast-600'>
                          Cost Codes Data
                        </div>
                      </div>
                      <div className="h-full w-full">
                        <Spinner
                          size='w-full'
                          name={COST_CODES}
                          showSkeleton={true}
                          loaderComponent={<DonutSkeleton />}
                          className='inline-block'
                        >
                          {data.OverallTotalSpend > 0 ? (
                            <DonutChart data={data.analyticsCostCode} />
                          ) : (
                            <div className='py-6'>
                              <NoTripData
                                description={
                                  "No data available for the selected period."
                                }
                                imgName={SetUpYourCompany}
                              />
                            </div>
                          )}
                        </Spinner>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center h-screen'>
          <div className='mb-2'>
            <RenderSVG
              Svg={TriangleExclaimation}
              height='60'
              width='60'
              color='red'
            />
          </div>
          <div className='text-contrast-900 text-2xl font-semibold leading-tight tracking-tight'>
            {(analyticsSpent?.error && analyticsTripData?.error && analyticsTransactions?.error && analyticsGraphData?.error && analyticsGreetings?.error) || "Something went wrong."}
          </div>
          <div className='text-contrast-500 text-base leading-[48px] text-center'>
            {analyticsSpent?.message || "Unable to fetch analytics information."}
          </div>
        </div>
      )}

      {shouldShowFilterModal && (
        <FilterDashboardModal
          handleClose={() => setShouldShowFilterModal(false)}
        />
      )}
    </div>
  )
}

export default Dashboard;