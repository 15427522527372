import React, { useState } from "react";
import classNames from "classnames";
import { TablePropsTypes } from "../../../prop-types";
import { DEFAULT_VALUES } from "../../../constants";
import { SortAscIcon, SortDescIcon, RenderSVG } from "../../../assets/icons";
const OBJECT = "object";
const FUNCTION = "function";

const { EMPTY_STRING } = DEFAULT_VALUES;
const Table = ({
  columnDefs,
  rowData,
  handleNavigtation = null,
  handleColumnClick = null,
  showTableHeader = true,
}) => {
  const [sortedColumn, setSortedColumn] = useState(null);

  const onColumnClick = (col, sortAsc, index) => {
    setSortedColumn(index);
    if (handleColumnClick) {
      handleColumnClick(col, sortAsc, index);
    }
  };

  const renderCell = (formatter, data) => {
    switch (typeof formatter) {
      case OBJECT:
        return React.cloneElement(formatter, { row: data });
      case FUNCTION:
        return formatter(data);
      default:
        return formatter;
    }
  };

  return (
    <table className='px-3 border-collapse w-full border border-x-0 border-contrast-200 bg-white text-sm'>
      <thead className='bg-contrast-200'>
        {showTableHeader && (
          <tr className='pl-6 '>
            {columnDefs.map((col, index) => (
              <th
                key={col.text}
                onClick={() => onColumnClick(col, col.sortAsc, index)}
                className={classNames(
                  "border-x-0 border border-slate-200 text-xs font-semibold px-4 py-4 text-contrast-500-custom text-left uppercase",
                  {
                    "hover:bg-contrast-500/40": handleColumnClick,
                  },
                  {
                    "cursor-pointer": col.allowSorting,
                    "cursor-default": !col.allowSorting,
                  }
                )}
              >
                <div className='flex justify-between items-center '>
                  <span>{col.text || EMPTY_STRING}</span>
                  {col.allowSorting && (
                    <RenderSVG
                      Svg={col.sortAsc ? SortAscIcon : SortDescIcon}
                      className={classNames("w-5 h-5", {
                        "fill-none stroke-indigo-500": index === sortedColumn,
                      })}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        )}
      </thead>
      <tbody>
        {rowData.map((row, index) => (
          <tr
            key={index}
            onClick={() => handleNavigtation && handleNavigtation(row)}
            className={classNames("hover:bg-contrast-100/80 hover:shadow-lg ", {
              "hover:cursor-pointer": handleNavigtation,
            })}
          >
            {columnDefs.map(({ text, formatter, index }) => {
              return (
                <td
                  key={text || index}
                  className='pl-4 border-x-0 border border-contrast-200 px-2 py-4'
                >
                  {renderCell(formatter, row)}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Table.propTypes = TablePropsTypes;

export default Table;
