import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Cross, RenderSVG } from "../../../assets/icons";
import {
  MODAL_SIZE,
  MODAL_POSITION,
  KEYDOWN,
  ESCAPE,
  WINDOWS_EVENTS,
  ROUTES,
} from "../../../constants";
import { selectSelectedModal } from "./index";
import { actions as ModalActions } from "./modal.reducers";
import { MODALS } from "../../organisms/AppModals/modals.constants";
import { ModalPropTypes } from "../../../prop-types";

const { MD, FULL, LG, XL, XXL } = MODAL_SIZE;
const { CENTER } = MODAL_POSITION;
const { POPSTATE } = WINDOWS_EVENTS;
const { API_FAILURE_MODAL } = MODALS;
const { BOOKINGS } = ROUTES;
const { setSelectedModal } = ModalActions;
const MODAL_TRANSITION_TIME = 200;

const Modal = ({
  title,
  subtitle,
  footer,
  children,
  size = MD,
  position = CENTER,
  shouldShowModalFromProps,
  shouldCloseOnClickOutside = false,
  shouldShowBorder = true,
  handleClose = null,
  modalClassname = "",
  titleClassname = "",
  zIndex = "999"
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedModal = useSelector(selectSelectedModal);
  const backdropRef = useRef(null);
  const [shouldShow, setShouldShow] = useState(false);

  const handleOnClose = useCallback(() => {
    setShouldShow(false);
    setTimeout(() => handleClose(), MODAL_TRANSITION_TIME);
  }, [handleClose]);

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (selectedModal === API_FAILURE_MODAL) navigate(BOOKINGS);
      dispatch(setSelectedModal(null));
    };
    window.addEventListener(POPSTATE, onBackButtonEvent, { once: true });
  }, [dispatch, selectedModal]);

  useEffect(() => {
    const close = ({ code }) => code === ESCAPE && handleOnClose();
    document.addEventListener(KEYDOWN, close);
    return () => document.removeEventListener(KEYDOWN, close);
  }, [handleOnClose]);

  useEffect(() => {
    if (selectedModal || shouldShowModalFromProps) {
      setTimeout(() => setShouldShow(true), MODAL_TRANSITION_TIME);
      window.addEventListener(POPSTATE, handleOnClose);
      document.body.style.overflow = "hidden";
    }

    return () => {
      window.removeEventListener(POPSTATE, handleOnClose);
      document.body.style.overflow = "auto";
    };
  }, [handleOnClose, shouldShowModalFromProps, selectedModal]);

  return (
    <div
      className={classNames(
        "modal fixed inset-0 bg-slate-900/70 bg-blue-gray-900/80 backdrop-blur-md overflow-auto transition-opacity duration-300 no-scrollbar inline-block",
        {
          "px-4 flex": size === MD || LG || XL || XXL,
          "items-center":
            (size === MD || LG || XL || XXL) && position === CENTER,
          show: shouldShow,
        }
      )}
      style={{ zIndex }}
      onClick={() => shouldCloseOnClickOutside && handleOnClose()}
    >
      <div
        ref={backdropRef}
        onClick={(e) => shouldCloseOnClickOutside && e.stopPropagation()}
        className={classNames(
          `modal-dialog bg-white w-full mx-auto rounded-md overflow-hidden ${modalClassname}`,
          {
            "my-6 sm:w-[600px] h-fit": size === MD,
            "my-6 sm:w-[800px] overflow-y-auto max-h-[600px] no-scrollbar flex flex-col":
              size === LG,
            "my-6 !w-[1000px] overflow-y-auto max-h-[600px] no-scrollbar flex flex-col":
              size === XL,
            "my-6 !w-[1200px] overflow-y-auto max-h-[600px] no-scrollbar flex flex-col":
              size === XXL,
            "h-screen flex flex-col": size === FULL,
            border: shouldShowBorder,
          }
        )}
      >
        {title && (
          <div className={`modal-header flex items-center px-10 pb-6 pt-10 ${titleClassname}`}>
            <div>
              <h6
                className={classNames(
                  "font-bold text-xl sm:text-2xl text-contrast-900",
                  {
                    "mb-2": subtitle,
                  }
                )}
              >
                {title}
              </h6>
              <p className='text-sm text-black'>{subtitle}</p>
            </div>
            {handleClose && (
              <button
                type='button'
                className='ml-auto rounded-md text-contrast-900 hover:scale-110 duration-200 '
                onClick={() => handleOnClose()}
              >
                <RenderSVG Svg={Cross} alt='Cross Icon' className="text-gray-900" />
              </button>
            )}
          </div>
        )}
        {children}
        {footer}
      </div>
    </div>
  );
};

Modal.propTypes = ModalPropTypes;

export default Modal;
