import React, { useState, useMemo } from "react";
import classNames from "classnames";

const RenderEditedPermissions = ({ tenantRolesPermissions, values }) => {
  const [shouldShowAllEditedValues, setShouldShowAllEditedValues] =
    useState(false);
  const MAX_VISIBLE_VALUES = 9;

  const combinedPermissions = useMemo(() => {
    const permissions = [];

    tenantRolesPermissions.forEach((value) => {
      if (!values.permission.includes(value)) {
        permissions.push({ permission: value, edit: "remove" });
      }
    });

    values.permission.forEach((value) => {
      if (!tenantRolesPermissions.includes(value)) {
        permissions.push({ permission: value, edit: "add" });
      }
    });

    return permissions;
  }, [tenantRolesPermissions, values.permission]);

  const toggleShowAllEditedValues = () => {
    setShouldShowAllEditedValues(!shouldShowAllEditedValues);
  };

  const renderPermission = (permission) => (
    <div
      key={permission.permission}
      className={classNames(
        "rounded-2xl py-0.5 px-[10px] text-xs font-medium inline-flex items-center gap-[6px] mb-2 mr-2",
        {
          "bg-green-100 text-green-800": permission.edit === "add",
          "bg-red-100 text-red-800 line-through whitespace-nowrap":
            permission.edit === "remove",
        }
      )}
    >
      {permission.permission}
    </div>
  );

  const minifiedPermissions = shouldShowAllEditedValues
    ? combinedPermissions
    : combinedPermissions.slice(0, MAX_VISIBLE_VALUES);

  return (
    <div>
      {minifiedPermissions &&
        minifiedPermissions.map((value) => renderPermission(value))}
      {combinedPermissions.length > MAX_VISIBLE_VALUES && (
        <button
          type='button'
          onClick={toggleShowAllEditedValues}
          className='text-sm text-blue-500'
        >
          {shouldShowAllEditedValues ? "View Less" : "View More"}
        </button>
      )}
    </div>
  );
};

export default RenderEditedPermissions;
