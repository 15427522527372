import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { WebCheckinInfoPropTypes } from "../../../prop-types";
import { getAirlineDetailsByCode, getCityNameByIata } from "../../../helper";
import { RenderSVG, Ticket } from "../../../assets/icons";
import { DEFAULT_VALUES, FLIGHT_TRIP_TYPES } from "../../../constants";

const { ONE, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { ROUND_TRIP } = FLIGHT_TRIP_TYPES;

const WebCheckinInfo = ({
  itineraries,
  pnrList,
  tripType,
  hasPNR,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {hasPNR && (
        <div className='bg-white rounded-lg border border-contrast-300 shadow-sm p-3 print:hidden'>
          <div className='bg-teal-100 p-4 rounded-md flex flex-col md:flex-row flex-wrap gap-4 items-start'>
            <div>
              <h4 className='font-bold text-contrast-950 text-base mb-1'>
                {t("bookingResult.webCheckIn.title")}
              </h4>
              <p className='text-contrast-500 text-xs'>
                {t("bookingResult.webCheckIn.message")}
              </p>
            </div>
            <div className='flex flex-col gap-2 w-full'>
              {itineraries?.map(({ segments }, index) => {
                const carrierCode = get(
                  segments,
                  "0.carrierCode",
                  EMPTY_STRING
                );
                const { linkToWebCheckin: webCheckinUrl, airlineName } =
                  getAirlineDetailsByCode(carrierCode);
                const departure = get(segments, "0.departure", EMPTY_OBJECT);
                const arrivalIata = get(
                  segments,
                  `${segments.length - ONE}.arrival.iataCode`,
                  EMPTY_STRING
                );

                return (
                  <>
                    {(tripType !== ROUND_TRIP || pnrList?.[index]) && (
                      <div
                        className='w-full flex justify-between items-center'
                        key={departure.date}
                      >
                        <div className='text-sm'>
                          {getCityNameByIata(departure.iataCode)} -{" "}
                          {getCityNameByIata(arrivalIata)}
                          <span className='mx-1 text-xs text-contrast-500'>
                            ({airlineName})
                          </span>
                        </div>
                        <div>
                          <a
                            href={webCheckinUrl}
                            target='_blank'
                            className='bg-primary-100/65 px-4 py-2 rounded-2xl text-xs flex items-center gap-3 border border-primary-700 text-primary-700 font-bold'
                          >
                            <RenderSVG Svg={Ticket} width='20' />
                            <span>{t("bookingResult.webCheckIn.btnText")}</span>
                          </a>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

WebCheckinInfo.propTypes = WebCheckinInfoPropTypes

export default WebCheckinInfo;
