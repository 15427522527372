import Admin from "./Admin";

import * as actions from "./admin.actions";
import * as slice from "./admin.reducer";
import * as selectors from "./admin.selector";

export const { fetchBookings, getLogsInformation, fetchBookingInfo } = actions;
export const { name, reducer } = slice;
export const {
  selectBookings,
  selectlogsInformation,
  selectBookingInfo,
  selectHotelBookings,
} = selectors;

export default Admin;
