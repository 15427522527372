import { DEFAULT_VALUES } from '../constants'

const { ZERO } = DEFAULT_VALUES

const getDefaultReturnDate = (date, days) => {
	const newDate = new Date(date)
	newDate.setDate(newDate.getDate() + days)
	return newDate.toISOString().split('T')[ZERO]
}

export default getDefaultReturnDate
