import { get } from "lodash";
import { BookingPricingPropTypes } from "../../../prop-types";
import { DEFAULT_VALUES, DEFAULT_CURRENCY_CODE } from "../../../constants";
import { formatPriceForCurrency } from "../../../helper/priceFormatter";

const { EMPTY_STRING } = DEFAULT_VALUES;

const BookingPricing = ({ amount, hotelRoomsInfo, numberOfAdults }) => {
  const currency = get(hotelRoomsInfo, "0.price.currencyCode", DEFAULT_CURRENCY_CODE);
  return (
    <div className='bg-white rounded-xl border border-contrast-300 overflow-hidden'>
      <div className='px-6 py-4 flex items-center border-b border-contrast-300 bg-contrast-50'>
        <h4 className='text-base font-bold text-contrast-900 flex-1'>
          Total Price
        </h4>
        <span className='text-base font-bold text-[#4f46e5] whitespace-nowrap'>
          {formatPriceForCurrency(Number(amount), currency)}
        </span>
      </div>
      <div className='p-6'>
        <div className='border border-contrast-200 divide-y divide-contrast-200'>
          <div className='flex items-center justify-between px-4 py-4'>
            <div>
              <h4 className='text-base font-bold text-contrast-900 flex-1'>
                {hotelRoomsInfo[0]?.roomTypeName || EMPTY_STRING}
              </h4>
              <p className='text-xs text-contrast-800'>
                <strong>{numberOfAdults}</strong> Adult(s)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BookingPricing.propTypes = BookingPricingPropTypes;

export default BookingPricing;
