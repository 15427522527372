import { createSlice } from "@reduxjs/toolkit";
import { getPermissions, getTenantsRoles } from "./permissions.actions";

const initialState = {
  permissions: [],
  roles: [],
};

const slice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setSelectedRolesPermissions(state, action) {
      const { roleId, updatedPermissions } = action.payload;
      const updatedRoles = state.roles.map((role) => {
        if (role.id === roleId) {
          return { ...role, permissions: updatedPermissions };
        }
        return role;
      });
      state.roles = updatedRoles;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.permissions = action.payload;
      })
      .addCase(getTenantsRoles.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.roles = action.payload;
      });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
