import reducers from "./dashboard.reducer";

export const selectSlice = (state) => state[reducers.name];

export const selectAnalyticsSpent = (state) => selectSlice(state).analyticsSpent
export const selectAnalyticsTripData = (state) => selectSlice(state).analyticsTripData
export const selectAnalyticsTransactions = (state) => selectSlice(state).analyticsTransaction
export const selectAnalyticsGraphData = (state) => selectSlice(state).analyticsGraphData
export const selectAnalyticsGreetings = (state) => selectSlice(state).analyticsGreetings
export const selectAnalyticsAggregate = (state) => selectSlice(state).analyticsAggregate

export const selectselectedTimeRange = (state) => selectSlice(state).selectedTimeRange;

export const selectAnalyticsQueryParams = (state) =>
  selectSlice(state).analyticsQueryParams;

export const selectSelectedUser = (state) => selectSlice(state).selectedUser;

export const selectCostCodeInfo = (state) => selectSlice(state).costCodeInfo;

export const selectFrequentlyUsedAirlines = (state) => selectSlice(state).frequentlyUsedAirlines;

export const selectFrequentlyUsedHotels = (state) => selectSlice(state).frequentlyUsedHotels;

export const selectUserUpcomingBookings = (state) => selectSlice(state).userUpcomingBookings;
