import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { createPopper } from "@popperjs/core";
import useClickOutside from "../../helper/useClickOutside";
import { setAddressDetails } from "../../screens/Booking/FlightBookings";
import { KEY_CODES, DEFAULT_VALUES, INDEX } from "../../constants";

const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { KEY_DOWN, KEY_UP, ENTER } = KEY_CODES;
const { LAST } = INDEX;
const GUEST_NATIONALITY = "guestNationality";
const ISSUE_COUNTRY = "passportDetails.issueCountry";
const NATIONALITY = "profileDetails.nationality";
const MOBILE_COUNTRY_CODE = "profileDetails.mobileCountryCode";
const NATIONALITY_FIELD = "nationality";
const COST_CODES = "costCodes";
const COUNTRY = "country";

const DropdownSelectField = ({
  name = "",
  value = "",
  setFieldTouched = () => {},
  selectableValues = [],
  setFieldValue,
  valueToShow,
  validateForm,
  placeholder = EMPTY_STRING,
  values = {},
  showChange = false,
  type = "isoCode",
  onChange = () => {},
  onBlur = () => {},
  handleInputChangeFromProps = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locationsOptionsRef = useRef(null);
  const componentRef = useRef(null);
  const selectRef = useRef();
  const dropdownRef = useRef();
  const popperInstance = useRef(null);

  const [filteredValues, setFilteredValues] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(LAST);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState(EMPTY_STRING);

  const showCountryName =
    name === ISSUE_COUNTRY ||
    name === NATIONALITY ||
    name === GUEST_NATIONALITY ||
    name === NATIONALITY_FIELD ||
    name === COUNTRY;

  const showPhoneCode =
    name !== ISSUE_COUNTRY &&
    name !== GUEST_NATIONALITY &&
    name !== NATIONALITY &&
    name !== NATIONALITY_FIELD &&
    name !== COUNTRY;

  const isBottom = get(
    popperInstance,
    'current.state.placement.includes("bottom")',
    EMPTY_STRING
  );

  const closeDropdown = () => setShowDropdown(false);

  useClickOutside(dropdownRef, () => {
    setShowDropdown(false);
    setFieldTouched(name + "." + type, true);
  });

  useEffect(() => {
    if (showDropdown) {
      popperInstance.current = createPopper(
        selectRef.current,
        dropdownRef.current,
        {
          placement: "bottom",
        }
      );
    }

    return () => {
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [showDropdown]);

  useEffect(() => {
    if (!isEmpty(value.name) && isEmpty(value.isoCode)) {
      const matchingCountry = selectableValues.find(
        (country) => country.name === value.name
      );

      if (matchingCountry) {
        setFieldValue(`${name}.isoCode`, matchingCountry.isoCode);
        dispatch(
          setAddressDetails({
            ...values,
            nationality: {
              isoCode: matchingCountry.isoCode,
              name: matchingCountry.name,
            },
          })
        );
      }
    }
    setInputValue(get(value, valueToShow, EMPTY_STRING));
  }, [value, valueToShow, selectableValues]);

  useEffect(() => {
    validateForm();
  }, [value]);

  const toggleDropdown = () => setShowDropdown((preValue) => !preValue);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    const filtered = selectableValues.filter((item) =>
      item.name.toLowerCase().includes(inputValue)
    );
    setFilteredValues(filtered);
    setSelectedIndex(LAST);
    setInputValue(event.target.value);
    if (inputValue === EMPTY_STRING)
      setFieldValue(name, {
        ...value,
        name: EMPTY_STRING,
        ...(showChange ? { id: EMPTY_STRING } : { isoCode: EMPTY_STRING }),
      });
    setFieldTouched(name + "." + type, true);
  };

  const setFieldValues = (each) => {
    setFieldValue(name, each);
    setFieldTouched(name + "." + type, true);
    setInputValue(each[valueToShow]);
    handleInputChangeFromProps(each);
    closeDropdown();
    onBlur(each);
    onChange();
  };

  const handleKeyDown = (event) => {
    const KEY = event.key;
    if (KEY === KEY_UP)
      setSelectedIndex((prevIndex) => Math.max(prevIndex - ONE, ZERO));
    else if (KEY === KEY_DOWN)
      setSelectedIndex((prevIndex) =>
        Math.min(prevIndex + ONE, filteredValues.length - ONE)
      );
    else if (KEY === ENTER && selectedIndex !== LAST) {
      const selectedItem = filteredValues[selectedIndex];
      setFieldValue(name, selectedItem);
      setInputValue(selectedItem[valueToShow]);
      setFieldTouched(name + "." + type, true);
      document.body.click();
      event.preventDefault();
      closeDropdown();
    }
  };

  const handleBlur = () => {
    const matchingText = selectableValues.find(
      ({ name }) => name.toLowerCase() === inputValue.toLowerCase()
    );

    if (matchingText) {
      setFieldValue(name, matchingText);
      setInputValue(matchingText[valueToShow]);
      onChange();
    } else {
      setInputValue(EMPTY_STRING);
      !showChange
        ? setFieldValue(name, {
            ...value,
            isoCode: EMPTY_STRING,
            name: EMPTY_STRING,
          })
        : setFieldValue(name, {
            ...value,
            name: EMPTY_STRING,
            id: EMPTY_STRING,
          });
    }
  };

  const getFlagImage = (coutryCode) =>
    `https://flagcdn.com/48x36/${coutryCode.toLowerCase()}.png`;

  const classnames = classNames(
    "flex text-contrast-900 font-normal rounded-md outline-none placeholder:text-blue-contrast-500 focus:outline-none focus:ring-2 focus:ring-blue-600",
    {
      "w-full h-full p-2.5 text-sm border-contrast-300 placeholder:text-blue-contrast-500":
        name === ISSUE_COUNTRY ||
        name === NATIONALITY ||
        name === NATIONALITY_FIELD ||
        name === COST_CODES ||
        name === COUNTRY,
      "w-full !h-[50px] border border-contrast-300 placeholder:text-blue-contrast-300 shadow-sm !text-base !py-2.5":
        name === GUEST_NATIONALITY,
      "w-18 h-11 text-sm justify-center items-center border-none":
        name === MOBILE_COUNTRY_CODE,
      "w-16 h-full text-sm justify-center items-center border-none":
        name !== ISSUE_COUNTRY &&
        name !== NATIONALITY &&
        name !== NATIONALITY_FIELD &&
        name !== MOBILE_COUNTRY_CODE &&
        name !== COST_CODES &&
        name !== COUNTRY,
    }
  );

  const setScrollBarPosition = () => {
    const selected =
      locationsOptionsRef?.current?.querySelector(".activeOption");
    if (selected)
      selected.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
  };

  return (
    <div className='col-span-2 sm:col-span-1 text-black lg:col-span-4 relative w-full'>
      <div className='rounded-md'>
        <input
          ref={selectRef}
          autoComplete='new-nationality-field'
          type='text'
          id={name}
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          onFocus={() => {
            !filteredValues.length && setFilteredValues(selectableValues);
            setFieldTouched(name + "." + type, false);
          }}
          onBlur={handleBlur}
          onClick={toggleDropdown}
          className={classnames}
        />
      </div>
      {showDropdown && (
        <div className='shadow-lg' ref={componentRef}>
          <div
            ref={dropdownRef}
            className={classNames(
              "dropdown-menu max-content-width shadow p-0 bg-white absolute !z-10 w-full",
              {
                "top-full": !isBottom,
                "bottom-full": isBottom,
              }
            )}
          >
            {filteredValues.length > ZERO ? (
              <ul
                ref={locationsOptionsRef}
                className='max-h-48 overflow-y-scroll'
              >
                {filteredValues.map((each, index) => {
                  setTimeout(() => {
                    setScrollBarPosition();
                  }, [100]);
                  return (
                    <li
                      key={showChange ? each.id : each.isoCode}
                      className={classNames(
                        "link block text-left px-5 py-2.5 mb-1 hover:bg-primary-100 cursor-pointer",
                        { activeOption: selectedIndex === index }
                      )}
                      onClick={() => setFieldValues(each)}
                    >
                      <div className='flex items-center gap-3'>
                        {!showChange ? (
                          <>
                            <div className='w-22 text-contrast-900 text-md font-medium'>
                              <img
                                src={getFlagImage(each.isoCode)}
                                width={20}
                                height={20}
                                alt='flag'
                                className='flex-shrink-0'
                              />
                            </div>
                            {showPhoneCode && (
                              <div className='text-contrast-900 text-sm font-medium'>
                                {each.phonecode}
                              </div>
                            )}
                            <div className='text-contrast-900 text-sm font-medium'>
                              {showCountryName ? each.name : each.isoCode}
                            </div>
                          </>
                        ) : (
                          <div className='text-contrast-900 text-md font-medium'>
                            {each.name}
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className='p-5 rounded-md  text-center'>
                <p className='text-center text-sm text-contrast-600'>
                  No Result Found
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSelectField;
