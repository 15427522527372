import React, { memo, useRef, useState, useEffect } from "react";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  getAirlineIconUrlByCarrierCode,
  getLayoverDuration,
  getCityNameByIata,
  calculateTotalDuration,
  getFormattedDuration,
  generatePolicyViolationMessage,
} from "../../helper";
import {
  Cloud,
  RenderSVG,
  Baggage,
  ChevronDown,
  ChevronUp,
  InfoIcon,
} from "../../assets/icons";
import {
  selectFlightInfo,
  setCurrentFlightType,
  setSelectedFlightInfo,
  selectSelectedFlightOptions,
  setSelectedFlightOptions,
} from "./index";
import {
  selectActiveSortOrder,
  selectFilteredFlights,
} from "../../components/organisms/Search";
import {
  selectSelectedFlightId,
  selectSelectedTripType,
} from "./flightResults.selectors";
import FlightDetailsTag from "../../components/atoms/FlightDetailsTag";
import { selectAllServicesPolicy } from "../Policies";
import { useCollapse } from "react-collapsed";
import { selectCountryInfo } from "../Profile";
import { setSelectedModal } from "../../components/organisms/Modal";
import FlightJourneyDetails from "./FlightJourneyDetails";
import { getStops } from "../../utils/flights.utils";
import { MODALS } from "../../components/organisms/AppModals";
import {
  DEFAULT_VALUES,
  INDEX,
  TRIP_TYPES,
  CURRENCY_SYMBOLS,
  FLIGHT_SERVICE_TYPE,
  FLIGHTS_RESULT_TYPE,
} from "../../constants";
import PriceInfo from "./PriceInfo";
import { Tooltip } from "react-tooltip";
import { isEmpty } from "lodash";

const { ZERO, ONE, EMPTY_STRING, MAX } = DEFAULT_VALUES;
const { ROUND_TRIP } = TRIP_TYPES;
const { LAST } = INDEX;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;
const { FLIGHT_OPTIONS_MODAL } = MODALS;
const { PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const maxQuantityOfCabinCO2EmissionInPound = 500;

const OneWayCard = ({ flights, currentFlightType, isReissuanceFlight }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cardRef = useRef(null);
  const [flightIndex, setFlightIndex] = useState(ZERO);

  const selectedFlightInfo = useSelector(selectFlightInfo);
  const tripType = useSelector(selectSelectedTripType);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const filteredFlights = useSelector(selectFilteredFlights);
  const activeSortOrder = useSelector(selectActiveSortOrder);
  const selectedFlightId = useSelector(selectSelectedFlightId);
  const selectedFlightOptions = useSelector(selectSelectedFlightOptions);
  const allServicesPolicy = useSelector(selectAllServicesPolicy);

  useEffect(() => {
    let selectedFlightIndex = ZERO;
    if (selectedFlightOptions[currentFlightType]) {
      const selectedFlightIdx = flights.findIndex(
        (flight) =>
          flight.flightId === selectedFlightOptions[currentFlightType]?.flightId
      );
      if (selectedFlightIdx !== -1) selectedFlightIndex = selectedFlightIdx;
    }

    setFlightIndex(selectedFlightIndex);
  }, [
    activeSortOrder,
    filteredFlights,
    selectedFlightId,
    selectedFlightOptions,
    currentFlightType,
  ]);

  const currencySymbol = get(
    selectedCountryInfo,
    "currency.symbol",
    CURRENCY_SYMBOLS.INR
  );

  const {
    itineraries = [],
    price = {},
    carbonEmissionByCabin,
    isRefundable,
    isPanRequiredAtTicket,
    source,
    flightId,
    miniFareRules,
    costPolicyViolation = {},
  } = flights[flightIndex] || {};

  const message = generatePolicyViolationMessage({costPolicyViolation, trip: "flight"});

  const { totalPrice = 0, publishedPrice = 0, tavaMarkup = 0 } = price;
  const { segments, duration } = itineraries[ZERO];
  const {
    noOfAvailableSeats = 0,
    flightNumber = "",
    aircraftCode = "",
    carrierCode = "",
    departure = {},
    carrierName = "",
    baggageInfo,
  } = segments[ZERO];
  const finalDestination = segments.at(LAST);
  const { arrival = {} } = finalDestination;
  const { checkInBaggage } = baggageInfo;
  const totalNoOfStops = segments.length - ONE;
  const layoverDuration = getLayoverDuration(segments);
  const areSimilarFlightsAvailable = flights.length > ONE;

  const carbonEmissionsArray = Array.isArray(carbonEmissionByCabin)
    ? carbonEmissionByCabin
    : [];
  const hasHighCO2Emission = carbonEmissionsArray.some(
    (emission) => emission.quantity > maxQuantityOfCabinCO2EmissionInPound
  );

  const selectedFlightOptionsIds = Object.values(selectedFlightOptions).map(
    (flight) => {
      return get(flight, "flightId", EMPTY_STRING);
    }
  );
  const selected = selectedFlightOptionsIds.includes(flightId);

  const handleFlightSelect = (flights) => {
    dispatch(setCurrentFlightType(currentFlightType));
    if (tripType === ROUND_TRIP) {
      dispatch(
        setSelectedFlightInfo({
          ...selectedFlightInfo,
          [currentFlightType]: {
            flights,
            selectedCountryInfo,
          },
        })
      );
    } else {
      dispatch(setSelectedFlightInfo({ packages: flights }));
    }

    if (flights.length > ONE) dispatch(setSelectedModal(FLIGHT_OPTIONS_MODAL));
    else {
      if (currentFlightType === PACKAGE_RESULT)
        dispatch(
          setSelectedFlightOptions({
            [currentFlightType]: flights[0],
          })
        );
      else
        dispatch(
          setSelectedFlightOptions({
            [currentFlightType]: flights[0],
          })
        );
    }
  };

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 500,
    defaultExpanded: false,
  });

  return (
    <div
      ref={cardRef}
      className={classNames(
        "border rounded-lg shadow-md transition duration-1000 ease-in-out overflow-hidden z-10",
        {
          "border-primary-600 bg-primary-100": selected,
          "border-red-200 bg-red-100 hover:border-red-900 bg-opacity-15":
            !selected && !isEmpty(message),
          "border-contrast-200 bg-white hover:border-contrast-400":
            !selected && isEmpty(message),

          "mb-3": areSimilarFlightsAvailable,
        }
      )}
    >
      {!isEmpty(message) && (
        <div className="top-0 left-0 flex gap-1 items-center w-1/2 right-0 bg-gradient-to-r from-red-500 to-transparent text-white px-2 text-xs font-bold py-1">
          Violates Cost Policy
          <div
            data-tooltip-id={flightId}
            data-tooltip-place="top"
            data-tooltip-content = {message}
          >
            <Tooltip
              id={flightId}
              className="!w-56 !sm:w-72 !bg-primary-900 !rounded-lg !z-50"
            />
            <RenderSVG
              Svg={InfoIcon}
              width="16"
              height="16"
              alt="Info icon"
            />
          </div>
        </div>
      )}
      <div className='grid grid-cols-12 gap-2 p-4 pb-3'>
        <div
          className={classNames("col-span-12 3xl:col-span-4", {
            "lg:col-span-4": tripType !== ROUND_TRIP,
          })}
        >
          <div className='flex justify-between'>
            <div className='flex items-center gap-4'>
              <div className='logo'>
                <img
                  src={getAirlineIconUrlByCarrierCode(carrierCode)}
                  className='rounded border-contrast-300 w-8 h-8'
                  alt={`${carrierCode}_logo`}
                />
              </div>
              <div className='flex-1'>
                <h5
                  className={classNames(
                    "text-base font-semibold text-contrast-900",
                    {
                      "text-sm": tripType === ROUND_TRIP,
                    }
                  )}
                >
                  {departure.time}
                  &nbsp;-&nbsp;
                  {arrival.time}
                </h5>
                <p className='text-xs text-contrast-600'>
                  {carrierName || carrierCode}, {flightNumber}{" "}
                  {aircraftCode ? `| ${aircraftCode}` : ""}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "col-span-12 3xl:col-span-8 grid grid-cols-12 gap-4",
            {
              "lg:col-span-8": tripType !== ROUND_TRIP,
              "md:gap-2": tripType === ROUND_TRIP,
            }
          )}
        >
          <div
            className={classNames(
              "col-span-12 sm:col-span-5 lg:col-span-7 flex gap-4",
              {
                "md:flex-col md:col-span-6": tripType === ROUND_TRIP,
              }
            )}
          >
            <div className='flex-1'>
              <h5 className='text-base text-contrast-900 font-semibold'>
                {source === AMADEUS
                  ? getFormattedDuration(duration)
                  : calculateTotalDuration(layoverDuration, duration)}
              </h5>
              <p className='text-xs text-contrast-600'>
                {getCityNameByIata(departure.iataCode)} -&nbsp;
                {getCityNameByIata(arrival.iataCode)}
              </p>
            </div>
            <div
              className={classNames(
                "md:text-base flex-1 text-end sm:text-start",
                {
                  "md:text-sm": tripType === ROUND_TRIP,
                }
              )}
            >
              {!totalNoOfStops ? (
                <h5 className='text-contrast-900 font-semibold'>
                  {t("flightResults.stopTypes.nonStop")}
                </h5>
              ) : (
                <div>
                  <h5 className='text-contrast-900 font-semibold'>
                    {totalNoOfStops} {t("flightResults.stopTypes.stop")}
                  </h5>
                  <div className='text-xs text-contrast-600'>
                    {getStops(segments)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              "col-span-12 sm:col-span-7 lg:col-span-5 flex items-start justify-end flex-wrap xs:flex-nowrap",
              {
                "md:flex-col lg:flex-row md:col-span-6 md:items-center gap-5":
                  tripType === ROUND_TRIP,
                "gap-4": tripType !== ROUND_TRIP,
              }
            )}
          >
            <div className='sm:text-end mr-auto sm:mr-0'>
              <PriceInfo
                flightIndex={flightIndex}
                totalPrice={totalPrice}
                publishedPrice={publishedPrice}
                tavaMarkup={tavaMarkup}
                currencySymbol={currencySymbol}
                translationHandler={t}
              />
            </div>
            <div className='flex flex-col items-center gap-1'>
              <div
                className={classNames("p-2 flex items-center gap-1", {
                  "flex-col gap-1": tripType === ROUND_TRIP,
                })}
              >
                <button
                  className={classNames(
                    "py-2 px-4 flex items-center gap-2 rounded-md hover:bg-primary-300 active:bg-primary-200 border-none shadow-sm lg:text-sm text-primary-700 font-medium whitespace-nowrap text-xs",
                    {
                      "bg-primary-200": selected,
                      "bg-primary-100 ": !selected,
                    }
                  )}
                  onClick={() => handleFlightSelect(flights)}
                >
                  {isReissuanceFlight
                    ? t("flightResults.reissueFlight")
                    : t("flightResults.selectFlight")}
                </button>
                <button {...getToggleProps()} href=''>
                  <RenderSVG
                    Svg={isExpanded ? ChevronUp : ChevronDown}
                    alt='Toggle dropdown'
                    width='24'
                    height='24'
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames("flex mb-2 px-4", {
          "mb-6 md:mb-4": areSimilarFlightsAvailable && tripType !== ROUND_TRIP,
          "mb-6": areSimilarFlightsAvailable && tripType === ROUND_TRIP,
        })}
      >
        <div className='flex flex-1 flex-wrap gap-2'>
          {hasHighCO2Emission && (
            <div className='rounded-2xl  h-fit py-0.5 px-2 bg-green-100 text-green-900 text-[10px] font-medium flex items-center gap-[6px]'>
              <RenderSVG Svg={Cloud} width='14' height='14' alt='cloud-icon' />
              <span>{t("flightResults.lowCo2")}</span>
            </div>
          )}
          {checkInBaggage && (
            <div className='text-[10px] h-fit font-semibold whitespace-nowrap bg-contrast-200 text-primary-900 rounded-2xl py-0.5 px-2 flex items-center'>
              <RenderSVG Svg={Baggage} width='14' height='14' alt='baggage' />
              <span className='ml-2'>
                {t("flightResults.baggageAllowed")}: {checkInBaggage}
              </span>
            </div>
          )}
          {isPanRequiredAtTicket && (
            <div className='text-xs h-fit font-medium bg-contrast-200 text-black rounded-2xl py-0.5 px-2 flex items-center'>
              {t("flightResults.panRequired")}
            </div>
          )}
          <FlightDetailsTag
            refundableStatus={isRefundable}
            miniFareRules={miniFareRules}
            noOfAvailableSeats={noOfAvailableSeats}
          />
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className='py-4 pl-8 sm:pl-14 md:pl-20 pr-4 border-t border-contrast-200'>
          <FlightJourneyDetails segments={segments} />
        </div>
      </div>
    </div>
  );
};

export default memo(OneWayCard);
