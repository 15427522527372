import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import createStore from "./createStore";
import rootReducer from "./rootReducer";
import SessionProvider from "./infrastructure/SessionProvider/SessionProvider";
import RoutesLayout from "./infrastructure/RoutesLayout";
import EffectController from "./components/organisms/EffectController";
import TenantThemeProvider from "./TenantThemeProvider";
import { DEFAULT_VALUES } from "./constants";

const { EMPTY_STRING } = DEFAULT_VALUES;

const store = createStore(rootReducer);

const App = () => {
  return (
    <>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GG_APP_ID || EMPTY_STRING}
      >
        <Provider store={store}>
          <Router>
            <TenantThemeProvider>
              <SessionProvider>
                <EffectController />
                <RoutesLayout />
                <ToastContainer position={toast.POSITION.TOP_RIGHT} />
              </SessionProvider>
            </TenantThemeProvider>
          </Router>
        </Provider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
