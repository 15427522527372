import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { REQUEST_METHODS } from "../../constants";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { createQueryString } from "../../helper";

const { GET, PATCH, DELETE, POST } = REQUEST_METHODS;
const { FETCH_USERS, UPDATE_USERS, FETCH_INVITES, DELETE_USERS, ADD_TRAVELER } =
  SPINNER_NAMES;

export const getUsersList = createAsyncThunk(
  "get-users",
  async (query = {}, thunkArgs) => {
    const {
      pageNumber = "",
      pageSize = "",
      searchKey = "",
      action = "",
      sortBy,
      sortOn
    } = query;
    const queryParams = createQueryString({
      pageNumber,
      pageSize,
      searchKey:encodeURIComponent(searchKey),
      action,
      sortBy,
      sortOn
    });
    return await asyncAction({
      url: `users${queryParams}`,
      methodType: GET,
      spinner: FETCH_USERS,
      showErrorToast: true,
      errorMessage: "Failed to fetch users",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const updateUser = createAsyncThunk(
  "update-user",
  async ({ id, body }, thunkArgs) => {
    return await asyncAction({
      url: `users/${id}`,
      methodType: PATCH,
      spinner: UPDATE_USERS,
      body: JSON.stringify(body),
      showErrorToast: true,
      errorMessage: "Failed to update users",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const getInvitedUsersList = createAsyncThunk(
  "get-invites",
  async (query = {}, thunkArgs) => {
    const { tenantId, pageNumber, pageSize, searchKey } = query;
    const queryParams = createQueryString({
      pageNumber,
      pageSize,
      searchKey: encodeURIComponent(searchKey),
    });
    return await asyncAction({
      url: `tenants/${tenantId}/invitations${queryParams}`,
      methodType: GET,
      spinner: FETCH_INVITES,
      showErrorToast: true,
      errorMessage: "Failed to fetch invites",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const deleteUser = createAsyncThunk(
  "delete-user",
  async ({ body }, thunkArgs) => {
    const { id } = body;
    return await asyncAction({
      url: `users/${id}`,
      methodType: DELETE,
      spinner: DELETE_USERS,
      showErrorToast: true,
      errorMessage: "Failed to delete user",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const addUser = createAsyncThunk(
  "add-user",
  async (requestBody, thunkArgs) => {
    return await asyncAction({
      url: "/travelers",
      methodType: POST,
      body: JSON.stringify(requestBody),
      spinner: ADD_TRAVELER,
      showErrorToast: true,
      errorMessage: "Failed to add user",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);
