import classNames from "classnames";
import { BadgePropsTypes } from "../../prop-types";
import { BADGE_LABELS } from "../../constants";

const {
  CONFIRMED,
  AWAITING_PAYMENT,
  IN_REVIEW,
  DRAFT,
  COMPLETED,
  PENDING,
  FAILED,
  PARTIALLY_CONFIRMED,
  CANCELLED
} = BADGE_LABELS;

const getFormattedBadgeValue = (value) =>
  value?.toLowerCase().replace("_", " ");

const Badge = ({ value, shouldCapitalizeFirstLetter = true }) => (
  <div
    className={classNames(
      `rounded-2xl py-0.5 px-[10px] text-xs font-medium inline-flex items-center gap-[6px]`,
      {
        "bg-green-100 text-green-900": value === CONFIRMED || value === COMPLETED,
        "bg-yellow-100 text-yellow-800": value === AWAITING_PAYMENT || value === PENDING || value === PARTIALLY_CONFIRMED,
        "bg-gray-100 text-gray-800": value === DRAFT,
        "bg-red-100 text-red-800": value === FAILED || value === CANCELLED,
        "bg-purple-100 text-purple-800": value === IN_REVIEW,
        capitalize: shouldCapitalizeFirstLetter,
      }
    )}
  >
    <span>
      {shouldCapitalizeFirstLetter ? getFormattedBadgeValue(value) : value}
    </span>
  </div>
);

Badge.propTypes = BadgePropsTypes;

export default Badge;
