import { createSlice } from "@reduxjs/toolkit";
import { fetchFlightPrices } from "./flightResults.actions";
import { DEFAULT_VALUES, FLIGHTS_RESULT_TYPE } from "../../constants";
import { getFormattedFlightPriceResponse } from "../../ResponseMapper";

const { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;

const initialState = {
  flightPriceInfo: EMPTY_ARRAY,
  selectedFlightInfo: {},
  currentFlightType: OUTBOUND_RESULT,
  flightPriceReq: null,
  tripType: EMPTY_STRING,
  roundTripResultFormat: EMPTY_STRING,
  selectedFlightId: {
    inbound: null,
    outbound: null,
    packages: null,
  },
  isInternationalFlight: false,
  isPrePackaged: true,
  selectedFlightIndexInfo: {},
  selectedFlightOptions: {},
  selectedFilteredFlights: {},
};

const slice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    setSelectedFlightInfo(state, action) {
      if (!action.payload) return;
      state.selectedFlightInfo = action.payload.key
        ? {
            ...state.selectedFlightInfo,
            ...action.payload,
          }
        : action.payload;
    },
    setCurrentFlightType(state, action) {
      state.currentFlightType = action.payload;
    },
    setFlightPriceInfo(state, action) {
      state.flightPriceInfo = action.payload;
    },
    resetFlightPriceInfo(state) {
      state.flightPriceInfo = EMPTY_ARRAY;
      state.flightPriceReq = null;
    },
    setFlightPriceReq(state, action) {
      if (action.payload) state.flightPriceReq = action.payload;
    },
    setSelectedTripType(state, action) {
      state.tripType = action.payload;
    },
    setRoundTripResultFormat(state, action) {
      state.roundTripResultFormat = action.payload;
    },
    setSelectedFlightId(state, action) {
      state.selectedFlightId = { ...state.selectedFlightId, ...action.payload };
    },
    setFilteredFlights(state, action) {
      state.filteredFlights = action.payload;
    },
    setIsInternational(state, action) {
      state.isInternationalFlight = action.payload;
    },
    setIsPrePackagedFlights(state, action) {
      state.isPrePackaged = action.payload;
    },
    setFlightResults(state, action) {
      if (action.payload) return action.payload;
    },
    setSelectedFlightIndexInfo(state, action) {
      state.selectedFlightIndexInfo = action.payload;
    },
    setSelectedFlightOptions(state, action) {
      if(!Object.entries(action.payload).length){
        state.selectedFlightOptions = {}
      }else{
        state.selectedFlightOptions = {
          ...state.selectedFlightOptions,
          ...action.payload,
        };
      }
    },
    setSelectedFilteredFlights(state, action) {
      state.selectedFilteredFlights = action.payload;
      if(!Object.entries(action.payload).length){
        state.selectedFilteredFlights = {}
      }else{
        state.selectedFilteredFlights = {
          ...state.selectedFilteredFlights,
          ...action.payload,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFlightPrices.fulfilled, (state, action) => {
      if (action.payload.error) return;
      if (action.payload.some((item) => item === undefined)) return;
      state.flightPriceInfo = action.payload;
      state.currentFlightType = OUTBOUND_RESULT;
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
