import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { debounce, get } from "lodash";
import { Range } from "react-range";
import { useCollapse } from "react-collapsed";
import classNames from "classnames";
import { ChevronDown, RenderSVG } from "../../../assets/icons";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import { selectCountryInfo } from "../../Profile";
import { selectTenantDetails } from "../../Auth";
import {
  DEBOUNCE_TIME,
  DEFAULT_VALUES,
  CURRENCY_SYMBOLS,
} from "../../../constants";
import { selectHotelsActiveFilters, selectHotelsSortingValues, setHotelsActiveFilters } from "../../../components/organisms/Search";

const { EMPTY_STRING, ZERO, ONE, HUNDRED } = DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;
const PriceFilter = ({
  header = EMPTY_STRING,
  resetFilters,
  setResetFilters,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const tenantDetails = useSelector(selectTenantDetails);
  const sortingValues = useSelector(selectHotelsSortingValues);
  const priceData = sortingValues?.price;
  const filters = useSelector(selectHotelsActiveFilters);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  const maxPriceRange = priceData.maxPrice;
  const minPriceRange =
    priceData.minPrice === priceData.maxPrice
      ? priceData.minPrice - ONE
      : priceData.minPrice;
  const isSliderDisabled = priceData.minPrice === priceData.maxPrice;

  const initialValuesArray = [
    filters.price.minPrice || minPriceRange,
    filters.price.maxPrice || maxPriceRange,
  ];

  const [priceRangeValues, setPriceRangeValues] = useState(initialValuesArray);
  const [sliderValues, setSliderValues] = useState(initialValuesArray);
  const [showError, setShowError] = useState(false);

  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const tenantConfig = get(tenantDetails, "tenantConfig");

  useEffect(() => {
    if (resetFilters) {
      dispatch(
        setHotelsActiveFilters({
          ...filters,
          minPrice: minPriceRange,
          maxPrice: maxPriceRange,
        })
      );
      setSliderValues([minPriceRange, maxPriceRange]);
      setPriceRangeValues([minPriceRange, maxPriceRange]);
      setResetFilters(false);
    }
  }, [resetFilters]);

  useEffect(
    debounce(() => {
      const isMaxPriceChanged = priceData.maxPrice !== sliderValues[ONE]
      const isMinPriceChanged = priceData.minPrice !== sliderValues[ZERO]
      setPriceRangeValues(sliderValues);
      dispatch(setHotelsActiveFilters({
        ...filters,
        price : {minPrice: isMinPriceChanged ? sliderValues[ZERO]:"",
        maxPrice: isMaxPriceChanged ? sliderValues[ONE]:""}
      }));
    }, DEBOUNCE_TIME),
    [sliderValues]
  );

  useEffect(() => {
    const [minPrice, maxPrice] = priceRangeValues;
    if (
      minPrice && maxPrice &&
      (+minPrice <= +maxPrice &&
        +minPrice >= +minPriceRange &&
        +maxPrice <= +maxPriceRange &&
        +maxPrice > +minPriceRange)
    )
      setShowError(false);
    else setShowError(true);
  }, [priceRangeValues, minPriceRange, maxPriceRange]);

  const handleMinValueChange = (e) => {
    let { value } = e.target;
    value = Number(value);
    setPriceRangeValues([value, priceRangeValues[ONE]]);
    if (
      value >= minPriceRange &&
      value <= maxPriceRange &&
      value < priceRangeValues[ONE] &&
      priceRangeValues[ONE] <= maxPriceRange
    )
      setSliderValues([value, priceRangeValues[ONE]]);
  };

  const handleMaxValueChange = (e) => {
    let { value } = e.target;
    value = Number(value);
    setPriceRangeValues([priceRangeValues[ZERO], value]);
    if (
      value <= maxPriceRange &&
      value >= minPriceRange &&
      value > priceRangeValues[ZERO] &&
      priceRangeValues[ZERO] >= minPriceRange
    )
      setSliderValues([priceRangeValues[ZERO], value]);
  };

  const getTrackBackground = ({ colors, min, max }) => {
    const progressValues = sliderValues
      .slice(ZERO)
      .sort((a, b) => a - b)
      .map((value) => ((value - min) / (max - min)) * HUNDRED);

    const middle = progressValues.reduce(
      (acc, point, index) =>
        `${acc}, ${colors[index]} ${point}%, ${colors[index + ONE]} ${point}%`,
      ""
    );

    return `linear-gradient(to right, ${colors[ZERO]} 0% ${middle}, ${
      colors[colors.length - ONE]
    } 100%)`;
  };

  return (
    <>
      <div className='flex items-center gap-2' {...getToggleProps()}>
        <h4 className='flex-1 text-sm font-semibold text-contrast-800'>
          {t(header)}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            className={classNames({ "rotate-180": isExpanded })}
            alt='Chevron Down Icon'
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className='pt-6 px-3 w-full'>
          <div className='range-slider mt-10 relative h-1 mb-6'>
            <div
              className={classNames("slider-main h-full absolute w-full", {
                "cursor-not-allowed": isSliderDisabled,
              })}
            >
              <Range
                disabled={isSliderDisabled}
                min={minPriceRange}
                max={maxPriceRange}
                values={sliderValues}
                onChange={setSliderValues}
                renderTrack={({
                  props: { onMouseDown, onTouchStart, ref },
                  children,
                }) => (
                  <div onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
                    <div
                      ref={ref}
                      className='h-1 w-full rounded bg-contrast-300 self-center'
                      style={{
                        background: getTrackBackground({
                          colors: [
                            "#D1D5DB",
                            tenantConfig.primaryColor,
                            "#D1D5DB",
                          ],
                          min: minPriceRange,
                          max: maxPriceRange,
                        }),
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, index }) =>
                  !(isSliderDisabled && index === ZERO) && (
                    <div
                      {...props}
                      className={classNames(
                        "h-4 w-4 bg-white flex justify-center items-center rounded-[50%] border border-solid border-contrast-300 !cursor-pointer",
                        { "!cursor-not-allowed": isSliderDisabled }
                      )}
                    >
                      <div className='absolute text-sm tooltip tooltip-left !translate-x-0 whitespace-nowrap'>
                        {currencySymbol}&nbsp;
                        {sliderValues[index]}
                      </div>
                      <div />
                    </div>
                  )
                }
              />
            </div>
          </div>
          <div className='grid grid-cols-2 gap-4'>
            <div className='col-span-1'>
              <div className='form-group'>
                <label className='text-xs text-contrast-900 font-medium mb-1'>
                  {t("flightResults.filters.priceRange.minimum")}
                </label>
                <input
                  type='number'
                  min={minPriceRange}
                  max={sliderValues[ONE]}
                  disabled={isSliderDisabled}
                  placeholder='Min Price'
                  value={priceRangeValues[ZERO]}
                  onChange={handleMinValueChange}
                  className='w-full h-auto text-xs py-[9px] px-3 rounded-md shadow-sm border border-contrast-300 text-contrast-900 disabled:cursor-not-allowed'
                />
              </div>
            </div>
            <div className='col-span-1'>
              <div className='form-group'>
                <label className='text-xs text-contrast-900 font-medium mb-1'>
                  {t("flightResults.filters.priceRange.maximum")}
                </label>
                <input
                  type='number'
                  min={sliderValues[ZERO]}
                  max={maxPriceRange}
                  disabled={isSliderDisabled}
                  placeholder='Max Price'
                  value={priceRangeValues[ONE]}
                  onChange={handleMaxValueChange}
                  className='w-full h-auto text-xs py-[9px] px-3 rounded-md shadow-sm border border-contrast-300 text-contrast-900 disabled:cursor-not-allowed'
                />
              </div>
            </div>
          </div>
          <div className="col-span-2 text-center max-w-full">
            {showError && (
              <ErrorMessage
                errorMessage={`${t(
                  "flightResults.filters.priceRange.priceError"
                )}[${currencySymbol} ${minPriceRange}
                - ${currencySymbol} ${maxPriceRange}]`}
                className="max-w-xs mx-auto"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceFilter;
