import * as Yup from "yup";
import { getRequiredErrorMessage } from "./getErrorMessage";
import { REGEX } from "../constants";
import { isEmpty } from "lodash";

const { NAME, PASSPORT_NUMBER, PANCARD_NUMBER } = REGEX;

export const getPrimaryPaxValidationSchema = (t = () => {}) => ({
  firstName: Yup.string()
    .trim()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .required(getRequiredErrorMessage("profilePage.firstName", t))
    .min(2, t("profilePage.errors.minLength"))
    .max(25, t("profilePage.errors.maxLength")),
  middleName: Yup.string()
    .trim()
    .default("")
    .test(
      "is-middleName-valid",
      `${t("profilePage.errors.nameFormat")}`,
      function (value) {
        if (!value) return true;
        return NAME.test(value);
      }
    )
    .max(25, t("profilePage.errors.maxLength")),
  lastName: Yup.string()
    .trim()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .required(getRequiredErrorMessage("profilePage.lastName", t))
    .min(2, "Last Name must have atleast 2 letters")
    .max(40, t("profilePage.errors.maxLength")),
});

export const getPassportValidationSchema = () => ({
  passportDetails: Yup.object().shape({
    passportNumber: Yup.string()
      .required("Passport Number is required")
      .matches(PASSPORT_NUMBER, "Invalid Passport Number"),
    issueCountry: Yup.object().shape({
      isoCode: Yup.string().required("Issuing Country is required"),
    }),
    issuedDate: Yup.string().required("Issuing Date is required"),
    expiryDate: Yup.string().required("Expiry Date is required"),
  }),
});

const validatePanWithName = (panCardNumber, surname) => {
  const validPanTypes = {
    'P': 'Individual',
    'F': 'Firm',
    'C': 'Company',
    'H': 'HUF',
    'A': 'Association',
    'T': 'Trust'
  };

  const normalizedSurname = surname?.trim()?.toUpperCase();

  const panTypeForCharacter = validPanTypes[panCardNumber[3]];
  if (!panTypeForCharacter) {
    return `Invalid PAN type. Expected type for ${panCardNumber[3]}: ${panTypeForCharacter || 'Unknown'}`;
  }
  if (normalizedSurname) {
    if (panCardNumber[4] !== normalizedSurname[0]) {
      return "The 5th character of PAN doesn't match the first letter of the surname.";
    }
  }
  return null;
};

export const getPanValidationSchema = () => {
  return Yup.object().shape({
    panCardDetails: Yup.object().shape({
      isChild: Yup.boolean().default(false),
      surname: Yup.string().default(''),
      panCardNumber: Yup.string().default('')
        .test('is-pan-required', 'PAN number is required', function (value) {
          const { isChild = false } = this.parent || {}
          if (isChild === false) {
            return !!value;
          }
          return true;
        })
        .test('is-pan-valid', 'Invalid PAN number', function (value) {
          const { isChild = false } = this.parent || {}
          if (!value || isChild) return true;
          return PANCARD_NUMBER.test(value);
        })
        .test('validate-pan-with-name', 'Invalid PAN number', function () {
          const { panCardNumber, surname, isChild = false } = this.parent;
          if (isEmpty(panCardNumber) || isChild) return true;
          return validatePanWithName(panCardNumber, surname) === null;
        }),
    }),
  });
};

export const decodeHtml = (html) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const checkingGuestValidation = (validationSchema, travelers = []) => {
  const paxValidationStatus = travelers.reduce((acc, guest) => {
    const { id = EMPTY_STRING, isSelected = false } = guest;
    if (!isSelected) return acc;
    try {
      validationSchema.validateSync(guest);
    } catch (err) {
      acc[id] = err.message;
    }
    return acc;
  }, {});

  return paxValidationStatus;
};
