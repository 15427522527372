import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { SKELETON_COLOR } from "../../../constants";

const { BASE_COLOR, HIGHLIGHT_COLOR } = SKELETON_COLOR;

const InformationDetailsSkeleton = () => (
  <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHLIGHT_COLOR}>
    <div className='w-full px-2'>
      <Skeleton
        width={"50%"}
        height={20}
        style={{ margin: "auto", display: "block" }}
      />
    </div>
    <div className='w-full px-2'>
      <Skeleton
        width={"90%"}
        height={20}
        style={{ margin: "auto", display: "block" }}
      />
    </div>
    <div className=' w-full pt-4 px-2'>
      <Skeleton width={"100%"} height={30} />
    </div>
    <div className=' w-full pt-4 px-2'>
      <Skeleton width={"100%"} height={30} />
    </div>
    <div className='py-4 px-2'>
      <Skeleton width={"100%"} height={30} />
    </div>
  </SkeletonTheme>
);

export default InformationDetailsSkeleton;
