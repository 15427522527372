import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../../infrastructure/asyncAction";
import { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import { DEFAULT_VALUES, REQUEST_METHODS, ENDPOINTS } from "../../../constants";
import { createQueryString } from "../../../helper";

const {
  SPECIAL_SERVICES,
  BOOK_FLIGHT,
  REISSUANCE_FLIGHT,
  FLIGHT_PAYMENT,
  BOOK_CALLBACK,
  PROMPT_SEARCH,
} = SPINNER_NAMES;
const { POST, GET, PATCH } = REQUEST_METHODS;
const { EMPTY_STRING } = DEFAULT_VALUES;
const { TBO_BOOKING_URL, TBO_REISSUANCE_URL } = ENDPOINTS;
const { REACT_APP_OPEN_AI_URL } = process.env;

export const fetchSpecialServices = createAsyncThunk(
  "fetch-special-services",
  async (requestBody, thunkArgs) => {
    const params = {
      url: `/flights/special-services`,
      methodType: POST,
      errorMessage: "Failed to load seat map.",
      showErrorToast: true,
      spinner: SPECIAL_SERVICES,
      ...thunkArgs,
    };

    return await Promise.all(
      requestBody.map((request) =>
        asyncAction({
          body: JSON.stringify(request),
          ...params,
        })
      )
    );
  }
);

export const initiateBooking = createAsyncThunk(
  "initiate-booking",
  async (requestBody, thunkArgs) => {
    return await asyncAction({
      url: TBO_BOOKING_URL,
      methodType: POST,
      body: JSON.stringify(requestBody),
      spinner: BOOK_FLIGHT,
      showErrorToast: true,
      isAuthRequired: true,
      errorMessage: "Failed to book Flight",
      ...thunkArgs,
    });
  }
);

export const reissuance = createAsyncThunk(
  "reissuance",
  async (requestBody, thunkArgs) => {
    return await asyncAction({
      url: TBO_REISSUANCE_URL,
      methodType: POST,
      body: JSON.stringify(requestBody),
      spinner: REISSUANCE_FLIGHT,
      showErrorToast: true,
      isAuthRequired: true,
      errorMessage: "Failed to reissuance Flight",
      ...thunkArgs,
    });
  }
);

export const initiatePayment = createAsyncThunk(
  "flight-payment",
  async (requestBody, thunkArgs) => {
    return await asyncAction({
      url: "payment",
      methodType: POST,
      body: requestBody,
      spinner: FLIGHT_PAYMENT,
      isAuthRequired: true,
      errorMessage: "Checkout payment failed.",
      ...thunkArgs,
    });
  }
);

export const paymentGateway = createAsyncThunk(
  "payment-gateway",
  async ({ requestBody, bookingId }, thunkArgs) => {
    const queryString = createQueryString({ bookingId });

    return await asyncAction({
      url: `/paymentGatewayCallback${queryString}`,
      methodType: POST,
      body: requestBody,
      errorMessage: "Failed to generate booking.",
      ...thunkArgs,
    });
  }
);

export const getPromoCodes = createAsyncThunk(
  "get-promocodes",
  async (thunkArgs) => {
    return await asyncAction({
      url: "promocodes",
      methodType: GET,
      errorMessage: "Failed to fetch promocodes",
      ...thunkArgs,
    });
  }
);

export const bookCallback = createAsyncThunk(
  "book-callback",
  async ({ queryParams }, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `/v2/book-callback${queryString}`,
      methodType: GET,
      spinner: BOOK_CALLBACK,
      abortOnPageChange: false,
      errorMessage:
        "Unable to complete booking currently. Please try again later.",
      ...thunkArgs,
    });
  }
);

export const reissuanceCallback = createAsyncThunk(
  "reissuance-callback",
  async ({ requestBody, queryParams }, thunkArgs) => {
    const queryString = queryParams
      ? createQueryString(queryParams)
      : EMPTY_STRING;

    return await asyncAction({
      url: `/reissuance-callback${queryString}`,
      methodType: POST,
      body: JSON.stringify(requestBody),
      spinner: BOOK_CALLBACK,
      abortOnPageChange: false,
      errorMessage:
        "Unable to complete booking currently. Please try again later.",
      ...thunkArgs,
    });
  }
);

export const updateBookingStatus = createAsyncThunk(
  "update-booking-status",
  async ({ requestBody, id }, thunkArgs) => {
    return await asyncAction({
      url: `/bookings/${id}`,
      methodType: PATCH,
      body: requestBody,
      abortOnPageChange: false,
      errorMessage:
        "Unable to update the status of booking, please try again later.",
      ...thunkArgs,
    });
  }
);

export const fetchPromptResult = createAsyncThunk(
  "fetchPromptResult",
  async (prompt, thunkArgs) => {
    return await asyncAction({
      url: REACT_APP_OPEN_AI_URL,
      methodType: POST,
      body: {
        model: "gpt-4",
        messages: [
          {
            role: "user",
            content: prompt,
          },
        ],
        max_tokens: 600,
        temperature: 0.7,
      },
      httpHeaders: {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_TOKEN}`,
        },
      },
      spinner: PROMPT_SEARCH,
      shouldAllowCustomHeaders: false,
      errorMessage: "Failed to process prompt, please try again",
      ...thunkArgs,
    });
  }
);
