import { useState } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { Cross, RenderSVG, ToggleDirection } from "../../../assets/icons";
import ErrorMessage from "../../atoms/ErrorMessage";
import {
  FlightClass,
  LocationPicker,
  TravelersCount,
  DateSelector,
} from "../../molecules";
import {
  DATE_TYPE_TITLES,
  DEFAULT_VALUES,
  LOCATION_TYPE,
  TRIP_TYPES,
  SEARCH_SECTION,
  DATE_FORMAT,
  CURRENT_DATE
} from "../../../constants";
import { selectTenantDetails } from "../../../screens/Auth";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const { ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { FROM, TO } = LOCATION_TYPE;
const { DEPARTURE, RETURN } = DATE_TYPE_TITLES;
const { ROUND_TRIP, ONE_WAY, MULTI_CITY } = TRIP_TYPES;
const { FLIGHT } = SEARCH_SECTION;

const getNextDate = (dateString) => {
  const originalDate = new Date(dateString);
  const oneDayAfter = new Date(originalDate);
  oneDayAfter.setDate(originalDate.getDate() + ONE);
  return oneDayAfter.toISOString().split("T")[ZERO];
};

const RoundTripSearch = ({
  handleLocationSwap,
  handleLocationChange,
  footer,
}) => {
  const { state } = useLocation();
  const tenantDetails = useSelector(selectTenantDetails);
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const { values, touched, errors, setFieldValue } = useFormikContext();
  const { tripType, journey = [] } = values;
  const { returnDate } = journey[ZERO] || {};
  const { isReissuance = false } = state || EMPTY_OBJECT;
  useEffect(() => {
    !isEmpty(returnDate) && setFieldValue("tripType", ROUND_TRIP);
  }, [returnDate]);
  const renderFieldError = (name) =>
    get(errors, name, false) &&
    get(touched, name, false) && (
      <ErrorMessage errorMessage={get(errors, name)} />
    );

  const handleDepartureDateChange = (values) => {
    const startDate = get(values, "0");
    const endDate = get(values, "1");
    const formattedStartDate = startDate?.format(DATE_FORMAT);
    const formattedEndDate = endDate?.format(DATE_FORMAT);
    setFieldValue("journey[0].departureDate", formattedStartDate);
    setFieldValue("journey[0].returnDate", formattedEndDate);
  };

  const handleCloseDatePicker = () => {
    if (tripType !== ROUND_TRIP) return;
    const departureDate = get(values, "journey[0].departureDate");
    const endDate = get(values, "journey[0].returnDate");
    !endDate &&
      setFieldValue("journey[0].returnDate", getNextDate(departureDate));
  };

  const [ SVGRotate, setSVGRotate ] = useState(0);
  const rotateSVG = () => {
    setSVGRotate(prevRotation => prevRotation === 0 ? 180 : 0)
  }

  
  return (
    <div>
      <div className='grid grid-cols-12 gap-2 xl:gap-2'>
        <div className='col-span-12 md:col-span-7 lg:col-span-4 grid grid-cols-2 gap-4 relative'>
          <span
            className='absolute z-10 left-1/2 -translate-x-1/2 top-11 sm:top-6 sm:-translate-y-1/2 cursor-pointer rotate-90 sm:rotate-0'
            // onClick={() => {
            //   !isReissuance && handleLocationSwap(ZERO, values, setFieldValue);
            // }}
            onClick={() => {
              if(!isReissuance){
                handleLocationSwap(ZERO, values, setFieldValue);
                rotateSVG()
              }
            }}
          >
            <RenderSVG
              Svg={ToggleDirection}
              width='32'
              height='32'
              alt='Toggle Direction'
              stroke={isReissuance ? "#616161" : tenantConfig.primaryColor}
              style={{ transform: `rotate(${SVGRotate}deg)`, transition: 'transform 0.3s ease-in-out' }}
            />
          </span>
          <LocationPicker
            name={`journey[0].originCode`}
            handleLocationChange={handleLocationChange}
            type={FROM}
            source={FLIGHT}
          />
          <LocationPicker
            name={`journey[0].destCode`}
            handleLocationChange={handleLocationChange}
            type={TO}
            source={FLIGHT}
          />
        </div>
        <div className='col-span-12 md:col-span-5 lg:col-span-4'>
          <div className='flex flex-col sm:flex-row gap-2'>
            <DateSelector
              showPrices={false}
              placeholder={DEPARTURE}
              name='journey[0].departureDate'
              defaultDates={[
                get(values, "journey[0].departureDate"),
                get(values, "journey[0].returnDate"),
              ]}
              tripType={tripType}
              range={tripType === ROUND_TRIP}
              showCalendarIcon={true}
              minDate={CURRENT_DATE}
              height='53px'
              inputClasses='!rounded-md !w-full !text-left'
              handleChangeFromProps={handleDepartureDateChange}
              fareCalendarPrices={{
                origin: values.journey[ZERO].originCode.iata,
                destination: values.journey[ZERO].destCode.iata,
              }}
              onClose={handleCloseDatePicker}
            />
            <div className='relative w-full'>
              <DateSelector
                showPrices={false}
                placeholder={RETURN}
                name={`journey[0].returnDate`}
                tripType={ONE_WAY}
                showCalendarIcon={true}
                inputClasses='!rounded-md'
                minDate={get(values, "journey[0].departureDate")}
                height='53px'
                fareCalendarPrices={{
                  origin: values.journey[ZERO].originCode.iata,
                  destination: values.journey[ZERO].destCode.iata,
                }}
              />
              {tripType === ROUND_TRIP && (
                <button
                  type='button'
                  className='absolute top-0 right-0 translate-x-1/3 -translate-y-1/3 rounded-full p-0.5 bg-white border border-gray-600 hover:border-2 hover:border-primary-600'
                  onClick={() => {
                    setFieldValue("journey[0].returnDate", "");
                    setFieldValue("tripType", ONE_WAY);
                  }}
                >
                  <RenderSVG
                    Svg={Cross}
                    className='text-primary-900'
                    width='15'
                    height='15'
                  />
                </button>
              )}
            </div>
          </div>
          <div>{renderFieldError("journey[0].returnDate")}</div>
        </div>
        <div className='col-span-12 lg:col-span-4 flex flex-col md:flex-row gap-3 2xl:gap-4'>
          <div
            className={classNames("gap-2", {
              "flex flex-col md:flex-row": tripType === MULTI_CITY,
              "w-full grid grid-cols-12":
                tripType === ROUND_TRIP || tripType === ONE_WAY,
            })}
          >
            <FlightClass />
            <TravelersCount />
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundTripSearch;
