import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import approvalsData from "./../../assets/json/approvals.json";
import {
  CarIcon,
  Filter,
  FlightIcon,
  HotelBedIcon,
  RenderSVG,
  RightIcon,
  SearchGreyIcon,
} from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { setSelectedModal } from "../../components/organisms/Modal";
import { useDispatch } from "react-redux";
import { MODALS } from "../../components/organisms/AppModals";

const { APPROVAL_FILTERS_MODAL } = MODALS;

const Approvals = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const thead = ["User", "Trip", "Dates", "Trip Types", "Status"];
  const tripTypeToIcon = {
    Flight: { Svg: FlightIcon, fill: "primary-600" },
    Hotel: { Svg: HotelBedIcon, fill: "#9D174D" },
    Car: { Svg: CarIcon },
  };
  const navigate = useNavigate();
  return (
    <div className='font-inter'>
      <div className='h-screen flex'>
        <main className='main flex-1 flex flex-col bg-contrast-100'>
          <div className='p-4 flex gap-8 bg-white border-contrast-200 border-b'>
            <div className='flex-1 relative'>
              <div className='flex-1 relative'>
                <RenderSVG
                  Svg={SearchGreyIcon}
                  className='absolute top-1/2 -translate-y-1/2 left-3'
                  width='20'
                  alt='Search Icon'
                />
                <input
                  type='text'
                  className='form-control block w-full text-sm pl-10 py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500'
                  placeholder='Trip or travelers names'
                />
              </div>
            </div>
            <button
              className={classNames(
                "relative py-2 px-4 ml-2 flex items-center gap-2 rounded-md disabled:cursor-not-allowed disabled:opacity-75 bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-600 font-medium"
              )}
              onClick={() => dispatch(setSelectedModal(APPROVAL_FILTERS_MODAL))}
              type='button'
            >
              <RenderSVG Svg={Filter} alt='Filter Icon' />
              {`${t("flightResults.filter")}`}
            </button>
          </div>

          <div className='p-4 flex-1 overflow-auto'>
            <div className='w-full overflow-auto relative max-h-[450px]'>
              <table className=' w-full bg-white text-sm shadow-sm'>
                <thead className='sticky top-0 border-collapse bg-contrast-50 border border-contrast-200'>
                  <tr>
                    {thead.map((item, index) => (
                      <th
                        key={index}
                        className='border border-slate-200 text-xs font-semibold px-4 py-3 text-contrast-500 text-left uppercase'
                      >
                        {item}
                      </th>
                    ))}
                    <th
                      width='80'
                      className='border border-slate-200 text-xs font-semibold px-4 py-3 text-contrast-500 text-left uppercase'
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {approvalsData.Approvals.ApprovedBookings.Booking.map(
                    (booking, index) => (
                      <tr key={index}>
                        <td className='border border-contrast-200 px-4 py-4 '>
                          <div className='flex gap-4 px-2'>
                            <div className='avatar shrink-0'>
                              <img
                                src={booking.traveler.travelerImg}
                                className='rounded-full'
                                width='40'
                                alt='user'
                              />
                            </div>
                            <div>
                              <h6 className='font-medium text-contrast-900 text-sm'>
                                {booking.traveler.travelerName}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className='border border-contrast-200 px-4 py-4 '>
                          <div className=' px-2'>
                            <h6 className='font-medium text-contrast-900 text-sm'>
                              {booking.trip.origin}, {booking.trip.dest}
                            </h6>
                          </div>
                        </td>
                        <td className='border border-contrast-200 px-4 py-4 '>
                          <p className='font-medium text-contrast-900 text-sm'>
                            {booking.dates.departDate}-
                            {booking.dates.arrivalDate}
                          </p>
                        </td>
                        <td className='border border-contrast-200 px-4 py-4 '>
                          <div className='flex gap-2'>
                            {booking.tripTypes.map((tripType, index) => (
                              <div
                                key={index}
                                className={classNames(
                                  "rounded-2xl py-0.5 px-[10px]  text-xs font-medium inline-flex items-center gap-[6px]",
                                  {
                                    "bg-blue-100 text-blue-800":
                                      tripType === "Flight",
                                    "bg-pink-100 text-pink-800":
                                      tripType === "Hotel",
                                    "bg-contrast-100 text-contrast-800":
                                      tripType === "Car",
                                  }
                                )}
                              >
                                <RenderSVG
                                  className='shrink-0'
                                  width='10'
                                  alt={`${tripType} Icon`}
                                  {...tripTypeToIcon[tripType]}
                                />
                                <span>{tripType}</span>
                              </div>
                            ))}
                          </div>
                        </td>
                        <td className='border border-contrast-200 px-4 py-4 '>
                          <div
                            className={classNames(
                              "rounded-2xl py-0.5 px-[10px] text-xs font-medium inline-flex items-center gap-[6px]",
                              {
                                "bg-green-100 text-green-900":
                                  booking.status === "Approved",
                                "bg-contrast-100 text-contrast-800":
                                  booking.status === "Waiting On Approval",
                                "bg-red-100 text-red-800":
                                  booking.status === "Rejected",
                                "bg-yellow-100 text-yellow-800":
                                  booking.status === "Partially Approved",
                              }
                            )}
                          >
                            <span>{booking.status}</span>
                          </div>
                        </td>
                        <td className='border border-contrast-200 px-4 py-4 '>
                          <button
                            className='shadow-sm hover:bg-contrast-50 active:bg-white border-contrast-300 border rounded-md p-2'
                            onClick={() => navigate(`${booking.id}`)}
                          >
                            <RenderSVG
                              Svg={RightIcon}
                              width='20'
                              alt='Right Icon'
                              fill='grey'
                            />
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Approvals;
