import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  RenderSVG,
  BadgeFlight,
  BadgeHotel,
  DownloadIcon
} from "../../../assets/icons";
import { TripsTablePropsTypes } from "../../../prop-types";
import {
  DEFAULT_VALUES,
  NOT_AVAILABLE,
  BOOKING_STATUS,
  BOOKING_TYPE,
  BOOKING_SEARCH_OPTIONS,
  ALLOWED_COLUMNS,
  SORT_ORDER,
  AUTH_ROLES
} from "../../../constants";
import Table from "../../../components/molecules/Table/Table";
import Badge from "../../../components/atoms/Badge";
import { ticketDownlaod, setToSessionStorage } from "../../../helper";
import Spinner, { selectActiveSpinners, SPINNER_NAMES } from "../../../components/organisms/Spinner";
import { downloadEticket, deleteBookingById } from "./trips.actions";
import { setSelectedTripId } from "./index";

const { EMPTY_ARRAY, EMPTY_STRING, ZERO, ONE } = DEFAULT_VALUES;
const { BOOKING_ID } = BOOKING_SEARCH_OPTIONS;
const COLUMN_HEADERS = {
  TRIP_ID: "Trip Id",
  TRIP_NAME: "Trip Name",
  COST_CODE: "Cost Code",
  DATE: "Date",
  STATUS: "Status",
  TRIP_TYPE: "Trip type",
};
const NO_TRIP_MATCH = "No Trip Match";
const { TRIP_ID, TRIP_NAME, COST_CODE, DATE, STATUS, TRIP_TYPE } =
  COLUMN_HEADERS;
const { DOWNLOAD_TICKET, DELETE_TRIP_BY_ID } = SPINNER_NAMES;
const { CONFIRMED, PARTIALLY_CONFIRMED } = BOOKING_STATUS;

const { FLIGHT, HOTEL } = BOOKING_TYPE;
const { ASCENDING, DESCENDING } = SORT_ORDER;

const DRAFT_CATEGORY = "DRAFT";
const CONFIRMED_CATEGORY = "CONFIRMED";
const ALL_CATEGORY = "ALL";

import { toast } from "react-toastify";
import { MODALS } from "../../../components/organisms/AppModals";
import { ExclamationCircleIcon, Trash } from "../../../assets/icons";
import DeleteConfirmationModal from "../../../components/organisms/AppModals/DeleteConfirmationModal";
import { selectUserInfo } from "../../Profile";
const { DELETE_CONFIRMATION_MODAL } = MODALS;

const createBookTypeArray = (bookType, flights, hotels) =>
  bookType
    ? bookType.split("_")
    : [
        ...(flights?.length > ZERO ? [FLIGHT] : EMPTY_ARRAY),
        ...(hotels?.length > ZERO ? [HOTEL] : EMPTY_ARRAY),
      ];

const IDFormatter = ({ row }) => {
  const { id } = row;
  return (
    <div className=''>
      <h6 className='font-medium text-contrast-900 text-sm'>{id}</h6>
    </div>
  );
};
const TripNameFormatter = ({ row }) => {
  const { tripName } = row;
  return tripName ? (
    <div className=''>
      <h6 className='font-medium text-contrast-900 text-sm'>{tripName}</h6>
    </div>
  ) : (
    NOT_AVAILABLE
  );
};

const DatesFormatter = ({ row }) => {
  const { date } = row;
  return isEmpty(date) ? (
    NOT_AVAILABLE
  ) : (
    <p className='font-medium text-contrast-900 text-sm'>
      {new Date(date).toDateString()}
    </p>
  );
};

const StatusFormatter = ({ row }) => {
  const { status } = row;
  return <Badge value={status} />;
};

const CostCodeFormatter = ({ row }) => {
  const { costCode } = row;
  return costCode ? (
    <div className=''>
      <h6 className='font-medium text-contrast-900 text-sm'>{costCode.costCode}</h6>
      <h6 className='font-medium text-contrast-900 text-sm'>({costCode.name})</h6>
    </div>
  ) : (
    NOT_AVAILABLE
  );
}

const TripTypeFormatter = ({ row }) => {
  const { bookType } = row;
  return (
    <div className='flex gap-2'>
      {bookType.map((type) => {
        return (
          <div
            key={type}
            className='rounded-2xl py-0.5 px-[10px] bg-contrast-300 text-contrast-800 text-xs font-medium inline-flex items-center gap-[6px]'
          >
            <RenderSVG
              Svg={type === FLIGHT ? BadgeFlight : BadgeHotel}
              className='shrink-0'
              width='10'
              alt='badge-icon'
            />{" "}
            <span className='capitalize'>{type.toLowerCase()}</span>
          </div>
        );
      })}
    </div>
  );
};

const ActionFormatter = ({ row }) => {
  const dispatch = useDispatch();
  const activeSpinners = useSelector(selectActiveSpinners);
  const [selectedModal, setSelectedModal] = useState(null);
  const deleteBookingTitle = "Delete Trip?";
  const deletePrimaryButtonText = "Delete";
  const handleClose = () => setSelectedModal(null);

  const { id, status, bookType, isFlightConfirmed, isHotelConfirmed } = row;
  const isDownloadTicketSpinnerActive = activeSpinners.some(
    (spinnerName) => spinnerName === `${DOWNLOAD_TICKET}_${id}`
  );

  const handleDownloadTicket = (event) => {
    event.stopPropagation();

    const params = bookType.reduce((acc, type) => {
      if (type === FLIGHT) return { ...acc, flight: true && isFlightConfirmed };
      if (type === HOTEL) return { ...acc, hotel: true && isHotelConfirmed };
    }, {});
    dispatch(
      downloadEticket({
        params,
        tavaBookingId: id,
      })
    ).then((res) => {
      if (res) {
        const flightContent =
          res?.payload?.output?.htmlContent?.flightPdf;
        const hotelContent =
          res?.payload?.output?.htmlContent?.hotelPdf;
        if (flightContent) {
          ticketDownlaod(flightContent, id);
        }
        if (hotelContent) {
          ticketDownlaod(hotelContent, id)
        }
      }
    });
  }

  const handleSubmitDelete = (params) =>{
    dispatch(
      deleteBookingById(params)
    ).then((res) => {
      if(res.payload) {
        toast.success(`Trip has been deleted! `)
      }
      handleClose();
    });
  }

  return (
    <div onClick={(e) => { e.stopPropagation() }}>
      {(status === CONFIRMED || status === PARTIALLY_CONFIRMED) && (
        <div className='flex justify-center'>
          <button
            className='hover:bg-contrast-50 active:bg-white p-2 hover:'
            disabled={isDownloadTicketSpinnerActive}
            onClick={(e) => handleDownloadTicket(e)}
          >
            <Spinner name={`${DOWNLOAD_TICKET}_${id}`} size='w-[18px] h-[18px]'>
              <RenderSVG
                Svg={DownloadIcon}
                width='50'
                alt='chevron-right-icon'
                height='50'
                className='hover:bg-contrast-200 rounded-full p-3'
              />
            </Spinner>
          </button>
        </div>
      )}
      {status === "DRAFT" && (
        <div
          className='flex justify-center'
          onClick={(e) => {
            e.stopPropagation();
            setSelectedModal(DELETE_CONFIRMATION_MODAL);
          }}
        >
          <RenderSVG
            Svg={Trash}
            width='50'
            height='50'
            alt='delete-icon'
            className='hover:bg-contrast-200 rounded-full p-3'
          />
        </div>
      )}
      {selectedModal === DELETE_CONFIRMATION_MODAL && (
        <DeleteConfirmationModal
          title={deleteBookingTitle}
          primaryButtonText={deletePrimaryButtonText}
          iconName={ExclamationCircleIcon}
          handleClose={handleClose}
          handleSubmit={()=>handleSubmitDelete(id)}
          spinnerName={DELETE_TRIP_BY_ID}
        >
          <div className="flex flex-col gap-6 text-gray-700">
            <div className=''>Are you sure you want to delete this Trip?</div>
            <div className=''>Deleted Trip can't be retrieved.</div>
          </div>
        </DeleteConfirmationModal>
      )}
    </div>
  );
};

const DEFAULT_COLUMNS = [
  { text: TRIP_ID, formatter: <IDFormatter /> },
  {
    text: TRIP_NAME,
    formatter: <TripNameFormatter />,
    allowSorting: true,
    sortAsc: false
  },
  {
    text: COST_CODE,
    formatter: <CostCodeFormatter />,
  },
  {
    text: DATE,
    formatter: <DatesFormatter />,
    allowSorting:true,
    sortAsc: false
  },
  {
    text: STATUS,
    formatter: <StatusFormatter />,
  },
  {
    text: TRIP_TYPE,
    formatter: <TripTypeFormatter />,
  },
];

const TripsTable = ({ trips, tripCategory, setSortCriteria }) => {
  const [rowData, setRowData] = useState(EMPTY_ARRAY);
  const [columns, setColumns] = useState(DEFAULT_COLUMNS)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const {role, permissions} = userInfo;


  const handleBookingNavigation = (row) => {
    const { id } = row;
    dispatch(setSelectedTripId(id));
    setToSessionStorage(BOOKING_ID, id);
    navigate(id);
  };

  const handleColumnClick = (col, sortasc, index) => {
    if (ALLOWED_COLUMNS[col.text]) {
      const sortingOrder = !sortasc;
      setSortCriteria({
        sortField: ALLOWED_COLUMNS[col.text],
        sortOrder: sortingOrder ? ASCENDING : DESCENDING
      });
      let updatedColumns = columns;
      updatedColumns[index].sortAsc = sortingOrder;
      setColumns(updatedColumns)
    }
  }

  useEffect(() => {
    const formattedTripsDetails = trips.map((trip) => {
      const { tripName, costCode , createdAt, status, bookType, id, flights, hotels } = trip;
     const isFlightConfirmed = flights
       ? flights.some((flight) => flight?.tenantStatus === CONFIRMED)
       : false;
     const isHotelConfirmed = hotels
       ? hotels.some((hotel) => hotel?.tenantBookingStatus === CONFIRMED)
       : false;
     
      const bookTypeArray = createBookTypeArray(bookType, flights, hotels);
      const requiredDetails = {
        id,
        tripName,
        costCode,
        date: createdAt,
        status,
        bookType: bookTypeArray,
        isFlightConfirmed,
        isHotelConfirmed,
      };
      return requiredDetails;
    });
    setRowData(formattedTripsDetails);
  }, [trips]);

  useEffect(() => {
    if (permissions.includes("canDeleteAllDraftTrip") &&(tripCategory === CONFIRMED_CATEGORY || tripCategory === DRAFT_CATEGORY || tripCategory === ALL_CATEGORY))
      setColumns([
        ...DEFAULT_COLUMNS,
        {
          text: EMPTY_STRING,
          formatter: <ActionFormatter />,
        }
      ])
    else
      setColumns(DEFAULT_COLUMNS)
  }, [tripCategory])

  return trips.length ? (
    <Table
      columnDefs={columns}
      rowData={rowData}
      handleNavigtation={handleBookingNavigation}
      handleColumnClick={handleColumnClick}
    />
  ) : (
    <div className='w-full h-full flex justify-center items-center text-sm shadow-sm bg-white border border-contrast-200 rounded-lg'>
      <h4 className='text-2xl font-extrabold text-contrast-900 mb-4'>
        {NO_TRIP_MATCH}
      </h4>
    </div>
  );
};

TripsTable.propTypes = TripsTablePropsTypes;

export default TripsTable;
