import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { REQUEST_METHODS } from "../../constants";
import { createQueryString } from "../../helper";

const { FETCH_SESSION } = SPINNER_NAMES;
const { GET, PATCH } = REQUEST_METHODS;

export const fetchSessionInfo = createAsyncThunk(
  "fetch-session-details",
  async ({ id, parentId }, thunkArgs) => {
    const queryString = createQueryString({
      sessionId: id,
      parentSessionId: parentId,
    });
    return await asyncAction({
      url: `/session${queryString}`,
      methodType: GET,
      spinner: FETCH_SESSION,
      isAuthRequired: true,
      abortOnPageChange: false,
      errorMessage: "Failed to get the session details.",
      ...thunkArgs,
    });
  }
);

export const updateSessionInfo = createAsyncThunk(
  "update-session-info",
  async ({ sessionParams, requestBody }, thunkArgs) => {
    const queryString = createQueryString({
      sessionId: sessionParams,
    });
    return await asyncAction({
      url: `/session${queryString}`,
      methodType: PATCH,
      body: requestBody,
      abortOnPageChange: false,
      isAuthRequired: true,
      errorMessage: "Failed to update the session details.",
      ...thunkArgs,
    });
  }
);
