import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { differenceWith, get, isEmpty, isEqual, size, toPairs } from "lodash";
import { useTranslation } from "react-i18next";
import { ArrowLeft, Filter, RenderSVG } from "../../assets/icons";
import { selectIsInternationalFlight, selectSelectedTripType } from "./index";
import {
  selectActiveFilters,
  selectFilteredFlights,
  selectFlights,
  selectSearchFilters,
} from "../../components/organisms/Search";
import { actions as flightActions } from "../../components/organisms/Search/search.reducer";
import {
  DEFAULT_VALUES,
  INITIAL_FILTERS,
  INITIAL_SORT_FILTERS,
  TRIP_TYPES,
  FLIGHTS_RESULT_TYPE,
} from "../../constants";
import FiltersModal from "../../components/organisms/AppModals/FiltersModal";
import {
  getAirportInfoByIata,
  getCityNameByIata,
  getFormattedArrivalAndDepartureDate,
} from "../../helper";
import JourneySummary from "../../components/molecules/JourneySummary";

const { setActiveFilters, setFilteredFlights } = flightActions;
const { ZERO } = DEFAULT_VALUES;
const { MULTI_CITY, ROUND_TRIP } = TRIP_TYPES;
const { INBOUND_RESULT, OUTBOUND_RESULT, PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const FlightHeader = ({ resultType = null }) => {
  const dispatch = useDispatch();
  const flights = useSelector(selectFlights) || {};
  const searchFilters = useSelector(selectSearchFilters);
  const filteredFlights = useSelector(selectFilteredFlights);
  const flightFilters = useSelector(selectActiveFilters);
  const isInternationalFlights = useSelector(selectIsInternationalFlight);
  const isFlightDataEmpty= (isEmpty(flights.packages) && isEmpty(flights.isolated.inbound) && isEmpty(flights.isolated.outbound))

  const { t } = useTranslation();
  const tripType = useSelector(selectSelectedTripType);
  const { originCode, destCode, returnDate, departureDate } =
    searchFilters.journeys[0];

  const [filtersCount, setFiltersCount] = useState({
    inbound: ZERO,
    outbound: ZERO,
    packages: ZERO,
  });

  const getJourneySummary = (searchFilters) => {
    const { journeys } = searchFilters;
    const journeySummary = journeys.map((journey) => ({
      ...journey,
      originCode: getAirportInfoByIata(journey.originCode.iata),
      destCode: getAirportInfoByIata(journey.destCode.iata),
    }));
    return journeySummary;
  };

  const journey = getJourneySummary(searchFilters);

  const getFiltersLength = (filters) => {
    if (isEmpty(filters)) return ZERO;

    const differencesCount = size(
      differenceWith(toPairs(INITIAL_SORT_FILTERS), toPairs(filters), isEqual)
    );

    return differencesCount;
  };

  const calculateFiltersLength = () => {
    if (!isEmpty(flightFilters)) {
      const {
        packages,
        isolated: { inbound, outbound },
      } = flightFilters;
      return {
        packages: getFiltersLength(packages),
        inbound: getFiltersLength(inbound),
        outbound: getFiltersLength(outbound),
      };
    }
  };

  useEffect(() => {
    const filterCount = calculateFiltersLength();
    getFilterCount(filterCount);
    setFiltersCount(filterCount);
  }, [flightFilters, tripType]);

  const getFilterCount = useCallback(
    (filtersCount) => {
      switch (resultType) {
        case INBOUND_RESULT:
          return filtersCount.inbound;
        case OUTBOUND_RESULT:
          return filtersCount.outbound;
        case PACKAGE_RESULT:
        default:
          return filtersCount.packages;
      }
    },
    [filtersCount]
  );

  const handleResetFilters = () => {
    if (resultType) {
      dispatch(
        setActiveFilters({
          ...flightFilters,
          isolated: {
            ...flightFilters.isolated,
            [resultType]: INITIAL_FILTERS.isolated[resultType],
          },
        })
      );
      dispatch(
        setFilteredFlights({
          ...filteredFlights,
          isolated: {
            ...filteredFlights.isolated,
            [resultType]: flights.isolated[resultType],
          },
        })
      );
    } else {
      dispatch(
        setActiveFilters({
          ...flightFilters,
          packages: INITIAL_FILTERS.packages,
        })
      );
      dispatch(
        setFilteredFlights({
          ...filteredFlights,
          packages: flights.packages,
        })
      );
    }
  };

  return (
    !isFlightDataEmpty && 
    <div className='bg-primary-100 relative rounded-xl border border-contrast-200 font-bold text-sm md:text-base lg:text-xl mr-1 mb-2 py-3 px-6 flex flex-row justify-between items-center flex-wrap gap-2 z-20'>
      <div className='flex flex-row items-center flex-wrap justify-center gap-2'>
        {tripType === ROUND_TRIP && !isInternationalFlights ? (
          resultType === OUTBOUND_RESULT ? (
            <div className='flex items-center flex-wrap justify-center gap-2'>
              <div className='flex items-center'>
                {getCityNameByIata(originCode.iata)}
                <RenderSVG
                  Svg={ArrowLeft}
                  alt='Left Arrow'
                  className='mx-2 w-4'
                  stroke='black'
                />
                {getCityNameByIata(destCode.iata)}
              </div>
              <div className='text-sm lg:text-base font-normal text-nowrap'>
                {getFormattedArrivalAndDepartureDate(departureDate)}
              </div>
            </div>
          ) : (
            <div className='flex items-center flex-wrap justify-center gap-2'>
              <div className='flex items-center'>
                {getCityNameByIata(destCode.iata)}
                <RenderSVG
                  Svg={ArrowLeft}
                  alt='Left Arrow'
                  className='mx-2 w-4'
                  stroke='black'
                />
                {getCityNameByIata(originCode.iata)}
              </div>
              <div className='text-sm lg:text-base font-normal text-nowrap'>
                {getFormattedArrivalAndDepartureDate(returnDate)}
              </div>
            </div>
          )
        ) : (
          <JourneySummary journey={journey} />
        )}
      </div>
      {tripType !== MULTI_CITY && (
        <div className='flex rounded-md justify-end gap-2'>
          {!!getFilterCount(filtersCount) && (
            <button
              className='py-2 px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-600 font-medium justify-end'
              onClick={handleResetFilters}
              type='button'
            >
              <RenderSVG Svg={Filter} alt='Filter Icon' />
              {t("flightResults.resetFilter")}
            </button>
          )}
          <FiltersModal
            resultType={resultType}
            filtersCount={getFilterCount(filtersCount)}
          />
        </div>
      )}
    </div>
  );
};

export default FlightHeader;
