import { RenderSVG, Plane, Location } from "../../assets/icons";

const FlightLoader = () => {
  return (
    <div className='w-full h-[500px] flex justify-center items-center'>
      <div className='relative w-1/2 h-32 flex items-center justify-center'>
        <div className='absolute w-full h-3 bg-contrast-300 rounded-full'>
          <div className='absolute h-3 w-full bg-primary-600 rounded-full animate-flight-search-progress'></div>
          <div className='absolute animate-flight-search-icon'>
            <RenderSVG Svg={Plane} width='36' height='36' />
          </div>
        </div>
        <div className='absolute right-0 top-[38px] animate-fade-search-fade'>
          <RenderSVG Svg={Location} />
        </div>
        <div className='absolute top-24 text-2xl leading-8 text-contrast-900 font-bold font-[inter]'>
          Finding Flights...
        </div>
      </div>
    </div>
  );
};

export default FlightLoader;
