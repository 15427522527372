import { useRef, useEffect } from "react";
import classNames from "classnames";
import { DEFAULT_VALUES } from "../../../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;
const BACKSPACE = "Backspace";
const NUMBER_REGEX = /[^0-9]/gi;

const OtpField = ({
  name,
  type,
  values,
  setFieldValue,
  isOtpValid,
  setIsOtpValid,
}) => {
  const inputRefs = useRef([]);
  const handleOnChange = (event, key, index) => {
    setIsOtpValid(true);
    const { value } = event.target;
    const sanitizedValue = value.replace(NUMBER_REGEX, EMPTY_STRING);
    if (!NUMBER_REGEX.test(value)) {
      const changedValue = {
        ...values,
        [key]: sanitizedValue.slice(-1),
      };
      setFieldValue(name, changedValue);
      if (value && index < 5) inputRefs.current[index + 1].focus();
    }
  };

  const handleBackSpace = (event, key, index) => {
    setIsOtpValid(true);
    if (event.key === BACKSPACE && !event.target.value && index > 0) {
      const changedValue = { ...values, [key]: EMPTY_STRING };
      setFieldValue(name, changedValue);
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("Text").trim();
    if (pasteData.length <= 6 && pasteData.match(/^\d+$/)) {
      const newValues = {};
      Object.keys(values).forEach((key, index) => {
        newValues[key] = pasteData.charAt(index) || EMPTY_STRING;
      });
      setFieldValue(name, newValues);
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  return Object.keys(values).map((key, index) => (
    <input
      key={key}
      ref={(element) => (inputRefs.current[index] = element)}
      type={type}
      name={key}
      value={values[key]}
      className={classNames(
        "text-center text-l bg-contrast-100 w-11 h-11 border-contrast-300 rounded-md shadow-sm text-base py-2 px-3 block",
        {
          "border bg-white focus:border-2": isOtpValid,
          "border-2 border-solid border-red-500 outline-red-500 shadow-none":
            !isOtpValid,
        }
      )}
      onChange={(event) => handleOnChange(event, key, index)}
      onKeyUp={(event) => handleBackSpace(event, key, index)}
      onPaste={handlePaste}
    />
  ));
};

export default OtpField;
