import { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCollapse } from "react-collapsed";
import { get } from "lodash";
import classNames from "classnames";
import { FlightInfoPropTypes } from "../../../prop-types";
import {
  getAirlineIconUrlByCarrierCode,
  getCityNameByIata,
  getDayDifference,
  getDayFromDate,
  getFormattedLongDate,
  getStopLabel,
} from "../../../helper";
import {
  ArrowLeft,
  ChevronDown,
  ChevronUp,
  RenderSVG,
} from "../../../assets/icons";
import { DEFAULT_VALUES, CURRENCY_SYMBOLS } from "../../../constants";
import { selectCountryInfo } from "../../../screens/Profile";
import { CancellationModalSkeleton } from "../../../components/organisms/AppSkeletons";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";

const { ZERO, ONE, TWO, EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY } =
  DEFAULT_VALUES;
const { CANCELLATION_CHARGES } = SPINNER_NAMES;
const { INR } = CURRENCY_SYMBOLS;
const CANCELLED = "CANCELLED";
const FAILED = "FAILED";

const JourneyInfo = ({
  pnrList,
  itinerary,
  cancellationDetails,
  index,
  isRoundTripDomestic,
  selectedJourneyPnr,
  currentStep,
  handleJourneySelection,
}) => {
  const { t } = useTranslation();
  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: 200,
    defaultExpanded: index === ZERO && true,
  });
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const {
    duration,
    segments = EMPTY_ARRAY,
    journeyDetails = EMPTY_OBJECT,
    pnr = EMPTY_STRING,
    tenantStatus = EMPTY_STRING,
  } = itinerary;

  const { price } = journeyDetails;
  const { grandTotal } = price;

  const departure = get(segments, "0.departure", EMPTY_OBJECT);
  const arrival = get(
    segments,
    `${segments.length - ONE}.arrival`,
    EMPTY_OBJECT
  );
  const journeyStartDate = departure.date;
  const journeyStartDay = journeyStartDate && getDayFromDate(journeyStartDate);
  const daysRemaining =
    journeyStartDate && getDayDifference(new Date(), journeyStartDate);

  const carrierCode = get(segments, "0.carrierCode", EMPTY_STRING);
  const numberOfStops = segments.length - ONE;
  const stopsLabel = getStopLabel(numberOfStops, t);
  const isFlightCancelled = tenantStatus === CANCELLED;
  const isFlightFailed = tenantStatus === FAILED;

  return (
    <div key={journeyStartDate}>
      {(currentStep === ONE ||
        !isRoundTripDomestic ||
        (currentStep === TWO && selectedJourneyPnr?.includes(pnr))) && (
        <div
          className={classNames("p-4 bg-primary-100/30 border rounded-lg", {
            "borber-b border-contrast-300": index,
            "bg-red-100/50":
              (index < pnrList?.length && !pnrList?.[index]) ||
              isFlightCancelled,
            "border-none rounded-none ": !isRoundTripDomestic,
          })}
        >
          <div className='flex justify-between'>
            <p className='mb-2 text-[10px] font-semibold text-contrast-700'>
              {t("bookingResult.remainingDays", { daysRemaining })} |{" "}
              {getFormattedLongDate(journeyStartDate, {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}{" "}
              | {journeyStartDay}
            </p>
            {isFlightCancelled && pnrList[index] && (
              <p className='mb-2 text-xs font-bold text-red-700'>
                Already Cancelled!
              </p>
            )}
            {isFlightFailed && (
              <p className='mb-2 text-xs font-bold text-red-700'>
                Booking Failed!
              </p>
            )}
          </div>
          <div className='grid grid-cols-12 gap-4 mb-4'>
            <div className='col-span-12 md:col-span-8 2xl:col-span-8'>
              <div className='flex gap-2 sm:gap-3'>
                {isRoundTripDomestic && (
                  <input
                    className='p-2'
                    type='checkbox'
                    onChange={() => handleJourneySelection(pnr)}
                    checked={selectedJourneyPnr?.includes(pnr)}
                    disabled={
                      currentStep === TWO ||
                      (currentStep === ONE &&
                        (isFlightCancelled || isFlightFailed))
                    }
                  />
                )}
                <div className='logo'>
                  <img
                    src={getAirlineIconUrlByCarrierCode(carrierCode)}
                    width='30'
                    className='rounded border border-contrast-300'
                    alt='airline logo'
                  />
                </div>
                <div className='flex flex-col xs:flex-row md:gap-6 justify-between'>
                  <div className=''>
                    <h5 className='text-sm font-bold text-contrast-900 mb-1'>
                      {getCityNameByIata(departure.iataCode)} (
                      {departure.iataCode})
                    </h5>
                    <div className='text-xs text-contrast-600 flex gap-2'>
                      <span>{departure.time}</span>
                      <span className='inline-block w-24'>
                        {departure.airportName}
                      </span>
                    </div>
                  </div>
                  <div className='icon shrink-0 opacity-50'>
                    <RenderSVG Svg={ArrowLeft} width='20' />
                  </div>
                  <div className='ml-0 lg:ml-4 xl:ml-8'>
                    <h5 className='text-sm font-bold text-contrast-900 mb-1'>
                      {getCityNameByIata(arrival.iataCode)} ({arrival.iataCode})
                    </h5>
                    <div className='text-xs text-contrast-600 flex gap-2'>
                      <span>{arrival.time}</span>
                      <span className='inline-block w-24'>
                        {arrival.airportName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-12 md:col-span-4 2xl:col-span-4 flex gap-4'>
              <div className='flex-1 text-start md:text-center'>
                <h5 className='text-xs text-contrast-800 font-bold mb-1'>
                  {duration} | {stopsLabel}
                </h5>
                <p
                  className={classNames(
                    {
                      "text-xs text-contrast-400 bg-primary-50 border border-primary-600 inline-flex rounded-2xl overflow-hidden":
                        pnrList[index],
                    },
                    {
                      "bg-red-50 border border-red-600":
                        !pnrList[index] && isRoundTripDomestic,
                    }
                  )}
                >
                  {pnrList[index] && (
                    <span>
                      <span className='px-2 py-0.5 bg-primary-600 text-white'>
                        PNR
                      </span>
                      <span className='font-bold text-contrast-900 px-1 py-0.5'>
                        {pnrList[index]}
                      </span>
                    </span>
                  )}
                </p>
              </div>
              {(isRoundTripDomestic ||
                (!isRoundTripDomestic && index == ZERO)) && (
                <button className='flex p-2 shrink-0' {...getToggleProps()}>
                  <RenderSVG
                    Svg={isExpanded ? ChevronUp : ChevronDown}
                    className='text-contrast-800'
                    width='20'
                  />
                </button>
              )}
            </div>
          </div>
          <div {...getCollapseProps()}>
            <div className='py-4 px-4 bg-white'>
              <div className='divide-y divide-gray-300 px-2'>
                <div className='flex gap-4 flex-row py-3'>
                  <div className='text-contrast-900 text-sm font-medium w-64'>
                    Amount paid for booking:
                  </div>
                  <div className='text-contrast-900 text-sm font-medium  ms-auto text-end'>
                    <span className='text-base font-bold'>
                      {currencySymbol}&nbsp;{grandTotal}
                    </span>
                  </div>
                </div>

                <div className='flex flex-col divide-y divide-gray-300'>
                  <div className='flex justify-between py-3'>
                    <div className='text-contrast-900 text-sm font-medium w-64'>
                      Cancellation Charges
                    </div>
                    <div className='text-end'>
                      <Spinner
                        size='w-full'
                        name={CANCELLATION_CHARGES}
                        showSkeleton={true}
                        loaderComponent={<CancellationModalSkeleton />}
                        persistSize
                        className='inline-block'
                      >
                        <span className=''>
                          {currencySymbol}{" "}
                          {cancellationDetails[pnrList[index]]
                            ?.cancellationCharge || ZERO}
                        </span>
                      </Spinner>
                    </div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='font-bold text-sm text-contrast-900  flex gap-4 items-start justify-between py-3 bg-primary-100 px-4 rounded-md'>
                  <p className='w-64'>Total Amount to be Refunded</p>
                  <div className='text-end'>
                    <Spinner
                      size='w-full'
                      name={CANCELLATION_CHARGES}
                      showSkeleton={true}
                      loaderComponent={<CancellationModalSkeleton />}
                      persistSize
                      className='inline-block'
                    >
                      <span className=''>
                        {currencySymbol}{" "}
                        {cancellationDetails[pnrList[index]]?.refundAmount ||
                          ZERO}
                      </span>
                    </Spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

JourneyInfo.propTypes = FlightInfoPropTypes;

export default memo(JourneyInfo);
