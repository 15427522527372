import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Table from "../../components/molecules/Table/Table";
import { RenderSVG, UserCircle } from "../../assets/icons";
import Badge from "../../components/atoms/Badge";
import { TransactionsTablePropsTypes } from "../../prop-types";
import {
  NOT_AVAILABLE,
  DEFAULT_VALUES,
  DEFAULT_LIMIT,
  CURRENCY_SYMBOLS,
  BOOKING_SEARCH_OPTIONS,
  ROUTES,
} from "../../constants";
import { selectedTransactionDetails, fetchTransactionDetails } from "./index";
import Pagination from "../../components/organisms/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectCurrentUserInfo } from "../Auth";
import Spinner, {
  ProgressBar,
  SPINNER_NAMES,
} from "../../components/organisms/Spinner";
import TransactionsEmptyLayout from "../../components/molecules/EmptyLayout";
import { useNavigate } from "react-router-dom";
import { setSelectedTripId } from "../Booking/Trips";
import { priceFormatter, setToSessionStorage } from "../../helper";
import { formatPriceForCurrency } from "../../helper/priceFormatter";

const { ONE, EMPTY_OBJECT, EMPTY_STRING, SINGLE_SPACE_STRING } = DEFAULT_VALUES;
const { FETCH_TRANSACTIONS } = SPINNER_NAMES;
const { BOOKING_ID } = BOOKING_SEARCH_OPTIONS;
const { ACCOUNT } = ROUTES;

const TimestampFormatter = ({ row }) => {
  const { createdAt } = row;
  return isEmpty(createdAt) ? (
    NOT_AVAILABLE
  ) : (
    <div className='font-medium text-contrast-900 text-sm'>
      {new Date(createdAt).toDateString()}
    </div>
  );
};

const UserDataFormatter = ({ row }) => {
  const users = row.users || {}; 
  const {
    email = EMPTY_STRING,
    userProfilePic = null,
    name= EMPTY_STRING,
    firstName = EMPTY_STRING,
    lastName = EMPTY_STRING,
  } = users;
  const userName = name || firstName.trim() + SINGLE_SPACE_STRING + lastName.trim();
  return (
    <div className='flex flex-row items-center gap-4'>
      <div className='avatar shrink-0'>
        {userProfilePic ? (
          <img
            src={userProfilePic}
            className='rounded-full'
            width='40'
            alt='user-icon'
          />
        ) : (
          <img className="rounded-full" src={`https://robohash.org/${userName}.png?size=500x500&&bgset=bg2`} width='40' height='40' alt='user-icon' />
        )}
      </div>
        <div className="">
            <h6 className="font-medium text-contrast-900 text-sm">{userName}</h6>
            <p className="font-medium text-contrast-500 text-xs">{email}</p>
        </div>
    </div>
  );
};

const AmountFormatter = ({ row }) => {
  const { currency, amount } = row;
  return amount ? (
    <h6 className='font-medium whitespace-nowrap text-contrast-900 text-sm'>
      {formatPriceForCurrency(amount, currency)}
    </h6>
  ) : (
    NOT_AVAILABLE
  );
};

const PaymentProviderFormatter = ({ row }) => {
  const { paymentProvider } = row;
  return (
    <h6 className='font-medium text-gray-900 text-sm'>
      {paymentProvider?.name}
    </h6>
  );
};

const TavaBookingIdFormatter = ({ row }) => {
  const { tavaBookingId } = row;
  return tavaBookingId ? (
    <div className='font-medium text-sm'>{tavaBookingId}</div>
  ) : (
    NOT_AVAILABLE
  );
};

const StatusFormatter = ({ row }) => {
  const { status } = row;
  return status ? <Badge value={status} /> : NOT_AVAILABLE;
};
const TransactionsTable = ({ fetchTransactions, searchValue }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const transactionDetails = useSelector(selectedTransactionDetails);
  const [paginationDetails, setPaginationDetails] = useState(EMPTY_OBJECT);
  const page = Number(transactionDetails?.countInfo?.currentPage) || ONE;
  const [currentPage, setCurrentPage] = useState(page);
  const currentUserInfo = useSelector(selectCurrentUserInfo);

  const columns = [
    {
      text: "TavaBooking Id",
      formatter: <TavaBookingIdFormatter />,
    },
    {
      text: "User",
      formatter: <UserDataFormatter />,
    },
    {
      text: "Timestamp",
      formatter: <TimestampFormatter />,
    },
    {
      text: "Amount",
      formatter: <AmountFormatter />,
    },
    {
      text: "Status",
      formatter: <StatusFormatter />,
    },
    {
      text: "Payment Provider",
      formatter: <PaymentProviderFormatter />,
    },
  ];

  const handleEmptyLayout = () => navigate(ACCOUNT);

  useEffect(() => {
    setCurrentPage(ONE);
  }, [searchValue]);

  const handlePagination = (currentPage) => {
    setCurrentPage(currentPage);
    fetchTransactions(searchValue, currentPage);
  };

  useEffect(() => {
    const queryParams = {
      pageSize: DEFAULT_LIMIT,
      pageNumber: ONE,
      tenantId: currentUserInfo?.tenantId ? currentUserInfo.tenantId : null,
      searchKey: "",
    };
    dispatch(fetchTransactionDetails(queryParams));
  }, []);

  useEffect(() => {
    const { countInfo } = transactionDetails;
    setPaginationDetails({ ...countInfo, currentPage: currentPage });
  }, [transactionDetails, currentPage]);
  const handleTransactionNavigation = (row) => {
    const { tavaBookingId } = row;
    const BOOKING = `/bookings/${tavaBookingId}`;
    navigate(BOOKING);
    dispatch(setSelectedTripId(tavaBookingId));
    setToSessionStorage(BOOKING_ID, tavaBookingId);
  };
const emptyLayoutSubtitle = searchValue !== "" ?"Update your search by entering another keyword" :  "Fund your company wallet to start tracking your travel expenses."
  
  return (
    <div className='flex-1 rounded-b-md border-opacity-10 h-full'>
      <ProgressBar
        name={!isEmpty(transactionDetails.result) && FETCH_TRANSACTIONS}
      />
      <div className='w-full relative flex flex-col bg-white h-full justify-between'>
        <div className='w-full overflow-x-auto'>
        <Spinner
          name={isEmpty(transactionDetails.result) && FETCH_TRANSACTIONS}
          spinnerClassName='w-full py-[28vh] '
          message={t("transactions.spinnerMessage")}
        >
          {!isEmpty(transactionDetails.result) ? (
            <Table
              columnDefs={columns}
              rowData={transactionDetails.result}
              handleNavigtation={handleTransactionNavigation}
            />
          ) : (
            <TransactionsEmptyLayout
              title={"No Transactions"}
              subTitle={
               emptyLayoutSubtitle
              }
              buttonLabel={"Set up Your Wallet"}
              showButton = {!currentUserInfo.isWalletEnabled}
              handleOnClick={handleEmptyLayout}
            />
          )}
        </Spinner>
        </div>
        {!isEmpty(transactionDetails.result) && (
          <Pagination
            data={paginationDetails}
            setCurrentPage={handlePagination}
          />
        )}
      </div>
    </div>
  );
};

TransactionsTable.propTypes = TransactionsTablePropsTypes;

export default TransactionsTable;
