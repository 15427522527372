import { RenderSVG } from "../../../../assets/icons";

const NoTripData = ({ description, imgName , title= "No Trip Data"}) => {
  return (
    <div className='w-full h-96 flex justify-center'>
      <div className='w-full h-full gap-4'>
        <div className='text-2xl font-extrabold text-center'>{title}</div>
        <div className='text-center text-sm font-medium py-3 text-contrast-500 font-inter'>
          {description}
        </div>
        <div className='flex w-full h-[80%] justify-center  pb-12'>
          <RenderSVG
            Svg={imgName}
            alt='Invite Team'
            className='w-full h-full'
          />
        </div>
      </div>
    </div>
  );
};

export default NoTripData;
