import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ReactDOM from "react-dom";
import { Tooltip } from "react-tooltip";
import moment from "moment";

const formatTime = (date) => {
  if (date) {
    return moment(date).local().format("h:mm A");
  }
  return "";
};

const getTimeFromHotelPolicy = (hotelPolicyDetail) => {
  if (!hotelPolicyDetail) return "";
  const match = hotelPolicyDetail.match(/CheckIn Time-Begin: ([\d:]+ [AP]M)/);
  return match ? match[1] : "";
};

const CalenderViewBookings = ({
  bookings,
  onActiveStartDateChange,
  activeStartDate,
}) => {
  const getTileClassName = ({ date, view }) => {
    if (view !== "month") return "";

    const formattedDate = moment(date).format("YYYY-MM-DD");

    const matchingBookings = bookings?.filter(
      ({ flight, hotel }) =>
        (flight &&
          moment(flight.departureTime).local().format("YYYY-MM-DD") ===
            formattedDate) ||
        (hotel &&
          moment(hotel.checkInDate).local().format("YYYY-MM-DD") ===
            formattedDate)
    );

    if (!matchingBookings || matchingBookings.length === 0) return "";

    const hasFlight = matchingBookings.some(({ flight }) => flight);
    const hasHotel = matchingBookings.some(({ hotel }) => hotel);

    return hasFlight && hasHotel
      ? "react-calendar__tile--filled-orange"
      : hasFlight
      ? "react-calendar__tile--filled-purple"
      : "react-calendar__tile--filled-blue";
  };

  const getTileContent = ({ date, view }) => {
    if (view !== "month") return null;

    const formattedDate = moment(date).local().format("YYYY-MM-DD");

    const uniqueBookings = bookings?.filter(
      ({ flight, hotel }) =>
        (flight &&
          moment(flight.departureTime).local().format("YYYY-MM-DD") ===
            formattedDate) ||
        (hotel &&
          moment(hotel.checkInDate).local().format("YYYY-MM-DD") ===
            formattedDate)
    );

    if (!uniqueBookings || !uniqueBookings.length) return null;

    const iconDisplay = (
      <div className='relative flex justify-center items-center'>
        {uniqueBookings.some(({ flight }) => flight) && (
          <span className='text-lg absolute -left-4 z-10'>✈️</span>
        )}
        {uniqueBookings.some(({ hotel }) => hotel) && (
          <span className='text-lg absolute -left-2 z-20'>🏨</span>
        )}
      </div>
    );

    const tooltipContent = uniqueBookings
      .reduce((acc, { flight, hotel }) => {
        const flightStatus = flight?.status;
        const hotelStatus = hotel?.status;

        const flightInfo = flight
          ? `Flight <span class="${
              flightStatus === "CONFIRMED"
                ? "text-green-500"
                : "text-yellow-500"
            }">${flightStatus.toLowerCase()}</span> and departing at ${formatTime(
              flight.departureTime
            )}`
          : null;

        const hotelInfo = hotel
          ? `Hotel <span class="${
              hotelStatus === "CONFIRMED" ? "text-green-500" : "text-yellow-500"
            }">${hotelStatus.toLowerCase()}</span> and check-in at ${
              getTimeFromHotelPolicy(hotel?.hotelPolicyDetail) ||
              formatTime(hotel?.checkInDate)
            }`
          : null;

        if (flightInfo && !acc.includes(flightInfo)) acc.push(flightInfo);
        if (hotelInfo && !acc.includes(hotelInfo)) acc.push(hotelInfo);

        return acc;
      }, [])
      .map((message, index) => {
        return `
        <div class="flex items-center mb-1" key=${index}>
          <span class="font-medium text-xs">${message}</span>
        </div>
      `;
      })
      .join("");

    const tooltipId = `${formattedDate}-tooltip`;

    return (
      <>
        <div
          data-tooltip-id={tooltipId}
          data-tooltip-place='top'
          data-tooltip-html={tooltipContent}
          className='h-full w-full items-center flex justify-center'
        >
          {iconDisplay}
        </div>
        {ReactDOM.createPortal(
          <Tooltip
            id={tooltipId}
            className='!min-w-56 !bg-gray-900 !rounded-lg !z-50'
          />,
          document.body
        )}
      </>
    );
  };

  return (
    <div className='!m-2 min-h-72'>
      <Calendar
        tileClassName={getTileClassName}
        tileContent={getTileContent}
        className='react-calendar'
        activeStartDate={activeStartDate}
        onActiveStartDateChange={onActiveStartDateChange}
      />
    </div>
  );
};

export default CalenderViewBookings;
