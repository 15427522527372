import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
SwiperCore.use([Navigation]);

const navigationButtons = {
  saleSlider: {
    nextEl: ".sale-button-next",
    prevEl: ".sale-button-prev",
  },
  topSellingSlider: {
    nextEl: ".top-selling-button-next",
    prevEl: ".top-selling-button-prev",
  },
  internalDestinationsSlider: {
    nextEl: ".internal-destinations-button-next",
    prevEl: ".internal-destinations-button-prev",
  },
  flightResultsSlider: {
    nextEl: ".search-result-button-next",
    prevEl: ".search-result-button-prev",
  },
};

export const SwiperScript = () => {
  let body = document.querySelector("body");
  let container = document.querySelector(".container");
  let startSpace = (body?.scrollWidth - container?.scrollWidth) / 2 + 20;

  const swiperdetails = {
    slidesPerView: 1.3,
    spaceBetween: 10,
    slidesOffsetBefore: 10,
    loop: true,

    breakpoints: {
      480: {
        slidesPerView: 1.8,
        spaceBetween: 10,
        slidesOffsetBefore: 10,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
        slidesOffsetBefore: 10,
      },
      768: {
        slidesPerView: 2.4,
        spaceBetween: 24,
      },
      992: {
        slidesPerView: 3.4,
        spaceBetween: 20,
        slidesOffsetBefore: startSpace,
      },
      1200: {
        slidesPerView: 3.8,
        spaceBetween: 20,
        slidesOffsetBefore: startSpace,
      },
      1400: {
        slidesPerView: 4.5,
        spaceBetween: 30,
        slidesOffsetBefore: startSpace,
      },
      1920: {
        slidesPerView: 4.5,
        spaceBetween: 40,
        slidesOffsetBefore: startSpace,
      },
    },
  };

  const saleSlider = new SwiperCore(".sale-slider", {
    ...swiperdetails,
    navigation: navigationButtons.saleSlider,
  });
  const topSellSlider = new SwiperCore(".top-selling-slider", {
    ...swiperdetails,
    navigation: navigationButtons.topSellingSlider,
  });
  const internalDestinationSlider = new SwiperCore(
    ".internal-destinations-slider",
    {
      ...swiperdetails,
      navigation: navigationButtons.internalDestinationsSlider,
    }
  );
  const flightResultsSlider = new SwiperCore(".flight-results-slider", {
    slidesPerView: 1.01,
    spaceBetween: 5,
    centeredSlides: true,
    simulateTouch: false,
    navigation: navigationButtons.flightResultsSlider,
  });

  return () => {
    saleSlider.destroy(true, true);
    topSellSlider.destroy(true, true);
    internalDestinationSlider.destroy(true, true);
    flightResultsSlider.destroy(true, true);
  };
};
