import { Cross, RenderSVG } from "../../assets/icons";
import { ROUTES } from "../../constants";

const { BOOKINGS } = ROUTES;

const SessionExpiredModal = () => {
  const handleGoToSearch = () => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split(/\/(flights|hotels)/)[0]; // Extracted the base URL before '/flights' or '/hotels'
    window.location.assign(baseUrl);
  };

  return (
    <div
      className={
        "fixed bg-contrast-700 inset-0 z-[999] overflow-auto transition-opacity duration-300 no-scrollbar items-center px-4 flex"
      }
    >
      <div
        className={
          "bg-white border w-full mx-auto rounded-md overflow-hidden my-6 sm:w-[600px] h-fit"
        }
      >
        <div className='flex items-center px-10 pb-6 pt-6 border-b border-contrast-300'>
          <div>
            <h6
              className={"font-bold text-xl sm:text-2xl text-contrast-900 mb-1"}
            >
              Session Expired!
            </h6>
            <p className='text-sm text-contrast-500'>
              Opps! Your session has expired.
            </p>
          </div>
          <button
            type='button'
            className='ml-auto rounded-md text-contrast-900 hover:scale-110 duration-200'
            onClick={() => handleGoToSearch()}
          >
            <RenderSVG Svg={Cross} alt='Cross Icon' className='w-8 h-6' />
          </button>
        </div>
        <div className='flex items-center justify-center py-8 px-7'>
          <p className='text-contrast-800'>
            Your session has expired due to inactivity. Please visit the
            Homepage and start a new Flight Search to continue with a delightful
            booking experience. Safe travels!
          </p>
        </div>

        <div className='flex justify-center flex-col item-center py-6 bg-contrast-50/70 border-t border-contrast-300'>
          <button
            className='text-white font-medium rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 text-sm py-2 items-center self-center'
            onClick={handleGoToSearch}
          >
            <span className='py-4 px-4'>Return to Homepage </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
