import { cloneDeep, get, isEmpty, set } from "lodash";
import iataCodes from "../../assets/json/iataCodes.json";
import carrierCodes from "../../assets/json/carrierCodes.json";
import { DEFAULT_VALUES } from "../../constants";

const { ZERO, ONE, TWO, FOUR, SIX, TEN, EMPTY_STRING } = DEFAULT_VALUES;
const AMADEUS = "AMADEUS";
const travelerTypeMapping = { ADT: "Adult", CH: "Child", INF: "Infant" };
const baggageUnitMapping = { K: "KG", L: "Pound" };
const cabinClassMapping = {
  M: "Economy",
  W: "Economy Premium",
  C: "Business",
  F: "First",
};
const FLIGHT_QUALIFIER = "S";
const BAGGAGE_QUALIFIER = "B";
const DURATION_QUALIFIER = "EFT";
const TRANSPORT_QUALIFIER = "V";
const WEIGHT_CODE = "W";
const CARDINALITY_CODE = "N";

const source = AMADEUS;
let flightIndex = ONE;

const formatTime = (time) => `${time.slice(ZERO, TWO)}:${time.slice(TWO)}`;

const formatDate = (dateString) => {
  const year = 2000 + parseInt(dateString.slice(FOUR, SIX), TEN);
  const month = parseInt(dateString.slice(TWO, FOUR), TEN);
  const day = parseInt(dateString.slice(ZERO, TWO), TEN);
  return `${year}-${month}-${day}`;
};

const getFormattedCarrierName = (carrierName) => {
  if (!carrierCodes[carrierName]) return carrierName;
  const splittedName = carrierCodes[carrierName].split("d/b/a");
  return splittedName.length > ONE ? splittedName[ONE] : splittedName[ZERO];
};

const getSegment = (flightInformation) => {
  const { productDateTime, location } = flightInformation;
  const { dateOfDeparture, timeOfDeparture, dateOfArrival, timeOfArrival } =
    productDateTime;
  const departureIataCode = get(location, "0.locationId._text");
  const arrivalIataCode = get(location, "1.locationId._text");

  const departure = {
    date: formatDate(dateOfDeparture?._text),
    time: formatTime(timeOfDeparture?._text),
    airportName: iataCodes[departureIataCode]?.airportName || departureIataCode,
    iataCode: departureIataCode,
    terminal: get(location, "0.terminal._text"),
  };

  const arrival = {
    date: formatDate(dateOfArrival?._text),
    time: formatTime(timeOfArrival?._text),
    airportName: iataCodes[arrivalIataCode]?.airportName || arrivalIataCode,
    iataCode: arrivalIataCode,
    terminal: get(location, "1.terminal._text"),
  };

  const carrierCode = get(
    flightInformation,
    "companyId.marketingCarrier._text",
    EMPTY_STRING
  );
  const carrierName = getFormattedCarrierName(carrierCode);

  const operatingCarrierCode = get(
    flightInformation,
    "companyId.operatingCarrier._text"
  );

  const flightNumber = get(flightInformation, "flightOrtrainNumber._text");

  const aircraftCode = get(
    flightInformation,
    "productDetail.equipmentType._text"
  );

  return {
    departure,
    arrival,
    carrierCode,
    carrierName,
    flightNumber,
    aircraftCode,
    operatingCarrierCode,
  };
};

const getBaggageInfo = ({ serviceCoverageInfoGrp, freeBagAllowanceGrp }) => {
  let baggageInfo = {};
  let baggageAllowance = {};

  const updateBaggageAllowance = ({ itemNumberInfo, freeBagAllownceInfo }) => {
    const baggageDetailCode = get(
      itemNumberInfo,
      "itemNumberDetails.number._text"
    );
    const quantityCode = get(
      freeBagAllownceInfo,
      "baggageDetails.quantityCode._text"
    );

    switch (quantityCode) {
      case WEIGHT_CODE:
        const weight = get(
          freeBagAllownceInfo,
          "baggageDetails.freeAllowance._text"
        );
        const unit = get(
          freeBagAllownceInfo,
          "baggageDetails.unitQualifier._text"
        );
        baggageAllowance[baggageDetailCode] = {
          weight: `${weight} ${baggageUnitMapping[unit]}`,
        };
        break;

      case CARDINALITY_CODE:
        const numberOfBags = get(
          freeBagAllownceInfo,
          "baggageDetails.freeAllowance._text"
        );
        baggageAllowance[baggageDetailCode] = {
          numberOfBags: `${numberOfBags} Piece(s)`,
        };
        break;
      default:
        break;
    }
  };

  if (Array.isArray(freeBagAllowanceGrp))
    freeBagAllowanceGrp.forEach((baggageAllowanceDetails) =>
      updateBaggageAllowance(baggageAllowanceDetails)
    );
  else updateBaggageAllowance(freeBagAllowanceGrp);

  const updateBaggageInfo = ({ itemNumberInfo, serviceCovInfoGrp }) => {
    const baggageIndex = get(itemNumberInfo, "itemNumber.number._text", ZERO);

    if (Array.isArray(serviceCovInfoGrp)) {
      serviceCovInfoGrp.forEach((service) => {
        const baggageDetailsCode = get(
          service,
          "refInfo.referencingDetail.refNumber._text"
        );

        baggageInfo[baggageIndex] = baggageAllowance[baggageDetailsCode];
      });
    } else {
      const baggageDetailsCode = get(
        serviceCovInfoGrp,
        "refInfo.referencingDetail.refNumber._text"
      );

      baggageInfo[baggageIndex] = baggageAllowance[baggageDetailsCode];
    }
  };

  Array.isArray(serviceCoverageInfoGrp)
    ? serviceCoverageInfoGrp.forEach((baggageServiceInfo) =>
        updateBaggageInfo(baggageServiceInfo)
      )
    : updateBaggageInfo(serviceCoverageInfoGrp);
  return baggageInfo;
};

const getTravelerPricing = (paxFareProduct) => {
  let travelerPricing = [];
  const updateTravaler = ({ paxFareDetail, paxReference, fareDetails }) => {
    const totalPrice = +get(paxFareDetail, "totalFareAmount._text");
    const taxPrice = +get(paxFareDetail, "totalTaxAmount._text");
    const travelerPrice = {
      totalPrice,
      taxPrice,
      basePrice: totalPrice - taxPrice,
    };
    const travelerType = get(paxReference, "ptc._text");
    const { traveller } = paxReference;

    const mapTravaler = (travelerDetails) =>
      travelerPricing.push({
        travelerId: travelerDetails.ref._text,
        travelerType: travelerTypeMapping[travelerType],
        priceDetails: travelerPrice,
      });

    if (Array.isArray(traveller))
      traveller.forEach((travelerDetails) => mapTravaler(travelerDetails));
    else mapTravaler(traveller);
  };

  if (Array.isArray(paxFareProduct))
    paxFareProduct.forEach((paxFareProd) => updateTravaler(paxFareProd));
  else updateTravaler(paxFareProduct);

  return travelerPricing;
};

const getRecommendationMapping = (
  segmentFlightRef,
  baggageInfo,
  price,
  flightArray,
  miscellaneousFlightDetails,
  company,
  flightRefNumber,
  flightId,
  isRefundable
) => {
  flightArray[flightRefNumber - ONE] &&
    flightArray[flightRefNumber - ONE][flightId].segments.forEach(
      (segment, segIndex) => {
        Object.entries(miscellaneousFlightDetails).forEach(([key, value]) => {
          value[flightRefNumber] &&
            set(
              flightArray[flightRefNumber - ONE],
              `${flightId}.segments.${segIndex}.${key}`,
              value[flightRefNumber][segIndex]
            );

          set(
            flightArray[flightRefNumber - ONE],
            `${flightId}.segments.${segIndex}.company`,
            company
          );
        });
      }
    );
  const itineraries = flightArray[flightRefNumber - ONE] && [
    flightArray[flightRefNumber - ONE][flightId],
  ];
  const flightType = flightRefNumber - ONE ? "IB" : "OB";
  const baggageDetailsIndex = segmentFlightRef.referencingDetail.find(
    ({ refQualifier }) => refQualifier?._text === BAGGAGE_QUALIFIER
  ).refNumber._text;

  if (itineraries?.length >= ONE)
    return {
      flightId: `${flightType}${flightIndex[flightRefNumber - ONE]++}`,
      isRefundable,
      itineraries,
      price,
      source,
      baggage: baggageInfo[baggageDetailsIndex],
    };
};

const getMappedGroupOfFlights = ({
  propFlightGrDetail: { flightProposal },
  flightDetails,
}) => {
  const segments = Array.isArray(flightDetails)
    ? flightDetails.map((flightDetail) =>
        getSegment(flightDetail.flightInformation)
      )
    : [getSegment(flightDetails.flightInformation)];

  const duration = flightProposal.find(
    ({ unitQualifier }) => unitQualifier?._text === DURATION_QUALIFIER
  ).ref._text;

  return { segments, duration: formatTime(duration) };
};

const getCompany = (paxFareProduct) => {
  const codeShareDetails = Array.isArray(paxFareProduct)
    ? paxFareProduct[ZERO]?.paxFareDetail?.codeShareDetails
    : paxFareProduct?.paxFareDetail?.codeShareDetails;

  const company = Array.isArray(codeShareDetails)
    ? codeShareDetails.find(
        ({ transportStageQualifier }) =>
          transportStageQualifier &&
          transportStageQualifier?._text === TRANSPORT_QUALIFIER
      )?.company._text
    : codeShareDetails?.transportStageQualifier?._text ===
        TRANSPORT_QUALIFIER && codeShareDetails?.company._text;

  return company;
};

const getBookingClassForSegment = ({ productInformation: { cabinProduct } }) =>
  Array.isArray(cabinProduct)
    ? cabinProduct[ZERO].rbd._text
    : cabinProduct.rbd._text;

const getCabinClassForSegment = ({ productInformation: { cabinProduct } }) =>
  cabinClassMapping[
    Array.isArray(cabinProduct)
      ? cabinProduct[ZERO].cabin._text
      : cabinProduct.cabin._text
  ];

const getSeatAvailabilityForSegment = ({
  productInformation: { cabinProduct },
}) =>
  Array.isArray(cabinProduct)
    ? cabinProduct[ZERO].avlStatus._text
    : cabinProduct.avlStatus._text;

const getMiscellaneousFlightDetails = (paxFareProduct) => {
  const fareDetails = Array.isArray(paxFareProduct)
    ? paxFareProduct[ZERO].fareDetails
    : paxFareProduct.fareDetails;

  let bookingClass = {};
  let cabinClass = {};
  let noOfAvailableSeats = {};
  const getBookingClassForSingleItinearay = ({ segmentRef, groupOfFares }) => {
    const itineraryId = segmentRef?.segRef?._text;

    if (Array.isArray(groupOfFares)) {
      bookingClass[itineraryId] = groupOfFares.map((groupOfFare) =>
        getBookingClassForSegment(groupOfFare)
      );
      cabinClass[itineraryId] = groupOfFares.map((groupOfFare) =>
        getCabinClassForSegment(groupOfFare)
      );
      noOfAvailableSeats[itineraryId] = groupOfFares.map((groupOfFare) =>
        getSeatAvailabilityForSegment(groupOfFare)
      );
    } else {
      bookingClass[itineraryId] = [getBookingClassForSegment(groupOfFares)];
      cabinClass[itineraryId] = [getCabinClassForSegment(groupOfFares)];
      noOfAvailableSeats[itineraryId] = [
        getSeatAvailabilityForSegment(groupOfFares),
      ];
    }
  };

  Array.isArray(fareDetails)
    ? fareDetails.forEach((fareDetail) =>
        getBookingClassForSingleItinearay(fareDetail)
      )
    : getBookingClassForSingleItinearay(fareDetails);

  return { bookingClass, cabinClass, noOfAvailableSeats };
};

export const getAmadeusIsolatedResponse = (flightData, flightLength) => {
  if (isEmpty(flightData.result)) return {};
  flightIndex = [flightLength[ZERO] + ONE, flightLength[ONE] + ONE];
  const isolatedFlights = { 0: [], 1: [] };

  const recommendation = flightData.recommendation;
  const serviceFeesGrp = flightData.serviceFeesGrp;

  const mappedBaggageData = Array.isArray(serviceFeesGrp)
    ? serviceFeesGrp.map((serviceFeesItem) => getBaggageInfo(serviceFeesItem))
    : [getBaggageInfo(serviceFeesGrp)];
  const baggageInfo = mappedBaggageData[ZERO];
  const flights = flightData.result.map((response) => {
    const flightsGroup = Array.isArray(response)
      ? response[ZERO].groupOfFlights
      : response.groupOfFlights;

    return Array.isArray(flightsGroup)
      ? flightsGroup.map((flightResponse) =>
          getMappedGroupOfFlights(flightResponse)
        )
      : [getMappedGroupOfFlights(flightsGroup)];
  });

  const getUpdatedFlights = (
    segmentRef,
    baggageInfo,
    price,
    flightArray,
    miscellaneousFlightDetails,
    company,
    refNumber,
    isRefundable
  ) => {
    const flightRecommendations = segmentRef?.referencingDetail.filter(
      ({ refQualifier }) => refQualifier?._text === FLIGHT_QUALIFIER
    );

    refNumber.forEach((ref, index) => {
      const recommendedFlightId = flightRecommendations[index].refNumber._text;
      const mappedFlight = getRecommendationMapping(
        segmentRef,
        baggageInfo,
        price,
        flightArray,
        miscellaneousFlightDetails,
        company,
        ref,
        +recommendedFlightId - ONE,
        isRefundable
      );

      mappedFlight && isolatedFlights[ref - ONE].push([mappedFlight]);
    });
  };

  const mapRecommendation = (
    { paxFareProduct, recPriceInfo, segmentFlightRef },
    flightArray
  ) => {
    const travelerPricing = getTravelerPricing(paxFareProduct);

    const totalPrice = +get(recPriceInfo, "monetaryDetail.0.amount._text");
    const taxPrice = +get(recPriceInfo, "monetaryDetail.1.amount._text");
    const miscellaneousFlightDetails =
      getMiscellaneousFlightDetails(paxFareProduct);

    const company = getCompany(paxFareProduct);
    let refundableFlightFlag = get(
      paxFareProduct,
      "fare[0].pricingMessage.freeTextQualification.informationType._text",
      ""
    );
    const isRefundable = refundableFlightFlag;

    const fareDetails = Array.isArray(paxFareProduct)
      ? paxFareProduct[ZERO].fareDetails
      : paxFareProduct.fareDetails;
    const refNumber = Array.isArray(fareDetails)
      ? fareDetails?.map((each) => each?.segmentRef?.segRef._text)
      : [fareDetails?.segmentRef.segRef._text];

    const price = {
      travelerPricing,
      totalPrice,
      taxPrice,
      basePrice: totalPrice - taxPrice,
    };

    if (Array.isArray(segmentFlightRef)) {
      segmentFlightRef.forEach((segmentRef) => {
        getUpdatedFlights(
          segmentRef,
          baggageInfo,
          price,
          flightArray,
          miscellaneousFlightDetails,
          company,
          refNumber,
          isRefundable
        );
      });
    } else {
      getUpdatedFlights(
        segmentFlightRef,
        baggageInfo,
        price,
        flightArray,
        miscellaneousFlightDetails,
        company,
        refNumber,
        isRefundable
      );
    }
  };

  Array.isArray(recommendation)
    ? recommendation.forEach((recommend) =>
        mapRecommendation(recommend, flights)
      )
    : mapRecommendation(recommendation, flights);
  return isolatedFlights;
};

const getPackagesRecommendationMapping = (
  segmentFlightRef,
  baggageInfo,
  price,
  flights,
  miscellaneousFlightDetails,
  company,
  isRefundable
) => {
  const flightId = flightIndex++;
  let itinerary = [];
  const flightRecommendations = segmentFlightRef.referencingDetail.filter(
    ({ refQualifier }) => refQualifier?._text === "S"
  );
  flightRecommendations.forEach((recommendation, index) => {
    const recommendedFlightId = recommendation.refNumber._text - ONE;
    flights[index][recommendedFlightId]?.segments.forEach(
      (segment, segIndex) => {
        Object.entries(miscellaneousFlightDetails).forEach(([key, value]) => {
          set(
            flights,
            `${index}.${recommendedFlightId}.segments.${segIndex}.${key}`,
            value[index + 1][segIndex]
          );
        });
        set(
          flights,
          `${index}.${recommendedFlightId}.segments.${segIndex}.company`,
          company
        );
      }
    );
    itinerary.push(cloneDeep(flights[index][recommendedFlightId]));
  });
  const baggageDetailsIndex = segmentFlightRef.referencingDetail.find(
    ({ refQualifier }) => refQualifier?._text === "B"
  )?.refNumber?._text;
  return [
    {
      flightId,
      itineraries: itinerary,
      isRefundable,
      price,
      source,
      baggage: baggageDetailsIndex ? baggageInfo[baggageDetailsIndex] : {},
    },
  ];
};

export const getAmadeusPackagedResponse = (flightData, flightLength = ONE) => {
  flightIndex = ++flightLength;

  if (isEmpty(flightData.result)) return [];

  const responseLength = flightData.result.filter(
    (data) => !isEmpty(data)
  ).length;

  let updatedResponse;
  if (responseLength === ONE) {
    updatedResponse = Array.isArray(flightData.result[ZERO])
      ? flightData.result[ZERO].filter((data) => !isEmpty(data))
      : flightData.result.filter((data) => !isEmpty(data));
  } else updatedResponse = flightData.result.map((res) => res[ZERO]);

  if (flightData.source !== AMADEUS) return;

  const recommendation = flightData.recommendation;
  const serviceFeesGrp = flightData.serviceFeesGrp;

  const mappedBaggageData = Array.isArray(serviceFeesGrp)
    ? serviceFeesGrp.map((serviceFeesItem) => getBaggageInfo(serviceFeesItem))
    : [getBaggageInfo(serviceFeesGrp)];
  const baggageInfo = mappedBaggageData[ZERO];

  const flights = updatedResponse.map((response) =>
    Array.isArray(response.groupOfFlights)
      ? response.groupOfFlights.map((flightResponse) =>
          getMappedGroupOfFlights(flightResponse)
        )
      : [getMappedGroupOfFlights(response.groupOfFlights)]
  );

  const mapRecommendation = ({
    recPriceInfo,
    segmentFlightRef,
    paxFareProduct,
  }) => {
    const travelerPricing = getTravelerPricing(paxFareProduct);

    const totalPrice = +get(recPriceInfo, "monetaryDetail.0.amount._text");
    const taxPrice = +get(recPriceInfo, "monetaryDetail.1.amount._text");

    const miscellaneousFlightDetails =
      getMiscellaneousFlightDetails(paxFareProduct);

    const company = getCompany(paxFareProduct);

    const price = {
      travelerPricing,
      totalPrice,
      taxPrice,
      basePrice: totalPrice - taxPrice,
    };
    let refundableFlightFlag = get(
      paxFareProduct,
      "fare[0].pricingMessage.freeTextQualification.informationType._text",
      ""
    );
    let isRefundable = refundableFlightFlag;

    if (Array.isArray(segmentFlightRef)) {
      const mappedRecommendation = segmentFlightRef.map((segmentRef) =>
        getPackagesRecommendationMapping(
          segmentRef,
          baggageInfo,
          price,
          flights,
          miscellaneousFlightDetails,
          company,
          isRefundable
        )
      );
      return mappedRecommendation;
    } else
      return [
        getPackagesRecommendationMapping(
          segmentFlightRef,
          baggageInfo,
          price,
          flights,
          miscellaneousFlightDetails,
          company,
          isRefundable
        ),
      ];
  };

  const itineraries = Array.isArray(recommendation)
    ? recommendation.reduce((itineraries, flightRecommendation) => {
        const mappedFlights = mapRecommendation(flightRecommendation);
        itineraries.push(...mappedFlights);
        return itineraries;
      }, [])
    : [...mapRecommendation(recommendation)];

  return itineraries;
};
