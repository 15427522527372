import UsersList from "./UsersList";
import Tabs from "../../components/organisms/Tabs";
import { getUsersList, getInvitedUsersList } from "./users.actions";
import { selectUsersData, selectInvitesData } from "./users.selectors";
import { selectUserInfo } from "../Profile";
import { useSelector } from "react-redux";
import EmptyLayout from "../../components/molecules/EmptyLayout";
import { AVAILABLE_PERMISSIONS, USER_COLUMN_HEADERS, SORT_ORDER , DEFAULT_VALUES} from "../../constants";
const {EMPTY_STRING}= DEFAULT_VALUES;
const { USER, ROLE, STATUS, CREATED_AT, UPDATED_AT,EXPIRY_DATE } = USER_COLUMN_HEADERS;
const { DESCENDING } = SORT_ORDER;

const usersListTableHeaders = [
  { text: USER,
    allowSorting: true, 
    sortOrder: DESCENDING },
  {
    text: ROLE,
  },
  {
    text: STATUS,
  },
  {
    text: CREATED_AT,
    allowSorting: true, 
    sortOrder: DESCENDING
  },
  {
    text: UPDATED_AT,
  },
  {
    text:EMPTY_STRING
  }
];
const invitesListTableHeaders = [
  { 
    text: USER
  },
  {
    text: ROLE,
  },
  {
    text: STATUS,
  },
  {
    text: CREATED_AT,
  },
  {
    text: UPDATED_AT,
  },
  {
    text: EXPIRY_DATE,
  },
  {
    text: EMPTY_STRING
  }
];

const tabs = [
  {
    id: 1,
    title: "Users",
    element: (
      <UsersList
        id='Users'
        key='Users'
        getUsers={getUsersList}
        userSelector={selectUsersData}
        tableHeaders={usersListTableHeaders}
      />
    ),
    default: true,
  },
  {
    id: 2,
    title: "Invites",
    element: (
      <UsersList
        id='Invites'
        key='Invites'
        getUsers={getInvitedUsersList}
        userSelector={selectInvitesData}
        tableHeaders={invitesListTableHeaders}
        isInvitesList={true}
      />
    ),
  },
];
const EMPTY_LAYOUT_TITLE = "You don't have permission to view users";
const {CAN_VIEW_TENANT_USERS} = AVAILABLE_PERMISSIONS;
const Users = () => {
  const userInfo = useSelector(selectUserInfo); 
  return (
  <div className='w-full bg-contrast-50 rounded-md rounded-t-lg shadow-md flex-1 flex flex-col bg-white h-full'>
      {userInfo?.permissions?.includes(CAN_VIEW_TENANT_USERS)?(<Tabs tabs={tabs} />):(<EmptyLayout title={EMPTY_LAYOUT_TITLE} />)}
  </div>);
};

export default Users;
