import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { get, isEmpty } from "lodash";
import {
  getHotelLocations,
  resetFlights,
  setHotels,
  setHotelsStaticData,
} from "../../../components/organisms/Search";
import {
  fetchPromptResult,
  resetPrompt,
  selectPromptResult,
  setTripStatus,
  selectTripStatus,
  resetTripStatus,
  selectTripOrder,
  setTripOrder,
  setIsAISearchInitiated,
} from "../FlightBookings";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import { selectCountryInfo } from "../../Profile";
import {
  getCityNameByIata,
  getQueryParams,
  getParsedJSON,
  getPrompt,
} from "../../../helper";
import {
  setSelectedFlightOptions,
  setSelectedFlightInfo,
  setSelectedFilteredFlights,
} from "../../FlightResults";
import { Send, AISearchIcon, RenderSVG } from "../../../assets/icons";
import {
  ERROR_MESSAGES,
  DEFAULT_VALUES,
  SEARCH_SECTION,
} from "../../../constants";
import { getFutureFormattedDateObject } from "../../../helper";
import { selectSelectedTripId } from "../Trips";

const {
  PROMPT_SEARCH,
  FETCH_FLIGHTS_RESULTS,
  FETCH_HOTEL_RESULTS,
  FETCH_HOTEL_STATIC_DATA,
} = SPINNER_NAMES;
const { EMPTY_OBJECT, TWO, EIGHT } = DEFAULT_VALUES;
const { FLIGHT, HOTEL } = SEARCH_SECTION;
const {
  SOMETHING_WENT_WRONG,
  ERROR_GETING_FLIGHT_RESULTS,
  ERROR_GETING_HOTEL_RESULTS,
} = ERROR_MESSAGES;

const AISearchTrip = () => {
  const [prompt, setPrompt] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ip: endUserIp } = useSelector(selectCountryInfo) || {};
  const promptResult = useSelector(selectPromptResult);
  const tripStatus = useSelector(selectTripStatus);
  const selectedTripId = useSelector(selectSelectedTripId);
  const selectedTripOrder = useSelector(selectTripOrder);
  const { date, month } = getFutureFormattedDateObject(new Date(), TWO);
  const dateObject = new Date();
  dateObject.setDate(dateObject.getDate() + EIGHT);
  const hotelDate = dateObject
    .getDate()
    .toLocaleString("en-US", { minimumIntegerDigits: TWO });
  const hotelMonth = dateObject.toLocaleDateString("en-US", { month: "short" });
  const finalPrompt = getPrompt(prompt, endUserIp);

  useEffect(() => {
    !isEmpty(promptResult) && handleSearch();
  }, [promptResult]);

  useEffect(() => {
    if (!selectedTripId) return;
    const {
      isFlightSearchInitiated,
      isHotelSearchInitiated,
      flightSearchQuery,
      hotelSearchQuery,
    } = tripStatus;

    if (selectedTripOrder.length === 2) {
      if (selectedTripOrder[0] === FLIGHT) {
        if (!isEmpty(flightSearchQuery) && !isFlightSearchInitiated) {
          navigate(`/bookings/${selectedTripId}/flights?${flightSearchQuery}`);
        }
      } else {
        if (!isEmpty(hotelSearchQuery) && !isHotelSearchInitiated) {
          navigate(`/bookings/${selectedTripId}/hotels?${hotelSearchQuery}`);
        }
      }
    } else if (selectedTripOrder.length < 2 && selectedTripOrder.length > 0) {
      if (
        selectedTripOrder[0] === FLIGHT &&
        !isEmpty(flightSearchQuery) &&
        !isFlightSearchInitiated
      ) {
        navigate(`/bookings/${selectedTripId}/flights?${flightSearchQuery}`);
      } else if (
        selectedTripOrder[0] === HOTEL &&
        !isEmpty(hotelSearchQuery) &&
        !isHotelSearchInitiated
      ) {
        navigate(`/bookings/${selectedTripId}/hotels?${hotelSearchQuery}`);
      }
    }
  }, [tripStatus]);

  const isFlightRequestValid = (flightJSON) => {
    const { cityName: origin } = get(flightJSON, "journeys[0].originCode", "");
    const { cityName: dest } = get(flightJSON, "journeys[0].destCode", "");
    return isEmpty(dest) || isEmpty(origin) ? true : false;
  };

  const isHotelRequestValid = (hotelJSON) => {
    const { cityId } = hotelJSON;
    return isEmpty(cityId);
  };

  const updateJourneyCities = (flightJSON) => {
    const updatedJourneys = flightJSON.journeys.map((journey) => {
      const { originCode, destCode } = journey;
      return {
        ...journey,
        originCode: {
          iata: originCode,
          cityName: getCityNameByIata(originCode),
        },
        destCode: {
          iata: destCode,
          cityName: getCityNameByIata(destCode),
        },
      };
    });
    return {
      ...flightJSON,
      journeys: updatedJourneys,
    };
  };

  const updateHotelCity = async (hotelJSON) => {
    const { cityName, countryCode } = hotelJSON;
    let cityId = "";
    cityName &&
      (await dispatch(getHotelLocations({ key: cityName })).then((res) => {
        if (res.payload) {
          const results = get(res, "payload.hits", []);
          const { DestinationId: destinationId = "" } =
            results.find(
              (item) =>
                item.CityName.toLowerCase() === cityName.toLowerCase() &&
                item.CountryCode.toLowerCase() === countryCode.toLowerCase()
            ) || {};
          cityId = String(destinationId);
          if (results && isEmpty(cityId)) {
            const { DestinationId: destinationId, HotelName: hotelName } = results[0]
            if (isEmpty(hotelName)) {
              cityId = String(destinationId);
            }
          }
        }
      }));
    return {
      ...hotelJSON,
      cityId,
    };
  };

  const handleSearch = async () => {
    const parsedJSON = getParsedJSON(promptResult);
    dispatch(resetPrompt());
    if (!parsedJSON?.FlightsJSON && !parsedJSON?.HotelsJSON)
      return toast.error(SOMETHING_WENT_WRONG);
    if (parsedJSON?.HotelsJSON) {
      const hotelRequest = await updateHotelCity(parsedJSON.HotelsJSON);
      if (isHotelRequestValid(hotelRequest)) {
        toast.error(ERROR_GETING_HOTEL_RESULTS);
      } else {
        const hotelSearchQuery = getQueryParams(hotelRequest);
        let key = "hotelSearchQuery";
        let value = hotelSearchQuery;
        dispatch(setHotels({}));
        dispatch(setHotelsStaticData([]));
        dispatch(setIsAISearchInitiated(true));
        dispatch(setTripStatus({ key, value }));
      }
    }
    if (parsedJSON?.FlightsJSON) {
      const flightRequest = updateJourneyCities(parsedJSON.FlightsJSON);
      if (isFlightRequestValid(flightRequest))
        toast.error(ERROR_GETING_FLIGHT_RESULTS);
      else {
        const flightSearchQuery = getQueryParams(flightRequest);
        let key = "flightSearchQuery";
        let value = flightSearchQuery;
        dispatch(resetFlights());
        dispatch(setIsAISearchInitiated(true));
        dispatch(setTripStatus({ key, value }));
      }
    }
  };

  const handleSubmit = () => {
    const lowerCasedPrompt = prompt.toLowerCase();
    const indexOfFlight = lowerCasedPrompt.indexOf(FLIGHT.toLowerCase());
    const indexOfHotel = lowerCasedPrompt.indexOf(HOTEL.toLowerCase());

    if (indexOfFlight !== -1 && indexOfHotel !== -1) {
      if (indexOfFlight < indexOfHotel) dispatch(setTripOrder([FLIGHT, HOTEL]));
      else dispatch(setTripOrder([HOTEL, FLIGHT]));
    } else if (indexOfFlight !== -1) dispatch(setTripOrder([FLIGHT]));
    else if (indexOfHotel !== -1) dispatch(setTripOrder([HOTEL]));
    setPrompt("");
    dispatch(resetTripStatus());
    dispatch(setSelectedFlightInfo(EMPTY_OBJECT));
    dispatch(setSelectedFlightOptions(EMPTY_OBJECT));
    dispatch(setSelectedFilteredFlights(EMPTY_OBJECT));
    dispatch(fetchPromptResult(finalPrompt));
  };

  let searchQuery = `Delhi to Pune flight on ${date}, ${month} with departureTime 2
            o'clock in the morning by AirIndia airline in a budget of 17000rs and
            book a hotel in Delhi on ${hotelDate}, ${hotelMonth} in budget of 15000rs.`;

  return (
    <div className='bg-white p-8 rounded min-h-[260px]'>
      <div className='flex gap-2 mb-6'>
        <div className='icon'>
          <RenderSVG Svg={AISearchIcon} width='24' alt='AISearchIcon' />
        </div>
        <h6 className='font-medium text-contrast-900'>
          Where would you like to go?
        </h6>
      </div>
      <div className='min-h-[15vh] flex'>
        <Spinner
          name={[
            PROMPT_SEARCH,
            FETCH_FLIGHTS_RESULTS,
            FETCH_HOTEL_RESULTS,
            FETCH_HOTEL_STATIC_DATA,
          ]}
          size='w-10 h-12'
        >
          <div className='flex-1 relative mb-6'>
            <button
              className='disabled:cursor-not-allowed'
              onClick={handleSubmit}
              disabled={isEmpty(prompt)}
            >
              <RenderSVG
                Svg={Send}
                width='20'
                alt='send'
                className='absolute top-1/2 -translate-y-1/2 end-3'
              />
            </button>
            <textarea
              value={prompt}
              placeholder='The more details, the better'
              onChange={(e) => setPrompt(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent adding a new line
                  if (!isEmpty(prompt)) {
                    handleSubmit();
                  }
                }
              }}
              className='w-full p-1 rounded text-base'
            ></textarea>
          </div>
          <p className='text-contrast-600'>
            <b>Example: </b>
            {searchQuery}
          </p>
        </Spinner>
      </div>
    </div>
  );
};

export default AISearchTrip;
