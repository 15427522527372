import React from 'react';
import { RenderSVG , SearchIcon } from '../../assets/icons';

const Search = ({placeholder , handleUpdate, searchValue, setSearchValue }) => {

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    handleUpdate(value)
  };

  return (
    <div>
      <RenderSVG
        Svg={SearchIcon}
        alt='Search Icon'
        color='#D1D5DB'
        className='absolute top-2 left-3'
      />
      <input
        type='text'
        className='form-control block w-full text-sm pl-10 py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500'
        placeholder={placeholder}
        value={searchValue}
        onChange={handleInputChange}
      />
    </div>

  );
};

export default Search;
