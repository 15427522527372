import { createSlice } from "@reduxjs/toolkit";
import {
    getAnalyticsGraphData,
    getAnalyticsGreetingsData,
    getAnalyticsTransactionInfo,
    getAnalyticsTripData,
    getAnalyticsSpentData,
    getCostCodeInfo,
    getAnalyticsAggregateData,
    getFrequentAirlines,
    getFrequentHotels,
    getUserUpcomingBookings
} from "./dashboard.actions";

const MONTH="month"
const CURRENT="current"
const THIS_MONTH="This Month"

const initialState = {
  analyticsSpent: null,
  analyticsTripData: null,
  analyticsTransaction: null,
  analyticsGraphData: null,
  analyticsGreetings: null,
  analyticsAggregate: null,
  analyticsQueryParams: { interval: MONTH, referencePeriod: CURRENT },
  selectedTimeRange:THIS_MONTH,
  selectedUser:[],
  costCodeInfo:{},
  frequentlyUsedAirlines: null,
  frequentlyUsedHotels: null,
  frequentlyUsedHotels: null,
  userUpcomingBookings: null,
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setAnalyticsQueryParams(state, action) {
      if (!action.payload) return;
      state.analyticsQueryParams = action.payload;
    },
    setSelectedTimeRange(state, action) {
      if (!action.payload) return;
      state.selectedTimeRange = action.payload;
    },
    setSelectedUser(state, action) {
      if (!action.payload) return;
      state.selectedUser = action.payload;
    },
    setFrequentlyUsedAirlines (state, action) {
      state.frequentlyUsedAirlines = action.payload;
    },
    setFrequentlyUsedHotels (state, action) {
      state.frequentlyUsedHotels = action.payload;
    },
    setUserUpcomingBookings (state, action) {
      state.userUpcomingBookings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAnalyticsSpentData.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.analyticsSpent = action.payload;
    })
    .addCase(getAnalyticsTripData.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.analyticsTripData = action.payload;
    })
    .addCase(getAnalyticsTransactionInfo.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.analyticsTransaction = action.payload;
    })
    .addCase(getAnalyticsGraphData.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.analyticsGraphData = action.payload;
    })
    .addCase(getAnalyticsGreetingsData.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.analyticsGreetings = action.payload;
    })
    .addCase(getCostCodeInfo.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.costCodeInfo = action.payload;
    })
    .addCase(getAnalyticsAggregateData.fulfilled, (state, action) => {
      if(!action.payload) return;
      state.analyticsAggregate = action.payload
    })
    .addCase(getFrequentAirlines.fulfilled, (state, action) => {
        if(!action.payload) return;
        state.frequentlyUsedAirlines = action.payload
    })
    .addCase(getFrequentHotels.fulfilled, (state, action) => {
        if(!action.payload) return;
        state.frequentlyUsedHotels = action.payload
    })
    .addCase(getUserUpcomingBookings.fulfilled, (state, action) => {
        if(!action.payload) return;
        state.userUpcomingBookings = action.payload
    })
  },
});

export default slice;

export const { name, reducer, actions } = slice;
