import Modal from "../../../organisms/Modal";
import { MODAL_SIZE } from "../../../../constants";
import MultiSelectField from "../../../molecules/MultiSelectField/MultiSelectField";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import Dropdown from "../../../atoms/Dropdown";

const { LG } = MODAL_SIZE;
const TAG_LIST = [
  { name: "TagName1" },
  { name: "TagName2" },
  { name: "TagName3" },
];

const AVAILABLE_EMPLOYEE = [
  { label: "UserName1" },
  { label: "UserName2" },
  { label: "UserName3" },
];

const FilterDashboardModal = ({ handleClose = () => {} }) => {
  const initialValues = {
    employee: "",
    tripType: [],
  };

  const validationSchema = Yup.object().shape({
    employee: Yup.string().required("Name is required"),
    tripType: Yup.array().required("Select at least one type"),
  });

  const handleOnSubmit = () => handleClose();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ values, setFieldValue, handleChange, resetForm }) => (
        <Form>
          <Modal
            size={LG}
            title={"Filter Dashboard"}
            shouldShowModalFromProps
            handleClose={handleClose}
          >
            <>
              <div className=' w-full py-6 px-3 sm:px-6 border-t border-contrast-200'>
                <div className='form-group flex flex-col gap-2 w-full'>
                  <Dropdown
                    options={AVAILABLE_EMPLOYEE}
                    onChange={(value) => setFieldValue("employee", value)}
                    fieldName='Employee'
                    placeHolder='Type to Search Names'
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='text-red-500 text-xs'
                  />
                </div>

                <div className='mt-3'>
                  <MultiSelectField
                    label={"Tags"}
                    name={"tripType"}
                    selectableValues={TAG_LIST}
                    selectAll={true}
                    placeholder={"All"}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <ErrorMessage
                    name='tripType'
                    component='div'
                    className='text-red-500 text-xs'
                  />
                </div>

                <div
                  className='text-primary-600 text-sm font-medium mt-6 cursor-pointer'
                  onClick={resetForm}
                >
                  Clear All Filters
                </div>
              </div>
              <div className=' flex border-t border-contrast-200 justify-end '>
                <div className='p-6 flex gap-4 '>
                  <button
                    type='reset'
                    className='border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium py-[10px] px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleOnSubmit}
                    className='text-white font-medium disabled:cursor-not-allowed disabled:bg-primary-400 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm'
                    type='submit'
                  >
                    Apply Filter
                  </button>
                </div>
              </div>
            </>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default FilterDashboardModal;
