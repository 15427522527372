import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Modal, { setSelectedModal } from "../../Modal";
import { RenderSVG, TriangleExclaimation } from "../../../../assets/icons";
import { selectSelectedTripId } from "../../../../screens/Booking/Trips";
import { selectCurrentUserInfo } from "../../../../screens/Auth";

const APIFailureModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const selectedTripId = useSelector(selectSelectedTripId);
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const currentTripType = location.pathname.includes("hotels")? "hotels": "flights"; //TODO: Need to add constants for this.
  const SEARCH_ROUTE = `/bookings/${selectedTripId}/${currentTripType}`;
  const MY_TRIPS_SEARCH_ROUTE = `/users/${currentUserInfo?.id}/bookings/${selectedTripId}/${currentTripType}`
  const handleSearchAgainAction = () => {
    if (window.location.pathname.includes(`/users/${currentUserInfo?.id}/bookings`)){
      navigate(MY_TRIPS_SEARCH_ROUTE);
    }
    else{
      navigate(SEARCH_ROUTE);
    }
    dispatch(setSelectedModal());
  };

  return (
    <Modal zIndex="1001">
      <div className='w-full bg-white rounded-lg shadow-md overflow-hidden'>
        <div className='relative'>
          <div className='py-10 z-10 relative'>
            <div className='modal-header flex gap-4 items-center justify-center'>
              <div className='logo flex-shrink-0'>
                <RenderSVG
                  Svg={TriangleExclaimation}
                  alt='Exclamation-Icon'
                  className='h-20 w-20'
                  color='#FF0000'
                />
              </div>
            </div>
            <div className='flex flex-col justify-center items-center px-6 w-full h-full'>
              <div className='flex flex-col justify-start items-center text-center mt-10'>
                <div className='text-2xl font-bold'>
                  {t("apiFailureResponse.mainText")}
                </div>
                <p className='text-sm text-contrast-600'>
                  {t("apiFailureResponse.subText")}
                </p>
              </div>
              <div className='justify-center w-full items-center top-0 mt-10'>
                <div>
                  <button
                    type='submit'
                    className='bg-primary-600 rounded-md text-white w-full text-center mb-2 h-10'
                    onClick={handleSearchAgainAction}
                  >
                    {t("apiFailureResponse.actionButtonText")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default APIFailureModal;
