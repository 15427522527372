import { useEffect, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { get, debounce, isEmpty } from "lodash";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import {
  getIATALocations,
  resetFlights,
  setSearchFilters,
  selectIsNonStopSelected,
  setSortedFlights,
  selectSearchFilters,
  selectActiveFilters,
} from "../index";
import OneWaySearch from "../../OneWaySearch/OneWaySearch";
import RoundTripSearch from "../../RoundTripSearch/RoundTripSearch";
import MultiCitySearch from "../../MultiCitySearch/MultiCitySearch";
import { selectActiveSpinners, SPINNER_NAMES } from "../../Spinner";
import JourneySummary from "../../../molecules/JourneySummary";
import TripTypeSelector from "../../../molecules/TripTypeSelector";
import { ChevronDown, ChevronUp, RenderSVG } from "../../../../assets/icons";
import {
  DEFAULT_VALUES,
  TRIP_TYPES,
  FARE_TYPES,
  CACHE_KEYS,
  FLIGHTS_RESULT_TYPE,
  DEBOUNCE_TIME,
  INITIAL_FILTERS,
  DEFAULT_FLIGHT_SEARCH_OPTIONS,
} from "../../../../constants";
import {
  getQueryParams,
  getObjectFromQueryParams,
  setToLocalStorage,
  setToSessionStorage,
} from "../../../../helper";
import { actions } from "../../../../screens/FlightResults/flightResults.reducer";
import { actions as filterActions } from "../search.reducer";
import FareTypeSelector from "../../FareTypeSelector";
import {
  clearSelectedValues,
  selectBookingTravelers,
  setBookingTravelers,
  selectTripStatus,
} from "../../../../screens/Booking/FlightBookings";
import { selectCountryInfo } from "../../../../screens/Profile";
import { selectSortedFlights } from "../search.selectors";
import { SearchSectionPropTypes } from "../../../../prop-types";
import { selectSelectedTrip } from "../../../../screens/Booking/Trips";
import { getDefaultValues } from "./flight.helper";
import SearchButton from "./SearchButton";
import { cancelAllAPIRequests } from "../../../../infrastructure/httpMethods/requestingMethods";

const { EMPTY_STRING, EMPTY_OBJECT, ZERO, ONE } = DEFAULT_VALUES;
const { SEARCH_FILTERS, TRAVELERS_INFORMATION } = CACHE_KEYS;
const { ONE_WAY, ROUND_TRIP, MULTI_CITY } = TRIP_TYPES;
const { REGULAR } = FARE_TYPES;
const { OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;
const ENTER_KEY_CODE = 13;
const {
  setSelectedFlightInfo,
  setCurrentFlightType,
  setSelectedTripType,
  setSelectedFlightOptions,
  setSelectedFlightId,
} = actions;
const { setActiveFilters, setFlightSearchOptions, setReIssuanceFlightData } =
  filterActions;

const Flight = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonStopSelected = useSelector(selectIsNonStopSelected);
  const { search, state } = useLocation();
  const { t } = useTranslation();
  const { ip: endUserIp, currency } =
    useSelector(selectCountryInfo) || EMPTY_OBJECT;
  const bookingTravelers = useSelector(selectBookingTravelers);
  const searchFilters = useSelector(selectSearchFilters);
  const tripDetails = useSelector(selectSelectedTrip);
  const lastSessionSearch = getDefaultValues(tripDetails, state);
  const sortedFlights = useSelector(selectSortedFlights);
  const activeFilters = useSelector(selectActiveFilters);
  const activeSpinners = useSelector(selectActiveSpinners);
  const tripStatus = useSelector(selectTripStatus);
  const [initialValues, setInitialValues] = useState(lastSessionSearch);
  const [isExpanded, setIsExpanded] = useState(true);

  const { flightSearchQuery } = tripStatus;

  useEffect(() => {
    if (isEmpty(flightSearchQuery)) return;
    const { tripType, includedAirlines, maxPrice, numberOfStops } =
      searchFilters;

    const updatedFilters = {
      airlines: includedAirlines,
      price: {
        maxPrice: maxPrice,
        minPrice: 0,
      },
      departureTime: {
        minTime: searchFilters.departureTime,
        maxTime: 1439,
      },
      arrivalTime: {
        minTime: searchFilters.arrivalTime,
        maxTime: 1439,
      },
      stops: numberOfStops,
    };

    if (tripType === ROUND_TRIP) {
      dispatch(
        setActiveFilters({
          ...activeFilters,
          isolated: { outbound: updatedFilters, inbound: updatedFilters },
        })
      );
    } else if (tripType === ONE_WAY) {
      const packageFilters = { packages: updatedFilters };
      dispatch(setActiveFilters({ ...activeFilters, ...packageFilters }));
    }
  }, [searchFilters, flightSearchQuery]);

  useEffect(() => {
    const searchFilters = getObjectFromQueryParams(search);
    if (isEmpty(searchFilters)) return;
    const {
      passengersCount,
      tripType,
      travelClass,
      journeys,
      fareType,
      isDirectFlight,
    } = searchFilters;

    const updatedValues = {
      adult: +get(passengersCount, "adult", ONE),
      children: +get(passengersCount, "children", ZERO),
      infants: +get(passengersCount, "infants", ZERO),
      tripType,
      travelClass,
      journey: journeys,
      fareType,
      isDirectFlight,
    };

    dispatch(setSearchFilters(searchFilters));
    setInitialValues(updatedValues);
  }, [dispatch, search]);

  const { getCollapseProps, getToggleProps } = useCollapse({
    duration: 500,
    isExpanded,
  });

  const handleNavigate = (queryString) =>
    navigate(`?${queryString}`, { replace: true, state });

  const debouncedLocationChange = useMemo(() => {
    const fetchCityCodes = (mainLocationSearchValue) => {
      const locationParams = {
        limit: 12,
        search: mainLocationSearchValue.inputValue.replace(/[^a-zA-Z ]/g, ""),
        type: mainLocationSearchValue.type,
      };
      dispatch(getIATALocations(locationParams));
    };
    return debounce(fetchCityCodes, DEBOUNCE_TIME);
  }, []);

  useEffect(() => {
    dispatch(clearSelectedValues());
  }, [dispatch]);

  const handleLocationChange = (mainLocationSearchValue) =>
    debouncedLocationChange(mainLocationSearchValue);

  const validationSchema = yup.object().shape({
    adult: yup
      .number()
      .min(ONE)
      .required(t("validationSchemas.searchSection.adults")),
    children: yup
      .number()
      .test(
        "check-children-count",
        t("validationSchemas.searchSection.invalidFareTypeDetails"),
        (value, context) =>
          !(context.parent.fareType !== REGULAR.value && value > ZERO)
      ),
    infants: yup
      .number()
      .test(
        "check-infants-counts",
        t("validationSchemas.searchSection.invalidFareTypeDetails"),
        (value, context) => {
          if (context.parent.fareType !== REGULAR.value && value > ZERO)
            return false;
          return context.parent.adult >= value;
        }
      )
      .test(
        "check-total-counts",
        "Maximum 9 passengers can be selected",
        (value, context) => {
          return value + context.parent.adult + context.parent.children <= 9;
        }        
      )
      .optional(),
    fareType: yup.string(),
    journey: yup.array().of(
      yup.object().shape({
        originCode: yup
          .object()
          .required(t("validationSchemas.searchSection.origin")),
        destCode: yup
          .object()
          .required(t("validationSchemas.searchSection.destination"))
          .test(
            "is-valid-loc",
            t("validationSchemas.searchSection.sameToAndFrom"),
            (value, context) => context.parent.originCode?.iata !== value?.iata
          ),
        returnDate: yup
          .string()
          .test(
            "is-valid-returndate",
            t("validationSchemas.searchSection.returnDate"),
            (value, context) => {
              return !(
                context.options.context.tripType === ROUND_TRIP &&
                new Date(value) < new Date(context.parent.departureDate)
              );
            }
          ),
        departureDate: yup
          .string()
          .test(
            "is-valid-departuredate",
            t("validationSchemas.searchSection.nextJourneyDate"),
            (value, context) => {
              return context.from[ONE].value.journey.every(
                (each, index) =>
                  index === ZERO ||
                  new Date(each.departureDate) >=
                    new Date(
                      context.from[ONE].value.journey[
                        index - ONE
                      ]?.departureDate
                    )
              );
            }
          ),
      })
    ),
  });

  const handleSearchFlights = useCallback(
    (values) => {
      const {
        adult,
        children,
        infants,
        journey,
        tripType,
        travelClass,
        fareType,
      } = values;
      dispatch(resetFlights());
      dispatch(setSelectedTripType(tripType));
      dispatch(setActiveFilters(INITIAL_FILTERS));
      dispatch(setFlightSearchOptions(DEFAULT_FLIGHT_SEARCH_OPTIONS));

      const { searchFilters = EMPTY_OBJECT } = state || EMPTY_OBJECT;
      const { searchType, bookingId, pnr } = searchFilters;

      const searchJourney = {
        destCode: journey[0].destCode,
        originCode: journey[0].originCode,
      };
      const queryFilters = {
        passengersCount: {
          adult,
          children,
          infants,
        },
        journeys: journey,
        tripType,
        travelClass,
        currencyCode: currency?.code,
        maxPrice: EMPTY_STRING,
        fareType,
        isDirectFlight: isNonStopSelected,
        ...(searchType && { searchType, bookingId, pnr }),
        timestamp: new Date().toISOString()
      };

      const queryString = getQueryParams(queryFilters);
      cancelAllAPIRequests();
      dispatch(setCurrentFlightType(OUTBOUND_RESULT));
      dispatch(setSelectedFlightInfo(EMPTY_OBJECT));
      dispatch(setSelectedFlightOptions(EMPTY_OBJECT));
      dispatch(
        setSelectedFlightId({
          inbound: null,
          outbound: null,
          packages: null,
        })
      );
      const updatedbookingTravelers = bookingTravelers.map((traveler) => ({
        ...traveler,
        document: {
          number: EMPTY_STRING,
          type: EMPTY_OBJECT,
        },
      }));

      setToLocalStorage(SEARCH_FILTERS, searchJourney);
      setToSessionStorage(TRAVELERS_INFORMATION, updatedbookingTravelers);
      dispatch(setBookingTravelers(updatedbookingTravelers));
      dispatch(setReIssuanceFlightData(state));
      handleNavigate(queryString);
      setIsExpanded(false);
    },
    [dispatch, isNonStopSelected, bookingTravelers, currency]
  );

  const handleLocationSwap = (index, values, setFieldValue) => {
    setFieldValue(
      `journey[${index}].originCode`,
      values.journey[index].destCode,
      false
    );
    setFieldValue(
      `journey[${index}].destCode`,
      values.journey[index].originCode,
      false
    );
  };

  const handleNonStopToggle = (isNonStopSelected, values) => {
    let queryStringFilters;
    const {
      adult,
      children,
      infants,
      journey,
      tripType: flightTripType,
      travelClass,
      fareType,
    } = values;

    const queryFilters = {
      endUserIp,
      passengersCount: {
        adult,
        children,
        infants,
      },
      journeys: journey,
      tripType: flightTripType,
      travelClass,
      currencyCode: currency?.code,
      maxPrice: EMPTY_STRING,
      fareType,
      isDirectFlight: false,
    };

    if (Object.keys(sortedFlights).length) {
      if (isNonStopSelected) {
        const arePackagesPresent = !isEmpty(sortedFlights.packages);
        const areInboundFlightsPresent = !isEmpty(
          get(sortedFlights, "isolated.inbound", [])
        );
        const areOutboundFlightsPresent = !isEmpty(
          get(sortedFlights, "isolated.outbound", [])
        );
        const filteredPackages =
          arePackagesPresent &&
          sortedFlights.packages.filter((eachPackage) => {
            for (const itinerary of eachPackage.itineraries) {
              if (itinerary.segments.length !== 1) {
                return false;
              }
            }
            return true;
          });
        const filteredInbound =
          areInboundFlightsPresent &&
          sortedFlights.isolated.inbound.filter((eachPackage) => {
            return (
              eachPackage?.itineraries?.length === 1 &&
              eachPackage?.itineraries[0]?.segments?.length === 1
            );
          });
        const filteredOutbound =
          areOutboundFlightsPresent &&
          sortedFlights.isolated.outbound.filter((eachPackage) => {
            return (
              eachPackage?.itineraries?.length === 1 &&
              eachPackage?.itineraries[0]?.segments?.length === 1
            );
          });
        const filteredSortedFlights = {
          ...sortedFlights,
          ...(arePackagesPresent && { packages: filteredPackages }),
          isolated: {
            ...(areInboundFlightsPresent && { inbound: filteredInbound }),
            ...(areOutboundFlightsPresent && { outbound: filteredOutbound }),
          },
        };
        dispatch(setSortedFlights(filteredSortedFlights));
        queryStringFilters = {
          ...queryFilters,
          isDirectFlight: true,
        };
      } else {
        dispatch(resetFlights());
        dispatch(setActiveFilters(INITIAL_FILTERS));
        queryStringFilters = {
          ...queryFilters,
          isDirectFlight: false,
        };
      }
      const queryString = getQueryParams(queryStringFilters);
      handleNavigate(queryString);
    }
  };

  const handleKeyDown = (e) =>
    e.keyCode === ENTER_KEY_CODE && e.preventDefault();

  const renderHybridSearchBar = (tripType) => [ONE_WAY, ROUND_TRIP].includes(tripType) ? (
    <RoundTripSearch
      handleLocationSwap={handleLocationSwap}
      handleLocationChange={handleLocationChange}
      footer={<SearchButton tripType={ROUND_TRIP} />}
    />
  ) : (
    <MultiCitySearch
      handleLocationChange={handleLocationChange}
      footer={<SearchButton tripType={MULTI_CITY} />}
    />
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSearchFlights}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form onKeyDown={handleKeyDown}>
          {<TripTypeSelector />}
          <div className='flex sm:flex-row flex-col gap-4 sm:items-center'>
            <div className='pt-6 pb-1'>
              {renderHybridSearchBar(values.tripType)}
              <FareTypeSelector
                fareType={values.fareType}
                setFareType={(value) => setFieldValue("fareType", value)}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

Flight.propTypes = SearchSectionPropTypes;

export default Flight;
