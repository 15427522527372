import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { SKELETON_COLOR } from "../../../constants";

const { BASE_COLOR, HIGHLIGHT_COLOR } = SKELETON_COLOR;

const ProfilePageSkeleton = () => (
  <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHLIGHT_COLOR}>
    <div className='w-full p-2'>
      <div className='text-center'>
        <Skeleton height={60} width={"95%"} borderRadius='0.5rem' />
      </div>
      <div className='pl-5'>
        <Skeleton height={40} width={120} />
      </div>
    </div>
  </SkeletonTheme>
);

export default ProfilePageSkeleton;
