import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { get, isEmpty, debounce } from "lodash";
import TripsTable from "./TripsTable";
import EmptyLayout from "../../../components/molecules/EmptyLayout";
import { SearchIconGray, FilterIcon, RenderSVG, PlusIcon } from "../../../assets/icons";
import Spinner, {
  ProgressBar,
  SPINNER_NAMES,
} from "../../../components/organisms/Spinner";
import {
  DEFAULT_VALUES,
  TRIPS_CATEGORIES,
  DEFAULT_LIMIT,
  INITIAL_FILTERS,
} from "../../../constants";
import {
  fetchTrips,
  selectSelectedCategoryTrips,
  selectTripCategory,
  setCurrentPage,
  setSearchKey,
  setTripCategory,
} from "./index";
import { selectCurrentUserInfo, selectTenantDetails } from "../../Auth";
import Pagination from "../../../components/organisms/Pagination";
import { setSelectedFilteredFlights } from "../../FlightResults";
import { setActiveFilters } from "../../../components/organisms/Search";
import { cancelAllAPIRequests } from "../../../infrastructure/httpMethods/requestingMethods";
import { selectCurrentPage, selectSearchKey } from "./trips.selector";
import { setSelectedModal } from "../../../components/organisms/Modal";
import { MODALS } from "../../../components/organisms/AppModals";
import { useLocation, useParams } from "react-router-dom";
import { setAddressDetails, setBookingTravelers, setContactDetails, setSelectedTravelers } from "../FlightBookings";
import { setOtherGuests } from "../../HotelBooking";

const {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  EMPTY_STRING,
  ZERO,
  ONE,
  SINGLE_SPACE_STRING,
} = DEFAULT_VALUES;
const { FETCH_TRIPS } = SPINNER_NAMES;

const EMPTY_LAYOUT = {
  TITLE: "No Trips Found",
};

const Trips = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const {userId:currentUserId}= useParams();
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const selectedCategoryTrips = useSelector(selectSelectedCategoryTrips);
  const tenantDetails = useSelector(selectTenantDetails);
  const selectedCurrentPage = useSelector(selectCurrentPage);
  const selectedSearchKey = useSelector(selectSearchKey);
  const tripCategory = useSelector(selectTripCategory);
  const [filteredTrips, setFilteredTrips] = useState(EMPTY_ARRAY);
  const [paginationDetails, setPaginationDetails] = useState(EMPTY_OBJECT);
  const [prevSearchKeyword, setPrevSearchKeyword] = useState(EMPTY_STRING);
  const [sortCriteria, setSortCriteria] = useState(EMPTY_OBJECT);
  const { CREATE_TRIP_MODAL } = MODALS;

  const tenantConfig = get(tenantDetails, "tenantConfig");
  const { brandName } = tenantConfig;

  const [ showNewTripButton, setShowNewTripButton ] = useState(true); 

  useEffect(() => {
    dispatch(setSelectedTravelers([]))
    dispatch(setBookingTravelers([]))
    dispatch(setAddressDetails(null))
    dispatch(setContactDetails(null))
    dispatch(setOtherGuests([]))
  }, [])

  useEffect(() => window.scrollTo(ZERO, ZERO), EMPTY_ARRAY);

  const debouncedFetchTrips = useCallback(
    debounce((formattedQueryParams, scrollIntoView) => {
      if (scrollIntoView)
        document
          .getElementById("trips-container")
          ?.scrollIntoView({ behavior: "smooth" });
      cancelAllAPIRequests();
      dispatch(fetchTrips(formattedQueryParams));
    }, 300),   
    [dispatch]
  );
  useEffect(() => {
    const { pathname} = location;
    const { sortField = EMPTY_STRING, sortOrder = EMPTY_STRING } = sortCriteria;
    const trimmedSearchKeyword = selectedSearchKey?.trim()?.toLowerCase();
    const searchQueryOrSortCriteria = !isEmpty(trimmedSearchKeyword)? `&q=${trimmedSearchKeyword}`: ``;
    let formattedQueryParams = `&status=${tripCategory}&pageSize=${DEFAULT_LIMIT}&page=${selectedCurrentPage}${searchQueryOrSortCriteria}&sortBy=${sortOrder}&sortOn=${sortField}`;
    if(currentUserId) formattedQueryParams= `userId=${currentUserId}${formattedQueryParams}`
  dispatch(fetchTrips(formattedQueryParams));
  }, [tripCategory, sortCriteria, selectedCurrentPage, selectedSearchKey, debouncedFetchTrips, location]);

  const handleSearchBookings = (e) => {
    setShowNewTripButton(false);
    const searchKeyWord = e.target.value.replace(/\s+/g, ' '); // To restrict user from entering multiple spaces
    dispatch(setSearchKey(searchKeyWord));
    const trimmedSearchKeyword = searchKeyWord?.trim()?.toLowerCase(); 
    if (searchKeyWord === SINGLE_SPACE_STRING) return; // To restrict API call on entering single space
    if (trimmedSearchKeyword === EMPTY_STRING) {
      if(currentUserId)
        debouncedFetchTrips(`userId=${currentUserId}&status=${tripCategory}`);
      else
        debouncedFetchTrips(`&status=${tripCategory}`);
    } else if (trimmedSearchKeyword !== prevSearchKeyword) {
      if(currentUserId)
        debouncedFetchTrips(`userId=${currentUserId}&status=${tripCategory}&q=${trimmedSearchKeyword}`);
      else
        debouncedFetchTrips(`&status=${tripCategory}&q=${trimmedSearchKeyword}`);
    }
    setPrevSearchKeyword(trimmedSearchKeyword);
  };

  useEffect(() => {
    const { result, countInfo } = selectedCategoryTrips;
    setFilteredTrips(result);
    dispatch(setSelectedFilteredFlights(EMPTY_OBJECT));
    dispatch(setActiveFilters(INITIAL_FILTERS));
    setPaginationDetails({ ...countInfo });
  }, [selectedCategoryTrips]);

  const handleTabChange = (category) => {
    dispatch(setSearchKey(EMPTY_STRING));
    setSortCriteria(EMPTY_OBJECT);
    dispatch(setCurrentPage(ONE));
    dispatch(setTripCategory(category));
  };

  const handlePagination = (currentPage) => {
    dispatch(setCurrentPage(currentPage));
    const trimmedSearchValue = selectedSearchKey.trim().toLowerCase();
    const { sortField = EMPTY_STRING, sortOrder = EMPTY_STRING } = sortCriteria;
    const searchQueryOrSortCriteria = !isEmpty(selectedSearchKey) ? `&q=${trimmedSearchValue}` : ``;
    let formattedQueryParams = `&status=${tripCategory}&pageSize=${DEFAULT_LIMIT}&page=${currentPage}&sortBy=${sortOrder}&sortOn=${sortField}${searchQueryOrSortCriteria}`;
    if(currentUserId) formattedQueryParams= `userId=${currentUserId}${formattedQueryParams}`;
    debouncedFetchTrips(formattedQueryParams, true);
  };


  return (
    <div className="h-full">
      <Helmet>
        <title>{brandName} | Trips</title>
      </Helmet>
      <div
        id='trips-container'
        className='main flex-1 flex flex-col bg-contrast-100 shadow-md round-b-md bg-white h-full'
      >
        <div className='bg-white border-b border-contrast-200'>
          <ul className='nav-tab flex items-center'>
            {TRIPS_CATEGORIES.map(({ key, name }) => (
              <li className='flex-1' key={key}>
                <button
                  onClick={() => handleTabChange(key)}
                  className={classNames(
                    "flex justify-center px-6 py-4 gap-3 items-center border-b-2 w-full text-sm",
                    {
                      "border-primary-500 text-primary-500":
                        key === tripCategory,
                      "border-contrast-300 text-contrast-500-custom":
                        key !== tripCategory,
                    }
                  )}
                >
                  <span className='font-semibold text-current text-sm capitalize'>
                    {name}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className='p-4 px-6 flex gap-8 bg-white'>
          <div className='flex-1 relative'>
            <RenderSVG
              Svg={SearchIconGray}
              alt='Filter Icon'
              className='absolute top-1/2 -translate-y-1/2 left-3'
              width='20'
            />
            <input
              type='text'
              className='form-control block w-full text-sm pl-10 py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500 focus:border-primary-600 focus:ring-primary-600'
              value={selectedSearchKey}
              onChange={handleSearchBookings}
              placeholder="Search by Trip ID or Name or Cost Code"
            />
          </div>
          <div className='relative'>
            <button className='py-2 px-4 flex items-center gap-2 rounded-md bg-contrast-100 hover:bg-contrast-100 active:bg-contrast-100 border border-contrast-300 shadow-sm text-sm text-primary-600 font-medium opacity-80 hidden'>
              <RenderSVG
                Svg={FilterIcon}
                className='text-primary-600'
                alt='Filter Icon'
              />
              Filters
            </button>
          </div>
        </div>

        <ProgressBar
          name={!isEmpty(selectedCategoryTrips?.result) && FETCH_TRIPS}
        />
        <div className='flex-1 overflow-auto'>
          <div className='flex w-full overflow-auto relative bg-white'>
            <div className='w-full'>
              <Spinner
                name={isEmpty(selectedCategoryTrips?.result) && FETCH_TRIPS}
                message={t("userBookings.spinnerMessage")}
                spinnerClassName='w-full py-[28vh]'
              >
                {selectedCategoryTrips?.result?.length ? (
                  <TripsTable trips={filteredTrips} tripCategory={tripCategory} setSortCriteria={setSortCriteria}/>
                ) : (
                  <EmptyLayout
                    title={EMPTY_LAYOUT.TITLE}
                    {...(showNewTripButton && {
                        buttonLabel:"New Trip",
                        icon:
                          <RenderSVG
                            Svg={PlusIcon}
                            className='min-w-[20px]'
                            alt='right-arrow-icon'
                          />,
                        handleOnClick:() => {
                          dispatch(setSelectedModal(CREATE_TRIP_MODAL));
                        }
                      })
                    }
                  />
                )}
              </Spinner>
            </div>
          </div>
        </div>
        <Pagination
          key={tripCategory}
          data={paginationDetails}
          setCurrentPage={handlePagination}
        />
      </div>
    </div>
  );
};

export default Trips;
