import { DEFAULT_VALUES } from "../constants";
const { EMPTY_STRING } = DEFAULT_VALUES;

export const createQueryString = (filters) => {
  const queryString = Object.keys(filters)
    .filter((each) => filters[each])
    .map((each) => `${each}=${filters[each]}`)
    .join("&");

  return queryString ? `?${queryString}` : EMPTY_STRING;
};
