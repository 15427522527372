import PropTypes from "prop-types";

export const FlightConfirmationPropTypes = {
  flightsInfo: PropTypes.array,
  bookingId: PropTypes.string,
  pnrList: PropTypes.array,
};

export const WebCheckinInfoPropTypes = {
  itineraries: PropTypes.array,
  pnrList: PropTypes.array,
  tripType: PropTypes.string,
  hasNonNullPnrValues: PropTypes.bool,
};

export const AirlineContactInfoPropTypes = {
  itineraries: PropTypes.array,
};

export const FlightInfoPropTypes = {
  pnrList: PropTypes.array,
  itinerary: PropTypes.object,
  index: PropTypes.number,
};

export const PaymentInfoPropTypes = {
  journeyDetails: PropTypes.array,
};

export const TravelersInfoPropTypes = {
  travelerData: PropTypes.array,
};

export const BookingHeadersPropTypes = {
  bookingStatus: PropTypes.string,
  bookingId: PropTypes.string,
  t: PropTypes.func,
};
