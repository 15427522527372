import { useDispatch, useSelector } from "react-redux";
import { isEmpty, get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  selectSelectedNonLCCMeals,
  selectSelectedLCCMeals,
} from "../flightBookings.selectors";
import { Cross, RenderSVG } from "../../../../assets/icons";
import { actions } from "../flightBookings.reducer";
import { DEFAULT_VALUES } from "../../../../constants";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { setSelectedLCCMeals, setSelectedNonLCCMeals } = actions;

const ReducedSelectedMeals = ({
  tabId,
  isLCC,
  selectedMeals = EMPTY_OBJECT,
  selectedMealCategory = EMPTY_OBJECT,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentFlightMeals = !isLCC
    ? get(selectedMealCategory, `${tabId}`, EMPTY_ARRAY)
    : get(selectedMeals, `${tabId}`, EMPTY_ARRAY);

  let mealCountMapping = currentFlightMeals.reduce((mealMapping, meal) => {
    mealMapping[meal.code] = {
      ...meal,
      count: (mealMapping[meal.code]?.count || ZERO) + ONE,
    };
    return mealMapping;
  }, {});

  const handleRemoveMeal = (codeToRemove) => {
    const filteredSelectedMeals = currentFlightMeals.filter(
      ({ code }) => code != codeToRemove
    );

    !isLCC
      ? dispatch(
          setSelectedNonLCCMeals({
            ...selectedMealCategory,
            [tabId]: filteredSelectedMeals,
          })
        )
      : dispatch(
          setSelectedLCCMeals({
            ...selectedMeals,
            [tabId]: filteredSelectedMeals,
          })
        );
  };

  return (
    <div>
      {!isEmpty(mealCountMapping) ? (
        Object.values(mealCountMapping).map(
          ({ airlineDescription, description, code, count }) => (
            <div
              key={code}
              className='text-xs flex justify-between gap-2 items-center my-1'
            >
              <div>
                {airlineDescription || description}
                <span className='font-semibold'> ({count})</span>
              </div>
              <button
                className='text-base bg-primary-600 text-white px-1 rounded-full'
                onClick={() => handleRemoveMeal(code)}
              >
                <RenderSVG Svg={Cross} className='text-white w-3' />
              </button>
            </div>
          )
        )
      ) : (
        <div className='text-xs text-contrast-700'>{t("mealSelection.noMeal")}</div>
      )}
    </div>
  );
};

const TravelerMealCard = ({ tabs }) => {
  const selectedMeals = useSelector(selectSelectedLCCMeals);
  const selectedMealCategory = useSelector(selectSelectedNonLCCMeals);
  const { t } = useTranslation();
  const updatedMealTabs = tabs.map(({ id, title, element, isLCC }) => ({
    id,
    title,
    element: (
      <ReducedSelectedMeals
        key={id}
        tabId={id}
        isLCC={isLCC}
        selectedMeals={selectedMeals}
        selectedMealCategory={selectedMealCategory}
      />
    ),
  }));

  return (
    <div className='p-3 xl:w-1/3 max-lg:w-full min-h-[190px]'>
      <div className='p-3 sm:pl-3 sm:p-5 bg-white rounded-lg border border-contrast-200 '>
        <div className='justify-start items-start gap-2 inline-flex'>
          <div className="mb-2 h-7 text-contrast-900 text-base font-bold font-['Inter'] leading-7">
            {t("mealSelection.selectedMeals")}
          </div>
        </div>
        <div className='pt-4 border-t border-contrast-200'>
          <div className='w-full p-4 rounded-lg border border-primary-600'>
            {updatedMealTabs.map(({ id, title, element }) => (
              <div key={id} className='mb-4'>
                <div className="text-primary-600 text-sm font-semibold font-['Inter'] leading-7">
                  {title}
                </div>
                {element}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelerMealCard;
