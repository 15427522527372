import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltips: {
      position: "nearest",
      mode: "index",
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      caretSize: 4,
      backgroundColor: "rgba(72, 241, 12, 1)",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 4,
    },
  },
  maintainAspectRatio: false,
};

const datasetConfig = {
  label: "Dataset",
  borderColor: "#1967d2",
  backgroundColor: "#4F46E5",
  barThickness: 10,
  borderRadius: 20,
};

const BarChart = ({ labels, data }) => {
  const barChartData = {
    labels,
    datasets: [
      {
        ...datasetConfig,
        data,
      },
    ],
  };
  return (
    <div className='w-full h-96 flex justify-center'>
      <Bar options={options} data={barChartData} />
    </div>
  );
};

export default BarChart;
