import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { REQUEST_METHODS } from "../../constants";
import { createQueryString } from "../../helper";
import { DEFAULT_VALUES } from "../../constants";

const {
    COST_CODES,
    ANALYTICS_SPENT_DATA,
    ANALYTICS_GRAPH_DATA,
    ANALYTICS_TRANSACTIONS_DATA,
    ANALYTICS_GREETING_DATA,
    ANALYTICS_AGGREGATE_DATA,
    ANALYTICS_FREQ_USED_AIRLINES,
    ANALYTICS_FREQ_USED_HOTELS,
    ANALYTICS_USER_UPCOMING_BOOKINGS,
    FETCH_MONTH_BASED_BOOKINGS
} = SPINNER_NAMES;
const { GET } = REQUEST_METHODS;
const { EMPTY_STRING } = DEFAULT_VALUES;

export const getAnalyticsSpentData = createAsyncThunk(
  "get-analytics-spent",
  async (queryParams, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `analytics/spent${queryString}`,
      methodType: GET,
      spinner: ANALYTICS_SPENT_DATA,
      isAuthRequired: true,
      showErrorToast: true,
      errorMessage:
        "Failed to get analytics information, please try again later",
      ...thunkArgs,
    });
  }
);
export const getAnalyticsTripData = createAsyncThunk(
  "get-analytics-tripdata",
  async (queryParams, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `analytics/tripdata${queryString}`,
      methodType: GET,
      spinner: ANALYTICS_SPENT_DATA,
      isAuthRequired: true,
      showErrorToast: true,
      errorMessage:
        "Failed to get analytics information, please try again later",
      ...thunkArgs,
    });
  }
);
export const getAnalyticsTransactionInfo = createAsyncThunk(
  "get-analytics-transaction",
  async (queryParams, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `analytics/transactions${queryString}`,
      methodType: GET,
      spinner: ANALYTICS_TRANSACTIONS_DATA,
      isAuthRequired: true,
      showErrorToast: true,
      errorMessage:
        "Failed to get analytics information, please try again later",
      ...thunkArgs,
    });
  }
);
export const getAnalyticsGraphData = createAsyncThunk(
  "get-analytics-graph",
  async (queryParams, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `analytics/usergraph${queryString}`,
      methodType: GET,
      spinner: ANALYTICS_GRAPH_DATA,
      isAuthRequired: true,
      showErrorToast: true,
      errorMessage:
        "Failed to get analytics information, please try again later",
      ...thunkArgs,
    });
  }
);
export const getAnalyticsGreetingsData = createAsyncThunk(
  "get-analytics-greetings",
  async (queryParams, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `analytics/usergreetings${queryString}`,
      methodType: GET,
      spinner: ANALYTICS_GREETING_DATA,
      isAuthRequired: true,
      showErrorToast: true,
      errorMessage:
        "Failed to get analytics information, please try again later",
      ...thunkArgs,
    });
  }
);

export const getAnalyticsAggregateData = createAsyncThunk(
  "get-analytics-aggregateData",
  async (queryParams, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `analytics/aggregate${queryString}`,
      methodType: GET,
      spinner: ANALYTICS_AGGREGATE_DATA,
      isAuthRequired: true,
      showErrorToast: true,
      errorMessage:
        "Failed to get analytics information, please try again later",
      ...thunkArgs,
    });
  }
);

export const getCostCodeInfo = createAsyncThunk(
  "get-CostCode-information",
  async (queryParams, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `analytics/costCode${queryString}`,
      methodType: GET,
      spinner: COST_CODES,
      isAuthRequired: true,
      showErrorToast: true,
      errorMessage:
        "Failed to get CostCode information, please try again later",
      ...thunkArgs,
    });
  }
);

export const getFrequentAirlines = createAsyncThunk(
  "get-frequently-used-airlines",
  async (queryParams, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `analytics/frequentAirlines${queryString}`,
      methodType: GET,
      spinner: ANALYTICS_FREQ_USED_AIRLINES,
      isAuthRequired: true,
      showErrorToast: true,
      errorMessage:
        "Failed to get frequently used airlines information, please try again later",
      ...thunkArgs,
    });
  }
);

export const getFrequentHotels = createAsyncThunk(
    "get-frequently-used-hotels",
    async (queryParams, thunkArgs) => {
      const queryString = createQueryString(queryParams);
      return await asyncAction({
        url: `analytics/frequentHotels${queryString}`,
        methodType: GET,
        spinner: ANALYTICS_FREQ_USED_HOTELS,
        isAuthRequired: true,
        showErrorToast: true,
        errorMessage:
          "Failed to get frequently used hotels information, please try again later",
        ...thunkArgs,
      });
    }
);

export const getUserUpcomingBookings = createAsyncThunk(
    "get-user-upcoming-bookings",
    async (queryParams, thunkArgs) => {
      const queryString = queryParams ? createQueryString(queryParams) : EMPTY_STRING;
      return await asyncAction({
        url: `analytics/upcomingBookings${queryString}`,
        methodType: GET,
        spinner: ANALYTICS_USER_UPCOMING_BOOKINGS,
        isAuthRequired: true,
        showErrorToast: true,
        errorMessage:
          "Failed to get user upcoming bookings information, please try again later",
        ...thunkArgs,
      });
    }
);

export const getUserBookingsInfo = createAsyncThunk(
  "get-bookings-information",
  async (queryParams, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `analytics/bookings${queryString}`, 
      methodType: GET,
      spinner: FETCH_MONTH_BASED_BOOKINGS,
      isAuthRequired: true,
      showErrorToast: true,
      errorMessage:
        "Failed to get CostCode information, please try again later",
      ...thunkArgs,
    });
  }
);
