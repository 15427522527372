import { Helmet } from "react-helmet";
import TravellerInformation from "./TravellerInformation";
import { useSelector } from "react-redux";
import { selectTenantDetails } from "../../Auth";
import { get } from "lodash";

const FlightBooking = () => {
  const tenantDetails = useSelector(selectTenantDetails);
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const { brandName } = tenantConfig;
  return (
    <div className='overflow-y-auto scroll-bar bg-contrast-50 px-8 pt-4'>
      <Helmet>
        <title>{brandName} | Flight Booking</title>
      </Helmet>
      <TravellerInformation />
    </div>
  );
};

export default FlightBooking;
