import React from 'react'
import Skeleton from "react-loading-skeleton";
import {
  RenderSVG,
  Luggage,
  Transactions,
  Dollar,
  TicketBasic,
  BaggageOutlined,
} from "../../../assets/icons";
const TOTAL_ANALYTIC = {
  SPENT: "spent",
  TRIPS: "trips",
  BOOKINGS: "bookings",
};

const getExpenseDetails = (type) => {
  switch (type) {
    case TOTAL_ANALYTIC.SPENT:
      return {
        icon: Dollar,
      };
    case TOTAL_ANALYTIC.TRIPS:
      return {
        icon: TicketBasic,
      };
    case TOTAL_ANALYTIC.BOOKINGS:
      return {
        icon: BaggageOutlined,
      };
  }
};

const ReportOverviewSkeleton = () => {
  return (
    <div className='w-full'>
      <div className='flex flex-col pt-[18px] px-8 pb-8 border border-contrast-300 rounded-lg gap-7 bg-white'>
        <div className='flex items-start'>
          <div className='grid grid-cols-5'>
            <RenderSVG Svg={Luggage} alt='luggage' className="none bg-primary-600 p-1 rounded-lg" color="white" width="30" height="30" />
            <div className='col-span-4 flex justify-end items-center capitalize font-medium'>
              Travel Report Overview
            </div>
          </div>
        </div>
        <Skeleton width={"100%"} height={180} duration={2} borderRadius={1} />
      </div>
    </div>
  )
}

const TransactionSkeleton = () => {
  return (
    <div className='w-full h-full'>
      <div className='flex flex-col h-full border border-contrast-300 rounded-lg bg-white'>
        <div className='items-start grid grid-cols-5 p-6 gap-8'>
          <RenderSVG
            Svg={Transactions}
            alt='Transactions'
            className='none bg-primary-600 p-1 rounded-lg'
            color='white'
            width='30'
            height='30'
          />
          <div className='col-span-4 flex justify-start items-center capitalize text-base font-medium font-inter'>
            Transactions
          </div>
        </div>
        <div className='px-6 grid gap-2 pb-4'>
          <Skeleton width={"100%"} height={180} duration={2} borderRadius={1} />
        </div>
      </div>
    </div>
  )
}
const AnalyticDataSkeleton = ({ name }) => {
  const { icon } = getExpenseDetails(name)
  return (
    <div className='w-full h-full'>
      <div className='flex flex-col h-full border border-contrast-300 rounded-lg bg-white'>
        <div className='items-start grid grid-cols-5 p-6 gap-8'>
          <RenderSVG Svg={icon} alt={`total ${name}`} className="stroke-current bg-primary-600 p-1 rounded-lg" color="white" width="30" height="30" />
          <div className='col-span-4 flex justify-start items-center capitalize text-[#111827] text-base font-medium font-inter'>
            {name}
          </div>
        </div>
        <div className='px-6 pb-4'>
          <Skeleton width={"100%"} height={140} duration={2} borderRadius={1} />
        </div>
      </div>
    </div>
  )
}

const DonutSkeleton = () => {
  return (
    <div className="flex justify-center h-full p-6">
      <div className="relative w-full h-full max-w-[600px] max-h-[400px]">
        <Skeleton width={"100%"} height={320} duration={2} borderRadius={1} />
      </div>
    </div>
  )
}

const LineChartSkeleton = () => {
  return (
    <div className='p-2 rounded-lg'>
      <Skeleton width={"100%"} height={320} duration={2} borderRadius={1} />
    </div>
  )
}

const GreetingsSkeleton = () => {
  return (
    <div className="w-full lg:min-w-[70vw]">
      <div className='flex flex-col h-full border border-contrast-300 rounded-lg bg-white'>
        <div className="items-start grid grid-cols-5 p-4">
          Greetings
        </div>
          <Skeleton width={"100%"} height={180} duration={2} borderRadius={1} />
      </div>
    </div >
  )
}

const FrequentlyUsedSkeleton = () => {
    return (
        <>
            <div className='mb-4'>
                <Skeleton width={"100%"} height={10} duration={2} borderRadius={1} />
            </div>
            <div className='mb-4'>
                <Skeleton width={"100%"} height={10} duration={2} borderRadius={1} />
            </div>
            <div className='mb-4'>
                <Skeleton width={"100%"} height={10} duration={2} borderRadius={1} />
            </div>
            <div className='mb-4'>
                <Skeleton width={"100%"} height={10} duration={2} borderRadius={1} />
            </div>
            <div className='mb-4'>
                <Skeleton width={"100%"} height={10} duration={2} borderRadius={1} />
            </div>
        </>
    )
}

const UserUpcomingBookingsSkeleton = () => {
    return (
      <div className='w-full h-full border border-contrast-300 rounded-lg bg-white p-4'>
        <div className='p-6 text-sm font-semibold font-inter text-[14px] text-[#111827] text-contrast-600'>
          User Confirmed Upcoming Bookings
        </div>
        <div className='border-b border-contrast-300 w-full pb-3'>
          <Skeleton width={"100%"} height={50} duration={2} borderRadius={1} />
        </div>
        <div className='pt-3'>
          <Skeleton width={"100%"} height={200} duration={2} borderRadius={1} />
        </div>
      </div>
    );
}
const TenantUpcomingBookingsSkeleton = () => {
  return (
    <div className='w-full h-full border border-contrast-300 rounded-lg bg-white p-4'>
      <div className='p-6 text-sm font-semibold font-inter text-[14px] text-[#111827] text-contrast-600'>
        Tenant Confirmed Upcoming Bookings
      </div>
      <div className='border-b border-contrast-300 w-full pb-3'>
        <Skeleton width={"100%"} height={50} duration={2} borderRadius={1} />
      </div>
      <div className='pt-3'>
        <Skeleton width={"100%"} height={200} duration={2} borderRadius={1} />
      </div>
    </div>
  );
}

const CalendarViewSkeleton = () => {
  return (
    <div className='w-full h-full bg-white'>
      <div className='p-6 text-sm font-semibold font-inter text-[14px] text-[#111827] text-contrast-600'>
        Fetching User Month Based Bookings...
      </div>
      <div className='border-b border-contrast-300 w-full pb-3'>
        <Skeleton width={"100%"} height={50} duration={2} borderRadius={1} />
      </div>
      <div className='pt-3'>
        <Skeleton width={"100%"} height={200} duration={2} borderRadius={1} />
      </div>
    </div>
  );
};

export {
  ReportOverviewSkeleton,
  TransactionSkeleton,
  AnalyticDataSkeleton,
  DonutSkeleton,
  LineChartSkeleton,
  GreetingsSkeleton,
  FrequentlyUsedSkeleton,
  UserUpcomingBookingsSkeleton,
  TenantUpcomingBookingsSkeleton,
  CalendarViewSkeleton,
};
