import { DEFAULT_VALUES } from "../constants";

const { ZERO } = DEFAULT_VALUES;

export const getRearrangedFlights = (
  flightsList = [],
  selectedFlightIdToMoveOnTop
) => {
  const specifiedFlightIndex = flightsList.findIndex(
    (flight) => flight[ZERO].flightId === selectedFlightIdToMoveOnTop
  );

  if (specifiedFlightIndex === -1) return flightsList;

  const specifiedFlight = flightsList[specifiedFlightIndex];
  const restFlights = [
    ...flightsList.slice(0, specifiedFlightIndex),
    ...flightsList.slice(specifiedFlightIndex + 1),
  ];

  return [specifiedFlight, ...restFlights];
};
