import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import {
  selectCurrentUserInfo,
  selectIsSidePanelCollapsed,
  selectIsUserAuthenticated,
  selectTenantDetails,
  setIsSidePanelCollapsed,
} from "../../../screens/Auth";
import { SidePanelPropTypes } from "../../../prop-types";
import { actions as searchActions } from "../Search/search.reducer";
import { signout } from "../../../helper";
import { setCurrentPage, setSearchKey, setTripCategory } from "../../../screens/Booking/Trips";
import ConfirmationModal from "../AppModals/ConfirmationModal";
import {
  Approvals,
  Bookings,
  DashboardIcon,
  Policies,
  Billing,
  CostCodes,
  SignOutIcon,
  Notifications,
  Profile,
  UserIcon,
  Transactions,
  AccountSettings,
  RenderSVG,
  Cross,
  BurgerMenuRight,
  Hamburger,
  ExclamationCircleIcon,
  WebsiteRedirect,
  ChevronUp,
  ChevronDown,
  bulletPoint,
} from "../../../assets/icons";
import {
  ROUTES,
  DEFAULT_VALUES,
  NAVIGATION_KEYS,
  ACCOUNT_KEYS,
  AVAILABLE_PERMISSIONS,
  SIDEPANEL_TABS,
  TRIPS_CATEGORIES,
} from "../../../constants";
import { useCollapse } from "react-collapsed";

const { resetFlights } = searchActions;
const PRIMARY = "primary";
const {
  DASHBOARD,
  APPROVALS,
  POLICIES,
  COST_CODES,
  USERS,
  PROFILE,
  BOOKINGS,
  ACCOUNT,
  TRANSACTIONS,
  PERMISSIONS,
  BOOKING_ID,
  CURRENT_USER_BOOKINGS,
} = ROUTES;
const {
  dashboard,
  bookings,
  approvals,
  policies,
  costCodes,
  users,
  billing,
  transactionsDetails,
  settings,
  permissions,
  notifications,
  profile,
  logOut,
} = SIDEPANEL_TABS;
const { ONE, EMPTY_ARRAY, EMPTY_STRING } = DEFAULT_VALUES;
const {
  CAN_VIEW_DASHBOARD,
  CAN_VIEW_APPROVALS,
  CAN_VIEW_BOOKINGS,
  CAN_VIEW_COST_CODES,
  CAN_VIEW_POLICIES,
  CAN_VIEW_TRANSACTIONS,
  CAN_VIEW_USERS,
  CAN_VIEW_SETTINGS,
  CAN_VIEW_ROLES,
  CAN_VIEW_TRANSACTIONS_DETAILS,
  CAN_SHOW_ALL_BOOKINGS,
} = AVAILABLE_PERMISSIONS;
const DEFAULT_PERMISSIONS = {
  navigationTabs: EMPTY_ARRAY,
  accountTabs: EMPTY_ARRAY,
};
const menubarOptions = {
  dashboard: {
    id: dashboard.id,
    name: dashboard.name,
    path: DASHBOARD,
  },
  bookings: {
    id: bookings.id,
    name: bookings.name,
    path: CURRENT_USER_BOOKINGS, // parent path for bookings tab
    subTabs: {
      myTrips: {
        id: "myTrips",
        name: "My Trips",
        path: CURRENT_USER_BOOKINGS,
      },
      myCompanyTrips: {
        id: "myCompanyTrips",
        name: "My Company Trips",
        path: BOOKINGS,
      },
    },
  },
  // approvals: {
  //   id: approvals.id,
  //   name: approvals.name,
  //   path: APPROVALS,
  // },
  policies: {
    id: policies.id,
    name: policies.name,
    path: POLICIES,
  },
  permissions: {
    id: permissions.id,
    name: permissions.name,
    path: PERMISSIONS,
  },
  costCodes: {
    id: costCodes.id,
    name: costCodes.name,
    path: COST_CODES,
  },
  users: {
    id: users.id,
    name: users.name,
    path: USERS,
  },
  // billing: {
  //   id: billing.id,
  //   name: billing.name,
  // },
  transactionsDetails: {
    id: transactionsDetails.id,
    name: transactionsDetails.name,
    path: TRANSACTIONS,
  },
  settings: {
    id: settings.id,
    name: settings.name,
    path: ACCOUNT,
  },
};
const profileOptions = [
  // {
  //   id: notifications.id,
  //   name: notifications.name,
  // },
  {
    id: profile.id,
    name: profile.name,
    path: PROFILE,
  },
  {
    id: logOut.id,
    name: logOut.name,
  },
];

const logoutData = {
  type: logOut.name,
  img: ExclamationCircleIcon,
  header: logOut.name,
  button: logOut.name,
};

const PERSONAL_WEBISTE_URL = "https://tavatrip.com";

const getMenubarIcon = (icon) => {
  switch (icon) {
    case dashboard.id:
      return <DashboardIcon />;
    case bookings.id:
      return <Bookings />;
    case approvals.id:
      return <Approvals />;
    case policies.id:
      return <Policies />;
    case costCodes.id:
      return <CostCodes />;
    case users.id:
      return <UserIcon />;
    case billing.id:
      return <Billing />;
    case transactionsDetails.id:
      return <Transactions />;
    case settings.id:
      return <AccountSettings />;
    case notifications.id:
      return <Notifications />;
    case profile.id:
      return <Profile />;
    case logOut.id:
      return <SignOutIcon />;
    case permissions.id:
      return <Approvals />; //Todo need to update the permission icon
    default:
      return null;
  }
};

const getValueBeforeFirstSlash = (str = "") => {
  const firstSlashIndex = str.indexOf("/", 1);
  return firstSlashIndex !== -1 ? str.slice(0, firstSlashIndex) : str;
};

const SidePanel = ({ setIsSidePanelOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId: currentUserId } = useParams();
  const isCollapsed = useSelector(selectIsSidePanelCollapsed);
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const isUserAuthenticated = useSelector(selectIsUserAuthenticated);
  const tenantDetails = useSelector(selectTenantDetails);
  const [selectedTab, setSelectedtab] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sidePanelOptions, setSidePanelOptions] = useState(DEFAULT_PERMISSIONS);
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const { primaryColor, logo, logoWithBrandBeta } = tenantConfig;
  const [expandedTabs, setExpandedTabs] = useState({});
  let permissions = EMPTY_ARRAY;
  if (!isEmpty(currentUserInfo) && currentUserInfo.permissions)
    permissions = currentUserInfo.permissions;
  const initializeExpandedTabs = (tabs) => {
    const initialExpanded = {};
    tabs.forEach(({ id, subTabs }) => {
      if (Array.isArray(subTabs) && subTabs.length > 0) {
        initialExpanded[id] = true; 
      }
    });
    return initialExpanded;
  };

  const toggleSubTabs = (id) => {
    setExpandedTabs((prev) => ({
      ...prev,
      [id]: !prev[id], 
    }));
  };
  useEffect(() => {
    const requiredPermissions = permissions.reduce((acc, each) => {
      const navigationTabs = [...acc.navigationTabs];
      const accountTabs = [...acc.accountTabs];
      let tab = {};
      switch (each) {
        case CAN_VIEW_DASHBOARD:
          tab = menubarOptions.dashboard;
          break;
        case CAN_VIEW_BOOKINGS:
          if (permissions.includes(CAN_SHOW_ALL_BOOKINGS)) {
            tab = {
              ...menubarOptions.bookings,
              subTabs: [
                menubarOptions.bookings.subTabs.myTrips,
                menubarOptions.bookings.subTabs.myCompanyTrips,
              ],
            };
          } else {
            tab = menubarOptions.bookings;
          }
          break;
        case CAN_VIEW_APPROVALS:
          tab = menubarOptions.approvals;
          break;
        case CAN_VIEW_POLICIES:
          tab = menubarOptions.policies;
          break;
        case CAN_VIEW_COST_CODES:
          tab = menubarOptions.costCodes;
          break;
        case CAN_VIEW_USERS:
          tab = menubarOptions.users;
          break;
        case CAN_VIEW_TRANSACTIONS:
          tab = menubarOptions.billing;
          break;
        case CAN_VIEW_TRANSACTIONS_DETAILS:
          tab = menubarOptions.transactionsDetails;
          break;
        case CAN_VIEW_SETTINGS:
          tab = menubarOptions.settings;
          break;
        // case CAN_VIEW_ROLES: //commenting for hiding the PERMISSIONS tab
        //   tab = menubarOptions.permissions;
        //   break;
      }

      if (!isEmpty(tab)) {
        if (NAVIGATION_KEYS.includes(tab.id)) navigationTabs.push(tab);
        if (ACCOUNT_KEYS.includes(tab.id)) accountTabs.push(tab);
      }
      return { navigationTabs, accountTabs };
    }, DEFAULT_PERMISSIONS);

    setSidePanelOptions(requiredPermissions);
  }, [currentUserInfo]);

  useEffect(() => {
    const { pathname, search } = location;
    if (!pathname.includes(BOOKINGS)) {
      dispatch(setCurrentPage(ONE));
      dispatch(setSearchKey(EMPTY_STRING));
    }
    const allTabs = [...Object.values(menubarOptions), ...profileOptions];

    let activeTabDetails = allTabs.find(({ path }) => pathname.includes(path));
    if (
      pathname.startsWith(`/users/${currentUserId}/bookings`) ||
      pathname.startsWith(`/bookings`)
    ) {
      activeTabDetails = allTabs.find(({ name }) => name === "Bookings");
    }
    if (!activeTabDetails) {
      activeTabDetails = allTabs.find(({ path }) => path.includes(path));
    }

    if (activeTabDetails) {
      setSelectedtab(activeTabDetails.id);
    }
    if (activeTabDetails && activeTabDetails.id === "bookings") {
      const { subTabs } = activeTabDetails;
      if (subTabs) {
        if (currentUserId) {
          activeTabDetails = subTabs.myTrips;
        } else {
          activeTabDetails = subTabs.myCompanyTrips;
        }
      }

      if (permissions.includes(CAN_SHOW_ALL_BOOKINGS)) {
        if (pathname.includes(`/users/${currentUserId}/bookings`)) {
          setSelectedtab(menubarOptions.bookings.subTabs.myTrips.id);
        } else {
          setSelectedtab(menubarOptions.bookings.subTabs.myCompanyTrips.id);
        }
      } else {
        setSelectedtab(menubarOptions.bookings.id);
      }
    }
  }, [permissions]);

  useEffect(() => {
    setExpandedTabs(initializeExpandedTabs(sidePanelOptions.navigationTabs));
  }, [sidePanelOptions.navigationTabs]);

  const handleAction = (type) => {
    setIsModalOpen(false);
    profileOptions.map(
      (option) =>
        option.id == logOut.id && handleTabActions(option.id, option.path)
    );
  };

  const handleTabActions = (id, path, url = EMPTY_STRING) => {
    const allTabs = [...Object.values(menubarOptions), ...profileOptions];
    let activeTabDetails = allTabs.find(({ id: tabId }) => tabId === id);

    if (!activeTabDetails) {
      const bookingsTab = allTabs.find(({ id }) => id === "bookings");
      if (bookingsTab && bookingsTab.subTabs) {
        activeTabDetails = Object.values(bookingsTab.subTabs).find(
          ({ id: subTabId }) => subTabId === id
        );
      }
    }
    if (activeTabDetails) {
      setSelectedtab(activeTabDetails.id);
      if (id === "myTrips" || id === "bookings") {
        path = `/users/${currentUserInfo.id}/bookings`;
        dispatch(setSearchKey(EMPTY_STRING));
        dispatch(setCurrentPage(ONE));
        dispatch(setTripCategory("DRAFT"))
      } else if (id === "myCompanyTrips") {
        path = `/bookings`;
        dispatch(setSearchKey(EMPTY_STRING));
        dispatch(setCurrentPage(ONE));
        dispatch(setTripCategory("DRAFT"));
      }
      navigate(path);
    }

    switch (id) {
      case "logOut":
        return isUserAuthenticated && signout();
    }

    dispatch(resetFlights());
  };

  const NOT_HOME_PAGE_ROUTE = location.pathname !== BOOKINGS;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 500,
    defaultExpanded: true,
  });

  return (
    <div className='relative flex h-full w-full'>
      <div
        className={classNames(
          "w-full flex flex-col gap-4 h-full bg-white border-contrast-200 border-r transition-all duration-300 ease-in-out",
          {
            "w-16": isCollapsed,
            "w-64": !isCollapsed,
          }
        )}
      >
        <div
          className={classNames(
            "logo p-4 flex justify-between relative items-center",
            { "md:justify-center": isCollapsed },
            { "md:justify-between": !isCollapsed }
          )}
        >
          <img
            src={isCollapsed ? logo : logoWithBrandBeta}
            alt='brand-icon'
            className={classNames({
              "hidden w-[70%] md:inline-block": !isCollapsed,
              hidden: isCollapsed,
            })}
          />
          <img src={logo} alt='brand-icon' className='inline-block md:hidden' />
          <button
            onClick={() => {
              setIsSidePanelOpen(false);
            }}
            className='block md:hidden'
          >
            <RenderSVG
              Svg={Cross}
              width='32'
              height='32'
              alt='Cross icon'
              stroke={primaryColor}
            />
          </button>
          <button
            className='hidden rounded-sm md:block'
            onClick={() => dispatch(setIsSidePanelCollapsed(!isCollapsed))}
          >
            <RenderSVG
              Svg={isCollapsed ? Hamburger : BurgerMenuRight}
              width='32'
              height='32'
              alt='Menu icon'
              stroke={isCollapsed ? primaryColor : EMPTY_STRING}
              className='text-primary-600 hover:text-primary-900'
            />
          </button>
        </div>
        <div className='flex flex-col h-full overflow-y-auto '>
          <div>
            {!isEmpty(sidePanelOptions.navigationTabs) && (
              <div className='px-2'>
                <h6
                  className={classNames(
                    "text-contrast-500 uppercase font-medium text-xs px-4 py-2",
                    { "md:hidden": isCollapsed }
                  )}
                >
                  Navigation
                </h6>
                <ul
                  className={classNames("flex flex-col", {
                    "items-center": isCollapsed,
                  })}
                >
                  {sidePanelOptions.navigationTabs.map(
                    ({ id, name, path, subTabs }) => (
                      <li key={id}>
                        <div
                          className={classNames(
                            "flex items-center gap-3 text-sm font-medium py-2 px-4 rounded-md cursor-pointer",
                            {
                              "bg-primary-100 text-primary-900":
                                selectedTab === id,
                              "hover:text-primary-900 text-contrast-400":
                                selectedTab !== id,
                            }
                          )}
                          {...(Array.isArray(subTabs) && subTabs.length > 0 && !isCollapsed
                            ? getToggleProps({
                                onClick: () => toggleSubTabs(id),
                              })
                            : {
                                onClick: () => handleTabActions(id, path),
                              })}
                        >
                          {getMenubarIcon(id)}
                          {!isCollapsed && (
                            <>
                              <span
                                className={classNames("", {
                                  "text-primary-900": selectedTab === id,
                                  "text-contrast-600 hover:text-primary-900":
                                    selectedTab !== id,
                                })}
                              >
                                {name}
                              </span>
                              {Array.isArray(subTabs) && subTabs.length > 0 && (
                                <RenderSVG
                                  Svg={isExpanded ? ChevronUp : ChevronDown}
                                  alt='Toggle dropdown'
                                  width='16'
                                  height='16'
                                  className='text-contrast-400 hover:text-primary-900'
                                />
                              )}
                            </>
                          )}
                        </div>

                        {/* Render sub-tabs if they exist and the tab is expanded */}
                        {!isCollapsed &&
                          expandedTabs[id] &&
                          Array.isArray(subTabs) && (
                            <ul className='ml-6' {...getCollapseProps()}>
                              {subTabs.map(
                                ({
                                  id: subId,
                                  name: subName,
                                  path: subPath,
                                }) => (
                                  <li
                                    key={subId}
                                    onClick={() =>
                                      handleTabActions(subId, subPath)
                                    }
                                    className={classNames(
                                      "flex items-center gap-3 text-sm font-medium py-1 px-4 rounded-md cursor-pointer",
                                      {
                                        "bg-primary-100 text-primary-900":
                                          selectedTab === subId,
                                        "hover:text-primary-900 text-contrast-400":
                                          selectedTab !== subId,
                                      }
                                    )}
                                  >
                                    <RenderSVG Svg={bulletPoint} color='text-contrast-100' />
                                    <span className="flex-1">{subName}</span>
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}

            {!isEmpty(sidePanelOptions.accountTabs) && (
              <div className='px-2 py-4'>
                <h6
                  className={classNames(
                    "text-contrast-500 uppercase font-medium text-xs px-4 py-2",
                    { "md:hidden": isCollapsed }
                  )}
                >
                  Account
                </h6>
                <ul
                  className={classNames("flex flex-col", {
                    "items-center": isCollapsed,
                  })}
                >
                  {sidePanelOptions.accountTabs.map(({ id, name, path }) => (
                    <li
                      key={id}
                      onClick={() => {
                        handleTabActions(id, path);
                      }}
                    >
                      <div
                        className={classNames(
                          "flex items-center gap-3 text-sm font-medium py-2 px-4 rounded-md cursor-pointer",
                          {
                            "bg-primary-100 text-primary-900":
                              selectedTab === id,
                            "hover:text-primary-900 text-contrast-400":
                              selectedTab !== id,
                          }
                        )}
                      >
                        {getMenubarIcon(id)}
                        {!isCollapsed && (
                          <span
                            className={classNames("", {
                              "text-primary-900": selectedTab === id,
                              "text-contrast-600 hover:text-primary-900":
                                selectedTab !== id,
                            })}
                          >
                            {name}
                          </span>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
                <div
                  onClick={() => window.open(PERSONAL_WEBISTE_URL, "_blank")}
                  className={classNames(
                    "pl-4 py-8 flex gap-3 text-sm font-medium items-center text-contrast-600 hover:text-primary-800 hover:cursor-pointer"
                  )}
                >
                  <RenderSVG Svg={WebsiteRedirect} color='text-contrast-100' />
                  <span className={classNames({ hidden: isCollapsed })}>
                    Personal
                  </span>
                </div>
              </div>
            )}
          </div>
          <div
            className={classNames("px-2 py-4 mt-auto", {
              "absolute bottom-0": isCollapsed,
            })}
          >
            <h6
              className={classNames(
                "text-contrast-500 uppercase font-medium text-xs",
                { "md:hidden": isCollapsed }
              )}
            >
              Profile
            </h6>
            <ul
              className={classNames("flex flex-col", {
                "items-center": isCollapsed,
              })}
            >
              {profileOptions.map(({ id, name, path }) => (
                <li
                  key={id}
                  onClick={() => {
                    {
                      id == logOut.id
                        ? setIsModalOpen(true)
                        : handleTabActions(id, path);
                    }
                  }}
                >
                  <div
                    className={classNames(
                      "flex items-center  gap-3 text-sm font-medium py-2 px-4 rounded-md cursor-pointer",
                      {
                        "bg-primary-100 text-primary-900": selectedTab === id,
                        "hover:text-primary-900 text-contrast-400":
                          selectedTab !== id,
                      }
                    )}
                  >
                    {getMenubarIcon(id)}
                    {!isCollapsed && (
                      <span
                        className={classNames("", {
                          "text-primary-900": selectedTab === id,
                          "text-contrast-600 hover:text-primary-900":
                            selectedTab !== id,
                        })}
                      >
                        {name}
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen
          setIsModalOpen={setIsModalOpen}
          data={logoutData}
          color={PRIMARY}
          handleAction={handleAction}
        >
          <div className='flex flex-col gap-5 text-sm text-contrast-600'>
            Are you sure you want to Logout?
          </div>
        </ConfirmationModal>
      )}
    </div>
  );
};
SidePanel.propTypes = SidePanelPropTypes;
export default SidePanel;
