import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { History, ApiLogs, RenderSVG } from "../../../assets/icons";
import { ROUTES } from "../../../constants";

const { ADMIN_BOOKINGS, ADMIN_API_LOGS } = ROUTES;

const ADMIN_SIDE_BAR_CONTENT = [
  {
    id: 1,
    name: "Bookings",
    routePath: ADMIN_BOOKINGS,
    icon: History,
  },
  {
    id: 2,
    name: "API logs",
    routePath: ADMIN_API_LOGS,
    icon: ApiLogs,
  },
];

const AdminLeftSidebar = () => (
  <div className='bg-white min-w-[220px] p-5 h-full fixed'>
    <ul className='flex flex-col gap-2'>
      {ADMIN_SIDE_BAR_CONTENT.map(({ id, name, routePath, icon }) => (
        <NavLink key={id} to={routePath}>
          {({ isActive }) => (
            <div
              className={classNames(
                "flex gap-2 w-full text-sm font-medium px-2",
                "flex items-center rounded-md overflow-hidden sm:h-11",
                {
                  "bg-primary-100 text-primary-600": isActive,
                }
              )}
            >
              <RenderSVG width={20} height={20} Svg={icon} alt='image' />
              <div>{name}</div>
            </div>
          )}
        </NavLink>
      ))}
    </ul>
  </div>
);

export default AdminLeftSidebar;
