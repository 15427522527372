export const timeFormatter = (timeString) => {
  const iscaretSymbol = timeString.includes(":");
  const hours = iscaretSymbol
    ? timeString.split(":").at(0)
    : timeString.slice(0, 2);
  const minutes = iscaretSymbol
    ? timeString.split(":").at(1)
    : timeString.slice(2);
  const period = +hours >= 12 ? "PM" : "AM";
  const formattedHours = +hours % 12 || 12;
  const formattedMinutes = minutes.padStart(2, "0");
  const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
  return formattedTime;
};

export const convertToTwelveHourFormat = (time = "") => {
  if (!time) return time;
  else {
    const [hour, minute] = time.split(":");
    const hours = parseInt(hour, 10);
    const suffix = hours >= 12 ? "PM" : "AM";
    const hoursIn12 = (hours % 12 || 12).toString().padStart(2, "0");

    return `${hoursIn12}:${minute}${suffix}`;
  }
};
