import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useCollapse } from "react-collapsed";
import { get } from "lodash";
import classNames from "classnames";
import { FlightInfoPropTypes } from "../../../prop-types";
import FlightJourneyDetails from "../../../screens/FlightResults/FlightJourneyDetails";
import {
  getAirlineIconUrlByCarrierCode,
  getCityNameByIata,
  getDayDifference,
  getDayFromDate,
  getFormattedLongDate,
  getStopLabel,
} from "../../../helper";
import {
  ArrowLeft,
  ChevronDown,
  ChevronUp,
  RenderSVG,
} from "../../../assets/icons";
import { DEFAULT_VALUES } from "../../../constants";

const { ZERO, ONE, EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY } = DEFAULT_VALUES;

const FlightInfo = ({ pnrList, itinerary, index }) => {
  const { t } = useTranslation();
  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: 200,
  });

  const { duration, segments = EMPTY_ARRAY } = itinerary;
  const departure = get(segments, "0.departure", EMPTY_OBJECT);
  const arrival = get(
    segments,
    `${segments.length - ONE}.arrival`,
    EMPTY_OBJECT
  );
  const journeyStartDate = departure.date;
  const journeyStartDay = journeyStartDate && getDayFromDate(journeyStartDate);
  const daysRemaining =
    journeyStartDate && getDayDifference(new Date(), journeyStartDate);

  const carrierCode = get(segments, "0.carrierCode", EMPTY_STRING);
  const numberOfStops = segments.length - ONE;
  const stopsLabel = getStopLabel(numberOfStops, t);
  const TODAY = "Today";
  const TOMORROW = "Tomorrow";
  const getFormattedRemainingDays = (daysRemaining) => {
    if (daysRemaining === ZERO) return TODAY;
    if (daysRemaining === ONE) return TOMORROW;
    const absDays = Math.abs(daysRemaining);
    const dayWord = (days) => (days === ONE ? "day" : "days");
    return daysRemaining > ZERO
      ? `In ${daysRemaining} ${dayWord(daysRemaining)}`
      : `${absDays} ${dayWord(absDays)} ago`;
  };

  return (
    <div key={journeyStartDate}>
      <div
       {...getToggleProps()}
        className={classNames("p-4 bg-primary-100/30", {
          "border-t borber-b border-contrast-300 border-dashed": index,
          "bg-red-100/30": index < pnrList?.length && !pnrList?.[index],
        })}
      >
        <div className='flex justify-between'>
          <p className='mb-2 text-[10px] font-semibold text-contrast-700'>
            {getFormattedRemainingDays(daysRemaining)} |{" "}
            {getFormattedLongDate(journeyStartDate, {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}{" "}
            | {journeyStartDay}
          </p>
          <p>
            {index === ZERO && pnrList?.length === ONE && (
              <p
                className={classNames(
                  "text-xs text-contrast-400 bg-primary-50 border border-primary-600 inline-flex rounded-2xl overflow-hidden",
                  { "bg-red-50 border border-red-600": !pnrList[index] }
                )}
              >
                {pnrList[index] ? (
                  <span>
                    <span className='px-2 py-0.5 bg-primary-600 text-white'>
                      PNR
                    </span>
                    <span className='font-bold text-contrast-900 px-1 py-0.5'>
                      {pnrList[index]}
                    </span>
                  </span>
                ) : (
                  <span className='font-bold text-red-700 px-3 py-0.5'>
                    Booking Failed!
                  </span>
                )}
              </p>
            )}
          </p>
        </div>
        <div className='grid grid-cols-12 gap-4 mb-4'>
          <div className='col-span-12 md:col-span-8 2xl:col-span-8'>
            <div className='flex gap-3 '>
              <div className='logo'>
                <img
                  src={getAirlineIconUrlByCarrierCode(carrierCode)}
                  width='30'
                  className='rounded border border-contrast-300'
                  alt='airline logo'
                />
              </div>
              <div className='flex gap-6 justify-between'>
                <div className=''>
                  <h5 className='text-sm font-bold text-contrast-900 mb-1'>
                    {getCityNameByIata(departure.iataCode)} (
                    {departure.iataCode})
                  </h5>
                  <div className='text-xs text-contrast-600 flex gap-2'>
                    <span>{departure.time}</span>
                    <span className='inline-block w-24'>
                      {departure.airportName}
                    </span>
                  </div>
                </div>
                <div className='icon shrink-0 opacity-50'>
                  <RenderSVG Svg={ArrowLeft} width='20' stroke='#000000' />
                </div>
                <div className=''>
                  <h5 className='text-sm font-bold text-contrast-900 mb-1'>
                    {getCityNameByIata(arrival.iataCode)} ({arrival.iataCode})
                  </h5>
                  <div className='text-xs text-contrast-600 flex gap-2'>
                    <span>{arrival.time}</span>
                    <span className='inline-block w-24'>
                      {arrival.airportName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-span-12 md:col-span-4 2xl:col-span-4 flex gap-4'>
            <div className='flex-1 text-start'>
              <h5 className='text-xs text-contrast-800 font-bold mb-1'>
                {duration} | {stopsLabel}
              </h5>
              {index < pnrList?.length && pnrList?.length > ONE && (
                <p
                  className={classNames(
                    "text-xs text-contrast-400 bg-primary-50 border border-primary-600 inline-flex rounded-2xl overflow-hidden",
                    { "bg-red-50 border border-red-600": !pnrList[index] }
                  )}
                >
                  {pnrList[index] ? (
                    <span>
                      <span className='px-2 py-0.5 bg-primary-600 text-white'>
                        PNR
                      </span>
                      <span className='font-bold text-contrast-900 px-1 py-0.5'>
                        {pnrList[index]}
                      </span>
                    </span>
                  ) : (
                    <span className='font-bold text-red-700 px-2 py-0.5'>
                      Booking Failed!
                    </span>
                  )}
                </p>
              )}
            </div>
            <button
              className='flex p-2 cursor-pointer shrink-0'
              {...getToggleProps()}
            >
              <RenderSVG
                Svg={isExpanded ? ChevronUp : ChevronDown}
                className='text-contrast-800'
                width='20'
              />
            </button>
          </div>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className='py-4 px-4 border-t border-contrast-300 border-dashed bg-white'>
          <FlightJourneyDetails segments={itinerary.segments} isBookingPage />
        </div>
      </div>
    </div>
  );
};

FlightInfo.propTypes = FlightInfoPropTypes;

export default memo(FlightInfo);
