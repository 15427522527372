import {
  DEFAULT_VALUES,
  FARE_TYPES,
  JOURNEY_DEFAULT_VALUES,
  TRIP_TYPES,
} from "../../../../constants";

const { EMPTY_OBJECT, ZERO, ONE, TWO } = DEFAULT_VALUES;
const { REGULAR } = FARE_TYPES;
const { ONE_WAY } = TRIP_TYPES;
const ECONOMY = "ECONOMY";

const getLastSessionSearchFilters = (result) => {
  const { tripStartDate, tripEndDate } = result || EMPTY_OBJECT;

  let journey = [{ ...JOURNEY_DEFAULT_VALUES }];

  return {
    adult: ONE,
    children: ZERO,
    infants: ZERO,
    tripType: ONE_WAY,
    travelClass: ECONOMY,
    journey,
    fareType: REGULAR.value,
    isDirectFlight: false,
  };
};

export const getDefaultValues = (result, state) => {
  let flightFilters = EMPTY_OBJECT;
  if (state) {
    const { searchFilters } = state || EMPTY_OBJECT;
    flightFilters = {
      ...searchFilters,
      fareType: REGULAR.value,
      isDirectFlight: false,
    };
    return flightFilters;
  } else {
    flightFilters = getLastSessionSearchFilters(result);
  }
  return flightFilters;
};
