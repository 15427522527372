import { isEmpty } from "lodash";
import getISODateString from "./getISODateString";
import capitalizeFirstLetterOfWord from "./capitalizeFirstLetterOfWord";
import { getTravelerCategory } from "./getTravelerAgeAndCategorization";
import { GENDER } from "../constants";

const { MALE } = GENDER;
const Titles = { MR: "Mr", MISS: "Miss", MSTR: "Mstr", MS: "Ms" };
const { MR, MISS, MSTR, MS } = Titles;
const todaysDate = getISODateString(new Date());
const parsedDate = new Date(todaysDate);
const minExpiryDateForPassport = getISODateString(
  new Date(
    parsedDate.getFullYear(),
    parsedDate.getMonth() + 6,
    parsedDate.getDate() + 1
  )
);
const travelerDefaultDOB = getISODateString(
  new Date(
    parsedDate.getFullYear() - 12,
    parsedDate.getMonth(),
    parsedDate.getDate()
  )
);

const getNameParts = (name) => {
  const nameParts = name?.split(" ");
  let firstName = "";
  let middleName = "";
  let lastName = "";

  if (nameParts?.length > 0) firstName = nameParts[0] || "";
  if (nameParts?.length > 1) {
    middleName = nameParts?.slice(1, -1).join(" ") || "";
    lastName = nameParts[nameParts?.length - 1] || "";
  }
  return { firstName, middleName, lastName };
};

const extractNameParts = (guest) => {
  let firstName = "";
  let middleName = "";
  let lastName = "";

  if (guest?.name) {
    const nameParts = getNameParts(guest?.name);
    firstName = nameParts?.firstName;
    middleName = nameParts?.middleName;
    lastName = nameParts?.lastName;
  } else {
    firstName = guest?.firstName;
    middleName = guest?.middleName;
    lastName = guest?.lastName;
  }

  return { firstName, middleName, lastName };
};

const getPaxTitleForFlight = (travelerType, gender) => {
  const titles = {
    Adult: { MALE: MR, FEMALE: MS },
    Child: { MALE: MR, FEMALE: MS },
    Infant: { MALE: MSTR, FEMALE: MS },
  };
  return titles[travelerType]?.[gender] || MR;
};

const getPaxTitleForHotel = (travelerType, gender) => {
  const titles = {
    Adult: { MALE: MR, FEMALE: MS },
    Child: { MALE: MR, FEMALE: MISS },
  };
  return titles[travelerType]?.[gender] || MR;
};

export const mapUsersToTravelers = (usersArray) => {
  return usersArray?.map((userApiResponse) => {
    const isUser = !!userApiResponse?.users || !userApiResponse?.travelers;
    const isPaxPresent = !!(
      userApiResponse?.users || userApiResponse?.travelers
    );
    const user = userApiResponse.users || userApiResponse.travelers;
    const dateOfBirth = userApiResponse?.travelers?.dateOfBirth;
    const travelerType = getTravelerCategory(dateOfBirth, 'flight') || "Adult";
    if (travelerType === "notEligible") {
      return null;
    } 
    const gender = user?.gender.toUpperCase() || MALE;
    const title = getPaxTitleForFlight(travelerType, gender); 
    const { firstName, middleName, lastName } = extractNameParts(user);
    if (!isEmpty(user)) {
      return {
        isUser,
        isPaxPresent,
        travelerType,
        fareType: "RegularFare",
        profileDetails: {
          firstName: capitalizeFirstLetterOfWord(firstName),
          middleName: capitalizeFirstLetterOfWord(middleName) || "",
          lastName:
            capitalizeFirstLetterOfWord(lastName) ||
            capitalizeFirstLetterOfWord(firstName),
          dateOfBirth: user?.dateOfBirth || travelerDefaultDOB,
          gender,
          nationality: {
            isoCode: "",
          },
          title,
        },
        passportDetails: {
          passport: user?.passportNumber || "",
          issueCountry: {
            isoCode: user?.passportIssueCountry || "",
          },
          issuedDate: user?.passportIssuanceDate || "",
          expireDate: user?.passportExpiryDate || "",
        },
        panCardDetails: {
          panCardNumber: user?.panNumber || "",
        },
        document: {
          number: "",
          type: {},
        },
        isPrimary: false,
        phoneCode: "+91",
        phoneNumber: user.phone || user.phoneNumber || "",
        email: user.email || "",
        address: user.address || "",
        city: user.city || "",
        state: user.state || "",
        nationality: { isoCode: "" },
        country: user.country || "",
        travelerId: user.id,
      };
    }
  }).filter(Boolean); 
};

export const mapPassengerToRoom = (
  otherGuestsArray,
  noOfRooms,
  noOfGuestsToBeBooked
) => {
  if (!otherGuestsArray || noOfRooms <= 0) return [];
  const totalGuests = otherGuestsArray.length;
  const baseGuestsPerRoom = Math.floor(totalGuests / noOfRooms);
  const remainingGuests = totalGuests % noOfRooms;
  let roomIndex = 0;

  const roomDistribution = Array(noOfRooms)
    .fill(baseGuestsPerRoom)
    .map((count, index) => {
      return index < remainingGuests ? count + 1 : count;
    });

  let availableRoomIndex = [...roomDistribution];

  return otherGuestsArray.map((otherGuest, i) => {
    const isUser = !!otherGuest?.users || !otherGuest?.travelers;
    const isPaxPresent = !!(otherGuest?.users || otherGuest?.travelers);
    const guest = otherGuest?.users || otherGuest?.travelers;
    const dateOFBirth = guest.dateOfBirth;
    const travelerType = getTravelerCategory(dateOFBirth,"hotel");  
    const gender =guest?.gender.toUpperCase() || MALE;
    const title = getPaxTitleForHotel(travelerType,gender);
    const { firstName, middleName, lastName } = extractNameParts(guest);
    while (availableRoomIndex[roomIndex] === 0) {
      roomIndex++;
    }
    let roomNumber = roomIndex;
    let isTravellerSelected = true;
    availableRoomIndex[roomIndex]--;

    if (totalGuests > noOfGuestsToBeBooked) {
      roomNumber = null;
      isTravellerSelected = false;
    }

    return {
      isUser,
      isPaxPresent,
      isSelected: isTravellerSelected,
      roomIndex: roomNumber,
      id: guest.id,
      profileDetails: {
        firstName: capitalizeFirstLetterOfWord(firstName),
        isChild: travelerType && travelerType === "Child",
        middleName: capitalizeFirstLetterOfWord(middleName),
        lastName:
          capitalizeFirstLetterOfWord(lastName) ||
          capitalizeFirstLetterOfWord(firstName),
        title,
      },
      passportDetails: {
        passportNumber: guest.passportNumber || " ",
        issueCountry: {
          isoCode: guest.passportIssueCountry || " ",
        },
        issuedDate: guest.passportIssuanceDate || todaysDate,
        expiryDate: guest.passportExpiryDate || minExpiryDateForPassport,
      },
      panCardDetails: {
        panCardNumber: guest.panNumber || "",
        surname: lastName || "",
        isChild: travelerType && travelerType === "Child"
      },
    };
  });
};

const calculateTotalPassengers = (guestsArray) => {
  if (!Array.isArray(guestsArray)) {
    return "";
  }
  let totalNoOfAdults = 0;
  let totalNoOfChildren = 0;
  guestsArray.forEach((guest) => {
    totalNoOfAdults += guest.noOfAdults || 0;
    totalNoOfChildren += guest.noOfChild || 0;
  });
  return {
    totalNoOfAdults,
    totalNoOfChildren,
  };
};

export const mapOtherGuestsToTravelers = (
  otherGuestsArray,
  noOfRooms,
  roomGuests
) => {

  const totals = calculateTotalPassengers(roomGuests);
  const totalNoOfAdults = totals.totalNoOfAdults || 0;
  const totalNoOfChildren = totals.totalNoOfChildren || 0;

 
 const { adultGuests, remainingAdultGuests } = otherGuestsArray.reduce(
   (acc, guest) => {
     const isUser = !!guest?.users;
     const dateOfBirth = guest?.travelers?.dateOfBirth;
     const isAdult = getTravelerCategory(dateOfBirth, "hotel") === "Adult";

     if (isUser || isAdult) {
       if (acc.adultGuests.length < totalNoOfAdults) {
         acc.adultGuests = [...acc.adultGuests, guest];
       } else {
         acc.remainingAdultGuests = [...acc.remainingAdultGuests, guest];
       }
     }
     return acc;
   },
   { adultGuests: [], remainingAdultGuests: [] }
 );

 const { childGuests, remainingchildGuests } = otherGuestsArray.reduce(
   (acc, guest) => {
     const isUser = !!guest?.users;
     const dateOfBirth = guest?.travelers?.dateOfBirth;
     const isChild = getTravelerCategory(dateOfBirth, "hotel") === "Child";

     if (isUser || !isChild) {
       return acc;
     }
     if (acc.childGuests.length < totalNoOfChildren) {
       acc.childGuests = [...acc.childGuests, guest];
     } else {
       acc.remainingchildGuests = [...acc.remainingchildGuests, guest];
     }
     return acc;
   },
   { childGuests: [], remainingchildGuests: [] }
 );

 const adultMapping = mapPassengerToRoom(
   adultGuests,
   noOfRooms,
   totalNoOfAdults
 );
 const childMapping = mapPassengerToRoom(
   childGuests,
   noOfRooms,
   totalNoOfChildren
 );
 const remainingChildMapping = mapPassengerToRoom(
   remainingchildGuests,
   noOfRooms,
   0
 );
 const remainingAdultMapping = mapPassengerToRoom(
   remainingAdultGuests,
   noOfRooms,
   0
 );
  
  return [...adultMapping, ...childMapping, ...remainingAdultMapping, ...remainingChildMapping]
};
