import { useState } from "react";
import classNames from "classnames";
import Asterisk from "../../atoms/Asterisk";
import { get, isEmpty } from "lodash";
import { Field, useFormikContext } from "formik";
import ErrorMessage from "../../atoms/ErrorMessage";
import {
  ShowPasswordIcon,
  HidePasswordIcon,
  RenderSVG,
} from "../../../assets/icons";
import { DEFAULT_VALUES, USER_AUTH_FIELDS } from "../../../constants";

const { NUMBER, EMPTY_STRING } = DEFAULT_VALUES;
const { PIN } = USER_AUTH_FIELDS;
const replaceNonDigitsRegex = /[^\d]/g;

const InputField = ({
  id,
  name,
  label,
  type,
  value,
  placeholder,
  showAsterisk = false,
  passwordToggleClassname = "",
  divMarginBottom="7",
  inputFieldClasses="",
  ...restProps
}) => {
  const { errors, touched, handleBlur, handleChange } = useFormikContext();
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const renderFieldError = () =>
    get(errors, name, false) &&
    get(touched, name, false) && (
      <ErrorMessage errorMessage={get(errors, name)} />
    );

  return (
    <div className={`form-group relative mb-${divMarginBottom}`}>
      <label className='block text-sm font-medium mb-1 text-contrast-900'>
        {label}
        {showAsterisk && <Asterisk />}
      </label>
      <div className='flex items-center focus-within:border-primary-500 border-2 rounded-md'>
        <Field
          id={id}
          name={name}
          type={shouldShowPassword ? "text" : type}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`form-control border-none rounded-md w-full !ring-0 ${inputFieldClasses}`}
          placeholder={placeholder}
          autoComplete='off'
          onInput={(e) => {
            if (id === PIN)
              e.target.value = e.target.value.replace(
                replaceNonDigitsRegex,
                EMPTY_STRING
              );
          }}
          onKeyPress={(e) => {
            if (type === NUMBER && (e.key === "e" || e.key === "E")) {
              e.preventDefault();
            }
          }}
          {...restProps}
        />
        {type === "password" && (
          <RenderSVG
            className={classNames(
              "w-8 h-5 cursor-pointer",
              passwordToggleClassname
            )}
            Svg={shouldShowPassword ? ShowPasswordIcon : HidePasswordIcon}
            alt='Password'
            fill={shouldShowPassword ? "" : "#FFFFFF"}
            onClick={() => setShouldShowPassword(!shouldShowPassword)}
          />
        )}
      </div>
      <div className="absolute">{renderFieldError()}</div>
    </div>
  );
};

export default InputField;
