import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { REQUEST_METHODS } from "../../constants";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";

const { CREATE_WALLET, GET_WALLET, UPDATE_WALLET, FETCH_WALLET_TRANSACTIONS } =
  SPINNER_NAMES;
const { GET, POST, PATCH } = REQUEST_METHODS;

export const createWallet = createAsyncThunk(
  "create-wallet",
  async (query = {}, thunkArgs) => {
    return await asyncAction({
      url: "wallets",
      methodType: POST,
      spinner: CREATE_WALLET,
      showErrorToast: true,
      errorMessage: "Failed to create Wallet",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const getWallet = createAsyncThunk(
  "get-wallet",
  async (query = {}, thunkArgs) => {
    return await asyncAction({
      url: "wallets",
      methodType: GET,
      spinner: GET_WALLET,
      showErrorToast: true,
      errorMessage: "Failed to fetch Wallet",
      isAuthRequired: true,
      abortOnPageChange: false,
      ...thunkArgs,
    });
  }
);

export const updateWallet = createAsyncThunk(
  "update-wallet",
  async ({ id, body }, thunkArgs) => {
    return await asyncAction({
      url: `wallets/${id}`,
      methodType: PATCH,
      body,
      spinner: UPDATE_WALLET,
      showErrorToast: true,
      errorMessage: "Failed to update Wallet",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

const createBookingParams = (params) =>
  params ? `?q=${params}` : EMPTY_STRING;

export const fetchWalletTransactions = createAsyncThunk(
  "wallet-transactions",
  async ({ formattedQueryParams, id }, thunkArgs) => {
    const queryParams = createBookingParams(formattedQueryParams);
    return await asyncAction({
      url: `wallets/${id}/transactions${queryParams}`,
      methodType: GET,
      spinner: FETCH_WALLET_TRANSACTIONS,
      showErrorToast: true,
      errorMessage: "Failed to fetch Wallet transactions",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);
