import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCollapse } from "react-collapsed";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import classNames from "classnames";
import { isEmpty } from "lodash";
import {
  getPricePolicyReqBody,
  getFromSessionStorage,
  setToSessionStorage,
  formatPriceWithCommas,
  useClickOutside
} from "../../../helper";
import { selectHotelSearchFilters } from "../../organisms/Search";
import {
  selectHotelInfo,
  selectHotelInfoReqBody,
  setPricePolicyInfo,
  setPricePolicyReqBody,
  setSelectedRoomInfo,
} from "../../../screens/HotelInfo";
import {
  Bed,
  ChevronDown,
  ChevronUp,
  RenderSVG,
  WhiteChevronDown,
  WhiteChevronUp,
} from "../../../assets/icons";
import { selectCountryInfo } from "../../../screens/Profile";
import {
  DEFAULT_VALUES,
  CURRENCY_SYMBOLS,
  DEFAULT_USER_IP_ADDRESS,
  CACHE_KEYS,
  MODAL_SIZE
} from "../../../constants";
import { getAmenityIcon } from "../../../assets/icons/Amenities"; 
import Modal from "../../organisms/Modal";
import {parse} from "html-react-parser/lib/index";
import { decodeHtml } from "../../../helper/hotelBookingHelpers";

const { LG } = MODAL_SIZE;

const PRICE = "price";
const { ZERO, ONE, TWO, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;
const { HOTEL_SEARCH_FILTERS, PRICE_POLICY_REQUEST_BODY } = CACHE_KEYS;
const ALL_ROOM_OPTIONS = "All";

const InfoTag = ({ children }) => {
  return (
    <div className='w-fit px-2 py-0.5 font-medium bg-[#FFEDD5] text-[#7C2D12] text-xs rounded-full'>
      {children}
    </div>
  );
};

const parseCancellationPolicy = (cancellationPolicy) => {
  const parsedCancellationPolicy = cancellationPolicy?.split("#^#") || [];
  const cancellationMsgArr = parsedCancellationPolicy[
    parsedCancellationPolicy.length - ONE
  ]
    ?.replaceAll("#!#")
    .split("|")
    .filter((item) => item && item !== "undefined");
  return cancellationMsgArr || [];
};

const mapAmenities = (amenities = []) => {
  return amenities?.flatMap((amenityStr) =>
    amenityStr
      .split(",")
      .filter((amenity) => !isEmpty(amenity.trim()))
  ) || []
};

const RoomOptionCard = ({
  roomOption,
  currencySymbol,
  isCombination = false,
  handleSelectRoom = () => {},
  isFixedCombination = true,
  isSelected = false,
  handleCheckboxChange = () => {},
  hotelDetailsResult,
}) => {

  const hotelAmenities = get(hotelDetailsResult, 'hotelFacilities', [])
  const [openAmenitiesModal, setOpenAmenitiesModal] = useState(false)
  const {
    cancellationPolicies,
    cancellationPolicy,
    isPANMandatory,
    isPassportMandatory,
    amenities,
    amenity
  } = roomOption;
  const { charge } = cancellationPolicies[ZERO] || {};
  const cancellationMsgArr = parseCancellationPolicy(cancellationPolicy);
  const isPriceDifference = roomOption.price.publishedPrice.toFixed(1) - (roomOption.price.roomPrice + roomOption.price.tavaMarkup + roomOption.price.tax + roomOption.price.otherCharges).toFixed(1)

  const combinedAmenities = Array.from(
    new Set([
      ...mapAmenities(hotelAmenities),
      ...mapAmenities(amenities),
      ...mapAmenities(amenity)
    ])
  );
  const firstFourAmenities = combinedAmenities.slice(0, 4);

  const getTaxesAndFees = (roomOption) => {
    const feesAndTaxes = (
      roomOption.price.tax + roomOption.price.otherCharges
    ).toFixed(TWO);
    return feesAndTaxes;
  };

  const getRoomAndBedType = (roomOption) => roomOption.roomTypeName.split(",");

  const requiredDocuments = [
    {
      key: "passport",
      label: "Passport Required",
      shouldShow: isPassportMandatory,
    },
    {
      key: "pan",
      label: "PAN Required",
      shouldShow: isPANMandatory,
    },
  ];

  return (
    <div
      className={classNames("lg:col-span-7 px-6 py-4 flex flex-col gap-4", {
        "border border-primary-200 rounded-md": !isCombination,
        "bg-blue-100 border-blue-500": isSelected,
      })}
    >
      <div
        className={classNames("w-full flex flex-col sm:flex-row items-start", {
          "my-1": isCombination,
        })}
      >
        <div className='flex flex-col sm:flex-row w-full'>
          <div className='w-full md:w-1/2 text-left flex flex-col justify-center'>
            <div className='mt-0 w-full text-contrast-900 text-left flex-col justify-center'>
              <div className='flex text-xs sm:text-xs md:text-sm lg:text-base font-semibold'>
                {getRoomAndBedType(roomOption)[ZERO]}
              </div>
              <div className='flex text-xs md:text-sm text-contrast-600 gap-2'>
                <RenderSVG
                  Svg={Bed}
                  className='w-4 sm:w-7 h-4 sm:h-7'
                  alt='bed-icon'
                />
                <p className='flex pt-1 text-sm'>
                  {getRoomAndBedType(roomOption)[ONE]}
                </p>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/3 text-left flex flex-col justify-center'>
            <div className='flex flex-row'>
              <div className="flex flex-col justify-center">
                {isPriceDifference > 0 && (<div className='flex text-xs justify-end line-through sm:text-sm md:text-[13px] mr-1 text-red-300 whitespace-nowrap'>
                  {currencySymbol}&nbsp;
                  {formatPriceWithCommas(
                    roomOption.price.publishedPrice
                  )}
                </div>)}
                <div className='flex text-xs sm:text-sm md:text-sm lg:text-lg sm:font-semibold text-primary-600 whitespace-nowrap'>
                  {currencySymbol}&nbsp;
                  {formatPriceWithCommas(
                    roomOption.price.roomPrice + roomOption.price.tavaMarkup
                  )}
                </div>
              </div>
              <div className='flex text-xs ml-3 lg:mb-1 items-end text-primary-600 whitespace-nowrap'>
                + {currencySymbol}&nbsp;
                {formatPriceWithCommas(getTaxesAndFees(roomOption))} taxes &
                fees
              </div>
            </div>
          </div>
          {!isCombination && isFixedCombination && (
            <div className='w-full md:w-1/4 text-left flex flex-col py-2 md:items-end'>
              <button
                className='py-2 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-xs md:text-sm text-white font-medium'
                onClick={handleSelectRoom}
              >
                Select Room
              </button>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="text-xs">
          <div className='flex space-y-1'>
            <ul className='list-inside list-disc'>
              {firstFourAmenities.map((amenity) =>
               <li
               key={amenity.trim()}
               className='list-item text-xs text-contrast-600'
             >
               {decodeHtml(amenity.trim())}
             </li>
              )}
            </ul>
          </div>
          {combinedAmenities.length > 4 && <div className="cursor-pointer text-primary-600 capitalize pl-4 pt-[3px] hover:underline" onClick={() => setOpenAmenitiesModal(!openAmenitiesModal)}>View More</div>}
        </div>
        {openAmenitiesModal && (
          <div className='overflow-auto'>
            <Modal
              title={"Amenities"}
              size={LG}
              shouldShowModalFromProps
              handleClose={() => setOpenAmenitiesModal(!openAmenitiesModal)}
            >
              <div className='px-10 pb-10 font-[inter] overflow-auto font-medium text-xs sm:text-sm text-contrast-900 grid grid-cols-1 xs:grid-cols-2 gap-3 sm:gap-4 col-span-12'>
                {combinedAmenities.map((amenity) => (
                  <div key={amenity} className='flex items-center gap-4 col-6'>
                    <img
                      src={getAmenityIcon(amenity)}
                      width={25}
                      alt='hotel-amenity'
                    />
                    <div> {amenity} </div>
                  </div>
                ))}
              </div>
            </Modal>
          </div>
        )}
      </div>
      <div className='flex justify-between'>
        <div className='flex flex-col sm:flex-row text-start sm:items-center gap-1'>
          <InfoTag>
            Cancellable
            {!!charge && (
              <span className='px-1 whitespace-nowrap'>with deductions*</span>
            )}
          </InfoTag>
          {requiredDocuments.map(({ key, label, shouldShow }) => (
            <span key={key}>{shouldShow && <InfoTag>{label}</InfoTag>}</span>
          ))}
        </div>
      </div>
      {cancellationMsgArr.length > 0 && (<div>
        <div className='px-4 py-4 bg-amber-50 rounded-lg border-amber-600/50 border'>
          <div className='text-xs md:text-sm font-bold'>
            Cancellation Policy
          </div>
          <ul className='list-disc text-xs text-contrast-700 px-4'>
            {cancellationMsgArr?.map((msg) => (
              <li key={msg} className='list-item'>
                {msg}
              </li>
            ))}
          </ul>
        </div>
      </div>)}
      {!isFixedCombination && (
        <div className='flex items-center mt-4'>
          <input
            type='checkbox'
            className='form-checkbox h-4 w-4 text-primary-600'
            checked={isSelected}
            onChange={handleCheckboxChange}
          />
          <span className='ml-2 text-xs sm:text-sm'>Select this option</span>
        </div>
      )}
    </div>
  );
};

const HotelRoomOptions = ({
  roomOptionsRef,
  roomOptionsErrorRef,
  roomCombinations,
  isFixedCombination,
  categoryId,
  onRoomSelection,
  highlightedComboIndex,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropDownRef = useRef();
  const selectedHotelInfo = useSelector(selectHotelInfo);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const hotelInfoReqBody = useSelector(selectHotelInfoReqBody);
  const selectedHotelSearchFilters =
    useSelector(selectHotelSearchFilters) ||
    getFromSessionStorage(HOTEL_SEARCH_FILTERS) ||
    EMPTY_OBJECT;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(ALL_ROOM_OPTIONS);

  const [selectedRoomIndices, setSelectedRoomIndices] = useState({});
  const [selectedRooms, setSelectedRooms] = useState([]);


  const handleRoomClick = (room) => {
    if (!isFixedCombination) {
      onRoomSelection(room);
    }
  };

  const handleCheckboxChange = (comboIndex, roomIndex) => {
    const selectedRoom = filteredRoomOptions.find(
      (room) => room.roomIndex === roomIndex
    );

    setSelectedRoomIndices((prev) => ({
      ...prev,
      [comboIndex]: roomIndex,
    }));

    setSelectedRooms((prevRooms) => {
      const newRooms = { ...prevRooms, [comboIndex]: selectedRoom };
      handleRoomClick(Object.values(newRooms));
      return newRooms;
    });
  };

  useClickOutside(dropDownRef, setIsOpen);
  
  useEffect(() => {
    if (highlightedComboIndex !== null && roomOptionsErrorRef.current) {
      roomOptionsErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [highlightedComboIndex]);

  const {
    ip: endUserIp = DEFAULT_USER_IP_ADDRESS,
    code: guestNationality = "IN",
  } = selectedCountryInfo || {};
  const hotelInfoResult = get(selectedHotelInfo, "priceResult", {});
  const roomInfo = get(
    selectedHotelInfo,
    "priceResult.hotelRoomsResult.hotelRoomsDetails",
    EMPTY_ARRAY
  );

  const hotelDetailsResult = get(hotelInfoResult, "hotelDetails", {});
  const { noOfRooms } = selectedHotelSearchFilters || EMPTY_OBJECT;

  const isCombination = parseInt(noOfRooms) > ONE;

  const filteredRoomOptions = selectedOption
    ? selectedOption === ALL_ROOM_OPTIONS
      ? roomInfo
      : roomInfo.filter(
          (roomInfo) =>
            roomInfo.roomTypeName.split(",")[ZERO] === selectedOption
        )
    : roomInfo;

  let filteredRoomCombination = [];
  roomCombinations.map((comboRoom) => {
    const indexExist = filteredRoomOptions.find(
      (room) => room.roomIndex === comboRoom.roomIndex[ZERO]
    );
    if (indexExist) {
      filteredRoomCombination = [...filteredRoomCombination, comboRoom];
    }
  });

  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const roomOptions = [
    ALL_ROOM_OPTIONS,
    ...Array.from(
      new Set(
        roomInfo.map((roomOption) => roomOption.roomTypeName.split(",")[ZERO])
      )
    ),
  ];

  const handleRoomTypeSelection = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const { sessionParams, sessionId } =
  getFromSessionStorage("hotelsSession") || EMPTY_OBJECT;

  const handleRoomSelection = (roomInfo) => {
    const { resultIndex, categoryId, hotelCode, checkInDate, checkOutDate } = hotelInfoReqBody;
    const pricePolicyReq = getPricePolicyReqBody(
      hotelInfoResult,
      roomInfo,
      hotelCode,
      endUserIp,
      resultIndex,
      noOfRooms,
      categoryId,
      guestNationality,
      checkInDate,
      checkOutDate,
      sessionId
    );
    dispatch(
      setSelectedRoomInfo(Array.isArray(roomInfo) ? roomInfo : [roomInfo])
    );
    setToSessionStorage(PRICE_POLICY_REQUEST_BODY, pricePolicyReq);
    
    dispatch(setPricePolicyInfo(EMPTY_OBJECT));
    dispatch(setPricePolicyReqBody(pricePolicyReq));
    const queryParams = `${"sessionId"}=${sessionParams}`;
    navigate(`${PRICE}?${queryParams}`);
  };

  const handleSelectCombo = (comboRoom) => {
    const roomOptions = comboRoom.roomIndex.map((roomIndex) =>
      filteredRoomOptions.find((room) => room.roomIndex === roomIndex)
    );
    handleRoomSelection(roomOptions);
  };

  return (
    <div className='w-full h-full justify-center item-center my-1 sm:my-4'>
      <p ref={roomOptionsRef} className='text-lg mb-1 font-bold'>
        Select Rooms
      </p>
      <div className='flex flex-col w-full gap-2'>
        <div className='border border-primary-400 rounded-md bg-primary-600'>
          <div className='flex w-full'>
            <div className='w-full md:w-1/2 px-2 sm:px-6 pt-4 text-contrast-900 text-left rounded-sm'>
              <div className='relative inline-block text-left'>
                  <div className='mb-4 gap-2'>
                    <button
                      type='button'
                      onClick={() => setIsOpen(!isOpen)}
                      className={classNames('flex justify-center w-full rounded-md border border-contrast-200 text-sm font-medium text-contrast-700 outline-none border-none',{
                        'cursor-default': roomInfo.length<=ONE
                      })}
                    >
                      <p className='text-sm text-white'>Room Type{roomInfo.length > ONE && (selectedOption==ALL_ROOM_OPTIONS ? 's' : ` - ${selectedOption}`)}</p>
                      {roomInfo.length > ONE && isFixedCombination && !isCombination && (
                        <RenderSVG
                          Svg={isOpen ? WhiteChevronUp : WhiteChevronDown}
                          className='w-5 h-5'
                          alt={isOpen ? "Up Icon" : "Down Icon"}
                        />
                      )}
                    </button>
                  </div>
                {roomInfo.length > ONE && isOpen && isFixedCombination && !isCombination &&(
                  <div className='origin-top-right absolute right-0 left-4 w-56 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 border border-primary-100' ref={dropDownRef}>
                    <div
                      className='py-1'
                      role='menu'
                      aria-orientation='vertical'
                      aria-labelledby='options-menu'
                    >
                      {roomOptions.map((option) => (
                        <div
                          key={option}
                          className='flex px-4 py-2 text-sm text-contrast-700 font-semibold hover:bg-primary-100 hover:text-contrast-900 cursor-pointer'
                          role='menuitem'
                          onClick={() => handleRoomTypeSelection(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                "hidden md:block h-11 w-1/3 p-4 text-left rounded-sm"
              }
            >
              <p className='text-sm text-white font-medium'>Price</p>
            </div>
          </div>
        </div>
        {filteredRoomCombination.map((comboRoom, comboIndex) => {
          const comboRef =
            comboIndex === highlightedComboIndex ? roomOptionsErrorRef : null;
          return (
            <div
              key={comboIndex}
              className={classNames("", {
                "border border-primary-200 rounded-md my-2": isCombination,
                "border-2 error-animate bg-red-100/20":
                  comboIndex === highlightedComboIndex,
              })}
              ref={comboRef}
            >
              {isCombination && isFixedCombination && (
                <div className='flex justify-between p-4 items-center border-b border-dashed'>
                  <p className='text-base font-semibold'>
                    Combo {comboIndex + 1}
                  </p>
                  <button
                    className='bg-primary-600 text-sm text-white px-4 py-2 rounded-md'
                    onClick={() => handleSelectCombo(comboRoom)}
                  >
                    Select Combo
                  </button>
                </div>
              )}
              {isCombination && !isFixedCombination && (
                <div className='flex justify-between p-4 items-center border-b border-dashed'>
                  <p className='text-base font-semibold'>
                    Room Options {comboIndex + 1}
                  </p>
                  <p className='text-base font-semibold'>
                    Select 1 room from below options
                  </p>
                </div>
              )}
              <div className='divide-y divide-contrast-200 gap-2'>
                {comboRoom?.roomIndex.map((roomIndex, index) => {
                  const roomOption = filteredRoomOptions.find(
                    (room) => room.roomIndex === roomIndex
                  );
                  const isSelected =
                    selectedRoomIndices[comboIndex] === roomIndex;
                  return (
                    <div key={roomOption.roomIndex}>
                      <RoomOptionCard
                        roomOption={roomOption}
                        currencySymbol={currencySymbol}
                        isCombination={isCombination}
                        handleSelectRoom={() => handleRoomSelection(roomOption)}
                        isFixedCombination={isFixedCombination}
                        isSelected={isSelected}
                        handleCheckboxChange={() =>
                          handleCheckboxChange(comboIndex, roomIndex)
                        }
                        hotelDetailsResult={hotelDetailsResult}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HotelRoomOptions;
