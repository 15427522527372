import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSpecialServices,
  initiateBooking,
  bookCallback,
  getPromoCodes,
  reissuanceCallback,
  fetchPromptResult,
  reissuance,
} from "./index";
import { DEFAULT_VALUES } from "../../../constants";
import { getFormattedSpecialServicesData } from "../../../ResponseMapper";

const { ZERO, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;

const initialState = {
  selectedTravelers: EMPTY_ARRAY,
  bookingTravelers: EMPTY_ARRAY,
  flightBookingInfo: null,
  tavaBookingId: null,
  promoCodes: EMPTY_ARRAY,
  selectedPromoCode: EMPTY_OBJECT,
  bookCallbackResponse: EMPTY_OBJECT,
  specialServices: EMPTY_ARRAY,
  totalSSRPrice: null,
  gstDetails: null,
  contactDetails: null,
  addressDetails: null,
  SSRDefaultValuesLCC: EMPTY_OBJECT,
  selectedLCCSeats: EMPTY_OBJECT,
  selectedLCCBaggages: EMPTY_OBJECT,
  selectedLCCMeals: EMPTY_OBJECT,
  selectedNonLCCMeal: EMPTY_OBJECT,
  selectedNonLCCSeats: EMPTY_OBJECT,
  flightBookingReqBody: EMPTY_OBJECT,
  newTripDetails: {
    tripName: "",
    tripReason: "",
  },
  promptResult: EMPTY_OBJECT,
  tripStatus: {
    isFlightSearchInitiated: false,
    isHotelSearchInitiated: false,
    isAISearchInitiated: false,
    flightSearchQuery: {},
    hotelSearchQuery: {},
  },
  reissuanceResponse: null,
  tripOrder: [],
};

const slice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setBookingTravelers(state, action) {
      state.bookingTravelers = action.payload;
    },
    setSelectedTravelers(state, action) {
      state.selectedTravelers = action.payload;
    },
    setselectedLCCSeats(state, action) {
      state.selectedLCCSeats = action.payload;
    },
    setPromoCode(state, action) {
      state.selectedPromoCode = action.payload;
    },
    setSelectedLCCMeals(state, action) {
      state.selectedLCCMeals = action.payload;
    },
    setSelectedNonLCCMeals(state, action) {
      state.selectedNonLCCMeal = action.payload;
    },
    setSelectedLCCBaggages(state, action) {
      state.selectedLCCBaggages = action.payload;
    },
    setTotalSSRPrice(state, action) {
      state.totalSSRPrice = action.payload;
    },
    clearSelectedValues(state, action) {
      state.selectedLCCSeats = EMPTY_OBJECT;
      state.selectedNonLCCMeal = EMPTY_OBJECT;
      state.selectedLCCMeals = EMPTY_OBJECT;
      state.selectedLCCBaggages = EMPTY_OBJECT;
      state.selectedPromoCode = EMPTY_OBJECT;
      state.selectedNonLCCSeats = EMPTY_OBJECT;
    },
    setSpecialServices(state, action) {
      state.specialServices = action.payload;
    },
    setTavaBookingId(state, action) {
      state.tavaBookingId = action.payload;
    },
    setBookCallbackResponse(state, action) {
      state.bookCallbackResponse = action.payload;
    },
    setGstDetails(state, action) {
      state.gstDetails = action.payload;
    },
    setContactDetails(state, action) {
      state.contactDetails = action.payload;
    },
    setAddressDetails(state, action) {
      state.addressDetails = action.payload;
    },
    setFlightBooking(state, action) {
      if (action.payload) return action.payload;
    },
    setSelectedNonLCCSeats(state, action) {
      state.selectedNonLCCSeats = action.payload;
    },
    setFlightBookingReqBody(state, action) {
      state.flightBookingReqBody = action.payload;
    },
    setTripStatus(state, action) {
      const { key, value } = action.payload;
      state.tripStatus = {
        ...state.tripStatus,
        [key]: value,
      };
    },
    setIsFlightSearchInitiated(state, action) {
      state.tripStatus.isFlightSearchInitiated = action.payload;
    },
    setIsAISearchInitiated(state, action) {
      state.tripStatus.isAISearchInitiated = action.payload;
    },
    setFlightSearchQuery(state, action) {
      state.tripStatus.flightSearchQuery = action.payload;
    },
    setHotelSearchQuery(state, action) {
      state.tripStatus.hotelSearchQuery = action.payload;
    },
    resetTripStatus(state) {
      state.tripStatus = initialState.tripStatus;
    },
    removeInvalidPax(state, action) {
      const cloneSelectedPax = state.selectedTravelers;
      const cloneInvalidPax = action.payload;
      const validPax = cloneSelectedPax.filter(
        ({ travelerId }) => !cloneInvalidPax.some((id) => id === travelerId)
      );
      state.selectedTravelers = validPax;
    },
    resetPrompt(state, action) {
      state.promptResult = {};
    },
    setTripOrder(state, action) {
      state.tripOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpecialServices.fulfilled, (state, action) => {
      if (action.payload)
        state.specialServices = getFormattedSpecialServicesData(
          action.payload,
          state
        );
    });
    builder.addCase(initiateBooking.fulfilled, (state, action) => {
      if (action.payload) state.flightBookingInfo = action.payload.output;
    });
    builder.addCase(bookCallback.fulfilled, (state, action) => {
      if (action.payload) state.bookCallbackResponse = action.payload;
    });
    builder.addCase(reissuanceCallback.fulfilled, (state, action) => {
      if (action.payload) state.bookCallbackResponse = action.payload.output;
    });
    builder.addCase(getPromoCodes.fulfilled, (state, action) => {
      if (action.payload) state.promoCodes = action.payload;
    });
    builder.addCase(fetchPromptResult.fulfilled, (state, action) => {
      if (action.payload)
        state.promptResult = action.payload.choices[ZERO].message.content;
    });
    builder.addCase(reissuance.fulfilled, (state, action) => {
      if (action.payload) state.reissuanceResponse = action.payload;
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
