export const mapUpdatePermissionsRequest = (
  roleId,
  addedPermissions,
  revokedPermissions
) => {
  return {
    roleId,
    addedPermissions: addedPermissions,
    revokedPermissions: revokedPermissions,
  };
};
