import classNames from "classnames";

const Asterisk = ({ children }) => {
  return (
    <div
      className={classNames({
        relative: children,
        "inline-block": !children,
      })}
    >
      <span
        className={classNames("text-red-500 text-sm font-medium", {
          "inline-block": children,
        })}
      >
        *
      </span>
      {children && (
        <span className='inline-block text-[12px] text-gray-900 absolute pl-0.5 top-0.5'>
          {children}
        </span>
      )}
    </div>
  );
};

export default Asterisk;
