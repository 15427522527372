import { REGEX, DEFAULT_VALUES  } from "../constants";

const { CHARACTER_AFTER_WHITESPACE } = REGEX;
const { EMPTY_STRING } = DEFAULT_VALUES;


const capitalizeFirstLetterOfWord = (value) =>
  value ? value?.replace(CHARACTER_AFTER_WHITESPACE, (firstLetter) =>
    firstLetter.toUpperCase()
  ) : EMPTY_STRING;

export default capitalizeFirstLetterOfWord;
