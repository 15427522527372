import { useState } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import Modal, { setSelectedModal } from "../../Modal";
import Spinner from "../../Spinner";
import {
  RenderSVG,
  HotelImg,
  CheckGreen,
  CrossRed,
} from "../../../../assets/icons";
import { DEFAULT_VALUES, MODAL_SIZE, ROUTES } from "../../../../constants";
const { LG } = MODAL_SIZE;
import {
  getFormattedDateObject,
  getFutureFormattedDateObject,
} from "../../../../helper";
import { useNavigate } from "react-router-dom";

const { BOOKINGS } = ROUTES;

const { ZERO, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;

const CancellationModal = ({
  handleCancellation,
  spinnerType,
  selectedTripHotel,
  hotelCancellationStatus,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const hotelName = get(
    selectedTripHotel,
    "blockRoomResJson.blockRoomResult.hotelName",
    EMPTY_STRING
  );
  const DayRates = get(
    selectedTripHotel,
    "blockRoomResJson.blockRoomResult.hotelRoomsDetails[0].dayRates",
    EMPTY_ARRAY
  );
  const checkInObject = DayRates?.[ZERO];
  const checkOutObject = DayRates?.slice(-1)[ZERO];
  let checkInDate;
  let checkOutDate;
  if ((checkInObject, checkOutObject)) {
    checkInDate = getFormattedDateObject(checkInObject.date);
    checkOutDate = getFutureFormattedDateObject(checkOutObject.date);
  }
  const handleClose = () =>
    !isSpinnerActive && dispatch(setSelectedModal(null));

  const navigateToBookings = () => {
    navigate(BOOKINGS);
    dispatch(setSelectedModal(null));
  };

  return (
    <div className='overflow-auto'>
      <Modal title='Confirm Cancellation' size={LG}>
        {!hotelCancellationStatus ? (
          <div className='text-center px-10 pb-6 pt-10'>
            <div className='pt-6 pb-10'>
              <div className='icon mb-5'>
                <RenderSVG
                  Svg={HotelImg}
                  className='stroke-primary-800 mx-auto'
                  fill='none'
                  width={100}
                  height={100}
                />
              </div>
              <h4 className='font-bold text-xl mb-2 text-[#030E43]'>
                This is the final step of cancellation
              </h4>
              <p className='text-blue-contrast-500 text-sm'>
                Once Cancelled, your booking can not be reinstated
              </p>
            </div>
            <div className='flex gap-3 justify-center items-center bg-[#F8F8FA] p-4 border border-gray-200 rounded-lg'>
              <div className='p-3 rounded-full bg-red-100/50 w-fit'>
                <div className='rounded-full bg-red-600 text-white w-5 h-5 text-center font-bold'>
                  i
                </div>
              </div>
              <div>
                {hotelName} | {checkInDate.day}{" "}
                <strong className='text-sm'>
                  {`${checkInDate.date} ${checkInDate.month} `}
                </strong>
                {checkInDate.year} - {checkOutDate.day}{" "}
                <strong className='text-sm'>
                  {`${checkOutDate.date} ${checkOutDate.month} `}
                </strong>
                {checkOutDate.year}
              </div>
            </div>
            <div className='icon mb-5'></div>

            <div>
              <div className='flex gap-3 justify-end p-6'>
                <button
                  type='button'
                  className='rounded-md shadow-sm px-4 py-3 border border-contrast-300 text-sm leading-5'
                  onClick={handleClose}
                >
                  Back
                </button>
                <button
                  type='button'
                  className='rounded-md shadow-sm px-4 disabled:bg-red-300 disabled:cursor-not-allowed py-3 bg-red-600 text-white text-sm leading-5'
                  onClick={handleCancellation}
                  disabled={isSpinnerActive}
                >
                  <Spinner
                    name={spinnerType}
                    setIsSpinnerActive={setIsSpinnerActive}
                    size='w-5 h-5'
                  />
                  {!isSpinnerActive && <span> Confirm cancellation</span>}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-col justify-center items-center gap-5 px-10'>
            <div
              className={classNames(`p-3 rounded-full w-fit`, {
                "bg-green-100/50": hotelCancellationStatus == "success",
                " bg-red-100/50": hotelCancellationStatus == "failed",
              })}
            >
              <div className='icon'>
                <RenderSVG
                  Svg={
                    hotelCancellationStatus == "success" ? CheckGreen : CrossRed
                  }
                  className={classNames(`p-6 rounded-full mx-auto`, {
                    " bg-green-300": hotelCancellationStatus == "success",
                    " bg-red-300": hotelCancellationStatus == "failed",
                  })}
                  fill='none'
                  width={100}
                  height={100}
                />
              </div>
            </div>
            <div>
              <h4 className='font-bold text-2xl mb-2 text-[#030E43]'>
                {hotelCancellationStatus == "success" ? "Success" : "Failed"}
              </h4>
            </div>
            <div className='flex gap-3 justify-center items-center bg-[#F8F8FA] p-4 border border-gray-200 rounded-lg w-full'>
              <div className='p-3 rounded-full bg-red-100/50 w-fit'>
                <div className='rounded-full bg-red-600 text-white w-5 h-5 text-center font-bold'>
                  i
                </div>
              </div>
              <div>
                {hotelName} | {checkInDate.day}{" "}
                <strong className='text-sm'>
                  {`${checkInDate.date} ${checkInDate.month} `}
                </strong>
                {checkInDate.year} - {checkOutDate.day}{" "}
                <strong className='text-sm'>
                  {`${checkOutDate.date} ${checkOutDate.month} `}
                </strong>
                {checkOutDate.year}
              </div>
            </div>
            <div className='text-sm text-gray-700'>
              {hotelCancellationStatus == "success"
                ? "*Amount will be refunded within 6-8 working days."
                : ""}
            </div>
            <div className='flex justify-end w-full p-6'>
              <button
                className='py-3 px-4 rounded-md bg-[#4f46e5] hover:bg-[#4338ca] active:bg-[#4f46e5] shadow-sm text-sm text-white font-medium'
                onClick={() => navigateToBookings()}
              >
                Go to My Trips
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CancellationModal;
