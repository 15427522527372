import Account from "./Account.js"

import * as actions from "./account.actions";
import * as slice from "./account.reducers";
import * as selectors from "./account.selectors";

export const {
  createWallet,
  getWallet,
  updateWallet,
  fetchWalletTransactions,
} = actions;

export const { name, reducer } = slice;

export const { selectWalletInfo, selectWalletTransactions } = selectors;

export { Account };
