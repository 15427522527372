import { useEffect, useState } from "react";
import classNames from "classnames";
import { DEFAULT_VALUES, DEFAULT_LIMIT } from "../../../constants";
import { RenderSVG, LeftChevron, RightChevron } from "../../../assets/icons";
import { PaginationPropsTypes } from "../../../prop-types";

const { ONE, TWO } = DEFAULT_VALUES;
const NEXT = "next";
const PREV = "prev";
const ELLIPSIS = "...";

const Pagination = ({ data = {}, setCurrentPage = () => {} }) => {
  const {
    count = ONE,
    size = DEFAULT_LIMIT,
    totalPage = ONE,
    currentPage = ONE,
  } = data;
  const lastPage = Math.ceil(count / size);

  const [pageNumbers, setPageNumbers] = useState([]);
  const [limit, setPageLimit] = useState({ startLimit: ONE, endLimit: size });
  const [page, setPage] = useState(Number(currentPage));

  useEffect(() => {
    const startLimit = page * size - size + ONE;
    const endLimit = Math.min(page * size, count);

    setPageLimit({ startLimit, endLimit });
  }, [page, size, count]);

  useEffect(() => {
    const pages = [];
    const startPage = Math.max(page - ONE, TWO);
    const endPage = Math.min(page + ONE, lastPage - ONE);
    pages.push(ONE);
    if (startPage > TWO) {
      pages.push(ELLIPSIS);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    if (endPage < lastPage - ONE) {
      pages.push(ELLIPSIS);
    }
    if (lastPage > ONE) {
      pages.push(lastPage);
    }
    setPageNumbers(pages);
  }, [page, lastPage]);

  useEffect(() => {
    setPage(Number(currentPage));
  }, [currentPage]);

  const handlePaginate = (type) => {
    if (type === PREV && page > ONE) {
      setPage(page - ONE);
      setCurrentPage(page - ONE);
    } else if (type === NEXT && page < totalPage) {
      setPage(page + ONE);
      setCurrentPage(page + ONE);
    }
  };

  const navigateToMiddlePage = (start, end) => {
    const middlePage = Math.floor((start + end) / TWO);
    setPage(middlePage);
    setCurrentPage(middlePage);
  };

  return (
    count > DEFAULT_LIMIT && (
      <div className='flex items-center justify-between gap-3 py-6 px-6 border-contrast-200 shadow-md rounded-b-md border-t bg-white '>
        <span className='text-sm text-contrast-700'>
          Showing <span className='font-medium'>{limit.startLimit}</span> to{" "}
          <span className='font-medium'>{limit.endLimit}</span> of{" "}
          <span className='font-medium'>{count}</span> results
        </span>

        <div className='inline-flex'>
          <button
            className={classNames(
              "p-2 hover:bg-contrast-50 active:bg-white border border-contrast-300 hover:border-contrast-400 rounded-s-md disabled:bg-contrast-200",
              {
                invisible: page === ONE,
              }
            )}
            disabled={page === ONE}
            onClick={() => handlePaginate(PREV)}
          >
            <RenderSVG Svg={LeftChevron} width='20' alt='arrow-left' />
          </button>
          {pageNumbers.map((pageNumber, index, arr) =>
            pageNumber === ELLIPSIS ? (
              <button
                key={index}
                className='py-2 px-4'
                onClick={() => {
                  const prevPage = arr[index - ONE];
                  const nextPage = arr[index + ONE];
                  navigateToMiddlePage(prevPage, nextPage);
                }}
              >
                ...
              </button>
            ) : (
              <button
                key={index}
                className={classNames(`py-2 px-4 border`, {
                  "border-primary-500 bg-primary-50": page === pageNumber,
                  "hover:bg-contrast-50 active:bg-white hover:border-contrast-400": page !== pageNumber,
                })}
                onClick={() => {
                  setPage(pageNumber);
                  setCurrentPage(pageNumber);
                }}
              >
                <span className='text-sm text-contrast-500 font-medium'>
                  {pageNumber}
                </span>
              </button>
            )
          )}
          <button
            className={classNames(
              "p-2 hover:bg-contrast-50 active:bg-white border border-contrast-300 hover:border-contrast-400 rounded-e-md disabled:bg-contrast-200",
              {
                invisible: page === lastPage,
              }
            )}
            disabled={page === lastPage}
            onClick={() => handlePaginate(NEXT)}
          >
            <RenderSVG Svg={RightChevron} alt='arrow-left' width='20' />
          </button>
        </div>
      </div>
    )
  );
};

Pagination.propTypes = PaginationPropsTypes;

export default Pagination;
