import { memo } from "react";
import { useSelector } from "react-redux";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { PaymentInfoPropTypes } from "../../../prop-types";
import { selectCountryInfo } from "../../../screens/Profile";
import { priceFormatter } from "../../../helper";
import { ChevronDown, ChevronUp, RenderSVG } from "../../../assets/icons";
import { CURRENCY_SYMBOLS, DEFAULT_VALUES } from "../../../constants";

const { ZERO, EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;

const PaymentInfo = ({ journeyDetails = EMPTY_ARRAY }) => {
  const { t } = useTranslation();
  const { isExpanded, getCollapseProps, getToggleProps } = useCollapse({
    duration: 200,
  });
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const priceDetails = journeyDetails.map((journey) =>
    get(journey, "price", EMPTY_OBJECT)
  );

  const { totalFare, totalBaseFare, totalTax, totalOtherServices } =
    priceDetails.reduce(
      (acc, journey) => {
        acc.totalFare += journey.grandTotal;
        acc.totalBaseFare += journey.offeredFare + (journey.tavaMarkup ?? 0);
        acc.totalTax += journey.taxFare;
        acc.totalOtherServices += journey.otherServicesAmount;
        return acc;
      },
      { totalFare: 0, totalBaseFare: 0, totalTax: 0, totalOtherServices: 0 }
    );

  const paymentDetails = get(
    journeyDetails,
    "0.price.paymentsDetails",
    EMPTY_OBJECT
  ); // TODO: Need to remove this mapping currently keeping it for previous bookings
  const {
    paidAmount: userPaidAmount = ZERO,
    offeredPriceInfo = EMPTY_OBJECT,
    offeredFare = ZERO,
    selectedPaymentPortal = EMPTY_STRING,
    surcharges = ZERO,
    otherServices = ZERO,
    discountAmount = ZERO,
    platformFee = ZERO,
  } = paymentDetails; // TODO: Need to remove this mapping currently keeping it for previous bookings

  const { baseFare = 0, airlineTaxes = 0 } = offeredPriceInfo;
  let basePrice = ZERO;
  let grandTotal = ZERO;
  const differenceAmount = baseFare + airlineTaxes - offeredFare;
  journeyDetails.forEach((journey) => {
    basePrice += get(journey, "price.basePrice", ZERO);
    grandTotal += get(journey, "price.grandTotal", ZERO);
  });
  return (
    <div className='bg-white rounded-lg border border-contrast-300 shadow-sm '>
      <div {...getToggleProps()} className='p-4 flex items-center gap-4'>
        <h4 className='text-base font-bold text-contrast-900 flex-1'>
          {t("bookingResult.totalPrice")}
        </h4>
        <div className='flex items-center gap-3'>
          <span className='text-lg font-bold text-primary-700'>
            {currencySymbol}&nbsp;
            {priceFormatter(totalFare)}
          </span>
          <button
            className='flex p-2 cursor-pointer shrink-0'
            {...getToggleProps()}
          >
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              className='text-contrast-800'
              width='20'
            />
          </button>
        </div>
      </div>
      <ul className='flex flex-col text-sm divide-y' {...getCollapseProps()}>
        <li className='flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid'>
          <span className=' mr-auto text-contrast-900'>
            {t("bookingResult.baseFare")}
          </span>
          <span className='font-medium'>
            {currencySymbol}&nbsp;
            {priceFormatter(totalBaseFare - totalTax || baseFare)}
          </span>
        </li>
        <li className='flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid'>
          <span className='mr-auto text-contrast-900'>
            {t("bookingResult.taxAndFees")}
          </span>
          <span className='font-medium'>
            {currencySymbol}&nbsp;
            {priceFormatter(totalTax || airlineTaxes + (platformFee || 0))}
          </span>
        </li>
        {!!(totalOtherServices || otherServices) && (
          <li className='flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid'>
            <span className='mr-auto text-contrast-900'>
              {t("bookingResult.ssrCharges")}
            </span>
            <span className='font-medium'>
              {currencySymbol}&nbsp;
              {priceFormatter(totalOtherServices || otherServices)}
            </span>
          </li>
        )}
        {/*Commented as promocode not implemented for now*/}
        {/* {differenceAmount > 0 && (
          <li className='flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid'>
            <span className='mr-auto text-green-400 font-semibold'>
              Discount
            </span>
            <span className='text-green-400 font-semibold'>
              {currencySymbol}
              {priceFormatter(differenceAmount)}
            </span>
          </li>
        )} */}

        {/* {!!discountAmount && (
          <li className='flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid'>
            <span className='mr-auto text-contrast-900'>
              {t("bookingResult.promoCode")}
            </span>
            <span className='font-medium'>
              - {currencySymbol}
              {priceFormatter(discountAmount)}
            </span>
          </li>
        )} */}
      </ul>
    </div>
  );
};

PaymentInfo.propTypes = PaymentInfoPropTypes;

export default memo(PaymentInfo);
