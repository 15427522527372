import React from "react";
import PermissionsTable from "./PermissionsTable";

const Permissions = () => {
  return (
    <div className='flex-1 flex flex-col min-h-screen font-inter bg-contrast-100 p-4'>
      <PermissionsTable />
    </div>
  );
};

export default Permissions;
