import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { REQUEST_METHODS } from "../../constants";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";

const { POST, GET, PATCH, DELETE } = REQUEST_METHODS;
const {
  FETCH_USER,
  ADD_TRAVELER,
  DELETE_TRAVELER,
  UPDATE_TRAVELER,
  UPDATE_PASSWORD,
  GET_COST_CODES,
  ADD_COST_CODES,
} = SPINNER_NAMES;
const DEFAULT_RATE_EXCHANGE_URL =
  "https://z2hn59wxsj.execute-api.ap-south-1.amazonaws.com/prod";
const { REACT_APP_RATE_EXCHANGE_URL } = process.env;
const rateExchangeURL =
  REACT_APP_RATE_EXCHANGE_URL || DEFAULT_RATE_EXCHANGE_URL;

export const addTraveler = createAsyncThunk(
  "add-traveler",
  async ({ body, id }, thunkArgs) => {
    return await asyncAction({
      url: `users/${id}`,
      methodType: POST,
      body,
      spinner: ADD_TRAVELER,
      showErrorToast: true,
      errorMessage: "Failed to add traveler",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const getTravelers = createAsyncThunk(
  "get-traveler",
  async (id, thunkArgs) => {
    return await asyncAction({
      url: `users/${id}`,
      methodType: GET,
      spinner: FETCH_USER,
      showErrorToast: true,
      abortOnPageChange: false,
      errorMessage: "Failed to fetch traveler",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const deleteTraveler = createAsyncThunk(
  "remove-traveler",
  async (id, thunkArgs) => {
    return await asyncAction({
      url: `travelers/${id}`,
      methodType: DELETE,
      spinner: DELETE_TRAVELER,
      showErrorToast: true,
      errorMessage: "Failed to remove traveler",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const updateTraveler = createAsyncThunk(
  "update-traveler",
  async ({ body, id }, thunkArgs) => {
    return await asyncAction({
      url: `travelers/${id}`,
      methodType: PATCH,
      spinner: UPDATE_TRAVELER,
      body,
      showErrorToast: true,
      errorMessage: "Failed to update traveler",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const getRateExchanges = createAsyncThunk(
  "get-exchangeRates",
  async (_, thunkArgs) => {
    return await asyncAction({
      url: `${rateExchangeURL}`,
      methodType: GET,
      errorMessage: "Failed to fetch exchangeRates",
      shouldAllowCustomHeaders: false,
      ...thunkArgs,
    });
  }
);

export const updatePassword = createAsyncThunk(
  "update-password",
  async ({ body, id }, thunkArgs) => {
    return await asyncAction({
      url: `users/${id}/password`,
      methodType: PATCH,
      spinner: UPDATE_PASSWORD,
      body,
      showErrorToast: true,
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const getCostCodes = createAsyncThunk(
"cost-code",
  async (tenantId, thunkArgs) => {
    return await asyncAction({
      url:`tenants/${tenantId}/costCodes?searchKey=`,
      methodType: GET,
      spinner: GET_COST_CODES,
      showErrorToast: true,
      errorMessage:"Failed to fetch Cost Codes",
      isAuthRequired: true,
      ...thunkArgs,
    })
  }
);

export const addCostCodes = createAsyncThunk(
  "add-cost-code",
  async (body, thunkArgs) => {
    const { tenantId } = body;
    return await asyncAction({
      url: `tenants/${tenantId}/costCodes`,
      body: JSON.stringify(body),
      methodType: POST,
      spinner: ADD_COST_CODES,
      showErrorToast: true,
      errorMessage: "Failed to add Cost Code",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);
