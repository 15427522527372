import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { DEFAULT_VALUES, SKELETON_COLOR } from "../../../constants";

const { ONE } = DEFAULT_VALUES;
const { BASE_COLOR, HIGHLIGHT_COLOR } = SKELETON_COLOR;

const SpecialServicesSkeleton = () => {
  return (
    <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHLIGHT_COLOR}>
      <div className='w-full'>
        <div className='p-2'>
          <Skeleton count={ONE} height={60} width={"100%"} />
        </div>
        <div className='p-2'>
          <Skeleton count={ONE} height={60} width={"100%"} />
        </div>
        <div className='p-2'>
          <Skeleton count={ONE} height={60} width={"100%"} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SpecialServicesSkeleton;
