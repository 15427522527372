import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import classNames from "classnames";
import {
  ROUTES,
  DEFAULT_VALUES,
  CACHE_KEYS,
  REGEX,
  INVITATION_STATUS,
} from "../../constants";
import { setToLocalStorage } from "../../helper";
import {
  setAuthInfo,
  acceptInvitation,
  invitationDetails,
  selectInvitationDetails,
  setInvitationId,
  forgotPassword,
  setIsUserAuthenticated,
} from "./index";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import InformationDetailsSkeleton from "../../components/organisms/AppSkeletons/InformationDetailsSkeleton";
import { RenderSVG, TripTavaWithBrand, ShowPasswordIcon, HidePasswordIcon } from "../../assets/icons";
import { OtpField } from "../../components/molecules";
import { toast } from "react-toastify";
import Asterisk from "../../components/atoms/Asterisk";

const { ACCEPT_INVITATION, INVITATION_DETAIL, FORGOT_PASSWORD } = SPINNER_NAMES;
const { PASSWORD_REGEX ,NO_WHITESPACES } = REGEX;

const { BOOKINGS, LOGIN } = ROUTES;
const { EMPTY_STRING } = DEFAULT_VALUES;
const { AUTH, CURRENT_USER_INFO } = CACHE_KEYS;

const initialValues = {
  code: {
    one: EMPTY_STRING,
    two: EMPTY_STRING,
    three: EMPTY_STRING,
    four: EMPTY_STRING,
    five: EMPTY_STRING,
    six: EMPTY_STRING,
  },
  password: EMPTY_STRING,
  confirmPassword: EMPTY_STRING,
};
const INVITE = "invite";
const RESET_PASSWORD = "reset-password";
const EMAIL_ID = "emailId";
const TYPE = "type";
const INVITATION_ID = "invitationId";

const SetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invitationDetail = useSelector(selectInvitationDetails);

  const [isInvitationExpired, setIsInvitationExpired] = useState(false);
  const [acceptInvitationReqBody, setAcceptInvitationReqBody] = useState({});
  const [isInvitedUser, setIsInvitedUser] = useState(false);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [shouldShowConfirmPassword, setShouldShowConfirmPassword] = useState(false);
  const [isInviteSpinnerActive, setIsInviteSpinnerActive] = useState(true);

  const queryParams = new URLSearchParams(window.location.search);
  const userEmail = decodeURIComponent(queryParams.get(EMAIL_ID));
  const actionType = queryParams.get(TYPE);

  useEffect(() => {
    if (actionType === INVITE) setIsInvitedUser(true);
    else if(actionType !== RESET_PASSWORD) navigate(LOGIN)
  }, [actionType]);

  useEffect(() => {
    if (isInvitedUser) {
      const invitationId = queryParams.get(INVITATION_ID);
      dispatch(setInvitationId(invitationId));
      dispatch(invitationDetails(invitationId));
    }
  }, [dispatch, isInvitedUser]);

  const isExpired = (expiryDate, status) => {
    const expirationDate = new Date(expiryDate);
    const currentDate = new Date();
    return expirationDate < currentDate || status !== INVITATION_STATUS.PENDING;
  };

  useEffect(() => {
    if (isInvitedUser && invitationDetail) {
      const expiryDate = get(
        invitationDetail,
        "invitationDetails.expirationDateTime",
        ""
      );

      const status = get(invitationDetail, "invitationDetails.status", "");
      const invitationId = get(invitationDetail, "invitationDetails.id", "");
      const email = get(invitationDetail, "invitationDetails.email", "");
      const tenantId = get(invitationDetail, "invitationDetails.tenantId", "");
      setAcceptInvitationReqBody({ invitationId, email, tenantId });
      setIsInvitationExpired(isExpired(expiryDate, status));
    }
  }, [invitationDetail, isInvitedUser]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
    .min(8, "Password must be at least 8 characters")
      .max(20, "Must be 20 characters or less")
      .matches(NO_WHITESPACES, "Password cannot contain whitespace")
      .matches(
        PASSWORD_REGEX,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password does not match")
      .required("Confirm Password is required"),
    ...(isInvitedUser
      ? {}
      : {
          code: Yup.object()
            .test(
              "code-keys",
              "Verification code is required",
              function (value) {
                for (const key of Object.keys(initialValues.code)) {
                  if (!(key in value) || !value[key]) {
                    return false;
                  }
                }
                return true;
              }
            )
            .required("Code is required"),
        }),
  });

  const authenticateUser = (user) => {
    if (user) {
      const { accessToken, refreshToken, id, email, permissions } = user;
      const auth = { refreshToken, accessToken, id, email };
      if (accessToken && refreshToken) {
        setToLocalStorage(AUTH, auth);
        setToLocalStorage(CURRENT_USER_INFO, user);
        dispatch(setIsUserAuthenticated(!!id));
        dispatch(setAuthInfo(auth));
      } else {
        toast.error(t("loginAndSignup.unableToAuthenticate"));
      }
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    const { confirmPassword, code } = values;
    const formattedCode = Object.values(code).reduce(
      (acc, val) => acc + val,
      EMPTY_STRING
    );
    const action = isInvitedUser
      ? acceptInvitation({
        ...acceptInvitationReqBody,
        password: confirmPassword,
      })
      : forgotPassword({
        email: userEmail,
        password: confirmPassword,
        code: formattedCode,
      });

    dispatch(action)
      .then((res) => {
        if (res.payload) {
          isInvitedUser && authenticateUser(res.payload);
          isInvitedUser ? navigate(BOOKINGS) : navigate(LOGIN);
          resetForm();
        }
      })
      .catch(() => setIsOtpValid(false));
  };

  return (
    <div className='font-inter bg-primary-50'>
      <div className='py-8 px-6 min-h-screen flex justify-center items-center'>
        <div className='flex-1 bg-white rounded-lg shadow-md p-6 max-w-md flex flex-col gap-6'>
          <div className=''>
            <div className='text-center'>
              <RenderSVG
                Svg={TripTavaWithBrand}
                width='94'
                height='24'
                className='mx-auto'
                alt='logo'
              />
            </div>
          </div>
          <Spinner
            name={INVITATION_DETAIL}
            showSkeleton={true}
            showSpinner={isInviteSpinnerActive}
            setIsSpinnerActive={setIsInviteSpinnerActive}
            loaderComponent={<InformationDetailsSkeleton />}
          >
            {isInvitationExpired ? (
              <div className='text-center pb-8 '>
                <h1 className='text-2xl text-contrast-900 text-center font-bold mb-2'>
                  Invite Expired
                </h1>
                <p className='text-base text-center text-contrast-500'>
                  Your invite has expired. Please contact the administrator for
                  a new invitation.
                </p>
              </div>
            ) : (
              <>
                <div className='text-center pb-8 '>
                  <h1 className='text-2xl text-contrast-900 font-bold mb-2'>
                    Set Password
                  </h1>
                  <p className='text-base text-contrast-500'>
                    Your password must be at least 8 characters in length and
                    include a special character.
                  </p>
                </div>
                <Formik
                  enableReinitialize
                  validateOnMount={true}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                  }) => (
                    <Form className='flex flex-col gap-6'>
                      {!isInvitedUser && (
                        <div className='form-group'>
                          <label
                            htmlFor='code'
                            className='text-black text-sm font-medium mb-1'
                          >
                            Verfication Code
                            <Asterisk />
                          </label>
                          <div className='flex justify-between'>
                            <OtpField
                              name='code'
                              type='text'
                              values={values.code}
                              setFieldValue={setFieldValue}
                              isOtpValid={isOtpValid}
                              setIsOtpValid={setIsOtpValid}
                              className={classNames(
                                "border-contrast-300 rounded-md shadow-sm text-base py-2 px-3 block w-full placeholder-[#6B7280]",
                                {
                                  "border-red-500": errors.code && touched.code,
                                }
                              )}
                            />
                          </div>
                          <ErrorMessage
                            name='code'
                            component='div'
                            className='text-red-500 text-sm'
                          />
                        </div>
                      )}
                      <div className='form-group'>
                        <label
                          htmlFor='password'
                          className='text-black text-sm font-medium mb-1'
                        >
                          Password
                          <Asterisk />
                        </label>
                        <div className='relative flex'>
                          <Field
                            id='password'
                            type={shouldShowPassword ? "text" : "password"}
                            name='password'
                            placeholder='Enter Password'
                            className={classNames(
                              "border-contrast-300 rounded-md shadow-sm text-base py-2 px-3 block w-full placeholder-[#6B7280]",
                              {
                                "border-red-500":
                                  errors.password && touched.password,
                              }
                            )}
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div className='absolute inset-y-0 right-0 mr-3 flex items-center cursor-pointer'>
                            <RenderSVG
                              Svg={
                                shouldShowPassword
                                  ? ShowPasswordIcon
                                  : HidePasswordIcon
                              }
                              onClick={() => setShouldShowPassword((prev) => !prev)}
                              fill={shouldShowPassword ? "" : "transparent"}
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name='password'
                          component='div'
                          className='text-red-500 text-sm'
                        />
                      </div>
                      <div className='form-group'>
                        <label
                          htmlFor='confirmPassword'
                          className='text-black text-sm font-medium mb-1'
                        >
                          Confirm Password
                          <Asterisk />
                        </label>
                        <div className='relative flex'>
                          <Field
                            id='confirmPassword'
                            type={shouldShowConfirmPassword ? "text" : "password"}
                            name='confirmPassword'
                            placeholder='Enter Confirm Password'
                            className={classNames(
                              "border-contrast-300 rounded-md shadow-sm text-base py-2 px-3 block w-full placeholder-[#6B7280]",
                              {
                                "border-red-500":
                                  errors.confirmPassword &&
                                  touched.confirmPassword,
                              }
                            )}
                            value={values.confirmPassword}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div className='absolute inset-y-0 right-0 mr-3 flex items-center cursor-pointer'>
                            <RenderSVG
                              Svg={
                                shouldShowConfirmPassword
                                  ? ShowPasswordIcon
                                  : HidePasswordIcon
                              }
                              onClick={() => setShouldShowConfirmPassword((prev) => !prev)}
                              fill={shouldShowConfirmPassword ? "" : "transparent"}
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name='confirmPassword'
                          component='div'
                          className='text-red-500 text-sm'
                        />
                      </div>
                      <button
                        type='submit'
                        className='py-[10px] px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-[16px] text-white font-medium'
                        disabled={isSpinnerActive}
                      >
                        <Spinner
                          name={[FORGOT_PASSWORD, ACCEPT_INVITATION]}
                          setIsSpinnerActive={setIsSpinnerActive}
                          size='w-5 h-5'
                        />
                        {!isSpinnerActive && <span>Set Password</span>}
                      </button>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Spinner>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
