export const getRoomTypeDetails = (hotelRoomsDetails) => {
    try {
        const roomTypeCounts = hotelRoomsDetails?.reduce((acc, { roomTypeName }) => {
            if (roomTypeName) {
                acc[roomTypeName] = (acc[roomTypeName] || 0) + 1;
            }
            return acc;
        }, {});
        const roomTypeData = Object.entries(roomTypeCounts)
            .map(([key, value]) => (value > 1 ? `${value} x ${key}` : key))
            .join(' | ');

        return roomTypeData   
    } catch (error) {
        return null
    }
}
