import React from "react";
import ReactDOM from "react-dom/client";
import "./main.css";
import "react-toastify/dist/ReactToastify.css";
import "react-json-view-lite/dist/index.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./i18n/config";
import App from "./App";
import initializeSentry from "./infrastructure/sentry";
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

initializeSentry();

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
