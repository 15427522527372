import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import {
  FlightInfo,
  TravelersInfo,
  WebCheckinInfo,
  AirlineContactInfo,
  PaymentInfo,
} from "../../../../components/organisms/FlightBookingInfoSections";
import {
  DEFAULT_VALUES,
  RE_ISSURANCE_FLIGHTS_CODE,
  BOOKING_STATUS_CODES
} from "../../../../constants";
import config from "../../../../config.json";
import { selectSelectedTrip } from "../../Trips";
import Ticket from "./Ticket";
import Contact from "./Contact";
import Reissuance from "./Reissuance";
import { RenderSVG, TicketOutline } from "../../../../assets/icons";
import { getFormattedLongDate,formatDateWithLeadingZeros } from "../../../../helper";
import { selectCurrentUserInfo } from "../../../Auth";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;
const { logo } = config;
const { FAILED, DRAFT, CANCELLED } = BOOKING_STATUS_CODES;
const CHECKLIST_POINTS = [
  "Ensure that all traveler names are spelled correctly and match their government-issued ID exactly.",
  "Each traveler should have a valid government-issued ID proof (passport, driver's license, etc.) for check-in and security clearance.",
  "Ensure that the date of birth of each traveler is accurate and matches the ID provided.",
];
const BOARDING_POINTS = [
  "Check-in online, if available, to save time at the airport. Otherwise, ensure to arrive at the airport well before the recommended check-in time.",
  "Remember to bring a printed or electronic copy of the boarding pass and any necessary travel documents (e.g. visas, health certificates).",
];
const ATTENTION_POINTS = [
  "Familiarize yourself with the airline's baggage allowance and any restrictions on size, weight, or prohibited items.",
  "Ensure that checked baggage is properly labeled with your name, contact information, and destination address.",
];

const DATE_FORMATS = {
  NUMERIC: "numeric",
  TWO_DIGIT: "2-digit",
  SHORT: "short",
};
const { NUMERIC, TWO_DIGIT, SHORT } = DATE_FORMATS;

const Flight = () => {
  const navigate = useNavigate();
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const [PNRList, setPNRList] = useState(EMPTY_ARRAY);
  const selectedTrip = useSelector(selectSelectedTrip);
  const {
    id,
    flights = EMPTY_ARRAY,
    createdAt = EMPTY_STRING
  } = selectedTrip || EMPTY_OBJECT;

  const tripType = flights[ZERO]?.journeyType.replace('_',' ');
  const providerBookingId = flights[ZERO]?.providerBookingId;
  const shouldShowReissuanceOrCancelBtn = flights?.some(
    ({ tenantStatus, departureDateTime }) => {
      if (departureDateTime) {
        const departureDate = new Date(departureDateTime);
        const currentDate = new Date();
        const isDateValidToCancel = departureDate > currentDate;
        return (
          tenantStatus !== CANCELLED &&
          tenantStatus !== FAILED &&
          isDateValidToCancel
        );
      }
      return false;
    }
  );
  const options = { hour: NUMERIC, minute: TWO_DIGIT, hour12: true };
  const bookingTime = createdAt
    ? getFormattedLongDate(
        `${formatDateWithLeadingZeros(createdAt)}`,
        options
      ).split(",")[ONE]
    : "";

  useEffect(() => {
    if (!selectedTrip) return;
    setPNRList(flights.map((each) => each.pNR));
  }, [selectedTrip]);

  const itineraries = flights.reduce((itineraryAcc, flight) => {
    const itinerary = get(
      flight,
      "bookingJson.journeyDetails.0.itineraries",
      EMPTY_ARRAY
    );
    return [...itineraryAcc, ...itinerary];
  }, []);
  const journeyDetails = flights.map((flightInfo) =>
    get(flightInfo, "bookingJson.journeyDetails.0")
  );
  const travelersData = get(journeyDetails, "0.travelerDetails", EMPTY_ARRAY);
  const travelerDetails = cloneDeep(travelersData);
  const hasPNR = PNRList.some((pnr) => pnr !== null);

  const flightData = flights.reduce((itineraryAcc, flight) => {
    const { bookingJson, id, tavaBookingId, providerBookingId, pNR, tenantStatus } = flight;
    const {
      itineraries = EMPTY_ARRAY,
      travelerDetails = EMPTY_ARRAY,
      fareRules = EMPTY_ARRAY,
    } = get(bookingJson, "journeyDetails.0", EMPTY_OBJECT);
    const { airline } = fareRules[0] || EMPTY_OBJECT;
    const isReIssuranceFlight = RE_ISSURANCE_FLIGHTS_CODE.includes(airline);
    const reIssuanceBookingStatus = get(flight, "reissuedBookings[0].tenantStatus", DRAFT);
    const isSuccessReIssuedBooking = reIssuanceBookingStatus !== DRAFT;

    const formattedData = isReIssuranceFlight
      ? [
          ...itineraryAcc,
          {
            id,
            airline,
            itineraries,
            travelerDetails,
            tripId: tavaBookingId,
            bookingId: providerBookingId,
            pnr: pNR,
            isSuccessReIssuedBooking,
            tenantStatus,
          },
        ]
      : itineraryAcc;
    return formattedData;
  }, []);

  return (
    <div className='py-16 relative'>
      <div className='container px-8 mx-auto -mt-12'>
        <div className='grid grid-cols-12 gap-8 '>
          <div className='col-span-12 xl:col-span-8 flex flex-col gap-4'>
            <div className='flex flex-col gap-8'>
              <div className='bg-white rounded-lg border border-dashed border-contrast-300 shadow-sm'>
                <div className='flex flex-wrap px-4 py-3 border-b border-dashed border-contrast-300 justify-between'>
                  <h4 className='text-contrast-900 font-bold text-sm'>
                    {tripType} | Flight
                  </h4>
                  <div className='flex flex-col gap-1'>
                    <p className='text-contrast-800 font-semibold text-xs flex gap-2 items-center '>
                      <span>Booking ID</span>
                      <span className='text-primary-700 bg-primary-100 px-2 py-0.5 rounded-2xl'>
                        {providerBookingId?providerBookingId : id}
                      </span>
                    </p>
                    {createdAt && (
                      <p className='font-semibold text-xs flex gap-2 items-center text-contrast-800'>
                        Booked On
                        <span className='text-primary-700 px-2 py-0.5 rounded-2xl bg-primary-100'>
                          {getFormattedLongDate(createdAt, {
                            day: NUMERIC,
                            month: SHORT,
                            year: NUMERIC,
                          }) +
                            " " +
                            bookingTime || EMPTY_STRING}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                {itineraries.map((itinerary, index) => (
                  <FlightInfo
                    key={index}
                    pnrList={PNRList}
                    itinerary={itinerary}
                    index={index}
                  />
                ))}
              </div>
            </div>

            <PaymentInfo journeyDetails={journeyDetails} />
            <TravelersInfo travelersData={travelerDetails} />
            {shouldShowReissuanceOrCancelBtn && (
              <WebCheckinInfo
                itineraries={itineraries}
                pnrList={PNRList}
                tripType={tripType}
                hasPNR={hasPNR}
              />
            )}

            <div className='rounded-lg border border-contrast-300 shadow-sm overflow-hidden'>
              <div className='px-6 py-4 border-b border-contrast-300 bg-contrast-50'>
                <h4 className='text-base font-bold text-contrast-900 flex-1'>
                  Important Information
                </h4>
              </div>
              <div className='py-4 px-6 flex flex-col gap-6'>
                <div className=''>
                  <h4 className='font-bold text-contrast-900 mb-2 text-sm'>
                    Mandatory check-list for passengers
                  </h4>
                  <ul className='!list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1'>
                    {CHECKLIST_POINTS.map((each) => (
                      <li>{each}</li>
                    ))}
                  </ul>
                </div>

                <div className=''>
                  <h4 className='font-bold text-contrast-900 mb-2 text-sm'>
                    Check-in and Boarding
                  </h4>
                  <ul className='!list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1'>
                    {BOARDING_POINTS.map((each) => (
                      <li>{each}</li>
                    ))}
                  </ul>
                </div>

                <div className=''>
                  <h4 className='font-bold text-contrast-900 mb-2 text-sm'>
                    Kind Attention
                  </h4>
                  <ul className='!list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1'>
                    {ATTENTION_POINTS.map((each) => (
                      <li>{each}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-12 xl:col-span-4'>
            <Ticket />
            {shouldShowReissuanceOrCancelBtn && (
              <div className='border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6 print:hidden'>
                <div className='p-6'>
                  <h4 className='font-bold text-contrast-900 text-base mb-5'>
                    Manage your trip
                  </h4>
                  {shouldShowReissuanceOrCancelBtn && (
                    <ul className='flex flex-col gap-3'>
                      <li>
                        <button
                          className='flex gap-2 items-center text-sm font-medium text-primary-600 disabled:cursor-not-allowed'
                          onClick={() =>{
                            {
                              if (
                                window.location.pathname.includes(
                                  `/users/${currentUserInfo?.id}/bookings`
                                )
                              ) {
                                navigate(`/users/${currentUserInfo?.id}/bookings/${id}/preview/flight/cancel`);
                              } else {
                                navigate(`/bookings/${id}/preview/flight/cancel`)
                              }
                            }
                          }}
                        >
                          <div className='icon'>
                            <RenderSVG Svg={TicketOutline} width='20' />
                          </div>
                          <span>Cancel Flight</span>
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            )}
            <AirlineContactInfo itineraries={itineraries} />
            <Contact />
            {!!flightData.length && shouldShowReissuanceOrCancelBtn && (
              <Reissuance flightData={flightData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flight;
